export default {
    analyzeCharge_00001: "整体充电量分析",
    analyzeCharge_00002: "站点充电量分析",
    analyzeCharge_00003: "团体充电量分析",
    analyzeCharge_00004: "用户来源充电量分析",
    analyzeCharge_00005: "加盟商充电量分析",
    analyzeCharge_00006: "充电量趋势",
    analyzeCharge_00007: "分析",
    analyzeCharge_00008: "充电量 (度)",
    analyzeCharge_00009: "充电量设备型号分布",
    analyzeCharge_00010: "充电量城市分布",
    analyzeCharge_00011: "站点充电量对比",
    analyzeCharge_00012: "站点充电量趋势",
    analyzeCharge_00013: "团体充电量对比",
    analyzeCharge_00014: "团体充电量趋势",
    analyzeCharge_00015: "加盟商充电量对比",
    analyzeCharge_00016: "加盟商充电量趋势",
    analyzeCharge_00017: "选择设备型号",
    analyzeCharge_00018: "站点利用率对比",
    analyzeCharge_00019: "站点利用率趋势",
    analyzeCharge_00020: "设备型号利用率对比",
    analyzeCharge_00021: "设备型号利用率趋势",
    analyzeCharge_00022: "充电利用率",
    analyzeCharge_00023: "加盟商利用率对比",
    analyzeCharge_00024: "加盟商利用率趋势",
    analyzeCharge_00025: "站点平均功率对比",
    analyzeCharge_00026: "设备型号平均功率分析",
    analyzeCharge_00027: "平均功率",
    analyzeCharge_00028: "选择或输入充电站",
    analyzeCharge_00029: "充电中枪口",
    analyzeCharge_00030: "充电枪口繁忙率",
    analyzeCharge_00031: "充电中枪口个数",
    analyzeCharge_00032: "充电枪口繁忙率 %",
    analyzeCharge_00033: "充电枪口繁忙率 = 当前充电中枪口数 / 总枪口数",
    analyzeCharge_00034: "用户来源充电量占比",
    analyzeCharge_00035: "第三方流量中各流量占比",
    analyzeCharge_00036: "用户来源充电量趋势",
    analyzeCharge_00037: "第三方流量充电量趋势",
    analyzeCharge_00038: "0-24小时平均充电量趋势",
    analyzeCharge_00039: "枪均充电量",
    analyzeCharge_00040: "枪均度数",
} 