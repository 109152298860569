export default {
  feedback_00001: '微信客服',
  feedback_00002: '客服',
  feedback_00003: '请输入用户昵称',
  feedback_00004: '请输入信息内容',
  feedback_00005: '开始日期',
  feedback_00006: '结束日期',
  feedback_00007: '至',
  feedback_00008: '消息列表',
  feedback_00009: '由于微信公众号限制，消息超过48小时无法回复',
  feedback_00010: '已回复 - 已超时',
  feedback_00011: '未回复 - 已超时',
  feedback_00012: '未回复 - ',
  feedback_00013: '已回复 - ',
  feedback_00014: '公众号后台链接',
  feedback_00015: '回复',
  feedback_00016: '请输入回复内容',
  feedback_00017: '发送',
  feedback_00018: '小时前',
  feedback_00019: '分钟前',
  feedback_00020: '回复的消息不能为空',
  feedback_00021: '回复成功',
  feedback_00022: '管理员',
  feedback_00023: "文本消息",
  feedback_00024: "图片消息",
  feedback_00025: "语音消息",
  feedback_00026: "视频消息",
  feedback_00027: "小视频消息",
  feedback_00028: "链接消息",
  feedback_00029: "位置消息",
  feedback_00030: "该消息类型为{0}，请在微信公众号后台查看！",
}