export default {
  notify_00001: '通知',
  notify_00002: '全部通知',
  notify_00003: '年',
  notify_00004: '月',
  notify_00005: '已开票',
  notify_00006: '已拒绝',
  notify_00007: '您可查看原因后重新申请',
  notify_00008: '请查看您的预留手机或邮箱',
  notify_00009: `账单通知：您的平台使用费<span style="color:#09B59D">扣款成功</span>，账单已付款。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">查看</a>`,
  notify_00010: `账单通知：您的物联网卡网费<span style="color:#09B59D">扣款成功</span>，账单已付款。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">查看</a>`,
  notify_00011: `账单通知：您的平台使用费<span style="color:#FA545E">扣款失败</span>，账单<span style="color:#FF9F0A">待支付</span>，剩余使用期限<span style="color:#FA545E">3</span>天，请及时缴费，以免影响相关服务。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">查看</a>`,
  notify_00012: `账单通知：您的平台使用费<span style="color:#FA545E">扣款失败</span>，账单<span style="color:#FA545E">已欠费</span>，请在<span style="color:#FA545E">{day}</span>天内及时缴费，以免影响相关服务。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">查看</a>`,
  notify_00013: `账单通知：您的物联网卡网费<span style="color:#FA545E">扣款失败</span>，账单<span style="color:#FA545E">已欠费</span>，请及时缴费。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">查看</a>`,
  notify_00014: `发票处理通知：您的发票申请{status}，账单金额{amount}元，{invoiceMsg}。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./invoiceDetails.html?id={id}' ,'{messageId}')">查看</a>`,
  notify_00015: `发票处理通知：您的发票申请{status}，账单金额{amount}元，物流信息：{logisticsInfo}。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./invoiceDetails.html?id={id}' ,'{messageId}')">查看</a>`,
  notify_00016: `{userName}在 {exportTime} {exportType}功能中导出的订单数据准备完成，请您在7天内完成下载。过期后，如有需要请您重新导出订单。<a href="javascript:void(0)" onclick="vm.readMessageAnddownload('{downloadId}-{downloadType}-{createTime}' ,'{messageId}')">下载</a>`,
  notify_00017: '请稍等，正在下载中',
  notify_00018: `{userName}在 {exportTime} {exportType}功能中导出订单失败，请稍后<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./bill.html#{type}' ,'{messageId}')">重试</a>！`,
  notify_00019: '文件已过期',
  notify_00020: `停止充电通知：您的平台使用费<span style="color:#FA545E">已欠费</span>，且并未及时缴费，已停止启动充电服务。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?id={id}#platformBillings' ,'{messageId}')">查看</a>`,
  notify_00021: `公对公转账充值平台账户成功!充值金额 {amount}元，详情请在<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./selfHelpPayCost.html?&payCostType=accountBalanceDetail' ,'{messageId}')">余额明细</a>中查看。`,
  notify_00022: `到期提醒：您有{simCardNum}台充电桩的免费物联卡网费<span style="color:#FA545E">已到期</span>，下月1日将产生费用账单。请保证平台账户余额充足。`,
  notify_00023: `非智充桩型号：{factory}-{sourceId} 申请成功，可以在平台上添加该型号设备。<a href="javascript:void(0)" onclick="vm.readMessageAndJump('./addDevice.html' ,'{messageId}')">添加设备</a>`,
  notify_00024: `非智充桩型号：{factory}-{sourceId} 申请失败，失败原因：{comment}。`,
};