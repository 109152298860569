export default {
    monthlyStatistics_00001:'财务中心',
    monthlyStatistics_00002:'开始日期',
    monthlyStatistics_00003:'结束日期',
    monthlyStatistics_00004:'查询',
    monthlyStatistics_00005:'共',
    monthlyStatistics_00006:'笔充值',
    monthlyStatistics_00007:'总金额',
    monthlyStatistics_00008:'元',
    monthlyStatistics_00009:'下载表格',
    monthlyStatistics_00010:'今天',
    monthlyStatistics_00011:'昨天',
    monthlyStatistics_00012:'7天前',
    monthlyStatistics_00013:'15天前',
    monthlyStatistics_00014:'30天前',
    monthlyStatistics_00015:'笔退款',
    
    monthlyStatistics_00016:'充值对象',
    monthlyStatistics_00017:'充值笔数',
    monthlyStatistics_00018:'充值金额(元)',
    monthlyStatistics_00019:'本金(元)',
    monthlyStatistics_00020:'赠送(元)',
    monthlyStatistics_00021:'操作',
    
    monthlyStatistics_00022:'退款对象',
    monthlyStatistics_00023:'退款笔数',
    monthlyStatistics_00024:'退款金额(元)',
    
    monthlyStatistics_00025:'统计对象',
    monthlyStatistics_00026:'用户数量(人)',
    monthlyStatistics_00027:'余额(元)',
    monthlyStatistics_00028:'本金(元)',
    monthlyStatistics_00029:'赠送(元)',
    
    monthlyStatistics_00030:'销售对象',
    monthlyStatistics_00031:'购买次数',
    monthlyStatistics_00032:'购买人数',
    monthlyStatistics_00033:'销售金额(元)',
    
    monthlyStatistics_00034:'全部卡券销售额',
    monthlyStatistics_00035:'全部用户钱包余额',

    monthlyStatistics_00036:'年',
    monthlyStatistics_00037:'财务统计'

}