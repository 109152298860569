export default {
  userClassList_00001: "分类管理",
  userClassList_00002: "分类列表",
  userClassList_00003: "用户分类",
  userClassList_00004: "分类名称",
  userClassList_00005: "当前用户数",
  userClassList_00006: "营销活动数",
  userClassList_00007: "营销活动",
  userClassList_00008: "分类详情",
  userClassList_00009: "操作",
}