export default {    
    stationMaintenanceAnalysis_00001: "station anomaly rate analysis",
    stationMaintenanceAnalysis_00002: "maintenance homepage",
    stationMaintenanceAnalysis_00003: "abnormal rate of pile responsibility = number of abnormal charging orders of this station in this day / number of charging orders of this station in this day",
    stationMaintenanceAnalysis_00004: "abnormal rate of vehicle liability = number of abnormal charging orders of this station / number of charging orders of this station",
    stationMaintenanceAnalysis_00005: "trend analysis",
    stationMaintenanceAnalysis_00006: "daily analysis",
    stationMaintenanceAnalysis_00007: "Charger Issue rate",
    stationMaintenanceAnalysis_00008: "Vehicle Issue rate",
    stationMaintenanceAnalysis_00009: "Equipment issue rate",
    stationMaintenanceAnalysis_00010: "pile responsibility abnormal order",
    stationMaintenanceAnalysis_00011: "pile responsibility exception code",
    stationMaintenanceAnalysis_00012: "abnormal rate of pile responsibility {num}%",
    stationMaintenanceAnalysis_00013: "order number",
    stationMaintenanceAnalysis_00014: "exception",
    stationMaintenanceAnalysis_00015: "charging port",
    stationMaintenanceAnalysis_00016: "device exception log",
    stationMaintenanceAnalysis_00017: "order details",
    stationMaintenanceAnalysis_00018: "device details",
    stationMaintenanceAnalysis_00019: "initiate work order",
    stationMaintenanceAnalysis_00020: "abnormal order of vehicle responsibility",
    stationMaintenanceAnalysis_00021: "station equipment",
    stationMaintenanceAnalysis_00022: "abnormal rate of pile responsibility = the number of abnormal charging orders of this pile responsibility in this day / the number of charging orders of this pile in this day",
    stationMaintenanceAnalysis_00023: "abnormal rate of vehicle responsibility = the number of abnormal charging orders of this pile this day / the number of charging orders of this pile this day",
    stationMaintenanceAnalysis_00024: "abnormal rate analysis",
    stationMaintenanceAnalysis_00025: "vehicle VIN",
    stationMaintenanceAnalysis_00026: "license plate number",
    stationMaintenanceAnalysis_00027: "vehicle type",
    stationMaintenanceAnalysis_00028: "vehicle charging log",
    stationMaintenanceAnalysis_00029: "device name",
    stationMaintenanceAnalysis_00030: "equipment model",
    stationMaintenanceAnalysis_00031: "equipment serial number",
    stationMaintenanceAnalysis_00032: "abnormal rate of pile responsibility",
    stationMaintenanceAnalysis_00033: "abnormal rate of vehicle liability",
    stationMaintenanceAnalysis_00034: "view work order",
    stationMaintenanceAnalysis_10000: "abnormal rate of vehicle liability {num}%",
    stationMaintenanceAnalysis_10001: "vehicle responsibility exception code",
    stationMaintenanceAnalysis_10002: "no exception temporarily",
    stationMaintenanceAnalysis_10003: "equipment abnormality rate analysis",
    stationMaintenanceAnalysis_10004: "proportion of exception codes",
    stationMaintenanceAnalysis_10005: "exception code",
}