export default {
  userClass_00001: 'Die folgenden Klassifizierungskriterien können verwendet werden, um nach den einzelnen oder kombinierten Kriterien des Zielbenutzers zu filtern.' ,
  userClass_00002: 'Hilfe anzeigen',
  userClass_00003: 'Referenzbasis',
  userClass_00004: 'Bereich',
  userClass_00005: 'Zeit von Letzter bezahlter Gebührenvorgang bis heute',
  userClass_00006: 'Bitte wählen Sie einen Zeitbereich',
  userClass_00007: 'Zeit von Bezahlungszeit bis heutigen Datum',
  userClass_00008: 'bezahlte Gebühren von einzelnutzer ohne Gruppenmitgliedschaft Nutzer',
  userClass_00009: 'Kumulierte Anzahl von Vorgänge',
  userClass_00010: 'Bitte geben Sie eine natürliche Zahl ein',
  userClass_00011: 'Kumulierter gezahlter Betrag (EUR)',
  userClass_00012: 'Kumulierte Elektrizität (kWh)',
  userClass_00013: 'von Erste bezahlte Vorgang bis heute',
  userClass_00014: 'In',
  userClass_00015: 'An',
  userClass_00016: 'Ergebnisse generieren',
  userClass_00017: 'Bundesland',
  userClass_00018: 'Stadt',
  userClass_00019: 'Bitte geben Sie die vollständige Ladevorgang an',
  userClass_00020: 'Bitte geben Sie die vollständige Zahlungsfrist für den Vorgang an',
  userClass_00021: 'Kategorie Hilfe',
  userClass_00022: 'Neue Benutzer können die Bedingung für den Zahlungszeitpunkt der ersten Bestellung definieren, indem sie diese festlegen',
  userClass_00023: 'RFM-Modell',
  userClass_00024: 'Das RFM-Modell ist ein wichtiges Instrument zur Messung des Wertes der Nutzer und ihrer Fähigkeit, Gewinne zu erzielen. Es wird in vielen Anwendungen zur Verwaltung von Benutzerbeziehungen (CRM) verwendet. ',
  userClass_00025: 'Dieses Modell beschreibt den Wert eines einzelnen Nutzers in Form von R (Zeitpunkt des letzten Konsums), F (Gesamthäufigkeit des Konsums im Laufe der Zeit) und M (Gesamtbetrag des Konsums im Laufe der Zeit).', 
  userClass_00026: 'Das RFM-Modell kann verwendet werden, um Zielnutzer durch Nutzerklassifizierung zu finden. Entsprechendes Modell',
  userClass_00027: 'R (Zeitpunkt der letzten Zahlung)',
  userClass_00028: 'F (kumulative Anzahl der von einem einzelnen Nutzer, der nicht zu einer Gruppe gehört, über einen bestimmten Zeitraum bezahlten Vorgang)',
  userClass_00029: 'M (kumulative Bezahlte Betrag der von einem einzelnen Nutzer, der nicht zu einer Gruppe gehört, über einen bestimmten Zeitraum bezahlten Vorgang)',
  userClass_00030: 'Hoch',
  userClass_00031: 'Niedrig',
  userClass_00032: 'Hoher Wert',
  userClass_00033: 'initiative Kontakt notwendig',
  userClass_00034: 'Entwicklungsschwerpunkt',
  userClass_00035: 'auf die Beibehaltung konzentrieren',
  userClass_00036: 'Referenzdaten',
  userClass_00037: 'Einzelbenutzer, die nicht zu einer Gruppe gehören, und bezahlte Gebührenaufträge',
  userClass_00038: 'Kumulierter gezahlter Betrag',
  userClass_00039: 'Kumulierter Elektrizität',
  userClass_00040: 'Kategorie Ergebnis',
  userClass_00041: 'Aktuelle Anzahl der berechtigten Nutzer',
  userClass_00042: 'Benutzer anzeigen',
  userClass_00043: 'Prozent in Gesamtzahl in der Region',
  userClass_00044: 'Bitte geben Sie den Namen der Kategorie ein',
  userClass_00045: 'Als Kategorie speichern',
  userClass_00046: 'Nach dem Speichern als Kategorie aktualisiert das System automatisch die Liste der Kategorie-Benutzer in Echtzeit gemäß den Bedingungen der Kategorie',
  userClass_00047: 'Bitte geben Sie den Namen der Benutzerkategorie ein',
  userClass_00048: 'Bitte auswählen',
  userClass_00049: 'Alle',
}