export default {
  userClass_00001: '以下分类条件可根据目标用户的单个条件或组合条件筛选使用。',
  userClass_00002: '查看帮助',
  userClass_00003: '参考依据',
  userClass_00004: '区域',
  userClass_00005: '最近一次充电订单支付时间在距今日',
  userClass_00006: '请选择时间范围',
  userClass_00007: '充电订单支付时间在距今日',
  userClass_00008: '单个用户非团体且已支付的充电订单：',
  userClass_00009: '累计订单数',
  userClass_00010: '请输入自然数',
  userClass_00011: '累计实付金额（元）',
  userClass_00012: '累计充电量（度）',
  userClass_00013: '首次订单支付时间在距今日',
  userClass_00014: '在',
  userClass_00015: '至',
  userClass_00016: '生成结果',
  userClass_00017: '省',
  userClass_00018: '市',
  userClass_00019: '请填写完整充电订单',
  userClass_00020: '请填写完整订单支付时间',
  userClass_00021: '分类帮助',
  userClass_00022: '新用户可通过设置首次订单支付时间条件来定义',
  userClass_00023: 'RFM模型',
  userClass_00024: 'RFM模型是衡量用户价值和用户创利能力的重要工具和手段。在众多用户关系管理（CRM）中被广泛应用。',
  userClass_00025: '该模型通单个用户的R(最近一次消费时间)、F(一段时间内消费总体频率)、M(一段时间内消费总体金额)来描述该用户的价值状况。',
  userClass_00026: '通过用户分类可使用RFM模型来找到目标用户。相对应模型',
  userClass_00027: 'R（最近一次充电订单支付时间）',
  userClass_00028: 'F（一段时间内单个用户非团体且已支付的充电订单累计订单数）',
  userClass_00029: 'M（一段时间内单个用户非团体且已支付的充电订单累计实付金额）',
  userClass_00030: '高',
  userClass_00031: '低',
  userClass_00032: '高价值',
  userClass_00033: '需主动联系',
  userClass_00034: '需重点发展',
  userClass_00035: '需重点挽留',
  userClass_00036: '参考数据',
  userClass_00037: '单个用户非团体且已支付的充电订单',
  userClass_00038: '累计实付金额',
  userClass_00039: '累计电量',
  userClass_00040: '分类结果',
  userClass_00041: '当前符合条件的用户数',
  userClass_00042: '查看用户',
  userClass_00043: '占区域总用户数',
  userClass_00044: '请输入分类名称',
  userClass_00045: '保存为分类',
  userClass_00046: '保存为分类后，系统根据分类条件实时自动更新分类用户列表',
  userClass_00047: '请输入用户分类名',
  userClass_00048: '请选择',
  userClass_00049: '全部',
}