    export default {
        revenueMonitoring_00001: "储能供电",
        revenueMonitoring_00002: "电网供电",
        revenueMonitoring_00003: "车充电电量（kWh）",
        revenueMonitoring_00004: "能源收益趋势({unit})",
        revenueMonitoring_00005: "车充电能源总收入",
        revenueMonitoring_00006: "储能补电电量（kWh）",
        revenueMonitoring_00007: "分析",
        revenueMonitoring_00008: "车充电电网部分成本",
        revenueMonitoring_00009: "储能部分补电成本",
        revenueMonitoring_00010: "能源收益",
        revenueMonitoring_00011: "车充电使用电量",
        revenueMonitoring_00012: "电量 (kWh)",
        revenueMonitoring_00013: "能源总收入({unit})",
        revenueMonitoring_00014: "能源总收益",
        revenueMonitoring_00015: "电网峰谷用电情况",
        revenueMonitoring_00016: "峰谷时段用电情况",
        revenueMonitoring_00017: "高电价时段 (尖+峰)",
        revenueMonitoring_00018: "平电价时段",
        revenueMonitoring_00019: "谷电价时段",
        revenueMonitoring_00020: "电网",
        revenueMonitoring_00021: "储能",
        revenueMonitoring_00022: "日",
        revenueMonitoring_00023: "周",
        revenueMonitoring_00024: "月",
        revenueMonitoring_00025: "年",
        revenueMonitoring_00026: "所有时间",
        revenueMonitoring_00027: "卖电给电网收入",
        revenueMonitoring_00028: "场站自供电比例",
        revenueMonitoring_00029: "PV供电量",
        revenueMonitoring_00030: "电网交互总电量",
        revenueMonitoring_00031: "光伏供电",
        revenueMonitoring_00032: "光伏",
        revenueMonitoring_00033: "储能利用率",
        revenueMonitoring_00034: "补电利用率",
        revenueMonitoring_00035: "放电利用率",
        revenueMonitoring_00036: "储能补电电量",
        revenueMonitoring_00037: "储能放电电量",
        revenueMonitoring_00038: "储能装机容量",

        revenueMonitoring_00039: "尖时段用电",
        revenueMonitoring_00040: "峰时段用电",
        revenueMonitoring_00041: "平时段用电",
        revenueMonitoring_00042: "谷时段用电",
        revenueMonitoring_00043: "电网用电成本",
        revenueMonitoring_00044: "电网购电量",
        revenueMonitoring_00045: "电网卖电量",
        revenueMonitoring_00046: "其他",
        revenueMonitoring_00047: "Grid并网电量",
        revenueMonitoring_00048: "储能补电量",
        revenueMonitoring_00049: "EV充电量",


        
        

        

        


        energyOperationMonitoring_00037: "1月",
        energyOperationMonitoring_00038: "2月",
        energyOperationMonitoring_00039: "3月",
        energyOperationMonitoring_00040: "4月",
        energyOperationMonitoring_00041: "5月",
        energyOperationMonitoring_00042: "6月",
        energyOperationMonitoring_00043: "7月",
        energyOperationMonitoring_00044: "8月",
        energyOperationMonitoring_00045: "9月",
        energyOperationMonitoring_00046: "10月",
        energyOperationMonitoring_00047: "11月",
        energyOperationMonitoring_00048: "12月",

        energyOperationMonitoring_00049: "周一",
        energyOperationMonitoring_00050: "周二",
        energyOperationMonitoring_00051: "周三",
        energyOperationMonitoring_00052: "周四",
        energyOperationMonitoring_00053: "周五",
        energyOperationMonitoring_00054: "周六",
        energyOperationMonitoring_00055: "周日",

    
    } 