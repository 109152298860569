export default {
    cardRecord_00001: "Total amount of recharge({unit})",
    cardRecord_00002: "Recharge principal({0})",
    cardRecord_00003: "Recharge amount({0})",
    cardRecord_00004: "Top-up Record",
    cardRecord_00005: "Operator charging card management",
    cardRecord_00006: "Charging card group management",
    cardRecord_00007: "Top-up counts(times)",
    cardRecord_00008: "Top-up amount({0})",
    cardRecord_00009: "Money amount of gift({unit})",
    cardRecord_00010: "Download top-up record",
    cardRecord_00011: "Top-up date",
    cardRecord_00012: "Name",
    cardRecord_00013: "Top-up method",
    cardRecord_00014: "CPO",
    cardRecord_00015: "This operation will download the Excel file of the top-up records, do you want to continue?",
    cardRecord_00016: "Wechat official account",
    cardRecord_00017: "（Alipay）",
    cardRecord_00018: "（Wechat pay）",
    cardRecord_00019: "Platform",
    cardRecord_00020: "Online card group",
    cardRecord_00021: "Offline card group",
    cardRecord_00022: "Supercard group",
    cardRecord_00023: "to",
    cardRecord_00024: "Start date",
    cardRecord_00025: "Close date",
    cardRecord_00026: "Card number",
  }