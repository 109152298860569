export default {
    work_00001: '发起工单',
    work_00002: '请输入昵称、手机号、卡号、多个查询条件支持以逗号（，）、换行、空格、顿号（、）分割',
    work_00003: '发起',
    work_00004: '问题场站',
    work_00005: '站点名称',
    work_00006: '问题来源',
    work_00007: '工作人员上报',
    work_00008: '客户反馈',
    work_00009: '设备上报',
    work_00010: '其他',
    work_00011: '工单标题',
    work_00012: '相关设备',
    work_00013: '相关订单',
    work_00014: '相关用户',
    work_00015: '优先级',
    work_00016: '一般',
    work_00017: '紧急',
    work_00018: '非常紧急',
    work_00019: '问题描述',
    work_00020: '处理人',
    work_00021: '关注人',
    work_00022: '添加工单相关用户',
    work_00023: '清除',
    work_00024: '查询结果',
    work_00025: '已添加',
    work_00026: '添加',
    work_00027: '请选择分类',
    work_00028: '请选择问题场站',
    work_00029: '请选择优先级',
    work_00030: '请选择问题来源',
    work_00031: '请输入工单标题',
    work_00032: '请输入问题描述',
    work_00033: '请选择处理人',
    work_00034: '桩企',
    work_00035: '充电订单详情',
    work_00036: '设备详情',
    work_00037: '场站详情',
    work_00038: '全部',
    work_00039: '设备离线',
    work_00040: '设备故障',
    work_00041: '电量小于1度',
    work_00042: '充电时间超长',
    work_00043: '设备异常结束',
    work_00044: '车辆异常结束',
    work_00045: '请填写相关设备SN',
    work_00046: '请填写相关订单号',
    work_00047: '全部运营商',


    work_10000: '维保',
    work_10001: '选择设备',
    work_10002: '请先选择一个相关设备，可自动生成问题并填写故障信息。切换设备后会替换问题内容，如需保留请先复制内容。',
    work_10003: '全部型号',
    work_10004: '设备SN',
    work_10005: '场站',
    work_10006: '运营商',
    work_10007: '切换设备',
    work_10008: '添加问题',
    work_10009: '场站位置',
    work_10010: '入场须知',
    work_10011: '相关订单',
    work_10012: '选择订单',
    work_10013: '故障信息',
    work_10014: '故障代码',
    work_10015: '全部场站',
    work_10016: '订单编号',
    work_10017: '至少填写一个问题',
    work_10018: '请选择设备',
    work_10019: '请填写内容',





    bill_00023: "重置",
    bill_00024: "查询",
    bill_00025: "收入({0})",
    bill_00026: "订单金额({0})",
    bill_00027: "电费金额({0})",
    bill_00028: "服务费金额({0})",
    bill_00029: "延时费金额({0})",
    bill_00030: "充电电量({0})",
    bill_00031: "充电时间(分钟)",
    bill_00032: "充电次数",
    bill_00033: "充电口",
    bill_00034: "已充电量(度)",
    bill_00035: "充电时长",
    bill_00036: "天",
    bill_00037: "小时",
    bill_00038: "分钟",
    bill_00039: "订单详情",
    bill_00040: "微信用户",
    bill_00041: "已支付",
    bill_00042: "第三方支付",
    bill_00043: "充电中",
    bill_00044: "计费中",
    bill_00045: "待支付",
    bill_00046: "已暂停",
    bill_00047: "结束未拔枪",
    bill_00048: "延误计费中",
    bill_00049: "已取消",
    bill_00050: "已创建",
    bill_00051: "异常",
    bill_00052: "开始充电",
    bill_00053: "结束充电",
    bill_00054: "团体",
    bill_00071: "电量小于1度",
    bill_00072: "充电时间超长",
    bill_00073: "设备异常结束",
    bill_00074: "车辆异常结束",
    bill_00086: "请正确填写参数",
    bill_00101: "电量大于500度",
    bill_00102: "电量大于设备在充电时长内可充最大电量",

    detailDepot_00035: '实景图片',
    detailDepot_00036: '地址、街道、房屋名称',
    detailDepot_00037: '查 找',
    detailDepot_00038: '名 称',
    detailDepot_00039: '详细地址',
    detailDepot_00040: '当前位置',
    detailDepot_00041: '取 消',
    detailDepot_00042: '使用此位置',
    detailDepot_00043: '选择充电站位置坐标',
    detailDepot_00044: '您修改的地图位置与地址不吻合，误差在200米以上！',
    detailDepot_00045: "提 示",
    detailDepot_00046: "我知道了",
    detailDepot_00047: "地址未找到",
    detailDepot_00048: "高德地图发生未知错误",
}
