export default {
  diversionDetail_00001: '接入详情',
  diversionDetail_00002: '流量中心',
  diversionDetail_00003: '开放引流',
  diversionDetail_00004: '该流量方已兼容智充二维码，需要在充电桩详情中升级为固定二维码',
  diversionDetail_00005: '该流量方不兼容智充二维码，需要打印二维码粘贴使用',
  diversionDetail_00006: '请输入站点名称',
  diversionDetail_00007: '站点列表',
  diversionDetail_00008: '储备金管理',
  diversionDetail_00009: '全局折扣',
  diversionDetail_00010: '站点名称',
  diversionDetail_00011: '运营商',
  diversionDetail_00012: '站点状态',
  diversionDetail_00013: '已启用',
  diversionDetail_00014: '未启用',
  diversionDetail_00015: '充电桩',
  diversionDetail_00016: '交流',
  diversionDetail_00017: '直流',
  diversionDetail_00018: '对接入流量开放状态',
  diversionDetail_00019: '关闭开放',
  diversionDetail_00020: '同意开放',
  diversionDetail_00021: '操作',
  diversionDetail_00022: '批量导出二维码',
  diversionDetail_00023: '资费设置',
  diversionDetail_00024: '站点折扣',
  diversionDetail_00025: '启停设置',
  diversionDetail_00026: '运营车平台',
  diversionDetail_00027: '聚合平台',
  diversionDetail_00028: '整车厂平台',
  diversionDetail_00029: '政府平台',
  diversionDetail_00030: '地图平台',
  diversionDetail_00031: '政府',
  diversionDetail_00032: '免费',
  diversionDetail_00033: '直签',
  diversionDetail_00034: '智充总包',
  diversionDetail_00035: '接入详情-',
  diversionDetail_00036: '乘用车',
  diversionDetail_00037: '物流车',
  diversionDetail_00038: '中巴车',
  diversionDetail_00039: '大巴车',
  diversionDetail_00040: '电单车',
  diversionDetail_00041: '已开放-不启停',
  diversionDetail_00042: '已开放-可启停',
  diversionDetail_00043: '未开放',
  diversionDetail_00044: '已开放',
  diversionDetail_00045: '站点无启用的充电桩，请启用至少一个充电桩。',
  diversionDetail_00046: '站点信息中的',
  diversionDetail_00047: '国家代码、',
  diversionDetail_00048: '省市辖区编码、',
  diversionDetail_00049: '停车费、',
  diversionDetail_00050: '站点类型、',
  diversionDetail_00051: '建筑场所、',
  diversionDetail_00052: '车位数量、',
  diversionDetail_00053: '客服电话、',
  diversionDetail_00054: '实景图片、',
  diversionDetail_00055: '为空',
  diversionDetail_00056: '请将【站点详情】-【站点信息】-【状态信息】中“是否对外开放”设置为“开放”。',
  diversionDetail_00057: '充电站',
  diversionDetail_00058: '智充',
  diversionDetail_00059: '储备金充值历史',
  diversionDetail_00060: '第三方流量平台',
  diversionDetail_00061: '是否储备资金',
  diversionDetail_00062: '当前余额',
  diversionDetail_00063: '储备金额',
  diversionDetail_00064: '储备充值',
  diversionDetail_00065: '可以填负值冲账',
  diversionDetail_00066: '提醒余额',
  diversionDetail_00067: '余额小于0自动停止充电',
  diversionDetail_00068: '是',
  diversionDetail_00069: '否',
  diversionDetail_00070: '充值金额',
  diversionDetail_00071: '预充值时间',
  diversionDetail_00072: '返回',
  diversionDetail_00073: '修改成功',
  diversionDetail_00074: '请输入数字',
  diversionDetail_00075: '运营商给予智充的服务费折扣(%)',
  diversionDetail_00076: '单次充电总费用不打折（%）',
  diversionDetail_00077: '单次充电服务费打折（%）',
  diversionDetail_00078: '85表示8.5折，0表示全免',
  diversionDetail_00079: '单次充电按每度电优惠',
  diversionDetail_00080: '显示名称',
  diversionDetail_00081: '请输入显示名称，推荐格式：品牌名充电站（站点名称），站点名称中需要去除“品牌名”的关键词',
  diversionDetail_00082: '是否允许第三方启停桩',
  diversionDetail_00083: '只允许特定用户充电',
  diversionDetail_00084: '再想想',
  diversionDetail_00085: '我知道了',
  diversionDetail_00086: '去填写',
  diversionDetail_00087: '名称不能为空',
  diversionDetail_00088: '开放成功',
  diversionDetail_00089: '提示信息',
  diversionDetail_00090: '填写信息',
  diversionDetail_00091: '确定',
  diversionDetail_00092: '是否允许第三方启停桩',
  diversionDetail_00093: '直签资费设置',
  diversionDetail_00094: '如果资费标准有修改，在站点详情-资费标准中点击“推送资费”按钮，资费才可以生效！',
  diversionDetail_00095: '交流资费',
  diversionDetail_00096: '选择交流资费标准',
  diversionDetail_00097: '直流资费',
  diversionDetail_00098: '选择直流资费标准',
  diversionDetail_00099: '站点折扣设置',
  diversionDetail_00100: '添加站点折扣',
  diversionDetail_00101: '开始时间',
  diversionDetail_00102: '现在',
  diversionDetail_00103: '结束时间',
  diversionDetail_00104: '不限时',
  diversionDetail_00105: '优惠方式',
  diversionDetail_00106: '85表示8.5折',
  diversionDetail_00107: '每度电优惠(元)',
  diversionDetail_00108: '使用服务费折扣方式',
  diversionDetail_00109: '使用每度电优惠方式',
  diversionDetail_00110: '状态',
  diversionDetail_00111: '未开始',
  diversionDetail_00112: '已开始',
  diversionDetail_00113: '已生效',
  diversionDetail_00114: '未生效',
  diversionDetail_00115: '站点折扣历史',
  diversionDetail_00116: '请保存上一步操作！！',
  diversionDetail_00117: '开始时间不可为空',
  diversionDetail_00118: '结束时间不可为空',
  diversionDetail_00119: '优惠方式不可为空',
  diversionDetail_00120: '请输入小于100的正整数',
  diversionDetail_00121: '保存成功',
  diversionDetail_00122: '服务费折扣:{dis}%',
  diversionDetail_00123: '每度电优惠:{dis}元',
  diversionDetail_00124: '关闭开放给{name}站点信息，{name}将不会显示任何站点信息。',
  diversionDetail_00125: '确认关闭开放给{name}站点信息？',
  diversionDetail_00126: '推送设备状态',
  diversionDetail_00127: '推送设备状态失败，请重试！',
  diversionDetail_00128: '推送设备状态成功！',
  diversionDetail_00129: '推送实际电量',

  detailDepot_30011: "请完整填写表单",
}