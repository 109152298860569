export default {
    analyzeXcharge_00001: "整体利用率趋势分析",
    analyzeXcharge_00002: "站点利用率分析",
    analyzeXcharge_00003: "设备型号利用率分析",
    analyzeXcharge_00004: "高峰时刻分析",
    analyzeXcharge_00005: "平均功率分析",
    analyzeXcharge_00006: "加盟商利用率分析",
    analyzeXcharge_00007: "分析",
    analyzeXcharge_00008: "按电量分析",
    analyzeXcharge_00009: "按时长分析",
    analyzeXcharge_00010: "利用率 = 日充电量 / 日满负荷充电量<br/>日满负荷充电量 = 充电桩额定功率 * 24小时",
    analyzeXcharge_00011: "利用率 = 充电枪口日充电时长 / 24小时",
    analyzeXcharge_00012: "交流设备充电利用率（%）",
    analyzeXcharge_00013: "直流设备充电利用率（%）",
    analyzeXcharge_00014: "选择加盟商",
    analyzeXcharge_00015: "选择站点",
    analyzeXcharge_00016: "选择团体",
    analyzeXcharge_00017: "选择设备型号",
    analyzeXcharge_00018: "站点利用率对比",
    analyzeXcharge_00019: "站点利用率趋势",
    analyzeXcharge_00020: "设备型号利用率对比",
    analyzeXcharge_00021: "设备型号利用率趋势",
    analyzeXcharge_00022: "充电利用率",
    analyzeXcharge_00023: "加盟商利用率对比",
    analyzeXcharge_00024: "加盟商利用率趋势",
    analyzeXcharge_00025: "站点平均功率对比",
    analyzeXcharge_00026: "设备型号平均功率分析",
    analyzeXcharge_00027: "平均功率",
    analyzeXcharge_00028: "选择或输入充电站",
    analyzeXcharge_00029: "充电中枪口",
    analyzeXcharge_00030: "充电枪口繁忙率",
    analyzeXcharge_00031: "充电中枪口个数",
    analyzeXcharge_00032: "充电枪口繁忙率 %",
    analyzeXcharge_00033: "充电枪口繁忙率 = 当前充电中枪口数 / 总枪口数",
} 