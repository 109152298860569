export default {
  userAllocateRecord_00001: "Gruppename",
  userAllocateRecord_00002: "Zuordnungsdatensatz",
  userAllocateRecord_00003: "Grundinformation",
  userAllocateRecord_00004: "Zugewiesene Zeit",
  userAllocateRecord_00005: "Zuteilungsbetrag({0})",
  userAllocateRecord_00006: "Zugewiesene Nummer",
  userAllocateRecord_00007: "Vertriebsweg",
  userAllocateRecord_00008: "Zuweiser",
  userAllocateRecord_00009: "Öffentliches WeChat-Konto",
  userAllocateRecord_00010: "Plattform",
  userAllocateRecord_00011: "Webversionsverwaltung",
  userAllocateRecord_00012: "Vertriebsdetails herunterladen",
  userAllocateRecord_00013: "bis",
  userAllocateRecord_00014: "Startdatum",
  userAllocateRecord_00015: "Endedatum",
  userAllocateRecord_00016: "Aktion",
  userAllocateRecord_00017: "Einzelheiten",
  userAllocateRecord_00018: "herunterladen",
  userAllocateRecord_00019: "Aufnahmeliste",
  userAllocateRecord_00020: "Bitte wählen Sie ein Datum",
}