export default {
  wra_10000:'Zu diesem Zeitpunkt sind keine Daten verfügbar',
  wra_00000:'Kopie',
  wra_00001:'Finanzzentrum',
  wra_00002:'Gemeinsame Funktionen',
  wra_00003:'Nutzername/Mobiltelefonnummer',
  wra_00004:'Startdatum',
  wra_00005:'Enddatum',
  wra_00006:'Antragsstatus',
  wra_00007:'suchen',
  wra_00008:'zurücksetzen',

  wra_00009:'Kopie erfolgreich!',

  wra_00010:'Heute',
  wra_00011:'Gestern',
  wra_00012:'Vor 7 Tagen',
  wra_00013:'Vor 15 Tagen',
  wra_00014:'Vor 30 Tagen',

  wra_00015:'Alle',
  wra_00016:'Muss noch bestätigt werden',
  wra_00017:'erledigt',
  wra_00018:'Abgebrochen',
  wra_00019:'Noch zu vervollständigen',

  wra_00020:'',
  wra_00021:'AppID erforderlich für WeChat Merchant Enterprise Zahlung',

  wra_00022:'Erstattungsdaten exportieren',
  wra_00023:'Bei diesem Vorgang wird eine Excel-Datei mit den erstatteten Bestellungen heruntergeladen, möchten Sie fortfahren?',
  wra_00024: "Hinweise",
  wra_00025: "OK",
  wra_00026: "Abbrechen",
  wra_00027: "Download starten",
  wra_00028: "Download abbrechen",
  wra_00029: "Herunterladen der Massenerstattungsdatei",
  wra_00030: "Massenerstattungsdatei herunterladen (WeChat Pay Spezialhändler)'",

  wra_00031:'Spitzname',
  wra_00032:'Handynummer',

  wra_00033:'EUR',

  wra_00034:'Zu erstattender Betrag',
  wra_00035:'Erstattet',
  wra_00036:'nicht Erstattet',

  wra_00037:'Erstattung abgeschlossen',
  wra_00038:'Behandlung akzeptieren',
  wra_00039: 'Behandlung abbrechen',
  
  wra_00040:'Bildung von zu erstattende Betrag ',
  wra_00041:'Zu erstattender Betrag für WeChat-Zahlung',
  wra_00042:'Von Alipay zu erstattender Betrag',
  wra_00043: 'Erstattungsbetrag für Bestellungen ohne Aufladung',
  
  wra_00044:'Ergebnis der Erstattungsannahme',
  wra_00045:'System erstattet automatisch (WeChat-Zahlung)',
  wra_00046:'Automatische Systemrückerstattung (Alipay)',
  wra_00047: 'von Admin A manuelle Erstattung', 
  
  wra_00048:'Grund für die unvollständige Erstattung',
  wra_00049:'keine',

  wra_00050:'Haben Sie eine Erstattung auf der WeChat-Händlerplattform vorgenommen?',
  wra_00051:'ändern',
  wra_00052:'Operation erfolgreich, warte auf WeChat-Ergebnismeldung',
  wra_00053:'Warten auf WeChat-Ergebnisbenachrichtigung',
  wra_00054:'Bewerbung erfolgreich, warte auf WeChat-Ergebnisbenachrichtigung',
  wra_00055:'Die automatische Erstattung konnte aufgrund von {Ursache} nicht abgeschlossen werden',
  wra_00056:'Systemgrund',
  wra_00057:'Warten auf WeChat-Ergebnisbenachrichtigung',
  wra_00058:'Das Zertifikat ist abgelaufen und kann nicht automatisch erstattet werden',
  wra_00059:'Vorgang überfällig für automatische Erstattung',
  wra_00060:'Unzureichender Erstattungsbetrag für den Händler verfügbar',
  wra_00061:'Es wurde eine Teilerstattung vorgenommen, die nicht storniert werden kann',
  wra_00062:'Erfolgreich modifiziert',

  wra_00063:'Erstattung in Bearbeitung...',

  wra_00064:'Bitte wählen sie einen termin Von höchstens 31 tagen wieder'

}