export default {
  usergroup_00001: "团体名称",
  usergroup_00002: "基本信息",
  usergroup_00003: "团体名称",
  usergroup_00004: "支付方式",
  usergroup_00005: "最多未支付金额",
  usergroup_00006: "最多未支付单数",
  usergroup_00007: "集团账户信息",
  usergroup_00008: "余额",
  usergroup_00009: "申请发票",
  usergroup_00010: "开票明细",
  usergroup_00011: "充值记录",
  usergroup_00012: "账户充值",
  usergroup_00013: "余额分配",
  usergroup_00014: "分配记录",
  usergroup_00015: "{num}笔",
  usergroup_00016: "个人付费",
  usergroup_00017: "集团储值",
  usergroup_00018: "包含赠送{promotionBalance}{unit}",
  usergroup_00019: "提交",
  usergroup_00020: "团体一年内订单可申请发票",
  usergroup_00021: "发票信息（不包含赠送金额、优惠金额、用户组分配金额）",
  usergroup_00022: "发票类型",
  usergroup_00023: "增值税普通发票",
  usergroup_00024: "发票详情",
  usergroup_00025: "发票形式",
  usergroup_00026: "个人",
  usergroup_00027: "企业",
  usergroup_00028: "请选择发票形式",
  usergroup_00029: "发票抬头",
  usergroup_00030: "发票税号",
  usergroup_00031: "发票银行",
  usergroup_00032: "开户行账号",
  usergroup_00033: "公司电话",
  usergroup_00034: "请输入发票抬头（必填）",
  usergroup_00035: "请输入发票税号",
  usergroup_00036: "请输入开户行全称",
  usergroup_00037: "请输入开户行帐号",
  usergroup_00038: "请输入公司电话",
  usergroup_00039: "获取方式",
  usergroup_00040: "开票方式",
  usergroup_00041: "收件人",
  usergroup_00042: "手机号",
  usergroup_00043: "收件地址",
  usergroup_00044: "纸质发票",
  usergroup_00045: "电子发票",
  usergroup_00046: "请输入收件人姓名",
  usergroup_00047: "请输入手机号",
  usergroup_00048: "请输入详细地址(必填)",
  usergroup_00049: "申请发票成功",
  usergroup_00050: "开票失败",
  usergroup_00051: "请输入正确的手机号",
  usergroup_00052: "公司地址",
  usergroup_00053: "请输入详细地址",
  usergroup_00054: "邮箱",
  usergroup_00055: "请输入邮箱",
  usergroup_00056: "增值税普通发票",
  usergroup_00057: "您的发票申请已提交，我们会尽快为您邮寄纸质发票!",
  usergroup_00058: "您的发票申请已提交，我们会尽快将电子发票发到您的手机上!",
  usergroup_00059: "不满足最低开票金额",
  usergroup_00060: "此用户组还没有可开具发票的订单",
  usergroup_00061: "充值",
  usergroup_00062: "上一步",
  usergroup_00063: "支付完成",
  usergroup_00064: "当前余额",
  usergroup_00065: "充值金额",
  usergroup_00066: "{money}{unit}",
  usergroup_00067: "其他金额",
  usergroup_00068: "微信支付",
  usergroup_00069: "支付宝支付",
  usergroup_00070: "请输入充值金额",
  usergroup_00071: "选择支付方式",
  usergroup_00072: "不能输入负数",
  usergroup_00073: "请输入正确的充值金额",
  usergroup_00074: "充值成功",
  usergroup_00075: "您本次订单未完成！",
  usergroup_00076: "请输入正确的邮箱格式",
  usergroup_00077: "个人支付",
  usergroup_00078: "集团支付",
  usergroup_00079: "",
  usergroup_00080: "",
}