export default {
  onlineAnalysis_00001: "Online-analyse der geräte des ordens",
  onlineAnalysis_00002: "Angabe der uhrzeit",
  onlineAnalysis_00003: "bis",
  onlineAnalysis_00004: "Analyse",
  onlineAnalysis_00005: "Halten sie sie auf",
  onlineAnalysis_00006: "Online-werte =1 der abstand zwischen dem suchzeitraum und dem suchzeitraum im gleichen zeitraum ist länger",
  onlineAnalysis_00007: "Name des gerät",
  onlineAnalysis_00008: "Modell der ausrüstung",
  onlineAnalysis_00009: "Die seriennummer des laufwerks",
  onlineAnalysis_000010: "Online-werte",
  onlineAnalysis_000011: "betrieb",
  onlineAnalysis_000012: "Einzelheiten",
  onlineAnalysis_000013: "Logbücher",
}