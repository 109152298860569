<template lang="html">
  <el-dialog :title="title" :visible="visibleFlag" :lock-scroll="false" @close="close" width="500px">
    <div class="addbox">
      <div class="flex">
        <el-input type="textarea" v-model="note" maxlength=50 :autosize="{ minRows: 4, maxRows: 6}" id="noResize"></el-input>
      </div>
      <p class="count">{{noteCount}}/50</p>
      <div class="add-btn">
        <el-button type="primary" @click="add">{{$t('common_00217')}}</el-button>
        <el-button type="primary" @click="close">{{$t('common_00215')}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      note: "",
      noteCount: 0,
    };
  },
  props: ["visibleFlag", "noteData", "title"],
  watch: {
    note: function (newV) {
      this.noteCount = newV.length;
    },
    visibleFlag: function (newV) {
      this.note = this.noteData.note ? this.noteData.note : "";
    },
  },
  methods: {
    add() {
      this.$emit("confirm", this.note);
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.addbox {
  height: 150px;
  .flex {
    display: flex;
    height: 60px;
    align-items: center;
    margin-top: 20px;
    #noResize {
      width: 465px;
      margin-left: 30px;
      height: 100px;
      line-height: 1;
    }
  }
  .count {
    float: right;
    margin: 30px 35px 0 0;
  }
  & .add-btn {
    width: inherit;
    height: auto;
    text-align: center;
    margin-top: 50px !important;
    button {
      width: 120px;
    }
  }
}
</style>
