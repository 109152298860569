export default {
    occupancy_00001: "Invoice list",
    occupancy_00002: "Export orders",
    occupancy_00003: "Bill No",
    occupancy_00004: "Start date",
    occupancy_00005: "End date",
    occupancy_00006: "reset",
    occupancy_00007: "search",
    occupancy_00008: "Invoice status",
    occupancy_00009: "all",
    occupancy_00010: "Bill type",
    occupancy_00011: "Charging",
    occupancy_00012: "to be paid",
    occupancy_00013: "paid",
    occupancy_00014: "Pile end direct ground lock",
    occupancy_00015: "Platform direct ground lock",
    occupancy_00016: "Invoice amount（€）",
    occupancy_00017: "Number of occupancy invoice",
    occupancy_00018: "Occupancy duration(min)",
    occupancy_00019: "Charging pile - charging port",
    occupancy_00020: "Occupancy start time",
    occupancy_00021: "Occupancy end time",
    occupancy_00022: "Charge duration",
    occupancy_00023: "day",
    occupancy_00024: "hour",
    occupancy_00025: "minute",
    occupancy_00026: "Wechat users",
    occupancy_00027: "group",
    occupancy_00028: "Offline stored value card",
    occupancy_00029: "Online ID card",
    occupancy_00030: "Current bill amount",
    occupancy_00031: "Actual payment amount",
    occupancy_00032: "Bill details",
    occupancy_00033: "Tips",
    occupancy_00034: "This operation will download the excel file of the bill. Do you want to continue?",
    occupancy_00035: "confirm",
    occupancy_00036: "cancel",
    occupancy_00037: "Start downloading",
    occupancy_00038: "Cancel Download",
    occupancy_00039: "User nickname, mobile phone number and license plate number",
    occupancy_00040: "nickname",
    occupancy_00041: "phone",
    occupancy_00042: "plate number",
    occupancy_00043: "Total bill amount",
    bill_00059: "today",
    bill_00060: "7 days ago",
    bill_00061: "15 days ago",
    bill_00077: "yesterday",
    bill_00078: "30 days ago",
  }