export default {
    bill_00001: "Vorgänge",
    bill_00002: "Alle Ladevorgänge",
    bill_00003: "Zustand",
    bill_00004: "Alle",
    bill_00005: "Aufladen",
    bill_00006: "Zu zahlen",
    bill_00007: "Bezahlt / Dritter bezahlt",
    bill_00008: "abnormal",
    bill_00009: "Typ",
    bill_00010: "Wechat User",
    bill_00011: "Online-ID-Karte",
    bill_00012: "Prepaidkarte",
    bill_00013: "Start/Stopp-Karte",
    bill_00014: "Administrator",
    bill_00015: "Gruppe",
    bill_00016: "Drittanbieter",
    bill_00017: "Liste der Bestellungen",
    bill_00018: "Bestellung exportieren",
    bill_00019: "Vorgangsnummer",
    bill_00020: "Benutzer bestellen",
    bill_00021: "Bitte Benutzername eingeben",
    bill_00022: "Ausrüstung",
    bill_00023: "Reset",
    bill_00024: "suchen",
    bill_00025: "Einkommen({0})",
    bill_00026: "Betrag({0})",
    bill_00027: "Betrag der Elektrizität({0})",
    bill_00028: "Servicegebühr({0})",
    bill_00029: "Verzugsgebühr({0})",
    bill_00030: "Kapazität der Ladung({0})",
    bill_00031: "Zeit des Ladens(Minute)",
    bill_00032: "Anzahl Ladevorgänge",
    bill_00033: "Ladepunkt",
    bill_00034: "Volumen({0})",
    bill_00035: "Ladezeit",
    bill_00036: "Tag",
    bill_00037: "Stunde",
    bill_00038: "Minute",
    bill_00039: "Vorgangsdetails",
    bill_00040: "App-Nutzer",
    bill_00041: "beendet",
    bill_00042: "Dritte Partei",
    bill_00043: "Aufladen",
    bill_00044: "Abrechnung",
    bill_00045: "Zahlung ausstehend",
    bill_00046: "pausiert",
    bill_00047: "beendet & eingesteckt",
    bill_00048: "Verzugsgebühr wird berechnet",
    bill_00049: "Abgebrochen",
    bill_00050: "Erstellt",
    bill_00051: "Anormal",
    bill_00052: "Laden gestartet",
    bill_00053: "Laden beendet",
    bill_00054: "Gruppe",
    bill_00055: "Betrag",
    bill_00056: "zu zahlender Betrag",
    bill_00057: "Startdatum",
    bill_00058: "Enddatum",
    bill_00059: "heute",
    bill_00060: "vor 7 Tagen",
    bill_00061: "vor 15 Tagen",
    bill_00062: "Hinweis",
    bill_00063: "Dieser Vorgang lädt die Excel-Datei der Ladevorgänge herunter. Fortsetzen?",
    bill_00064: "Bestätigen",
    bill_00065: "Abbrechen",
    bill_00066: "Data is in preparation, please wait patiently. Please do not close or refresh the page during this period.",
    bill_00067: "Nutzername",
    bill_00068: "Tel-Nr.",
    bill_00069: "Kartennummer",
    bill_00070: "Kennzeichen",
    bill_00071: "Strommenge beträgt weniger als 1 kWh",
    bill_00072: "ungewöhnlich lange Ladezeit",
    bill_00073: "Gerät wurde unerwartet beendet",
    bill_00074: "Fahrzeug wurde unerwartet beendet",
    bill_00075: "Doppelte Waffenlieferung",
    bill_00076: "Gebühr für Belegung",
    bill_00077: "gestern",
    bill_00078: "vor 30 Tagen",
    bill_00079: "Prepaidkarte",
    bill_00080: "Start/Stopp-Karte",
    bill_00081: "ID-Karte",
    bill_00082: "Administrator",
    bill_00083: "Daten-Backup",
    bill_00084: "POS-Benutzer",
    bill_00085: "Währungseinheit",
    bill_00086: "Benutzer dieser bestellung",
    bill_00087: "Benutzername",
    bill_00088: "Das handy",
    bill_00089: "Die nummer",
    bill_00090: "nummernschilder",
    bill_00091: "Anschließen und aufladen",
    bill_00101: "Ladung über 500 'ne halbe tonne",
    bill_00102: "Maximale ladung der anlage im lauf der aufgeladenen zeit",
    bill_00103: "Geänderter Bestellbetrag({0})",
    bill_00104: "Rabattbetrag({0})",
}