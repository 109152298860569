export default {
    orderMangement_00001: 'Ticket management',
    orderMangement_00002: "We will notify the handlers and followers of status changes of the ticket on XCHARGE's official WeChat account. '",
    orderMangement_00003: "Please use WeChat to scan the QR code of the official account on the left, and follow XCHARGE's official WeChat account. '",
    orderMangement_00004: 'In the function menu below the official account, find and open the "Smart Maintenance" MiniApp. Log in to the "Smart Maintenance" MiniApp with your platform account.',
    orderMangement_00005: 'You can receive ticket notifications for the corresponding account through the MiniApp or the "Smart Charge" official account. ',
    orderMangement_00006: 'You can receive the maintenance notification of the corresponding account through the MiniApp or WeChat service notification bar. ',
    orderMangement_00007: 'Tickets initiated by me',
    orderMangement_00008: 'Tickets processed by me',
    orderMangement_00009: 'Tickets followed by me',
    orderMangement_00010: 'Unclosed tickets',
    orderMangement_00011: 'All tickets',
    orderMangement_00012: 'Initiate ticket',
    orderMangement_00013: 'Issue description keywords',
    orderMangement_00014: 'Station name',
    orderMangement_00015: 'All',
    orderMangement_00016: 'Top category',
    orderMangement_11116: 'Secondary category',
    orderMangement_00017: 'Ticket number',
    orderMangement_00018: 'All manufacturers' ,
    orderMangement_00019: 'All models',
    orderMangement_00020: 'Pending tickets',
    orderMangement_00021: 'Processing tickets',
    orderMangement_00022: 'Resolved tickets',
    orderMangement_00023: 'Closed tickets',
    orderMangement_00024: 'List of tickets',
    orderMangement_00025: 'Export tickets',
    orderMangement_00026: 'Tickets number',
    orderMangement_00027: 'Tickets title',
    orderMangement_00028: 'Categories',
    orderMangement_00029: 'Priority',
    orderMangement_00030: 'Status',
    orderMangement_00031: 'Station with issue',
    orderMangement_00032: 'Processor',
    orderMangement_00033: 'Initiator',
    orderMangement_00034: 'Initiate time',
    orderMangement_00035: 'Update time',
    orderMangement_00036: 'Issue source',
    orderMangement_00037: 'Operation',
    orderMangement_00038: 'Reject',
    orderMangement_00039: 'Resolved',
    orderMangement_00040: 'Accept',
    orderMangement_00041: 'Transfer',
    orderMangement_00042: 'Close',
    orderMangement_00043: 'Add to knowledge bank',
    orderMangement_00044: 'Details',
    orderMangement_00045: 'Very urgent',
    orderMangement_00046: 'Urgent',
    orderMangement_00047: 'General',
    orderMangement_00048: 'Pending',
    orderMangement_00049: 'Processing',
    orderMangement_00050: 'Processed',
    orderMangement_00051: 'Closed',
    orderMangement_00052: 'Remarks',
    orderMangement_00053: 'Please enter the content',
    orderMangement_00054: 'Solution',
    orderMangement_00055: 'Transfer processor',
    orderMangement_00056: 'Accept ticket',
    orderMangement_00057: 'Whether to accept the ticket?',
    orderMangement_00058: 'Confirm accept',
    orderMangement_00059: 'Transfer ticket',
    orderMangement_00060: 'Confirm transfer',
    orderMangement_00061: 'Reasons for transfer',
    orderMangement_00062: 'Close ticket',
    orderMangement_00063: 'Are you sure to close the ticket?',
    orderMangement_00064: 'Confirm',
    orderMangement_00065: 'Close reason',
    orderMangement_00066: 'Reject ticket',
    orderMangement_00067: 'Are you sure to reject the ticket?',
    orderMangement_00068: 'Confirm reject',
    orderMangement_00069: 'Return reason',
    orderMangement_00070: 'The ticket has been resolved',
    orderMangement_00071: 'Do you confirm that the ticket has been resolved?',
    orderMangement_00072: 'Confirmation resolved',
    orderMangement_00073: 'Please fill in completely',
    orderMangement_00074: 'Operation succeed',
    orderMangement_00075: 'Staff report',
    orderMangement_00076: 'Customer report',
    orderMangement_00077: 'Charger report',
    orderMangement_00078: 'Auto trigger',
    orderMangement_00079: 'Other',
    orderMangement_00080: 'Tickets priority',
    orderMangement_00081: 'Tickets status',
    orderMangement_00082: 'Become a follower',
    orderMangement_00083: 'Charger manufacturer',
    orderMangement_00084: 'We will notify the ticket through the mailbox bound by the initiator, processor and follower',
    orderMangement_00085: 'Replacement parts code or replacement part name',
    orderMangement_00086: 'Completion Confirmed',
    orderMangement_00087: 'Add Replacement Part',
    orderMangement_00088: 'All CPOs',
    orderMangement_00089: 'All Stations',
    orderMangement_00090: 'CPO Name',
    orderMangement_00091: 'Charger Manufacturer',
    orderMangement_00092: 'We will notify the work order through the mailbox bound by the initiator, processor and follower',
    orderMangement_00093: 'Material Name',
    orderMangement_00094: 'Material Number',
    orderMangement_00095: 'Material SN',
    orderMangement_00096: 'Please enter the Material SN used, one per line',
    orderMangement_10000: 'Charger SN',
    orderMangement_10001: 'Current Processor',
    //工单新加
    work_00058: 'Ticket Num',
    work_00059: 'Description of action',
    work_00060: 'Historical actions ',
    work_10006: 'Issue Description',
    work_10007: 'Another Charger',
    work_10008: 'Add a question',
    work_10009: 'Station Address',
    work_10010: 'Admission Notice',
    work_10011: 'Related Transaction',
    work_10012: 'Select Transaction',
    work_10013: 'Error Information',
    work_10014: 'Error Code',
    work_10015: 'Stations',
    work_10016: 'Transactions ID',
    work_10017: 'Transactions ID',
    work_10020: 'Please process all issues',
    work_00007: 'Ticket Num',
    work_00008: 'Issue station',
    work_00009: 'Issue Site Brand and Model',
    work_00010: 'Issue Source',
    work_00011: 'Initiator',
    work_00012: 'Initiate Time',
    work_00013: 'Update Time',
    work_00014: ',Current Processor',
    work_00016: 'General',
    work_00017: 'Urgent',
    work_00018: 'Very urgent',
    work_00050: 'Mobile：{mobile}',
    work_00051: 'Receive notifications',
}