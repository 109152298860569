export default {
    login_00001: "Login CPO platform",
    login_00002: "Enter the username",
    login_00003: "Enter the password",
    login_00004: "Log in",
    login_00005: "User name and password cannot be blank",
    login_00006: "· Operation",
    login_00007: "I have already registered, now",
    login_00008: "Login",
    registerEmail_00001: "Register",
    registerEmail_00002: "Franchised CPO",
    registerEmail_00003: "Mailbox authentication",
    registerEmail_00004: "input registration information",
    registerEmail_00005: "Find charging operator",
    registerEmail_00006: "search",
    registerEmail_00007: "Please enter the invitation code sent to you by the operator",
    registerEmail_00008: "Operator name",
    registerEmail_00009: "Settlement currency",
    registerEmail_00010: "Join the operator",
    registerEmail_00011: "Join the charging operator Alliance",
    registerEmail_00012: "Fill in the mailbox",
    registerEmail_00013: "We have sent an email to {email} you filled in. Please click the verification link in the email to complete the verification and fill in the registration information",
    registerEmail_00014: "Contact name | what should I call you",
    registerEmail_00015: "Please enter your name",
    registerEmail_00016: "Operator name | name viewed by charging user",
    registerEmail_00017: "No more than {0} words",
    registerEmail_00018: "Login account|such as:XCharge",
    registerEmail_00019: "use letters and numbers",
    registerEmail_00020: "Login password | Contains at least 6 characters, including at least 1 digit, 1 letter, and 1 special character",
    registerEmail_00021: "Confirm that the password is consistent with the login password",
    registerEmail_00022: "Mobile number verification",
    registerEmail_00023: "Binding WeChat official account",
    registerEmail_00024: "Chinese is recommended",
    registerEmail_00025: "Please enter the operator name",
    registerEmail_00026: "No qualified operators were found",
    registerEmail_00027: "Wechat number",
    registerEmail_00028: "Invitation code used",
    registerEmail_00029: "Invitation code does not exist",
    registerEmail_00030: "Mailbox cannot be empty",
    registerEmail_00031: "Please enter your email address so that we can contact you",
    registerEmail_00032: "Please fill in the correct email address",
    registerEmail_00033: "operation failed",
    registerEmail_00034: "Server response timeout",
    registerEmail_00035: "Please get the official account first.",
    registerEmail_10036: "Operator platform",
    registerEmail_10037: "Please select a language",
    registerEmail_10038: "I've registered and sign in now",
    registerEmail_10039: "Login",
    registerEmail_10040: "Login to the operator platform",
    registerEmail_10041: "Login user group management platform",
    registerEmail_10042: "Set successfully",
    registerEmail_10043: "Currency type",
    registerEmail_10044: "Please enter the password again",
    registerEmail_10045: "The two passwords are inconsistent",
    registerEmail_10046: "Please enter the verification code",
    registerEmail_10047: "Please enter a numeric value",
    registerEmail_10048: "Please enter 4 digits",
    registerEmail_10049: "Please enter the correct contact name",
    registerEmail_10050: "The account number format is a combination of numbers and letters",
    registerEmail_10051: "The password is a combination of letters or numbers",
    registerEmail_10052: "Please enter contact name",
    registerEmail_10053: "Please enter the operator name",
    registerEmail_10054: "The name cannot exceed {0} characters",
    registerEmail_10055: "Please enter the account number",
    registerEmail_10056: "Please input a password",
    registerEmail_10057: "The length is between 6 and 20 characters",
    registerEmail_10058: "Please read the privacy policy carefully first to confirm whether you agree!",
    registerEmail_10059: "Please enter your mobile phone number",
    registerEmail_10060: "Please enter the correct mobile phone number",
    // ******
    registerEmail_20053: "currency",
    registerEmail_20054: "Transaction currency seen by charging user",
    registerEmail_20055: "Please select",
    registerEmail_20056: "Please fill in the correct email address and open the link in the email for verification.",
    registerEmail_20057: "Please fill in all the information on the right to complete the registration.",
    // ****2022/1/4
    registerEmail_30058: "Please select currency",
    registerEmail_30059: "Please enter again",
    registerEmail_20056: "Please fill in the correct email address and open the link in the email for verification.",
    registerEmail_20057: "Please fill in all the information on the right to complete the registration.",
    registerEmail_20051: "I have read and agreed",
    registerEmail_20052: "smart charging operator platform privacy policy",
    // 注册码
    registerEmail_21000: "Registration code|If you need registration code, please contact us：4008770227",
    registerEmail_21001: "Please enter the registration code",
    registerEmail_21002: "Registration code error",
    registerEmail_21003: "Next",
    registerEmail_21004: "Registration code",
    registerEmail_21005: "If you need registration code, please contact us.",
    registerEmail_21006: "Please set a password",
  }