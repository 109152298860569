export default {
  powerMaint_00001: '需电源模块保养设备',
  powerMaint_00002: '台！',
  powerMaint_00003: '电源模块需保养计时提示：',
  powerMaint_00004: '重置时间起累计充电时长',
  powerMaint_00005: '重置时间：',
  powerMaint_00006: '{hours}小时',
  powerMaint_00007: '正常',
  powerMaint_00008: '故障',
  powerMaint_00009: '您确定已对此充电桩{0}完成此次保养？点击确定后，{0}需要保养提示重新计时。',
  powerMaint_00010: '提示',
  powerMaint_00011: '确定',
  powerMaint_00012: '取消',
  powerMaint_00013: '成功',
  // ******
  powerMaint_10000: '需保养设备',
  powerMaint_10001: '点亮时间',
  powerMaint_10002: '已使用',
  powerMaint_10003: '小时',
  powerMaint_10004: '次',
  powerMaint_10005: '保养计时提示阀值：',
  powerMaint_10006: '重置时间起累计使用时长大于等于',
}