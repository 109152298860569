export default {
    stationRangeManage_00001: "管理场站范围",
    stationRangeManage_00002: "账号",
    stationRangeManage_00003: "充电站范围",
    stationRangeManage_00004: "充电站名称",
    stationRangeManage_00005: "操作",
    stationRangeManage_00006: "添加充电站",
    stationRangeManage_00007: "操作",
    stationRangeManage_00008: "站点详情",
    stationRangeManage_000024: "操作成功",
    stationRangeManage_000025: "请输入充电站名称",
    stationRangeManage_000026: "添加充电站成功",
    stationRangeManage_000027: "已选充电站",
    stationRangeManage_000028: "删除成功",
    stationRangeManage_000029: "您确定要删除吗?",
    stationRangeManage_000030: "已取消删除",
    
}