export default {
    homePage_00001: '充电次数',
    homePage_00002: '订单金额 ({type})',
    homePage_00003: '服务费金额 ({type})',
    homePage_00004: '充电电量 ({type})',
    homePage_00005: '减少碳排放CO₂ (kg)',
    homePage_00006: '充电用户数 (个)',
    homePage_00007: '待办事项',
    homePage_00008: '设备状态',
    homePage_00009: '常用功能',
    homePage_00010: '经营建议',
    homePage_00011: '昨日利用率',
    homePage_00012: '昨日用户排行',
    homePage_00013: '营收趋势',
    homePage_00014: '电量趋势',
    homePage_00015: '充电时长趋势',
    homePage_00016: '充电用户数趋势',
    homePage_00017: '今日',
    homePage_00018: '昨日',
    homePage_00019: '环比前日',
    homePage_00020: '退款申请',
    homePage_00021: '个人发票申请',
    homePage_00022: '团体发票申请',
    homePage_00023: '缴费账单',
    homePage_00024: '场站数量',
    homePage_00025: '个',
    homePage_00026: '直流充电口',
    homePage_00027: '交流充电口',
    homePage_00028: '空闲充电口',
    homePage_00029: '使用充电口',
    homePage_00030: '离线/故障充电口',
    homePage_00031: '实时监控',
    homePage_00032: '用户管理',
    homePage_00033: '充电订单',
    homePage_00034: '收入分析',
    homePage_00035: '充值赠送',
    homePage_00036: '异常订单',
    homePage_00037: '企业管理',
    homePage_00038: '政府平台',
    homePage_00039: '如何提升场站利用效率？',
    homePage_00040: '领取活动',
    homePage_00041: '用户会员等级设置',
    homePage_00042: '如何获取新用户？',
    homePage_00043: '如何使用平台？',
    homePage_00044: '交流',
    homePage_00045: '直流',
    homePage_00046: '设置',
    homePage_00047: '取 消',
    homePage_00048: '确 定',
    homePage_00049: '金额',
    homePage_00050: '电量',
    homePage_00051: '最近7日',
    homePage_00052: '最近30日',
    homePage_00053: '京都充电站',
    homePage_00054: '直流充电口',
    homePage_00055: '充电用户数',
    homePage_00056: '首次用户数',
    homePage_00057: '电费({type})',
    homePage_00058: '订单金额({type})',
    homePage_00059: '服务费({type})',
    homePage_00060: '充电时长',
    homePage_00061: '交流充电口',
    homePage_00062: '导流服务',
    homePage_00063: '邀请好友',
    homePage_00064: '设备广告投放',
    homePage_00065: '集团团体',
    homePage_00066: '首页概览',

    homePage_00067: '此功能暂未开放，敬请期待',
    homePage_00068: '青年路站',
    homePage_00069: '太阳水岸',
    homePage_00070: '幸福中路',
    homePage_00071: '东方社区',

    homePage_00072: '曹操出行',
    homePage_00073: '滴滴出行',
    homePage_00074: '嘀嗒出行',
    homePage_00075: '昆凌',
    homePage_00076: '公园世家',
    homePage_00077: '周润发',
    homePage_00078: '周杰伦',
    homePage_00079: '北京老炮',

    homePage_00080: '元',
    homePage_00081: '欢迎{name}!',
    homePage_00082: '用户会员等级',
    homePage_00083: '导流服务',
    homePage_00084: '邀请好友',
    homePage_00085: '收入分析',
    homePage_00086: '用户分析',
    homePage_00087: '充电实时监控',
    homePage_00088: '故障处理',

    homePage_00089: '电费金额 ({type})',
    homePage_00090: '查看货币',
    homePage_00097: '您的平台版本不包含此功能，请升级为高级版或联系商务',
    homePage_00091: '次',
    homePage_00092: '订单金额',
    homePage_00093: '服务费金额',
    homePage_00094: '充电电量',
    homePage_00095: '减少碳排放CO₂',
    homePage_00096: '电费金额',


    

    


}