export default {
    errorLog_00001: '充电站',
    errorLog_00002: '设备型号',
    errorLog_00003: '开始时间',
    errorLog_00004: '结束时间',
    errorLog_00005: '至',
    errorLog_00006: '请输入设备序列号',
    errorLog_00007: '上报时间',
    errorLog_00008: '日志分类',
    errorLog_00009: '全部',
    errorLog_00010: '充电桩故障',
    errorLog_00011: '充电桩异常',
    errorLog_00012: '离线',
    errorLog_00013: '请输入故障异常代码',
    errorLog_00014: '故障异常描述',
    errorLog_00015: '故障异常代码',
    errorLog_00016: '补充描述',
    errorLog_00017: '充电站',
    errorLog_00018: '运营商',
    errorLog_00019: '恢复时间',
    errorLog_00020: '已恢复',
    errorLog_00021: '未恢复',
    errorLog_00022: '是否恢复',
    errorLog_00023: '未恢复',
    errorLog_00024: '已恢复',
    errorLog_00025: '充电桩故障——桩无法正常使用，充电桩异常——导致结束充电，桩可以继续使用',
    errorLog_00026: '储能故障',
}
