export default {
  knowledge_00001: '知识库',
  knowledge_00002: '问题描述关键词',
  knowledge_00003: '全部厂商设备',
  knowledge_00004: '全部型号',
  knowledge_00005: '列表',
  knowledge_00006: '新建知识',
  knowledge_00007: '知识标题',
  knowledge_00008: '分类',
  knowledge_00009: '相关设备',
  knowledge_00010: '',
  knowledge_00011: '',
  knowledge_00012: '',
  knowledge_00013: '',
  knowledge_00014: '工单二级分类',
  knowledge_00015: '全部',
  knowledge_00016: '工单分类',
  knowledge_00017: '知识分类',
}