<template>
  <div :style="`height:${height?height:'330'}px;`" :id="`chart${idName}`"></div>
</template>
<script>
export default {
  props: ["chartOptions", "idName", "height"],
  data() {
    return {
      myChart: null,
    };
  },
  watch: {
    chartOptions: {
      handler(val) {
        this.$nextTick(() => {
          if (val) this.myChart.setOption(val);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    let echarts = require("echarts");
    this.myChart = echarts.init(document.getElementById(`chart${this.idName}`));
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
};
</script>
<style lang="scss" scoped>
</style>