export default {
  orderTriggerOpt_00001: '新建触发器',
  orderTriggerOpt_00002: '触发器名称',
  orderTriggerOpt_00003: '触发器是否启用',
  orderTriggerOpt_00004: '全部',
  orderTriggerOpt_00005: '启用',
  orderTriggerOpt_00006: '运营类触发器',
  orderTriggerOpt_00007: '设备类触发器',
  orderTriggerOpt_00008: '触发器工单类别',
  orderTriggerOpt_00009: '触发条件',
  orderTriggerOpt_00010: '工单处理人',
  orderTriggerOpt_00011: '关注人',
  orderTriggerOpt_00012: '工单通知时间',
  orderTriggerOpt_00013: '编辑触发器',
  orderTriggerOpt_00014: '当设备上报设备故障或者设备异常时，如果触发器设置的时间间隔后仍未恢复正常，自动发起工单排障。（注意：部分设备故障会自动恢复，如果时间间隔设置太短容易产生误报工单）',
  orderTriggerOpt_00015: '全部',
  orderTriggerOpt_00016: '工单分类',
  orderTriggerOpt_00017: '设备序列号',
  orderTriggerOpt_00018: '全部厂商设备',
  orderTriggerOpt_00019: '全部型号',
  orderTriggerOpt_00020: '待处理工单',
  orderTriggerOpt_00021: '处理中工单',
  orderTriggerOpt_00022: '已解决工单',
  orderTriggerOpt_00023: '已关闭工单',
  orderTriggerOpt_00024: '工单列表',
  orderTriggerOpt_00025: '导出工单',
  orderTriggerOpt_00026: '工单编号',
  orderTriggerOpt_00027: '工单标题',
  orderTriggerOpt_00028: '分类',
  orderTriggerOpt_00029: '优先级',
  orderTriggerOpt_00030: '状态',
  orderTriggerOpt_00031: '问题场站',
  orderTriggerOpt_00032: '处理人',
  orderTriggerOpt_00033: '发起人',
  orderTriggerOpt_00034: '发起时间',
  orderTriggerOpt_00035: '更新时间',
  orderTriggerOpt_00036: '问题来源',
  orderTriggerOpt_00037: '操作',
  orderTriggerOpt_00038: '退回工单',
  orderTriggerOpt_00039: '已处理',
  orderTriggerOpt_00040: '接单',
  orderTriggerOpt_00041: '转派工单',
  orderTriggerOpt_00042: '关闭工单',
  orderTriggerOpt_00043: '加入知识库',
  orderTriggerOpt_00044: '详情',
  orderTriggerOpt_00045: '非常紧急',
  orderTriggerOpt_00046: '紧急',
  orderTriggerOpt_00047: '一般',
  orderTriggerOpt_00048: '待处理',
  orderTriggerOpt_00049: '处理中',
  orderTriggerOpt_00050: '已处理',
  orderTriggerOpt_00051: '已关闭',
  orderTriggerOpt_00052: '备注',
  orderTriggerOpt_00053: '请输入内容',
  orderTriggerOpt_00054: '解决方案',
  orderTriggerOpt_00055: '转派处理人',
  orderTriggerOpt_00056: '接单',
  orderTriggerOpt_00057: '是否确认接单',
  orderTriggerOpt_00058: '确认接单',
  orderTriggerOpt_00059: '转派工单',
  orderTriggerOpt_00060: '确认转派',
  orderTriggerOpt_00061: '转派原因',
  orderTriggerOpt_00062: '关闭工单',
  orderTriggerOpt_00063: '是否确认关闭工单',
  orderTriggerOpt_00064: '确认关闭',
  orderTriggerOpt_00065: '关闭原因',
  orderTriggerOpt_00066: '退回工单',
  orderTriggerOpt_00067: '是否确认退回工单',
  orderTriggerOpt_00068: '确认退回',
  orderTriggerOpt_00069: '退回原因',
  orderTriggerOpt_00070: '已解决工单',
  orderTriggerOpt_00071: '是否确认已解决工单',
  orderTriggerOpt_00072: '确认已解决',
  orderTriggerOpt_00073: '请填写完整',
  orderTriggerOpt_00074: '操作成功',
  orderTriggerOpt_00075: '达到',
  orderTriggerOpt_00076: '保存成功',
  orderTriggerOpt_00077: '站点名称',
  orderTriggerOpt_00078: '站点状态',
  orderTriggerOpt_00079: '添加站点',
  orderTriggerOpt_00080: '站点触发条件',
  orderTriggerOpt_00081: '连续',
  orderTriggerOpt_00082: '天',
  orderTriggerOpt_00083: '超过',
  orderTriggerOpt_00084: '充电口无订单',
  orderTriggerOpt_00085: '请选择站点',
  orderTriggerOpt_00086: '请输入站点触发条件',
  orderTriggerOpt_00087: '批量移除',
  orderTriggerOpt_00088: '请填写完整',
  orderTriggerOpt_00089: '单异常', 
  orderTriggerOpt_00090: '设备上报故障/ 异常后',
  orderTriggerOpt_00091: '问题描述',
  orderTriggerOpt_00092: '异常订单统计-充电口',
  orderTriggerOpt_00093: '异常订单统计-用户',
  orderTriggerOpt_00094: '呆滞桩',
  orderTriggerOpt_00095: '设备类触发器',
  orderTriggerOpt_00096: '已选择',
  orderTriggerOpt_00097: '输入≥0的整数',
  orderTriggerOpt_00098: '触发器包含故障码',

  orderTriggerOpt_00099: "触发频次",
  orderTriggerOpt_000100: "每",
  orderTriggerOpt_000101: "日",
  orderTriggerOpt_000102: "每台设备每种故障只报",
  orderTriggerOpt_000103: "每台设备所有故障只报",
  orderTriggerOpt_000104: "次",
  orderTriggerOpt_000105: "默认",

  orderTriggerList_00001:"充电桩离线",
  orderTriggerList_00002:"急停按钮被按下",
  orderTriggerList_00003:"电源模块报警",
  orderTriggerList_00004:"电磁锁上锁/解锁操作失败",
  orderTriggerList_00005:"桩企",
  orderTriggerList_00006:"触发器设置",
  orderTriggerList_00007:"触发器模板",

  



  
}