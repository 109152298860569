export default {
  privilegedMember_00001: '特权会员名称',
  privilegedMember_00002: '会员列表',
  privilegedMember_00003: '新建特权会员',
  privilegedMember_00004: '费用折扣',
  privilegedMember_00005: '会员档位',
  privilegedMember_00006: '可用场站',
  privilegedMember_00007: '状态',
  privilegedMember_00008: '上架',
  privilegedMember_00009: '下架',
  privilegedMember_00010: '全部场站',
  privilegedMember_00011: '自营场站',
  privilegedMember_00012: '自定义场站',
  privilegedMember_00013: '删除后不可恢复',
  privilegedMember_00014: '用户获取生效之日起 {key} 日&nbsp;&nbsp;&nbsp;&nbsp;{price}{currencyType}',
  privilegedMember_00015: '{type}{val}',
  privilegedMember_00016: '电费和服务费',
  privilegedMember_00017: '充电服务费',
  privilegedMember_00018: '全免',
  privilegedMember_00019: '打 {0} 折',
  privilegedMember_00020: '开通特权会员启用',
  privilegedMember_00021: '不打折',
  privilegedMember_00022: '是否启用卡券优惠',
  privilegedMember_00023: '1.如设置为启用卡券优惠，则特权会员用户支付订单时，可以使用卡券；如设置为不启用卡券优惠，特权会员用户支付订单时不能使用卡券。',
  privilegedMember_00024: '2.支付订单时优先使用特权会员特权，使用特权会员特权后的账单金额再根据卡券是否满足条件选择使用的卡券。',
  privilegedMember_00025: '例：特权会员用户此次充电费用100元(含电费70元、服务费30元)，并且特权会员未到期无限制，可享受特权(单次充电服务费8.5折)，且有1张卡券(单次充电服务费满足20元服务费9折)，则使用特权用户特权后后，账单金额为95.5元(含电费70元，服务费25.5元(30*85%))，满足卡券(单次充电服务费满足20元服务费9折)。则使用卡券后，账单金额为92.95元(含电费70元+服务费22.95元(25.5*90%))，最终此次需实际支付92.95元。',
  privilegedMember_00026: '复制购买入口链接',
  privilegedMember_00027: '外发专用链接',
  privilegedMember_00028: '复制链接',
  privilegedMember_00029: '复制外链成功',
  privilegedMember_00030: '查看用户',
}