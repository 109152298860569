export default {
    joinOperator_00001: "Franchise einladen",
    joinOperator_00002: "Einladung erstellen",
    joinOperator_00003: "Einladung versendet",
    joinOperator_00004: "Anfragen",
    joinOperator_00005: "Einladungscode generieren",
    joinOperator_00006: "Gäste bearbeiten",
    joinOperator_00007: "Einladungscode/Gast",
    joinOperator_00008: "Einladungscode",
    joinOperator_00009: "Bauzeit",
    joinOperator_000010: "Gast",
    joinOperator_000011: "Zustand",
    joinOperator_000012: "arbeiten",
    joinOperator_000013: "Bitte Gäste eintragen",
    joinOperator_000014: "Erforderlich, nicht mehr als 20 Zeichen",
    joinOperator_000015: "generieren",
    joinOperator_000016: "Status",
    joinOperator_000017: "alle",
    joinOperator_000018: "Ungebraucht",
    joinOperator_000019: "Gebraucht",
    joinOperator_000020: "Einladungscode erfolgreich generiert",
    joinOperator_000021: "Zeitüberschreitung der Serverantwort",
    joinOperator_000022: "Bitte speichern Sie den vorherigen Vorgang! !",
    joinOperator_000023: "Bitte geben Sie den Gast ein",
    joinOperator_000024: "Die Anzahl der eingegebenen Wörter überschreitet nicht 20 Wörter",
    joinOperator_000025: "Einladungsliste",
    screen_time: "Screening-Zeit",
    joinOperator_000027: "Startzeit",
    joinOperator_000028: "Endzeit",
    joinOperator_000029: "Betreibername/Einladungscode",
    joinOperator_000030: "Anfragen",
    joinOperator_000031: "Name des Bedieners",
    joinOperator_000032:"Erstellungszeitpunkt",
    joinOperator_000033:"Kontaktname",
    joinOperator_000034:"Telefonnummer",
    joinOperator_000035:"Einladungscode",
    joinOperator_000036:"E-Mail"
    
}