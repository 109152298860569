<template lang="html">
  <div class="chargingDataInfo">
    <div class="title" v-if="showTitle">{{info.portTitle}}{{$t('billDetail_00025')}}</div>
    <div :class="['showSelect', lang!='zh'?'column':'', !showTitle ? 'pSelect':'']">
      <el-radio-group v-model="radio" @change="radioChange">
        <div class="select-mode">
          <el-radio class="radio" :label="0">
            <el-checkbox-group v-model="checkLeftList" @change="checkLeftChange">
              <el-checkbox :label="$t('billDetail_00026')" :disabled="radio==1"></el-checkbox>
              <el-checkbox :label="$t('billDetail_00027')" :disabled="radio==1"></el-checkbox>
            </el-checkbox-group>
          </el-radio>
        </div>
        <div class="select-mode">
          <el-radio class="radio" :label="1">
            <el-checkbox-group v-model="checkRightList" @change="checkRightChange">
              <el-checkbox :label="$t('billDetail_00028')" :disabled="!radio"></el-checkbox>
              <el-checkbox :label="$t('billDetail_00029')" :disabled="!radio"></el-checkbox>
            </el-checkbox-group>
          </el-radio>
        </div>
      </el-radio-group>
    </div>
    <div id="containerDC" :class="['echarts',!showTitle? 'w50':'']"></div>
    <div class="title" v-if="info.dualPort">P2{{$t('billDetail_00025')}}</div>
    <div class="showDualSelect" v-show="info.dualPort">
      <el-radio-group v-model="radio1" @change="radioChange">
        <div class="select-mode">
          <el-radio class="radio" :label="0">
            <el-checkbox-group v-model="checkLeftList" @change="checkLeftChange">
              <el-checkbox :label="$t('billDetail_00026')" :disabled="radio==1"></el-checkbox>
              <el-checkbox :label="$t('billDetail_00027')" :disabled="radio==1"></el-checkbox>
            </el-checkbox-group>
          </el-radio>
        </div>
        <div class="select-mode">
          <el-radio class="radio" :label="1">
            <el-checkbox-group v-model="checkRightList" @change="checkRightChange">
              <el-checkbox :label="$t('billDetail_00028')" :disabled="!radio"></el-checkbox>
              <el-checkbox :label="$t('billDetail_00029')" :disabled="!radio"></el-checkbox>
            </el-checkbox-group>
          </el-radio>
        </div>
      </el-radio-group>
    </div>
    <div id="containerDC1" :class="['echarts',!showTitle? 'w50':'']" v-show="info.dualPort"></div>
    <div class="infobox">
      <div class="model">
        <div class="num">{{soc}}</div>
        <div class="name">{{$t('billDetail_00030')}}</div>
      </div>
      <div class="model">
        <div class="num">{{(info.batteryCharged==null||info.batteryCharged==0)?' -':info.batteryCharged+'%'}}</div>
        <div class="name">{{$t('billDetail_00031')}}</div>
      </div>
      <div class="model" v-show="info.status =='charging'">
        <div class="num">{{info.powerSupply}}<span>kW</span></div>
        <div class="name">{{$t('billDetail_00032')}}</div>
      </div>
      <div class="model">
        <div class="num">{{aveSupply}}<span>kW</span></div>
        <div class="name">{{$t('billDetail_00033')}}</div>
      </div>
      <div class="model">
        <div class="num">{{maxSupply}}<span>kW</span></div>
        <div class="name">{{$t('billDetail_00034')}}</div>
      </div>
      <div class="model">
        <div class="num">{{vehicleModel}}</div>
        <div class="name">{{$t('billDetail_00035')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {formmatTime} from "@/assets/utils/time";
export default {
  props: ["info","showTitle"],
  data() {
    let colors = ["#E9A779", "#DB83D1", "#41A0FC", "#53CA76"];
    //电压
    let volData = [];
    let powerCharged = [];
    let eleCurrentData = [];
    let powerSupplyData = [];
    return {
      lang: 'zh',
      echart: "",
      echart1:"",
      soc: "-",
      radio: 0, // 选需求或者测量
      radio1: 0, // 选需求或者测量
      checkLeftList: [this.$parent.$t('billDetail_00026'), this.$parent.$t('billDetail_00027')], // 需求中，选择电压电流
      checkRightList: [this.$parent.$t('billDetail_00028'), this.$parent.$t('billDetail_00029')], // 测量重，选择电流
      vehicleModel: "-", //车型
      maxSupply: "", //最高功率
      aveSupply: "-", //平均功率

      option: {
        color: colors,
        title: {
          text: "",
          textStyle: {
            color: "#999",
            width: "100%",
            fontSize: 12,
            fontWeight: 400,
            align: "center"
          },
          top: "18%",
          left: "center"
        },
        axisPointer: {
          link: {
            xAxisIndex: "all"
          }
        },
        // 提示框
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          },
          formatter: (parmas)=> {
            let box = "<div style='padding:0 10px'>";
            let num = parmas.length;
            const formatText = (val)=>{
              switch (val) {
                case this.$parent.$t('billDetail_00036'):
                  return "kW";
                  break;
                case this.$parent.$t('billDetail_00038'):
                  return "V";
                  break;
                case this.$parent.$t('billDetail_00026'):
                  return "V";
                  break;
                case this.$parent.$t('billDetail_00028'):
                  return "V";
                  break;
                case this.$parent.$t('billDetail_00037'):
                  return "A";
                  break;
                case this.$parent.$t('billDetail_00027'):
                  return "A";
                  break;
                case this.$parent.$t('billDetail_00029'):
                  return "A";
                  break;
                case this.$parent.$t('billDetail_00039'):
                  return "%";
                  break;
              }
            }
            for (let i = 0; i < num; i++) {
              box +=
                parmas[i].marker +
                parmas[i].seriesName +
                " : " +
                parmas[i].value[1] +
                formatText(parmas[i].seriesName) +
                "</br>";
            }
            box += "</div>";
            return box;
          }
        },
        legend: {
          data: [this.$parent.$t('billDetail_00036'), this.$parent.$t('billDetail_00038'), this.$parent.$t('billDetail_00037'), this.$parent.$t('billDetail_00039')]
        },
        dataZoom: [
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1, 2]
          }
        ],
        grid: [
          {
            left: "8%",
            right: "8%",
            bottom: "31%",
            height: "46%"
          },
          {
            left: "8%",
            right: "8%",
            bottom: "8%",
            height: "15%"
          },
          {
            left: "8%",
            right: "8%",
            bottom: "31%",
            height: "46%",
            z: 3
          }
        ],
        xAxis: [
          {
            min: "dataMin",
            max: "dataMax",
            type: "time",
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          },
          {
            min: "dataMin",
            max: "dataMax",
            gridIndex: 1,
            type: "time",
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            },
            axisLabel: {}
          },
          {
            min: "dataMin",
            max: "dataMax",
            gridIndex: 2,
            type: "time",
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          }
        ],
        yAxis: [
          {
            name: this.$parent.$t('billDetail_00036')+"(kW)",
            type: "value",
            scale: true,
            min: 0,
            position: "left",
            splitLine: {
              show: false
            },
            axisTick: {
              inside: true,
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            axisLine: {
              lineStyle: {
                color: colors[2],
                width: 2
              }
            }
          },
          {
            name: this.$parent.$t('billDetail_00038')+"(V)",
            type: "value",
            scale: true,
            min: 0,
            position: "right",
            axisTick: {
              inside: true,
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            nameTextStyle: {
              padding: [0, 80, 0, 0]
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: colors[1],
                width: 2
              }
            },
            axisLabel: {
              inside: true,
              margin: 16
            }
          },
          {
            gridIndex: 1,
            name: this.$parent.$t('billDetail_00039')+"(%)",
            type: "value",
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                opacity: 0
              }
            }
          },
          {
            gridIndex: 2,
            name: this.$parent.$t('billDetail_00037')+"(A)",
            min: 0,
            type: "value",
            position: "right",
            offset: 2,
            nameTextStyle: {
              padding: [0, 0, 0, 30]
            },
            axisTick: {
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: colors[3],
                width: 2
              }
            },
            axisLabel: {
              margin: 16
            }
          }
        ],
        series: [
          {
            name: this.$parent.$t('billDetail_00036'),
            type: "line",
            markArea: {
              label: {
                normal: {
                  show: true,
                  color: "#999"
                }
              },
              itemStyle: {
                normal: {
                  opacity: 1
                }
              },
              data: [
                [
                  {
                    name: "",
                    xAxis: ""
                  },
                  {
                    xAxis: ""
                  }
                ]
              ]
            },
            itemStyle: {
              normal: {
                color: "#41A0FC"
              }
            },
            lineStyle: {
              normal: {
                color: "#41A0FC"
              }
            },
            areaStyle: {},
            data: powerSupplyData
          },
          {
            name: this.$parent.$t('billDetail_00038'),
            type: "line",
            yAxisIndex: 1,
            xAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            lineStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            data: volData
          },
          {
            name: this.$parent.$t('billDetail_00039'),
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 2,
            areaStyle: {
              normal: {
                color: "#E9A779"
              }
            },
            itemStyle: {
              normal: {
                color: "#E9A779"
              }
            },
            lineStyle: {
              normal: {
                width: 3
              }
            },
            data: powerCharged
          },
          {
            name: this.$parent.$t('billDetail_00037'),
            type: "line",
            itemStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            lineStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            yAxisIndex: 3,
            xAxisIndex: 2,
            data: eleCurrentData
          },
          {
            name: this.$parent.$t('billDetail_00026'),
            type: "line",
            yAxisIndex: 1,
            xAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            lineStyle: {
              normal: {
                color: "rgba(207,135,211,0.4)",
                type: "dashed",
                width: 3
              }
            }
          },
          {
            name: this.$parent.$t('billDetail_00027'),
            type: "line",
            itemStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            lineStyle: {
              normal: {
                color: "rgba(83,202,118,0.4)",
                type: "dashed",
                width: 3
              }
            },
            yAxisIndex: 3,
            xAxisIndex: 2
          },
          {
            name: this.$parent.$t('billDetail_00028'),
            type: "line",
            yAxisIndex: 1,
            xAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            lineStyle: {
              normal: {
                color: "rgba(207,135,211,0.4)",
                type: "dashed",
                width: 3
              }
            }
          },
          {
            name: this.$parent.$t('billDetail_00029'),
            type: "line",
            itemStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            lineStyle: {
              normal: {
                color: "rgba(83,202,118,0.4)",
                type: "dashed",
                width: 3
              }
            },
            yAxisIndex: 3,
            xAxisIndex: 2
          }
        ]
      },
      option1: {
        color: colors,
        title: {
          text: "",
          textStyle: {
            color: "#999",
            width: "100%",
            fontSize: 12,
            fontWeight: 400,
            align: "center"
          },
          top: "11%",
          left: "center"
        },
        axisPointer: {
          link: {
            xAxisIndex: "all"
          }
        },
        // 提示框
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          },
          formatter: (parmas)=> {
            let box = "<div style='padding:0 10px'>";
            let num = parmas.length;
            const formatText = (val)=>{
              switch (val) {
                case this.$parent.$t('billDetail_00036'):
                  return "kW";
                  break;
                case this.$parent.$t('billDetail_00038'):
                  return "V";
                  break;
                case this.$parent.$t('billDetail_00026'):
                  return "V";
                  break;
                case this.$parent.$t('billDetail_00028'):
                  return "V";
                  break;
                case this.$parent.$t('billDetail_00037'):
                  return "A";
                  break;
                case this.$parent.$t('billDetail_00027'):
                  return "A";
                  break;
                case this.$parent.$t('billDetail_00029'):
                  return "A";
                  break;
                case this.$parent.$t('billDetail_00039'):
                  return "%";
                  break;
              }
            }
            for (let i = 0; i < num; i++) {
              box +=
                parmas[i].marker +
                parmas[i].seriesName +
                " : " +
                parmas[i].value[1] +
                formatText(parmas[i].seriesName) +
                "</br>";
            }
            box += "</div>";
            return box;
          }
        },
        legend: {
          data: [this.$parent.$t('billDetail_00036'), this.$parent.$t('billDetail_00038'), this.$parent.$t('billDetail_00037'), this.$parent.$t('billDetail_00039')]
        },
        dataZoom: [
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1, 2]
          }
        ],
        grid: [
          {
            left: "8%",
            right: "8%",
            bottom: "31%",
            height: "46%"
          },
          {
            left: "8%",
            right: "8%",
            bottom: "8%",
            height: "15%"
          },
          {
            left: "8%",
            right: "8%",
            bottom: "31%",
            height: "46%",
            z: 3
          }
        ],
        xAxis: [
          {
            min: "dataMin",
            max: "dataMax",
            type: "time",
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          },
          {
            min: "dataMin",
            max: "dataMax",
            gridIndex: 1,
            type: "time",
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            },
            axisLabel: {}
          },
          {
            min: "dataMin",
            max: "dataMax",
            gridIndex: 2,
            type: "time",
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          }
        ],
        yAxis: [
          {
            name: this.$parent.$t('billDetail_00036')+"(kW)",
            type: "value",
            scale: true,
            min: 0,
            position: "left",
            splitLine: {
              show: false
            },
            axisTick: {
              inside: true,
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            axisLine: {
              lineStyle: {
                color: colors[2],
                width: 2
              }
            }
          },
          {
            name: this.$parent.$t('billDetail_00038')+"(V)",
            type: "value",
            scale: true,
            min: 0,
            position: "right",
            axisTick: {
              inside: true,
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            nameTextStyle: {
              padding: [0, 80, 0, 0]
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: colors[1],
                width: 2
              }
            },
            axisLabel: {
              inside: true,
              margin: 16
            }
          },
          {
            gridIndex: 1,
            name: this.$parent.$t('billDetail_00039')+"(%)",
            type: "value",
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                opacity: 0
              }
            }
          },
          {
            gridIndex: 2,
            name: this.$parent.$t('billDetail_00037')+"(A)",
            min: 0,
            type: "value",
            position: "right",
            offset: 2,
            nameTextStyle: {
              padding: [0, 0, 0, 30]
            },
            axisTick: {
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: colors[3],
                width: 2
              }
            },
            axisLabel: {
              margin: 16
            }
          }
        ],
        series: [
          {
            name: this.$parent.$t('billDetail_00036'),
            type: "line",
            markArea: {
              label: {
                normal: {
                  show: true,
                  color: "#999"
                }
              },
              itemStyle: {
                normal: {
                  opacity: 1
                }
              },
              data: [
                [
                  {
                    name: "",
                    xAxis: ""
                  },
                  {
                    xAxis: ""
                  }
                ]
              ]
            },
            itemStyle: {
              normal: {
                color: "#41A0FC"
              }
            },
            lineStyle: {
              normal: {
                color: "#41A0FC"
              }
            },
            areaStyle: {},
            data: powerSupplyData
          },
          {
            name: this.$parent.$t('billDetail_00038'),
            type: "line",
            yAxisIndex: 1,
            xAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            lineStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            data: volData
          },
          {
            name: this.$parent.$t('billDetail_00039'),
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 2,
            areaStyle: {
              normal: {
                color: "#E9A779"
              }
            },
            itemStyle: {
              normal: {
                color: "#E9A779"
              }
            },
            lineStyle: {
              normal: {
                width: 3
              }
            },
            data: powerCharged
          },
          {
            name: this.$parent.$t('billDetail_00037'),
            type: "line",
            itemStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            lineStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            yAxisIndex: 3,
            xAxisIndex: 2,
            data: eleCurrentData
          },
          {
            name: this.$parent.$t('billDetail_00026'),
            type: "line",
            yAxisIndex: 1,
            xAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            lineStyle: {
              normal: {
                color: "rgba(207,135,211,0.4)",
                type: "dashed",
                width: 3
              }
            }
          },
          {
            name: this.$parent.$t('billDetail_00027'),
            type: "line",
            itemStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            lineStyle: {
              normal: {
                color: "rgba(83,202,118,0.4)",
                type: "dashed",
                width: 3
              }
            },
            yAxisIndex: 3,
            xAxisIndex: 2
          },
          {
            name: this.$parent.$t('billDetail_00028'),
            type: "line",
            yAxisIndex: 1,
            xAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            lineStyle: {
              normal: {
                color: "rgba(207,135,211,0.4)",
                type: "dashed",
                width: 3
              }
            }
          },
          {
            name: this.$parent.$t('billDetail_00029'),
            type: "line",
            itemStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            lineStyle: {
              normal: {
                color: "rgba(83,202,118,0.4)",
                type: "dashed",
                width: 3
              }
            },
            yAxisIndex: 3,
            xAxisIndex: 2
          }
        ]
      }
    };
  },
  methods: {
    // 此处为incldes的polyfill,确保在ie下识别includes方法
    includesPolyfill(){
      if (!Array.prototype.includes) {
        Array.prototype.includes = function(search, start) {
          "use strict";
          if (typeof start !== "number") {
            start = 0;
          }

          if (start + search.length > this.length) {
            return false;
          } else {
            return this.indexOf(search, start) !== -1;
          }
        };
      }
    },
    // 监听需求电压电流多选框
    checkLeftChange(event) {
      this.includesPolyfill()
      let all = [this.$t('billDetail_00026'), this.$t('billDetail_00027')];
      let current = [...event];
      let diff = all.filter(x => ![...event].includes(x)); // 补集
      this.selectLine(current);
      this.unSelectLine(diff);
    },
    // 监听测量电压电流多选框
    checkRightChange(event) {
      this.includesPolyfill()
      let all = [this.$t('billDetail_00028'), this.$t('billDetail_00029')];
      let current = [...event];
      let diff = all.filter(x => ![...event].includes(x)); // 补集
      this.selectLine(current);
      this.unSelectLine(diff);
    },
    // 监听radio
    radioChange(event) {
      switch (event) {
        case 0:
          this.checkLeftList = [this.$t('billDetail_00026'), this.$t('billDetail_00027')];
          this.checkRightList = [];
          break;
        case 1:
          this.checkRightList = [this.$t('billDetail_00028'), this.$t('billDetail_00029')];
          this.checkLeftList = [];
          break;
      }
    },
    // 给echarts配发隐藏哪条线
    unSelectLine(arr) {
      for (let i = 0; i < arr.length; i++) {
        this.echart.dispatchAction({
          type: "legendUnSelect",
          name: arr[i]
        });
        this.echart1.dispatchAction({
          type: "legendUnSelect",
          name: arr[i]
        });
      }
    },
    // 给echarts配发显示哪条线
    selectLine(arr) {
      for (let i = 0; i < arr.length; i++) {
        this.echart.dispatchAction({
          type: "legendSelect",
          name: arr[i]
        });
        this.echart1.dispatchAction({
          type: "legendSelect",
          name: arr[i]
        });
      }
    },
    formmatSoC(item) {
      if (!item) {
        return "-";
      }
      var soc = item.soC;
      if (!soc || soc == 0) {
        return "-";
      }
      return soc + "%";
    },
    formatAveSupply(bill) {
      var powerCharged = bill.powerCharged;
      var chargeInterval = bill.chargeInterval;
      if (powerCharged == null) {
        powerCharged = 0;
      }
      if (chargeInterval == null || chargeInterval == 0) {
        return 0;
      }
      var s = powerCharged / (chargeInterval / 60);
      return s.toFixed(3);
    },
    calPowerRange(array, num) {
      var arrayRange = {};
      for (var i = 0; i < array.length; i++) {
        if (array[i] > num) {
          arrayRange.begin = i;
          break;
        }
      }
      var reservedArray = array.reverse();
      for (var i = 0; i < reservedArray.length; i++) {
        if (reservedArray[i] > num) {
          arrayRange.end = reservedArray.length - i - 1;
          break;
        }
      }
      return arrayRange;
    },
    //计算两时间段的分钟差
    calMinusByIndex(array, begin, end) {
      var time1 = new Date(array[begin]);
      var time2 = new Date(array[end]);
      var diffTime = time2.getTime() - time1.getTime();
      var minutes = Math.floor(diffTime / (60 * 1000));
      return minutes;
    },
    formmatTime(time) {
      return formmatTime(time, true);
    },
    calPercent(num, total) {
      if (total == 0) {
        return "0%";
      }
      return Math.round(num / total * 10000) / 100.0 + "%";
    }
  },
  watch: {
    info: function(value) {
      if (value.vehicleModel) {
        this.vehicleModel = value.vehicleModel;
      }
      if (value.chargeData) {
        var om = this;
        var batteryPercent = []; //电池电量百分比二维数组
        var timeData = []; //时间
        var volData = []; //电压二维数组
        var demandVolData = []; //需求电压二维数组
        var measureVolData = []; //需求电压二维数组
        var powerCharged = []; //已充电量单位度
        var eleCurrentData = []; //电流二维数组
        var demandEleCurrentData = []; //需求电流二维数组
        var measureEleCurrentData = []; //测量电流二维数组
        var powerSupplyData = []; //功率一维
        var powerSupplyData1 = []; //功率二维数组

        var length = value.chargeData.length;
        for (var i = 0; i < length; i++) {
          var time = this.formmatTime(value.chargeData[i].time, true);
          timeData.push(time);
          var array = []; //电流
          var array1 = []; //充电百分比
          var array2 = []; //功率
          var array3 = []; //电压
          var array4 = []; //需求电流
          var array5 = []; //需求电压
          var array6 = []; //测量电流
          var array7 = []; //测量电压
          array.push(time);
          array1.push(time);
          array2.push(time);
          array3.push(time);
          array4.push(time);
          array5.push(time);
          array6.push(time);
          array7.push(time);
          var curentEle = Math.abs(
            (value.chargeData[i].data[2] / 1000).toFixed(2)
          );
          array.push(curentEle);
          eleCurrentData.push(array);
          powerCharged.push(value.chargeData[i].data[0]);
          //生成二维数组
          array1.push(value.chargeData[i].data[6]);
          batteryPercent.push(array1);
          array2.push((value.chargeData[i].data[1] / 1000).toFixed(2));
          powerSupplyData.push((value.chargeData[i].data[1] / 1000).toFixed(2)); //w转kw
          powerSupplyData1.push(array2); //w转kw
          var vol = 0;
          if (curentEle != 0) {
            if (value.chargeData[i].data[8] != null) {
              vol = value.chargeData[i].data[8].toFixed(2);
            } else {
              vol = (value.chargeData[i].data[1] / curentEle).toFixed(2);
            }
          }
          array3.push(vol);
          volData.push(array3);

          if (value.chargeData[i].data[9] != null) {
            var demandEleCurrent = value.chargeData[i].data[9];
            array4.push(Math.abs((demandEleCurrent / 1000).toFixed(2)));
            demandEleCurrentData.push(array4);
          }

          if (value.chargeData[i].data[10] != null) {
            var demandVol = value.chargeData[i].data[10];
            array5.push(demandVol);
            demandVolData.push(array5);
          }
          if (value.chargeData[i].data[11] != null) {
            var measureEleCurrent = value.chargeData[i].data[11];
            array6.push(Math.abs((measureEleCurrent / 1000).toFixed(2)));
            measureEleCurrentData.push(array6);
          }
          if (value.chargeData[i].data[12] != null) {
            var measureVol = value.chargeData[i].data[12];
            array7.push(measureVol);
            measureVolData.push(array7);
          }
        }
        //图0 x时间 y0充电功率 y1 充电电压 y2充电电流
        //图1 x时间 y 充电电量
        //图2 x时间 y 电流
        this.option.series[0].data = powerSupplyData1; //功率
        this.option.series[1].data = volData; // 电压
        this.option.series[2].data = batteryPercent; //电池电量百分比
        this.option.series[3].data = eleCurrentData; // 电流
        this.option.series[4].data = demandVolData; // 需求电压
        this.option.series[5].data = demandEleCurrentData; // 需求电流
        this.option.series[6].data = measureVolData; // 测量电压
        this.option.series[7].data = measureEleCurrentData; // 测量电流
        //获取初始的电量百分比
        if (value.extraChargeInfo) {
          var soc = value.extraChargeInfo.soC;
          if (!soc || soc == 0) {
            if(batteryPercent && batteryPercent.length > 0) {
              this.soc = batteryPercent[0][1] + "%";
            }
          } else {
            this.soc = soc + "%";
          }
        }else if(batteryPercent && batteryPercent.length > 0){
          this.soc = batteryPercent[0][1] + "%";
        }
        var maxSupply = Math.max.apply(null, powerSupplyData); //最大功率
        this.maxSupply = maxSupply.toFixed(2); //最高功率
        var aveSupply = this.formatAveSupply(value);
        var supply = (maxSupply / 1.1).toFixed(2);
        if (supply < aveSupply) {
          this.aveSupply = supply;
        } else {
          this.aveSupply = aveSupply;
        }
        if(this.aveSupply * 1 > this.maxSupply * 1){
            this.aveSupply = this.maxSupply;
        }
        if (length >= 2) {
          var powerSupplyRange = maxSupply * 0.75;
          //计算最高功率返回对应的坐标begin、end
          var arrayRange = this.calPowerRange(
            powerSupplyData,
            powerSupplyRange
          );
          var totalMinutes = this.calMinusByIndex(timeData, 0, length - 1);
          var rangeMinutes = this.calMinusByIndex(
            timeData,
            arrayRange.begin,
            arrayRange.end
          );
          //计算占比
          var timePercent = this.calPercent(rangeMinutes, totalMinutes);
          //计算两点之间的电量差
          var rangePowerCharged = (
            powerCharged[arrayRange.end] - powerCharged[arrayRange.begin]
          ).toFixed(3);
          var powerCahrgedPercent = this.calPercent(
            rangePowerCharged,
            powerCharged[length - 1]
          );
          //最高功率范围描述
          var rangeRemind= this.$t('billDetail_00040',{powerBegin:powerSupplyRange.toFixed(2),powerEnd:maxSupply.toFixed(2),minutes:rangeMinutes,timePercent:timePercent,powerCharged:rangePowerCharged,chargePercent:powerCahrgedPercent});
          this.option.title.text = rangeRemind;
          if(this.lang!='zh') {
            this.option.title.top = '15%';
          }
          this.option.series[0].markArea.data[0][0].xAxis =
            timeData[arrayRange.begin];
          this.option.series[0].markArea.data[0][1].xAxis =
            timeData[arrayRange.end];
        }
      }
      if (value.dualChargeData){
        var om = this;
        var batteryPercent = []; //电池电量百分比二维数组
        var timeData = []; //时间
        var volData = []; //电压二维数组
        var demandVolData = []; //需求电压二维数组
        var measureVolData = []; //需求电压二维数组
        var powerCharged = []; //已充电量单位度
        var eleCurrentData = []; //电流二维数组
        var demandEleCurrentData = []; //需求电流二维数组
        var measureEleCurrentData = []; //测量电流二维数组
        var powerSupplyData = []; //功率一维
        var powerSupplyData1 = []; //功率二维数组

        var length = value.dualChargeData.length;
        for (var i = 0; i < length; i++) {
          var time = this.formmatTime(value.dualChargeData[i].time, true);
          timeData.push(time);
          var array = []; //电流
          var array1 = []; //充电百分比
          var array2 = []; //功率
          var array3 = []; //电压
          var array4 = []; //需求电流
          var array5 = []; //需求电压
          var array6 = []; //测量电流
          var array7 = []; //测量电压
          array.push(time);
          array1.push(time);
          array2.push(time);
          array3.push(time);
          array4.push(time);
          array5.push(time);
          array6.push(time);
          array7.push(time);
          var curentEle = Math.abs(
            (value.dualChargeData[i].data[2] / 1000).toFixed(2)
          );
          array.push(curentEle);
          eleCurrentData.push(array);
          powerCharged.push(value.dualChargeData[i].data[0]);
          //生成二维数组
          array1.push(value.dualChargeData[i].data[6]);
          batteryPercent.push(array1);
          array2.push((value.dualChargeData[i].data[1] / 1000).toFixed(2));
          powerSupplyData.push((value.dualChargeData[i].data[1] / 1000).toFixed(2)); //w转kw
          powerSupplyData1.push(array2); //w转kw
          var vol = 0;
          if (curentEle != 0) {
            if (value.dualChargeData[i].data[8] != null) {
              vol = value.dualChargeData[i].data[8].toFixed(2);
            } else {
              vol = (value.dualChargeData[i].data[1] / curentEle).toFixed(2);
            }
          }
          array3.push(vol);
          volData.push(array3);

          if (value.dualChargeData[i].data[9] != null) {
            var demandEleCurrent = value.dualChargeData[i].data[9];
            array4.push(Math.abs((demandEleCurrent / 1000).toFixed(2)));
            demandEleCurrentData.push(array4);
          }

          if (value.dualChargeData[i].data[10] != null) {
            var demandVol = value.dualChargeData[i].data[10];
            array5.push(demandVol);
            demandVolData.push(array5);
          }
          if (value.dualChargeData[i].data[11] != null) {
            var measureEleCurrent = value.dualChargeData[i].data[11];
            array6.push(Math.abs((measureEleCurrent / 1000).toFixed(2)));
            measureEleCurrentData.push(array6);
          }
          if (value.dualChargeData[i].data[12] != null) {
            var measureVol = value.dualChargeData[i].data[12];
            array7.push(measureVol);
            measureVolData.push(array7);
          }
        }
        //图0 x时间 y0充电功率 y1 充电电压 y2充电电流
        //图1 x时间 y 充电电量
        //图2 x时间 y 电流
        this.option1.series[0].data = powerSupplyData1; //功率
        this.option1.series[1].data = volData; // 电压
        this.option1.series[2].data = batteryPercent; //电池电量百分比
        this.option1.series[3].data = eleCurrentData; // 电流
        this.option1.series[4].data = demandVolData; // 需求电压
        this.option1.series[5].data = demandEleCurrentData; // 需求电流
        this.option1.series[6].data = measureVolData; // 测量电压
        this.option1.series[7].data = measureEleCurrentData; // 测量电流
        //获取初始的电量百分比
        if (value.extraChargeInfo) {
          var soc = value.extraChargeInfo.soC;
          if (!soc || soc == 0) {
            if(batteryPercent && batteryPercent.length > 0) {
              this.soc = batteryPercent[0][1] + "%";
            }
          } else {
            this.soc = soc + "%";
          }
        }else if(batteryPercent && batteryPercent.length > 0){
          this.soc = batteryPercent[0][1] + "%";
        }
        var maxSupply = Math.max.apply(null, powerSupplyData); //最大功率
        this.maxSupply = maxSupply.toFixed(2); //最高功率
        var aveSupply = this.formatAveSupply(value);
        var supply = (maxSupply / 1.1).toFixed(2);
        if (supply < aveSupply) {
          this.aveSupply = supply;
        } else {
          this.aveSupply = aveSupply;
        }
        if(this.aveSupply * 1 > this.maxSupply * 1){
            this.aveSupply = this.maxSupply;
        }
        if (length >= 2) {
          var powerSupplyRange = maxSupply * 0.75;
          //计算最高功率返回对应的坐标begin、end
          var arrayRange = this.calPowerRange(
            powerSupplyData,
            powerSupplyRange
          );
          var totalMinutes = this.calMinusByIndex(timeData, 0, length - 1);
          var rangeMinutes = this.calMinusByIndex(
            timeData,
            arrayRange.begin,
            arrayRange.end
          );
          //计算占比
          var timePercent = this.calPercent(rangeMinutes, totalMinutes);
          //计算两点之间的电量差
          var rangePowerCharged = (
            powerCharged[arrayRange.end] - powerCharged[arrayRange.begin]
          ).toFixed(3);
          var powerCahrgedPercent = this.calPercent(
            rangePowerCharged,
            powerCharged[length - 1]
          );
          //最高功率范围描述
          var rangeRemind= this.$t('billDetail_00040',{powerBegin:powerSupplyRange.toFixed(2),powerEnd:maxSupply.toFixed(2),minutes:rangeMinutes,timePercent:timePercent,powerCharged:rangePowerCharged,chargePercent:powerCahrgedPercent});
          this.option1.title.text = rangeRemind;
          if(this.lang!='zh') {
            this.option1.title.top = '15%';
          }
          this.option1.series[0].markArea.data[0][0].xAxis =
            timeData[arrayRange.begin];
          this.option1.series[0].markArea.data[0][1].xAxis =
            timeData[arrayRange.end];
        }
      }
      this.echart.setOption(this.option);
      this.echart1.setOption(this.option1);
      this.unSelectLine([this.$t('billDetail_00028'), this.$t('billDetail_00029')]);
    }
  },
  mounted() {
    this.lang = window.localStorage.lang;
    let echarts = require('echarts');
    this.echart = echarts.init(document.getElementById("containerDC"));
    this.echart1 = echarts.init(document.getElementById("containerDC1"));
    window.addEventListener("resize", () => {
      this.echart.resize();
      this.echart1.resize();
    });
  },
};
</script>

<style lang="scss">
  .charging-info::-webkit-scrollbar{
    display: none;
  }
.chargingDataInfo {
  height: auto;
  padding-top: 20px;
  position: relative;
  & .title {
    font-size: 26px;
    color: #333;
    text-align: center;
    padding: 10px 0 20px;
  }
  .showDualSelect{
    position: absolute;
    top: 863px;
    width: inherit;
    text-align: center;
    z-index: 99;
    left: 50%;
    margin-left: -376px;
    & .select-mode {
      display: inline-block;
      vertical-align: middle;
      padding: 6px 10px;
      border-radius: 4px;
      border: 1px dashed #bfbfbf;
      background-color: #f2f2f2;
      & .el-radio__label {
        display: inline-block;
      }
      & .el-checkbox-group {
        margin-left: 6px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
  & .showSelect {
    position: absolute;
    top: 120px;
    left:50%;
    margin-left: -376px;
    width: inherit;
    text-align: center;
    z-index: 99;
    &.column {
      top: 110px;
      left: 50%;
      transform: translateX(-15%);
    }
    &.pSelect{
    top: 50px;
    }
    & .select-mode {
      display: inline-block;
      vertical-align: middle;
      padding: 6px 10px;
      border-radius: 4px;
      border: 1px dashed #bfbfbf;
      background-color: #f2f2f2;
      & .el-radio__label {
        display: inline-block;
      }
      & .el-checkbox-group {
        margin-left: 6px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
  & .echarts {
    width: 82vw;
    height: 680px;
    margin: 0 auto;
    &.w50{
      width: 55vw!important;
    }
  }
  & .infobox {
    display: flex;
    justify-content: center;
    height: auto;
    margin: 30px auto 40px;
    & .model {
      display: table-cell;
      width: 16.6%;
      text-align: center;
      vertical-align: middle;
      border-right: 1px solid #e6e6e6;
      & .num {
        font-size: 20px;
        color: #2c2c2c;
        & span {
          font-size: 14px;
        }
      }
      & .name {
        font-size: 14px;
        margin-top: 10px;
        color: #8a8a8a;
      }
      &:last-child {
        border-right: 0;
        & .num {
          font-size: 15px;
        }
      }
    }
  }
}
</style>
