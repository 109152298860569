export default {
    gia_00003:'Bei den beantragten Rechnungsarten handelt es sich um alle üblichen Mehrwertsteuerrechnungen, ausgenommen Geschenk- und Skontobetrag.',
    gia_00004:'Bewerbungsstatus',
    gia_00005:'Alle',
    gia_00006:'Ausstehend',
    gia_00007:'Geöffnet',
    gia_00008:'Abgesagt',
    gia_00009:'Gruppe',
    gia_000010:'suchen',
    gia_000011:'reset',
    gia_000012:'Daten exportieren',
    gia_000013:'Zu verarbeitende Nummer',
    gia_000014:'Zu verarbeitender Gesamtbetrag (Yuan)',
    gia_000015:'Anzahl Rechnungen',
    gia_000016:'Gesamtrechnungsbetrag (Yuan)',
    gia_000017:'Spitzname',
    gia_000018:'Typ',
    gia_000019:'Weg',
    gia_000020:'Antragsbetrag',
    gia_000021:'Ladereihenfolge prüfen',
    gia_000022:'Parkplatzbelegungsabrechnung prüfen',
    gia_000023:'Grund für die Ablehnung',
    gia_000024:'Rechnungs-Details',
    gia_000025:'Rechnung',
    gia_000026:'Steuernummer',
    gia_000027:'Firmenanschrift',
    gia_000028:'Ablehnen',
    gia_000029:'Information',
    gia_000030:'Telefonnummer',
    gia_000031:'E-Mail',
    gia_000032:'auf jeder Seite anzeigen',
    gia_000033:'Schaltfläche „Rechnungsstellung ablehnen“',
    gia_000034:'Bitte tragen Sie den Ablehnungsgrund ein',
    gia_000035:'Rechnung wurde geöffnet Schaltfläche Erinnerung',
    gia_000036:'Papierrechnung',
    gia_000037:'Elektronische Rechnung',
    gia_000038:'Hinweis',
    gia_000039:'Spitzname/Telefonnummer',
    gia_000040:'Bewerbungszeit:',
    gia_000041:'Startdatum',
    gia_000042:'Endtermin',
    gia_000043:'Unternehmensrechnung',
    gia_000044:'Persönliche Rechnung',
    gia_000045:'Arbeitshandy',
    gia_000046:'Bankkonto',
    gia_000047:'Konto',
    gia_000048:'Empfänger',
    gia_000049:'Lieferanschrift',
    gia_000050:'Keine Daten',
    gia_000051:'Dieser Vorgang lädt die Excel-Datei der Rechnung herunter. Möchten Sie fortfahren?',
    gia_000052:'Hinweis',
    gia_000053:'Sicher',
    gia_000054:'Abbrechen',
    gia_000055:'Starte Download',
    gia_000056:'Download abbrechen',
    gia_000057:'Rechnungsliste',
    gia_000058:'Papierrechnung & elektronische Rechnung'
}