export default  {
    charging_package45:"Use cash to offset electricity and service charges",
    charging_package46:"Electricity credits for both electricity and service charges",
    charging_package47:"Cash offset service fees",
    charging_package48:"Electricity amount offset service fee",
    charging_package52:" {day} from the date of purchase/redemption of the package by the user",

    cash_coupon0001: 'Marketing center',
    cash_coupon0002: 'Preferential tools',
    cash_coupon0003: 'Name',
    cash_coupon0004: 'Please enter',
    cash_coupon0005: 'Reset',
    cash_coupon0006: 'Search',
    cash_coupon0007: 'List',
    cash_coupon0008: 'Create',
    cash_coupon0009: 'Type',
    cash_coupon0010: 'Amount',
    cash_coupon0011: 'kwh',
    cash_coupon0012: 'euro',
    cash_coupon0013: 'Validity period',
    cash_coupon0014: 'Operate',
    cash_coupon0015: 'Detail',
    cash_coupon0016: 'Electricity tickets',
    cash_coupon0017: 'Credits',
}
