export default {
    settingDepot_00001: '批量设置',
    settingDepot_00002: '场站中心',
    settingDepot_00003: '场站管理',
    settingDepot_00004: '已选充电站',
    settingDepot_00005: '公共资源',
    settingDepot_00006: '团体资费',
    settingDepot_00007: '基本信息',
    settingDepot_00008: '运营设置',
    settingDepot_00009: '请将设置完的资费标准推送到充电桩上，充电桩才能以新的资费标准为用户进行充电费用的结算',
    settingDepot_00010: '推送资费',
    settingDepot_00011: '交流资费标准',
    settingDepot_00012: '当前使用资费：',
    settingDepot_00013: '选择资费',
    settingDepot_00014: '新建直流资费',
    settingDepot_00015: '开始时间',
    settingDepot_00016: '结束时间',
    settingDepot_00017: '电费({type}/{unit})',
    settingDepot_00018: '服务费({type}/{unit})',
    settingDepot_00019: '延时费({type}/分钟)',
    settingDepot_00020: '谷峰平时段',
    settingDepot_00021: '未定义',
    settingDepot_00022: '尖',
    settingDepot_00023: '峰',
    settingDepot_00024: '平',
    settingDepot_00025: '谷',
    settingDepot_00026: '直流资费标准',
    settingDepot_00027: '新建交流资费',
    settingDepot_00028: '新建资费',
    settingDepot_00029: '资费名称',
    settingDepot_00030: '请输入资费名称',
    settingDepot_00031: '该资费名称已被占用',
    settingDepot_00032: '添加时段',
    settingDepot_00033: '操 作',
    settingDepot_00034: '删 除',
    settingDepot_00035: '取 消',
    settingDepot_00036: '确 定',
    settingDepot_00037: '请选择或新建资费标准',
    settingDepot_00038: '最多可以添加{0}个时段',
    settingDepot_00039: '请填写资费标准',
    settingDepot_00040: '请填写正确的资费标准',
    settingDepot_00041: '新建成功',
    settingDepot_00042: '选 择',
    settingDepot_00043: '请完善资费信息',
    settingDepot_00044: '资费时段需从0开始至24结束',
    settingDepot_00045: "单次充电总费用不打折",
    settingDepot_00046: "单次充电服务费不打折",
    settingDepot_00047: "单次充电服务费{des}折",
    settingDepot_00048: "单次充电总费用{des}折",
    settingDepot_00049: "单次充电总费用全免",
    settingDepot_00050: "单次充电服务费全免",
    settingDepot_00051: '请输入正确的金额（大于0）',
    settingDepot_00052: '成功',
    settingDepot_00053: '推送中',
    settingDepot_00054: '失败',
    settingDepot_00055: '推送失败',
    settingDepot_00056: '站点名称',
    settingDepot_00057: '设备数量',
    settingDepot_00058: '设备推送结果',
    settingDepot_00059: '直流',
    settingDepot_00060: '交流',
    settingDepot_00061: '添加团体',
    settingDepot_00062: '团体名称',
    settingDepot_00063: '当前资费标准',
    settingDepot_00064: '当前优惠折扣',
    settingDepot_00065: '优先级(由高到低)',
    settingDepot_00066: '上 移',
    settingDepot_00067: '下 移',
    settingDepot_00068: '设 置',
    settingDepot_00069: '推 送',
    settingDepot_00070: '删 除',
    settingDepot_00071: '场站联系人',
    settingDepot_00072: '当设备出现故障报警，将以短信形式通知场站联系人',
    settingDepot_00073: '姓 名',
    settingDepot_00074: '请输入姓名',
    settingDepot_00075: '手机号',
    settingDepot_00076: '请输入手机号',
    settingDepot_00077: '邮 箱',
    settingDepot_00078: '请输入邮箱',
    settingDepot_00079: '修 改',
    settingDepot_00080: '保 存',
    settingDepot_00081: '场站启用状态',
    settingDepot_00082: '控制站点是否在微信公众号中显示',
    settingDepot_00083: '启 用',
    settingDepot_00084: '未启用',
    settingDepot_00085: '对外开放',
    settingDepot_00086: '开 放',
    settingDepot_00087: '不开放',
    settingDepot_00088: '只允许支持的团体充电',
    settingDepot_00089: '启用后可允许团体资费设置中的团体用户充电',
    settingDepot_00090: '是',
    settingDepot_00091: '否',
    settingDepot_00092: '是否支持优惠券',
    settingDepot_00093: '是否支持排队',
    settingDepot_00094: '是否支持预约',
    settingDepot_00095: '预约保留时间',
    settingDepot_00096: '15分钟',
    settingDepot_00097: '30分钟',
    settingDepot_00098: '微信用户支付模式',
    settingDepot_00099: '预付费',
    settingDepot_00100: '后付费',
    settingDepot_00101: '最低余额限制为',
    settingDepot_00102: '元',
    settingDepot_00103: '在线身份卡/即插即充卡用户付费模式',
    settingDepot_00104: '影响站点在客户端的展示信息',
    settingDepot_00105: '开放时间',
    settingDepot_00106: '预付费模式时，只有充值用户才可以充电',
    
    settingDepot_00107: '成 功',
    settingDepot_00108: '推送中',
    settingDepot_00109: '失 败',
    settingDepot_00110: '推送失败',
    settingDepot_00111: '请先修改默认资费或添加团体',
    settingDepot_00112: '推送失败，站点下没有设备',
    settingDepot_00113: '此操作将从列表中删除该团体，是否继续？',
    settingDepot_00114: '提示',
    settingDepot_00115: '确认',
    settingDepot_00116: '取消',
    settingDepot_00117: '已取消删除',
    settingDepot_00118: '请先确认保存其他操作',
    settingDepot_00119: '请选择站点',
    settingDepot_00120: '无信息修改，操作已取消',
    settingDepot_00121: '请输入正确的联系人名称',
    settingDepot_00122: '请输入正确的电话号码',
    settingDepot_00123: '请输入正确的邮箱格式',
    settingDepot_00124: '日期设置不正确',
    settingDepot_00125: '选择团体',
    settingDepot_00126: '请输入团体名称',
    settingDepot_00127: '用户数量',
    settingDepot_00128: '资费标准',
    settingDepot_00129: '如果资费标准有修改，在场站详情-资费标准中点击“推送资费”按钮，资费才可以生效！',
    settingDepot_00130: '交流资费',
    settingDepot_00131: '请选择资费',
    settingDepot_00132: '直流资费',
    settingDepot_00133: '折扣选择',
    settingDepot_00134: '单次成功点总费用不打折(%)',
    settingDepot_00135: '单次充电服务费打折(%)',
    settingDepot_00136: '举例说明：如果设置单次充电服务费8.5折，此次充电费用100元(含电费70元、服务费30元),则此次需实际支付金额95.5元(70+30*85%=95.5)',
    settingDepot_00137: '85表示8.5折，0表示全免',
    settingDepot_00138: '单次充电总费用打折(%) ',
    settingDepot_00139: '举例说明：如果设置单次充电总费用8.5折，此次充电总费用100元，则此次需要实际支付金额85元(100*85%=85)',
    settingDepot_00140: '85表示8.5折，0表示全免',
    settingDepot_00141: '团体添加成功',
    settingDepot_00142: '在资费标准中点击[ 推送收费 ] 按钮资费才能生效！',
    settingDepot_00143: '可以在[ 充电站管理 - 站点详情 - 站点信息 ] 中进行如下操作 ：',
    settingDepot_00144: '1、移除已添加的团体',
    settingDepot_00145: '2、添加新的团体',
    settingDepot_00146: '上一步',
    settingDepot_00147: '下一步',
    settingDepot_00148: '确定',
    settingDepot_00149: '请选择团体',
    settingDepot_00150: '请选择资费标准',
    settingDepot_00151: '团体不可重复被添加',
    settingDepot_00152: '请设置折扣参数',
    settingDepot_00153: '请输入小于100的正整数',
    settingDepot_00154: '选择资费及折扣',
    settingDepot_00155: '完成',
    settingDepot_00156: '批量修改结果',
    settingDepot_00157: '等待响应',
    settingDepot_00158: '原因',
    settingDepot_00159: '关闭',
    settingDepot_00160: '余额最低',
    settingDepot_00161: '使用货币',
    settingDepot_00162: '1、一处已添加的团体',
}