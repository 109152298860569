export default {
    monthlyStatistics_00001:'Financial Center',
    monthlyStatistics_00002:'Start date',
    monthlyStatistics_00003:'End date',
    monthlyStatistics_00004:'query',
    monthlyStatistics_00005:'common',
    monthlyStatistics_00006:'Top-up',
    monthlyStatistics_00007:'Total amount',
    monthlyStatistics_00008:'Yuan',
    monthlyStatistics_00009:'Download form',
    monthlyStatistics_00010:'today',
    monthlyStatistics_00011:'yesterday',
    monthlyStatistics_00012:'7 days ago',
    monthlyStatistics_00013:'15 days ago',
    monthlyStatistics_00014:'30 days ago',
    monthlyStatistics_00015:'Refund',
    
    monthlyStatistics_00016:'Top-up object',
    monthlyStatistics_00017:'Number of top-up transactions',
    monthlyStatistics_00018:'Recharge amount(Yuan)',
    monthlyStatistics_00019:'principal(Yuan)',
    monthlyStatistics_00020:'give(Yuan)',
    monthlyStatistics_00021:'operation',
    
    monthlyStatistics_00022:'Refund object',
    monthlyStatistics_00023:'Number of refunds',
    monthlyStatistics_00024:'refund amount(Yuan)',
    
    monthlyStatistics_00025:'Statistical object',
    monthlyStatistics_00026:'Number of users (person)',
    monthlyStatistics_00027:'Balance (Yuan)',
    monthlyStatistics_00028:'Principal (Yuan)',
    monthlyStatistics_00029:'Gift (Yuan)',
    
    monthlyStatistics_00030:'sales target',
    monthlyStatistics_00031:'Number of purchases',
    monthlyStatistics_00032:'Number of purchasers',
    monthlyStatistics_00033:'Sales amount (Yuan)',
    
    monthlyStatistics_00034:'All coupon sales',
    monthlyStatistics_00035:'All user wallet balances',

    monthlyStatistics_00036:'year',
    monthlyStatistics_00037:'Financial statistics'


}