export default {
    outlineCard_00001: 'CPO charging card management',
    outlineCard_00002: 'Plug and charge card details',
    outlineCard_00003: 'Online identity charging card details',
    outlineCard_00004: 'Offline stored value card details',
    outlineCard_00005: 'Offline start stop card details',
    outlineCard_00006: 'Basic information',
    outlineCard_00007: 'Fleet',
    outlineCard_00008: 'Recharge record',
    outlineCard_00009: 'Plug and play charging card recharge',
    outlineCard_00010: 'Recharge card',
    outlineCard_00011: 'Transactions',
    outlineCard_00012: 'Cancellation of loss reporting',
    outlineCard_00013: 'Card loss report',
    outlineCard_00014: 'Recharge',
    outlineCard_00015: 'EV type{type}：',
    outlineCard_00016: 'EV number:',
    outlineCard_00017: 'Contact name:',
    outlineCard_00018: 'mobile number:',
    outlineCard_00019: 'Current balance:',
    outlineCard_00020: 'Top-up',
    outlineCard_00021: 'unit',
    outlineCard_00022: 'Fill in the top-up amount',
    outlineCard_00023: 'Login password',
    outlineCard_00024: 'Required',
    outlineCard_00025: 'Please enter password',
    outlineCard_00026: 'Top-up succeeded',
    outlineCard_00027: 'Top-up',
    outlineCard_00028: 'Complete',
    outlineCard_00029: 'Plug and charge card information',
    outlineCard_00030: 'ID card information',
    outlineCard_00031: 'Are you sure to report the loss of this card?',
    outlineCard_00032: 'Are you sure to cancel the loss report of this card?',
    outlineCard_00033: 'confirm',
    outlineCard_00034: 'cancel',
    outlineCard_00035: 'Successful loss reporting',
    outlineCard_00036: 'Successful cancellation of loss reporting',
    outlineCard_00037: 'The operation was cancelled',
    outlineCard_00038: 'Please complete',
    outlineCard_00039: 'Card number',
    outlineCard_00040: 'Cannot be modified',
    outlineCard_00041: 'Card type',
    outlineCard_00042: 'balance',
    outlineCard_00043: 'Subject to the final reimbursement sheet',
    outlineCard_00044: 'Card status',
    outlineCard_00045: 'report the loss',
    outlineCard_00046: 'normal',
    outlineCard_00047: 'Aux number',
    outlineCard_00048: 'Please enter the number',
    outlineCard_00049: 'save',
    outlineCard_00050: 'modify',
    outlineCard_00051: 'Card source',
    outlineCard_00052: 'Charging card group',
    outlineCard_00053: 'Station charging restrictions',
    outlineCard_00054: 'yes',
    outlineCard_00055: 'no',
    outlineCard_00056: 'EV information',
    outlineCard_00057: 'EV number',
    outlineCard_00058: 'Please enter the license plate number',
    outlineCard_00059: 'Location',
    outlineCard_00060: 'Model',
    outlineCard_00061: 'Manual input',
    outlineCard_00062: 'EV brand and model',
    outlineCard_00063: 'Contact information',
    outlineCard_00064: 'Name',
    outlineCard_00065: 'Mobile number',
    outlineCard_00066: 'Offline stored value card',
    outlineCard_00067: 'Offline start stop card',
    outlineCard_00068:  "XCharge configuration card",
    outlineCard_00069: 'Plug and charge',
    outlineCard_00070: 'ID card',
    outlineCard_00071: "Including cashback{promotionBalance}{unit}",
    outlineCard_00072: "Include group assignments{num}{unit}",
    outlineCard_00073: "Are you sure you want to release VIN",
    outlineCard_00074: "Think again",
    outlineCard_00075: "Release succeeded",
    outlineCard_00076: "Please save the previous operation",
    outlineCard_00077: "The parameter is null",
    outlineCard_00078: "Wrong license plate number format!",
    outlineCard_00079: '"Plug and charge" is not allowed for simple ID card source',
    outlineCard_00080: "Please select EV type",
    outlineCard_00081: "The mobile number or license plate number is empty",
    outlineCard_00082: "Modified successfully",
    outlineCard_00083: "Fleets",
    outlineCard_00084: "Add into fleet",
    outlineCard_00085: "Remove from fleets",
    outlineCard_00086: "fleet",
    outlineCard_00087: "Payment",
    outlineCard_00088: "Maximum unpaid transactions",
    outlineCard_00089: "Members",
    outlineCard_00090: "operation",
    outlineCard_00091: "Fleet details",
    outlineCard_00092: "remove",
    outlineCard_00093: "Maximum unpaid amount({unit})",
    outlineCard_00094: "join",
    outlineCard_00095: "Joined",
    outlineCard_00096: "Join successfully",
    outlineCard_00097: "Are you sure to remove all selected groups?",
    outlineCard_00098: "Tips",
    outlineCard_00099: "Are you sure to remove the current group?",
    outlineCard_00100: "Removal succeeded",
    outlineCard_00101: "Paid by the fleet",
    outlineCard_00102: "Paid by the individual",
    outlineCard_00103: "Recharge list",
    outlineCard_00104: "Recharge date",
    outlineCard_00105: "Total recharge amount({unit})",
    outlineCard_00106: "Recharge principal({0})",
    outlineCard_00107: "Recharge gift amount({0})",
    outlineCard_00108: "Recharge mode",
    outlineCard_00109: "WeChat official account（Alipay）",
    outlineCard_00110: "WeChat official account（Wechat payment）",
    outlineCard_00111: "platform",
    outlineCard_00112: "CPO",
    outlineCard_00113: "Release",
    outlineCard_00114: "Are you sure to delete the card",
    outlineCard_00115: "delete",
  }