export default {
    login_00001: "Melden Sie sich bei der Betreiberplattform an",
    login_00002: "Login-Konto",
    login_00003: "Passwort eingeben",
    login_00004: "Anmelden",
    login_00005: "Nutzername und Passwort dürfen nicht leer sein",
    login_00006: "Betreiberplattform",
    login_00007: "Ich habe mich bereits registriert",
    login_00008: "Anmelden",
    registerEmail_00001: "Registrieren",
    registerEmail_00002: "Franchisee",
    registerEmail_00003: "E-Mail-Authentifikation",
    registerEmail_00004: "Füllen Sie die Registrierungsinformationen aus",
    registerEmail_00005: "Finden Sie einen Ladebetreiber",
    registerEmail_00006: "Anfragen",
    registerEmail_00007: "Bitte geben Sie den Einladungscode ein, der Ihnen vom Betreiber zugesandt wurde",
    registerEmail_00008: "CPO Name",
    registerEmail_00009: "Abrechnungswährung",
    registerEmail_00010: "Treten Sie diesem Betreiber bei",
    registerEmail_00011: "Treten Sie der Ladebetreiber-Allianz bei",
    registerEmail_00012: "Bitte geben Sie die E-Mail-Adresse ein",
    registerEmail_00013: "Wir haben eine E-Mail an Ihre {email} gesendet. Bitte klicken Sie auf den Bestätigungslink in der E-Mail, um die Bestätigung abzuschließen und die Registrierungsinformationen einzugeben.",
    registerEmail_00014: "Name des Kontakts|Wie kann ich Sie anrufen",
    registerEmail_00015: "Bitte geben Sie Ihren Namen ein",
    registerEmail_00016: "Betreibername|Name, der von Benutzern angezeigt wird, die Gebühren erheben",
    registerEmail_00017: "Nicht mehr als {0} Wörter",
    registerEmail_00018: "Login-Konto|z.B. xcharge",
    registerEmail_00019: "Verwenden Sie Buchstaben und Zahlen",
    registerEmail_00020: "Login-Passwort|Mindestens 6 ziffern und besteht aus mindestens einer zahl, einem buchstaben und einem spezialzeichen",
    registerEmail_00021: "Passwort bestätigen|Gleich wie das Login-Passwort",
    registerEmail_00022: "Bestätigung der Telefonnummer",
    registerEmail_00023: "Binden Sie das offizielle WeChat-Konto",
    registerEmail_00024: "Chinesisch wird empfohlen",
    registerEmail_00025: "Bitte geben Sie den Betreibernamen ein",
    registerEmail_00026: "Es wurden keine qualifizierten Operatoren gefunden",
    registerEmail_00027: "App-Konto",
    registerEmail_00028: "Einladungscode wurde verwendet",
    registerEmail_00029: "Einladungscode existiert nicht",
    registerEmail_00030: "E-Mail darf nicht leer sein",
    registerEmail_00031: "Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir Sie kontaktieren können",
    registerEmail_00032: "Bitte geben Sie die richtige E-Mail-Adresse ein",
    registerEmail_00033: "Operation fehlgeschlagen",
    registerEmail_00034: "Zeitüberschreitung der Serverantwort",
    registerEmail_00035: "Bitte holen Sie sich zuerst den offiziellen Account",
    registerEmail_10036: "Betreiberplattform",
    registerEmail_10037: "Wähle eine Sprache",
    registerEmail_10038: "Ich habe mich bereits registriert",
    registerEmail_10039: "Login",
    registerEmail_10040: "Melden Sie sich bei der Betreiberplattform an",
    registerEmail_10041: "Melden Sie sich bei der Gruppenverwaltungsplattform an",
    registerEmail_10042: "Erfolgreich eingestellt",
    registerEmail_10043: "Währungstyp",
    registerEmail_10044: "Bitte geben Sie das Passwort erneut ein",
    registerEmail_10045: "Passwörter stimmen nicht überein",
    registerEmail_10046: "Bitte geben Sie den Bestätigungscode ein",
    registerEmail_10047: "Bitte Zahlenwerte eingeben",
    registerEmail_10048: "Bitte 4 Ziffern eingeben",
    registerEmail_10049: "Bitte geben Sie den korrekten Namen des Kontakts ein",
    registerEmail_10050: "Das Kontonummernformat ist eine Kombination aus Zahlen und Buchstaben",
    registerEmail_10051: "Passwort ist eine Kombination aus Buchstaben oder Zahlen",
    registerEmail_10052: "Bitte geben Sie den Kontaktnamen ein",
    registerEmail_10053: "Bitte geben Sie den Betreibernamen ein",
    registerEmail_10054: "Name darf {0} Zeichen nicht überschreiten",
    registerEmail_10055: "Bitte Konto eingeben",
    registerEmail_10056: "Bitte geben Sie das Passwort ein",
    registerEmail_10057: "6 bis 20 Zeichen lang",
    registerEmail_10058: "Bitte lesen Sie die 'Xcharge-Plattform-Datenschutzrichtlinie' sorgfältig durch, um zu bestätigen, ob Sie damit einverstanden sind!",
    registerEmail_10059: "Bitte Telefonnummer eingeben",
    registerEmail_10060: "Bitte geben Sie eine gültige Telefonnummer ein",
    registerEmail_20053: "Währung",
    registerEmail_20054: "Transaktionswährung, die von Benutzern in Rechnung gestellt wird",
    registerEmail_20055: "bitte auswählen",
    registerEmail_20056: "Bitte geben Sie die richtige E-Mail-Adresse ein und öffnen Sie den Link in der E-Mail zur Bestätigung.",
    registerEmail_20057: "Bitte füllen Sie alle Informationen auf der rechten Seite aus, um die Registrierung abzuschließen.",
    // ****2022/1/4
    registerEmail_30058: "Bitte wählen sie lhre währung aus.",
    registerEmail_30059: "Bitte geben sie nochmal ein.",
    registerEmail_20051: "Ich habe gelesen und stimme zu",
    registerEmail_20052: "《Xcharge-Plattform-Datenschutzrichtlinie》",
    // 注册码
    registerEmail_21000: "Zugangsnummer|Rufen sie uns an, wenn sie eine chiffre brauchen：4008770227",
    registerEmail_21001: "Geben sie die einrichtungsnummer ein",
    registerEmail_21002: "Einlesezeichen falsch.",
    registerEmail_21003: "Unter",
    registerEmail_21004: "Zugangsnummer",
    registerEmail_21005: "Rufen sie uns an, wenn sie eine chiffre brauchen.",
    registerEmail_21006: "Bitte geben sie ein.",
  }