export default {
  userClassDetail_00001: '分类详情',
  userClassDetail_00002: '修改分类名称',
  userClassDetail_00003: '营销活动',
  userClassDetail_00004: '分类用户',
  userClassDetail_00005: '分类条件',
  userClassDetail_00006: '系统根据分类条件实时自动更新分类用户列表',
  userClassDetail_00007: '用户昵称、手机号、车牌号',
  userClassDetail_00008: '用户列表',
  userClassDetail_00009: '导出用户',
  userClassDetail_00010: '区域',
  userClassDetail_00011: '最近一次充电订单支付时间在距今日',
  userClassDetail_00012: '充电订单支付时间在距今日',
  userClassDetail_00013: '单个用户非团体且已支付的充电订单：',
  userClassDetail_00014: '累计订单数',
  userClassDetail_00015: '至',
  userClassDetail_00016: '累计实付金额（元）',
  userClassDetail_00017: '累计充电量（度）',
  userClassDetail_00018: '首次订单支付时间在距今日',
  userClassDetail_00019: '您确定删除此用户分类？',
  userClassDetail_00020: '删除此用户分类成功',
  userClassDetail_00021: '输入不可为空',
  userClassDetail_00022: '此操作将下载用户的Excel文件, 是否继续?',
  userClassDetail_00023: '操作',
  userClassDetail_00024: '全部',
  userClassDetail_00025: "平均值",
  userClassDetail_00026: "在平均值（含）上的用户累计订单数的平均值",
}