export default {
login_00001: "login to operator platform",
login_00002: "login account",
login_00003: "enter password",
login_00004: "login",
login_00005: "user name and password cannot be empty",
login_00006: "operator platform",
login_00007: "I have registered and now log in",
login_00008: "login",
registerEmail_00001: "registration",
registerEmail_00002: "franchised operator",
registerEmail_00003: "mailbox authentication",
registerEmail_00004: "fill in registration information",
registerEmail_00005: "find charging operator",
registerEmail_00006: "query",
registerEmail_00007: "please enter the invitation code sent to you by the operator",
registerEmail_00008: "operator name",
registerEmail_00009: "settlement currency",
registerEmail_00010: "join the operator",
registerEmail_00011: "join the charging operator alliance",
registerEmail_00012: "fill in mailbox",
registerEmail_00013: "we have sent an email to {email} you filled in. Please click the verification link in the email to complete the verification and fill in the registration information",
registerEmail_00014: "contact name | how to call you",
registerEmail_00015: "please enter your name",
registerEmail_00016: "operator name | name viewed by charging user",
registerEmail_00017: "no more than {0} words",
registerEmail_00018: "login account | such as xcharge",
registerEmail_00019: "use letters and numbers",
registerEmail_00020: "login password | Contains at least 6 characters, including at least 1 digit, 1 letter, and 1 special character",
registerEmail_00021: "confirm password | consistent with login password",
registerEmail_00022: "mobile number verification",
registerEmail_00023: "bind WeChat official account",
registerEmail_00024: "Chinese is recommended",
registerEmail_00025: "please enter the operator name",
registerEmail_00026: "no qualified operators found",
registerEmail_00027: "micro signal",
registerEmail_00028: "invitation code used",
registerEmail_00029: "invitation code does not exist",
registerEmail_00030: "mailbox cannot be empty",
registerEmail_00031: "please enter your email address so that we can contact you",
registerEmail_00032: "please fill in the correct email",
registerEmail_00033: "operation failed",
registerEmail_00034: "server response timeout",
registerEmail_00035: "please get the official account first.",
registerEmail_10036: "operator platform",
registerEmail_10037: "please select a language",
registerEmail_10038: "I have registered and now log in",
registerEmail_10039: "login",
registerEmail_10040: "login to operator platform",
registerEmail_10041: "login to user group management platform",
registerEmail_10042: "setting succeeded",
registerEmail_10043: "currency type",
registerEmail_10044: "please enter the password again",
registerEmail_10045: "the two passwords are inconsistent",
registerEmail_10046: "please enter the verification code",
registerEmail_10047: "please enter a numeric value",
registerEmail_10048: "please enter 4 digits",
registerEmail_10049: "please enter the correct contact name",
registerEmail_10050: "account format is a combination of numbers and letters",
registerEmail_10051: "password is a combination of letters or numbers",
registerEmail_10052: "please enter the contact name",
registerEmail_10053: "please enter the operator name",
registerEmail_10054: "the name does not exceed {0} characters",
registerEmail_10055: "please enter an account",
registerEmail_10056: "please enter password",
registerEmail_10057: "length between 6 and 20 characters",
registerEmail_10058: "Please read the privacy policy carefully first to confirm whether you agree!",
registerEmail_10059: "please enter your mobile phone number",
registerEmail_10060: "please enter the correct mobile phone number",
registerEmail_20050: "mobile number verification",
registerEmail_20051: "I have read and agreed",
registerEmail_20052: "smart charging operator platform privacy policy",
registerEmail_20053: "currency",
registerEmail_20054: "transaction currency seen by charging user",
registerEmail_20055: "please select",
// *****
registerEmail_20056: "Please fill in the correct email address and open the link in the email for verification.",
registerEmail_20057: "Please fill in all the information on the right to complete the registration.",
registerEmail_20058: "Please fill in all the information on the right to proceed to the next step.",
registerEmail_20059: "Verify the phone number to complete the binding",
registerEmail_20060: "WeChat public account binding",
registerEmail_20061: "Verify the phone number to the next step",
// ****2022/1/4
registerEmail_30058: "Please select currency",
registerEmail_30059: "Please enter again",
// ****绑定公众号回显--当前页面独有
registerEmail_40000: "An unknown error",
registerEmail_40001: "The public account has been bound by another carrier.",
registerEmail_40002: "You are bound to this public account.",
registerEmail_40003: "You are bound to a public account:",
registerEmail_40004: "prompt",
registerEmail_40005: "determine",
// 注册码
registerEmail_21000: "Registration code|If you need registration code, please contact us：4008770227",
registerEmail_21001: "Please enter the registration code",
registerEmail_21002: "Registration code error",
registerEmail_21003: "Next",
registerEmail_21004: "Registration code",
registerEmail_21005: "If you need registration code, please contact us.",
registerEmail_21006: "Please set a password",
}