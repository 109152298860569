export default {
    accountManage_00001: "请输入账号、拥有人、手机号",
    accountManage_00002: "账号",
    accountManage_00003: "拥有人",
    accountManage_00004: "手机号",
    accountManage_00005: "角色",
    accountManage_00006: "添加账号",
    accountManage_00007: "操作",
    accountManage_00008: "管理场站范围",
    accountManage_00009: "添加账户",
    accountManage_000010: "账户列表",
    accountManage_000011: "编辑账号",
    accountManage_000012: "登录平台的账号",
    accountManage_000013: "密码",
    accountManage_000014: "登录平台的密码",
    accountManage_000015: "最少6位，且至少包含1个数字及1个字母以及1个特殊字符",
    accountManage_000016: "拥有人",
    accountManage_000017: "使用真实姓名方便后续查找",
    accountManage_000018: "手机号",
    accountManage_000019: "方便他人联系管理员",
    accountManage_000020: "角色",
    accountManage_000021: "运营商管理员",
    accountManage_000022: "请选择",
    accountManage_000023: "请选择账号角色",
    accountManage_000024: "操作成功",
    accountManage_000025: "请输入充电站名称",
    accountManage_000026: "添加充电站成功",
    accountManage_000027: "已选充电站",
    accountManage_000028: "删除成功",
    accountManage_000029: "超级管理员",
    accountManage_000030: "您确定要删除吗?",
    accountManage_000031: "已取消删除",
    accountManage_000032: "添加时间",
    accountManage_000033: "邮箱",
    accountManage_000034: "已绑定",
    accountManage_000035: "解除绑定",
    accountManage_000036: "支持字母和数字，不超过20个字",
}