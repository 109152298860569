
export default {
  cardGroup_00001: "确定将充电卡群组确定将充电卡群组{title}删除吗?",
  cardGroup_00002: "共推送了{allDevice}台设备，{sucDevice}台成功，{faiDevice}台失败。",
  cardGroup_00003: "工作模式|(二选一)",
  cardGroup_00004: "{id}(已挂失)",
  cardGroup_00005: "无法读取卡片! 提示:请确认读卡器已连接并将NFC卡放在读卡器上。",
  cardGroup_00006: "该卡不是空卡",
  cardGroup_00007: "该卡是空卡",
  cardGroup_00008: "写卡失败：{msg}",
  cardGroup_00009: "充电卡组详情",
  cardGroup_00010: "运营商充电卡管理",
  cardGroup_00011: "充电卡组管理",
  cardGroup_00012: "卡组管理",
  cardGroup_00013: "绑定限定站点",
  cardGroup_00014: "推送离线白名单",
  cardGroup_00015: "查询充值记录",
  cardGroup_00016: "查询订单",
  cardGroup_00017: "删除卡组",
  cardGroup_00018: "操作已取消",
  cardGroup_00019: "充电卡组详情-在线卡组",
  cardGroup_00020: "充电卡组详情-离线卡组",
  cardGroup_00021: "充电卡组详情-超级卡组",
  cardGroup_00022: "如果设备离线，推送白名单后，可以支持本充电卡组的充电卡启动充电。创建、挂失卡片后，需要重新推送白名单。",
  cardGroup_00023: "重试",
  cardGroup_00024: "设备编号",
  cardGroup_00025: "设备名称",
  cardGroup_00026: "推送结果",
  cardGroup_00027: "成功",
  cardGroup_00028: "失败",
  cardGroup_00029: "等待响应",
  cardGroup_00030: "关 闭",
  cardGroup_00031: "推送失败，没有绑定站点。",
  cardGroup_00032: "推送失败",
  cardGroup_00033: "推送离线白名单",
  cardGroup_00034: "在线卡组",
  cardGroup_00035: "离线卡组",
  cardGroup_00036: "超级卡组",
  cardGroup_00037: "充电卡组名称",
  cardGroup_00038: "修改",
  cardGroup_00039: "保存",
  cardGroup_00040: "取消",
  cardGroup_00041: "登录账号",
  cardGroup_00042: "登录密码",
  cardGroup_00043: "下载制卡NFC（APP）",
  cardGroup_00044: "新建简易在线身份卡",
  cardGroup_00045: "为用户录入在线身份卡。卡号需为NFC读卡程序识别出来的卡号。 在线身份卡可以进行充值，并且无需配置充电桩即可刷卡充电。",
  cardGroup_00046: "新建即插即充卡",
  cardGroup_00047: "为用户录入即插即充卡。即插即充卡可以进行充值，需要在充电桩配置可以开启即插即充方式充电。",
  cardGroup_00048: "请输入卡号、用户昵称、手机号、编号",
  cardGroup_00049: "卡片类型：",
  cardGroup_00050: "卡号",
  cardGroup_00051: "编号",
  cardGroup_00052: "用户姓名(手机号)",
  cardGroup_00053: "车牌号",
  cardGroup_00054: "卡片类型",
  cardGroup_00055: "离线启停卡",
  cardGroup_00056: "离线储值卡",
  cardGroup_00057: "在线身份卡",
  cardGroup_00058: "即插即充卡",
  cardGroup_00059: "配置卡",
  cardGroup_00060: "余额",
  cardGroup_00061: "操作",
  cardGroup_00062: "详情",
  cardGroup_00063: "请保存上一步操作",
  cardGroup_00064: "参数为空",
  cardGroup_00065: "新建简易身份卡",
  cardGroup_00066: "来源",
  cardGroup_00067: "联系人姓名",
  cardGroup_00068: "联系人手机号",
  cardGroup_00069: "密钥",
  cardGroup_00070: "充值金额",
  cardGroup_00071: "登录密码",
  cardGroup_00072: "充电卡制作成功",
  cardGroup_00073: "可以在",
  cardGroup_00074: "「充电卡管理」",
  cardGroup_00075: "充电卡群组的",
  cardGroup_00076: "「详情」",
  cardGroup_00077: "中进行如下操作：",
  cardGroup_00078: "1、给存储类型的充电卡充值；",
  cardGroup_00079: "2、查看充值记录；",
  cardGroup_00080: "3、修改充电卡的联系人信息；",
  cardGroup_00081: "完 成",
  cardGroup_00082: "确 定",
  cardGroup_00083: "请输入身份卡号",
  cardGroup_00084: "请输入正确的卡号",
  cardGroup_00085: "卡号不能包含中文",
  cardGroup_00086: "请输入卡片来源",
  cardGroup_00087: "来源不允许填写“即插即充”",
  cardGroup_00088: "请输入正确的车牌号",
  cardGroup_00089: "请输入正确的手机号",
  cardGroup_00090: "充值金额格式不正确",
  cardGroup_00091: "请输入密码",
  cardGroup_00092: "在线身份卡录入成功",
  cardGroup_00093: "新建即插即充卡",
  cardGroup_00094: "查询用户匹配的车辆VIN",
  cardGroup_00095: "完善卡片信息",
  cardGroup_00096: "完成新建",
  cardGroup_00097: "此手机号2周内匹配多辆车（{type}），请手动确定车辆（{type}）！",
  cardGroup_00098: "用户昵称|手机号",
  cardGroup_00099: "车辆VIN",
  cardGroup_00100: "最近一次|充电结束时间",
  cardGroup_00101: "最近一次|充电站点",
  cardGroup_00102: "确认",
  cardGroup_00103: "上一步",
  cardGroup_00104: "下一步",
  cardGroup_00105: "即插即充",
  cardGroup_00106: "站点列表",
  cardGroup_00107: "添加站点",
  cardGroup_00108: "站点名称",
  cardGroup_00109: "运营商",
  cardGroup_00110: "站点详情",
  cardGroup_00111: "移除",
  cardGroup_00112: "确定删除该站点吗?",
  cardGroup_00113: "提示",
  cardGroup_00114: "确定",
  cardGroup_00115: "输入站点名称、运营商名称",
  cardGroup_00116: "站点状态",
  cardGroup_00117: "对外开放",
  cardGroup_00118: "充电桩",
  cardGroup_00119: "交",
  cardGroup_00120: "直",
  cardGroup_00121: "添加",
  cardGroup_00122: "已添加",
  cardGroup_00123: "已启用",
  cardGroup_00124: "未启用",
  cardGroup_00125: "开放",
  cardGroup_00126: "不开放",
  cardGroup_00127: "车辆MAC",
  cardGroup_00128: "查询用户匹配的车辆MAC",
  cardGroup_10000: "制作充电卡",
  cardGroup_10001: "组内卡充电SOC限制",
  cardGroup_10002: "请输入1-100整数",
  cardGroup_10003: "下载批量导入模版",
  cardGroup_10004: "批量导入简易在线身份卡",
  cardGroup_10005: "批量导入即插即充卡",
  cardGroup_10006: "1、请先下载批量导入模板，在模版中填入充电卡相关信息。",
  cardGroup_10007: "如您批量导入即插即充卡，模板中卡号请录入车辆充电中使用的车辆VIN。",
  cardGroup_10008: "如您批量导入简易在线身份卡，模板中卡号请录入NFC读卡程序识别出来的卡号。",
  cardGroup_10009: "2、请根据充电卡类型，选择对应的按钮导入充电卡。",
  cardGroup_10010: "请使用模板批量导入",
  cardGroup_10011: "在模版文件中，以下卡号请正确输入手机号和车牌号。",
  cardGroup_10012: "在模版文件中，以下卡号在平台中已存在。",
  cardGroup_10013: "总计{totalCount}张，成功导入{successCount}张。",
  cardGroup_10014: "总计{totalCount}张，成功导入{successCount}张，失败导入{failCount}张。",
  cardGroup_10015: "在模版文件中，以下卡号在平台中其他卡组已存在。",
  cardGroup_10016: "卡组：{0}",
  cardGroup_10017: "卡号：",

  support_u2_card: "支持离线储值卡",
  support_u1_card: "支持离线启停卡",
  working_mode_title: "欢迎信息|(充电桩屏幕展示信息)",
  config_card_welcome: "欢迎使用智能充电桩",
  config_card_tip_1: "额定电流|(实际电流以充电桩设备为准)",
  addTimeInterval: "添加时段",
  addTimeIntervalPrompt: "最多可以添加12个时段",
  beginTime: "开始时间",
  endTime: "结束时间",
  electricity_fee: "电费",
  service_fee: "服务费",
  operation: "操作",
  electric_unit: "度",
  config_card_wifi_ssid: "Wi-Fi SSID",
  config_card_wifi_pass: "Wi-Fi 密码",
  select_input: "选填",
  elec_lock: "电磁锁",
  elec_lock_status: "电磁锁状态",
  unavailable: "不可用",
  available: "可用",
  unlock_before_pay: "充满解锁",
  unlock_after_pay: "结算解锁",
  radar: "雷达",
  radar_status: "雷达状态",
  config_card_make_success: "配置卡制作成功",
  add_charge_card_remind_3: "1、给存储类型的充电卡充值；",
  add_charge_card_remind_4: " 2、查看充值记录；",
  add_charge_card_remind_5: "3、修改充电卡的联系人信息；",
  finish: "完成",
  nextStep: "下一步",
  preStep: "上一步",
  ac: "交",
  dc: "直",
  user_control_add: "添加",
  user_control_added: "已添加",
  enabled: "已启用",
  disabled: "未启用",
  open: "开放",
  unopen: "不开放",
  charge_card_operation_remind_1: "无法读取卡片! 提示:请确认读卡器已连接并将NFC卡放在读卡器上。",
  charge_card_operation_remind_2: "该卡不是空卡",
  charge_card_operation_remind_3: "该卡是空卡",
  charge_card_operation_remind_4: "该卡不属于当前集团账户",
}