export default {
    chargeMonitor_00001: 'Ladestation auswählen',
    chargeMonitor_00002: 'Ladestationsname',
    chargeMonitor_00003: 'CPO',
    chargeMonitor_00004: 'Echtzeitige Überwachung',
    chargeMonitor_00005: 'Vorgänge anzeigen',
    chargeMonitor_00006: 'Vorgängeinformationen',
    chargeMonitor_00007: 'Ladeinformationen',
    chargeMonitor_00008: 'aktuelle Vorgänge',
    chargeMonitor_00009: 'Ladeelektrizität',
    chargeMonitor_00010: "Gateway",
    chargeMonitor_00011: "unbesetzt",
    chargeMonitor_00012: "in der Nutzung",
    chargeMonitor_00013: "abnormal",
    chargeMonitor_00014: "nicht aktiviert",
    chargeMonitor_00015: "aktiviert",
    chargeMonitor_00016: "online",
    chargeMonitor_00017: "offline",
    chargeMonitor_00018: "Störung",
    chargeMonitor_00019: "reserviert",
    chargeMonitor_00020: "gesteckt",
    chargeMonitor_00021: "im Laden",
    chargeMonitor_00022: "Platz zu machen",
    chargeMonitor_00023: "zu ziehende",
    chargeMonitor_00024: "besetzt",
    chargeMonitor_00025: "Bitte geben Sie CPO-Name ein",
    chargeMonitor_00026: "Zurückgesetzt",
    chargeMonitor_00027: "nicht zurückgesetzt",
    chargeMonitor_00028: "Zeigt alle störungen des laufwerks",
    chargeMonitor_00029: "Betrachten sie alle offline laufwerke",
    chargeMonitor_00030: "Neuen Fenstertab hinzufügen",
    chargeMonitor_00031: "Ein",
    chargeMonitor_00032: "Möchten Sie alle Überwachungsseiten gemäß den letzten Browser-Tab-Einstellungen öffnen?",
    chargeMonitor_00033: "Bitte wählen Sie eine zu überwachende Station im aktuellen Fenster",
    chargeMonitor_00034: "Einrichtungshilfe",
    chargeMonitor_00035: "Bitte stellen Sie die Stationen, die Sie überwachen möchten, in jeder Registerkarte ein (bis zu 10), klicken Sie auf die Schaltfläche 'Öffnen', und die entsprechende Browser-Registerkarte wird geöffnet, um die Überwachungsstationen gemäß Ihren Einstellungen anzuzeigen",
    chargeMonitor_00036: 'Wenn Ihr Browser das neu geöffnete Fenster gestoppt hat, überprüfen Sie bitte die Einstellungen Ihres Browsers und lassen Sie Pop-up-Fenster zu.',
    chargeMonitor_00037: 'Alle auswählen',
    chargeMonitor_00038: 'Ausgewählte Websites auf der aktuellen Seite',
    chargeMonitor_00039: 'Nottaste ausgelöst, Aufladung angehalten',
    chargeMonitor_00040: 'Funktionsstörung der Ausrüstung, Aufladung ausgesetzt',
    chargeMonitor_00041: 'Voll aufgeladen oder festgelegte Bedingungen erreicht',
    chargeMonitor_00042: 'Gerätestörung, Ladestopp',
    chargeMonitor_00043: 'Fernladung durch Betreiber gestoppt',
    chargeMonitor_00044: 'Laden von der Ladestation stoppen',
    chargeMonitor_00045: 'Aufladung',
    chargeMonitor_00046: 'Eingefügt',
    chargeMonitor_00047: 'Abgeschlossen',
    chargeMonitor_00048: 'Besetzt',
    chargeMonitor_00049: 'Abrutly gestoppt',
    chargeMonitor_00050: 'Aufladung abgeschlossen',
    chargeMonitor_00051: 'Warten auf verfügbare Energie',
    chargeMonitor_00052: 'Abnormales Tagebuch',
    chargeMonitor_00053: "Geschätzt",
    chargeMonitor_00054: "Voll aufgeladen",
    chargeMonitor_00055: 'Gebucht ',
    chargeMonitor_00056: "Bitte wechseln Sie das aktuelle Tab-Fenster, um das Label zu löschen",
    chargeMonitor_00057: "Der erste Eintrag kann nicht gelöscht werden",

}