export default {
  cardAnalysis_00001: "分析",
  cardAnalysis_00002: "已抵用总金额",
  cardAnalysis_00003: "电量包",
  cardAnalysis_00004: "可使用电量(度)",
  cardAnalysis_00005: "已用完电量(度)",
  cardAnalysis_00006: "已失效电量(度)",
  cardAnalysis_00007: "待生效电量(度)",
  cardAnalysis_00008: "电量同时抵用电费及服务费",
  cardAnalysis_00009: "电量抵用服务费",
  cardAnalysis_00010: "代金券",
  cardAnalysis_00011: "可使用代金券",
  cardAnalysis_00012: "已用完代金券",
  cardAnalysis_00013: "已失效代金券",
  cardAnalysis_00014: "待生效代金券",
  cardAnalysis_00015: "现金抵用电费及服务费",
  cardAnalysis_00016: "现金抵用服务费",
  cardAnalysis_00017: "抵用券",
  cardAnalysis_00018: "可使用数量",
  cardAnalysis_00019: "已用完数量",
  cardAnalysis_00020: "已失效数量",
  cardAnalysis_00021: "待生效数量",
  cardAnalysis_00022: "折扣券",
  cardAnalysis_00023: "用户拥有",
  cardAnalysis_00024: "电量包总电量",
  cardAnalysis_00025: "代金券总金额",
  cardAnalysis_00026: "抵用券总数量",
  cardAnalysis_00027: "折扣券总数量",
  cardAnalysis_00028: "个",
  cardAnalysis_00029: "度",
  cardAnalysis_00030: "已折扣总金额",
  cardAnalysis_00031: "用户卡券领取时间",
  cardAnalysis_00032: "统计的是在选择的用户卡券领取时间范围内的卡券，到当前时间的使用消耗情况。",
}