export default {
    createDepot_00001: '创建场站',
    createDepot_00002: '场站中心',
    createDepot_00003: '上一步',
    createDepot_00004: '支持车型',
    createDepot_00005: '乘用车',
    createDepot_00006: '物流车',
    createDepot_00007: '中巴车',
    createDepot_00008: '大巴车',
    createDepot_00009: '场站名称',
    createDepot_00010: '推荐使用地标作为名称',
    createDepot_00011: '场站地址',
    createDepot_00012: '获取位置',
    createDepot_00013: '省',
    createDepot_00014: '市',
    createDepot_00015: '区',
    createDepot_00016: '街道',
    createDepot_00017: '可修改具体街道的描述',
    createDepot_00018: '下一步',
    createDepot_00019: '场站基本信息',
    createDepot_00020: '场站资费标准',
    createDepot_00021: '创建完成',
    createDepot_00022: '电单车',
    createDepot_00023: '选择资费',
    createDepot_00024: '新建资费',
    createDepot_00025: '开始时间',
    createDepot_00026: '结束时间',
    createDepot_00027: '电费({type}/{uint})',
    createDepot_00028: '服务费({type}/{uint})',
    createDepot_00029: '延时费({type}/{uint})',
    createDepot_00030: '谷峰平时段',
    createDepot_00031: '未定义',
    createDepot_00032: '尖',
    createDepot_00033: '峰',
    createDepot_00034: '平',
    createDepot_00035: '谷',
    createDepot_00036: '可以在',
    createDepot_00037: '查看场站详情；',
    createDepot_00038: '查询该场站的充电订单；',
    createDepot_00039: '管理场站的设备；',
    createDepot_00040: '设置场站的资费标准；',
    createDepot_00041: '设置场站的运营策略等。',
    createDepot_00042: '进入场站管理',
    createDepot_00043: '最多可以添加{0}个时段',
    createDepot_00044: '填写场站名称，支持充电车型，场站地址，均为必填项。',
    createDepot_00045: '可以新建资费或者下拉框选择已有资费。',
    createDepot_00046: '您的场站已经创建完成，快去体验吧！',
    createDepot_00047: '场站创建完成!',
    createDepot_00048: '[场站管理]',
    createDepot_00049: '中进行如下操作:',
    createDepot_00050: '直流资费标准',
    createDepot_00051: '新建交流资费',
    createDepot_00052: '交流资费标准',
    createDepot_00053: '新建直流资费',
    createDepot_00054: '资费名称',
    createDepot_00055: '选择',
    createDepot_00056: '添加时段',
    createDepot_00057: '请填写资费标准',
    createDepot_00058: '删除',
    createDepot_00059: '取 消',
    createDepot_00060: '确 定',
    createDepot_00061: '请完善站点信息',
    createDepot_00062: '资费时段需从0开始至24结束',
    createDepot_00063: '请填写正确的资费标准',
    createDepot_00064: '新建成功',
    createDepot_00065: '选择充电站位置坐标',
    createDepot_00066: '名称',
    createDepot_00067: '详细地址',
    createDepot_00068: '使用此位置',
    createDepot_00069: '地址、街道、房屋名称',
    createDepot_00070: '查找',
    createDepot_00071: '当前位置',
    createDepot_00072: '获取位置失败!',
    createDepot_00073: '该资费名称已被占用',
    createDepot_00074: '请选择或新建资费标准',
    createDepot_00075: '当前使用资费：',
    createDepot_00076: '请输入资费名称',
    createDepot_00077: '使用货币',
    createDepot_00078: '该站点需要选择{type}的资费类型，当前没有该资费模板，请新建资费。',
    createDepot_00079: '直流桩和交流桩资费必须选择相同货币类型。',
    createDepot_00080: '场站货币类型',
    createDepot_00081: '没有需要的货币类型？',
    createDepot_00082: '点击添加',
    createDepot_00083: '添加',
    createDepot_00084: '取消',
    createDepot_00085: '添加失败',
    createDepot_00086: '时区',
    createDepot_00087: '添加或管理场站设备',
    createDepot_00088: '场站获取失败',
}