export default {
    maintenanceHome_00001: "Current number of faulted chargers",
    maintenanceHome_00002: "Current number of offline chargers",
    maintenanceHome_00003: "The work number is not closed",
    maintenanceHome_00004: "Number of chargers that need to maintain power module",
    maintenanceHome_00005: "Number of chargers that need to maintain other parts",
    maintenanceHome_00006: "View",
    maintenanceHome_00007: "Station name",
    maintenanceHome_00008: "Real-time monitoring",
    maintenanceHome_00009: "Abnormal rate of charger's responsibility today",
    maintenanceHome_000010: "Abnormal rate of vehicle's responsibility today",
    maintenanceHome_000011: "Anomaly rate analysis",
    maintenanceHome_000012: "On-line analysis",


}