export default {
  orderTriggerOpt_00001: 'New trigger',
  orderTriggerOpt_00002: 'Trigger name',
  orderTriggerOpt_00003: 'Whether the trigger is enabled',
  orderTriggerOpt_00004: 'All',
  orderTriggerOpt_00005: 'Enable',
  orderTriggerOpt_00006: 'Operation statistics trigger',
  orderTriggerOpt_00007: 'Charger report trigger',
  orderTriggerOpt_00008: 'Ticket Trigger Category',
  orderTriggerOpt_00009: 'Trigger condition',
  orderTriggerOpt_00010: 'Ticket Processor',
  orderTriggerOpt_00011: 'Ticket follower',
  orderTriggerOpt_00012: 'Ticket notification time',
  orderTriggerOpt_00013: 'Edit trigger',
  orderTriggerOpt_00014: 'When the equipment reports a equipment failure or a equipment abnormality, if the trigger does not return to normal after the time interval set by the trigger, it will automatically initiate a Ticket troubleshooting. (Note: Some equipment failures will automatically recover. If the time interval is set too short, it is easy to generate false alarms)',
  orderTriggerOpt_00015: 'All',
  orderTriggerOpt_00016: 'Ticket categories',
  orderTriggerOpt_00017: 'Equipment serial number',
  orderTriggerOpt_00018: 'All Equipments by manufacturer',
  orderTriggerOpt_00019: 'All models',
  orderTriggerOpt_00020: 'Pending Ticket',
  orderTriggerOpt_00021: 'Processing Ticket',
  orderTriggerOpt_00022: 'The Ticket has been resolved',
  orderTriggerOpt_00023: 'The Ticket has been closed',
  orderTriggerOpt_00024: 'Ticket list',
  orderTriggerOpt_00025: 'Export Ticket',
  orderTriggerOpt_00026: 'Ticket number',
  orderTriggerOpt_00027: 'Title of the Ticket',
  orderTriggerOpt_00028: 'Categories',
  orderTriggerOpt_00029: 'Priority',
  orderTriggerOpt_00030: 'Status',
  orderTriggerOpt_00031: 'Problem station',
  orderTriggerOpt_00032: 'Processor',
  orderTriggerOpt_00033: 'Originator',
  orderTriggerOpt_00034: 'Ticket start time',
  orderTriggerOpt_00035: 'Update time',
  orderTriggerOpt_00036: 'Problem source',
  orderTriggerOpt_00037: 'Operation',
  orderTriggerOpt_00038: 'Return to Ticket',
  orderTriggerOpt_00039: 'Processed',
  orderTriggerOpt_00040: 'Order',
  orderTriggerOpt_00041: 'Transfer Ticket',
  orderTriggerOpt_00042: 'Close the Ticket',
  orderTriggerOpt_00043: 'Add to knowledge base',
  orderTriggerOpt_00044: 'Details',
  orderTriggerOpt_00045: 'Very urgent',
  orderTriggerOpt_00046: 'Urgent',
  orderTriggerOpt_00047: 'General',
  orderTriggerOpt_00048: 'Pending',
  orderTriggerOpt_00049: 'Processing',
  orderTriggerOpt_00050: 'Processed',
  orderTriggerOpt_00051: 'closed',
  orderTriggerOpt_00052: 'Remarks',
  orderTriggerOpt_00053: 'Please enter the content',
  orderTriggerOpt_00054: 'Solution',
  orderTriggerOpt_00055: 'Transfer handler',
  orderTriggerOpt_00056: 'Accpet the Resquest',
  orderTriggerOpt_00057: 'Whether to confirm the Ticket',
  orderTriggerOpt_00058: 'Confirm Ticket receipt',
  orderTriggerOpt_00059: 'Transfer Ticket',
  orderTriggerOpt_00060: 'Confirm transfer',
  orderTriggerOpt_00061: 'Transfer reason',
  orderTriggerOpt_00062: 'Close the Ticket',
  orderTriggerOpt_00063: 'Are you sure to close the Ticket',
  orderTriggerOpt_00064: 'Confirm close',
  orderTriggerOpt_00065: 'Close reason',
  orderTriggerOpt_00066: 'Return to Ticket',
  orderTriggerOpt_00067: 'Are you sure to return the Ticket',
  orderTriggerOpt_00068: 'Confirm return',
  orderTriggerOpt_00069: 'Return reason',
  orderTriggerOpt_00070: 'The Ticket has been resolved',
  orderTriggerOpt_00071: 'Do you confirm that the Ticket has been resolved',
  orderTriggerOpt_00072: 'Confirmation resolved',
  orderTriggerOpt_00073: 'Please fill in completely',
  orderTriggerOpt_00074: 'Operation successful',
  orderTriggerOpt_00075: 'Arrived',
  orderTriggerOpt_00076: 'Save successfully',
  orderTriggerOpt_00077: 'Station name',
  orderTriggerOpt_00078: 'Station status',
  orderTriggerOpt_00079: 'Add station',
  orderTriggerOpt_00080: 'Station trigger condition',
  orderTriggerOpt_00081: 'Continuous',
  orderTriggerOpt_00082: 'day(s)',
  orderTriggerOpt_00083: 'Over',
  orderTriggerOpt_00084: 'connectors have no transaction',
  orderTriggerOpt_00085: 'Please select a site',
  orderTriggerOpt_00086: 'Please enter the site trigger condition',
  orderTriggerOpt_00087: 'Multiple remove',
  orderTriggerOpt_00088: 'Please complete the form',
  orderTriggerOpt_00089: 'Abnormal transactions',
  orderTriggerOpt_00090: 'After the Equipment reports a fault/abnormal',
  orderTriggerOpt_00091: 'Issue description',
  orderTriggerOpt_00092: 'Abnormal order statistics-charging port',
  orderTriggerOpt_00093: 'Abnormal order statistics-user',
  orderTriggerOpt_00094: 'No Charging Charger',
  orderTriggerOpt_00095: 'Equipment trigger',
  orderTriggerOpt_00096: 'Selected',
  orderTriggerOpt_00097: 'Enter an integer ≥ 0',
  orderTriggerOpt_00098: 'The trigger contains fault codes',
  orderTriggerOpt_00099: "Trigger frequency",
  orderTriggerOpt_000100: "Every",
  orderTriggerOpt_000101: "day",
  orderTriggerOpt_000102: "Each charger will report each fault only",
  orderTriggerOpt_000103: "Each charger will report all faults only",
  orderTriggerOpt_000104: "times",
  orderTriggerOpt_000105: "Default",
  orderTriggerList_00001:"Charger offline",
  orderTriggerList_00002:"Emergency stop button pressed",
  orderTriggerList_00003:"Power module alarmed",
  orderTriggerList_00004:"Electromagnetic lock enable/unlock operation failed",
  orderTriggerList_00005:"Charger manufacturer",
  orderTriggerList_00006:"Trigger Settings", 
  orderTriggerList_00007:"Trigger template",
}