export default {
    validity: 'Expiry date',
    charging_package2:"Card coupon sales link",
    all:"All",
    copy: 'Copy',
    charging_package:"Deals&Coupons sale management",
    charging_package0:"Deals&Coupons sale",
    charging_package1:"After adding a card voucher, check the box to sell it on the shelf, and you can sell it in [WeChat Official account] - [Personal Centre] - [Card Voucher Purchase].",
    charging_package3:"Please enter coupon name",
    charging_package4:"Deals&Coupons name",
    charging_package5:"Deals&Coupons type",
    charging_package6:"Deals&Coupons amount",
    charging_package7:"Price（€）",
    charging_package8:"Deals&Coupons details",
    charging_package9:"Add coupon sales",
    charging_package10:"Coupon amount（€）",
    charging_package11:"Coupon price（€）",
    charging_package12:"Day {count} from the date of purchase/redemption of the card coupon by the user",
    charging_package13:"Manage redemption code",
    charging_package14:"Generate redemption code",
    charging_package15:"Redemption code",
    charging_package16:"Distribution channel",
    charging_package17:"Change to price (€)",
    charging_package18:"Generate number",
    charging_package19:" Counts of redemptions",
    charging_package20:"Number of redemptions",
    charging_package21:"Purchased coupon price(€)",
    charging_package22:"Redeemed coupon price（€）",
    charging_package23:"Unused electricity（kwh）",
    charging_package24:"Expired electricity（kwh）",
    charging_package25:"Electricity used（kwh）",
    charging_package26:"Amount of electricity consumption credit（€）",
    charging_package27:"On-Sale",
    charging_package28:"Please enter the value",
    charging_package29:"Coupon sales details",
    charging_package30:"Statistic information",
    charging_package31:"Card voucher price change",
    charging_package32:"User acquirement details",
    charging_package33:"Payment price（€）",
    charging_package34:"Access time",
    charging_package35:"Current price（€）",
    charging_package36:"Changed price（€）",
    charging_package37:"Not redeemed",
    charging_package38:"Redeemed",
    charging_package39:"No longer valid",
    charging_package40:"Please enter redeem code",
    charging_package41:"Expiry date",
    charging_package42:"Max. 10000",
    charging_package43:"Redemption code generated successfully！",
    charging_package44:"Confirm to remove this coupon from the list！",
    charging_package45:"Cash offset electricity and service fee",
    charging_package46:"Use of electricity to offset both electricity and service fee",
    charging_package47:"Cash offset service fee",
    charging_package48:"Electricity amount offset service charge",
    charging_package49:"Coupon amount（kwh）",
    charging_package50:"Please enter a card voucher name of less than 16 characters",
    charging_package51:"Copy success！",
    charging_package52:"Day {day} from the date of purchase/redemption of the card voucher by the user",
    charging_package53:"Please complete the coupon sales information",
    charging_package54:"Please select the site where you want to add the coupon sales",
    charging_package55:"Purchase",
    charging_package56:"Reedem",
    charging_package57:"permanent",
    charging_package58:"Consumed cash（€）",
    charging_package59:"Not consumed cash（€）",
    charging_package60:"Lapsed cash（€）",
    charging_package61:"Available",
    charging_package62:"Used",
    charging_package63:"To be validated",
    charging_package64:"Remaining balance",
    charging_package65:"This offer",
    charging_package66:"Credit {power} for {noney} off your charge",
    charging_package67:"offer{money}euro",
    charging_package68:"Incorrect input format",
    charging_package69:"Date of purchase/redemption of card vouchers by the user",
    charging_package70:"Are you sure to export this coupon redemption code？",
    delete_success:"Deletion success",

    parameters_is_empty: "Parameter is empty",
    park_placeholder1:"Support up to two decimal places！",
    modify_success: 'Modify success',

    charging_00001: 'Deals&Coupons',
    charging_00002: 'Marketing center',
    charging_00003: 'Deals&Coupons sale',
    charging_00004: 'Please enter Deals&Coupons name',
    charging_00005: 'Coupon type',
    charging_00006: 'Credit',
    charging_00007: 'Electricity ticket',
    charging_00008: 'Reset',
    charging_00009: 'Search',
    charging_00010: ' After adding the vouchers, check the "On sell" box, you can sell on shelf in [WeChat Public] - [Personal Center] - [Vouchers] - [Buy Vouchers].',
    charging_00011: 'Unpriced',
    charging_00012: 'Operate',
    charging_00013: 'Pricing',
    charging_00014: 'Deals&Coupons detail',
    charging_00015: 'Pricing',
    charging_00016: 'Support two decimal places',
    charging_00017: 'Cancel',
    charging_00018: 'Confirm',
    charging_00019: 'Deals&Coupons on sale',
    charging_00020: 'Please enter a number greater than 0',
}
