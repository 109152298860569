export default {
  chargingAmount_00001: '充电统计',
  chargingAmount_00002: "团体名称",
  chargingAmount_00003: "每日充电电量",
  chargingAmount_00004: "每月充电电量",
  chargingAmount_00005: "时间(日期)",
  chargingAmount_00006: "站点名称",
  chargingAmount_00007: "充电量(度)",
  chargingAmount_00008: "合计(度)",
  chargingAmount_00009: "查询充电订单",
  chargingAmount_00010: "时间(月份)",
  chargingAmount_00011: "操作",
  chargingAmount_00012: "",
  chargingAmount_00013: "",
}