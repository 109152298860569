export default {
  onlineAnalysis_00001: "场站设备在线率分析",
  onlineAnalysis_00002: "上报时间",
  onlineAnalysis_00003: "至",
  onlineAnalysis_00004: "分析",
  onlineAnalysis_00005: "场站设备",
  onlineAnalysis_00006: "在线率=1-此桩查询时间段内离线时长/查询时间段时长",
  onlineAnalysis_00007: "设备名称",
  onlineAnalysis_00008: "设备型号",
  onlineAnalysis_00009: "设备序列号",
  onlineAnalysis_000010: "在线率",
  onlineAnalysis_000011: "操作",
  onlineAnalysis_000012: "设备详情",
  onlineAnalysis_000013: "设备异常日志",
}