export default {
    createDepot_00001: 'Ladestation erstellen',
    createDepot_00002: 'Stations',
    createDepot_00003: 'Vorheriger Schritt',
    createDepot_00004: 'EV-Typ',
    createDepot_00005: 'Pkw',
    createDepot_00006: 'Logistik-EV',
    createDepot_00007: 'Lkw',
    createDepot_00008: 'Bus',
    createDepot_00009: 'Name der Station',
    createDepot_00010: 'Empfehlung: Landmarke als Name verwenden',
    createDepot_00011: 'Anschrift der Station',
    createDepot_00012: 'Standort abrufen',
    createDepot_00013: '',
    createDepot_00014: '',
    createDepot_00015: '',
    createDepot_00016: 'Straße',
    createDepot_00017: 'Hier können Sie die Beschreibung der Straße ändern',
    createDepot_00018: 'Nächster Schritt',
    createDepot_00019: 'Basisinformationen',
    createDepot_00020: 'Tarif',
    createDepot_00021: 'Erstellung abgeschlossen',
    createDepot_00022: 'E-Fahrrad',
    createDepot_00023: 'Tarif auswählen',
    createDepot_00024: 'Neuer Tarif',
    createDepot_00025: 'Startzeit',
    createDepot_00026: 'Endzeit',
    createDepot_00027: 'Elektrizitätsentgelt({type}/{uint})',
    createDepot_00028: 'Leistung({type}/{uint})',
    createDepot_00029: 'Idle fee({type}/{uint})',
    createDepot_00030: 'Haupt-/Nebentarif Zeiten',
    createDepot_00031: 'Undefiniert',
    createDepot_00032: 'Tip',
    createDepot_00033: 'peak',
    createDepot_00034: 'Flat',
    createDepot_00035: 'offpeak',
    createDepot_00036: 'Kann in',
    createDepot_00037: 'Überprüfen Sie die Stationsdaten;',
    createDepot_00038: 'Abfrage des Ladeauftrags der Station;',
    createDepot_00039: 'Verwalten Sie die Ausrüstung der Station;',
    createDepot_00040: 'den Tarifstandard des Bahnhofs festlegen;',
    createDepot_00041: 'Setzen Sie die Betriebsstrategie des Bahnhofs usw.',
    createDepot_00042: 'Management der Access Station',
    createDepot_00043: 'Max.{0} Zeiten können hinzugefügt werden',
    createDepot_00044: 'Geben Sie den Namen der Station, das Lademodell und die Stationsadresse ein, die alle erforderlich sind.',
    createDepot_00045: 'Sie können einen neuen Tarif erstellen oder einen bestehenden Tarif aus dem Dropdown-Feld auswählen.',
    createDepot_00046: 'Ihre Station wurde geschaffen. Gehen Sie und erleben Sie sie!',
    createDepot_00047: 'Einrichtung der Station abgeschlossen!',
    createDepot_00048: '[Leitung der Station]',
    createDepot_00049: 'Machen Sie folgendes in:',
    createDepot_00050: 'DC Stromtarif',
    createDepot_00051: 'Neuer AC-Tarif',
    createDepot_00052: 'AC Stromtarif',
    createDepot_00053: 'Neuer DC-Tarif',
    createDepot_00054: 'Bezeichnung des Tarifs',
    createDepot_00055: 'Wahl',
    createDepot_00056: 'Zeiten zufügen',
    createDepot_00057: 'Tarifstandard eingeben',
    createDepot_00058: 'Löschen',
    createDepot_00059: 'Abbrechen',
    createDepot_00060: 'Bestätigen',
    createDepot_00061: 'Standortinformationen bestätigen',
    createDepot_00062: 'Tarifperiode liegt zwischen 0 und 24',
    createDepot_00063: 'Bitte den korrekten Tarif eingeben',
    createDepot_00064: 'Neue erfolgreich',
    createDepot_00065: 'Bitte wählen Sie die Koordinate des Standorts',
    createDepot_00066: 'Name',
    createDepot_00067: 'Adresse',
    createDepot_00068: 'diesen Ort verwenden',
    createDepot_00069: 'Adresse, Straße und Hausnummer',
    createDepot_00070: 'lookup',
    createDepot_00071: 'site_current_location',
    createDepot_00072: 'Fehler bei der Standortwahl!',
    createDepot_00073: 'Der Tarifname ist bereits belegt',
    createDepot_00074: 'Bitte wählen Sie oder erstellen Sie einen neuen Tarifstandard',
    createDepot_00075: 'Aktuelle Nutzungsgebühren:',
    createDepot_00076: 'Bitte geben Sie den Tarifnamen ein',
    createDepot_00077: 'Währung',
    createDepot_00078: 'Diese Ladestation muss eine Tarifart von {type} auswählen. Es gibt derzeit keine solche Tarifvorlage, Bitte erstellen Sie einen neuen Tarif.',
    createDepot_00079: 'Für Gleichstrom- und Wechselstrompfahltarife muss der gleiche Währungstyp gewählt werden',
    createDepot_00080: 'Ladestationswährungstyp',
    createDepot_00081: 'Kein verlangte Währungstyp?',
    createDepot_00082: 'Klicken hinzuzufügen',
    createDepot_00083: 'hinzufügen',
    createDepot_00084: 'abbrechen',
    createDepot_00085: 'Hinzufügen fehlgeschlagen',
    createDepot_00086: 'Zeitzone',
    createDepot_00087: 'Ladestation hinzufügen oder verwalten',
    createDepot_00088: 'Stationserfassung fehlgeschlagen',
}