export default {
  createKnowledge_00001: 'Create knowledege',
  createKnowledge_00002: 'Knowledege ban',
  createKnowledge_00003: 'Related tickets category',
  createKnowledge_00004: 'Related tickets',
  createKnowledge_00005: 'Ticket title',
  createKnowledge_00006: 'Ticket title',
  createKnowledge_00007: 'Related euipement brand & model',
  createKnowledge_00008: '',
  createKnowledge_00009: 'All models',
  createKnowledge_00010: 'Issue description',
  createKnowledge_00011: 'Solution',
  createKnowledge_00012: 'Please fill in completely',
  createKnowledge_00013: 'Created successfully',
  createKnowledge_00014: 'Share with related CPOs',
  createKnowledge_00015: 'Edit knowledege',
  createKnowledge_00017: 'Related Transaction',
  createKnowledge_00018: 'Faulted code',
}