export default {
  orderClassify_00001: "工单分类",
  orderClassify_00002: "工单列表",
  orderClassify_00003: "新建分类",
  orderClassify_00004: "分类名称",
  orderClassify_00005: "上级分类",
  orderClassify_00006: "分类级别",
  orderClassify_00007: "顶级",
  orderClassify_00008: "二级",
  orderClassify_00009: "默认分类工单处理人",
  orderClassify_00010: "默认分类工单关注人",
  orderClassify_00011: "操作",
  orderClassify_00012: "新建下级分类",
  orderClassify_00013: "编辑",
  orderClassify_00014: "删除",
  orderClassify_00015: "无",
  orderClassify_00016: "删除分类后不可恢复",
  orderClassify_00017: "当前分类不可删除",
  orderClassify_00018: "顶级分类",
  orderClassify_00019: "分类名称",
  orderClassify_00020: "分类工单模板设置",
  orderClassify_00021: "问题描述",
  orderClassify_00022: "默认分类工单处理角色",
  orderClassify_00023: "默认关注人",
  orderClassify_00024: "全部",
  orderClassify_00025: "编辑分类",
  orderClassify_00026: "请填写分类名称",
  orderClassify_00027: "请选择处理人",
  orderClassify_00028: "请选择关注人",
  orderClassify_00029: "新建成功",
  orderClassify_00030: "修改成功",
  orderClassify_00031: "不可删除",
}