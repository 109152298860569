export default {
    copy: 'copy',
    invite_manage:"User Invitation",
    invite_enabled:"Enable to invite friends",
    invite_open_description:"Enable the function and this function will be displayed in WeChat Official Account - Personal Center.",
    invite_friend_link:"Link to invite friends",
    invite_friend_link_copy:"Copied to clipboard successfully",
    invite_friend_link_description:"To add the invitation menu to the WeChat Official Account, you can add menu in [customized menu], set the menu content to ‘Jump the Web Page’, and copy the URL address above to the ‘Page Address’.",
    invite_set_rewords:"Set the rewards",
    invite_stats_invite_count:"Number of invitations",
    invite_stats_invite_userCount:"Number of people who initiated invitations",
    invite_stats_receive_userCount:"Number of people who accepted the invitation",
    invite_stats_chargeCompleted_userCount:"Invitee who completes the first charging session",
    invite_stats_totalBalance:"Cumulative cash incentive of inviters({unit})",
    invite_stats_totalCoupon:"Cumulative coupon of inviters",
    invite_stats_receive_totalBalance:"Cumulative cash incentive of invitees({unit})",
    invite_stats_receive_totalCoupon:"Cumulative coupon of invitees",
    invite_wechat_nickname:"Invitee WeChat name",
    invite_receive_usercount_item:"Number of people who accepted the invitation",
    invite_receive_charge_completed:"Invitee who completes the first charge",
    invite_receive_totalBalance:"Cumulative amount of cash incentive({unit})",
    invite_receive_totalCoupon:"Cumulative amount of coupons",
    invite_open_remind:"Please set the rewards before enabling the user invitation function.",
    invite_enable_remind:"Are you sure to {option} user invitation function",
    invite_rewords:"Rewards for inviters",
    invite_reword_amount:"Cash incentive({unit})",
    invite_reword_coupon:"Coupon rewards",
    invite_reword_coupon_remind:"no more than 1 type of coupon can be added, and numbers cannot exceed 100.",
    invite_code:"Invitation code",
    invite_object:"Invitee",
    invite_object_tip:"Must fill out within 20 letters",
    invite_object_input_remind:"Please enter the invitee",
    invite_object_input_template:"{num} letters are left",
    invite_code_or_object:"Invitation code/invitees",
    notify:"notify",
    clear: "clear",
    operation: "option",
    save: "save",
    user_detail:"User details",
    confirm: 'OK',
    cancel: "cancel",
    modify: "modify",
    remind: "remind",
    generate_invite_code_success_template:"Generated invitation code successfully, the code is: {code}",
    export_invited_data: "export",
    export_invited_data_excel:"This operation will download the Excel file of inviting friends' data. Do you want to continue?",
    head_portrait: "Profile",
    coupon_title:"Name",
    coupon_amount:"Numbers",

    coupon_type:"Type",
    choose_coupon:"Choose coupon",
    voucher_coupon:"Voucher",
    discount_coupon:"Coupon",
    type: "type",

    detail: 'detail',
    reword_set_rules_remind01:"The following rewards can be set singly or in combination.",
    reword_set_rules_remind02:"After the invitee(user who have not registered before) complete the first charge and pay, the inviter can get rewards instantly.",
    reword_set_rules_remind03:"The inviter rewards cannot be empty.",
    reword_set_rules_remind04:"The invitee rewards cannot be empty.",
    reword_set_rules_remind05:"Invalid number of cash incentive for inviter.",
    reword_set_rules_remind06:"Invalid number of cash incentive for invitee.",
    reword_set_rules_remind07:"Invalid numbers of coupons.",
    reword_set_rules_remind08:"Numbers of coupons cannot exceed 100.",
    coupon_base_invoice_5:"Up to 100 coupons of each type",
    invitee_rewords:"Rewards for invitees",
    invitee_rewords_place_rule_remind:"After the invitee(user who have not registered before) accept the invitation, the invitee can get rewards instantly.",
    close: 'close',
    open: 'open',
    begin_date_select_remind: "Choose start date",
    end_date_select_remind: "Choose end date",
    until: "-",
    enter_activity_explain:"The description cannot be empty",
    no_limit:"No limit",
    limit:"Limit",
    single_user_charge_amount:"For user has consumed",
    user_charge_amount:"For user charged",
    only_no_charged:"For new user only",
    userLevel:"user level",
    userRange:"User scope",
    promotion_type1:"Cash(after top-up)",
    promotion_type2:"Cash and coupon(after top-up)",
    promotion_type3:"Coupon(after top-up)",
    promotion_type4:"Coupon",
    promotion_type5: "Coupon package promotion",
    cycle_type1:"times/the whole process",
    cycle_type2:"times/month",
    cycle_type3:"times/week",
    cycle_type4:"times/day",
    default_userLevel:"Non-level users",
    mouth_1:"1 month",
    mouth_2:"3 months",
    mouth_3:"12 months",
    promotion_date:"During the activity",
    promotion_invoice_1:"Please enter the name in 20 characters",
    promotion_invoice_2:"Start time cannot be empty",
    promotion_invoice_3:"End time cannot be empty",
    promotion_invoice_4:"Invalid time",
    promotion_invoice_5:"Activity object cannot be empty",
    promotion_invoice_6:"Please enter the description in less than 150 words.",
    promotion_invoice_7:"Amount is too large",
    promotion_invoice_8:"Invalid times of top-up per user",
    promotion_invoice_9:"Invalid times of participation per user",
    promotion_invoice_10:"Invalid total times of participation",
    promotion_invoice_11:"Invalid given cash amount",
    promotion_invoice_12:"One user consumed {equal} {feeAmount} within {feeAmountPeriod} months",
    promotion_invoice_14:"No more than 6 classes",
    promotion_invoice_15:"Confirm to delete",
    enter_amount:"Amount",
    week:"1 week",
    enter_charge_placeholder:"please enter the charging amount",
    support_refund:"Support refund",
    no_refund:"No refunds",
    enter_success_times_1:"Limit times cannot be empty",
    enter_success_times_2:"Limit times cannot be empty",
    enter_discount_count:"Cash amount cannot be empty",
    enter_coupon_name:"Please enter the coupon name",
    query: "query",
    wechat_user: "App user",
    confirm_delete_activate:"Confirm to delete",
    delete_success: "Deleted successfully",
    validity:"validity",
    activity_detail: "detail",
    day:"day",
    select_coupon_invoice_1:"No more than 6 types of coupons",
    select_coupon_invoice_2:"No more than 1 type of coupon",
    less_than_or_equal_to:"<=",
    greater_than_or_equal_to:">=",
    promotion_invoice_17:"The total accumulative charging cost of a single user meets {equal}{feeAmount} during the activity period",
    promotion_invoice_12:"One user consumed {equal} {feeAmount} within {feeAmountPeriod} months",
    promotion_invoice_24:"The accumulative charging capacity of a single user meets {equal}{powerAmount} degrees during the event",
    promotion_invoice_21:"Invalid total charging amount per user",
    promotion_invoice_22:"Users charged {equal} {powerAmount} Kwh within {powerAmountPeriod} month(s).",
    promotion_invoice_23:"Users charged {equal} {powerAmount} Kwh within a week.",
    modify_success: "Modified successfully ",
    count_time:"Time",
    count_coupons:"卡券发放总数",
    get_coupon_people:"Persons participated",
    get_coupon_count:"Coupons received",
    provide_coupon:"Coupon issuance",
    all_coupons_count:"Total count",
    fee_total:"Total cost",
    use_condition:"condition",
    used:"used",
    unused:"unused",
    expired: "expired",
    user_collection_detail: "User Collection Details",
    nickname: "nickname",
    sex: "sex",
    user_mobile:"User(Phone number)",
    card_number: "Card number",
    collection_time: "Collection time",
    gifts_coupon_amount:"Coupon value",
    gifts_coupon_length:"Number of gift coupons",
    u3_card: "Online card",
    promotion_use_coupon_condition:"Charge {msg} {feeAtLeast} at least",
    service_fee: "Service fee",
    promotion_amount:"Bonus amount",
    amount_balance:"Top-up amount",
    promotion_charge_people:"Persons",
    promotion_charge_count:"Times",
    user_charge_detail:"List of top-up",
    charge_time:"Top-up Time",
    export_data: "Export data",
    coupon_detail:"Coupon",
    start_download: "Start to download",
    cancel_download: "Cancel",
    promotion_invoice_16:"Please1 enter the activity description in no more than 500 characters.",
    enable: "enable",

    invite_00001: 'Inviting friends',
    invite_00002: 'Marketing',
    invite_00003: 'Yes',
    invite_00004: 'No',
    invite_00005: 'Invite friends to manage',
    invite_00006: 'Inviter list',
    invite_00007: 'Invite friends reward settings',
    invite_00008: 'Cancel',
    invite_00009: 'Save',
    invite_00010: 'Edit',
    invite_00011: 'Type',

}