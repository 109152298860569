<template lang="html">
    <div class="chargingDataInfo">
        <div class="title" v-if="showTitle">{{info.portTitle}}{{$t('billDetail_00025')}}</div>
        <div id="container" :class="['echarts',!showTitle? 'w50':'']"></div>
        <div class="title" v-if="info.dualPort">P2{{$t('billDetail_00025')}}</div>
        <div id="container1" :class="['echarts',!showTitle? 'w50':'']" v-show="info.dualPort"></div>
        <div class="infobox">
            <div class="model">
                <div class="num">{{parseFloat(info.powerCharged==null?0:info.powerCharged).toFixed(3)}}<span>{{$t('billDetail_00042')}}</span></div>
                <div class="name">{{$t('billDetail_00041')}}</div>
            </div>
            <div class="model" v-show="info.status =='charging'">
                <div class="num">{{info.powerSupply}}<span>kW</span></div>
                <div class="name">{{$t('billDetail_00032')}}</div>
            </div>
            <div class="model">
                <div class="num">{{aveSupply}}<span>kW</span></div>
                <div class="name">{{$t('billDetail_00033')}}</div>
            </div>
            <div class="model">
                <div class="num">{{maxSupply}}<span>kW</span></div>
                <div class="name">{{$t('billDetail_00034')}}</div>
            </div>
            <div class="model">
                <div class="num">{{vehicleModel}}</div>
                <div class="name">{{$t('billDetail_00035')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {formmatTime} from "@/assets/utils/time";
export default {
  props: ["info","showTitle"],
  data() {
    let colors = ["#E9A779", "#DB83D1", "#41A0FC", "#53CA76"];
    //电压
    let volData = [];
    let powerCharged = [];
    let eleCurrentData = [];
    let powerSupplyData = [];
    return {
      echart: "",
      echart1: "",
      vehicleModel: "-", //车型
      maxSupply: "", //最高功率
      aveSupply: "-", //平均功率
      option: {
        color: colors,
        title: {
          text: "",
          textStyle: {
            color: "#999",
            width: "100%",
            fontSize: 12,
            fontWeight: 400,
            align: "center"
          },
          top: "18%",
          left: "center"
        },
        axisPointer: {
          link: {
            xAxisIndex: "all"
          }
        },
        // 提示框
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          },
          formatter:(parmas) =>{
            let box = "<div style='padding:0 10px'>";
            let num = parmas.length;

            const formatText = (val)=> {
              switch (val) {
                case this.$parent.$t('billDetail_00036'):
                  return "kW";
                  break;
                case this.$parent.$t('billDetail_00038'):
                  return "V";
                  break;
                case this.$parent.$t('billDetail_00037'):
                  return "A";
                  break;
                case this.$parent.$t('billDetail_00039'):
                  return this.$parent.$t('common_00209');
                  break;
              }
            }
            for (let i = 0; i < num; i++) {
              box +=
                parmas[i].marker +
                parmas[i].seriesName +
                parmas[i].value[1] +
                formatText(parmas[i].seriesName) +
                "</br>";
            }
            box += "</div>";
            return box;
          }
        },
        legend: {
          data: [this.$parent.$t('billDetail_00036'), this.$parent.$t('billDetail_00038'), this.$parent.$t('billDetail_00037'), this.$parent.$t('billDetail_00039')]
        },
        dataZoom: [
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1, 2]
          }
        ],
        grid: [
          {
            left: "8%",
            right: "8%",
            height: "50%"
          },
          {
            left: "8%",
            right: "8%",
            bottom: "10%",
            height: "15%"
          },
          {
            left: "8%",
            right: "8%",
            height: "50%",
            z: 3
          }
        ],
        xAxis: [
          {
            min: "dataMin",
            max: "dataMax",
            type: "time",
            scale: true,
            axisTick: {
              show: true
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          },
          {
            min: "dataMin",
            max: "dataMax",
            gridIndex: 1,
            type: "time",
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          },
          {
            min: "dataMin",
            max: "dataMax",
            gridIndex: 2,
            type: "time",
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          }
        ],
        yAxis: [
          {
            name: this.$parent.$t('billDetail_00036')+"(kW)",
            type: "value",
            min: 0,
            scale: true,
            position: "left",
            splitLine: {
              show: false
            },
            axisTick: {
              inside: true,
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            axisLine: {
              lineStyle: {
                color: colors[2],
                width: 2
              }
            }
          },
          {
            name: this.$parent.$t('billDetail_00038')+"(V)",
            type: "value",
            scale: true,
            min: 0,
            position: "right",
            axisTick: {
              inside: true,
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            nameTextStyle: {
              padding: [0, 80, 0, 0]
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: colors[1],
                width: 2
              }
            },
            axisLabel: {
              inside: true,
              margin: 16
            }
          },
          {
            gridIndex: 1,
            name: this.$parent.$t('billDetail_00039')+"(度)",
            type: "value",

            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                opacity: 0
              }
            }
          },
          {
            gridIndex: 2,
            name: this.$parent.$t('billDetail_00037')+"(A)",
            min: 0,
            type: "value",
            position: "right",
            offset: 2,
            nameTextStyle: {
              padding: [0, 0, 0, 30]
            },
            axisTick: {
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: colors[3],
                width: 2
              }
            },
            axisLabel: {
              margin: 16
            }
          }
        ],
        series: [
          {
            name: this.$parent.$t('billDetail_00036'),
            type: "line",
            markArea: {
              label: {
                normal: {
                  show: true,
                  color: "#bfbfbf"
                }
              },
              itemStyle: {
                normal: {
                  opacity: 1
                }
              },
              data: [
                [
                  {
                    name: "",
                    xAxis: ""
                  },
                  {
                    xAxis: ""
                  }
                ]
              ]
            },
            itemStyle: {
              normal: {
                color: "#41A0FC"
              }
            },
            lineStyle: {
              normal: {
                color: "#41A0FC"
              }
            },
            areaStyle: {},
            data: powerSupplyData,
          },
          {
            name: this.$parent.$t('billDetail_00038'),
            type: "line",
            yAxisIndex: 1,
            xAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            lineStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            data: volData
          },
          {
            name: this.$parent.$t('billDetail_00039'),
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 2,
            areaStyle: {
              normal: {
                color: "#E9A779"
              }
            },
            itemStyle: {
              normal: {
                color: "#E9A779"
              }
            },
            lineStyle: {
              normal: {
                width: 3
              }
            },
            data: powerCharged
          },
          {
            name: this.$parent.$t('billDetail_00037'),
            type: "line",
            itemStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            lineStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            yAxisIndex: 3,
            xAxisIndex: 2,
            data: eleCurrentData
          }
        ]
      },
      option1: {
        color: colors,
        title: {
          text: "",
          textStyle: {
            color: "#999",
            width: "100%",
            fontSize: 12,
            fontWeight: 400,
            align: "center"
          },
          top: "6%",
          left: "center"
        },
        axisPointer: {
          link: {
            xAxisIndex: "all"
          }
        },
        // 提示框
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          },
          formatter: function(parmas) {
            let box = "<div style='padding:0 10px'>";
            let num = parmas.length;

            const formatText = (val)=> {
              switch (val) {
                case this.$parent.$t('billDetail_00036'):
                  return "kW";
                  break;
                case this.$parent.$t('billDetail_00038'):
                  return "V";
                  break;
                case this.$parent.$t('billDetail_00037'):
                  return "A";
                  break;
                case this.$parent.$t('billDetail_00039'):
                  return this.$parent.$t('common_00209');
                  break;
              }
            }
            for (let i = 0; i < num; i++) {
              box +=
                parmas[i].marker +
                parmas[i].seriesName +
                parmas[i].value[1] +
                formatText(parmas[i].seriesName) +
                "</br>";
            }
            box += "</div>";
            return box;
          }
        },
        legend: {
          data: [this.$parent.$t('billDetail_00036'), this.$parent.$t('billDetail_00038'), this.$parent.$t('billDetail_00037'), this.$parent.$t('billDetail_00039')]
        },
        dataZoom: [
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100,
            xAxisIndex: [0, 1, 2]
          }
        ],
        grid: [
          {
            left: "8%",
            right: "8%",
            height: "50%"
          },
          {
            left: "8%",
            right: "8%",
            bottom: "10%",
            height: "15%"
          },
          {
            left: "8%",
            right: "8%",
            height: "50%",
            z: 3
          }
        ],
        xAxis: [
          {
            min: "dataMin",
            max: "dataMax",
            type: "time",
            scale: true,
            axisTick: {
              show: true
            },
            axisLabel: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          },
          {
            min: "dataMin",
            max: "dataMax",
            gridIndex: 1,
            type: "time",
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          },
          {
            min: "dataMin",
            max: "dataMax",
            gridIndex: 2,
            type: "time",
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                width: 2
              }
            }
          }
        ],
        yAxis: [
          {
            name: this.$parent.$t('billDetail_00036')+"(kW)",
            type: "value",
            min: 0,
            scale: true,
            position: "left",
            splitLine: {
              show: false
            },
            axisTick: {
              inside: true,
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            axisLine: {
              lineStyle: {
                color: colors[2],
                width: 2
              }
            }
          },
          {
            name: this.$parent.$t('billDetail_00038')+"(V)",
            type: "value",
            scale: true,
            min: 0,
            position: "right",
            axisTick: {
              inside: true,
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            nameTextStyle: {
              padding: [0, 80, 0, 0]
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: colors[1],
                width: 2
              }
            },
            axisLabel: {
              inside: true,
              margin: 16
            }
          },
          {
            gridIndex: 1,
            name: this.$parent.$t('billDetail_00039')+"("+this.$parent.$t('billDetail_00042')+")",
            type: "value",

            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: "#bfbfbf",
                opacity: 0
              }
            }
          },
          {
            gridIndex: 2,
            name: this.$parent.$t('billDetail_00037')+"(A)",
            min: 0,
            type: "value",
            position: "right",
            offset: 2,
            nameTextStyle: {
              padding: [0, 0, 0, 30]
            },
            axisTick: {
              length: 12,
              lineStyle: {
                width: 2
              }
            },
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: colors[3],
                width: 2
              }
            },
            axisLabel: {
              margin: 16
            }
          }
        ],
        series: [
          {
            name: this.$parent.$t('billDetail_00036'),
            type: "line",
            markArea: {
              label: {
                normal: {
                  show: true,
                  color: "#bfbfbf"
                }
              },
              itemStyle: {
                normal: {
                  opacity: 1
                }
              },
              data: [
                [
                  {
                    name: "",
                    xAxis: ""
                  },
                  {
                    xAxis: ""
                  }
                ]
              ]
            },
            itemStyle: {
              normal: {
                color: "#41A0FC"
              }
            },
            lineStyle: {
              normal: {
                color: "#41A0FC"
              }
            },
            areaStyle: {},
            data: powerSupplyData
          },
          {
            name: this.$parent.$t('billDetail_00038'),
            type: "line",
            yAxisIndex: 1,
            xAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            lineStyle: {
              normal: {
                color: "#DB83D1"
              }
            },
            data: volData
          },
          {
            name: this.$parent.$t('billDetail_00039'),
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 2,
            areaStyle: {
              normal: {
                color: "#E9A779"
              }
            },
            itemStyle: {
              normal: {
                color: "#E9A779"
              }
            },
            lineStyle: {
              normal: {
                width: 3
              }
            },
            data: powerCharged
          },
          {
            name: this.$parent.$t('billDetail_00037'),
            type: "line",
            itemStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            lineStyle: {
              normal: {
                color: "#53CA76"
              }
            },
            yAxisIndex: 3,
            xAxisIndex: 2,
            data: eleCurrentData
          }
        ]
      }
    };
  },
  methods: {
    formatAveSupply(bill) {
      var powerCharged = bill.powerCharged;
      var chargeInterval = bill.chargeInterval;
      if (powerCharged == null) {
        powerCharged = 0;
      }
      if (chargeInterval == null || chargeInterval == 0) {
        return 0;
      }
      var s = powerCharged / (chargeInterval / 60);
      return s.toFixed(3);
    },
    calPowerRange(array, num) {
      var arrayRange = {};
      for (var i = 0; i < array.length; i++) {
        if (array[i] > num) {
          arrayRange.begin = i;
          break;
        }
      }
      var reservedArray = array.reverse();
      for (var i = 0; i < reservedArray.length; i++) {
        if (reservedArray[i] > num) {
          arrayRange.end = reservedArray.length - i - 1;
          break;
        }
      }
      return arrayRange;
    },
    //计算两时间段的分钟差
    calMinusByIndex(array, begin, end) {
      var time1 = new Date(array[begin]);
      var time2 = new Date(array[end]);
      var diffTime = time2.getTime() - time1.getTime();
      var minutes = Math.floor(diffTime / (60 * 1000));
      return minutes;
    },
    formmatTime(time) {
      return formmatTime(time, true);
    },
    calPercent(num, total) {
      if (total == 0) {
        return "0%";
      }
      return Math.round(num / total * 10000) / 100.0 + "%";
    }
  },
  watch: {
    info: function(value) {
      if (value.vehicleModel) {
        this.vehicleModel = value.vehicleModel;
      }
      if (value.chargeData) {
        var timeData = []; //时间
        var volData = []; //电压
        var volData1 = []; //电压
        var powerCharged = []; //已充电量单位度
        var powerCharged1 = []; //已充电量单位度
        var eleCurrentData = []; //电流
        var eleCurrentData1 = []; //电流
        var powerSupplyData = []; //功率
        var powerSupplyData1 = []; //功率

        var length = value.chargeData.length;
        for (var i = 0; i < length; i++) {
          var time = this.formmatTime(value.chargeData[i].time, true);
          timeData.push(time);
          var array = [];
          var array1 = [];
          var array2 = [];
          var array3 = [];
          array.push(time);
          array1.push(time);
          array2.push(time);
          array3.push(time);
          var curentEle = Math.abs(value.chargeData[i].data[2] / 1000).toFixed(
            2
          );
          array.push(curentEle);
          eleCurrentData.push(curentEle); //mA转A
          eleCurrentData1.push(array);
          var power = value.chargeData[i].data[0].toFixed(3);
          powerCharged.push(power);
          //生成二维数组
          array1.push(power);
          powerCharged1.push(array1);
          var powerSupply = (value.chargeData[i].data[1] / 1000).toFixed(2);
          array2.push(powerSupply);
          powerSupplyData.push(powerSupply); //w转kw
          powerSupplyData1.push(array2); //w转kw
          var vol = 0;
          if (curentEle != 0) {
            if (value.chargeData[i].data[8] != null) {
              vol = value.chargeData[i].data[8].toFixed(2);
            } else {
              vol = (value.chargeData[i].data[1] / curentEle).toFixed(2);
            }
          }
          volData.push(vol);
          array3.push(vol);
          volData1.push(array3);
        }
        //图0 x时间 y0充电功率 y1 充电电压 y2充电电流
        //图1 x时间 y 充电电量
        //图2 x时间 y 电流
        this.option.series[0].data = powerSupplyData1; //
        this.option.series[1].data = volData1;
        this.option.series[2].data = powerCharged1;
        this.option.series[3].data = eleCurrentData1;
        var maxSupply = Math.max.apply(null, powerSupplyData); //最大功率
        var supply = (maxSupply / 1.1).toFixed(2);
        var aveSupply = this.formatAveSupply(value);
        if (supply < aveSupply) {
          this.aveSupply = supply;
        } else {
          this.aveSupply = aveSupply;
        }

        this.maxSupply = maxSupply.toFixed(2); //最高功率
        if(this.aveSupply * 1 > this.maxSupply * 1){
              this.aveSupply = this.maxSupply;
        }
        if (length >= 2) {
          var powerSupplyRange = maxSupply * 0.75;
          var arrayRange = this.calPowerRange(
            powerSupplyData,
            powerSupplyRange
          );
          var totalMinutes = this.calMinusByIndex(timeData, 0, length - 1);
          var rangeMinutes = this.calMinusByIndex(
            timeData,
            arrayRange.begin,
            arrayRange.end
          );
          //计算占比
          var timePercent = this.calPercent(rangeMinutes, totalMinutes);
          //计算两点之间的电量差
          var rangePowerCharged = (
            powerCharged[arrayRange.end] - powerCharged[arrayRange.begin]
          ).toFixed(3);
          var powerCahrgedPercent = this.calPercent(
            rangePowerCharged,
            powerCharged[length - 1]
          );
            //最高功率范围描述
            var rangeRemind= this.$t('billDetail_00091',{powerBegin:powerSupplyRange.toFixed(2),powerEnd:maxSupply.toFixed(2),minutes:rangeMinutes,timePercent:timePercent,powerCharged:rangePowerCharged,chargePercent:powerCahrgedPercent})
         /* var rangeRemind =
            "最高功率范围，功率范围" +
            powerSupplyRange.toFixed(2) +
            "-" +
            maxSupply.toFixed(2) +
            "Kw，共" +
            rangeMinutes +
            "分钟(占总时长的" +
            timePercent +
            ")，共" +
            rangePowerCharged +
            "度电，占总电量" +
            powerCahrgedPercent;*/
          this.option.title.text = rangeRemind;
          this.option.series[0].markArea.data[0][0].xAxis =
            timeData[arrayRange.begin];
          this.option.series[0].markArea.data[0][1].xAxis =
            timeData[arrayRange.end];
        }
      }
      if (value.dualChargeData) {
        var timeData = []; //时间
        var volData = []; //电压
        var volData1 = []; //电压
        var powerCharged = []; //已充电量单位度
        var powerCharged1 = []; //已充电量单位度
        var eleCurrentData = []; //电流
        var eleCurrentData1 = []; //电流
        var powerSupplyData = []; //功率
        var powerSupplyData1 = []; //功率

        var length = value.dualChargeData.length;
        for (var i = 0; i < length; i++) {
          var time = this.formmatTime(value.dualChargeData[i].time, true);
          timeData.push(time);
          var array = [];
          var array1 = [];
          var array2 = [];
          var array3 = [];
          array.push(time);
          array1.push(time);
          array2.push(time);
          array3.push(time);
          var curentEle = Math.abs(value.dualChargeData[i].data[2] / 1000).toFixed(
            2
          );
          array.push(curentEle);
          eleCurrentData.push(curentEle); //mA转A
          eleCurrentData1.push(array);
          var power = value.dualChargeData[i].data[0].toFixed(2);
          powerCharged.push(power);
          //生成二维数组
          array1.push(power);
          powerCharged1.push(array1);
          var powerSupply = (value.dualChargeData[i].data[1] / 1000).toFixed(2);
          array2.push(powerSupply);
          powerSupplyData.push(powerSupply); //w转kw
          powerSupplyData1.push(array2); //w转kw
          var vol = 0;
          if (curentEle != 0) {
            if (value.dualChargeData[i].data[8] != null) {
              vol = value.dualChargeData[i].data[8].toFixed(2);
            } else {
              vol = (value.dualChargeData[i].data[1] / curentEle).toFixed(2);
            }
          }
          volData.push(vol);
          array3.push(vol);
          volData1.push(array3);
        }
        //图0 x时间 y0充电功率 y1 充电电压 y2充电电流
        //图1 x时间 y 充电电量
        //图2 x时间 y 电流
        this.option1.series[0].data = powerSupplyData1; //
        this.option1.series[1].data = volData1;
        this.option1.series[2].data = powerCharged1;
        this.option1.series[3].data = eleCurrentData1;
        var maxSupply = Math.max.apply(null, powerSupplyData); //最大功率
        var supply = (maxSupply / 1.1).toFixed(2);
        var aveSupply = this.formatAveSupply(value);
        if (supply < aveSupply) {
          this.aveSupply = supply;
        } else {
          this.aveSupply = aveSupply;
        }

        this.maxSupply = maxSupply.toFixed(2); //最高功率
        if(this.aveSupply * 1 > this.maxSupply * 1){
              this.aveSupply = this.maxSupply;
        }
        if (length >= 2) {
          var powerSupplyRange = maxSupply * 0.75;
          var arrayRange = this.calPowerRange(
            powerSupplyData,
            powerSupplyRange
          );
          var totalMinutes = this.calMinusByIndex(timeData, 0, length - 1);
          var rangeMinutes = this.calMinusByIndex(
            timeData,
            arrayRange.begin,
            arrayRange.end
          );
          //计算占比
          var timePercent = this.calPercent(rangeMinutes, totalMinutes);
          //计算两点之间的电量差
          var rangePowerCharged = (
            powerCharged[arrayRange.end] - powerCharged[arrayRange.begin]
          ).toFixed(3);
          var powerCahrgedPercent = this.calPercent(
            rangePowerCharged,
            powerCharged[length - 1]
          );
            //最高功率范围描述
            var rangeRemind= this.$t('billDetail_00091',{powerBegin:powerSupplyRange.toFixed(2),powerEnd:maxSupply.toFixed(2),minutes:rangeMinutes,timePercent:timePercent,powerCharged:rangePowerCharged,chargePercent:powerCahrgedPercent})
         /* var rangeRemind =
            "最高功率范围，功率范围" +
            powerSupplyRange.toFixed(2) +
            "-" +
            maxSupply.toFixed(2) +
            "Kw，共" +
            rangeMinutes +
            "分钟(占总时长的" +
            timePercent +
            ")，共" +
            rangePowerCharged +
            "度电，占总电量" +
            powerCahrgedPercent;*/
          this.option1.title.text = rangeRemind;
          this.option1.series[0].markArea.data[0][0].xAxis =
            timeData[arrayRange.begin];
          this.option1.series[0].markArea.data[0][1].xAxis =
            timeData[arrayRange.end];
        }
      }
      this.echart.setOption(this.option);
      this.echart1.setOption(this.option1);
    }
  },
  mounted() {
    let echarts = require('echarts');
    this.echart = echarts.init(document.getElementById("container"));
    this.echart1 = echarts.init(document.getElementById("container1"));
    window.addEventListener("resize", () => {
      this.echart.resize();
      this.echart1.resize();
    });
  }
};
</script>

<style lang="scss" scoped>
.chargingDataInfo {
  height: auto;
  padding-top: 20px;
  & .title {
    font-size: 26px;
    color: #333;
    text-align: center;
    padding: 10px 0 20px 0;
  }
  & .echarts {
    width: 82vw;
    height: 600px;
    margin: 0 auto;
    &.w50{
      width: 55vw!important;
    }
  }
  & .infobox {
    display: flex;
    justify-content: center;
    height: auto;
    margin: 30px auto 40px;
    & .model {
      display: table-cell;
      width: 16.6%;
      text-align: center;
      vertical-align: middle;
      border-right: 1px solid #e6e6e6;
      & .num {
        font-size: 20px;
        color: #2c2c2c;
        & span {
          font-size: 14px;
        }
      }
      & .name {
        font-size: 14px;
        margin-top: 10px;
        color: #8a8a8a;
      }
      &:last-child {
        border-right: 0;
        & .num {
          font-size: 15px;
        }
      }
    }
  }
}
</style>
