export default {
    depotMap_00001: "Stations",
    depotMap_00002: "Current charging user",
    depotMap_00003: "Total power",
    depotMap_00004: "Price(€)",
    depotMap_00005: "Electricity(kWh)",
    depotMap_00006: "Show all",
    depotMap_00007: "Total power(kW)",
    depotMap_00008: "Wechat user",
    depotMap_00009: "Offline start/stop card",
    depotMap_000010: "Offline value-stored card",
    depotMap_000011: "Online identity card",
    depotMap_000012: "Administrator",
    depotMap_000013: "Third-party user",
    depotMap_000014: "Credit Card",
    depotMap_000015: "Idle charging",
    depotMap_000016: "In-use charging",
    depotMap_000017: "Abnormal charging",
}