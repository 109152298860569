export default {
  coupon_charge_condition_1:"Die Gesamtkosten einer einzelne Laden entsprechen {feeAtLeast}EUR",
  voucher_amount_1:"Ermäßigen Gesamtkosten {discount} EUR",
  discount_amount_4:"Gesamtkostenfrei",
  coupon_charge_condition_2:"Servicegebühr einer einzelne Laden entsprechen {feeAtLeast}EUR",
  voucher_amount_2:"Ermäßigen Servicebetrag {discount} EUR",
  discount_amount_3:"Servicekostenfrei",
  voucher_coupon:"Ermäßigungsgutschein",
  discount_coupon:"Coupon",
  from_get_coupon_on:"{validityDays} Tagen ab den Erhaltungstag",
  discount_amount_1:"Gesamtkosten {discount} Rabatt", 
  discount_amount_2:"Servicegebühr {discount} Rabatt",

  coupon_00001: 'Marketing-Zentrum',
  coupon_00002: 'Angebote-Instrumente',
  coupon_00003: 'Name',
  coupon_00004: 'Bitte eingeben',
  coupon_00005: 'zurücksetzen',
  coupon_00006: 'suchen',
  coupon_00007: 'Liste',
  coupon_00008: 'neu erstellen',
  coupon_00009: 'Nutzungsbedingung',
  coupon_00010: 'Gültigkeitsdauer',
  coupon_00011: 'Aktion',
  coupon_00012: 'Einzelheiten',
  coupon_00013: 'Gutschein',
  coupon_00014: 'Coupon',
}