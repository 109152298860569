var list = {
	1:'[GtoV] 只允许电网给车充电',
	2:'[BtoV] 只允许电池给车充电（强制放电）',
	3:'[GtoB] 只允许电网给电池补电（强制补电）',
	4:'[GtoV > BtoV] 不允许补电，车充电先使用ac模块后使用dc模块。', 
	5:'[GtoV > GtoB] 不允许电池放电，没车充电时补电，有车充电时，只使用ac模块给车充电。',
	6:'[BtoV > GtoV] 不允许补电，有车充电时先使用dc模块后使用ac模块',
	7:'[BtoV > GtoB] 有车充电的时候，使用电池放电，没车充电的时候，给电池补电',
	8:'[GtoV > BtoV > GtoB] 没车充电的时候给电池补电，有车充电的时候优先使用ac模块，后使用dc模块。', 
	9:'[BtoV > GtoV > GtoB] 没车充电的时候给电池补电，有车充电的时候优先使用dc模块，后使用ac模块。',
	10:'[GtoB > GtoV] 先把电池充满，再用电网电力给车充',
	11:'[toG] 自动并网，且不允许给车充电',
	12:'[toG > toV] 自动并网，且允许给车充电',
	13:'[Max] 全功率模式',
	14:'[Eco] 经济模式',
	15:'[Auto] 自动模式',
}
module.exports = list;