
export default {
    cardGroup_00001: "Sind Sie sicher, dass Sie die Ladekartengruppe aus der Gruppe{title} entfernen wollen?",
    cardGroup_00002: "Insgesamt wurden {allDevice} Geräte geschoben, {sucDevice} war erfolgreich und {faiDevice} ist fehlgeschlagen.",
    cardGroup_00003: "Betriebsart(wählen Sie eine aus)",
    cardGroup_00004: "{id}(verloren)",
    cardGroup_00005: "Karte kann nicht gelesen werden! Tipp: Bitte bestätigen Sie, dass das Kartenlesegerät angeschlossen ist und legen Sie die NFC-Karte auf das Lesegerät. ",
    cardGroup_00006: "Die Karte ist nicht leer",
    cardGroup_00007: "Die Karte ist leer",
    cardGroup_00008: "Karte konnte nicht geschrieben werden{msg}",
    cardGroup_00009: "Einzelheiten der Kartegruppen",
    cardGroup_00010: "CPO Karteverwaltung",
    cardGroup_00011: "Ladekartegruppe Management",
    cardGroup_00012: "Kartegruppe Management",
    cardGroup_00013: "Bindung begrenzter Stationen",
    cardGroup_00014: "Offline-Push-Whitelist",
    cardGroup_00015: "Aufladungsrekord suchen",
    cardGroup_00016: "Vorgänge suchen",
    cardGroup_00017: "Kartegruppe löschen",
    cardGroup_00018: "Vorgang wird gelöscht",
    cardGroup_00019: "Einzelheiten der Kartegruppen-Online Kartegruppe",
    cardGroup_00020: "Einzelheiten der Kartegruppen-Offline Kartegruppe",
    cardGroup_00021: "Einzelheiten der Kartegruppen-Super Kartegruppe",
    cardGroup_00022: "Wenn das Gerät offline ist, kann Ladestart mit Ladekarten aus dieser Ladekartengruppe nach dem Verschieben der Whitelist unterstützt werden. Nach der Erstellung und dem Verlust der Karte muss die Whitelist erneut aktualisiert werden.",
    cardGroup_00023: "Wiederholung",
    cardGroup_00024: "Grätenummer",
    cardGroup_00025: "Gerätename",
    cardGroup_00026: "Push-Ergebnisse",
    cardGroup_00027: "Erfolg",
    cardGroup_00028: "Fehlgeschlagen",
    cardGroup_00029: "Warten auf Antwort",
    cardGroup_00030: "Geschlossen",
    cardGroup_00031: "Push fehlgeschlagen, keine Standortbindung",
    cardGroup_00032: "Push fehlgeschlagen",
    cardGroup_00033: "Offline-Push-Whitelist",
    cardGroup_00034: "Online-Kartegruppe",
    cardGroup_00035: "Offline-Kartegruppe",
    cardGroup_00036: "Super-Kartegruppe",
    cardGroup_00037: "Name der Ladekartegruppe",
    cardGroup_00038: "ändern",
    cardGroup_00039: "speichern",
    cardGroup_00040: "abbrechen",
    cardGroup_00041: "Konto anmelden",
    cardGroup_00042: "Anmeldungspasswörte",
    cardGroup_00043: "NFC(APP) herunterladen", 
    cardGroup_00044: "Neues einfache Online-Ausweise erstellen",
    cardGroup_00045: "Geben Sie einen Online-Ausweis für den Benutzer ein. Mit dem Online-Ausweis kann es aufgeladen werden und die Karte kann zum Laden durchgestrichen werden, ohne dass eine Ladesäule eingerichtet werden muss.",
    cardGroup_00046: "Plug&Charge Karte erstellen",
    cardGroup_00047: "Geben Sie eine Plug&Charge Karte für den Benutzer ein. Die Plug&Charge Karte kann wieder aufgeladen werden und muss an der Ladesäule konfiguriert werden, um das Plug&Charge zu ermöglichen.", 
    cardGroup_00048: "Bitte geben Sie die Kartennummer, den Benutzernamen, die Handynummer und die Nummer ein",
    cardGroup_00049: "Kartetypen",
    cardGroup_00050: "Kartenummer",
    cardGroup_00051: "Nummer",
    cardGroup_00052: "Benutzername(Handynummer)",
    cardGroup_00053: "Kfz-Kennzeichen",
    cardGroup_00054: "Kartetypen",
    cardGroup_00055: "Offline-Start/Stopp Karte",
    cardGroup_00056: "Offline gespeichertewertkarte",
    cardGroup_00057: "Online-Ausweis",
    cardGroup_00058: "Plug&Charge Karte",
    cardGroup_00059: "Konfigurationskarte",
    cardGroup_00060: "Bilanz",
    cardGroup_00061: "Aktion",
    cardGroup_00062: "Einzelheit",
    cardGroup_00063: "Bitte speichern Sie den letzten Vorgang",
    cardGroup_00064: "Parameter ist leer",
    cardGroup_00065: "Neuer einfache Ausweis erstellen",
    cardGroup_00066: "Quelle",
    cardGroup_00067: "Kontaktname",
    cardGroup_00068: "Kontakthandynummer",
    cardGroup_00069: "Geheimnis Schlüssel", 
    cardGroup_00070: "Aufladesbetrag",
    cardGroup_00071: "Anmeldungspasswörte",
    cardGroup_00072: "Ladenkarte erfolgreich erstellt", 
    cardGroup_00073: "verfügbar",
    cardGroup_00074: "Karteverwaltung",
    cardGroup_00075: "von Kartegruppe",
    cardGroup_00076: "Einzelheiten",
    cardGroup_00077: "Führen Sie die folgenden Aktionen",
    cardGroup_00078: "1. die Speichertypskarte aufladen",
    cardGroup_00079: "2. die Aufladungshistorie suchen",
    cardGroup_00080: "3. die Kontaktinformationen der Ladekarte zu ändern",
    cardGroup_00081: "Erledigt",
    cardGroup_00082: "bestätigen",
    cardGroup_00083: "Bitte geben Sie die Nummer des Personalausweises ein",
    cardGroup_00084: "Bitte geben Sie die richtige Kartennummer ein",
    cardGroup_00085: "Die Kartennummer darf keine chinesischen Zeichen enthalten",
    cardGroup_00086: "Bitte geben Sie Kartequelle ein",
    cardGroup_00087: "Die Quelle darf nicht 'Plug & Charge' sein",
    cardGroup_00088: "Bitte geben Sie das richtige Kfz-Kennzeichen ein",
    cardGroup_00089: "Bitte geben Sie das richtige Handynummer ein",
    cardGroup_00090: "Der Aufladebetrag ist in falsche Format",
    cardGroup_00091: "Bitte geben Sie Passwörte ein",
    cardGroup_00092: "Eingabe des Online-Ausweises erfolgreich",
    cardGroup_00093: "Neue Plug&Charge-Karte",
    cardGroup_00094: "Passende Fahrzeug-VIN des Benutzers suchen",
    cardGroup_00095: "Verbesserung der Karteninformationen",
    cardGroup_00096: "Neue Karte fertig erstellt",
    cardGroup_00097: "Diese Handynummer stimmt mit mehreren Fahrzeugen ({type}) innerhalb 2 Wochen überein, bitte bestätigen Sie die Fahrzeuge ({type}) manuell!",
    cardGroup_00098: "Spitzname|Handynummer",
    cardGroup_00099: "Kfz-VIN",
    cardGroup_00100: "Letzte|Ladeendezeit",
    cardGroup_00101: "Letzte|Ladestation",
    cardGroup_00102: "bestätigen",
    cardGroup_00103: "zurück",
    cardGroup_00104: "nächste",
    cardGroup_00105: "Plug&Charge",
    cardGroup_00106: "Ladestationsliste",
    cardGroup_00107: "Ladestation hinzufügen",
    cardGroup_00108: "Ladestationsname",
    cardGroup_00109: "CPO",
    cardGroup_00110: "Ladestationseinzelheit",
    cardGroup_00111: "löschen",
    cardGroup_00112: "Bestätigen Sie diese Ladestation zu entfernen?",
    cardGroup_00113: "Tipp",
    cardGroup_00114: "bestätigen",
    cardGroup_00115: "输入站点名称、运营商名称",
    cardGroup_00116: "Ladestationsstatus",
    cardGroup_00117: "Für die Öffentlichkeit zugänglich",
    cardGroup_00118: "Ladesäule",
    cardGroup_00119: "AC",
    cardGroup_00120: "DC",
    cardGroup_00121: "hinzufügen",
    cardGroup_00122: "hinzugefügt",
    cardGroup_00123: "aktiviert",
    cardGroup_00124: "nicht aktiviert",
    cardGroup_00125: "Offen",
    cardGroup_00126: "Nicht geöffnet",
    cardGroup_00127: "Fzg-MAC",
    cardGroup_00128: "Benutzerspezifischer Fahrzeug-MAC suchen",
    cardGroup_10000: "Charging Cards",
    cardGroup_10001: "SOC limit for in-group card charging",
    cardGroup_10002: "Please enter an integer of 1-100",
    cardGroup_10003: "Download batch import template",
    cardGroup_10004: "Batch import of ID cards",
    cardGroup_10005: "Batch import of plug-and-charge cards",
    cardGroup_10006: "1. Please download the batch import template first and fill in the charging card related information in the template.",
    cardGroup_10007: "If you import Plug-and-Charge cards in bulk, please enter the card number in the template by the vehicle MAC used in vehicle charging.",
    cardGroup_10008: "If you import ID cards in bulk, please enter the card number recognized by the NFC card reader in the template.",
    cardGroup_10009: "2. Please select the corresponding button to import the charging card based on the type of charging card.",
    cardGroup_10010: "Please use the template to batch import!",
    cardGroup_10011: "In the template file, please input  Mobile and Vehicle license number correctly for the following cards.",
    cardGroup_10012: "In the template file, the following cards already exist on the platform.",
    cardGroup_10013: "{totalCount} cards in total,successfully imported {successCount} cards.",
    cardGroup_10014: "{totalCount} cards in total,successfully imported {successCount} cards, failed to import {failCount} cards.",
    cardGroup_10015: "In der vorlage sind die folgenden karteikarten bereits in anderen formaten enthalten.",
    cardGroup_10016: "card groups：{0}",
    cardGroup_10017: "cards：",



    support_u2_card:"Prepaidkarte unterstützen",
    support_u1_card:"Start/Stop-Karte unterstützen",
    working_mode_title:"Begrüßungsinformationen | (Informationen zum Anzeigen auf dem Bildschirm)",
    config_card_welcome:"Willkommen bei XCharge Ladestation",
    config_card_tip_1:"Nennstrom | (Der tatsächliche Strom hängt von der Ladestation ab)",
    addTimeInterval:"Zeiten zufügen",
    addTimeIntervalPrompt:"Max.12 Zeiten können hinzugefügt werden",
    beginTime: "Startdatum",
    endTime: "Endedatum",
    electricity_fee:"Strompreis",
    service_fee:"Servicegebühr",
    operation: "Operation",
    electric_unit: "kWh",
    config_card_wifi_ssid:"Wi-Fi SSID",
    config_card_wifi_pass:"Wi-Fi-Passwort",
    select_input: "Optional",
    elec_lock: "Elektromagnetisches Schloss",
    elec_lock_status: "Status der Elektromagnetisches Schloss ",
    unavailable: "nicht verfügbar",
    available: "verfügbar",
    unlock_before_pay: "Vollgeladen und Freischalten",
    unlock_after_pay: "Rechnung und Freischalten",
    radar: "Radar",
    radar_status: "Radarstatus",
    config_card_make_success:"Die Konfigurationskarte wurde erfolgreich erstellt.",
    add_charge_card_remind_3:"1. Laden Sie die Prepaidkarte auf.",
    add_charge_card_remind_4:"2. Die Aufladehistorie ansehen.",
    add_charge_card_remind_5:"3. Die Kontaktinformationen der Ladekartebesitzer ändern.",
    finish: "Erledigt",
    nextStep: "nächste",
    preStep: "zurück",
    ac: "AC",
    dc: "DC",
    user_control_add: "hinzufügen",
    user_control_added: "hinzugefügt",
    enabled: "aktiviert",
    disabled: "nicht aktiviert",
    open: "offen",
    unopen: "nicht geöffnet",
    charge_card_operation_remind_1:"Karte kann nicht gelesen werden! Tipp: Stellen Sie sicher, dass der Kartenleser angeschlossen ist, und legen Sie die NFC-Karte auf den Kartenleser.",
    charge_card_operation_remind_2:"Die Karte ist keine leere Karte",
    charge_card_operation_remind_3:"Die Karte ist eine leere Karte",
    charge_card_operation_remind_4:"Die Karte gehört nicht zum aktuellen Gruppenkonto",
}