export default {
  diversionDetail_00001: 'Zugangsdaten',
  diversionDetail_00002: 'Flusszentrum',
  diversionDetail_00003: 'Fluss öffnen',
  diversionDetail_00004: 'Dieser Flussteilnehmer ist bereits mit dem XCharge-QR-Code kompatibel. Sie müssen in den Details auf einen festen QR-Code umsteigen',
  diversionDetail_00005: 'Dieser Flussteilnehmer ist bereits mit dem XCharge-QR-Code kompatibel. Sie müssen den QR-Code ausdrucken und einfügen',
  diversionDetail_00006: 'Bitte geben Sie den Ladestationsnamen ein',
  diversionDetail_00007: 'Ladestationsliste',
  diversionDetail_00008: 'Reservenverwaltung',
  diversionDetail_00009: 'Alle Rabbat',
  diversionDetail_00010: 'Ladestationsname',
  diversionDetail_00011: 'CPO',
  diversionDetail_00012: 'Ladestationsstatus',
  diversionDetail_00013: 'Aktiviert',
  diversionDetail_00014: 'Nicht aktiviert',
  diversionDetail_00015: 'Ladestation',
  diversionDetail_00016: 'AC',
  diversionDetail_00017: 'DC',
  diversionDetail_00018: 'Öffnungsstatus für Zugangsfluss',
  diversionDetail_00019: 'Öffnungsstatus schließen',
  diversionDetail_00020: 'Öffnungs zustimmen',
  diversionDetail_00021: 'Aktion',
  diversionDetail_00022: 'Massweise Export QR Code',
  diversionDetail_00023: 'Tarifeinstellungen',
  diversionDetail_00024: 'Ladestationrabatte',
  diversionDetail_00025: 'Start/Stopp-Einstellungen',
  diversionDetail_00026: 'CPO-Plattform',
  diversionDetail_00027: 'Aggregationsplattform',
  diversionDetail_00028: 'OEM-Plattform',
  diversionDetail_00029: 'Regierungsplattform',
  diversionDetail_00030: 'Kartenplattform',
  diversionDetail_00031: 'Regierung',
  diversionDetail_00032: 'Frei',
  diversionDetail_00033: 'Direktanmeldung',
  diversionDetail_00034: 'XCHARGE-Paket',
  diversionDetail_00035: 'Zugangsdaten-',
  diversionDetail_00036: 'Personenkraftwagen',
  diversionDetail_00037: 'Logistikfahrzeuge',
  diversionDetail_00038: 'Mittlerer Bus',
  diversionDetail_00039: 'Bus',
  diversionDetail_00040: 'E-Fahrrad',
  diversionDetail_00041: 'Geöffnet - kein Start/Stop',
  diversionDetail_00042: 'Geöffnet - Start/Stop möglich',
  diversionDetail_00043: 'Nicht offen',
  diversionDetail_00044: 'offen',
  diversionDetail_00045: 'Keine Ladesäulen an der Station aktiviert, bitte aktivieren Sie mindestens eine Ladesäule',
  diversionDetail_00046: 'von Stationsinformationen',
  diversionDetail_00047: 'Ländercode',
  diversionDetail_00048: 'Provinz- und stadtordnungen',
  diversionDetail_00049: 'Parkgebühr',
  diversionDetail_00050: 'Ladestationstyp',
  diversionDetail_00051: 'Baustelle',
  diversionDetail_00052: 'Anzahl der Parkplätze',
  diversionDetail_00053: 'Telefonnummer des Kundendienstes',
  diversionDetail_00054: 'Bilder aus dem wirklichen Leben',
  diversionDetail_00055: 'ist leer',
  diversionDetail_00056: 'Bitte setzen Sie in [Ladestationseinzelheit] - [Ladestation-Info] - [Status Info] "Ob offen zum Außen" auf "Offen',
  diversionDetail_00057: 'Ladestation',
  diversionDetail_00058: 'Xcharge',
  diversionDetail_00059: 'Geschichte der Reserveaufladung',
  diversionDetail_00060: 'Flussplattform eines Dritten',
  diversionDetail_00061: 'Ob Fonds reservieren',
  diversionDetail_00062: 'Aktuelle Guthaben',
  diversionDetail_00063: 'Reservebetrag',
  diversionDetail_00064: 'Reserveaufladen',
  diversionDetail_00065: 'Kann mit einem negativen Wert gefüllt werden, um das Konto auszugleichen',
  diversionDetail_00066: 'Guthabenerinnerung',
  diversionDetail_00067: 'Automatischer Ladestopp, wenn der Guthaben kleiner als 0 ist',
  diversionDetail_00068: 'Ja',
  diversionDetail_00069: 'Nein',
  diversionDetail_00070: 'Aufladebetrag',
  diversionDetail_00071: 'Abzugsaufladenszeit',
  diversionDetail_00072: 'zurück',
  diversionDetail_00073: 'Erfolgreich geändert',
  diversionDetail_00074: 'Bitte geben Sie eine Nummer ein',
  diversionDetail_00075: 'Vom CPO für XCharge gegebene Rabatt auf die Servicegebühr (%)',
  diversionDetail_00076: 'Keine Ermäßigung auf die Gesamtkosten einer einzelnen Gebühr',
  diversionDetail_00077: '(%)Rabatt für einmalige Servicegebühren',
  diversionDetail_00078: '85 bedeutet 85% Rabatt, 0 bedeutet kostenlos',
  diversionDetail_00079: 'Rabatt pro kWh für eine einmalige Aufladung',
  diversionDetail_00080: 'Anzeigename',
  diversionDetail_00081: 'Bitte geben Sie den Anzeigenamen ein, empfohlenes Format: Markenname Ladestation (Statioinsname), das Schlüsselwort "Markenname" muss aus dem Standortnamen entfernt werden',
  diversionDetail_00082: 'Ob Start-/Stopp von Dritten zugelassen werden sollen',
  diversionDetail_00083: 'Nur bestimmten Nutzern das Aufladen erlauben',
  diversionDetail_00084: 'Noch einmal nachdenken',
  diversionDetail_00085: 'Alles klar',
  diversionDetail_00086: 'Füllen Sie aus',
  diversionDetail_00087: 'Name kann nicht leer sein',
  diversionDetail_00088: 'Erfolgreich öffnen',
  diversionDetail_00089: 'Hinweise',
  diversionDetail_00090: 'Füllen Sie die Informationen aus',
  diversionDetail_00091: 'ok',
  diversionDetail_00092: 'Start/Stopp von Dritten zulassen',
  diversionDetail_00093: 'Direkttarifgestaltung',
  diversionDetail_00094: 'Wenn der Tarif geändert wurde, klicken Sie auf "Push Tariff" in Ladestationseinzelheit - Tariff, um den Tarif wirksam zu machen!',
  diversionDetail_00095: 'AC-Tarif',
  diversionDetail_00096: 'AC-Tarif wählen',
  diversionDetail_00097: 'Gleichstromtarif',
  diversionDetail_00098: 'Gleichstromtarif wählen',
  diversionDetail_00099: 'Einstellungen für Ladestationsrabatte',
  diversionDetail_00100: 'Ladestationsrabatt hinzufügen',
  diversionDetail_00101: 'Startzeit',
  diversionDetail_00102: 'Jetzt',
  diversionDetail_00103: 'Endzeit',
  diversionDetail_00104: 'unbegrenzt',
  diversionDetail_00105: 'Angebotsmethode',
  diversionDetail_00106: '85 bedeutet 85% Rabatt',
  diversionDetail_00107: 'Rabatt pro kWh (EUR)',
  diversionDetail_00108: 'Verwenden Sie die Methode des Servicebetrag',
  diversionDetail_00109: 'Verwendung der Rabattmethode pro kWh',
  diversionDetail_00110: 'Status',
  diversionDetail_00111: 'Nicht gestartet',
  diversionDetail_00112: 'gestartet',
  diversionDetail_00113: 'wirksam',
  diversionDetail_00114: 'nicht wirksam',
  diversionDetail_00115: 'Geschichte der Ladestationsrabatte',
  diversionDetail_00116: 'Bitte speichern Sie die vorherige Aktion!!',
  diversionDetail_00117: 'Startzeit kann nicht leer sein',
  diversionDetail_00118: 'Endezeit kann nicht leer sein',
  diversionDetail_00119: 'Angebote Methode kann nicht leer sein.',
  diversionDetail_00120: 'Bitte geben Sie eine positive ganze Zahl kleiner als 100 ein',
  diversionDetail_00121: 'Erfolgreich gespeichert',
  diversionDetail_00122: 'Ermäßigung der Dienstleistungsgebühr: {dis}%',
  diversionDetail_00123: 'Rabatt pro kWh:{dis}%',
  diversionDetail_00124: 'Schließen für {name} geöffenete Standortinformationen öffnen, für {name} wird keine Ladestationsinformationen angezeigt.',
  diversionDetail_00125: 'Bestätigen Sie, dass die Informationen zur Ladestation{Name} geschlossen sind?',
  diversionDetail_00126: 'Die tanks sind voll',
  diversionDetail_00127: 'Versuche es noch einmal. Die tanks sind nicht an.',
  diversionDetail_00128: 'Maschinen in betrieb!',
  diversionDetail_00129: 'Push actual power',
  detailDepot_30011: "Please fill out the form completely",
}