export default {
  alipayApp_00001: "Anthem open platform to create applications",
  alipayApp_00002: "To add Alipay payment method to WeChat Official account, you need to create an application in Anthem Open Platform before you can use it. Please use your Alipay account to log in to Anthem Open Platform (a real-name certified enterprise Alipay account is required). For the first time, please select 'Resident Status' as Self-developed Developer.",
  alipayApp_00003: "Click to view the process of first-time membership in Anthem's open platform.",
  alipayApp_00004: "Please create the [Payment Access] application in [Developer Center] - [Web & Mobile Application]. When you create the application, if you have selected the usage scenario, please select the usage scenario as [Self-use application]. Add [Mobile Website Payment] function. You can sign up immediately or after you add the application successfully.",
  alipayApp_00005: "Click to view the process of creating an application.",
  alipayApp_00006: "When creating the application, please select 'RSA (SHA256) secret key' as the interface countersigning method to set the application public key. Before setting the application public key, please generate the secret key first. When generating the secret key, choose PKCS8(JAVA applicable) for the secret key format and 2048 for the secret key length.",
  alipayApp_00007: "Click to view the secret key generation process.",
  alipayApp_00008: "After adding the application, please complete the application APPID setting and secret key setting in the operation platform, and then apply for the application online.",
  alipayApp_00009: "Go to Anthem Open Platform",
  alipayApp_000010: "Application App ID Settings",
  alipayApp_000011: "After you finish creating the app, please go to Anthem Open Platform and add the created app APPID here in [Developer Center] - [Web & Mobile App].",
  alipayApp_000012: "APPID",
  alipayApp_000013: "Secret key setting",
  alipayApp_000014: "After you finish creating the application, please add the application private key generated by [Add signature method] [RSA(SHA256) secret key] setting when you add the application here. Also check the Alipay public key and add the Alipay public key here. Later, if you change the secret key in Anthem Open Platform, please add it here again, otherwise WeChat Public cannot use Alipay payment function.",
  alipayApp_000015: "Application private key and Alipay public key",
  alipayApp_000016: "Set up",
  alipayApp_000017: "Set the time:",
  alipayApp_000018: "Show Alipay payment method in WeChat official account",
  alipayApp_000019: "After creating the application in Anthem Open Platform, you can apply for the application to be online in Anthem Open Platform after completing the application APPID setting and secret key setting in the operation platform. After the application is successfully launched on Anthem Open Platform, you can set 'Yes' to show Alipay payment method in WeChat public number, otherwise users cannot use Alipay payment in WeChat public number.",
  alipayApp_000020: "Settings",
  alipayApp_000021: "Please add the application private key here (required）",
  alipayApp_000022: "please add Paypal public key here (required)",
  alipayApp_000023: "Not set",
  alipayApp_000024: 'Please set "No" to "Show Alipay payment method in WeChat public website" first',
  alipayApp_000025: "",
  alipayApp_000026: "",
  
}