export default {
    cardSales_00001: 'Discount shopping store orders',
    cardSales_00002: 'Order No.',
    cardSales_00003: 'Please enter username/mobile number',
    cardSales_00004: 'Start date',
    cardSales_00005: 'End date',
    cardSales_00006: 'To',
    cardSales_00007: 'Price(€)',
    cardSales_00008: 'Purchase count',
    cardSales_00009: 'Number of purchases',
    cardSales_00010: 'Order list',
    cardSales_00011: 'Export orders',
    cardSales_00012: 'all',
    cardSales_00013: 'Electricity ticket',
    cardSales_00014: 'Coupon',
    cardSales_00015: 'Paid amount',
    cardSales_00016: 'Wechat pay',
    cardSales_00017: 'Wechat Official Accounts',
    cardSales_00018: 'Download the order list as an Excel file?',
    cardSales_00019: 'Reminder',
    cardSales_00020: 'Start downloading',
    cardSales_00021: 'Cancel the download',
  }