export default {
    begin_date_select_remind: "Beginn Datum",
    end_date_select_remind: "Ende Datum",
    to: "bis",
    use_condition:"Nutzungsbedingunge",
    fee_at_least:"Mindestes Gesamtkosten einer einzigen Laudng",
    yuan:"Euro",
    fee_discount:"Ermäßigungsbetrag für Gesamtbetrag",
    description_info_1:"Hinweis: Wenn Sie 10 Ermäßigung auf die Gesamtkosten einer einzelnen Ladung von 60 EUR oder mehr festlegen und die Gesamtkosten für diese Gebühr 100 EUR betragen, dann beträgt der tatsächlich zu zahlende Betrag diesmal 90 EUR (100-10=90).",
    service_fee_at_least:"Mindestes Gesamtkosten einer einzigen Laudng",
    service_fee_discount:"Ermäßigungsbetrag für Sevicegebühr",
    description_info_2:"Wenn Sie 10 Ermäßigung auf die Servicegebühr einer einzelnen Ladung von 20 EUR oder mehr festlegen und die Gesamtkosten für diese Gebühr 100 EUR (Elektrizitätsgebühr 70 EUR, Servicegebühr 30 EUR) betragen, dann beträgt der tatsächlich zu zahlende Betrag diesmal 90 EUR (70+(30-10)=90).",
    description_info_3:"Wenn Sie die Gesamtkosten für eine einzelne Gebühr so festlegen, dass sie den Gesamtkosten von 60EUR und 15 % Rabatt entsprechen, und die Gesamtkosten für diese Gebühr 100EUR betragen, beträgt der tatsächlich zu zahlende Betrag diesmal 85EUR (100*85%=85).",
    description_info_4:"Hinweis: Wenn Sie die einmalige Servicegebühr so einstellen, dass der 15% Rabatt von 20EUR Servicegebühr eingehalten wird, kostet die Gebühr dieses Mal 100EUR (einschließlich 70EUR Stromgebühr und 30EUR Servicegebühr), dann beträgt der tatsächlich zu zahlende Betrag dieses Mal 95,5 EUR (70+30*85%=95,5).",
    percent_discount:"Rabatt auf Gesamtbetrag",
    percent:"%",
    all_site:"alle Ladestation",
    self_run_site:"selbst betriebene Ladestation",
    custom_site:"selbst definierte Ladestation",
    add_site:"Ladestation hinzufügen",
    site_name: "Ladestationsname",
    operator: "Betreiber",
    enter_site:"Bitte geben Sie Ladestationsname, Betreibersname ein.",
    query: "suchen",
    confirm: "feststellen",
    siteStatus: "Ladestationsstatus",
    isOpen: "Zugänglich für die Öffentlichkeit",
    enable: "aktivieren",
    enabled: "aktiviert",
    disabled: "nicht aktiviert",
    open: "geöffnet",
    unopen: "nicht geöffnet",
    device_type_dc: "DC",
    device_type_ac: "AC",
    chargeEquipment: "Ladestation",
    member_00072: 'beseitigen',
    cancel: 'beseitigen',
    member_00073: 'beseitigen',
    ac:"AC",
    dc:"DC",
    delete_success: "Erfolgreiche Löschen",
    charging_package44:"Bestätigen Sie diese Nachricht zu löschen",
    operate_canceled: "Operation beseitigen",
    discount_amount_5:"85 heißt 15% Rabatt, 0 heißt kostenfrei",
    service_percent_discount:"Rabatt für Servicegebühr",
    remind: "Hinweise",
    coupon_error_message_1:"Bitte geben Sie einen Coupon-Namen mit weniger als 16 Zeichen ein.",
    coupon_error_message_2:"Bitte füllen Sie die Couponinformationen aus.",
    coupon_error_message_3:"Bitte geben Sie richtige Gültigkeitsdauer ein. ",
    coupon_error_message_4:"Beginndatum soll früher als oder gleich wie Endedatum sein.",
    coupon_error_message_5:"Beginndatum soll früher als oder gleich wie aktuelle Datum sein.",
    coupon_error_message_6:"Bitte geben Sie Ermäßigungsbetrag ein.",
    coupon_error_message_7:"Bitte geben Sie Rabatt ein.",
    coupon_error_message_8:"Bitte geben Sie richtige Betrag ein.",
    coupon_error_message_9:"Bitte füllen Sie Couponinformationen aus.",
    coupon_error_message_10:"Bitte wählen Sie die Ladestation aus, auf der Sie den Coupon hinzufügen möchten.",
    common_00241: "erfolgreich hinzufügen",
    text_rule1: 'Bitte geben Sie eine Zahl mit bis zu zwei Dezimalstellen ein.',
    coupon_00001: 'Marketing Zentrum',
    coupon_00002: 'Angebote Instrument',
    coupon_00003: 'Name',
    coupon_00004: 'Bitte eingeben',
    coupon_00005: 'zurücksetzen',
    coupon_00006: 'suchen',
    coupon_00007: 'Liste',
    coupon_00008: 'neu erstellen',
    coupon_00009: 'Nutzungsbedingung',
    coupon_00010: 'Gültigkeitsdauer',
    coupon_00011: 'Operation',
    coupon_00012: 'Einzelheit',
    coupon_00013: 'Gutschein',
    coupon_00014: 'Coupon',
    coupon_00015: 'Ändern',
    coupon_00016: 'Information',
    coupon_00017: 'beseitigen',
    coupon_00018: 'speichern',
    coupon_00019: 'ändern',
    coupon_00020: 'löschen',
    coupon_00021: 'relativ',
    coupon_00022: 'absolut',
    coupon_00023: 'Datum der Nutzergewinnung',
    coupon_00024: 'Bitte geben Sie eine natürliche Zahl ein',
    coupon_00025: 'Tag',
    coupon_00025_1: 'bis',
    coupon_00026: 'Nutzungsbedingung',
    coupon_00027: 'verfügbare Ladestationen',
    coupon_00028: 'Ladestationsname',
    coupon_00029: 'suchen',
    coupon_00030: 'Batch entfernen',
    coupon_00031: 'Operation',
    coupon_00032: 'entfernen',
    coupon_00033: 'nicht wiederherstellbar',
}
