export default {
  outlineCard_00001: '运营商充电卡管理',
  outlineCard_00002: '即插即充充电卡详情',
  outlineCard_00003: '在线身份充电卡详情',
  outlineCard_00004: '离线储值卡详情',
  outlineCard_00005: '离线启停卡详情',
  outlineCard_00006: '基本信息',
  outlineCard_00007: '所在团体',
  outlineCard_00008: '充值记录',
  outlineCard_00009: '即插即充充电卡充值',
  outlineCard_00010: '在线身份充电卡充值',
  outlineCard_00011: '查询订单',
  outlineCard_00012: '解除挂失',
  outlineCard_00013: '卡片挂失',
  outlineCard_00014: '充值',
  outlineCard_00015: '车辆{type}：',
  outlineCard_00016: '车牌号：',
  outlineCard_00017: '联系人姓名：',
  outlineCard_00018: '联系人手机号：',
  outlineCard_00019: '当前余额：',
  outlineCard_00020: '充值',
  outlineCard_00021: '单位',
  outlineCard_00022: '填写充值金额',
  outlineCard_00023: '登录密码',
  outlineCard_00024: '必填',
  outlineCard_00025: '请输入密码',
  outlineCard_00026: '充值成功',
  outlineCard_00027: '充 值',
  outlineCard_00028: '完 成',
  outlineCard_00029: '即插即充卡信息',
  outlineCard_00030: '在线身份卡信息',
  outlineCard_00031: '确定将该卡挂失吗?',
  outlineCard_00032: '确定将该卡解除挂失吗?',
  outlineCard_00033: '确定',
  outlineCard_00034: '取消',
  outlineCard_00035: '挂失成功',
  outlineCard_00036: '解除挂失成功',
  outlineCard_00037: '操作已取消',
  outlineCard_00038: '请填写完整',
  outlineCard_00039: '卡号',
  outlineCard_00040: '不可修改',
  outlineCard_00041: '卡片类型',
  outlineCard_00042: '余额',
  outlineCard_00043: '以最后上报账单为准',
  outlineCard_00044: '卡片状态',
  outlineCard_00045: '挂失',
  outlineCard_00046: '正常',
  outlineCard_00047: '编号',
  outlineCard_00048: '请输入编号',
  outlineCard_00049: '保存',
  outlineCard_00050: '修改',
  outlineCard_00051: '卡片来源',
  outlineCard_00052: '所属充电卡组',
  outlineCard_00053: '是否接受场站充电限制约束',
  outlineCard_00054: '是',
  outlineCard_00055: '否',
  outlineCard_00056: '车辆信息',
  outlineCard_00057: '车牌号',
  outlineCard_00058: '请输入车牌号',
  outlineCard_00059: '所在地',
  outlineCard_00060: '车型',
  outlineCard_00061: '手动输入',
  outlineCard_00062: '车品牌型号',
  outlineCard_00063: '联系人信息',
  outlineCard_00064: '联系人姓名',
  outlineCard_00065: '联系人手机号',
  outlineCard_00066: '离线储值卡',
  outlineCard_00067: '离线启停卡',
  outlineCard_00068: '智充配置卡',
  outlineCard_00069: '即插即充',
  outlineCard_00070: '在线身份卡',
  outlineCard_00071: "包含赠送{promotionBalance}{unit}",
  outlineCard_00072: "包含团体分配{num}{unit}",
  outlineCard_00073: "您确定解除VIN",
  outlineCard_00074: "再想想",
  outlineCard_00075: "解除成功",
  outlineCard_00076: "请保存上一步操作",
  outlineCard_00077: "参数为空",
  outlineCard_00078: "车牌号格式有误！",
  outlineCard_00079: "简易身份卡来源不允许填写“即插即充”",
  outlineCard_00080: "请选择车型",
  outlineCard_00081: "手机号或车牌号为空",
  outlineCard_00082: "修改成功",
  outlineCard_00083: "团体列表",
  outlineCard_00084: "加入团体",
  outlineCard_00085: "批量移除",
  outlineCard_00086: "团体名称",
  outlineCard_00087: "支付方式",
  outlineCard_00088: "最大未支付单数",
  outlineCard_00089: "成员总数",
  outlineCard_00090: "操作",
  outlineCard_00091: "团体详情",
  outlineCard_00092: "移除",
  outlineCard_00093: "最大未支付金额({unit})",
  outlineCard_00094: "加入",
  outlineCard_00095: "已加入",
  outlineCard_00096: "加入成功",
  outlineCard_00097: "确定移除所选所有团体吗？",
  outlineCard_00098: "提示",
  outlineCard_00099: "确定移除当前团体吗？",
  outlineCard_00100: "移除成功",
  outlineCard_00101: "用户自行付费",
  outlineCard_00102: "资金账户",
  outlineCard_00103: "充值列表",
  outlineCard_00104: "充值日期",
  outlineCard_00105: "充值总金额({unit})",
  outlineCard_00106: "充值本金({0})",
  outlineCard_00107: "充值赠送金额({0})",
  outlineCard_00108: "充值方式",
  outlineCard_00109: "微信公众号（支付宝）",
  outlineCard_00110: "微信公众号（微信支付）",
  outlineCard_00111: "平台",
  outlineCard_00112: "操作员",
  outlineCard_00113: "解除绑定",
  outlineCard_00114: "确定将该卡删除吗",
  outlineCard_00115: "卡片删除",
}