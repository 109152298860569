export default {
  orderAnalysis_00001: '工单统计',
  orderAnalysis_00002: '分析',
  orderAnalysis_00003: '场站工单数量统计',
  orderAnalysis_00004: '角色处理工单统计',
  orderAnalysis_00005: '工单分类统计',
  orderAnalysis_00006: '工单优先级统计',
  orderAnalysis_00007: '工单问题来源统计',
  orderAnalysis_00008: '设备品牌型号工单统计',
  orderAnalysis_00009: '工作人员上报',
  orderAnalysis_00010: '客户反馈',
  orderAnalysis_00011: '设备上报',
  orderAnalysis_00012: '自动触发',
  orderAnalysis_00013: '其他',
  orderAnalysis_00014: '一般',
  orderAnalysis_00015: '紧急',
  orderAnalysis_00016: '非常紧急',
}