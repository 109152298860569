export default {
    from_get_coupon_on:"领券之日起{validityDays}日",
    rewardSetting_0001: '奖励设置',
    rewardSetting_0002: '数量',
    rewardSetting_0003: '添加卡券',
    rewardSetting_0004: '请输入卡券名称',
    rewardSetting_0005: '卡券类型',
    rewardSetting_0006: '卡券名称',
    rewardSetting_0007: '类型',
    rewardSetting_0008: '有效期',
    rewardSetting_0009: '升级任务-{name}',
    rewardSetting_0010: '保级任务-{name}',
    rewardSetting_00011: '如果添加有效期位绝对时间的卡券，请注意在卡券失效时间前将卡券从奖励中删除。',
    rewardSetting_00012: '请输入数字，数量不能超过100',
    rewardSetting_00013: '用户会员等级',
    rewardSetting_00014: "获取之日{validityDays}日内",
    
}