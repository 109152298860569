export default {
    auto_00001: "We will use the template message of the XCHARGE official WeChat account to notify the targeted group with the corresponding message category.",
    auto_00002: "Please use WeChat to scan the QR code of the official WeChat account on the left, and follow XCHARGE’s official WeChat account.",
    auto_00003: "In the function menu below the official WeChat account, you can find and open the 'Smart Maintenance' MiniApp. Use the platform account to log in to the 'Smart Maintenance' MiniApp.",
    auto_00004: "You can receive maintenance notification of the corresponding account through the XCHARGE official WeChat account.",
    auto_00005: "Equipment Failure (This error will resulted in charging function disable)",
    auto_00006: "Equipment Abnormal (This error will not resulted in charging function disable)",
    auto_00007: "Charger Offline",
    auto_00008: "For example: emergency stop button pressed, chassis temperature alarm, etc.",
    auto_00009: "For example: electromagnetic lock failed to lock, power module alarm, etc.",
    auto_00010: "Notification Category",
    auto_00011: "Notification Target Group",
    auto_00012: "Action",
    auto_00013: "Edit Notification Target Group",
    auto_00014: "All",
    auto_00015: "Notify Automatically",
    auto_00016: "Remove",
    auto_00017: "Role",
    auto_00018: "Account",
    auto_00019: "Action",
    auto_00020: "Set notification frequency",
    auto_00021: "Every",
    auto_00022: "day",
    auto_00023: "Each charger will notify each fault only",
    auto_00024: "Each charger will notify all faults only",
    auto_00025: "times",
    auto_00026: "Default",
    auto_00027: "Each charger is notified of offline faults only",
}