export default {
    whiteList_00001: 'Whitelist',
    whiteList_00002: 'Station management',
    whiteList_00003: 'Charger management',
    whiteList_00004: 'Whitelist configuration',
    whiteList_00005: 'Card number/Name/Mobile number',
    whiteList_00006: 'All',
    whiteList_00007: 'Online ID card',
    whiteList_00008: 'Plug and Charge card',
    whiteList_00009: 'Reset',
    whiteList_00010: 'Search',
    whiteList_00011: 'Name',
    whiteList_00012: 'Mobile number',
    whiteList_00013: 'Number',
    whiteList_00014: 'Card number',
    whiteList_00015: 'Card type',
    whiteList_00016: 'Permission source',
    whiteList_00017: 'Operatioin',
    whiteList_00018: 'View Details',
    whiteList_00019: 'Offline Start/Stop Card',
    whiteList_00020: 'Offline Stored Value Card',
    whiteList_00021: 'Plug&Charge',
}