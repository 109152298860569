export default {
    groupRecord_00001: "Top-up date",
    groupRecord_00002: "Total top-up amount({unit})",
    groupRecord_00003: "Top-up principal({0})",
    groupRecord_00004: "Top-up refund amount({unit})",
    groupRecord_00005: "Account balance after top-up({0})",
    groupRecord_00006: "Top-up mode",
    groupRecord_00007: "CPO",
    groupRecord_00008: "Including cashback{promotionBalance}{unit}",
    groupRecord_00009: "Cashback amount({unit})",
    groupRecord_00010: "Group account Top-up record",
    
  }