export default {
    bill_00059: "今天",
    bill_00060: "7天前",
    bill_00061: "15天前",
    bill_00077: "昨天",
    bill_00078: "30天前",



    convert_00001: '渠道兑换码记录',
    convert_00002: '营销中心',
    convert_00003: '卡券分发',
    convert_00004: '渠道兑换码记录',
    convert_00005: '请输入兑换码',
    convert_00006: '请输入分发渠道',
    convert_00007: '请输入卡券名称',
    convert_00008: '开始日期',
    convert_00009: '结束日期',
    convert_00010: '请输入用户名/手机号',
    convert_00011: '卡券类别',
    convert_00012: '全部',
    convert_00013: '代金券',
    convert_00014: '电量包',
    convert_00015: '重置',
    convert_00016: '查询',
    convert_00017: '兑换人数',
    convert_00018: '兑换次数',
    convert_00019: '兑换电量包个数',
    convert_00020: '兑换电量包额度(度)',
    convert_00021: '兑换代金券个数',
    convert_00022: '兑换代金券额度(元)',
    convert_00023: '卡券列表',
    convert_00024: '导出数据',
    convert_00025: '用户头像',
    convert_00026: '用户名(手机号)',
    convert_00027: '兑换码',
    convert_00028: '分发渠道',
    convert_00029: '兑换的卡券类别',
    convert_00030: '兑换的卡券名称',
    convert_00031: '兑换时间',
    convert_00032: '操作',
    convert_00033: '用户详情',
    convert_00034: '卡券详情',
    convert_00035: '此操作将下载渠道兑换码记录数据的Excel文件，是否继续?',
    convert_00036: '提示',
    convert_00037: '取消',
    convert_00038: '开始下载',
    convert_00039: '电量包',
    convert_00040: '代金券',
    convert_00041: '渠道兑换码记录',
    convert_00042: '特权会员',
    convert_00043: '兑换特权会员次数',
}
