
export default {
  userOrderQuery_00001: "Gruppenname",
  userOrderQuery_00002: "Vorgänge suchen",
  userOrderQuery_00003: "Vorgangsnummer",
  userOrderQuery_00004: "Zurücksetzen",
  userOrderQuery_00005: "Umsatz (EUR)",
  userOrderQuery_00006: "Vorgangsbetrag (EUR)",
  userOrderQuery_00007: "Elektrizitätmenge (EUR)",
  userOrderQuery_00008: "Betrag der Servicegebühr (EUR)",
  userOrderQuery_00009: "Betrag der Erweiterungsgebühr (EUR)",
  userOrderQuery_00010: "Ladestrom (kWh)",
  userOrderQuery_00011: "Ladezeit (min)",
  userOrderQuery_00012: "Ladeanzahl",
  userOrderQuery_00013: "bis",
  userOrderQuery_00014: "Anfangsdatum",
  userOrderQuery_00015: "Enddatum",
  userOrderQuery_00016: "Vorgängesliste",
  userOrderQuery_00017: "Vorgänge exportieren",
  userOrderQuery_00018: "Ladesäule - Ladepunkte",
  userOrderQuery_00019: "Ladestunden",
  userOrderQuery_00020: "Gelöscht",
  userOrderQuery_00021: "Ladevorgang starten",
  userOrderQuery_00022: "Laden beenden",
  userOrderQuery_00023: "Abgerechneter Strom (kWh)",
  userOrderQuery_00024: "Erstellt",
  userOrderQuery_00025: "Abgebrochen",
  userOrderQuery_00026: "Im Laden",
  userOrderQuery_00027: "zu zahlende",
  userOrderQuery_00028: "Bezahlt",
  userOrderQuery_00029: "Gruppe",
  userOrderQuery_00030: "WeChat-Benutzer",
  userOrderQuery_00031: "Administrator",
  userOrderQuery_00032: "Aktueller Vorgangsbetrag",
  userOrderQuery_00033: "Tatsächlicher Zahlungsbetrag",
  userOrderQuery_00034: "Vorgangseinzelheiten",
  userOrderQuery_00035: "Bei diesem Vorgang wird eine Excel-Datei mit der Rechnung heruntergeladen, möchten Sie fortfahren?" ,
  userOrderQuery_00036: "Hinweise",
  userOrderQuery_00037: "OK",
  userOrderQuery_00038: "Abbrechen",
  userOrderQuery_00039: "Die Daten werden gerade aufbereitet, bitte haben Sie etwas Geduld. Wenn die Daten fertig sind, wird dieses Popup-Fenster automatisch geschlossen und der Download beginnt. Bitte schließen oder aktualisieren Sie die Seite in der Zwischenzeit nicht..." ,
  userOrderQuery_00040: "Download abbrechen",
  userOrderQuery_00041: "Tage",
  userOrderQuery_00042: "Stunden",
  userOrderQuery_00043: "Minuten",
  userOrderQuery_00044: "Karte starten/stoppen",
  userOrderQuery_00045: "Gespeichertewertkarte",
  userOrderQuery_00046: "Sicherungsdaten",
  userOrderQuery_00047: "Identitätskarte",
  userOrderQuery_00048: "Double Stecke Vorgänge",
  userOrderQuery_00049: "Strom kleiner als 1 kWh",
  userOrderQuery_00050: "Die Ladezeit ist zu lang",
  userOrderQuery_00051: "Gerät wurde abnormal beendet",
  userOrderQuery_00052: "Fahrzeug abnormal beendet",
  userOrderQuery_00053: "",
  userOrderQuery_00054: "",
  userOrderQuery_00055: "",
  userOrderQuery_00056: "",
  userOrderQuery_00057: "",
  userOrderQuery_00058: "",
  userOrderQuery_00059: "", 
  userOrderQuery_00060: "",
}