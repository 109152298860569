var triggerList = [
  {
    value: 'tx',
    label: 'Charger internal communication',
    children: [
      {
      value: '4',
      label: 'Charger offline',
      code:"offline",
      disabled: true,
      },
      {
        value: '5',
        label: 'Emergency stop button pressed [A0403]',
        code:"A0403",
        disabled: true,
      },
      {
        value: '6',
        label: 'Power module alarmed [A0301]',
        code: "A0301",
        disabled: true,
      },
      {
        value: '7',
        label: 'Electromagnetic lock enable/unlock operation failed [A0502/A0503] ',
        code: "A0502/A0503",
        disabled: true,
      },
      {
        value: '8',
        label: 'The communication between the charger host and DCB extension failed [A0101]',
        code:"A0101"
      },
      {
        value: '9',
        label: 'The communication between the charging pile host and the extension DCB failed [A0102]',
        code:"A0102",
      },
    {
      value: '10',
      label: 'DC meter communication 485 inspection failed [A0103]',
      code:"A0103"
    },
      {
        value: '11',
        label: 'Power module CAN communication failed [A0104]',
        code:"A0104",
      },
      {
        value: '12',
        label: 'Connector port insulation communication module alarmed [A0105]',
        code: "A0105",
      },
      {
        value: '13',
        label: 'Self-test timeout [A0106]',
        code:"A0106"
      },
  ]
  },
  {
    value: 'dy',
    label: 'Power Module',
    children: [{
      value: '14',
      label: 'C6 charger power module address conflict/C2 charger is not initialized [A0302]',
      code: "A0302",
      disabled: true,
    }]
  },
  {
    value: 'cgq',
    label: 'Sensor',
    children: [
      {
        value: '15',
        label: 'Charging host access control failure [A0401]',
        code:"A0401",
      },
      {
        value: '16',
        label: 'Liquid level alarmed[A0402]',
        code:"A0402",
      },
      {
        value: '17',
        label: 'AC/DC shock protection alarmed[A0404/A0405]',
        code: "A0404/A0405"
      },
      {
        value: '18',
        label: 'Connector contactor device fault [A0406]',
        code:"A0406"
      },
      {
        value: '19',
        label: 'Charging host smoke sensor fault [A0407]',
        code: "A0407"
      },
      {
        value: '20',
        label: 'Chassis temperature alarmed [A0408]',
        code:"A0408"
      },
      
      {
        value: '21',
        label: 'Liquid-cooled connector head/connector tail temperature failure[A040B/A040D/A040F/A0411/A0414/A0416]',
        code:"A040B/A040D/A040F/A0411/A0414/A0416",
      },
      {
        value: '22',
        label: 'Liquid cooling fault [A0417]',
        code: "A0417"
      },
      {
        value: '23',
        label: 'Charger Host failure [A0418]',
        code:"A0418"
      },
      {
        value: '24',
        label: 'Tilt angle alarmed/fault[A040A/A0419]',
        code: "A040A/A0419",
      },
    ]
  },
  {
    value: 'jl',
    label: 'AC input',
    children: [
      {
        value: '25',
        label: 'A/B/C phase voltage is too high/low [A0701/A0702/A0703/A0704/A0705/A0706]',
        code: "A0701/A0702/A0703/A0704/A0705/A0706"
      },
      {
        value: '26',
        label: 'Ground alarm [A0707]',
        code: "A0707"
      },
      {
        value: '27',
        label: 'NEUTRAL WIRE is not connected [A0708]',
        code: 'A0708'
      },
      {
        value: '28',
        label: 'AC input circuit breaker fault [A0709]',
        code: 'A0709'
      },
      {
        value: '29',
        label: 'AC input contactor refusal/adhesion fault [A070A/A070B]',
        code:'A070A/A070B'
      },
      {
        value: '30',
        label: 'System power failure, no input [A070C]',
        code:"A070C"
      },
      {
        value: '31',
        label: 'AC input phase loss [A070E]',
        code:"A070E",
      },
    ]
  },
  {
    value: 'zl',
    label: 'DC output',
    children: [
    {
      value: '32',
      label: 'DC bus output fuse fault [A080A]',
      code: "A080A"
    },
      {
        value: '33',
        label: 'Abnormal voltage, overvoltage/undervoltage/short circuit/overload[A0801/A0802/A0803/A0804]',
        code:"A0801/A0802/A0803/A0804"
      },
      {
        value: '34',
        label: 'DC inspection failed [A0806]',
        code:"A0806"
      },
      {
        value: '35',
        label: 'Vent inspection failed [A0807]',
        code:"A0807"
      },
      {
        value: '36',
        label: 'Parallel contactor refusal/adhesion fault [A0808/A0809]',
        code: "A0808/A0809"
      },
      {
        value: '37',
        label: 'Charger host pre-charging failure fault [A080B]',
        code:"A080B"
      },
      {
        value: '38',
        label: 'The reading of the DC meter is abnormal [A080F]',
        code:"A080F"
      },
      {
        value: '39',
        label: 'The connector DC output contactor refuses to move/adhesion fault [A0810/A0811]',
        code:"A0810/A0811"
      },
  ]
  },
  {
    value: 'cws',
    label: 'Parking lock',
    children: [{
      value: '40',
      label: 'Parking lock failed to lock [C0103]',
      code:"C0103"

    }]
  },
  {
    value: 'fzdy',
    label: 'Assist power supply',
    children: [{
      value: '41',
      label: 'Assist power supply is not powered on [A0901]',
      code:"A0901"
    }]
  },
  {
    value: 'txbw',
    label: 'Communication message',
    children: [{
      value: '42',
      label: 'CMS communication message timeout and stop [A0A01/A0A02/A0A03/A0A04/A0A05/A0A06/A0A07/A0A08/A0A09/A0A0A]',
      code: 'A0A01/A0A02/A0A03/A0A04/A0A05/A0A06/A0A07/A0A08/A0A09/A0A0A'
    }]
  },
  {
    value: 'fzdy',
    label: 'The charging state is abnormal',
    children: [{
      value: '43',
      label: 'The output current of the charger is greater than 6A, and the reading of the DC meter has no change for 180s[A0B01]',
      code: 'A0B01'
    }]
  },
  {
    value: 'jycw',
    label: 'Insulation error',
    children: [{
      value: '44',
      label: 'Insulation fault [A0202]',
      code:'A0202'
    }]
  },
  {
    value: 'cdq',
    label: 'Connector',
    children: [{
      value: '45',
      label: 'Connector temperature alarmed[A0501]',
       code:'A0501'
     },
     {
       value:'46',
       label: 'The connector is abnormal (voltage changed) [A0504]',
       code:'A0504'
       }
   ]
   },
];

module.exports = triggerList;