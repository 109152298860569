export default {
    vehicleChargingLog_00001: "Bitte geben Sie das Kennzeichen ein",
    vehicleChargingLog_00002: "Startdatum",
    vehicleChargingLog_00003: "Enddatum",
    vehicleChargingLog_00004: "Kennzeichen",
    vehicleChargingLog_00005: "Fahrzeugtyp",
    vehicleChargingLog_00006: "vehicle {tag}",
    vehicleChargingLog_00007: "Eigentümer",
    vehicleChargingLog_00008: "Handynummer",
    vehicleChargingLog_00009: "Ladestartzeit",
    vehicleChargingLog_00010: "Ladeende Zeit",
    vehicleChargingLog_00011: "Charge Order Number",
    vehicleChargingLog_00012: "Ladestation",
    vehicleChargingLog_00013: "Ladeanschluss",
    vehicleChargingLog_00014: "Log details",
    vehicleChargingLog_00015: "details",
    vehicleChargingLog_00016: "Datumsbereich darf 90 Tage nicht überschreiten, bitte erneut auswählen!",
    vehicleChargingLog_00018: "Bitte wählen Sie ein Datum!",
    vehicleChargingLog_00019: "Vehicle fault",
    vehicleChargingLog_00020: "enter the vehicle VIN",
    vehicleChargingLog_00021: "Branding|Modelle",
    }