export default { //todo.
    userAllocateRecord_00001: "团体名称",
    userAllocateRecord_00002: "分配记录",
    userAllocateRecord_00003: "基本信息",
    userAllocateRecord_00004: "分配时间",
    userAllocateRecord_00005: "分配金额({0})",
    userAllocateRecord_00006: "分配人数",
    userAllocateRecord_00007: "分配渠道",
    userAllocateRecord_00008: "分配人",
    userAllocateRecord_00009: "微信公众号",
    userAllocateRecord_00010: "平台",
    userAllocateRecord_00011: "网页版管理",
    userAllocateRecord_00012: "下载分配明细",
    userAllocateRecord_00013: "至",
    userAllocateRecord_00014: "开始日期",
    userAllocateRecord_00015: "结束日期",
    userAllocateRecord_00016: "操作",
    userAllocateRecord_00017: "详情",
    userAllocateRecord_00018: "下载",
    userAllocateRecord_00019: "记录列表",
    userAllocateRecord_00020: "请选择日期",
  }