export default {
    not_begin:"Nicht gestartet",
    in_progress:"In Bearbeitung",
    paused: "pausiert",
    stopped: "beendet",
    all: "Alle",
    promotion_type1: "Geschenk aufladen (Bargeld)",
    promotion_type2: "Geschenk aufladen (Bargeld + Gutschein)",
    promotion_type3: "Geschenk aufladen (Gutschein)",
    promotion_type4: "Geschenkgutschein",
    promotion_type5: "Coupon package promotion",
    copy_link_success:"Copied to clipboard successfully",
    member_00006: 'geöffnet',
    member_00007: 'geschlossen',
    handle_giv: "Manual",
    activate_limit:"Restrictions",
    user_scope:"Users qualified",
    level_giv:"Direct gift",
    add_givuser_text1: "The object of gift",
    add_givuser_text2: "The object of gift have not been added yet.",
    giv: "give",
    giv_error_info:"Failure cause",
    card_search_giv: "Please enter the nickename or telephone for searching the user.",
    search: "Suchen",
    head_portrait: "Profilbild",
    nickname: "Nutzernahme",
    cell_phone_number: "Tel-Nr.",
    success_giv: "Send successfully",
    success_giv1: "Send successfully!",
    error_giv: "Send unsuccessfully",
    stored_00003: 'Name',
    stored_00004: 'Status',
    stored_00007: 'Liste',
    stored_00008: 'neu gebaut',
    stored_00009: 'Name',
    stored_00010: 'Zeit',
    stored_00011: 'Status',
    stored_00012: 'Aktivitätseingang',
    stored_00013: 'Dedizierter Link',
    stored_00014: 'Adresse kopieren',
    stored_00015: 'Zeigen oder nicht',
    stored_00016: 'Autobesitzer, mein Portemonnaie aufladen',
    stored_00018: 'Details',
    stored_00019: 'Manual',
    stored_00020: 'Stored value return',
    stored_00021: 'Free collection',
}
