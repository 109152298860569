export default {
    outlineCard_00001: 'Verwaltung der Ladekarten des Betreibers',
    outlineCard_00002: 'Details zu Plug- und Spielkarten',
    outlineCard_00003: 'Online Karte Details',
    outlineCard_00004: 'Angaben zur Offline-Karte',
    outlineCard_00005: 'Details zur Stoppkarte starten',
    outlineCard_00006: 'Basisinformationen',
    outlineCard_00007: 'Gruppe',
    outlineCard_00008: 'Datensatz der Aufladung',
    outlineCard_00009: 'Laden von Plug- und Spielkarten',
    outlineCard_00010: 'Aufladen der Online-Identität',
    outlineCard_00011: 'Ladevorgänge',
    outlineCard_00012: 'Den Verlust aufheben',
    outlineCard_00013: 'Karte verloren',
    outlineCard_00014: 'Aufladen',
    outlineCard_00015: 'Fahrzeug{type}：',
    outlineCard_00016: 'Nummernschild:',
    outlineCard_00017: 'Kontaktname:',
    outlineCard_00018: 'Telefonnummer:',
    outlineCard_00019: 'aktueller Saldo：',
    outlineCard_00020: 'Aufladen',
    outlineCard_00021: 'Einheit',
    outlineCard_00022: 'Geben Sie den Aufladebetrag ein',
    outlineCard_00023: 'Login Passwort',
    outlineCard_00024: 'erforderlich',
    outlineCard_00025: 'Bitte Passwort eingeben',
    outlineCard_00026: 'Aufladung erfolgreich',
    outlineCard_00027: 'Aufladen',
    outlineCard_00028: 'Abschließen',
    outlineCard_00029: 'Steck- und Ladekarteninformationen',
    outlineCard_00030: 'Information der online Ladungskarte',
    outlineCard_00031: 'Verlust melden?',
    outlineCard_00032: 'icher, dass Kartenverlust rückgängig gemacht warden soll?',
    outlineCard_00033: 'Sicher',
    outlineCard_00034: 'Abbrechen',
    outlineCard_00035: 'Verlust erfolgreich gemeldet',
    outlineCard_00036: 'Kartenverlust erfolgreich aufgehoben',
    outlineCard_00037: 'Vorgang abgebrochen',
    outlineCard_00038: 'Bitte vervollständigen',
    outlineCard_00039: 'Kartennummer',
    outlineCard_00040: 'Unveränderlich',
    outlineCard_00041: 'Speicherkarten-Typ',
    outlineCard_00042: 'Gleichgewicht',
    outlineCard_00043: 'Vorbehaltlich der eingereichten Schlussrechnung',
    outlineCard_00044: 'Kartenstatus',
    outlineCard_00045: 'Verlust melden',
    outlineCard_00046: 'normal',
    outlineCard_00047: 'Nummerierung',
    outlineCard_00048: 'Geben Sie die Nummer ein',
    outlineCard_00049: 'speichern',
    outlineCard_00050: 'Überarbeiten',
    outlineCard_00051: 'Kartenquelle',
    outlineCard_00052: 'Eigene Ladekartengruppe',
    outlineCard_00053: 'Ob das Ladelimit der Station akzeptiert werden soll',
    outlineCard_00054: 'Yes',
    outlineCard_00055: 'NO',
    outlineCard_00056: 'Fahrzeuginformationen',
    outlineCard_00057: 'Nummernschild',
    outlineCard_00058: 'Bitte geben Sie das Kennzeichen ein',
    outlineCard_00059: 'Lage',
    outlineCard_00060: 'Auto Model',
    outlineCard_00061: 'Manuelle Eingabe',
    outlineCard_00062: 'Modell',
    outlineCard_00063: 'Kontaktinformation',
    outlineCard_00064: 'Kontaktname',
    outlineCard_00065: 'Kontakt Telefonnummer',
    outlineCard_00066: 'Prepaidkarte',
    outlineCard_00067: 'Start/Stopp-Karte',
    outlineCard_00068: 'XCharge-Konfigurationskarte',
    outlineCard_00069: 'Anschließen und aufladen',
    outlineCard_00070: 'ID-Karte',
    outlineCard_00071: "Einschließlich Bonus{promotionBalance}{unit}",
    outlineCard_00072: "Gruppenzuweisung einschließen{num}{unit}",
    outlineCard_00073: "Sind Sie sicher, dass Sie die VIN entfernen?",
    outlineCard_00074: "Denk nochmal",
    outlineCard_00075: "Erfolgreich aufgehoben",
    outlineCard_00076: "Bitte vorherigen Schritt speichern!",
    outlineCard_00077: "Parameter ist leer",
    outlineCard_00078: "Das Nummernschildformat ist falsch！",
    outlineCard_00079: "Die Quelle des einfachen Personalausweises darf nicht 'plug and charge' ausfüllen",
    outlineCard_00080: "Wählen Sie ein Automodell",
    outlineCard_00081: "Handynummer oder Kennzeichen ist leer",
    outlineCard_00082: "Erfolgreich geändert",
    outlineCard_00083: "Gruppenliste",
    outlineCard_00084: "Einer Gruppe beitreten",
    outlineCard_00085: "Massenentnahme",
    outlineCard_00086: "Gruppenname",
    outlineCard_00087: "Zahlungsmethode",
    outlineCard_00088: "Maximale Anzahl unbezahlter Bestellungen",
    outlineCard_00089: "Gesamtzahl der Mitglieder",
    outlineCard_00090: "arbeiten",
    outlineCard_00091: "Gruppendetails",
    outlineCard_00092: "Entfernen",
    outlineCard_00093: "Maximaler unbezahlter Betrag({unit})",
    outlineCard_00094: "dazu kommen",
    outlineCard_00095: "beigetreten",
    outlineCard_00096: "Erfolgreich beigetreten",
    outlineCard_00097: "Möchten Sie wirklich alle ausgewählten Gruppen entfernen?",
    outlineCard_00098: "Hinweis",
    outlineCard_00099: "Möchten Sie die aktuelle Gruppe wirklich entfernen?",
    outlineCard_00100: "Erfolgreich entfernt",
    outlineCard_00101: "Benutzer zahlt",
    outlineCard_00102: "Fondskonto",
    outlineCard_00103: "Aufladeliste",
    outlineCard_00104: "Aufladedatum",
    outlineCard_00105: "Pauschalbetrag({unit})",
    outlineCard_00106: "Aufladeprinzip({0})",
    outlineCard_00107: "Guthaben aufladen({0})",
    outlineCard_00108: "Auflademethode",
    outlineCard_00109: "Öffentliches WeChat-Konto (Alipay)",
    outlineCard_00110: "Offizielles WeChat-Konto (WeChat Pay)",
    outlineCard_00111: "Plattform",
    outlineCard_00112: "Operator",
    outlineCard_00113: "Lösen",
    outlineCard_00114: "Möchten sie die karte wirklich entfernen",
    outlineCard_00115: "Löschen",
  }