export default {
    cardRecord_00001: "Gesamtbetrag des Laden{unit}",
    cardRecord_00002: "Ladekapital{0}",
    cardRecord_00003: "Geschenksbetrag von Aufladen({0})",
    cardRecord_00004: "Aufladendatensätze", 
    cardRecord_00005: "CPO Kartemanagement",
    cardRecord_00006: "Kartegruppe Management",
    cardRecord_00007: "Anzahl des Aufladens",
    cardRecord_00008: "Aufladenbetrag({0})",
    cardRecord_00009: "Geschenkbetrag({unit})",
    cardRecord_00010: "Aufladendatensätze herunterladen",
    cardRecord_00011: "Aufladendatum",
    cardRecord_00012: "Name",
    cardRecord_00013: "Aufladen Methode",
    cardRecord_00014: "Betreiber",
    cardRecord_00015: "Bei diesem Vorgang wird die Excel-Datei mit den Aufladensdatensätzen heruntergeladen. Möchten Sie fortfahren?",
    cardRecord_00016: "WeChat Öffentliche Konto",
    cardRecord_00017: "Alipay",
    cardRecord_00018: "Bezahlen mit Wechat", 
    cardRecord_00019: "Plattform",
    cardRecord_00020: "Online-Kartegruppe",
    cardRecord_00021: "Offline-Kartegruppe",
    cardRecord_00022: "Super-Kartegruppe",
    cardRecord_00023: "bis",
    cardRecord_00024: "Startdatum",
    cardRecord_00025: "Endedatum",
    cardRecord_00026: "Kartenummer",
  }