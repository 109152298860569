export default {
    createDepot_00001: 'Create Depot',
    createDepot_00002: 'Depot Center',
    createDepot_00003: 'Last Step',
    createDepot_00004: 'Support Models',
    createDepot_00005: 'Passenger Car ',
    createDepot_00006: 'Cargo Van',
    createDepot_00007: 'Midibus',
    createDepot_00008: 'Bus',
    createDepot_00009: 'Depot Name',
    createDepot_00010: 'It is recommended to use a landmark as the name',
    createDepot_00011: 'Depot Address',
    createDepot_00012: 'Input.GetPosition',
    createDepot_00013: 'Province',
    createDepot_00014: 'City',
    createDepot_00015: 'District',
    createDepot_00016: 'Street',
    createDepot_00017: 'You can modify the description of a specific street',
    createDepot_00018: 'Next Step',
    createDepot_00019: 'Basic Information',
    createDepot_00020: 'Fees',
    createDepot_00021: 'CreationComplete',
    createDepot_00022: 'Scooter',
    createDepot_00023: 'Choose rates',
    createDepot_00024: 'New rates',
    createDepot_00025: 'Start time',
    createDepot_00026: 'End time',
    createDepot_00027: 'Electricity({type}/{uint})',
    createDepot_00028: 'Fee({type}/{uint})',
    createDepot_00029: 'Idle fee({type}/{uint})',
    createDepot_00030: 'Peak/valley/flat time period',
    createDepot_00031: 'Undefined',
    createDepot_00032: 'Cusp',
    createDepot_00033: 'Peak',
    createDepot_00034: 'Flat',
    createDepot_00035: 'Valley',
    createDepot_00036: 'You can',
    createDepot_00037: 'View the details of the station;',
    createDepot_00038: 'Query the charging order of the station;',
    createDepot_00039: 'Manage the equipment of the station;',
    createDepot_00040: 'Set the charge standard of the station;',
    createDepot_00041: 'Set the operation strategy of the station, etc.',
    createDepot_00042: 'Access station management',
    createDepot_00043: 'A maximum of {0} time periods can be added',
    createDepot_00044: 'Fill in the station name, charging model, station location, all are mandatory. ',
    createDepot_00045: 'You can create a new tariff or select an existing tariff from the drop-down box.',
    createDepot_00046: 'Here is the description copy.',
    createDepot_00047: 'Station created!',
    createDepot_00048: 'in Site Management',
    createDepot_00049: 'perform the following operations:',
    createDepot_00050: 'DC rate standard',
    createDepot_00051: 'New AC charges',
    createDepot_00052: 'AC rate standard',
    createDepot_00053: 'New DC charges',
    createDepot_00054: 'Charges name',
    createDepot_00055: 'Select',
    createDepot_00056: 'Add time',
    createDepot_00057: 'Please fill in the rate standard',
    createDepot_00058: 'Delete',
    createDepot_00059: 'Cancel',
    createDepot_00060: 'Confirm ',
    createDepot_00061: 'Please verify the station information',
    createDepot_00062: 'The tariff period starts from 0 to 24',
    createDepot_00063: 'Please fill in the correct rate',
    createDepot_00064: 'New success',
    createDepot_00065: 'Select the location coordinates of the charging station',
    createDepot_00066: 'Name',
    createDepot_00067: 'Detailed address',
    createDepot_00068: 'Using this location',
    createDepot_00069: 'Address, Street, House Name',
    createDepot_00070: 'Search',
    createDepot_00071: 'Current location',
    createDepot_00072: 'Failed to get location!',
    createDepot_00073: 'The tariff name is occupied',
    createDepot_00074: 'Please select or create a new rate standard',
    createDepot_00075: 'Current usage:',
    createDepot_00076: 'Please enter the name of the rates',
    createDepot_00077: '使用货币',
    createDepot_00078: '该站点需要选择{type}的资费类型，当前没有该资费模板，请新建资费。',
    createDepot_00079: '直流桩和交流桩资费必须选择相同货币类型。',
    createDepot_00080: '场站货币类型',
    createDepot_00081: '没有需要的货币类型？',
    createDepot_00082: '点击添加',
    createDepot_00083: '添加',
    createDepot_00084: '取消',
    createDepot_00085: '添加失败',
    createDepot_00087: 'Add or manage station charger',
    createDepot_00088: 'Station acquisition failed',
}