export default {
    user_00001: "Spitzname des Benutzers, Handynummer, Autokennzeichen",
    user_00002: "Benutzer",
    user_00003: "Gefolgt",
    user_00004: "Folgen abbrechen",
    user_00005: "Nutzer exportieren",
    user_00006: "Dieser Vorgang lädt die Excel-Datei des Benutzers herunter, möchten Sie fortfahren?",
    user_00007: "Liste der Benutzer",
    user_00008: "Benutzer, die in diese Kategorie passen",
    user_00009: "Aktion",
    user_00010: "User information is not authorized",
}