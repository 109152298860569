export default {
  diversionServices_00001: 'Flow Center',
  diversionServices_00002: 'Open Leads',
  diversionServices_00003: 'Flow platform intergretion steps',
  diversionServices_00004: 'Flow platform intergretion steps',
  diversionServices_00005: "Step 1 Traffic Application If you want to access the traffic side, please contact the platform.",
  diversionServices_00006: "Step 2 Business negotiation You need to sign a business cooperation agreement with SmartChange, binding the rights and obligations of both parties. This includes, but is not limited to, system docking fees, financial settlement, etc.",
  diversionServices_00007: "Step 3 System docking The platform completes the system docking between operators and traffic parties.",
  diversionServices_00008: "Step 4 Site open&nbsp&nbsp&nbsp&nbspOperators can see the traffic that can be opened under the Open Leads module and open and close open operations on the operating sites themselves.",
  diversionServices_00009: 'Available site',
  diversionServices_00010: 'Unopened site',
  diversionServices_00011: 'Accessed',
  diversionServices_00012: 'Not accessed',
  diversionServices_00013: 'Go to open',
  diversionServices_00014: 'Map Platform',
  diversionServices_00015: 'Operating Vehicle Platform',
  diversionServices_00016: 'Aggregation Platform',
  diversionServices_00017: 'OEM platform',
  diversionServices_00018: 'Government Platform',
  diversionServices_00019: 'Government',
  diversionServices_00020: 'Free',
  diversionServices_00021: 'Direct Signing',
  diversionServices_00022: 'Xcharge general contactor',
}