export default {
    alipayApp_00001: "蚂蚁金服开放平台创建应用",
    alipayApp_00002:"微信公众号添加支付宝支付方式，需在蚂蚁金服开放平台创建应用，才可使用。请使用支付宝账号登录蚂蚁金服开放平台（需实名认证的企业支付宝账号）。 首次入驻请选择“入驻身份”为自研开发者",
    alipayApp_00003: "点击查看首次入驻蚂蚁金服开放平台流程。",
    alipayApp_00004: "请在【开发者中心】-【网页&移动应用】创建【支付接入】应用。创建应用时，如有选择使用场景，请选择使用场景为【自用型应用】。添加【手机网站支付】功能。【手机网站支付】功能需签约，可立即签约或在成功添加应用后进行签约。",
    alipayApp_00005: "点击查看创建应用流程。",
    alipayApp_00006: "创建应用时，接口加签方式请选择“RSA(SHA256)秘钥”设置应用公钥。设置应用公钥前，请先生成秘钥。生成秘钥时，秘钥格式选择PKCS8(JAVA适用），秘钥长度选择2048。",
    alipayApp_00007: "点击查看生成秘钥流程。",
    alipayApp_00008: "添加应用后，请在运营平台完成应用APPID设置、秘钥设置，再申请应用上线。",
    alipayApp_00009: "前往蚂蚁金服开放平台",
    alipayApp_000010: "应用APPID设置",
    alipayApp_000011: "创建应用完成后，请进入蚂蚁金服开放平台，在【开发者中心】-【网页&移动应用】中将创建的应用APPID添加到此处。",
    alipayApp_000012: "APPID",
    alipayApp_000013: "秘钥设置",
    alipayApp_000014: "创建应用完成后，请将添加应用时【加签方式】【RSA(SHA256)秘钥】设置生成的应用私钥添加到此处。同时查看支付宝公钥，将支付宝公钥添加到此处。后续如果您在蚂蚁金服开放平台更换秘钥，请在此处重新添加，否则微信公众号无法使用支付宝支付功能。",
    alipayApp_000015: "应用私钥和支付宝公钥",
    alipayApp_000016: "已设置",
    alipayApp_000017: "设置时间：",
    alipayApp_000018: "微信公众号内显示支付宝支付方式",
    alipayApp_000019: "在蚂蚁金服开放平台创建完应用，在运营平台完成应用APPID设置、秘钥设置后，可在在蚂蚁金服开放平台申请应用上线。蚂蚁金服开放平台通知应用上线成功后，您可在此处设置在微信公众号内显示支付宝支付方式为“是”，否则用户在微信公众号内无法使用支付宝支付。",
    alipayApp_000020: "设置",
    alipayApp_000021: "应用私钥请添加到此处（必填）",
    alipayApp_000022: "支付宝公钥请添加到此处（必填）",
    alipayApp_000023: "未设置",
    alipayApp_000024: "请先将“微信公众号内显示支付宝支付方式”设置为“否”",
    alipayApp_000025: "",
    alipayApp_000026: "",
    
}