export default {
    manageUsers_00001: '团体成员管理',
    manageUsers_00002: "团体名称",
    manageUsers_00003: "微信用户成员",
    manageUsers_00004: "充电卡成员",
    manageUsers_00005: "添加团体成员",
    manageUsers_00006: "导出团体成员",
    manageUsers_00007: "用户昵称/手机号",
    manageUsers_00008: "查询",
    manageUsers_00009: "查询订单",
    manageUsers_00010: "移除",
    manageUsers_00011: "头像",
    manageUsers_00012: "昵称",
    manageUsers_00013: "性别",
    manageUsers_00014: "手机号",
    manageUsers_00015: "车牌号",
    manageUsers_00016: "操作",
    manageUsers_00017: "卡号",
    manageUsers_00018: "用户姓名",
    manageUsers_00019: "操作已取消",
    manageUsers_00020: "您确定将[{nickname}]从团体中删除？",
    manageUsers_00021: "该用户还有团体分配余额{num}元,删除后系统将自动收回。",
    manageUsers_00022: "[{nickname}]从用户组中删除失败！该用户有未完成的订单，无法收回其用户组分配余额{num}元。",
    manageUsers_00023: "设置成功",
    manageUsers_00024: "此操作将下载团体用户的Excel文件, 是否继续？",
    manageUsers_00025: "提示",
    manageUsers_00026: "开始下载",
    manageUsers_00027: "取消下载",
    manageUsers_00028: "请输入昵称、手机号、卡号、多个查询条件支持以逗号（，）、换行、空格、顿号（、）分割",
    manageUsers_00029: "清除",
    manageUsers_00030: "查询结果",
    manageUsers_00031: "已添加",
    manageUsers_00032: "添加",
    manageUsers_00033: "无权查看!",
    manageUsers_00034: "请输入您要查询的昵称、手机号或卡号",
}