export default {
    whiteList_00001: '白名单列表',
    whiteList_00002: '场站管理',
    whiteList_00003: '设备管理',
    whiteList_00004: '白名单配置',
    whiteList_00005: '卡号、姓名、手机号、编号',
    whiteList_00006: '全部',
    whiteList_00007: '在线身份卡',
    whiteList_00008: '即插即充卡',
    whiteList_00009: '重 置',
    whiteList_00010: '查 找',
    whiteList_00011: '姓名',
    whiteList_00012: '手机号',
    whiteList_00013: '编号',
    whiteList_00014: '卡号',
    whiteList_00015: '卡片类型',
    whiteList_00016: '权限来源',
    whiteList_00017: '操作',
    whiteList_00018: '查看详情',
    whiteList_00019: '离线启停卡',
    whiteList_00020: '离线储值卡',
    whiteList_00021: '即插即充',
}