export default {
  workSchedule_00001: 'Time schedule name',
  workSchedule_00002: 'List',
  workSchedule_00003: 'New time schedule',
  workSchedule_00004: 'Time schedule name',
  workSchedule_00005: 'This work mode is not applied and can be deleted. Are you sure you want to delete?',
  workSchedule_00006: 'Retry',
  workSchedule_00007: '',
  workSchedule_00008: '',
  workSchedule_00009: '',
  workSchedule_00010: '',
  workSchedule_00011: '',
}