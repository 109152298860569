export default {
    orderlyCharging_00001: "Orderly charging actual operation of buses",
    orderlyCharging_00002: "Help",
    orderlyCharging_00003: "EV charging",
    orderlyCharging_00004: "EV driver",
    orderlyCharging_00005: "Limit the total charging power",
    orderlyCharging_00006: "Actual charging power",
    orderlyCharging_00007: "Vehicle code",
    orderlyCharging_00008: "Minimum SOC required for departure(%)",
    orderlyCharging_00009: "departure time",
    orderlyCharging_00010: "Return time",
    orderlyCharging_00011: "Electricity",
    orderlyCharging_00012: "1. Start charging when the charger is offline. The platform cannot control the overall load, so please operate with caution! <br/>2. The charging site tariff is set to idle fee, and the orderly charging function of buses cannot be used! <br/>3. When the EV is charging, the connector plug out, and the charging session must be restarted manually!",
}
