export default {
  energyStoragePileManagement_00001: '占用中',
  energyStoragePileManagement_00002: '设备尚未启用',
  energyStoragePileManagement_00003: '设备离线',
  energyStoragePileManagement_00005: '进入空闲',
  energyStoragePileManagement_00006: '开始充电',
  energyStoragePileManagement_00008: '预约中',
  energyStoragePileManagement_00009: '暂无故障码',
  energyStoragePileManagement_00010: "设备重启成功",
  energyStoragePileManagement_00011: "远程重启",
  energyStoragePileManagement_00012: "设备重启失败",
  energyStoragePileManagement_00013: "设备管理",
  energyStoragePileManagement_00014: "当前故障",
  energyStoragePileManagement_00015: "故障处理",
  energyStoragePileManagement_00016: "请输入设备名称或序列号",
  energyStoragePileManagement_00017: "选择或输入场站名称",
  energyStoragePileManagement_00018: "全部",
  energyStoragePileManagement_00019: "设备类型：",
  energyStoragePileManagement_00020: "直流",
  energyStoragePileManagement_00021: "交流",
  energyStoragePileManagement_00022: "网关",
  energyStoragePileManagement_00023: "故障",
  energyStoragePileManagement_00024: "离线",
  energyStoragePileManagement_00025: "空闲",
  energyStoragePileManagement_00026: "仅看已插枪",
  energyStoragePileManagement_00027: "使用中",
  energyStoragePileManagement_00028: "仅看待拔枪",
  energyStoragePileManagement_00029: "离线",
  energyStoragePileManagement_00030: "未启用",
  energyStoragePileManagement_00031: "设备序列号：",
  energyStoragePileManagement_00032: "厂商-型号：",
  energyStoragePileManagement_00033: "充电站：",
  energyStoragePileManagement_00034: "重启设备",
  energyStoragePileManagement_00035: "设备详情",
  energyStoragePileManagement_00036: "充电口：",
  energyStoragePileManagement_00037: "离线",
  energyStoragePileManagement_00038: "未启用",
  energyStoragePileManagement_00039: "故障",
  energyStoragePileManagement_00040: "占用中",
  energyStoragePileManagement_00041: "充电中",
  energyStoragePileManagement_00042: "空闲",
  energyStoragePileManagement_00043: "待挪车",
  energyStoragePileManagement_00044: "预约中",
  energyStoragePileManagement_00045: "已插枪",
  energyStoragePileManagement_00046: "待拔枪",
  energyStoragePileManagement_00047: "查看订单",
  energyStoragePileManagement_00048: "重启充电口",
  energyStoragePileManagement_00049: "设备重启指令已发送，重启过程大概需要5分钟。请停留在此页面，不要刷新页面。",
  energyStoragePileManagement_00050: "提示信息",
  energyStoragePileManagement_00051: "确 定",
  energyStoragePileManagement_00052: "设备管理",
  energyStoragePileManagement_00053: "当前故障",
  energyStoragePileManagement_00054: "插枪",
  energyStoragePileManagement_00055: "充电结束",
  energyStoragePileManagement_00056: "未启用",
  energyStoragePileManagement_00057: "正常",
  energyStoragePileManagement_00058: "故障",
  energyStoragePileManagement_00059: "锁车",
  energyStoragePileManagement_00060: "休眠",
  energyStoragePileManagement_00061: "未知",
  energyStoragePileManagement_00062: "设备状态：",
  energyStoragePileManagement_00063: "故障",
  energyStoragePileManagement_00064: "仅看未归位",
  energyStoragePileManagement_00065: "已归位",
  energyStoragePileManagement_00066: "未归位",
  energyStoragePileManagement_00067: "异常",
  energyStoragePileManagement_00068: "输入设备名称或序列名",
  energyStoragePileManagement_00069: "当前工作模式",
  energyStoragePileManagement_00070:"空闲",
  energyStoragePileManagement_00071: "已插枪",
  energyStoragePileManagement_00072: "充电中",
  energyStoragePileManagement_00073: "待拔枪",
  energyStoragePileManagement_00074: "故障",
  energyStoragePileManagement_00075: "离线",
  energyStoragePileManagement_00076: "未启用",
  energyStoragePileManagement_00077: "占用",
  energyStoragePileManagement_00078: "储能补电中",
  energyStoragePileManagement_00079: "储能放电中",
  energyStoragePileManagement_00080: "储能空闲中",
  energyStoragePileManagement_00081: "分钟充满",
  energyStoragePileManagement_00082: "模式",
  
}