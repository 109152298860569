export default {
  weChatApp_00001: "Abonnementskonten", 
    weChatApp_00002: "Wechat-Pay", 
    weChatApp_00003: "Kontosinformation", 
    weChatApp_00004: "Kontosname", 
    weChatApp_00005: "Kontosprofibild", 
    weChatApp_00006: "Anzeig",  
    weChatApp_00007: "Zeig Station von drittem Anbieter",
    weChatApp_00008: "Meine Site mit anderen Plattformen teilen",
    weChatApp_00009: "Untere Einstellungslist anpassen", 
    weChatApp_000010: "Geben Sie die offizielle WeChat-Kontoplattform ein, fügen Sie ein Menü zum 'Benutzerdefinierten Menü' hinzu, wählen Sie 'Gehe zur Seite' für den Menüinhalt und kopieren Sie die URL-Adresse unten in die Url-Adresse",
    weChatApp_000011: "Finden Sie die Adresse der Ladestation",
    weChatApp_000012: "Adress der persönlichen Zentrum",
    weChatApp_000013: "Gehe zur WeChat-Plattform",
    weChatApp_000014: "Anleitung für den Zugriff auf die WeChat-Pay",
    weChatApp_000015: "Folgen Sie den Anweisungen für den Zugriff auf die WeChat-Pay und schließen Sie die entsprechenden Vorgänge ab, um den WeChat-Pay zu nutzen",
    weChatApp_000016: "WeChat Pay-Händlerkonto-Zahlungsautorisierungsverzeichniseinstellungen",
    weChatApp_000017: "Gehe zur WeChat-Händlerplattform",
    weChatApp_000018: "Geben Sie die WeChat Payment Merchant Platform in die 'Entwicklungskonfiguration' des 'Product Centers' ein,kopieren Sie die unten stehende Adresse und fügen Sie sie dem 'Payment Authorization Directory' der 'WechatKontozahlung' hinzu.Hinweis: Wählen Sie „https://“ in der Dropdown-Liste aus.",
    weChatApp_000019: "Autorisierte Verzeichnisadresse",
    weChatApp_000020: "Link kopieren",
    weChatApp_000021: "Einstellungen des Händlerkontos für die Zahlung der WeChat-Händlerplattform",
    weChatApp_000022: "Geben Sie die WeChat-Händlerplattform ein und fügen Sie in den 'Händlerinformationen' des 'Kontocenters' die 'WeChat Pay Merchant ID' der 'Basiskontoinformationen' hier hinzu.",
    weChatApp_000023: "WeChat-Pay Händlerkonto",
    weChatApp_000024: "WeChat Merchant Platform API Key Setting",
    weChatApp_000025: "Geben Sie die WeChat-Händlerplattform ein und kopieren Sie den PIN in die 'API-Sicherheit' des 'Kontoscenter' in die 'PIN-einstellungen' des 'API-PINs'",
    weChatApp_000026: "PIN ändern",
    weChatApp_000027: "Erzeuge eine neue PIN",
    weChatApp_000028: "Nach dem Generieren eine neue PIN müssen Sie die WeChat-Händlerplattform eingeben,in der 'API-Sicherheit' des 'Kontoscenter' die generierte PIN unten in die 'PIN-Einstellungen' des 'API-PINS' kopieren. , und kehren Sie dann zur Plattform zurück, um die neue PIN zu speichern, um eine normale Zahlung sicherzustellen.",
    weChatApp_000029: "Zertifikat hochladen",
    weChatApp_000030: "Die automatische Rückerstattungsfunktion kann nach dem Hochladen des Zertifikats eingeschaltet werden", 
    weChatApp_000031: "Bitte melden Sie sich bei der WeChat-Pay Händlerplattform an, klicken Sie auf [Kontoscenter] -> [Kontoeinstellungen] -> [API-Sicherheit]. Wenn Sie die Schaltfläche [Zertifikat herunterladen] sehen, laden Sie die Zertifikatsdatei direkt herunter und legen Sie sie auf diese Seite Laden Sie das Zertifikat hoch; <br/>Wenn Sie die Schaltfläche [Zertifikat beantragen] sehen, lesen Sie bitte <a href='http://kf.qq.com/faq/161222NneAJf161222U7fARv.html' target=_blank>The WeChat-Dokument</br/> a>Nachdem Sie das Zertifikat beantragt haben, laden Sie die Zertifikatsdatei im .p12-Format auf dieser Seite hoch.",
    weChatApp_000032: "Zertifikat wurde hochgeladen", 
    weChatApp_000033: "Klicken zum Hochladen", 
    weChatApp_000034: "Bitte bestätigen Sie, dass das Zertifikat die neueste Version ist (befolgen Sie bitte die Benachrichtigung der WeChat-Händlerplattform)",
    weChatApp_000035: "XCharge verwendet dieses Zertifikat nur für Rückerstattungsvorgänge", 
    weChatApp_000036: "Anmerkung: Das Zahlungszertifikat ist eine vertrauliche Information, bitte verwenden Sie diese Funktion mit Vorsicht.",
    weChatApp_000037: "Nachdem die Einstellung des Händlerkontos für die WeChat-Händlerplattform abgeschlossen ist, können Sie hier einstellen, dass die Zahlungsmethode WeChat im WeChat-Konto als 'Ja' angezeigt wird, andernfalls können Benutzer WeChat Pay nicht im WeChat-Konto verwenden.", 
    weChatApp_000038: "Das WeChat-Konto unterstützt die Zahlungsmethoden von WeChat",

    weChatApp_000039: "Bitte letzten Vorgang speichern", 
    weChatApp_000040: "Kopier-'Ladestation' Link", 
    weChatApp_000041: "Kopier-'Ladevorgängen' Link", 
    weChatApp_000042: "Erfolgreich geändert, bitte aktualisieren Sie die Adresse der Ladestation finden im unteren Menü des WeChat-Kontos",
    weChatApp_000043: "Erfolgreich geändert", 
    weChatApp_000044: "Kopier-'Zahlungsautorisierungsverzeichnis' Link", 
    weChatApp_000045: "Kopier-PIN", 
    weChatApp_000046: "Kopiert - PIN", 
    weChatApp_000047: "Bestätigen, um die PIN zu speichern",
    weChatApp_000048: "Anmerkung",
    weChatApp_000049: "Wenn Sie PIN auf der WeChat-Händlerplattform nicht geändert haben, speichern Sie die Änderung einfach direkt, was dazu führt, dass der Ladeauftrag nicht bezahlt werden kann. Klicken Sie auf 'Abbrechen', um das Speichern abzubrechen. Wenn Sie die Änderung bestätigen, klicken Sie nach Ablauf des 30-Sekunden-Countdowns auf „Speichern",
    weChatApp_000050:"Zum Hochladen anklicken",  
    weChatApp_000051:"Hochladen", 
    weChatApp_000052:"Muss eine Datei im ZIP- oder P12-Format sein", 
    weChatApp_000053:"Erfolgreich hochgeladen", 
    weChatApp_000054:"Möchten Sie das Zertifikat wirklich löschen?",
    weChatApp_000055:"Erinnerung", 
    weChatApp_000056:"Keine Berechtigung", 
    weChatApp_000057:"Hochladen fehlgeschlagen", 

}