export default {
  userClassList_00001: "Klassifikationsmanagement",
  userClassList_00002: "Klassifikationsliste",
  userClassList_00003: "Nutzerklassifikation",
  userClassList_00004: "Klassifikationsname",
  userClassList_00005: "aktuelle Anzahl der Nutzer",
  userClassList_00006: "Anzahl der Aktivitäten",
  userClassList_00007: "Marketing Aktivität",
  userClassList_00008: "Klassifikationseinzelheit",
  userClassList_00009: "Aktion",
}