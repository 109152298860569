export default {
    analyzeIncome_00001: "Analyse des Gesamttrends der Einkommen",
    analyzeIncome_00002: "Analyse der Ladestationseinkommen",
    analyzeIncome_00003: "Analyse der Gruppeeinkommen",
    analyzeIncome_00004: "Analyse der Einkommen nach Nutzerquellen",
    analyzeIncome_00005: "Analyse der Franchisenehmer-Einkommen",
    analyzeIncome_00006: "Einkommentrend",
    analyzeIncome_00007: "Analyse",
    analyzeIncome_00008: "Einkommen({0})",
    analyzeIncome_00009: "Gerätsmodell Verteilung",
    analyzeIncome_00010: "Städte Verteilung",
    analyzeIncome_00011: "Vergleich der Ladestationseinkommen",
    analyzeIncome_00012: "Trend der Ladestationseinkommen",
    analyzeIncome_00013: "Vergleich der Gruppeeinkommen",
    analyzeIncome_00014: "Trend der Gruppeeinkommen",
    analyzeIncome_00015: "Franchisenehmer Einkommensvergleich",
    analyzeIncome_00016: "Franchisenehmer Einkommensvergleich",
    analyzeIncome_00017: "Gerätsmodelle auswählen",
    analyzeIncome_00018: "Vergleich der Ladestationsnutzungsrate",
    analyzeIncome_00019: "Analyse der Ladestationsnutzungsrate",
    analyzeIncome_00020: "Vergleich der Nutzungsrate von Ausrüstungsmodellen",
    analyzeIncome_00021: "Trends in der Modellverwendungsrate",
    analyzeIncome_00022: "Ladenutzungsrate",
    analyzeIncome_00023: "Vergleich der Nutzungsrate durch Franchisenehmer",
    analyzeIncome_00024: "Trends bei der Nutzungsrate durch Franchisenehmer",
    analyzeIncome_00025: "Stadt",
    analyzeIncome_00026: "Produkttyp",
    analyzeIncome_00027: "Servicebetrag({0})",
    analyzeIncome_00028: "Elektrizitätbetrag({0})",
    analyzeIncome_00029: "Ladestecke im Betrieb",
    analyzeIncome_00030: "Betragsanalyse der Ladevorgänge", 
    analyzeIncome_00034: "Anteil des Einkommen nach Nutzerquellen",
    analyzeIncome_00035: "Anteil des Verkehrs von Dritten nach Einkommen",
    analyzeIncome_00036: "Trends des Einkommen nach Nutzerquellen",
    analyzeIncome_00037: "Trends des Verkehrs von Dritten nach Einkommen",
    analyzeIncome_00038: "Währungseinheit",
    analyzeIncome_00039: 'Revenue per connector trend',
    analyzeIncome_00040: 'Idle fee',
    analyzeIncome_00041: 'Electricity fee per connector',
    analyzeIncome_00042: 'Service fee per connector',
    analyzeIncome_00043: 'Idle fee per connector',
}