export default {
    errorLog_00001: 'Schaltstation',
    errorLog_00002: 'Modell der ausrüstung',
    errorLog_00003: 'Zeit zu starten',
    errorLog_00004: 'Die zeit ist um',
    errorLog_00005: '至',
    errorLog_00006: 'Bitte geben sie die seriennummer des laufwerks ein',
    errorLog_00007: 'Angabe der uhrzeit',
    errorLog_00008: 'Nach dem logbuch',
    errorLog_00009: 'Das ganze glas',
    errorLog_00010: 'Fehlfunktion',
    errorLog_00011: 'Anormal',
    errorLog_00012: 'Offline',
    errorLog_00013: 'Bitte geben sie einen fehlercode ein',
    errorLog_00014: 'Fehler im system',
    errorLog_00015: 'Fehler im system',
    errorLog_00016: 'Ergänzen sie die beschreibung',
    errorLog_00017: 'Schaltstation',
    errorLog_00018: 'Mit einer telefonleitung',
    errorLog_00019: 'Zeit der erneuerung',
    errorLog_00020: 'Wieder aufgeladen',
    errorLog_00021: 'Nicht erholt',
    errorLog_00022: 'Whether to restore',
    errorLog_00023: 'Not restored',
    errorLog_00024: 'Restored',
    errorLog_00025: 'Severe Error - Charger unable to use, Abnormal - Charging end abnormally, and the charger can continue to use',
}
