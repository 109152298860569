export default {
  securityLimited_00001: "该限制仅在直流桩运营时，为了防止以下情况：",
  securityLimited_00002: "1、电单车充电占桩，或使用不安全转接设备充电而造成安全隐患",
  securityLimited_00003: "2、电动汽车用低功率充电，造成长时间占用",
  securityLimited_00004: "符合条件历史订单",
  securityLimited_00005: "是否启用安全充电限制",
  securityLimited_00006: "当充电超过",
  securityLimited_00007: "分钟",
  securityLimited_00008: "充电功率低于",
  securityLimited_00009: "kW时",
  securityLimited_00010: "直接结束充电订单",
  securityLimited_00011: "向超级管理员和场站管理员预警 ",
  securityLimited_00012: "设置:",
  securityLimited_00013: "自动通加",
  securityLimited_00014: "拉黑该用户(微信用户永久拉黑，卡用户挂失该充电卡)",
  securityLimited_00015: "保存设置",
  securityLimited_00016: "多选框必须至少选一项",
  securityLimited_00017: "请填写分钟",
  securityLimited_00018: "请填写限制功率",
  securityLimited_00019: "查询",
  securityLimited_00020: "输入站点名、运营商名",
  securityLimited_00021: "站点名称",
  securityLimited_00022: "运营商",
  securityLimited_00023: "站点状态",
  securityLimited_00024: "已启用",
  securityLimited_00025: "未启用",
  securityLimited_00026: "对外开放",
  securityLimited_00027: "开放",
  securityLimited_00028: "不开放",
  securityLimited_00029: "请选择站点",
  securityLimited_00030: "充电桩",
  securityLimited_00031: "交",
  securityLimited_00032: "直",
  all_site:"全部站点",
  self_run_site:"自营站点",
  custom_site:"自定义站点",
  coupon_00028: '站点名称',
  coupon_00029: '查询',
  coupon_00030: '批量移除',
  add_site:"添加站点",
  site_name: "站点名称",
  operator: "运营商",
  coupon_00031: '操作',
  coupon_00032: '移除',

};