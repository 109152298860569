export default {
  resetPassword_00001: "信息验证",
  resetPassword_00002: "修改密码",
  resetPassword_00003: "完成修改",
  resetPassword_00004: "账号",
  resetPassword_00005: "请输入需要重置密码的账号",
  resetPassword_00006: "手机号码",
  resetPassword_00007: "11位手机号",
  resetPassword_00008: "请输入此账号绑定的手机号",
  resetPassword_00009: "发送验证码",
  resetPassword_00010: "{0}s后重新获取",
  resetPassword_00011: "短信验证码",
  resetPassword_00012: "6位数字",
  resetPassword_00013: "请输入短信验证码",
  resetPassword_00014: "下一步",
  resetPassword_00015: "新密码",
  resetPassword_00016: "最少6位，且至少包含1个数字及1个字母以及1个特殊字符",
  resetPassword_00017: "请输入新密码",
  resetPassword_00018: "确认密码",
  resetPassword_00019: "与新密码一致",
  resetPassword_00020: "请再次输入新密码",
  resetPassword_00021: "保存",
  resetPassword_00022: "修改成功",
  resetPassword_00023: "登录运营平台",
  resetPassword_00024: "请输入验证码",
  resetPassword_00025: "请输入数字值",
  resetPassword_00026: "请输入6位数字",
  resetPassword_00027: "请再次输入密码",
  resetPassword_00028: "两次输入的新密码不一致",
  resetPassword_00029: "请输入手机号",
  resetPassword_00030: "请输入正确的11位手机号",
  resetPassword_00031: "请输入账号",
  resetPassword_00032: "请输入密码",
  resetPassword_00033: "",
  resetPassword_00034: "输入的手机号非账号绑定的手机号！如有需要请联系管理员！",
  resetPassword_00035: "验证码有误",
  resetPassword_00036: "验证码无效",
  resetPassword_00037: "验证码已失效，请重新获取验证码",
  resetPassword_00038: "重置密码",
  resetPassword_00039: "请按规则输入新密码",
  resetPassword_00040: "邮箱",
  resetPassword_00041: "请输入此账号绑定的邮箱",
  resetPassword_00042: "请输入正确的邮箱",
  resetPassword_00043: "输入的邮箱非账号绑定的邮箱！如有需要请联系管理员！",
  resetPassword_00044: "邮箱验证码",
  resetPassword_00045: "请输入邮箱验证码",


}