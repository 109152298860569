export default {
    groupDetail_00001: "Individuellzahlungsgruppendetails",
    groupDetail_00002: "Gruppenzahlungsgruppendetails",
    groupDetail_00003: "Zuteilungsbetrag",
    groupDetail_00004: "Rabattladestationseinstellungen",
    groupDetail_00005: "Mitgliederverwaltung",
    groupDetail_00006: "Nur Auto erkennen",
    groupDetail_00007: "Betriebseinstellung",
    groupDetail_00008: "Gruppenmanagement",
    groupDetail_00009: "Mitglieder exportieren",
    groupDetail_00010: "Gruppe löschen",
    groupDetail_00011: "Nach dem Löschen einer Gruppe nicht wiederherstellbar",
    groupDetail_00012: "Bestellung suchen",
    groupDetail_00013: "Dieser Vorgang lädt die Excel-Datei des Gruppenbenutzers herunter. Möchten Sie fortfahren?",
    groupDetail_00014: "Möchten Sie die Gruppe {name} wirklich löschen?",
    groupDetail_00015: "Vorgang abgebrochen",
    groupDetail_00016: "Grundlegende Einstellungen",
    groupDetail_00017: "Gruppenname",
    groupDetail_00018: "Zahlungsmethode",
    groupDetail_00019: "Berechtigungseinstellung",
    groupDetail_00020: "Maximaler unbezahlter Betrag ({unit})",
    groupDetail_00021: "Maximale Anzahl unbezahlter Bestellungen",
    groupDetail_00022: "Akzeptieren Sie die Öffnungsfrist des Ladepunktes",
    groupDetail_00023: "Persönliche Zahlung",
    groupDetail_00024: "Gruppenzahlung",
    groupDetail_00025: "{num}",
    groupDetail_00026: "Ob die Verwendung von Coupons erlaubt wird",
    groupDetail_00027: "Ob selbstbedingte Gruppeauflade unterstützen",
    groupDetail_00028: "Kontoinformationen zuweisen",
    groupDetail_00029: "Guthaben",
    groupDetail_00030: "Aufladerekord",
    groupDetail_00031: "Kontoaufladen",
    groupDetail_00032: "Geschenk enthalten{promotionBalance}{unit}",
    groupDetail_00033: "Bitte speichern Sie den vorherigen Vorgang!",
    groupDetail_00034: "Ladestationsname",
    groupDetail_00035: "Ladestationsliste",
    groupDetail_00036: "Rabattladestation hinzufügen",
    groupDetail_00037: "Massenentfernen",
    groupDetail_00038: "Aktuelle Tarifvorlage",
    groupDetail_00039: "Aktuelle Angebote",
    groupDetail_00040: "Aktion",
    groupDetail_00041: "DC",
    groupDetail_00042: "AC",
    groupDetail_00043: "Kein Rabatt für gesamte Gebühr jedes einzelnen Ladens",
    groupDetail_00044: "Kein Rabatt für die Servicegebühr jedes einzelnen Ladens",
    groupDetail_00045: "{des}% Rabatt für Servicegebühr jedes einzelnen Ladens",
    groupDetail_00046: "{des}% Rabatt für gesamte Gebühr jedes einzelnen Ladens",
    groupDetail_00047: "Gesamtgebührenfrei jedes einzelnen Ladens",
    groupDetail_00048: "Servicegebührfrei jedes einzelnen Ladens",
    groupDetail_00049: "Tarifrabatteinstellung",
    groupDetail_00050: "Ladestation-Details",
    groupDetail_00051: "Entfernen",
    groupDetail_00052: "Bestätigen Sie die Ladestation aus der Gruppe zu löschen?",
    groupDetail_00053: "Löschen abgebochen",
    groupDetail_00054: "Tarifsatz",
    groupDetail_00055: "Wenn der Tarifsatz geändert wird, klicken Sie in den Ladestationdetails-Tarifsatz auf die Taste 'Tarif pushen' und der Tarif wird wirksam!",
    groupDetail_00056: "AC-Tarif ",
    groupDetail_00057: "DC-Tarif ",
    groupDetail_00058: "Bitte AC-Tarif auswählen",
    groupDetail_00059: "Bitte DC-Tarif auswählen",
    groupDetail_00060: "Kein Rabatt für gesamte Gebühr jedes einzelnen Aufladens",
    groupDetail_00061: "Rabatt für Servicegebühr jedes einzelnen Ladens",
    groupDetail_00062: "85 steht für einen Rabatt von 15%, 0 entspricht der Gebührfrei.",
    groupDetail_00063: "Beispiel: Wenn Sie die einmalige Servicegebühr so einstellen, dass der 15% Rabatt von 20EUR Servicegebühr eingehalten wird, kostet die Gebühr dieses Mal 100EUR (einschließlich 70EUR Stromgebühr und 30EUR Servicegebühr), dann beträgt der tatsächlich zu zahlende Betrag dieses Mal 95,5 EUR (70+30*85%=95,5)",
    groupDetail_00064: "Rabatt für gesamte Gebühr jedes einzelnen Ladens",
    groupDetail_00065: "Beispiel: Wenn Sie die Gesamtkosten für eine einzelne Gebühr so festlegen, dass sie den Gesamtkosten von 60EUR und 15 % Rabatt entsprechen, und die Gesamtkosten für diese Gebühr 100EUR betragen, beträgt der tatsächlich zu zahlende Betrag diesmal 85EUR (100*85%=85).",
    groupDetail_00066: "Einstellungen speichern",
    groupDetail_00067: "Ausfüllen und sofort pushen",
    groupDetail_00068: "Ob Betrag den Gruppenmitgliedern zuweisen",
    groupDetail_00069: "Gruppeninformationen",
    groupDetail_00070: "Gruppenname",
    groupDetail_00071: "aktueller Guthaben:{balance}{unit}",
    groupDetail_00072: "Geschenk enthalten{promotionBalance}{unit}",
    groupDetail_00073: "Füllen Sie den Aufladebetrag aus",
    groupDetail_00074: "Geschenk",
    groupDetail_00075: "Passwort",
    groupDetail_00076: "Bitte geben Sie das Passwort ein",
    groupDetail_00077: "Ob Nur Autoerkennen öffenen?",
    groupDetail_00078: "Spitzname, Handynummer",
    groupDetail_00079: "Hinzufügen",
    groupDetail_00080: "Spitzname (Handynummer)",
    groupDetail_00081: "Account Einstellungen",
    groupDetail_00082: "Als Administrator festlegen",
    groupDetail_00083: "Administrator abbrechen",
    groupDetail_00084: "Entfernen",
    groupDetail_00085: "Nutzerdetails",
    groupDetail_00086: "Bestätigen Sie [{nickname}] wirklich aus der Gruppe zu löschen?",
    groupDetail_00087: "Der Benutzer hat auch ein Gruppenzuweisungsguthaben von {num} EUR, das nach dem Löschen automatisch vom System wiederhergestellt wird.",
    groupDetail_00088: "WeChat-Benutzermitglied",
    groupDetail_00089: "Ladekartemitglieder",
    groupDetail_00090: "Kartenummer",
    groupDetail_00091: "Karteliste",
    groupDetail_00092: "Nutzername",
    groupDetail_00093: "Bitte geben Sie Spitzname, Handynummer, Kartenummer, und mehrere Bedingungen werden mit Komma, Zeilenumbrüche, Leerzeichen, Punkte aufgeteilt", 
    groupDetail_00094: "Ergebnisse suchen",
    groupDetail_00095: "löschen",
    groupDetail_00096: "hinzugefügt",
    groupDetail_00097: "hinzufügen",
    groupDetail_00098: "eingestellt",
    groupDetail_00099: "als Adminitrator eingestellt",
    groupDetail_00100: "Nutzerliste",
    groupDetail_00101: "Kartedetails",
    groupDetail_00102: "1、Wenn die Einstellung 'Coupons zulassen' auf 'Ja' steht, kann der Coupon verwendet werden, wenn der Gruppenbenutzer die Bestellung bezahlt.", 
    groupDetail_00103: "Bei der Bezahlung der Bestellung wird vorrangig der Gruppenrabatt für die Ladestationsbindung genutzt, und der Rechnungsbetrag nach Nutzung des Gruppenrabatts für die Ladestationsbindung wird dann nach den Kriterien für die Erfüllung des Gutscheins ausgewählt.",
    groupDetail_00104: "Beispiel: <br/>Gruppennutzer, die 100{unit} (einschließlich 70{unit} für Strom und 30{unit} für Service) berechnen, haben Anspruch auf eine Ermäßigung auf den Gruppentarif für gebundene Standorte (8 % auf den Einzeltarif für Service) und haben einen Coupon (10 % auf den Einzeltarif für Service für 20{unit}),<br/> dann verwenden Sie den Gruppentarif für gebundene Standorte. Nach der Ermäßigung beträgt der Rechnungsbetrag 95,5{unit} (einschließlich 70{unit} für Strom und 25,5{unit} für die Servicegebühr (30*15%)) und der Gutschein (10% Ermäßigung für 20{unit} Servicegebühr für eine einzige Gebühr) ist erfüllt. Nach der Verwendung des Gutscheins wird die Rechnung 92,95{unit} betragen (einschließlich 70{unit} für Strom + 22,95{unit} für die Servicegebühr (25,5*90%)), was zu einer tatsächlichen Zahlung von 92,95{unit} führt",
    groupDetail_00105: "2、Wenn die Option 'Gutscheine zulassen' auf 'Nein' gesetzt ist, können Gruppenbenutzer keine Gutscheine beim Bezahlen von Bestellungen verwenden.",
    groupDetail_00106: "Wenn die Fahrzeugerkennung aktiviert ist, wird beim ersten Aufladen an einer gruppengebundenen Rabattstation die Fahrgestellnummer des Fahrzeugs des Gruppenmitglieds gespeichert, und ein Gruppenmitglied kann nur einem Fahrzeug entsprechen. Beim anschließenden Aufladen an den vergünstigten Ladestation der Gruppe können nur die Fahrzeuge mit der VIN des Gruppenmitglieds den vergünstigten Tarif an der Ladestation der Gruppe in Anspruch nehmen.",
    groupDetail_00107: "• Es wird empfohlen, dass die Gruppenmitglieder, sobald diese Einstellung aktiviert ist, ihre VINs so schnell wie möglich einmal am Ladestation des Gruppenrabatts laden",
    groupDetail_00108: "• Wenn diese Einstellung aktiviert ist, können die Gruppenmitglieder den AC-Pfahl nicht zum Aufladen verwenden.",
    groupDetail_00109: "Unzulässige Parameter",
    groupDetail_00110: "Bitte geben Sie den Spitznamen, die Handynummer oder die Kartennummer ein, nach der Sie suchen möchten",
    groupDetail_00111: "Aktion abgebrochen",
    groupDetail_00112: "Erfolgreich Entfernen",
    groupDetail_00113: "ob Online-Rechnung untersützen",
    groupDetail_00114: "Gruppekonto",
    groupDetail_00115: "Gruppekontoinformation",
    groupDetail_00116: "Mindestguthabengrenze ({unit})",
    groupDetail_00117: "Wenn der Kontostand unter dem Mindestsaldo im Zahlungsmodus des Finanzierungskontos liegt, können die Benutzer in der Gruppe nicht mit der Aufladung beginnen (für kein Limit auf 0{unit} gesetzt)",
    groupDetail_00118: "Erinnerung an den Guthaben({unit})",
    groupDetail_00119: "Der Gruppenadministrator erhält eine Erinnerungs-SMS, wenn der Kontostand unter den Erinnerungssaldo fällt, wenn sich das Finanzierungskonto im Zahlungsmodus befindet. (Eine Einstellung von 0{unit} bedeutet keinen Alarm)",
    groupDetail_00120: "Es können nur 0 oder positive Ganzzahlen eingegeben werden",
    groupDetail_00121: "Währung verwenden",
    // 20220124--添加优惠站点弹窗
    groupDetail_10000: "Server hinzufügen",
    groupDetail_10001: "Geben sie den namen einer webseite Oder eine einladung ein",
    groupDetail_10002: "Einen massenproduktion",
    groupDetail_10003: "Name der webseite",
    groupDetail_10004: "Mit einer telefonleitung",
    groupDetail_10005: "Ladet den pfahl",
    groupDetail_10006: "Status der webseite",
    groupDetail_10007: "betrieb",
    groupDetail_10008: "Hinzufügen",
    groupDetail_10009: "General hinzugefügt",
    groupDetail_10010: "Wurde aktiviert",
    groupDetail_10011: "Nicht aktiviert",
    groupDetail_10012: "ac",
    groupDetail_10013: "dc",
    groupDetail_10014: "ch meine als nächstes",
    groupDetail_10015: "Bitte wählen sie die gebühren aus",
}