export default {
  teamDetail_00001: '队伍详情',
  teamDetail_00002: '组队打榜',
  teamDetail_00003: '参与队伍',
  teamDetail_00004: '队伍详情',
  teamDetail_00005: '',
  teamDetail_00006: '',
  teamDetail_00007: '请输入用户昵称或手机号',
  teamDetail_00008: '',
  teamDetail_00009: '',
}