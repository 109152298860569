exports.googleApi = "https://maps.googleapis.com/maps/api/js?v=3&key=AIzaSyAyfVnxQL9gRDT1v-Ii99ANum4J6rr2sXE&libraries=geometry,places&sensor=false&language=en";
// exports.gaodeApi = "https://webapi.amap.com/maps?v=1.3&key=488532a6c58d60d65095d51e86d1c6bd";
exports.gaodeApi = "https://map.qq.com/api/gljs?v=2.exp&libraries=service,geometry&key=KNGBZ-NWDCH-IKJDF-WGVX2-JGSMO-WZFYC";
exports.product_area = "cn";
exports.requestUrl = "http://xcloud.dev.xcharger.net/service/api";
// exports.requestUrl = "http://xcloud-eu.dev.xcharger.net/service/api";
// exports.requestUrl = "http://xcloud2.dev.xcharger.net/service/api";
// exports.requestUrl = "http://service.xcharger.net/api";
exports.bindWxUrl = "http://xcloud.dev.xcharger.net/xchargewechat/wechat/auth/v2.0?redirectUrl=http://xcloud.dev.xcharger.net/service/api/wechatoa/bind";
exports.activateUrl = "http://xcloud.dev.xcharger.net/xchargewechat/activate/toCheck?oasourceid=gh_5a18ccb661bc";
exports.operator = "智充";
exports.record = "京ICP备15038975号-1";
