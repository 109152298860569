<!--富文本编辑器-->
<template>
  <div class="RichTextEditor-Wrap" v-loading="loading">

    <quill-editor :disabled='isReadOnly' :content="content" :options="editorOption" class="ql-editor" @click.native="editorClickEvent" ref="myQuillEditor" @change="onEditorChange($event)">
    </quill-editor>

    <!-- 图片上传组件辅助-->
    <el-upload :headers="headers" :with-credentials='true' v-show="false" :show-file-list="false" :name="uploadImgConfig.name" :multiple="false" :action="uploadImgConfig.uploadUrl" :before-upload="onBeforeUpload" :on-success="onSuccess" :on-error="onError" :file-list="fileList">
      <!--<i class="el-icon-upload"></i>-->
      <!--<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>-->
      <!--<div class="el-upload__tip" slot="tip">最多只能上传两个附件</div>-->
      <button ref="myinput">{{$t('common_71006')}}</button>
    </el-upload>

    <!--视频上传-->
    <div>
      <el-dialog :close-on-click-modal="false" width="50%" style="margin-top: 1px" :title="$t('common_71007')" :visible.sync="videoDialog.show" append-to-body>
        <el-tabs v-model="videoDialog.activeName">
          <el-tab-pane :label="$t('common_71008')" name="first">
            <el-input v-model="videoDialog.videoLink" :placeholder="$t('common_71009')" clearable></el-input>
            <el-button type="primary" size="small" style="margin: 20px 0px 0px 0px " @click="addVideoLink(videoDialog.videoLink)">{{$t('common_00212')}}
            </el-button>
          </el-tab-pane>
          <el-tab-pane :label="$t('common_71013')" name="second">
            <el-upload :headers="headers" :show-file-list="false" :with-credentials='true' v-loading="loading" style="text-align: center;" drag :action="uploadVideoConfig.uploadUrl" accept="video/*" :name="uploadVideoConfig.name" :before-upload="onBeforeUploadVideo" :on-success="onSuccessVideo" :on-error="onErrorVideo" :multiple="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">{{$t('common_71010')}}<em>{{$t('common_71011')}}</em></div>
              <div class="el-upload__tip" slot="tip">{{$t('common_71012',{maxSize:uploadVideoConfig.maxSize})}}</div>
            </el-upload>

          </el-tab-pane>
        </el-tabs>
      </el-dialog>
    </div>

    <!-- 图片预览 -->
    <el-dialog custom-class="editor_dialog" :visible.sync="dialogVisible" :show-close="false" :lock-scroll="false" :modal-append-to-body="false">
      <img class="preview_img" :src="currentSrc" alt="">
    </el-dialog>
  </div>
</template>
<script>
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { Quill, quillEditor } from "vue-quill-editor";
//视频标签插入（样式保持宽度100%）
import Video from "../assets/utils/video";
Quill.register(Video, true);
// 设置title
const titleConfig = {
  "ql-bold": "加粗",
  "ql-font": "字体",
  "ql-code": "插入代码",
  "ql-link": "添加链接",
  "ql-color": "字体颜色",
  "ql-background": "背景颜色",
  "ql-size": "字体大小",
  "ql-strike": "删除线",
  "ql-script": "上标/下标",
  "ql-underline": "下划线",
  "ql-blockquote": "引用",
  "ql-header": "标题",
  "ql-indent": "缩进",
  "ql-list": "列表",
  "ql-align": "文本对齐",
  "ql-direction": "文本方向",
  "ql-code-block": "代码块",
  "ql-formula": "公式",
  "ql-image": "图片",
  "ql-video": "视频",
  "ql-clean": "清除字体样式",
};

// 工具栏
const toolbarOptions = [
  // toggled buttons
  "bold",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  { list: "ordered" },
  { list: "bullet" },
  { script: "sub" },
  { script: "super" }, // superscript/subscript
  { indent: "-1" },
  { indent: "+1" }, // outdent/indent
  { direction: "rtl" },
  { size: ["small", false, "large", "huge"] },
  { header: [1, 2, 3, 4, 5, 6, false] },
  { font: [] },
  { color: [] },
  { background: [] }, // dropdown with defaults from theme
  { align: [] },
  "clean", // remove formatting button
  "link",
  "image",
  "video",
  // ["image", "video"],
];
import { requestUrl, ajax } from "@/assets/utils/network";
export default {
  name: "RichTextEditor",
  model: {
    prop: "content",
    event: "change",
  },
  components: {
    quillEditor,
  },
  props: {
    isShowVideo: {
      // 是否支持上传视频
      type: String,
      default: "",
    },
    isShowImg: {
      // 是否支持上传图片
      type: String,
      default: "",
    },
    isReadOnly: {
      // 是否只读
      type: Boolean,
      default: false,
    },
    content: {
      // 返回的html片段
      type: String,
      default: "",
    },
    uploadImgConfig: {
      // 图片上传配置 - 若不配置则使用quillEditor默认方式，即base64方式
      type: Object,
      default() {
        return {
          uploadUrl: `${requestUrl}/file`, // 图片上传地址
          maxSize: 2, // 图片上传大小限制，默认不超过2M
          name: "file", // 图片上传字段
        };
      },
    },
    uploadVideoConfig: {
      // 视频上传配置
      type: Object,
      default() {
        return {
          uploadUrl: `${requestUrl}/file`, // 上传地址
          maxSize: 10, // 图片上传大小限制，默认不超过2M
          name: "file", // 图片上传字段
        };
      },
    },
  },
  watch: {
    isShowVideo: {
      handler(val) {
        if (val && val == "false") {
          let index = toolbarOptions.indexOf("video");
          toolbarOptions.splice(index, 1);
          this.editorOption.modules.toolbar.container = toolbarOptions;
        }
      },
      immediate: true,
    },
    isShowImg: {
      handler(val) {
        if (val && val == "false") {
          let index = toolbarOptions.indexOf("image");
          toolbarOptions.splice(index, 1);
          this.editorOption.modules.toolbar.container = toolbarOptions;
        }
      },
      immediate: true,
    },
  },
  data() {
    let _self = this;
    return {
      headers:{
        "co-id":"xcharge"
      },
      currentSrc: "", // 预览图地址
      dialogVisible: false,
      loading: false, // 加载loading
      editorOption: {
        placeholder: "",
        theme: "snow", // or 'bubble'
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              video: function (value) {
                _self.videoDialog.show = true;
              },
            },
          },
        },
      },

      // 图片上传变量
      fileList: [],

      // 视频上传变量
      videoDialog: {
        show: false,
        videoLink: "",
        activeName: "first",
      },
    };
  },
  mounted() {
    // 初始给编辑器设置title
    this.addQuillTitle();

    let toolbar = this.$refs["myQuillEditor"].quill.getModule("toolbar");
    // 是否开启图片上传到服务器功能
    if (this.uploadImgConfig.uploadUrl) {
      toolbar.addHandler("image", this.addImageHandler);
    }
  },
  methods: {
    editorClickEvent(e) {
      if (!this.isReadOnly) return;
      if (e.target && e.target.nodeName == "IMG") {
        this.currentSrc = e.target.currentSrc;
        this.dialogVisible = true;
      }
    },
    // 设置title
    addQuillTitle() {
      const oToolBar = document.querySelector(".ql-toolbar");
      const aButton = oToolBar.querySelectorAll("button");
      const aSelect = oToolBar.querySelectorAll("select");
      aButton.forEach(function (item) {
        if (item.className === "ql-script") {
          item.value === "sub" ? (item.title = "下标") : (item.title = "上标");
        } else if (item.className === "ql-indent") {
          item.value === "+1"
            ? (item.title = "向右缩进")
            : (item.title = "向左缩进");
        } else {
          item.title = titleConfig[item.className];
        }
      });
      // 字体颜色和字体背景特殊处理，两个在相同的盒子
      aSelect.forEach(function (item) {
        if (item.className.indexOf("ql-background") > -1) {
          item.previousSibling.title = titleConfig["ql-background"];
        } else if (item.className.indexOf("ql-color") > -1) {
          item.previousSibling.title = titleConfig["ql-color"];
        } else {
          item.parentNode.title = titleConfig[item.className];
        }
      });
    },
    // 文本编辑
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)
      // console.log(html.replace(/<[^>]*>|/g, ''), 33333333)
      this.$emit("update:content", html);
      this.$emit("change", html);
    },
    hideLoading() {
      this.loading = false;
    },
    // --------- 图片上传相关 start ---------

    addImageHandler(value) {
      if (value) {
        // 触发input框选择图片文件
        this.$refs["myinput"].click();
      } else {
        this.quill.format("image", false);
      }
    },
    // 把已经上传的图片显示回富文本编辑框中
    uploadSuccess(imgurl) {
      let quill = this.$refs["myQuillEditor"].quill;
      let range = quill.getSelection();
      let index = 0;
      if (range == null) {
        index = 0;
      } else {
        index = range.index; // 获取光标所在位置
      }
      // 插入
      quill.insertEmbed(index, "image", imgurl); // imgurl是服务器返回的图片链接地址
      // 调整光标到最后
      quill.setSelection(index + 1);
    },
    // el-文件上传组件
    onBeforeUpload(file) {
      this.loading = true;
      let acceptArr = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
      const isIMAGE = acceptArr.includes(file.type);
      const isLt1M = file.size / 1024 / 1024 < this.uploadImgConfig.maxSize;
      if (!isIMAGE) {
        this.hideLoading();
        this.$message.error("只能插入图片格式!");
      }
      if (!isLt1M) {
        this.hideLoading();
        this.$message.error(
          `上传文件大小不能超过 ${this.uploadImgConfig.maxSize}MB!`
        );
      }
      return isLt1M && isIMAGE;
    },
    // 文件上传成功时的钩子
    onSuccess(response, file, fileList) {
      // ---- 注意这部分需要改为对应的返回格式
      this.hideLoading();
      let res = JSON.parse(JSON.stringify(response));
      if (res.result) {
        this.uploadSuccess(res.result.fileUrl);
      } else {
        this.$message.error("上传失败");
      }
    },
    // 文件上传失败时的钩子
    onError(file, fileList) {
      this.hideLoading();
      this.$message.error("上传失败");
    },

    // --------- 图片上传相关 end ---------

    // --------- 视频上传相关 start ---------

    addVideoLink(videoLink) {
      if (!videoLink) return this.$message.error("请输入视频地址");
      this.videoDialog.show = false;
      let quill = this.$refs["myQuillEditor"].quill;
      let range = quill.getSelection();
      let index = 0;
      if (range == null) {
        index = 0;
      } else {
        index = range.index;
      }
      // 插入
      quill.insertEmbed(index, "video", videoLink);
      // 调整光标到最后
      quill.setSelection(index + 1);
    },

    // el-文件上传组件
    onBeforeUploadVideo(file) {
      this.loading = true;
      let acceptArr = ["video/mp4"];
      const isVideo = acceptArr.includes(file.type);
      const isLt1M = file.size / 1024 / 1024 < this.uploadVideoConfig.maxSize;
      if (!isVideo) {
        this.hideLoading();
        this.$message.error("只能上传mp4格式文件!");
      }
      if (!isLt1M) {
        this.hideLoading();
        this.$message.error(
          `上传文件大小不能超过 ${this.uploadVideoConfig.maxSize}MB!`
        );
      }
      return isLt1M && isVideo;
    },
    // 文件上传成功时的钩子
    onSuccessVideo(response, file, fileList) {
      // ---- 注意这部分需要改为对应的返回格式
      this.hideLoading();
      let res = JSON.parse(JSON.stringify(response));
      if (res.result) {
        this.addVideoLink(res.result.fileUrl);
      } else {
        this.$message.error("上传失败");
      }
    },
    // 文件上传失败时的钩子
    onErrorVideo(file, fileList) {
      this.hideLoading();
      this.$message.error("上传失败");
    },

    // --------- 视频上传相关 end ---------
  },
};
</script>
<style>
.RichTextEditor-Wrap .ql-container .ql-editor {
  min-height: 237px;
}

.RichTextEditor-Wrap .ql-editor {
  padding: 8px 10px;
}

.RichTextEditor-Wrap .ql-tooltip {
  left: 5px !important;
}
.ql-snow .ql-editor img {
  max-width: 30%;
  max-height: 300px;
}
.RichTextEditor-Wrap .ql-disabled {
  border: none !important;
}
.editor_dialog {
  background: transparent !important;
  box-shadow: none !important;
}
.editor_dialog .preview_img {
  max-width: 100%;
  max-height: 100%;
}
.RichTextEditor-Wrap .v-modal {
  opacity: 0.2;
}
</style>

