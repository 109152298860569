export default {
	securityRealTimeProtection_00001: "Number of Trigger Protection Today",
	securityRealTimeProtection_00002: "Number of Trigger Protection this Month",
	securityRealTimeProtection_00003: "Number of Charging Stops Today",
	securityRealTimeProtection_00004: "Number of Charging Stops this Month",
	securityRealTimeProtection_00005: "Number of Refused to Charge Today",
	securityRealTimeProtection_00006: "Number of Refused to Charge this Month",
	securityRealTimeProtection_00007: "Serious Alarm（{num}Today）",
	securityRealTimeProtection_00008: "Warning Alarm（{num}Today）",
	securityRealTimeProtection_00009: "Real-Time Protection List",
	securityRealTimeProtection_00010: "Serious Alarm",
	securityRealTimeProtection_00011: "Items",
	securityRealTimeProtection_00012: "Time",
	securityRealTimeProtection_00013: "Vehicle Model",
	securityRealTimeProtection_00014: "Plate Number",
	securityRealTimeProtection_00015: "Station",
	securityRealTimeProtection_00016: "Event",
	securityRealTimeProtection_00018: "[Real-Time Protection] Stop Charging",
	securityRealTimeProtection_00019: "[Real-time Protection] Power Reduction",
	securityRealTimeProtection_00020: "[Early Warning] Unsafe",
	securityRealTimeProtection_00021: "[Early Warning] Sub-Safety",
	securityRealTimeProtection_00022: "[Early Warning] Platform Forbids Charging",
	securityRealTimeProtection_00023: "[Early Warning] Platform Limit Charging",
	securityRealTimeProtection_00024: "Normal",
	securityRealTimeProtection_00025: "Warning Alarm",
	securityRealTimeProtection_00026: "Unprocessed Alarm Today",
}