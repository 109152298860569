export default {
    manageUsers_00001: 'Group member management',
    manageUsers_00002: "Group name",
    manageUsers_00003: "Wechat user Members",
    manageUsers_00004: "Charging card member",
    manageUsers_00005: "Add group members",
    manageUsers_00006: "Export community members",
    manageUsers_00007: "User nickname/mobile number",
    manageUsers_00008: "Search",
    manageUsers_00009: "Transactions",
    manageUsers_00010: "remove",
    manageUsers_00011: "head portrait",
    manageUsers_00012: "Username",
    manageUsers_00013: "Gender",
    manageUsers_00014: "Mobile number",
    manageUsers_00015: "plate number",
    manageUsers_00016: "operation",
    manageUsers_00017: "Card number",
    manageUsers_00018: "User name",
    manageUsers_00019: "The operation was cancelled",
    manageUsers_00020: "Are you sure you want to delete [{nickname}] from the group？",
    manageUsers_00021: "The user also has a group allocation balance of {num} (€), which will be automatically recovered after deletion.",
    manageUsers_00022: "[{nickname}] failed to delete from user group! The user has unfinished orders and cannot recover the user group allocation balance of {num} (€).",
    manageUsers_00023: "Set successfully",
    manageUsers_00024: "This operation will download Excel files for group users. Do you want to continue?",
    manageUsers_00025: "Reminder",
    manageUsers_00026: "Start downloading",
    manageUsers_00027: "Cancel Download",
    manageUsers_00028: "Please enter your nickname, mobile phone number, card number and multiple query criteria, which can be separated by comma (,), line feed, space and stop sign (,)",
    manageUsers_00029: "eliminate",
    manageUsers_00030: "Query results",
    manageUsers_00031: "Added",
    manageUsers_00032: "add",
    manageUsers_00033: "No permission to view!",
    manageUsers_00034: "Please enter the username, mobile phone number or card number you want to query",
}