<template>
  <el-dialog :title="$t('common_61031')" :visible.sync="rangeDialogVisible" width="800px" :modal-append-to-body="false" :close-on-click-modal="false" :lock-scroll="false" :before-close="closeRangeDialog">
    <div class="wrap">
      <slot name="title"></slot>
      <el-row class="query_tabs" v-if="rangData">
        <el-col :span="24">
          <el-tabs v-model="active" @tab-click="tabChange">
            <el-tab-pane :label="$t('common_61032')+$t('common_61037',[rangData.default?rangData.default.length:0])" name="public"></el-tab-pane>
            <el-tab-pane v-if="ratesContent&&ratesContent.type==0" :label="$t('common_61033')+$t('common_61037',[rangData.userGroup?rangData.userGroup.length:0])" name="group"></el-tab-pane>
            <el-tab-pane v-if="ratesContent&&ratesContent.type ==0 && product== 'cn'" :label="$t('common_61034')+$t('common_61037',[rangData.outsideOwner?rangData.outsideOwner.length:0])" name="outside"></el-tab-pane>
            <el-tab-pane label="" name=""></el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
      <div v-loading="loading" class="table_wrap">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="title" label="">
            <template slot-scope="scope">
              <span class="title_wrap">
                <span v-if="active=='group'">{{scope.row.userGroupTitle}} - </span>
                <span v-if="active=='outside'">{{scope.row.outSideOwneTitle}} - </span>
                {{scope.row.siteName}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="" :width="ratesContent&&ratesContent.type==1?'300':'240'" align="center">
            <template slot-scope="scope">
              <span>{{$t('common_45004')}}：{{scope.row.slowChargers}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span>{{$t('common_45003')}}：{{scope.row.fastChargers}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span v-if="ratesContent&&ratesContent.type==1">{{$t('common_45005')}}：{{scope.row.energyStorageChargers||0}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="">
            <template slot-scope="scope">
              <div class="push_content" v-if="ratesContent&&ratesContent.type==0">
                <div>
                  <img :src="scope.row.feePolicySlow==ratesContent.id?successImg:errorImg" alt="">
                  <span>{{$t('common_61035')}}</span>
                </div>
                <div>
                  <img :src="scope.row.feePolicyFast==ratesContent.id?successImg:errorImg" alt="">
                  <span>{{$t('common_61036')}}</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeRangeDialog" size="medium">{{$t('common_00303')}}</el-button>
      <slot name="sureBtn"></slot>
    </span>
  </el-dialog>
</template>

<script>
import successImg from "@/assets/img/push_success.png";
import errorImg from "@/assets/img/push_error.png";
export default {
  props: ["rangeDialogVisible", "allData"],
  data() {
    return {
      product:"",
      rangData: null,
      ratesContent: null,
      loading: true,
      successImg: successImg,
      errorImg: errorImg,
      active: "public",
      tableData: [],
    };
  },
  watch: {
    allData: {
      handler(val) {
        let { rangData, ratesContent } = val;
        if (!rangData || !ratesContent) return;
        this.rangData = rangData;
        this.ratesContent = ratesContent;
        if (ratesContent.type == 1) {
          this.active = "public";
        }
        this.tabChange();
      },
      deep: true,
    },
  },
  methods: {
    tabChange() {
      this.loading = false;
      switch (this.active) {
        case "public":
          this.tableData = this.rangData.default;
          break;
        case "group":
          this.tableData = this.rangData.userGroup;
          break;
        case "outside":
          this.tableData = this.rangData.outsideOwner;
          break;
        default:
          break;
      }
    },
    closeRangeDialog() {
      this.$emit("closeRangeDialog", false);
    },
  },
  created() {
    this.product = window.product_area;

  },
};
</script>

<style lang="scss" scoped>
/deep/.el-tabs__content,
/deep/.el-table__header-wrapper {
  display: none;
}
.wrap {
  .table_wrap {
    max-height: 234px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #eff2f6;
    overflow-y: auto;
    box-sizing: border-box;
    color: #464b5c;
    .title_wrap {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .push_content {
      display: flex;
      align-items: center;
      justify-content: center;
      > div:nth-of-type(1) {
        margin-right: 10px;
      }
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.table_wrap {
  /*滚动条整体样式*/
  &::-webkit-scrollbar {
    /*高宽分别对应横竖滚动条的尺寸*/
    width: 5px;
    background: #eff2f6;
    border-radius: 4px;
  }
  /*滚动条里面滑块*/
  &::-webkit-scrollbar-thumb {
    background: #9fa4b7;
    opacity: 0.4;
    border-radius: 4px;
  }

  // 上箭头
  &::-webkit-scrollbar-button:vertical:single-button:start {
    display: none;
  }
  // 下箭头
  &::-webkit-scrollbar-button:vertical:single-button:end {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
/deep/.el-tabs__nav-scroll {
  padding-left: 15px;
}
/* .el-tabs__nav{
    height: 63px;
  } */
/deep/.el-tabs__nav .is-active {
  color: #5278e2 !important;
}
/deep/.el-tabs__active-bar {
  height: 4px !important;
}
/deep/.el-tabs__item {
  color: #8087a0 !important;
  font-size: 16px !important;
  padding: 12px 30px 0 0 !important;
  font-weight: 600 !important;
  &:last-of-type {
    padding: 0 !important;
  }
}
/deep/.el-tabs__header {
  background-color: #fff !important;
  height: 64px !important;
  margin-bottom: 15px !important;
}
/deep/.el-tabs__nav {
  height: 64px;
  margin-left: 0 !important;
}
/deep/.el-tabs__content {
  margin-top: 20px;
}
/deep/.el-tabs__nav-wrap {
  height: 64px !important;
  &::after {
    background-color: transparent !important;
  }
}
</style>