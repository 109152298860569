export default {
    errorLog_00001: 'Select station',
    errorLog_00002: 'Select model',
    errorLog_00003: 'Start time',
    errorLog_00004: 'End time',
    errorLog_00005: '-',
    errorLog_00006: 'Enter SN',
    errorLog_00007: 'Report time',
    errorLog_00008: 'Log classification',
    errorLog_00009: 'All',
    errorLog_00010: "Charger Error",
    errorLog_00011: "Charger Abnormal",
    errorLog_00012: 'Offline',
    errorLog_00013: 'Enter error code',
    errorLog_00014: 'Description',
    errorLog_00015: 'Error code',
    errorLog_00016: 'More info',
    errorLog_00017: 'Station',
    errorLog_00018: 'CPO',
    errorLog_00019: 'Restored time',
    errorLog_00020: 'Restored',
    errorLog_00021: 'Not restored',
    errorLog_00022: 'Whether to restore',
    errorLog_00023: 'Not restored',
    errorLog_00024: 'Restored',
    errorLog_00025: "Charger Error - Unable to use normally, Charger Abnormal - Leading to the Termination of Charging, Charger can continue to be used",
    errorLog_00026: "Energy Storage Error"
}
