export default {
  userCharge_00001: "团体名称",
  userCharge_00002: "充值记录",
  userCharge_00003: "基本信息",
  userCharge_00004: "充值日期",
  userCharge_00005: "充值总金额({0})",
  userCharge_00006: "充值本金({0})",
  userCharge_00007: "充值赠送金额({0})",
  userCharge_00008: "支付方式",
  userCharge_00009: "操作用户",
  userCharge_00010: "余额({0})",
  userCharge_00011: "充值记录",
  userCharge_00012: "包含赠送{promotionBalance}{unit}",
  userCharge_00013: "至",
  userCharge_00014: "开始日期",
  userCharge_00015: "结束日期",
  userCharge_00016: "平台",
  userCharge_00017: "微信公众号（微信支付）",
  userCharge_00018: "微信公众号（支付宝）",
  userCharge_00019: "其他",
  userCharge_00020: "",
  userCharge_00021: "",
  userCharge_00022: "",
  userCharge_00023: "",
  userCharge_00024: "",
  userCharge_00025: "",
  userCharge_00026: "",
  userCharge_00027: "",
  userCharge_00028: "",
  userCharge_00029: "",
  userCharge_00030: "",
}