export default {
    stationRangeManage_00001: "Management station range",
    stationRangeManage_00002: "account",
    stationRangeManage_00003: "Charging station range",
    stationRangeManage_00004: "Name of charging station",
    stationRangeManage_00005: "Operate",
    stationRangeManage_00006: "Add charging site",
    stationRangeManage_00007: "operate",
    stationRangeManage_00008: "Site details",
    stationRangeManage_000024: "Operate succeeded",
    stationRangeManage_000025: "Please enter the name of the charging site",
    stationRangeManage_000026: "Charging site added successfully",
    stationRangeManage_000027: "Selected charging site",
    stationRangeManage_000028: "Delete succeeded",
    stationRangeManage_000029: "Are you sure you want to delete?",
    stationRangeManage_000030: "Deletion cancelled",
}