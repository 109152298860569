var checkHealth = {
	"CH1045": {
		"msg": "DCB内存",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1046": {
		"msg": "DCB磁盘",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1047": {
		"msg": "交流接触器",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1013": {
		"msg": "ACDC模块",
		"idMsg": "模块-",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1014": {
		"msg": "DCDC模块",
		"idMsg": "模块-",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1029": {
		"msg": "绝缘检测部件状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1049": {
		"msg": "并联/切换接触器",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1048": {
		"msg": "直流接触器",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {
			"1": "充电口1+",
			"-1": "充电口1-",
			"2": "充电口2+",
			"-2": "充电口2-"
		}
	},
	"CH1001": {
		"msg": "充电枪电磁锁",
		"idMsg": "充电口-",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1041": {
		"msg": "电表状态",
		"idMsg": "充电口-",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1004": {
		"msg": "充电枪CC电压",
		"idMsg": "充电口-",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1005": {
		"msg": "充电枪温度",
		"idMsg": "充电口-",
		"unit": "℃",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1002": {
		"msg": "充电枪CP电压",
		"idMsg": "充电口-",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1003": {
		"msg": "充电枪PP电压",
		"idMsg": "充电口-",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1006": {
		"msg": "充电枪液冷泵液位",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1007": {
		"msg": "充电枪液冷泵泵转速",
		"idMsg": "",
		"unit": "转/分钟",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1008": {
		"msg": "充电枪液冷泵风机转速",
		"idMsg": "",
		"unit": "转/分钟",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1009": {
		"msg": "充电枪液冷泵液温（给液）",
		"idMsg": "",
		"unit": "℃",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1010": {
		"msg": "充电枪液冷泵液温（回液）",
		"idMsg": "",
		"unit": "℃",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1018": {
		"msg": "DCB与A8通讯状态（延时）",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1021": {
		"msg": "急停状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1022": {
		"msg": "LED指示灯状态",
		"idMsg": "充电口-",
		"unit": "",
		"unitExt": {
			"1": "灭",
			"2": "红",
			"3": "绿",
			"4": "蓝"
		},
		"idMsgExt": {}
	},
	"CH1024": {
		"msg": "屏幕点亮状态",
		"idMsg": "",
		"unit": "",
		"unitExt": {
			"1": "亮",
			"2": "灭"
		},
		"idMsgExt": {}
	},
	"CH1032": {
		"msg": "广告服务器连接状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1033": {
		"msg": "广告屏幕状态",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1030": {
		"msg": "内部各温度",
		"idMsg": "",
		"unit": "℃",
		"unitExt": {},
		"idMsgExt": {
			"1": "机箱"
		}
	},
	"CH1031": {
		"msg": "防雷保护器状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1035": {
		"msg": "GSM网络信号质量",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1038": {
		"msg": "烟雾报警器状态",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1044": {
		"msg": "倾倒传感器",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1039": {
		"msg": "浸水检测器状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1040": {
		"msg": "各门禁状态",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {
			"1": "前",
			"2": "后",
			"3": "左",
			"4": "右"
		}
	},
	"CH1011": {
		"msg": "辅助电源电压情况12V",
		"idMsg": "",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1012": {
		"msg": "辅助电源电压情况24V",
		"idMsg": "",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1017": {
		"msg": "AC输入电压水平",
		"idMsg": "",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {
			"1": "A",
			"2": "B",
			"3": "C"
		}
	},
	"CH1026": {
		"msg": "光线传感器状态",
		"idMsg": "",
		"unit": "Ω",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1027": {
		"msg": "NFC状态/pos机状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1028": {
		"msg": "散热组件状态",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1042": {
		"msg": "熔断器状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1043": {
		"msg": "漏电保护器状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1034": {
		"msg": "OCPP/X-Cloud辅助平台连接状态",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1036": {
		"msg": "LAN连接DNS",
		"idMsg": "",
		"unit": "毫秒",
		"unitExt": {},
		"idMsgExt": {}
	},
}
module.exports = checkHealth;