export default {
  powerMaint_00001: 'Equipment requiring power module maintenance',
  powerMaint_00002: '!',
  powerMaint_00003: 'Timing prompt for maintenance of power module:',
  powerMaint_00004: 'Cumulative charging time from reset time',
  powerMaint_00005: 'Reset time: ',
  powerMaint_00006: '{hours}Stunde',
  powerMaint_00007: 'normal',
  powerMaint_00008: 'Fehler',
  powerMaint_00009: 'Sind Sie sicher, dass Sie diese Wartung für dieses Ladepunktleistungsmodul abgeschlossen haben? Nachdem Sie auf OK geklickt haben, benötigt das Leistungsmodul eine Wartungsaufforderung, um die Zeit zu verlängern.',
  powerMaint_00010: 'Eingabeaufforderung',
  powerMaint_00011: 'OK',
  powerMaint_00012: 'Abbrechen',
  powerMaint_00013: 'Erfolg',
  //*****
  powerMaint_10000: 'Wartungsausrüstung',
  powerMaint_10001: 'Lichtzeit',
  powerMaint_10002: 'Verwendet',
  powerMaint_10003: 'Stunde',
  powerMaint_10004: 'Zeit',
  powerMaint_10005: 'Aufforderungsschwelle für Wartungszeit: ',
  powerMaint_10006: 'Die kumulierte Nutzungszeit seit dem Zurücksetzen ist größer oder gleich',
}