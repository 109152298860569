export default {
    orderMangement_00001: 'Automatic ticket trigger',
    orderMangement_00002: 'Trigger name',
    orderMangement_00003: 'Whether the trigger is enabled',
    orderMangement_00004: 'All',
    orderMangement_00005: 'Enable',
    orderMangement_00006: 'Operation trigger',
    orderMangement_00007: 'Equipment class trigger',
    orderMangement_00008: 'Ticket category',
    orderMangement_00009: 'Trigger condition',
    orderMangement_00010: 'Open Tickets',
    orderMangement_00011: 'Followers of the ticket',
    orderMangement_00012: 'ticket notification time',
    orderMangement_00013: 'New trigger',
    orderMangement_00014: 'When the device reports a device failure or the device is abnormal, if the trigger does not return to normal after the time interval set by the trigger, it will automatically initiate a ticket troubleshooting. (Note: Some equipment failures will automatically recover, if the time interval is set too short, it is easy to generate false alarms)',
    orderMangement_00015: 'All Categories',
    orderMangement_00016: 'ticket classification',
    orderMangement_00017: 'Device serial number',
    orderMangement_00018: 'All Equipments by manufacturer',
    orderMangement_00019: 'All models',
    orderMangement_00020: 'Pending ticket',
    orderMangement_00021: 'Processing ticket',
    orderMangement_00022: 'Resolved Tickets',
    orderMangement_00023: 'Closed Tickets',
    orderMangement_00024: 'List of tickets',
    orderMangement_00025: 'Export ticket',
    orderMangement_00026: 'ticket number',
    orderMangement_00027: 'ticket title',
    orderMangement_00028: 'Classification',
    orderMangement_00029: 'Priority',
    orderMangement_00030: 'Status',
    orderMangement_00031: 'Problem station',
    orderMangement_00032: 'Processor',
    orderMangement_00033: 'Originator',
    orderMangement_00034: 'Origination time',
    orderMangement_00035: 'Update time',
    orderMangement_00036: 'Problem source',
    orderMangement_00037: 'Operation',
    orderMangement_00038: 'Reject',
    orderMangement_00039: 'Processed',
    orderMangement_00040: 'Accept',
    orderMangement_00041: 'Transfer ticket',
    orderMangement_00042: 'Close the order',
    orderMangement_00043: 'Add to knowledge base',
    orderMangement_00044: 'Details',
    orderMangement_00045: 'Very urgent',
    orderMangement_00046: 'Urgent',
    orderMangement_00047: 'General',
  
    orderMangement_00048: 'When the system finds that a certain number of abnormal orders for charging ports have reached a certain number within a few days, it will automatically initiate a ticket for investigation. ',
    orderMangement_00049: 'When the system finds that a certain number of abnormal orders of users reach a certain number within a few days, it will automatically initiate a ticket for investigation. ',
    orderMangement_00050: 'When the system finds that within a few days, there are more than one charger in a certain station with no order and also shows no fault code, it will automatically initiate a ticket for investigation. ',
    orderMangement_00051: 'Abnormal order statistics-charging port',
    orderMangement_00052: 'Abnormal order statistics-user',
    orderMangement_00053: 'No Charging Charger',
    orderMangement_00054: 'Equipment trigger',
    orderMangement_00055: '',
    orderMangement_00056: '',
    orderMangement_00057: '',
    orderMangement_00058: '',
    orderMangement_00059: '',
    orderMangement_00060: '',
    orderMangement_00061: '',
    orderMangement_00062: '',
    orderMangement_00063: '',
    orderMangement_00064: '',
    orderMangement_00065: 'Close reasoning',
    orderMangement_00066: 'Return to ticket',
    orderMangement_00067: 'Are you sure to return the ticket',
    orderMangement_00068: 'Confirm return',
    orderMangement_00069: 'Return reasoning',
    orderMangement_00070: 'The order has been processed',
    orderMangement_00071: 'Do you confirm that the ticket has been processed',
    orderMangement_00072: 'Confirmation has been processed',
    orderMangement_00073: 'Please complete the form',
    orderMangement_00074: 'The operation was successful',
    orderMangement_00075: 'Exception',
    orderMangement_00076: 'Continuous',
    orderMangement_00077: 'Settings',
    orderMangement_00078: 'Sites',
    orderMangement_00079: 'Days arrived',
    orderMangement_00080: 'Single exception',
    orderMangement_00081: 'minutes later',
    orderMangement_00082: 'Description',
}