export default {
    bill_00059: "Today",
    bill_00060: "7 days before",
    bill_00061: "15 days before",
    bill_00077: "Yesterday",
    bill_00078: "30 days before",



    convert_00001: 'Channel redemption code record',
    convert_00002: 'Marketing centre',
    convert_00003: 'Coupon distribution',
    convert_00004: 'Please enter redemption code',
    convert_00005: 'Please enter redemption code',
    convert_00006: 'Please enter distribution channel',
    convert_00007: 'Please enter coupon name',
    convert_00008: 'Start date',
    convert_00009: 'End date',
    convert_00010: 'Please enter your username/mobile phone number',
    convert_00011: 'Coupon Category',
    convert_00012: 'All',
    convert_00013: 'Credit',
    convert_00014: 'Electricity ticket',
    convert_00015: 'Reset',
    convert_00016: 'Search',
    convert_00017: 'Number of Redemptions',
    convert_00018: 'Counts of Redemptions',
    convert_00019: 'Number of electricity ticket redeemed',
    convert_00020: 'Amount of electricity ticket redeemed (kwh)',
    convert_00021: 'Count of credits redeemed', 
    convert_00022: 'Amount of credits to be redeemed(€)',
    convert_00023: 'List of coupons', 
    convert_00024: 'Export data',
    convert_00025: 'User photo profile', 
    convert_00026: 'User(mobile number)', 
    convert_00027: 'Redemption code', 
    convert_00028: 'Distribution channel',
    convert_00029: 'Category of coupon to be redeemed',
    convert_00030: 'Name of the redeemed coupon',
    convert_00031: 'Time of redemption',
    convert_00032: 'Operation', 
    convert_00033: 'User details', 
    convert_00034: 'Coupon details', 
    convert_00035: 'This operation will download an Excel file of the channel redemption code record data, do you wish to continue?',
    convert_00036: 'Reminder', 
    convert_00037: 'Cancel',
    convert_00038: 'Start downloading', 
    convert_00039: 'Electricity ticket', 
    convert_00040: 'Credit', 
    convert_00041: 'Channel redemption code record',
}
