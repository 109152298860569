export default  {
    charging_package45:"Bargeld als Strom und Servicebetrag",
    charging_package46:"Elektrizität als Stom und Servicebetrag",
    charging_package47:"Bargeld als Servicebetrag",
    charging_package48:"Elektrizität als Servicebetrag",
    charging_package52:"ab den Einkaufstag{day}",  

    cash_coupon0001: 'Marketing-Zentrum',
    cash_coupon0002: 'Angebote-Instrumente',
    cash_coupon0003: 'Name',
    cash_coupon0004: 'Bitte eingeben', 
    cash_coupon0005: 'zurücksetzen',
    cash_coupon0006: 'suchen',
    cash_coupon0007: 'Liste',
    cash_coupon0008: 'neu erstellen',
    cash_coupon0009: 'Typen',
    cash_coupon0010: 'Summe',
    cash_coupon0011: 'kWh',
    cash_coupon0012: 'EUR',
    cash_coupon0013: 'Gültigkeitsdauer',
    cash_coupon0014: 'Betrieb',
    cash_coupon0015: 'Einzelheiten',
    cash_coupon0016: 'Elektrizitätspaket',
    cash_coupon0017: 'Gutscheine',
}
