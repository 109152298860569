<template>
  <div>
    <el-dialog
      :title="$t('common_46019')"
      width="800px"
      :lock-scroll="false"
      :visible="visible"
      @close="close"
    >
      <div class="active-list">
        <div :class="['a-items',val == v.id ? 'active':'']" v-for="(v,k) in list" :key="k" @click="checkBanner(v.url)">
          <p>{{v.name}}</p>
          <img :src="v.url"/>
        </div>

      </div>
        
    </el-dialog>
  </div>
</template>

<script>
import {
  requestUrl,
  ajax,
} from "@/assets/utils/network";
export default {
  // props: {
  //   visible: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data() {
    return {
      imgList:[],
      url: `${requestUrl}/file`,
      val:'',
      visible:true,
      list:[
        {
          id:1,
          name:this.$parent.$t('common_46020'),
          url: 'http://test-file-repository.oss-cn-beijing.aliyuncs.com/2022/10/11/14/4fc2fd3f2ead4acf8c700cd4133f1b53.png',
        },
        {
          id:2,
          name:this.$parent.$t('common_46021'),
          url: 'http://test-file-repository.oss-cn-beijing.aliyuncs.com/2022/10/11/14/abbb1d6a5c8a478f810149780715f4b9.png',
        },
        {
          id:3,
          name:this.$parent.$t('common_46022'),
          url: 'http://test-file-repository.oss-cn-beijing.aliyuncs.com/2022/10/11/14/54752fe97e784242bdf5827900ddc24f.png',
        },
        {
          id:4,
          name:this.$parent.$t('common_46023'),
          url: 'http://test-file-repository.oss-cn-beijing.aliyuncs.com/2022/10/11/14/d5c31c685a6d488a92b71346ad7d0f30.png',
        },
        {
          id:5,
          name:this.$parent.$t('common_61158'),
          url: 'http://test-file-repository.oss-cn-beijing.aliyuncs.com/2023/7/19/12/a4118b5772eb4c0897bc6d80735598ab.png',
        },
        {
          id:6,
          name:this.$parent.$t('common_80002'),
          url: 'http://test-file-repository.oss-cn-beijing.aliyuncs.com/2023/8/2/19/8f5e452ead59424694d932a1c1c25b75.jpg',
        },

      ]
    };
  },
  mounted() {
  },
  methods: {
    checkBanner (v) {
      this.val = v
      this.$emit("confirm", {val: v});
      this.close()
      
    },
    close() {
      this.$emit("close");
      this.val = ''
    },
  },
};
</script>

<style lang="scss">
.active-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .a-items{
    width: 340px;
    height: 196px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #E6E7EC;
    margin-bottom: 30px;
    box-sizing: border-box;
    color: #1B2330;
    cursor: pointer;
    &:hover{
      border: 1px solid #5278E2;
      p{
      color: #5278E2;
      }
    }
    &.active{
      border: 1px solid #5278E2;
      p{
      color: #5278E2;
      }

    }
    img{
      display: block;
      width: 280px;
      height: 106px;
    }
    p{
      margin-bottom: 10px;
      color: #1B2330;
    }
  }
}
</style>
