export default {
  orderClassify_00001: "Ticket category",
  orderClassify_00002: "List of category",
  orderClassify_00003: "New category",
  orderClassify_00004: "Category name",
  orderClassify_00005: "Parent category",
  orderClassify_00006: "Category level",
  orderClassify_00007: "Top level",
  orderClassify_00008: "Second level",
  orderClassify_00009: "Default ticket category processor",
  orderClassify_00010: "Default ticket category follower",
  orderClassify_00011: "Operation",
  orderClassify_00012: "Create secondary category",
  orderClassify_00013: "Edit",
  orderClassify_00014: "Delete",
  orderClassify_00015: "None",
  orderClassify_00016: "Cannot recover after deleting a category",
  orderClassify_00017: "The current category cannot be deleted",
  orderClassify_00018: "Top level",
  orderClassify_00019: "Category name",
  orderClassify_00020: "Tickets of the category default settings",
  orderClassify_00021: "Issue description",
  orderClassify_00022: "Default processor",
  orderClassify_00023: "Default follower",
  orderClassify_00024: "All",
  orderClassify_00025: "Edit Category",
  orderClassify_00026: "Please fill in the classification name",
  orderClassify_00027: "Please select the respondent of the issue",
  orderClassify_00028: "Please select a follower",
  orderClassify_00029: "New success",
  orderClassify_00030: "Modified successfully",
  orderClassify_00031: "Can't be deleted",
}