<template>
  <div class="chart_home">
    <div class="content">
      <div class="title">
        {{chartData.text}}({{$t(chartData.unit)}})<span class="today">{{$t('common_20054')}}</span>
      </div>
      <div class="count" :style="`color: ${chartData.color};`">{{chartData.today}}</div>
      <ul class="yesterday">
        <li>
          <span class="label">{{$t('common_20055')}}</span>
          <span class="value">{{chartData.yesterday}}</span>
        </li>
        <li>
          <span class="label">{{$t('common_20056')}}</span>
          <span  :class="['value',parseInt(chartData.rate)>0?'bg_color1':'bg_color']">{{chartData.rate}}</span>
        </li>
      </ul>
    </div>
    <div class="chart" :id="`chart${index}`"></div>
  </div>
</template>

<script>
export default {
  props: ["chartData", "index"],
  data() {
    return {
      myChart: null,
      options: {
        tooltip: {
          trigger: "axis",
          textStyle: {
            align: "left",
          },
        },
        grid: {
          top: 1,
          left: 0,
          right: 0,
          bottom: 25,
          containLabel: false,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: null,
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: "#ddd",
              fontSize: 12,
            },
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            name: "",
            type: "line",
            stack: "总量",
            smooth: true,
            data: null,
            showSymbol: false,
            symbol: "emptyCircle",
            symbolSize: 10,
            hoverAnimation: false,
            areaStyle: {},
            itemStyle: {},
          },
        ],
      },
    };
  },
  watch: {
    chartData: {
      handler(val) {
        this.$nextTick(() => {
          this.init(val);
        });
      },
      immediate: true,
    },
  },
  mounted() {
    let echarts = require("echarts");
    this.myChart = echarts.init(document.getElementById(`chart${this.index}`));
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
    init(val) {
      let options = {};
      Object.assign(options, this.options);
      options.xAxis.data = val.xAxisData;
      options.series[0].name = val.text;
      options.series[0].data = val.seriesData;
      options.series[0].itemStyle = {
        normal: {
          color: val.color, //折点颜色
          lineStyle: {
            color: val.color, //折线颜色
          },
        },
      };
      let echarts = require("echarts");
      options.series[0].areaStyle = {
        opacity: 0.1,
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: val.color,
          },
          {
            offset: 1,
            color: "#fff",
          },
        ]),
      };
      this.myChart.setOption(options);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart_home {
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(30, 30, 30, 0.05);
  border-radius: 6px;
  .content {
    padding: 20px 16px 0;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #8087a0;
      line-height: 22px;
      white-space: nowrap;
      .today {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #8087a0;
        line-height: 17px;
        margin-left: 4px;
      }
    }
    .count {
      font-size: 34px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      line-height: 41px;
      margin: 14px 0 8px;
    }
    .yesterday {
      li {
        display: flex;
        margin-bottom: 6px;
        .label {
          width: 80px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #8087a0;
          line-height: 17px;
        }
        .value {
          white-space: nowrap;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #8087a0;
          line-height: 17px;
          &.bg_color {
            padding: 2px 5px;
            border-radius: 4px;
            background: #ffeeef;
            color: #fa545e;
          }
          &.bg_color1 {
            padding: 2px 5px;
            border-radius: 4px;
            color: #4ec99b;
            background: #dff5ea;
          }
        }
      }
    }
  }
  .chart {
    height: 72px;
  }
}
</style>