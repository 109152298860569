export default {
vehicleChargingLog_00001: "请输入车牌号",
vehicleChargingLog_00002: "开始日期",
vehicleChargingLog_00003: "结束日期",
vehicleChargingLog_00004: "车牌号",
vehicleChargingLog_00005: "车型",
vehicleChargingLog_00006: "车辆{tag}",
vehicleChargingLog_00007: "车主",
vehicleChargingLog_00008: "手机号",
vehicleChargingLog_00009: "充电开始时间",
vehicleChargingLog_00010: "充电结束时间",
vehicleChargingLog_00011: "充电订单号",
vehicleChargingLog_00012: "充电站",
vehicleChargingLog_00013: "充电口",
vehicleChargingLog_00014: "日志详情",
vehicleChargingLog_00015: "订单详情",
vehicleChargingLog_00016: "日期范围不能超过90天，请重新选择！",
vehicleChargingLog_00018: "请选择日期！",
vehicleChargingLog_00019: "车责故障",
vehicleChargingLog_00020: "请输入车辆VIN",
vehicleChargingLog_00021: "品牌|车型",
}