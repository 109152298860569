export default {
    energyHome_00001: "Non NZS Charger",
    energyHome_00002: "NZS Battery",
    energyHome_00003: "Current NZS Battery SOC",
    energyHome_00004: "Self-Powered Today",
    energyHome_00005: "Available",
    energyHome_00006: "Power mode",
    energyHome_00007: "Current Working Mode",
    energyHome_00008: "Microgrid Charger Management",
    energyHome_00009: "NZS Battery Installed Capacity",
    energyHome_00010: "Power",
    energyHome_00011: "Voltage",
    energyHome_00012: "Current",
    energyHome_00013: "Work mode time SCH",
    energyHome_00014: "Today's EV Charging Amount",
    energyHome_00015: "Today's EV Charging Revenue",
    energyHome_00016: "Set Grid Use Cost",
    energyHome_00017: "Charging Station Capacity"

  }