import dayjs from 'dayjs';
import constants from "./constants";
var lang = window.localStorage.lang;//切换语言存localStorage,每次优先从localStorage取
if (!lang) {
  var language = navigator.language.slice(0,2);
  if (language === "ch" || language === "en" || language === "de" || language === "es") {
    lang = language;
  } else {
    var product = constants.product_area;
    if (product == "eu") {
      lang = "en";
    } else {
      lang = "zh";
    }
  }
}
export function checkLogin(openUrl) {
  if (LoginInfo != "") {
    if (openUrl == "name") {
      var obj = new Object();
      obj.loginId = LoginInfo.result.id;
      obj.name = LoginInfo.result.title;
      return obj;
    }
    if (openUrl == "id") {
      return LoginInfo.result.id;
    }
    if (openUrl == "wechatOASourceId") {
      return LoginInfo.result.wechatOASourceId;
    }
    if (openUrl == "imgUrl") {
      return LoginInfo.result.imageUrl;
    }
    if (openUrl == "idType") {
      return LoginInfo.result.idType;
    }
    if (openUrl == "role") {
      return LoginInfo.result.role;
    }
    if (openUrl == "permissions") {
      return LoginInfo.result.permissions;
    }
    if (openUrl == "permissionDeniedStyle") {
      return LoginInfo.result.permissionDeniedStyle;
    }
    if (openUrl == "permissionsV2") {
      return LoginInfo.result.permissionsV2
    }
    if (openUrl == "appId") {
      return LoginInfo.result.appId;
    }
    if (openUrl == "loginId") {
      return LoginInfo.result.loginId;
    }
    //运营商的货币类型
    if (openUrl == "currencyType") {
      return LoginInfo.result.currencyType;
    }
    //只有充电卡组账号才返回
    if (openUrl == "type") {
      return LoginInfo.result.type;
    }
    if (openUrl == "resources") {
      return LoginInfo.result.resources;
    }
    if (openUrl == "isSupervisor") {
      return LoginInfo.result.isSupervisor;
    }
    if (openUrl == "userGroupId") {
      return LoginInfo.result.userGroupId;
    }
  }
  var hasLogin = true;
  var id = "";
  var sourceId = "";
  var name = "";
  var isDealer = true;
  var cookieValue = getCookie("sid");
  ajax({
    type: "GET",
    async: false,
    url: requestUrl + "/myinfo",
    dataType: "json",
    success: function(data) {
      var info = JSON.parse(data);
      if (info.error != null) {
        LoginInfo = "";
        window.location.href = "./login.html?l=0";
        return false;
      } else {
        LoginInfo = info;
        hasLogin = true;
        if (info.result.idType != "dealerLevel1") {
          isDealer = false;
        }
      }
    }
  });
  if (hasLogin) {
    if (openUrl == null) {
      return true;
    } else {
      if (openUrl == "id") {
        return LoginInfo.result.id;
      }
      if (openUrl == "sourceId") {
        var obj = new Object();
        obj.sourceId = LoginInfo.result.wechatOASourceId;
        obj.isDealer = isDealer;
        return obj;
      }
      if (openUrl == "name") {
        var obj = new Object();
        obj.loginId = LoginInfo.result.id;
        obj.name = LoginInfo.result.title;
        return obj;
      }
      if (openUrl == "imgUrl") {
        return LoginInfo.result.imageUrl;
      }
      if (openUrl == "idType") {
        return LoginInfo.result.idType;
      }
      if (openUrl == "wechatOASourceId") {
        return LoginInfo.result.wechatOASourceId;
      }
      if (openUrl == "role") {
        return LoginInfo.result.role;
      }
      if (openUrl == "permissions") {
        return LoginInfo.result.permissions;
      }
      if (openUrl == "appId") {
        return LoginInfo.result.appId;
      }
      if (openUrl == "loginId") {
        return LoginInfo.result.loginId;
      }
      if (openUrl == "type") {
        return LoginInfo.result.type;
      }
      //运营商的货币类型
      if (openUrl == "currencyType") {
        return LoginInfo.result.currencyType;
      }/*  else {
        window.location.href = "./" + openUrl;
      } */
    }
  } else {
    window.location.href = "./login.html";
  }
}
export function checkTabs(permissionsV2) {
  let permissions = checkLogin("permissionsV2");
  let permissionRole = checkLogin("permissionDeniedStyle");
  if(permissions[permissionsV2] === undefined){
    permissions[permissionsV2] = [0,0]
  }
  if(permissions[permissionsV2][0] == 0 && permissions[permissionsV2][1] == 0){
    if(permissionRole == 1){
      return false;
    }
  }else if(permissions[permissionsV2][0] == 1 && permissions[permissionsV2][1] == 0){
    return false;
  }else if(permissions[permissionsV2][0] == 2 && permissions[permissionsV2][1] == 0){
    return false;
  }
}
//n天前
export function getDateTimeLast(n) {
  var time = new Date();
  if (n != null) {
    time = new Date(new Date() - 24 * 60 * 60 * 1000 * parseInt(n));
  }
  return (
    "" +
    time.getFullYear() +
    (time.getMonth() + 1 < 10
      ? "0" + (time.getMonth() + 1)
      : time.getMonth() + 1) +
    (time.getDate() < 10 ? "0" + time.getDate() : time.getDate()) +
    (time.getHours() < 10 ? "0" + time.getHours() : time.getHours()) +
    (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()) +
    (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds())
  );
}

export function getDateTime0Last(n) {
  var time = new Date();
  if (n != null) {
    time = new Date(new Date() - 24 * 60 * 60 * 1000 * parseInt(n));
  }
  return (
    "" +
    time.getFullYear() +
    (time.getMonth() + 1 < 10
      ? "0" + (time.getMonth() + 1)
      : time.getMonth() + 1) +
    (time.getDate() < 10 ? "0" + time.getDate() : time.getDate()) +
    "000000"
  );
}

export function getDateTime9Last(n) {
  var time = new Date();
  if (n != null) {
    time = new Date(new Date() - 24 * 60 * 60 * 1000 * parseInt(n));
  }
  return (
    "" +
    time.getFullYear() +
    (time.getMonth() + 1 < 10
      ? "0" + (time.getMonth() + 1)
      : time.getMonth() + 1) +
    (time.getDate() < 10 ? "0" + time.getDate() : time.getDate()) +
    "235959"
  );
}

export function getDateTime9LastNew(n) {
  var time = new Date();
  if (n != null) {
    time = new Date(new Date() - 24 * 60 * 60 * 1000 * parseInt(n));
  }
  return (
    "" +
    time.getFullYear() +
    (time.getMonth() + 1 < 10
      ? "0" + (time.getMonth() + 1)
      : time.getMonth() + 1) +
    (time.getDate() < 10 ? "0" + time.getDate() : time.getDate()) +
    "235959"
  );
}

//将long时间格式转成时间类型Date、
export function getDateFromLongTime(longTime) {
  longTime = "" + longTime;
  var str =
    longTime.substring(0, 4) +
    "/" +
    longTime.substring(4, 6) +
    "/" +
    longTime.substring(6, 8) +
    " " +
    longTime.substring(8, 10) +
    ":" +
    longTime.substring(10, 12) +
    ":" +
    longTime.substring(12, 14);
  return new Date(str);
}
//通过北京时间计算客户端所在时区的时间
export function getLocalTimeFromBjTime(longTime, hasH) {
  if (longTime == null || longTime == "") {
    return "*";
  }
  var bjTime = getDateFromLongTime(longTime);
  var offSet = new Date().getTimezoneOffset() * 60 * 1000; //单位毫秒
  var bjOffSet = 8 * 60 * 60 * 1000; //毫秒差
  var gmtDate = new Date(bjTime.getTime() - bjOffSet);
  var time = new Date(gmtDate.getTime() - offSet);
  var localTime = dayjs(time);
  var dayjslang = "en";
  if (lang) {
    if (lang == "zh") {
      dayjslang = "zh-cn";
    } else {
      dayjslang = lang;
    }
  }
  if (hasH) {
    if (!window.format.timeFormat[dayjslang]) {
      dayjslang = "en";
    }
    return localTime.format(window.format.timeFormat[dayjslang]);
  } else {
    if (!window.format.dateFormat[dayjslang]) {
      dayjslang = "en";
    }
    return localTime.format(window.format.dateFormat[dayjslang]);
  }
}
//将本地时间转成东八区时间
export function getBjTimeFromLocalTime(longTime) {
  var localDate = getDateFromLongTime(longTime);
  var offSet = localDate.getTimezoneOffset() * 60 * 1000; //单位毫秒
  var bjOffSet = 8 * 60 * 60 * 1000; //毫秒差
  var gmtDate = new Date(localDate.getTime() + offSet);
  var time = new Date(gmtDate.getTime() + bjOffSet);

  return (
    "" +
    time.getFullYear() +
    (time.getMonth() + 1 < 10
      ? "0" + (time.getMonth() + 1)
      : time.getMonth() + 1) +
    (time.getDate() < 10 ? "0" + time.getDate() : time.getDate()) +
    (time.getHours() < 10 ? "0" + time.getHours() : time.getHours()) +
    (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()) +
    (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds())
  );
}

//获取参数
export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return r[2];
  return "";
}

//获取文件后缀名
export function getFileSuffix(fileUrl) {
  let strs = fileUrl.split(".");
  let str = strs[strs.length - 1];
  if (str.toLowerCase() == "jpeg") return "jpg";
  else return str;
}

//将long时间格式化(此处一般是对接口返回的时间进行处理，北京时间转当地时区时间)
//1、获取接口返回的北京时间(long类型),转换成date
//2、将北京时间换算成所在时区的时间
//3、调用语言包的格式化模板对时间进行格式化处理
export function formmatTime(time, hasH) {
  if (time == null || time == "") {
    return "*";
  } else {
    return getLocalTimeFromBjTime(time, hasH);
  }
}
//将long时间进行格式化（不走时区转换逻辑，此处一般页面定义的时间，非接口返回的时间）
export function formatTime(time, hasH) {
  if (time == null || time == "") {
    return "*";
  } else {
    var time = getDateFromLongTime(time);
    var localTime = dayjs(time);
    var dayjslang = "en";
    if (lang) {
      if (lang == "zh") {
        dayjslang = "zh-cn";
      } else {
        dayjslang = lang;
      }
    }

    if (hasH) {
      if (!window.format.timeFormat[dayjslang]) {
        dayjslang = "en";
      }
      return localTime.format(window.format.timeFormat[dayjslang]);
    } else {
      if (!window.format.dateFormat[dayjslang]) {
        dayjslang = "en";
      }
      return localTime.format(window.format.dateFormat[dayjslang]);
    }
  }
}

export function formmatTimeHourFirst(time) {
  if (time == null || time == "") {
    return "*";
  } else {
    return (
      " " +
      time.substring(8, 10) +
      ":" +
      time.substring(10, 12) +
      ":" +
      time.substring(12, 14) +
      " " +
      time.substring(0, 4) +
      "/" +
      time.substring(4, 6) +
      "/" +
      time.substring(6, 8)
    );
  }
}

export function formmatNumFix2(num, num1) {
  if (num == null && num1 == null) return "0.00";
  else if (num != null && num1 == null) return (num * 0.01).toFixed(2);
  else if (num == null && num1 != null) return (num1 * 0.01).toFixed(2);
  else return ((num + num1) * 0.01).toFixed(2);
}

export function formmatSecond(s, $day, $hour, $minute) {
  var t;
  if (s > -1) {
    var hour = Math.floor(s / 3600);
    var min = Math.floor(s / 60) % 60;
    var sec = s % 60;
    var day = parseInt(hour / 24);
    if (day > 0) {
      hour = hour - 24 * day;
      t = day + $day + hour + $hour;
    } else t = hour + $hour;
    if (min < 10) {
      t += "0";
    }
    t += min + $minute;
    /*if(sec < 10){t += "0";}
		        t += sec;*/
  }
  return t;
}

export function getFormatStringFromLongTime(longTime) {
  longTime = "" + longTime;
  var str =
    longTime.substring(0, 4) +
    "/" +
    longTime.substring(4, 6) +
    "/" +
    longTime.substring(6, 8) +
    " " +
    longTime.substring(8, 10) +
    ":" +
    longTime.substring(10, 12) +
    ":" +
    longTime.substring(12, 14);
  return str;
}

export function formatTimes(time) {
  if(!time) return '';
  let str =
    time.substring(0, 4) +
    "/" +
    time.substring(4, 6) +
    "/" +
    time.substring(6, 8);
  return str;
}
// 时间戳转成 yyyyMMddHHmmss 
export function formatYmds(s,num) {
  let time = new Date (s)
  let yyyyMMdd = ""
  // return (
    let y = time.getFullYear()
    let m = (time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1)
    let d = (time.getDate() < 10 ? "0" + time.getDate() : time.getDate())
    yyyyMMdd = `${y}${m}${d}`
  return yyyyMMdd + num
  // if (type == 0) {
  //   console.log('000')
  //   yyyyMMdd + '000000'
  // }
  // if(type == 9){
  //   console.log('9999')
  //   yyyyMMdd + '235959'

  // }
}
