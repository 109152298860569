export default {
    wra_10000:'No data available at this time',
    
    wra_00000:'Copy',
    wra_00001:'Finance Centre',
    wra_00002:'Common functions',
    wra_00003:'User name/Mobile number',
    wra_00004:'Start Date',
    wra_00005:'End date',
    wra_00006:'Application Status',
    wra_00007:'Enquiry',
    wra_00008:'Reset',

    wra_00009:'Copy successful!',

    wra_00010:'Today',
    wra_00011:'Yesterday',
    wra_00012:'7 days ago',
    wra_00013:'15 days ago',
    wra_00014:'30 days ago',

    wra_00015:'All',
    wra_00016:'To be confirmed',
    wra_00017:'Completed',
    wra_00018:'Cancelled',
    wra_00019:'To be completed',

    wra_00020:'',
    wra_00021:'AppID required for WeChat Merchant Enterprise payment',

    wra_00022:'Export refunded data',
    wra_00023:'This operation will download an Excel file of the refunded orders, do you want to continue?' ,
    wra_00024:'Prompt',
    wra_00025: 'OK',
    wra_00026: 'Cancel',
    wra_00027: 'Start download',
    wra_00028: "Cancel download",
    wra_00029: "Downloading bulk refund file",
    wra_00030: 'Download bulk refund file (WeChat Pay special merchants)',

    wra_00031:'Nickname',
    wra_00032:'Mobile Number',

    wra_00033:'Euro',

    wra_00034:'Amount to be refunded',
    wra_00035:'Refunded',
    wra_00036:'Not refunded',

    wra_00037:'Refund completed',
    wra_00038:'Acceptance confirmed',
    wra_00039: 'Cancellation accepted',
    
    wra_00040:'Amount to be refunded consists of',
    wra_00041:'Amount to be refunded for WeChat payment',
    wra_00042: 'Amount to be refunded by Alipay',
    wra_00043: 'Refund amount for no top-up orders',
    
    wra_00044:'Refund acceptance result',
    wra_00045:'System refunded automatically (WeChat payment)',
    wra_00046:'System automatic refund (Alipay)',
    wra_00047: 'Admin a manual refund',
    
    wra_00048:'Reason for incomplete refund',
    wra_00049:'None',

    wra_00050:'Have you made a refund on the WeChat Merchant Platform?' ,
    wra_00051:'Modified',
    wra_00052:'Operation successful, waiting for WeChat result notification',
    wra_00053:'Waiting for WeChat result notification',
    wra_00054:'Application successful, waiting for WeChat result notification',
    wra_00055:'Automatic refund could not be completed due to {cause}',
    wra_00056:'System reason',
    wra_00057:'Waiting for WeChat result notification',
    wra_00058:'Certificate has expired and cannot be refunded automatically',
    wra_00059:'Order overdue for automatic refund',
    wra_00060:'Insufficient refund balance available for merchant',
    wra_00061:'A partial refund has been made and cannot be cancelled',
    wra_00062:'Modified successfully',

    wra_00063:'Refund in progress...',

    wra_00064:'the time range exceeds 31 days, select a new one'

}