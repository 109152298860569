export default {
  orderClassify_00001: "Related Requests Category",
  orderClassify_00002: "List of requests",
  orderClassify_00003: "New Category",
  orderClassify_00004: "Category Name",
  orderClassify_00005: "Category Parent",
  orderClassify_00006: "Category Level",
  orderClassify_00007: "Top Level",
  orderClassify_00008: "Second Level",
  orderClassify_00009: "Default Request Category Handler",
  orderClassify_00010: "Default Request Category Followers",
  orderClassify_00011: "Operation",
  orderClassify_00012: "Create a new subclass",
  orderClassify_00013: "Edit",
  orderClassify_00014: "Delete",
  orderClassify_00015: "None",
  orderClassify_00016: "Cannot recover after deleting a category",
  orderClassify_00017: "The current category cannot be deleted",
  orderClassify_00018: "Top level classification",
  orderClassify_00019: "Category Name",
  orderClassify_00020: "Request Category template settings",
  orderClassify_00021: "Problem Description",
  orderClassify_00022: "Default Handler",
  orderClassify_00023: "Default Followers",
  orderClassify_00024: "All",
  orderClassify_00025: "Edit Category",
  orderClassify_00026: "Please fill in the classification name",
  orderClassify_00027: "Please select the respondent of the issue",
  orderClassify_00028: "Please select a follower",
  orderClassify_00029: "New success",
  orderClassify_00030: "Modified successfully",
  orderClassify_00031: "Cannot be deleted",
}