import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import resources from './langs/static'
import {isOpenInCurrentPage} from "../utils/permission";
import constants from "../utils/constants";
//element-ui语言包(增加语言包需要扩展) 
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import enLocale from 'element-ui/lib/locale/lang/en'
import deLocale from 'element-ui/lib/locale/lang/de'
import esLocale from 'element-ui/lib/locale/lang/es'
//dayjs 语言包(增加语言包需要扩展)
import dayjs from 'dayjs';
import dayjsZh from 'dayjs/locale/zh-cn';
import dayjsDe from 'dayjs/locale/de';
import dayjsEs from 'dayjs/locale/es';

import i18n_cn from "./zh-cn.js"
import i18n_en from "./en.js"
import i18n_de from "./de.js"
import i18n_es from './es.js'


window.product_area = constants.product_area;
Vue.prototype.isOpenInCurrentPage = isOpenInCurrentPage;
Vue.use(VueI18n)


var lang = window.localStorage.lang;//切换语言存localStorage,每次优先从localStorage取
if (!lang) {
    var language = navigator.language.slice(0,2);
    if (language === "ch" || language === "en" || language === "de" || language === "es") {
        lang = language;
    } else {
        var product = constants.product_area;
        if (product == "eu") {
            lang = "en";
        } else {
            lang = "zh";
        }
    }
}

const path_href = window.location.href;
const path_indexof = path_href.lastIndexOf("/");
const path_indexText = path_href.substring(path_indexof + 1);
const path_htmlBeforeText = path_indexText.indexOf(".html");
let pathUrl = path_indexText.substring(0, path_htmlBeforeText);

let pathRoute,messages;
if(pathUrl === 'login'){
    let pathRoute_cn = require(`@/pages/login/login_cn`);
    let pathRoute_en = require(`@/pages/login/login_en`);
    let pathRoute_de = require(`@/pages/login/login_de`);
    let pathRoute_es = require(`@/pages/login/login_es`);
    messages = {
        'zh': Object.assign(Object.assign(i18n_cn, zhLocale),resources.cn,pathRoute_cn.default),
        'en': Object.assign(Object.assign(i18n_en, enLocale),resources.en,pathRoute_en.default),
        'de': Object.assign(Object.assign(i18n_de, deLocale),resources.en,pathRoute_de.default),
        'es': Object.assign(Object.assign(i18n_es, esLocale),resources.en,pathRoute_es.default),
    }
}else{
    switch (lang){
        case "zh":
            pathRoute = require(`@/pages/${pathUrl}/${pathUrl}_cn`);
            messages = {
                'zh': Object.assign(Object.assign(i18n_cn, zhLocale),resources.cn,pathRoute.default)
            }
            break;
        case "en":
            pathRoute = require(`@/pages/${pathUrl}/${pathUrl}_en`);
            messages = {
                'en': Object.assign(Object.assign(i18n_en, enLocale),resources.en,pathRoute.default),
            }
            break;
        case "de":
            pathRoute = require(`@/pages/${pathUrl}/${pathUrl}_de`);
            messages = {
                'de': Object.assign(Object.assign(i18n_de, deLocale),resources.en,pathRoute.default),
            }
            break;
        case "es":
            pathRoute = require(`@/pages/${pathUrl}/${pathUrl}_es`);
            messages = {
                'es': Object.assign(Object.assign(i18n_es, esLocale),resources.en,pathRoute.default),
            }
            break;
    }
}
//dayjs语言包数据定义(默认使用英文，增加其他语言需要扩展)
const dayjslocals = {
    'zh': dayjsZh,
    'de': dayjsDe,
    'es': dayjsEs,
}
const format = {
    timeFormat:{
        'zh-cn': i18n_cn.common_00003,
        'en': i18n_en.common_00003
    },
    dateFormat:{
        'zh-cn': i18n_cn.common_00002,
        'en': i18n_en.common_00002
    },
}
window.format = format;
const i18n = new VueI18n({
    locale: lang, // set locale
    messages, // set locale messages
})
ElementLocale.use(i18n.messages[i18n.locale]);
if(dayjslocals[lang]){
    dayjs.locale(dayjslocals[lang])
}else{
    dayjs.locale('en')
}
onkeydown = (e)=> {
    if (e.ctrlKey && e.keyCode == 13) {
        if(lang === 'zh'){
            window.localStorage.lang = "default";
            window.location.reload();
        }else{
            window.localStorage.lang = "zh";
            window.location.reload();
        }
        
    }
    
}
export default i18n