
export default {
  charge_00001: "{num} 张",
  charge_00002: "您可以在该组中新建离线卡|该组的离线卡需要配置到桩上才能使用",
  charge_00003: "运营商充电卡管理",
  charge_00004: "充电卡组名称",
  charge_00005: "充电卡组列表",
  charge_00006: "新建充电卡组",
  charge_00007: "卡组类型",
  charge_00008: "在线卡组",
  charge_00009: "离线卡组",
  charge_00010: "超级卡组",
  charge_00011: "充电卡数量",
  charge_00012: "查询订单",
  charge_00013: "操作",
  charge_00014: "可以在「充电卡管理」充电卡群组的「详情」中进行如下操作:",
  charge_00015: "充电卡组群名称",
  charge_00016: "(例如：国宾大厦内部员工)",
  charge_00017: "给充电卡群组起一个名称",
  charge_00018: "在线卡组",
  charge_00019: "您可以在该组中新建在线卡",
  charge_00020: "离线卡组",
  charge_00021: "1、修改充电卡群组名称；",
  charge_00022: "充电卡组新建成功",
  charge_00023: "2、制作NFC配置卡；",
  charge_00024: "3、添加NFC充电卡；",
  charge_00025: "完成",
  charge_00026: "请输入充电卡群组名称",
}