export default {
  depotMap_00001: "Schnellansicht von Standort", 
  depotMap_00002: "Aktueller Ladenutzer",
  depotMap_00003: "Gesamtleistung",
  depotMap_00004: "Kosten(€)",
  depotMap_00005: "Elektrizität(kWh)",
  depotMap_00006: "Alle anzeigen",
  depotMap_00007: "Gesamtleistung(kW)",
  depotMap_00008: "WeChat-Benutzer",
  depotMap_00009: "Offline Start/Stop Karte",
  depotMap_000010: "Offline-Speicherwertkarte",
  depotMap_000011: "Online-Ausweis",
  depotMap_000012: "Verwalter",
  depotMap_000013: "Benutzer von Dritten",
  depotMap_000014: "Kreditkarte",
  depotMap_000015: "Leerlaufladen",
  depotMap_000016: "Benutzen Aufladestation",
  depotMap_000017: "Abnormale Ladeöffnung",
}