export default {
  weChatApp_00001: "Wechart Official accounts",
  weChatApp_00002: "WeChat Payment",
  weChatApp_00003: "Official account Information",
  weChatApp_00004: "Official account name",
  weChatApp_00005: "Official account photo profile ",
  weChatApp_00006: "Site display rules",
  weChatApp_00007: "Displaying third-party sites in the official account",
  weChatApp_00008: "Share my site to other platforms",
  weChatApp_00009: "WeChat Official account bottom menu settings ",
  weChatApp_000010: "Enter the WeChat official platform, add a menu in the 'Custom Menu', select 'Jump to Web' for the menu content, and copy the URL address below into the 'Page Address'.",
  weChatApp_000011: "Find charging site address",
  weChatApp_000012: "Personal Center Address",
  weChatApp_000013: "Go to WeChat Public Platform",
  weChatApp_000014: "WeChat payment access guidelines",
  weChatApp_000015: "Follow the guidelines to complete the relevant operations to use WeChat payment service.",
  weChatApp_000016: "WeChat payment merchant number payment authorization directory setting",
  weChatApp_000017: "Go to WeChat Merchant Platform",
  weChatApp_000018: "Go to the WeChat Payments Merchant Platform, and copy and add the address below to the 'Payment Authorization Directory' of 'Public Payments' in the 'Development Configuration' of 'Product Center'. Note: Select 'https://' in the drop-down list.",
  weChatApp_000019: "Authorisation directory address",
  weChatApp_000020: "Copy Link",
  weChatApp_000021: "WeChat merchant platform payment merchant number setting",
  weChatApp_000022: "Enter the WeChat merchant platform and add 'WeChat Pay Merchant Number' to 'Basic Account Information' under 'Merchant Information' in 'Account Center'.",
  weChatApp_000023: "WeChat payment merchant number",
  weChatApp_000024: "WeChat merchant platform API secret key setting",
  weChatApp_000025: "Enter the WeChat Merchant Platform and copy the delegated secret key into the 'Secret Key Settings' of 'API Security' in the 'Account Center'.",
  weChatApp_000026: "Modify secret key",
  weChatApp_000027: "Generate a new secret key",
  weChatApp_000028: "After generating a new secret key, you must enter the WeChat Merchant Platform, copy the secret key generated below into the 'Secret Key Settings' of 'API Secret Key' in 'Account Center', and then return to the platform to save the secret key in order to ensure normal collection.",
  weChatApp_000029: "Upload certificate",
  weChatApp_000030: "Automatic refund function can be enabled after uploading the certificate",
  // ***** weChatApp_000031
  // ；<br/>如果看到的是【申请证书】按钮，则参考<a href='http://kf.qq.com/faq/161222NneAJf161222U7fARv.html' target=_blank>该微信文档</a>申请证书后，在本页面内上传 .p12 格式的证书文件。
  weChatApp_000031: "Please login to WeChat Payment Merchant Platform, click [Account Center] -> [Account Settings] -> [API Security], if you see the [Download Certificate] button, download the certificate file directly and upload the certificate in this page.",
  weChatApp_000032: "Certificate uploaded",
  weChatApp_000033: "Click to upload",
  weChatApp_000034: "Please make sure the certificate is the latest version (please follow notification of WeChat merchant platform)",
  weChatApp_000035: "XCharge will only use this certificate for refund operations." ,
  weChatApp_000036: "Special reminder: Payment certificates are confidential information, please use this function with caution." ,
  weChatApp_000037: "After the payment merchant number of WeChat merchant platform is set, you can set 'Yes' to show WeChat payment method in WeChat public number here, otherwise users cannot use WeChat payment in WeChat public number" ,
  weChatApp_000038: "WeChat Public News supports WeChat payment method",
  weChatApp_000039: "Please save the previous operation!" ,
  weChatApp_000040: "Copied - 'Charging Station' page address",
  weChatApp_000041: "Copied - 'Billing History' page address",
  weChatApp_000042: "Modified successfully, please update the Find Charging Station address in the menu at the bottom of the WeChat public page",
  weChatApp_000043: "Modified successfully",
  weChatApp_000044: "Copied - 'Payment Authorization Directory' page address",
  weChatApp_000045: "Copied authorize key",
  weChatApp_000046: "Copied - 'Authorize Key'",
  weChatApp_000047: "Confirm saving key",
  weChatApp_000048: "Special Note",
  weChatApp_000049: "If you save the changes directly without modifying the secret key on the WeChat Merchant Platform, it will cause the charging order to be unpayable, click 'Cancel' to abandon the save. If you confirm that the changes have been made, click 'Save' after the 30-second countdown." ,
  weChatApp_000050: "Click to upload",
  weChatApp_000051: "Upload",
  weChatApp_000052: "Must be a zip or p12 format file",
  weChatApp_000053: "Upload successful", 
  weChatApp_000054: "Sure to remove the certificate?" ,
  weChatApp_000055: "Reminder",
  weChatApp_000056: "No permission to operate",
  weChatApp_000057: "Upload failed",
  
}