export default {
	securityRealTimeProtection_00001: "Anzahl der Trigger Protection heute",
	securityRealTimeProtection_00002: "Anzahl der Trigger Protection in diesem Monat",
	securityRealTimeProtection_00003: "Anzahl der Ladestopps heute",
	securityRealTimeProtection_00004: "Anzahl der Ladestopps in diesem Monat",
	securityRealTimeProtection_00005: "Anzahl der heute abgelehnten Gebühren",
	securityRealTimeProtection_00006: "Anzahl der in diesem Monat abgelehnten Gebühren",
	securityRealTimeProtection_00007: "Ernsthafter Alarm({num}Heute)",
	securityRealTimeProtection_00008: "Warnalarm({num}Heute)",
	securityRealTimeProtection_00009: "Echtzeit-Schutzliste",
	securityRealTimeProtection_00010: "Ernsthafter Alarm",
	securityRealTimeProtection_00011: "Artikel",
	securityRealTimeProtection_00012: "Zeit",
	securityRealTimeProtection_00013: "Fahrzeugmodell",
	securityRealTimeProtection_00014: "Kennzeichen",
	securityRealTimeProtection_00015: "Station",
	securityRealTimeProtection_00016: "Ereignis",
	securityRealTimeProtection_00018: "[Echtzeit-Schutz] Laden stoppen",
	securityRealTimeProtection_00019: "[Echtzeitschutz] Leistungsreduktion",
	securityRealTimeProtection_00020: "[Frühwarnung] Unsicher",
	securityRealTimeProtection_00021: "[Frühwarnung] Teilsicherheit",
	securityRealTimeProtection_00022: "[Frühwarnung] Plattform verbietet Aufladung",
	securityRealTimeProtection_00023: "[Frühwarnung] Plattform Limit Charging",
	securityRealTimeProtection_00024: "Normal",
	securityRealTimeProtection_00025: "Warnalarm",
	securityRealTimeProtection_00026: "Ungearbeitete Erinnerung heute",
}