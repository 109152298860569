export default {
    login_00001: "登录运营商平台",
    login_00002: "登录账号",
    login_00003: "输入密码",
    login_00004: "登 录",
    login_00005: "用户名密码不能为空",
    login_00006: "· 运营商平台",
    login_00007: "我已注册，现在登录",
    login_00008: "登录",
    login_00009: "让共享充电更简单",
    login_00010: "针对不同运营者的业务布局，深入洞见客户需求，自创数字化充电运营全功能管理系统：",
    login_00011: "SaaS平台V3.0",
    login_00012: "欢迎，登录运营商平台",
    login_00013: "登录名",
    login_00014: "请输入登录名",
    login_00015: "密码",
    login_00016: "请输入密码",
    login_00017: "还没注册？",
    login_00018: "注册",
    login_00019: "加入充电运营联盟",
    login_00020: "隐私政策",
    login_00021: "已准备",
    login_00022: "注册需准备资料提示",
    login_00023: "加入充电运营联盟提示",
    login_00024: "自营微信公众号（需为服务号)",
    login_00025: "开通微信支付",
    login_00026: "加入联盟后，可以共享联盟内用户。",
    login_00027: "加入联盟后，可以拥有自己的运营商平台。",
    login_00028: "* 需准备上级运营商提供的邀请码",
    login_00029: "车主微信扫码即可启动充电",
    login_00030: "车主微信支付钱款直接进入微信支付商户平台账号",
    login_00031: "平台注册码",
    login_00032: "需向您对应的销售获取平台注册码",
    login_00033: "忘记密码？",
    login_00034: "需运营商平台注册账号点击登录，阅读并同意《用户服务协议》和《隐私政策》，才可以登录使用平台！",
    login_00035: "需您加盟的上级运营商平台注册账号点击登录，阅读并同意《用户服务协议》和《隐私政策》，才可以登录使用平台！",
}