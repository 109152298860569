export default {
  charging_package52:"ab den Einkaufstag {count}Tagen",
  convert_00001: 'Einlösecode',
  convert_00002: 'Marketing -Zentrum',
  convert_00003: 'Gutscheinverteilung',
  convert_00004: 'Einlösecode',
  convert_00005: 'Bitte geben Sie Einlösecode ein',
  convert_00006: 'Bitte geben Sie Gutscheinsname ein',
  convert_00007: 'Bitte geben Sie Verteilungskanal ein',
  convert_00008: 'Einlösecode-Status',
  convert_00009: 'alle',
  convert_00010: 'nicht eingelöst',
  convert_00011: 'eingelöst',
  convert_00012: 'abgelauft',
  convert_00013: 'Gutscheintypen',
  convert_00014: 'alle',
  convert_00015: 'Gutschein',
  convert_00016: 'Elektrizitätspaket',
  convert_00017: 'zurücksetzen',
  convert_00018: 'suchen',
  convert_00019: 'Gutscheinliste',
  convert_00020: 'Einlösecode generieren',
  convert_00021: 'Daten exportieren',
  convert_00022: 'Einlösecode',
  convert_00023: 'Exportzeit',
  convert_00024: 'Gültigekeitsdauer',
  convert_00025: 'Dauerhaft',
  convert_00026: 'Verteilungskanal',
  convert_00027: 'Status',
  convert_00028: 'Gutscheinstyp',
  convert_00029: 'Gutscheinsname',
  convert_00030: 'Gutscheinseinzelheit',
  convert_00031: 'Gutschein generieren',
  convert_00032: 'Elektrizitätpaket',
  convert_00033: 'Gutschein',
  convert_00034: 'generierte Menge',
  convert_00035: 'Max. 10000 zu generieren',
  convert_00036: 'Ablaufsdatum',
  convert_00037: 'Kein',
  convert_00038: 'Ja',
  convert_00039: 'Ablaufsdatum',
  convert_00040: 'Vertelungskanal',
  convert_00041: 'nicht mehr als 20 chinesische Zeichen',
  convert_00042: 'abbrechen',
  convert_00043: 'generieren',
  convert_00044: 'Bei dieser Aktion wird eine Excel-Datei mit den Einlösecodes heruntergeladen, möchten Sie fortfahren?',
  convert_00045: 'Hinweise',
  convert_00046: 'abbrechen',
  convert_00047: 'Herunterladen starten',
  convert_00048: 'Bitte wählen Sie zu generierende Gutscheine aus',
  convert_00049: 'Bitte geben Sie generierte Menge ein',
  convert_00050: 'Bitte geben Sie Zahl für generierte Menge ein',
  convert_00051: 'Bitte geben Sie Verteilungskanal ein',
  convert_00052: 'Bitte geben Sie Ablaufsdatum ein',
  convert_00053: 'Max. 10000 zu generieren',
  convert_00054: 'Bitte geben Sie richrige generierte Menge ein.',
  convert_00055: 'Kanal kann 20 chinesiche Zeichen nicht überschreiten!',
  convert_00056: 'Gutschein wird erfolgreich generiert!',
  convert_00057: 'Elektrizitätspaket',
  convert_00058: 'Gutschein',
  convert_00059: 'Einlösecode generieren',
  convert_00060: 'Aktion',
  convert_00061: 'Aktion abbrechen',
}