export default {
    vehicleChargingLog_00001: "please enter the Plate number",
    vehicleChargingLog_00002: "start date",
    vehicleChargingLog_00003: "end date",
    vehicleChargingLog_00004: "Plate number",
    vehicleChargingLog_00005: "vehicle type",
    vehicleChargingLog_00006: "vehicle {tag}",
    vehicleChargingLog_00007: "owner",
    vehicleChargingLog_00008: "mobile",
    vehicleChargingLog_00009: "start time",
    vehicleChargingLog_00010: "end time",
    vehicleChargingLog_00011: "order number",
    vehicleChargingLog_00012: "station",
    vehicleChargingLog_00013: "port",
    vehicleChargingLog_00014: "log details",
    vehicleChargingLog_00015: "details",
    vehicleChargingLog_00016: "date range cannot exceed 90 days, please select again!",
    vehicleChargingLog_00018: "please select a date!",
    vehicleChargingLog_00019: "Vehicle fault",
    vehicleChargingLog_00020: "enter the vehicle VIN",
    vehicleChargingLog_00021: "Brand|Models",
    }