export default {
  configInfo_00001: 'Intelligentes Ladesystem',
    configInfo_00002: 'C1 (4,3"-Bildschirm) Informationen zur Bildschirmkonfiguration des Ladegeräts',
    configInfo_00003: 'C2 (7"-Bildschirm) Informationen zur Bildschirmkonfiguration des Ladegeräts',
    configInfo_00004: 'Bild hochladen',
    configInfo_00005: 'Ziehen Sie die Datei hierher, oder klicken Sie zum Hochladen',
    configInfo_00006: 'Es werden nur Bilder im PNG-Format Größe {area}px, nicht mehr als {size}kb unterstützt',
    configInfo_00007: 'Bild ändern',
    configInfo_00008: 'Das von Ihnen ausgewählte Bild ist zu groß, bitte wählen Sie neu aus.',
    configInfo_00009: 'Bitte wählen Sie ein Bild im png-Format zum Hochladen.',
    configInfo_00010: 'Bitte laden Sie ein {0}px Bild hoch.',
    configInfo_00011: 'Bitte laden Sie ein Bild hoch.',
    configInfo_00012: 'Erfolgreich geändert',
    configInfo_00013: 'Wechat-Scancode zum Ladestart',
    configInfo_00014: 'Bitte scannen Sie den QR-Code auf der linken Seite mit Wechat und folgen Sie den Anweisungen für den nächsten Schritt',
    configInfo_00015: 'Nicht mehr als 24 Zeichen',
    configInfo_00016: 'Anzeige nach dem Aufwachen',
    configInfo_00017: 'Anzeige nach Scannen',
    configInfo_00018: 'Anzeige nach Ziehen',
    configInfo_00019: 'Anzeige nach Scannen und Ziehen',
    configInfo_00020: 'Anzeige im Leerlauf',
    configInfo_00021: 'Mehrere Bilddrehungen oder mp4-Videomethode, wählen Sie eine',
    configInfo_00022: 'Bilddrehung',
    configInfo_00023: 'Klicken hochzuladen',
    configInfo_00024: 'Nur Bilder im PNG- und JPG-Format, Größe {area}px, nicht mehr als {size}kb unterstützen',
    configInfo_00025: 'Video verwenden',
    configInfo_00026: '5 Sekunde',
    configInfo_00027: '10 Sekunde',
    configInfo_00028: '15 Sekunde',
    configInfo_00029: 'Bis zu 6 Bilder hochzuladen',
    configInfo_00030: 'Bitte wählen Sie das Format png oder jpg  zum Hochladen',
    configInfo_00031: 'Bis zu 1 Video hochladen',
    configInfo_00032: 'Das Video, das Sie ausgewählt haben, ist zu groß, bitte wählen Sie es erneut aus',
    configInfo_00033: 'Bitte laden Sie Bilder oder Video hoch',
    configInfo_00034: 'Spielzeit',
    configInfo_00035: 'Nur Videos im mp4-Format unterstützen, Größe {vsize} MB nicht überschreiten (empfohlene Auflösung max. {varea} Pixel, Bildrate weniger als 30fps.)',
    configInfo_00036: 'Bild oder Video hochladen',
    configInfo_00037: 'Bild verwenden',
    configInfo_00038: 'Nur Bilder im Format PNG, JPG, GIF werden unterstützt, Größe {area}px, nicht mehr als {size}kb.',
    configInfo_00039: 'Video verwenden',
    configInfo_00040: 'Bitte wählen Sie mp4-Video-Upload',
    configInfo_00041: 'Bitte laden Sie ein Video hoch',
    configInfo_00042: 'Anzeige löschen',
    configInfo_00043: 'Bestätigen Sie Werbungen von diese artige Ladesäule in der Ladestatiohn zu löschen?',
    configInfo_00044: 'Es ist nach Löschen nicht wiederholbar, möchten Sie fortfahren?',
    configInfo_00045: 'bestätigen',
    configInfo_00046: 'abbrechen',
    configInfo_00047: 'alle ankreutzen',
    configInfo_00048: 'Ladesäulesname',
    configInfo_00049: 'Gerätsnummer',

    configInfo_00050: 'X-CHARGE智充',
    configInfo_00051: 'Willkommen zum intelligenten DC-Ladesystem',
    configInfo_00052: 'Bitte verwenden Sie WeChat, um den Code zum Laden zu scannen, oder verwenden Sie NFC, um die Karte zum Laden durchzuziehen',
    configInfo_00053: 'Berührungstaste zur Auswahl des Ladeanschlusses verwenden',
    configInfo_00054: 'C401 Bildschirm-Konfigurationsinformationen',
    configInfo_00055: 'C6 Bildschirm-Konfigurationsinformationen',
    configInfo_00056: 'C6s Bildschirm-Konfigurationsinformationen',

    configInfo_00057: 'Das oberste Logo kann entweder im Text- oder im Bildformat sein.',
    configInfo_00058: 'Text verwenden',
    configInfo_00059: 'Bitte Inhalt eingeben',
    configInfo_00060: '{num} Wörter oder weniger (einschließlich Interpunktion)',
    configInfo_00061: 'Bitte füllen Sie den oberen Bildschirmtext aus oder laden Sie ein Bild hoch',
    configInfo_00062: 'Der Text auf dem oberen Bildschirm ist zu lang',
    configInfo_00063: "Bitte wählen Sie zuerst 'Bild verwenden' aus",
    configInfo_00064: "Bitte speichern Sie die vorherige Aktion",
    configInfo_00065: "Nicht mehr als {num} Zeichen",
    configInfo_00066: "Nicht mehr als 10 Zeichen",
    configInfo_00067: "Parameter ist leer",
    configInfo_00068: "Der für den Parameter eingegebene Text kann nicht mehr als 7 Ziffern leer sein",
    configInfo_00069: "Der Titel des Sweep-Bildschirms darf nicht mehr als 10 Ziffern haben",
    configInfo_00070: "Parameterlänge ist zu lang",
    configInfo_00071: "Bitte scannen Sie den Code zum Laden oder verwenden Sie die NFC-Karte.",
    configInfo_00072: "Insgesamt wurden {allDevice} Geräte gepusht, {sucDevice} erfolgreich, {faiDevice} fehlgeschlagen",
    configInfo_00073: "Gerätsname",
    configInfo_00074: "Push-Ergebnis",

    // ***********
    configInfo_00075: "Ladestationseinzelheit",
    configInfo_00076: "Bildschirmkonfiguration",
    configInfo_00077: "Konfigurationsinformation",
    configInfo_00078: "Start Logo",
    configInfo_00079: "ändern",
    configInfo_00080: "Obere Log", 
    configInfo_00081: "Bildschirmtext",
    configInfo_00082: "Willkommen zu nutzen",
    configInfo_00083: "Bitte scannen zu laden",
    configInfo_00084: "Willkommen zu nutzen",
    configInfo_00085: "Sweep-Bildschirmtitel",
    configInfo_00086: "speichern",
    configInfo_00087: "Nicht mehr als 16 Zeichen",
    configInfo_00088: "C2 Bildschirm Konfigurationsinformationen",
    configInfo_00089: "Scannenseite-text",
    configInfo_00090: "Text oben",
    configInfo_00091: "Willkommens-Text-Kopfzeile",
    configInfo_00092: "Willkommenstext Inhalt",
    configInfo_00093: "Hinweisetext unten",
    configInfo_00094: "Oberes Logo ändern",
    configInfo_00095: "löschen",
    configInfo_00096: "Werbungsinfo",
    configInfo_00097: "Gerätsliste",
    configInfo_00098: "Bearbeiten Sie die Konfigurationsinformationen, ankreuzen Sie die Ladesäule und pushen Sie sie an die Ladesäule. Es wird empfohlen, die Konfigurationsinformationen nach jeder Änderung zu aktualisieren, um sicherzustellen, dass die Ladesäule ordnungsgemäß funktioniert.",
    configInfo_00099: "Push-Konfiguration",
    configInfo_00100: "Alle-Push",
    configInfo_00101: "Erfolg",
    configInfo_00102: "Fehlgeschlagen",
    configInfo_00103: "Pushing",
    configInfo_00104: "Schließen",
    configInfo_00114: "C1 7'-Bildschirm",
    configInfo_00113: "C2 Bildschirm",
    configInfo_00112: "C401 Bildschirm",
    configInfo_00111: "C6 Bildschirm",
    configInfo_00110: "C6s Bildschirm",
    configInfo_00115: "C1 4.3'-Bildschirm",
    configInfo_00117: "C9Pro Bildschirm",
    modify_success: "Erfolgrech geändert",
}