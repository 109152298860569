export default {
  teamStatistics_00001: '历史统计',
  teamStatistics_00002: '组队打榜',
  teamStatistics_00003: '参与队伍',
  teamStatistics_00004: '队伍详情',
  teamStatistics_00005: '{0} 第 {1} 周',
  teamStatistics_00006: '',
  teamStatistics_00007: '请输入用户昵称或手机号',
  teamStatistics_00008: '',
  teamStatistics_00009: '',
}