<template>
  <div class="step_one">
    <div class="search">
      <el-input v-model.trim="keywords" :placeholder="$t('registerEmail_00007')" :class="['inp', {'w216':product=='cn'}]" size="medium" @keyup.enter="searchWechat"></el-input>
      <el-button type="primary" size="medium" @click="searchWechat">{{$t('registerEmail_00006')}}</el-button>
    </div>
    <!-- 查询结果 -->
    <div class="result-box">
      <div class="search-result" v-if="resultFlag==true">
        <div class="center-info">
          <div class="icon-state"><i class="el-icon-circle-check"></i></div>
          <!-- 头像 -->
          <div class="icon-img">
            <img :src="dealer.wechatOA&&dealer.wechatOA.imageUrl">
          </div>
          <div class="name-info">
            <p>{{dealer.title}}</p>
            <p>{{$t('registerEmail_00009')}}：{{dealer.currencyType==null?'':$t(dealer.currencyType.split(',')[0])}}</p>
            <!-- <p>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">{{dealer.wechatOA==null?'':dealer.wechatOA.title}}</div>
                <span>{{$t('registerEmail_00027')}}: {{dealer.wechatOA==null?'':dealer.wechatOA.title}}</span>
              </el-tooltip>
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="read" v-if="operatorName=='智充'">
      <el-checkbox-group v-model="check">
        <el-checkbox name="check">{{$t("registerEmail_20051")}}</el-checkbox>
      </el-checkbox-group>
      <a v-if="product!='cn'" href="https://www.xcharge.com/statement" target="_blank" class="agreement">{{$t("common_45010")}}</a>
      <a v-else href="./saasAgreement.html" target="_blank" class="agreement">{{$t("registerEmail_20052")}}</a>
    </div>
    <div class="add-btn" v-if="resultFlag==true">
      <el-button type="primary" @click="userOA">{{$t('registerEmail_00010')}}</el-button>
    </div>
  </div>
</template>

<script>
import {
  requestUrl,
  ajax,
  checkLogin,
  getQueryString,
} from "@/assets/utils/network";
import {operator} from "@/assets/utils/constants";

export default {
  props: ["product"],
  data() {
    return {
      keywords: "",
      dealer: {},
      resultFlag: false,
      codeId: "",
      check: false,
      operatorName: "",
    };
  },
  methods: {
    // 查询运营商
    searchWechat() {
      if(!this.check){
        this.$message.error(this.$t('registerEmail_10058'));
        return;
      }
      this.codeId = this.keywords;
      this.resultFlag = false;
      var om = this;
      if (!isNaN(this.keywords) && (this.keywords + "").length == 8) {
        ajax({
          type: "GET",
          url: requestUrl + `/dealer/level1?code=` + om.keywords,
          dataType: "json",
          success: function (response) {
            var res = JSON.parse(response);
            if (res == null) {
              om.$message.error(om.$t("registerEmail_00026"));
              return;
            } else if (res.hasOwnProperty("error")) {
              var error = res.error;
              if (error.code == "IN_USE") {
                om.$message.error(om.$t("registerEmail_00028"));
              } else if (error.code == "NOT_EXISTS") {
                om.$message.error(om.$t("registerEmail_00029"));
              }
              return;
            } else if (res.result[0] == null) {
              om.$message.error(om.$t("registerEmail_00029"));
            } else {
              om.dealer = res.result[0];
              om.resultFlag = true;
            }
          },
        });
      } else {
        om.$message.error(om.$t("registerEmail_00007"));
        return false;
      }
    },
    // 加入运营商
    userOA() {
      if (this.dealer == null || this.dealer == "") {
        this.$message.error(this.$t("registerEmail_00035"));
        return false;
      }
      if(!this.check){
        this.$message.error(this.$t('registerEmail_10058'));
        return;
      }
      var obj = new Object();
      obj.codeId = this.codeId;
      obj.parentId = this.dealer.id;
      this.$emit("next", { codeInfo: obj, stepActive: "1", tag: "search" });
    },
  },
  mounted() { 
    this.operatorName = operator;
    if (this.operatorName != '智充') this.check = true;
  }
};
</script>

<style lang="scss" scoped>
.step_one {
  margin-top: 20%;
  .search {
    // border-bottom: 1px solid #e6e7ec;
    .inp {
      &.w216{
        width: 216px;
      }
      width: 406px;
      margin: 0 20px 20px 0;
    }
  }
  // 公众号查询结果
  & .search-result {
    height: 100px;
    padding-top: 10px;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid #ececec;
    box-sizing: border-box;
    background: #fafdff;
    & .center-info {
      height: 80px;
      p {
        margin: 0;
        line-height: 16px;
      }
      & .icon-state {
        float: left;
        width: 20px;
        height: 80px;
        line-height: 80px;
        color: #2bdc9c;
        font-size: 18px;
        margin-left: 30px;
      }
      & .icon-img {
        float: left;
        width: 60px;
        height: 70px;
        padding-top: 10px;
        margin-left: 20px;
        & img {
          display: block;
          border: none;
          margin: 0;
          background: #999;
          width: 60px;
          height: 60px;
        }
      }
      & .name-info {
        float: left;
        // width: 180px;
        height: 70px;
        font-size: 14px;
        padding-top: 18px;
        margin-left: 10px;
        & p {
          width: inherit;
          height: 30px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          & span {
            display: block;
            width: 180px;
            height: 30px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          &:nth-child(2) {
            color: #999;
          }
        }
      }
    }
  }
  .read {
    margin-top: 22px;
  }
  /deep/.el-checkbox-group {
    display: inline-block;
    & + a {
      color: #ff4949;
      margin-left: 5px;
    }
  }
  // 加盟运营商按钮
  .add-btn {
    margin-top: 20px;
    text-align: center;
  }
}
</style>