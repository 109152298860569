export default {
    homePage_00001: 'Ladezeiten',
    homePage_00002: 'Bestellbetrag ({type})',
    homePage_00003: 'Service Beitrag ({type})',
    homePage_00004: 'Elektrizität ({type})',
    homePage_00005: 'CO2 Reduktion (kg)',
    homePage_00006: 'Anzahl der Nutzer',
    homePage_00007: 'Anstehende Aufgabe',
    homePage_00008: 'Status der Anlage',
    homePage_00009: 'Häufige Funktionen',
    homePage_00010: 'Betriebsvorschlage',
    homePage_00011: 'Nutzungsrate Gestern',
    homePage_00012: 'Nutzer-Ranking Gestern',
    homePage_00013: 'Trend von Betriebseinkommen',
    homePage_00014: 'Trend der Eletrizität',
    homePage_00015: 'Trend der Ladedauer',
    homePage_00016: 'Trend der Nutzeranzahl',
    homePage_00017: 'Heute',
    homePage_00018: 'Gestern',
    homePage_00019: 'Vergleich mit Vortag',
    homePage_00020: 'Erstattungsantrag',
    homePage_00021: 'Persönliche Quittungsantrag',
    homePage_00022: 'Gruppenquittung',
    homePage_00023: 'Zahlungsrechnung',
    homePage_00024: 'Anzahl der Ladestationen',
    homePage_00025: '',
    homePage_00026: 'DC Ladepunkt',
    homePage_00027: 'AC Ladepunkt',
    homePage_00028: 'Unbesetzte',
    homePage_00029: 'Besetzte',
    homePage_00030: 'Abnormale',
    homePage_00031: 'Echtzeit Überwachung',
    homePage_00032: 'Nutzer Management',
    homePage_00033: 'Ladevorgang',
    homePage_00034: 'Analyse des Einkommen',
    homePage_00035: 'Aufladen und Geschenke',
    homePage_00036: 'Abnormale Vorgänge',
    homePage_00037: 'Business Management',
    homePage_00038: 'Regierung Plattform',
    homePage_00039: '如何提升场站利用效率？',
    homePage_00040: 'Entgegennahme Aktivität',
    homePage_00041: '用户会员等级设置',
    homePage_00042: '如何获取新用户？',
    homePage_00043: '如何使用平台？',
    homePage_00044: 'AC',
    homePage_00045: 'DC',
    homePage_00049: 'Beitrag',
    homePage_00050: 'Elektrizität',
    homePage_00051: 'Letzte 7 Tage',
    homePage_00052: 'Letzte 30 Tage',
    homePage_00053: '京都充电站',
    homePage_00054: 'DC Ladepunkt',
    homePage_00055: 'Anzahl der Ladebenutzer',
    homePage_00056: 'Anzahl der Erstnutzer',
    homePage_00057: 'Gesamte Beitrag({type})',
    homePage_00058: 'Order amount({type})',
    homePage_00059: 'Service Beitrag({type})',
    homePage_00060: 'Ladezeit',
    homePage_00061: 'AC Ladepunkt',
    homePage_00062: '导流服务',
    homePage_00063: '邀请好友',
    homePage_00064: '设备广告投放',
    homePage_00065: 'Gruppengruppe',
    homePage_00066: 'Gruppen',
    homePage_00067: 'Not open yet',
    homePage_00080: '元',
    homePage_00081: 'Welcome {name}!',
    homePage_00082: 'Mitglied Stufen',
    homePage_00083: 'Umleitung Service',
    homePage_00084: 'Freunde Einladen',
    homePage_00085: 'Einkommensanalyse',
    homePage_00086: 'Benutzeranalyse',
    homePage_00087: 'Echtzeitüberwachung',
    homePage_00088: 'Fehlerbehandlung',
    homePage_00089: 'Strom ({type})',
    homePage_00090: 'Währung anzeigen',
    homePage_00091: "times",
    homePage_00092: "Bestellbetrag",
    homePage_00093: "Service Beitrag",
    homePage_00094: "Ladeleistung",
    homePage_00095: "CO2 Reduktion",
    homePage_00096: 'Strom',
    homePage_00097: 'Your platform version does not contain this function, please upgrade to advanced version or contact business',
    
}