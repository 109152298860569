export default {
    user_00001: "用户昵称、手机号、车牌号",
    user_00002: "用户",
    user_00003: "已关注",
    user_00004: "已取消关注",
    user_00005: "导出用户",
    user_00006: "此操作将下载用户的Excel文件, 是否继续?",
    user_00007: "用户列表",
    user_00008: "符合此分类条件的用户",
    user_00009: "操作",
    user_00010: "用户信息未授权",
}