export default {
    carDetail_00001: "Fahrzeug Einzelheiten",
    carDetail_00002: "Fahrzeug",
    carDetail_00003: "Fahrzeug löschen", 
    carDetail_00004: " Bei diesem Vorgang werden die Fahrzeuginformationen dauerhaft gelöscht. Möchten Sie fortfahren?",
    carDetail_00005: "Fahrzeugdaten",
    carDetail_00006: "Kontaktinformationen",
    carDetail_00007: "Kontaktname",
    carDetail_00008: "Gruppe Verwaltung",
    carDetail_00009: "Mitglieder exportieren", 
    carDetail_00010: "Gruppe löschen",
    carDetail_00011: "Keine Wiederherstellung nach Löschung von Gruppen",
    carDetail_00012: "Vorgänge suchen",
    carDetail_00013: "Bei diesem Vorgang lädt die Excel-Datei des Gruppenbenutzers herunter, ob er fortfahren soll?",
}