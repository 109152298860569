export default {
    revenueMonitoring_00001: "NZS battery",
    revenueMonitoring_00002: "Grid",
    revenueMonitoring_00003: "Charger Usage Power(kWh)",
    revenueMonitoring_00004: "Enery Value({unit})",
    revenueMonitoring_00005: "Charger usage revenue",
    revenueMonitoring_00006: "NZS Battery Charge Power(kWh)",
    revenueMonitoring_00007: "Analyze",
    revenueMonitoring_00008: "Charger from grid cost",
    revenueMonitoring_00009: "NZS battery from grid cost",
    revenueMonitoring_00010: "Enery Value",
    revenueMonitoring_00011: "Charger Usage",
    revenueMonitoring_00012: "Power(kWh)",
    revenueMonitoring_00013: "Enery Usage Revenue({unit})",
    revenueMonitoring_00014: "Enery value",
    revenueMonitoring_00015: "Time of Use",
    revenueMonitoring_00016: "Time-of-Use",
    revenueMonitoring_00017: "Peak (Sharp&Peak)",
    revenueMonitoring_00018: "Off-Peak",
    revenueMonitoring_00019: "Super Off-Peak",
    revenueMonitoring_00020: "Grid",
    revenueMonitoring_00021: "NZS battery",
    revenueMonitoring_00022: "Day",
    revenueMonitoring_00023: "Week",
    revenueMonitoring_00024: "Month",
    revenueMonitoring_00025: "Year",
    revenueMonitoring_00026: "Lifetime",
    revenueMonitoring_00027: "Grid exported revenue",
    revenueMonitoring_00028: "Self-Powered",
    revenueMonitoring_00029: "PV",
    revenueMonitoring_00030: "Total Grid interaction",
    revenueMonitoring_00031: "PV supplied energy",
    revenueMonitoring_00032: "PV",
    revenueMonitoring_00033: "NZS Battery Utilization Rate",
    revenueMonitoring_00034: "Charging Utilization Rate",
    revenueMonitoring_00035: "Discharging Utilization Rate",
    revenueMonitoring_00036: "NZS Battery Charged",
    revenueMonitoring_00037: "NZS Battery Discharged",
    revenueMonitoring_00038: "NZS Battery Installed Capacity",
    revenueMonitoring_00039: "Super-peak",
    revenueMonitoring_00040: "Peak ",
    revenueMonitoring_00041: "Off-peak",
    revenueMonitoring_00042: "Super-off-peak",
    revenueMonitoring_00043: "Grid cost",
    revenueMonitoring_00044: "Net grid imported",
    revenueMonitoring_00045: "To Grid",
    revenueMonitoring_00046: "Others",
    revenueMonitoring_00047: "Grid-Connected Energy",
    revenueMonitoring_00048: "Battery Charged Energy",
    revenueMonitoring_00049: "EV Charged Energy",



    energyOperationMonitoring_00037: "Jan",
    energyOperationMonitoring_00038: "Feb",
    energyOperationMonitoring_00039: "Mar",
    energyOperationMonitoring_00040: "Apr",
    energyOperationMonitoring_00041: "May",
    energyOperationMonitoring_00042: "Jun",
    energyOperationMonitoring_00043: "Jul",
    energyOperationMonitoring_00044: "Aug",
    energyOperationMonitoring_00045: "Sep",
    energyOperationMonitoring_00046: "Oct",
    energyOperationMonitoring_00047: "Nov",
    energyOperationMonitoring_00048: "Dec",
  
    energyOperationMonitoring_00049: "Mon",
    energyOperationMonitoring_00050: "Tue",
    energyOperationMonitoring_00051: "Wed",
    energyOperationMonitoring_00052: "Thu",
    energyOperationMonitoring_00053: "Fri",
    energyOperationMonitoring_00054: "Sat",
    energyOperationMonitoring_00055: "Sun",
   
} 