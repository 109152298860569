export default {
    joinOperator_00001: "Invite franchise",
    joinOperator_00002: "Create invitation",
    joinOperator_00003: "Sent invitation",
    joinOperator_00004: "search",
    joinOperator_00005: "Generate inviting code",
    joinOperator_00006: "Modify invitees",
    joinOperator_00007: "Invitation code/invitee",
    joinOperator_00008: "Inviting code",
    joinOperator_00009: "Generation time",
    joinOperator_000010: "Invitees",
    joinOperator_000011: "Status",
    joinOperator_000012: "Operation",
    joinOperator_000013: "Please enter the invite users",
    joinOperator_000014: "Necessary，no more than 20 words",
    joinOperator_000015: "Generate",
    joinOperator_000016: "Status",
    joinOperator_000017: "All",
    joinOperator_000018: "Not used",
    joinOperator_000019: "Used",
    joinOperator_000020: "Invitation code generated successfully",
    joinOperator_000021: "Server response timeout",
    joinOperator_000022: "Please save the previous operation!!",
    joinOperator_000023: "Please fill in the invitation object",
    joinOperator_000024: "The number of words entered should not exceed 20",
    joinOperator_000025: "Invitation list",
    joinOperator_000026: "",

    //---------已发送邀请-------------------------------
    screen_time: "Screening time",
    joinOperator_000027: "Start time",
    joinOperator_000028: "End time",
    joinOperator_000029: "CPO name/Invitation code",
    joinOperator_000030: "Search",
    joinOperator_000031: "CPO name",
    joinOperator_000032: "Create time",
    joinOperator_000033: "Contact name",
    joinOperator_000034: "Phone number",
    joinOperator_000035: "Invitation code",
    joinOperator_000036: "Email"
    
}