export default {
  deviceMonitor_00001: 'Occupying',
  deviceMonitor_00002: 'Charger is unavailable',
  deviceMonitor_00003: 'Charger is offline',
  deviceMonitor_00005: 'Change into available',
  deviceMonitor_00006: 'Start charging',
  deviceMonitor_00008: 'Reserved',
  deviceMonitor_00009: 'No faulty code',
  deviceMonitor_00010: "Charger reboot successfully",
  deviceMonitor_00011: "Remote reboot",
  deviceMonitor_00012: "Charger reboot failed",
  deviceMonitor_00013: "Charger management",
  deviceMonitor_00014: "Current faults",
  deviceMonitor_00015: "Fault handling",
  deviceMonitor_00016: "Enter charger name or SN",
  deviceMonitor_00017: "Select or enter station name",
  deviceMonitor_00018: "All",
  deviceMonitor_00019: "Charger model：",
  deviceMonitor_00020: "DC",
  deviceMonitor_00021: "AC",
  deviceMonitor_00022: "Gateway",
  deviceMonitor_00023: "Faulted",
  deviceMonitor_00024: "Offline",
  deviceMonitor_00025: "Available",
  deviceMonitor_00026: "View preparing only",
  deviceMonitor_00027: "Charging",
  deviceMonitor_00028: "View finishing only",
  deviceMonitor_00029: "Offline",
  deviceMonitor_00030: "Unavailable",
  deviceMonitor_00031: "SN：",
  deviceMonitor_00032: "Manufacture-model：",
  deviceMonitor_00033: "Station：",
  deviceMonitor_00034: "Reboot charger",
  deviceMonitor_00035: "Details",
  deviceMonitor_00036: "Connector：",
  deviceMonitor_00037: "Offline",
  deviceMonitor_00038: "Unavailable",
  deviceMonitor_00039: "Faulted",
  deviceMonitor_00040: "Occupying",
  deviceMonitor_00041: "Charging",
  deviceMonitor_00042: "Available",
  deviceMonitor_00043: "EV to be moved",
  deviceMonitor_00044: "Reserved",
  deviceMonitor_00045: "Preparing",
  deviceMonitor_00046: "Finishing",
  deviceMonitor_00047: "Transactions",
  deviceMonitor_00048: "Restart connector",
  deviceMonitor_00049: "Charger reboot command has been sent, the reboot process will take approximately 5 minutes. Please stay on this page and do not refresh the page",
  deviceMonitor_00050: "Remind info",
  deviceMonitor_00051: "Confirm",
  deviceMonitor_00052: "Charger management",
  deviceMonitor_00053: "Current fault",
  deviceMonitor_00054: "Plug in",
  deviceMonitor_00055: "Charging session end",
  deviceMonitor_00056: "Unavailable",
  deviceMonitor_00057: "Normal",
  deviceMonitor_00058: "Faulted",
  deviceMonitor_00059: "Locked",
  deviceMonitor_00060: "Standby",
  deviceMonitor_00061: "Unknown",
  deviceMonitor_00062: "Charger status：",
  deviceMonitor_00063: "Faulted",
  deviceMonitor_00064: "View unreturned only",
  deviceMonitor_00065: "Returned",
  deviceMonitor_00066: "Not homed",
  deviceMonitor_00067: "Faulted/Offline",
  deviceMonitor_00068: "NZS Battery",
  deviceMonitor_00085: "Dual Platform",
  deviceMonitor_00086: "Add charger",

  eqDetails_30001: "Charger recovery emergency stop state",
  eqDetails_30002: "Whether to perform automatic self-inspection after that",
  eqDetails_30003: "Hardware self-inspection",
  eqDetails_30004: "1. Check the status of the device hardware, which takes tens of seconds",
  eqDetails_30005: "2. This check requires that the firmware version number of the Android application for the charger be above 3.0.0.",
  eqDetails_30006: "3. This check is not supported by third-party devices.",
  eqDetails_30007: "Last inspection result",
  eqDetails_30008: "Start inspection",
  eqDetails_30009: "Please wait during self-inspection",
  eqDetails_30010: "Re-inspection",
  eqDetails_30011: "Inspect time",
  eqDetails_30012: "Status inspection",
  eqDetails_30013: "Hardware lifetime",
  eqDetails_30014: "Self-inspection items",
  eqDetails_30015: "Confirm and recover from emergency stop",
  eqDetails_30016: "The charger is in use, self-inspection is canceled, please operate after it is idle",
  eqDetails_30017: "The current charger is offline",
  eqDetails_30018: "Charger self-inspection timed out, please try again",
  eqDetails_30020: "Poorer",
  eqDetails_30021: "moderate",
  eqDetails_30022: "better",
  eqDetails_30023: "This inspection requires that the firmware version number of the Android application for the charger be 3.0.0 or higher",
  eqDetails_30024: "Last self-inspection result not found",
  eqDetails_30025: "Recovery immediately",
  eqDetails_30026: "Check the charger first, then consider recovery",
  eqDetails_30027: "Disclaimer",
  eqDetails_30028: "To ensure the safe operation of the charger, we recommend that you perform a hardware check before restoring the emergency stop button. If any hardware failure or safety hazard is found, please stop using the charger and contact our technical support team. We will provide a solution as soon as possible to ensure that your charger can operate safely and stably.",
  eqDetails_30029: "Please note that this disclaimer is intended to remind you that choosing to directly restore the emergency stop button without first determining the status of the charger may involve certain risks, and we will not be held responsible for any resulting consequences.",
  eqDetails_30030: "Confirm direct recovery",
  eqDetails_30031: "Self-check items",
  eqDetails_30032: "State",
  eqDetails_30033: "{msg},{code},self-inspection is canceled, Please operate after maintenance",

}