export default {
    carDetail_00001: "车辆详情",
    carDetail_00002: "车辆",
    carDetail_00003: "删除车辆",
    carDetail_00004: "此操作将永久删除该车辆信息, 是否继续?",
    carDetail_00005: "车辆信息",
    carDetail_00006: "联系人信息",
    carDetail_00007: "联系人姓名",
    carDetail_00008: "团体管理",
    carDetail_00009: "导出团体成员",
    carDetail_00010: "删除团体",
    carDetail_00011: "删除团体后不可恢复",
    carDetail_00012: "查询订单",
    carDetail_00013: "此操作将下载团体用户的Excel文件, 是否继续？",
}