<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :lock-scroll="true"
    :close-on-click-modal="false"
    :before-close="close"
    width="850px"
  >
    <div class="menu-mode">
      <el-button :class="['b-button',modeTypeVal == 1 ? 'active':'']" type="primary" plain round size="small" @click="changeMode(1)">{{$t('common_20017')}}</el-button>
      <el-button :class="['b-button',modeTypeVal == 2 ? 'active':'']" type="primary" plain round size="small"  @click="changeMode(2)">{{$t('common_20018')}}</el-button>
      <el-button :class="['b-button',modeTypeVal == 3 ? 'active':'']" type="primary" plain round size="small"  @click="changeMode(3)">{{$t('common_20019')}}</el-button>
      <el-button :class="['b-button',modeTypeVal == 4 ? 'active':'']" type="primary" plain round size="small"  @click="changeMode(4)">{{$t('common_70000')}}</el-button>
    </div>
    <div>
      <div class="mode-detail">
        <div class="mode-info">
          <p>{{$t('common_20048')}}：</p>
          <div class="mode-content">
          {{modeInfo[modeTypeVal-1].introduce}}
          </div>
        </div>
        <div class="mode-info">
          <p>{{$t('common_20049')}}：</p>
          <div class="mode-content">
          {{modeInfo[modeTypeVal-1].scene}}
          </div>
        </div>
      </div>
      <!-- <div class="mode-image">
        <img src="@/assets/img/d-max-en.png" v-if="modeTypeVal == 1"/>
        <img src="@/assets/img/d-eco-en.png" v-if="modeTypeVal == 2"/>
        <img src="@/assets/img/d-auto-en.png" v-if="modeTypeVal == 3"/>
        <img src="@/assets/img/c-en.png" v-if="modeTypeVal == 4"/>
      </div> -->
      <div class="mode-image" v-if="product == 'eu'">
        <img src="@/assets/img/d-max-en.png" v-if="modeTypeVal == 1"/>
        <img src="@/assets/img/d-eco-en.png" v-if="modeTypeVal == 2"/>
        <img src="@/assets/img/d-auto-en.png" v-if="modeTypeVal == 3"/>
        <img src="@/assets/img/c-en.png" v-if="modeTypeVal == 4"/>
      </div>
      <div class="mode-image" v-if="product == 'cn'">
        <img src="@/assets/img/d-max-zh.png" v-if="modeTypeVal == 1"/>
        <img src="@/assets/img/d-eco-zh.png" v-if="modeTypeVal == 2"/>
        <img src="@/assets/img/d-auto-zh.png" v-if="modeTypeVal == 3"/>
        <img src="@/assets/img/c-cn.png" v-if="modeTypeVal == 4"/>
      </div>
      <div class="mode-image" v-if="product == 'de'">
        <img src="@/assets/img/d-max-zh.png" v-if="modeTypeVal == 1"/>
        <img src="@/assets/img/d-eco-zh.png" v-if="modeTypeVal == 2"/>
        <img src="@/assets/img/d-auto-zh.png" v-if="modeTypeVal == 3"/>
        <img src="@/assets/img/c-en.png" v-if="modeTypeVal == 4"/>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    modeType:{
      type: Number,
      default:1
    },
  },
  watch: {
    modeType: {
      handler(val) {
        this.modeTypeVal = val || 1
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      modeTypeVal: "",
      title:this.$parent.$t('common_20057'),
      product:'cn',
      visible: true,
      modeInfo:[{
        introduce: this.$parent.$t('common_20042'),
        scene:  this.$parent.$t('common_20043')
      },{
        introduce: this.$parent.$t('common_20044'),
        scene: this.$parent.$t('common_20045'),
      },
      {
        introduce:this.$parent.$t('common_20046'),
        scene: this.$parent.$t('common_20047'),
      },
      {
        introduce:this.$parent.$t('common_70002'),
        scene: this.$parent.$t('common_70003'),
      },]
    };
  },
  created() {
    console.log(this.modeType)
    this.product = window.product_area;
    console.log(this.product)
  },

  methods:{
    changeMode (v) {
      this.modeTypeVal =Number(v)
    },
    close() {
      this.$emit("close");
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
}
/deep/.el-input__inner {
  height: 36px;
  line-height: 36px;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.m20 {
  margin: 0 20px;
}
.flex-container-left {
  margin-bottom: 20px;
  .tips {
    i {
      margin-right: 15px;
    }
    margin-bottom: 20px;
  }
}
.dialog-btn{
  display: block;
  width: 100%;
  text-align: right;
}
.mode-detail{
  .mode-info{
    display: flex;
    margin-bottom: 10px;
    p{
      min-width: 72px;
      flex-shrink: 0;
    }
    .mode-content{
      text-align: justify;
    }
  }
}
.menu-mode{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.b-button{
  &.active{
    background:#0A5ABF;
    color: #fff;
  }
}
.mode-image{
  img{
    width: 100%;
    height: 100%;
  }
  
}
</style>
