export default {
  versionCompare_00001: "功能对比",
  versionCompare_00002: "功能",
  versionCompare_00003: "基础版服务",
  versionCompare_00004: "简单实用，捕捉一切重要信息",
  versionCompare_00005: "高级版服务",
  versionCompare_00006: "最强功能，助力效率全面提升",
  versionCompare_00007: "微信公众号客户端",
  versionCompare_00008: "平台首页",
  versionCompare_00009: "整体概览",
  versionCompare_00010: "场站管理系统",
  versionCompare_00011: "充电订单管理",
  versionCompare_00012: "设备维保系统",
  versionCompare_00013: "微信用户管理",
  versionCompare_00014: "充电卡管理",
  versionCompare_00015: "刷充电卡充电",
  versionCompare_00016: "财务管理系统",
  versionCompare_00017: "用户客服",
  versionCompare_00018: "工单系统",
  versionCompare_00019: "行业CRM系统",
  versionCompare_00020: "用户营销系统",
  versionCompare_00021: "流量导流系统",
  versionCompare_00022: "数据分析系统",
  versionCompare_00023: "加盟商管理系统",
}