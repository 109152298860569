export default {
    charging_package52:"用户购买/兑换套餐之日起{day}日",
    convert_00001: '渠道兑换码',
    convert_00002: '营销中心',
    convert_00003: '卡券分发',
    convert_00004: '渠道兑换码',
    convert_00005: '请输入兑换码',
    convert_00006: '请输入卡券名称',
    convert_00007: '请输入分发渠道',
    convert_00008: '兑换码状态',
    convert_00009: '全部',
    convert_00010: '未兑换',
    convert_00011: '已兑换',
    convert_00012: '已失效',
    convert_00013: '卡券类别',
    convert_00014: '全部',
    convert_00015: '代金券',
    convert_00016: '电量包',
    convert_00017: '重置',
    convert_00018: '查询',
    convert_00019: '卡券列表',
    convert_00020: '生成兑换码',
    convert_00021: '导出数据',
    convert_00022: '兑换码',
    convert_00023: '生成时间',
    convert_00024: '有效期',
    convert_00025: '永久',
    convert_00026: '分发渠道',
    convert_00027: '状态',
    convert_00028: '兑换卡券类别',
    convert_00029: '兑换卡券名称',
    convert_00030: '卡券详情',
    convert_00031: '生成卡券',
    convert_00032: '电量包',
    convert_00033: '代金券',
    convert_00034: '生成数量',
    convert_00035: '最多生成10000',
    convert_00036: '失效日期',
    convert_00037: '无',
    convert_00038: '有',
    convert_00039: '失效日期',
    convert_00040: '分发渠道',
    convert_00041: '不超过20个汉字',
    convert_00042: '取消',
    convert_00043: '生成',
    convert_00044: '此操作将下载渠道兑换码数据的Excel文件，是否继续?',
    convert_00045: '提示',
    convert_00046: '取消',
    convert_00047: '开始下载',
    convert_00048: '请选择要生成的卡券',
    convert_00049: '请填写生成数量',
    convert_00050: '生成数量请输入数字',
    convert_00051: '请填写分发渠道',
    convert_00052: '请选择失效日期',
    convert_00053: '最多生成10000！',
    convert_00054: '请输入正确的生成数量！',
    convert_00055: '分发渠道不超过20个汉字！',
    convert_00056: '卡券生成成功！',
    convert_00057: '电量包',
    convert_00058: '代金券',
    convert_00059: '生成兑换码',
    convert_00060: '操作',
    convert_00061: '操作取消',
    convert_00062: '特权会员',
}
