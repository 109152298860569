export default {
    contrastCurve_00001: 'Charge Curves',
    contrastCurve_00002: 'Orders Centre',
    contrastCurve_00003: 'Full charge order',
    contrastCurve_00004: 'Order details',
    contrastCurve_00005: 'Charge Curves',
    contrastCurve_00006: 'OrderInfo',
    contrastCurve_00007: 'ChargeInfo',
    contrastCurve_00008: 'Current order',
    contrastCurve_00009: 'Pile orders for the period',
    contrastCurve_00010: 'Kwh',
    contrastCurve_00011: 'Degree',
    contrastCurve_00012: 'View order Details',
    contrastCurve_00013: 'Power',
    contrastCurve_00014: 'Voltage',
    contrastCurve_00015: 'Demand voltage',
    contrastCurve_00016: 'Electricity',
    contrastCurve_00017: 'Demand electricity',
    contrastCurve_00018: 'day',
    contrastCurve_00019: 'h',
    contrastCurve_00020: 'min',
    contrastCurve_00021: 'Group',
    contrastCurve_00022: 'App user',
    contrastCurve_00023: "Administrator",
    contrastCurve_00024: 'Start time',
    contrastCurve_00025: 'Stop time',
    contrastCurve_00026: 'Charge time',
    contrastCurve_00027: "Order number",
    contrastCurve_00028: "Wechat user",
    contrastCurve_00029: "Start/stop card",
    contrastCurve_00030: "Stored value card",
    contrastCurve_00031: "Identity card",
    contrastCurve_00032: "Smart charging configuration card",
    contrastCurve_00033: "Group configuration card",
    contrastCurve_00034: "Offline data reading card",
    contrastCurve_00035: "Backup data",
    contrastCurve_00036: "Charging curve",
    contrastCurve_00037: "Demand voltage at EV side (dashed line)",
    contrastCurve_00038: "EV-side demand current (dashed line)",
}