export default {
  userCharge_00001: "Gruppename",
  userCharge_00002: "Aufladerekord",
  userCharge_00003: "Basisinformation",
  userCharge_00004: "Aufladedatum",
  userCharge_00005: "Gesamtbetrag des Aufladens({0})",
  userCharge_00006: "Aufladenkapitel({0})",
  userCharge_00007: "Aufladensgeschenksbetrag({0})",
  userCharge_00008: "Zahlungsmethode",
  userCharge_00009: "操作用Operationsbenutzer户",
  userCharge_00010: "Guthaben({0})",
  userCharge_00011: "Aufladerekord",
  userCharge_00012: "Enthält Bonus {promotionBalance}{unit}",
  userCharge_00013: "bis",
  userCharge_00014: "Startdatum",
  userCharge_00015: "Endedatum",
  userCharge_00016: "Platform",
  userCharge_00017: "Wechat Publik(wechat)",
  userCharge_00018: "Wechat Publik(Alipay)",
  userCharge_00019: "Sonstige",
  userCharge_00020: "",
  userCharge_00021: "",
  userCharge_00022: "",
  userCharge_00023: "",
  userCharge_00024: "",
  userCharge_00025: "",
  userCharge_00026: "",
  userCharge_00027: "",
  userCharge_00028: "",
  userCharge_00029: "",
  userCharge_00030: "",
}