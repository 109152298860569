export default {
    analyzeUser_00001: "User",
    analyzeUser_00002: "User By Station",
    analyzeUser_00003: "Utilization analysis of Charger model",
    analyzeUser_00004: "User contribution analysis",
    analyzeUser_00005: "User RFM model ",
    analyzeUser_00006: "User number trends",
    analyzeUser_00007: "Analysis",
    analyzeUser_00008: "Analysis by electricity amount",
    analyzeUser_00009: "Analysis by duration",
    analyzeUser_00010: "Repeat users",
    analyzeUser_00011: "New users",
    analyzeUser_00012: "Comparison of stations by user",
    analyzeUser_00013: "User trends of selected stations",
    analyzeUser_00014: "Average revenue contribution per site",
    analyzeUser_00015: "Average charging amount per site",
    analyzeUser_00016: "Average charging duration per site",
    analyzeUser_00017: "Average chargign time per site",
    analyzeUser_00018: "Cumulative charging price (€）",
    analyzeUser_00019: "Name",
    analyzeUser_00020: "User type",
    analyzeUser_00021: "Card No.",
    analyzeUser_00022: "Last charging time",
    analyzeUser_00023: "Cumulative charging price (€)",
    analyzeUser_00024: "Charging count(time)",
    analyzeUser_00025: "Charging counts(time)",
    analyzeUser_00026: "Users whose last recharge was within 30 days",
    analyzeUser_00027: "Users whose last recharge was within 30-90 days",
    analyzeUser_00028: "Users whose last recharge was within 90-180 days",
    analyzeUser_00029: "Comparison of Franchisees by user",
    analyzeUser_00030: "User By Franchisee",
    analyzeUser_00031: "User trends of selected Franchisees",
    analyzeUser_00032: "Analyze the number of charging orders from user sources",
    analyzeUser_00033: "Charging orders",
    analyzeUser_00034: "Percentage of charging orders from user sources",
    analyzeUser_00035: "Share of each of the third-party flows",
    analyzeUser_00036: "Trends in the number of charging orders from user sources",
    analyzeUser_00037: "Trends in the number of third-party flow charging orders",
    analyzeUser_00038: 'Transaction',
    analyzeUser_00039: 'Transaction number per connector',
} 