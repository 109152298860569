export default {
    group_00001: "Bitte geben Sie einen Gruppennamen ein",
    group_00002: "Benutzer",
    group_00003: "Gruppenverwaltung",
    group_00004: "Gruppename",
    group_00005: "Zahlungsmethode",
    group_00006: "Guthaben({unit})",
    group_00007: "Max unbezahlter Betrag ({unit})",
    group_00008: "Maximal unbezahlte Vorgänge",
    group_00009: "Einzelne Zahlungen",
    group_00010: "Gruppenzahlung",
    group_00011: 'Geschenk {promotionBalance}{unit}',
    group_00012: 'Vorgänge anzeigen',
    group_00013: 'Gesamtzahl der Mitglieder',
    group_00014: 'Neue Gruppe erstellen',
    group_00015: 'Gruppenliste',
    group_00016: 'Name der Gruppe',
    group_00017: 'Geben Sie der Gruppe einen Namen',
    group_00018: "Max. unbezahlter Betrag",
    group_00019: "(Keine Abrechnung mehr, wenn der Gesamtbetrag der unbezahlten Abrechnungsbetrag den festgelegten Wert erreicht)",
    group_00020: "(keine Abrechnung mehr, wenn die Gesamtzahl der unbezahlten Abrechnungsbetrag den eingestellten Wert erreicht)",
    group_00021: "Speichern und neu erstellen",
    group_00022: "Maximale Anzahl von unbezahlten Vorgänge",
    group_00023: "Neue Gruppe erfolgreich erstellt!" ,
    group_00024: "Sie können in 'User Center', 'Gruppen' und 'Details' Folgendes tun:",
    group_00025: "1. den Gruppennamen ändern;",
    group_00026: "2. die Zahlungsmethode ändern;",
    group_00027: "3. den Betrag und die Anzahl der Begrenzungen des ungezahlten Vorgänge anpassen;",
    group_00028: "4. Laden Sie Ihr Online-Konto auf (nur wenn die Zahlungsmethode Gruppenkontozahlung ist);",
    group_00029: "Bitte vervollständigen Sie die Gruppeninformationen",
    group_00030: "Eingabefehler"
}