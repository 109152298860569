export default {
    joinOperator_00001: "加盟商邀请",
    joinOperator_00002: "创建邀请",
    joinOperator_00003: "已发送邀请",
    joinOperator_00004: "查询",
    joinOperator_00005: "生成邀请码",
    joinOperator_00006: "修改邀请对象",
    joinOperator_00007: "邀请码/邀请对象",
    joinOperator_00008: "邀请码",
    joinOperator_00009: "生成时间",
    joinOperator_000010: "邀请对象",
    joinOperator_000011: "状态",
    joinOperator_000012: "操作",
    joinOperator_000013: "请输入邀请对象",
    joinOperator_000014: "必填，不超过20个字",
    joinOperator_000015: "生成",
    joinOperator_000016: "状态",
    joinOperator_000017: "全部",
    joinOperator_000018: "未使用",
    joinOperator_000019: "已使用",
    joinOperator_000020: "生成邀请码成功",
    joinOperator_000021: "服务器响应超时",
    joinOperator_000022: "请保存上一步操作！！",
    joinOperator_000023: "请填写邀请对象",
    joinOperator_000024: "输入的字数不超过20个字",
    joinOperator_000025: "邀请列表",
    screen_time: "筛选时间",
    joinOperator_000027: "开始时间",
    joinOperator_000028: "结束时间",
    joinOperator_000029: "运营商名称/邀请码",
    joinOperator_000030: "查询",
    joinOperator_000031: "运营商名称",
    joinOperator_000032:"创建时间",
    joinOperator_000033:"联系人姓名",
    joinOperator_000034:"手机号",
    joinOperator_000035:"邀请码",
    joinOperator_000036:"邮箱"
    
}