<template lang="html">
  <el-dialog
    :title="title"
    :visible="flag"
    @close="closeFlag"
    width="600px">
    <div class="addCar">
      <div class="title">{{ product != 'cn' ? `${$t('common_00195')} (${$t('common_00326')})` : $t('common_00195')}}</div>
      
      <el-input v-model.trim="vehicle.licenseNo"  size="medium"/>
      <div class="title">{{ product != 'cn' ? `${$t('common_00325')} (${$t('common_00280')})` : $t('common_00196')}}</div>
      <el-input v-model.trim="vehicle.vehicleId"  size="medium"/>
      <div class="title">{{ product != 'cn' ? `${$t('common_00197')} (${$t('common_00326')})` : $t('common_00197')}}</div>
      <el-input v-model.trim="vehicle.mobile"  size="medium"/>
      <div class="title">{{$t('common_00198').split('|')[0]}}<span>{{$t('common_00198').split('|')[1]}}</span></div>
      <!-- <el-input v-model="vehicle.vehicleModel" /> -->
      <el-row class="brand">
        <el-col :span='24'>
          <el-select class="w100" size="medium" v-model="valueBrandModel" filterable remote :placeholder="$t('common_00283')" :remote-method="getCarOptios">
            <el-option v-for="item in vehicleOptions" :key="item.id" :label="`${item.brand}|${item.series} ${item.fullName}`" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="brand mt10">
        <!-- <el-col :span='5'>
          <el-select v-model="brand" :placeholder="$t('common_00283')" @change='selectChange' :disabled='checked' size="medium">
            <el-option
              v-for="(item,key) in optionsBrand"
              :label="item.title" :value="item.title" :key="key">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span='5' :offset='1'>
          <el-select v-model="model" clearable :placeholder="$t('common_00284')" :disabled='checked' size="medium">
            <el-option
              v-for="(item,key) in optionsModel"
              :label="item.title" :value="item.title" :key="key">
            </el-option>
          </el-select>
        </el-col> -->
        <el-col :span='4'>
          <el-checkbox v-model="checked">{{$t('common_00285')}}</el-checkbox>
        </el-col>
        <el-col :span='19' :offset='1'>
          <el-input v-model="vehicle.vehicleModel" :placeholder="$t('common_00286')" :disabled='!checked' size="medium"></el-input>
        </el-col>
      </el-row>
      <div class="title">{{$t('common_00199').split('|')[0]}}<span>{{$t('common_00199').split('|')[1]}}</span></div>
      <el-input v-model="vehicle.address" :placeholder="$t('common_00200')" size="medium"/>
      <div class="addButton">
        <el-button type="primary" @click="submit" size="medium">{{title}}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import {requestUrl,ajax,getQueryString} from "@/assets/utils/network";
  import EV_MODELES from '@/assets/js/EV_MODELS.js'
  export default {
    props: ['flag', 'vId', "licenseNo"],
    data() {
      return {
        product:"",
        vehicleOptions: [], // 车型库
        valueBrandModel: "", // 所选车型id
        brand: '', //品牌
        model: '', //车型
        checked: false, // 手动输入开关
        optionsBrand: EV_MODELES, // 品牌列表
        optionsModel: null, // 车型列表
        numberPlate: '', // 车牌号
        //vin: '', // VIN码
        tel: '', // 手机号
        models: '', // 车型
        address: '', // 所在地
        title:this.$parent.$t('common_00193'),
        vehicle:{},
        cache:{},
        product:'',
      }
    },
    created(){
      this.product = window.product_area;
    },
    watch:{
      flag:function(val, oldVal){
        console.log(this.vId,'this.vId',this.licenseNo,'this.licenseNo')
        if(this.vId){
          if(this.licenseNo){
            this.title = this.$t('common_00194');
            this.queryVehicle(this.vId, this.licenseNo);
            this.cache = this.vehicle;
          }else {
            this.title = this.$t('common_00193');
            this.vehicle.vehicleId = this.vId;
            this.cache = this.vehicle;
          }
        }
      }
    },
    methods: {
      // 车型列表
      getCarOptios(keyword) {
        ajax({
          type: "GET",
          url: `${requestUrl}/vehicle/model?limit=50&keyword=${keyword||''}`,
          dataType: "json",
          async: false,
          success: (response) => {
            let info = JSON.parse(response);
            this.vehicleOptions = info.result || [];
          },
        });
      },
      selectChange(title) {
        EV_MODELES.forEach((e, index) => {
          if (e.title === title) {
            this.model = '';
            this.optionsModel = EV_MODELES[index].models
          }
        })
      },
      closeFlag() {
        this.$emit('close', false)
      },
      submit() {
        if(!this.checked){
          this.vehicle.vehicleModelId = this.valueBrandModel; // this.brand + this.model;
        }
        if(this.profuct !='cn') {
          if(!this.vehicle.licenseNo) {delete this.vehicle.licenseNo;}
          if(!this.vehicle.mobile) {delete this.vehicle.mobile;}
        }
        let params = JSON.parse(JSON.stringify(this.vehicle))
        if(!this.checked){
          delete params.vehicleModel;
        }else {
          delete params.vehicleModelId;
        }
        ajax({
          type:"post",
          data:JSON.stringify(params),
          url:requestUrl + '/vehicle',
          dataType:"json",
          success:(response)=>{
            let json = JSON.parse(response);
            if(json.error){
              this.vehicle = this.cache;
              this.$message({message: json.error.msg,type: 'error'});
            }else{
              this.$message({message: this.$t('common_00201'),type: 'success'});
              this.closeFlag();
              var href = window.location.href;
              if(href.indexOf('onLineUser.html') != -1){
                window.location.href = window.location.origin + window.location.pathname + '?tab=third';
              }else if(href.indexOf('billDetail.html') != -1 || href.indexOf('car.html')){
                this.$emit('confirm')
              }else{
                window.location.reload();
              }
            }
          }
        });
      },
      queryVehicle:function(vid, licenseNo){
        var om = this;
        ajax({
          type:"get",
          url:requestUrl + "/vehicle?vehicleId=" + vid + "&keyword=" + licenseNo,
          dataType: "json",
          success: function (response) {
            let json = JSON.parse(response);
            if(json.result){
              om.vehicle = json.result[0] || {};
            }
            console.log(om.vehicle,'om.vehicle')
          }
        })
      }
    },
    mounted(){
      this.product = window.product_area;
      this.getCarOptios();
    }
  }
</script>

<style lang="scss" scoped>
  .addCar {
    font-size: 16px;
    & .title {
      margin-top: 20px;
      margin-bottom: 4px;
      & span {
        margin-left: 6px;
        color: #999;
      }
    }
    .w100{
      width: 100%;
    }
    .mt10{
      margin-top: 10px;
    }
    .brand{display: flex;align-items: center;}
    & .addButton {
      margin-top: 30px;
      text-align: center;
    }
  }
</style>
