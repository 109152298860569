<template>
  <div class="wrap">
    <el-form :model="ruleForm" ref="ruleForm" class="regin-info" :rules="rules">
      <div class="title">{{$t('registerEmail_00016').split('|')[0]}} <span>( {{$t('registerEmail_00016').split('|')[1]}} )</span></div>
      <el-form-item class="input" prop="title">
        <el-input :placeholder="$t('registerEmail_00017',[product == 'cn' ? 8 : 20])" v-model="ruleForm.title"></el-input>
      </el-form-item>
      <!--货币  -->
      <div class="title" v-if="selectedLevel==1 &&  product !='cn'">{{$t('registerEmail_20053')}} <span>( {{$t('registerEmail_20054')}} )</span></div>
      <el-form-item class="input currency" prop="currency" v-if="selectedLevel==1 && product !='cn'">
        <el-select v-model="ruleForm.currency" :placeholder="$t('registerEmail_20055')">
          <el-option v-for="item in currencyOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <div class="title">{{$t('registerEmail_00018').split('|')[0]}} <span>( {{$t('registerEmail_00018').split('|')[1]}} )</span></div>
      <el-form-item class="input" prop="loginId">
        <el-input :placeholder="$t('registerEmail_00019')" v-model="ruleForm.loginId"></el-input>
      </el-form-item>
      <div class="title">{{$t('registerEmail_00020').split('|')[0]}} <span>( {{$t('registerEmail_00020').split('|')[1]}} )</span></div>
      <el-form-item class="input" prop="password">
        <el-input :placeholder="$t('registerEmail_21006')" v-model="ruleForm.password" type="password"></el-input>
      </el-form-item>
      <div class="title">{{$t('registerEmail_00021').split('|')[0]}} <span v-if="product=='cn'">( {{$t('registerEmail_00021').split('|')[1]}} )</span></div>
      <el-form-item class="input" prop="passwordRe">
        <el-input :placeholder="$t('registerEmail_30059')" v-model="ruleForm.passwordRe" type="password"></el-input>
      </el-form-item>
      <div class="title">{{$t('registerEmail_00014').split('|')[0]}} <span>( {{$t('registerEmail_00014').split('|')[1]}} )</span></div>
      <el-form-item class="input" prop="contactName">
        <el-input :placeholder="product !='cn'?$t('registerEmail_00019'):$t('registerEmail_00024')" v-model="ruleForm.contactName"></el-input>
      </el-form-item>
      <div class="title" v-if="product =='cn'">联系人手机号码<span>( 11位手机号 )</span></div>
      <el-row class="input" v-if="product =='cn'">
        <el-col :span="18">
          <el-form-item prop="contactMobile">
            <el-input placeholder="请输入手机号" v-model="ruleForm.contactMobile"></el-input>
          </el-form-item>
        </el-col>
        <el-col align="right" :span="5" :offset="1">
          <el-button type="primary" @click="sendCode" id="code" :disabled="sendFlag">发送验证码</el-button>
        </el-col>
      </el-row>
      <div class="title" v-if="product =='cn'">短信验证码<span>( 4位数字 )</span></div>
      <el-form-item v-if="product =='cn'" class="input" prop="smsAuthCode">
        <el-input placeholder="请输入验证码" v-model="ruleForm.smsAuthCode"></el-input>
      </el-form-item>
      <!-- <el-form-item class="input read" prop="check">
        <el-checkbox-group v-model="ruleForm.check">
          <el-checkbox name="check">{{$t("registerEmail_20051")}}</el-checkbox>
        </el-checkbox-group>
        <a v-if="product != 'cn'" href="https://www.xcharge.com/statement" target="_blank" class="agreement">{{$t("registerEmail_20052")}}</a>
        <a v-if="product == 'cn'" href="./saasAgreement.html" target="_blank" class="agreement">{{$t("registerEmail_20052")}}</a>
      </el-form-item> -->
      <el-form-item>
        <div class="btn">
          <el-button type="primary" @click="registerFirst(1)" v-if="product == 'cn'">{{$t('registerEmail_00001')}}</el-button>
          <el-button type="primary" @click="registerFirst(product == 'cn'?2:1)">{{product == 'cn'&&selectedLevel==1?$t('registerEmail_00023'):$t('registerEmail_00001')}}</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  requestUrl,
  ajax,
  checkLogin,
  getQueryString,
} from "@/assets/utils/network";
export default {
  props: ["product", "selectedLevel", "regCode", "codeInfo"],
  data() {
    // 验证货币
    var checkcurrency = (rule, value, callback) => {
      if (this.selectedLevel == 1 && this.product != "cn") {
        if (value === "") {
          callback(new Error(this.$t("registerEmail_30058")));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    // 验证密码是否一致
    var checkpass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("registerEmail_10044")));
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$t("registerEmail_10045")));
      } else {
        callback();
      }
    };
    // 验证验证码是否正确
    var checkcode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (isNaN(value)) {
        callback(new Error("请输入数字值"));
      } else if (value.toString().length !== 4) {
        callback(new Error("请输入4位数字"));
      } else {
        callback();
      }
    };
    // 验证联系人姓名
    var checkContactName = (rule, value, callback) => {
      // const reg = /^[\u4e00-\u9fa5]{2,4}$/;
      // if (reg.test(value)) {
      //   callback();
      // } else {
      //   callback(new Error("请输入正确的联系人姓名"));
      // }
      if (typeof value == "undefined" || value == null) {
        callback(new Error(this.$t("registerEmail_10049")));
      } else {
        callback();
      }
    };
    // 自定义 - 验证登录账号
    var checkLoginId = (rule, value, callback) => {
      const reg = /^[A-Za-z0-9]+$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("registerEmail_10050")));
      }
    };
    return {
      interval: null,
      sendFlag: false, //发送手机验证码的开关
      currencyOptions: [
        {
          value: "gbp",
          label: `${this.$parent.$t("gbp_name")}(${this.$parent.$t("gbp")})`,
        },
        {
          value: "usd",
          label: `${this.$parent.$t("usd_name")}(${this.$parent.$t("usd")})`,
        },
        {
          value: "eur",
          label: `${this.$parent.$t("eur_name")}(${this.$parent.$t("eur")})`,
        },
        {
          value: "rub",
          label: `${this.$parent.$t("rub_name")}(${this.$parent.$t("rub")})`,
        },
      ],
      ruleForm: {
        // 表单内信息
        contactName: "",
        title: "",
        loginId: "",
        password: "",
        contactMobile: "",
        smsAuthCode: "",
        passwordRe: "",
        interval: "",
        check: [],
        currency: "",
        skipWechatOa: false
      },
      rules: {
        contactName: [
          {
            required: true,
            message: this.$parent.$t("registerEmail_10052"),
            trigger: " ",
          },
          {
            validator: checkContactName,
            trigger: " ",
          },
        ],
        title: [
          {
            required: true,
            message: this.$parent.$t("registerEmail_10053"),
            trigger: " ",
          },
          {
            max: this.product == "cn" ? 8 : 20,
            message: this.$parent.$t("registerEmail_10054", [
              this.product == "cn" ? 8 : 20,
            ]),
            trigger: "blur",
          },
        ],
        loginId: [
          {
            required: true,
            message: this.$parent.$t("registerEmail_10055"),
            trigger: " ",
          },
          {
            validator: checkLoginId,
            trigger: " ",
          },
        ],
        password: [
          {
            required: true,
            message: this.$parent.$t("registerEmail_10056"),
            trigger: " ",
          },
          {
            min: 6,
            max: 20,
            message: this.$parent.$t("registerEmail_10057"),
            trigger: " ",
          },
        ],
        passwordRe: [
          {
            validator: checkpass,
            trigger: " ",
          },
        ],
        // 校验规则
        contactMobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: " ",
          },
          {
            min: 11,
            max: 11,
            message: "请输入正确的手机号",
            trigger: " ",
          },
        ],
        // 短信验证码
        smsAuthCode: [
          {
            validator: checkcode,
            trigger: " ",
          },
        ],
        check: [
          {
            type: "array",
            required: true,
            message: this.$parent.$t("registerEmail_10058"),
            trigger: " ",
          },
        ],
        currency: [
          {
            validator: checkcurrency,
            trigger: " ",
          },
        ],
      },
    };
  },
  methods: {
    // 发送验证码
    sendCode() {
      var info = new Object();
      info.mobile = this.ruleForm.contactMobile;
      if (this.selectedLevel == 1) {
        info.dealerLicenseCode = this.regCode;
      }
      if (this.selectedLevel == 2) {
        info.inviteCode = this.codeInfo.codeId;
      }
      if (info.mobile == "" || info.mobile.length != 11) {
        this.$message.error("请输入正确的手机号");
        return false;
      }
      ajax({
        type: "POST",
        url: requestUrl + "/smsauthcode",
        data: JSON.stringify(info),
        dataType: "json",
        success: function (response) {},
        fail: function (response) {
          om.$message.error("服务器响应超时");
        },
      });
      let time_num = 60;
      let that = this;
      this.sendFlag = true;
      var t = setInterval(function () {
        if (--time_num < 1) {
          clearInterval(t);
          that.sendFlag = false;
          document
            .getElementById("code")
            .getElementsByTagName("span")[0].innerText = "获取验证码";
        } else document.getElementById("code").getElementsByTagName("span")[0].innerText = time_num + "s后重新获取";
      }, 1000);
      this.interval = t;
    },
    // 注册按钮---下一步
    registerFirst(status) {
      console.log(status)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let stepActive = "1";
          if (this.selectedLevel == 2 && this.product != "cn") {
            stepActive = "2";
          }
          if(status == 1) {
            this.ruleForm.skipWechatOa = true
          }
          this.$emit("next", {
            codeInfo: this.ruleForm,
            stepActive,
            tag: "regisInfo",
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {
    if (this.product == "cn") {
      this.ruleForm.currency = "cny";
    }
  },
};
</script>

<style lang="scss" scoped>
.title-all {
  color: #464b5c;
  font-size: 14px;
  margin-bottom: 10px;
  span {
    color: #8087a0;
  }
}
.regin-info {
  .title {
    @extend .title-all;
  }
  .btn {
    text-align: center;
  }
}
.currency {
  /deep/.el-select {
    width: 100%;
  }
}
.read {
  margin-bottom: 40px;
  /deep/.el-form-item__content {
    line-height: 20px;
  }
}
/deep/.el-checkbox-group {
  display: inline-block;
  & + a {
    color: #ff4949;
  }
}
</style>