export default {
    from_get_coupon_on:"{validityDays} days from the date of receipt of the coupon",
    rewardSetting_0001: 'Reward Setting',
    rewardSetting_0002: 'Quantity',
    rewardSetting_0003: 'Add Coupon',
    rewardSetting_0004: 'Please enter the card name',
    rewardSetting_0005: 'Coupon Type',
    rewardSetting_0006: 'Coupon name',
    rewardSetting_0007: 'Type',
    rewardSetting_0008: 'Expiration Date',
    rewardSetting_0009: 'Upgrade Mission-{name}',
    rewardSetting_0010: 'Relegation Mission-{name}',
    rewardSetting_00011: 'If you add a coupon with an absolute validity period, please note that the coupon will be removed from the reward before the coupon expires. ',
    rewardSetting_00012: 'Please enter a number, the number cannot exceed 100',
    rewardSetting_00013: 'User Membership Level',
    rewardSetting_00014: "Within {validityDays} days of acquisition",
}