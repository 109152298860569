export default { //todo.
    userAllocateDetail_00001: "团体名称",
    userAllocateDetail_00002: "分配记录",
    userAllocateDetail_00003: "基本信息",
    userAllocateDetail_00004: "分配详情",
    userAllocateDetail_00005: "下载分配明细",
    userAllocateDetail_00006: "头像",
    userAllocateDetail_00007: "用户/手机号/卡号",
    userAllocateDetail_00008: "类型", 
    userAllocateDetail_00009: "分配金额({0})",
    userAllocateDetail_00010: "微信用户",
    userAllocateDetail_00011: "在线身份卡",
    userAllocateDetail_00012: "用户列表",
    userAllocateDetail_00013: "",
    userAllocateDetail_00014: "",
    userAllocateDetail_00015: "",
    userAllocateDetail_00016: "",
    userAllocateDetail_00017: "",
    userAllocateDetail_00018: "",
    userAllocateDetail_00019: "",
    userAllocateDetail_00020: "",
  }