export default {
    charging_package52:"Day {day} from the date the user purchased/redeemed the package",
    convert_00001: 'Channel redemption code',
    convert_00002: 'Marketing Center',
    convert_00003: 'Card Voucher Distribution',
    convert_00004: 'Channel Redemption Code',
    convert_00005: 'Please enter redemption code',
    convert_00006: 'Please enter coupon name',
    convert_00007: 'Please enter the distribution channel',
    convert_00008: 'Redemption code status',
    convert_00009: 'All',
    convert_00010: 'Not redeemed',
    convert_00011: 'Redeemed',
    convert_00012: 'No longer valid',
    convert_00013: 'Card Voucher Category',
    convert_00014: 'All',
    convert_00015: 'Credit',
    convert_00016: 'Electricity ticket',
    convert_00017: 'Reset',
    convert_00018: 'Search',
    convert_00019: 'Coupon list',
    convert_00020: 'Generate Redeem code',
    convert_00021: 'Export data',
    convert_00022: 'Redeem code',
    convert_00023: 'Generate time',
    convert_00024: 'Expiry date',
    convert_00025: 'Permanent',
    convert_00026: 'Distribution channel',
    convert_00027: 'Status',
    convert_00028: 'Redeem coupon category',
    convert_00029: 'Redeem voucher name',
    convert_00030: 'Coupon detail',
    convert_00031: 'Generate coupon',
    convert_00032: 'Electricity ticket',
    convert_00033: 'Credit',
    convert_00034: 'Generate quantity',
    convert_00035: 'Generate up to 10000',
    convert_00036: 'Expiry date',
    convert_00037: 'N/A',
    convert_00038: 'Yes',
    convert_00039: 'Expiry date',
    convert_00040: 'Distribution channel',
    convert_00041: 'No more than 20 characters',
    convert_00042: 'Cancel',
    convert_00043: 'Generate',
    convert_00044: 'This operation will download an Excel file of the channel redemption code data, continue?',
    convert_00045: 'Reminder',
    convert_00046: 'Cancel',
    convert_00047: 'Start downloading',
    convert_00048: 'Please select the coupon to be generated',
    convert_00049: 'Please fill in the quantity to be generated',
    convert_00050: 'Please enter a number for the quantity to be generated',
    convert_00051: 'Please fill in the distribution channel',
    convert_00052: 'Please select expiry date',
    convert_00053: 'Generate up to 10000！',
    convert_00054: 'Please enter the correct number to be generated!',
    convert_00055: 'Distribute channels of no more than 20 characters!',
    convert_00056: 'Coupon generated successfully!',
    convert_00057: 'Electricity ticket',
    convert_00058: 'Credit',
    convert_00059: 'Generate redemption code',
    convert_00060: 'Operate',
    convert_00061: 'Cancel the operation',
}
