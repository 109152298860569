export default {
    begin_date_select_remind: "Beginndatum auswählen",
    end_date_select_remind: "Endedatum auswählen",
    until: "bis",
    enter_activity_explain:"Bitte geben Sie die Beschreibung der Aktivität ein",
    no_limit:"nicht limitiert",
    limit:"limitiert",
    single_user_charge_amount:"mindeste kumulierten Gesamtkosten für einen einzelnen Nutzer",
    user_charge_amount:"mindeste kumulierten Elektrizität für einen einzelnen Nutzer",
    only_no_charged:"Nur Nutzer, die noch nie auf der Plattform aufgeladen haben",
    userLevel:"Nutzer Niveau",
    userClass:"Nutzer-Kategorien",
    promotion_type1: "Aufladegeschenk (Bargeld)",
    promotion_type2: "Aufladegeschenk (Bargeld+Coupon)",
    promotion_type3: "Aufladegeschenk (Coupon)",
    promotion_type4: "Coupon verschenken",
    promotion_type5: "Coupon verkaufen",
    cycle_type1:"Mal / gesamte Zeitraum",
    cycle_type2:"Mal/jede Monate",
    cycle_type3:"Mal/jede Woche",
    cycle_type4:"Mal/jeden Tag",
    default_userLevel:"Nicht-hierarchische Benutzer",
    mouth_1:"eine Monate",
    mouth_2:"drei Monate",
    mouth_3:"12 Monate",
    promotion_date:" Während der Aktivität",
    promotion_invoice_1:"Bitte geben Sie einen Aktivitätsnamen mit weniger als 20 Zeichen ein",
    promotion_invoice_2:"Bitte geben Sie Beginndatum ein",
    promotion_invoice_3:"Bitte geben Sie Endedatum ein",
    promotion_invoice_4:"Bitte geben Sie die richtige Zeit der Aktivität ein",
    promotion_invoice_5:"Bitte wählen Sie Aktivitätsobjekt aus.",
    promotion_invoice_6:"Bitte geben Sie einen Aktivitätsnamen mit weniger als 150 Zeichen ein",
    promotion_invoice_7:"Aufladebetrag/eingegebene Geschenkbetrag mehr als 4 Ziffern",
    promotion_invoice_8:"Die Anzahl der erfolgreichen Aufladungen für einen einzelnen Benutzer wurde falsch eingegeben",
    promotion_invoice_9:"Der Gesamtbetrag der kumulierten Kosten für einen einzelnen Benutzer wurde falsch eingegeben",
    promotion_invoice_10:"Die kumulative Anzahl der erfolgreichen Anträge für alle Benutzer wurde falsch eingegeben",
    promotion_invoice_11:"Der Gesamtbetrag der geschenkten Barmittel wurde falsch eingegeben",
    promotion_invoice_14:"bis zu 6 Klasse unterstützen",
    promotion_invoice_15:"Bestätigen Sie die Löschung dieses Aktivitätsklasse",
    enter_amount:"Bitte geben Sie Betrag ein",
    week:"eine Woche",
    enter_charge_placeholder:"Bitte geben Sie Elektrizität ein",
    support_refund:"Erstattung unterstützen",
    no_refund:"Erstattung nicht unterstützen",
    enter_success_times_1:"Bitte geben Sie die Anzahl der erfolgreichen Aufladungen ein",
    enter_success_times_2:"Bitte geben Sie die Anzahl der erfolgreichen Sammlung ein",
    enter_discount_count:"Bitte geben Sie den Gesamtbetrag des geschenkten Bargeldes ein",
    enter_coupon_name:"Bitte geben Sie Couponsname ein",
    query: "suchen",
    confirm: "bestätigen",
    coupon_title:"Couponsname",
    coupon_type:"Couponstypen",
    common_00241: "erfolgreich hinzufügen",
    wechat_user: "Wechat Nutzer",
    confirm_delete_activate:"bestätigen diese Aktivität zu löschen",
    delete_success: "erfolgreiche Löschen",
    validity:"Gültigkeitsdauer",
    operation: "Operation",
    activity_detail: "Einzelheit",
    voucher_coupon:"Gutschein",
    discount_coupon:"Coupon",
    day:"Tag",
    select_coupon_invoice_1:"Wählen Sie bis zu 6 Gutscheine",
    select_coupon_invoice_2:"Wählen Sie bis zu 1 Gutscheine",
    less_than_or_equal_to:"Weniger als oder gleich",
    greater_than_or_equal_to:"Mehr als oder gleich",
    promotion_invoice_17:"Die kumulierten Gesamtkosten für einen einzelnen Nutzer entsprechen im Aktivitätszeitraum {equal}{feeAmount}Yuan",
    promotion_invoice_12:"Die kumulierten Gesamtkosten für einen einzelnen Nutzer entsprechen in der {feeAmountPeriod}Monate {equal}{feeAmount}Yuan",
    promotion_invoice_24:"Die kumulierten Gesamtelektrizität für einen einzelnen Nutzer entsprechen im Aktivitätszeitraum {equal}{powerAmount}kWh",
    promotion_invoice_21:"Falsche Eingabe des kumulierten Entgelts für einen einzelnen Nutzer",
    promotion_invoice_22:"Die kumulierten Gesamtelektrizität für einen einzelnen Nutzer entsprechen in der{powerAmountPeriod}Monate {equal}{powerAmount}kWh",
    promotion_invoice_23:"Die kumulierten Gesamtelektrizität für einen einzelnen Nutzer entsprechen in den 7 Tagen {equal}{powerAmount}kWh",
    modify_success: 'erfolgreiche Änderung',
    count_time:"Statistische Zeit",
    count_coupons:"Gesamtzahl der ausgestellten Kartengutscheine",
    get_coupon_people:"Gesamtzahl der Begünstigten",
    get_coupon_count:"Gesamtzahl der Sammlungen",
    provide_coupon:"Situation der ausgestellten Kartengutscheine",
    all_coupons_count:"Kumulierte Anzahl der ausgestellten Kartengutscheine",
    fee_total:"Gesamtgebühr",
    use_condition:"Nutzungsbedingung",
    used:"genutzte Menge",
    unused:"Verfügbare Menge",
    expired: "Anzahl der Ungültige",
    user_collection_detail:"Benutzer-Sammlung",
    head_portrait: "Avatar",
    nickname: "Spitzname",
    sex: "Geschlecht",
    user_mobile:"Benutzername (Handynummer)",
    card_number: "Kartenummer",
    collection_time:"Erhebungszeit",
    gifts_coupon_amount:"Betrag des Gutscheins",
    gifts_coupon_length:"Anzahl der ausgegebenen Gutscheine",
    u3_card: "Onine-Ausweis",
    promotion_use_coupon_condition:"Einzelne Ladengebühr {msg} mehr als {feeAtLeast}Yuan",
    service_fee: "Servicegebühr",
    promotion_amount:"Gesamtbetrag des Gutscheins (Yuan)",
    amount_balance:"Aufladung Gesamtbetrag (Yuan)",
    promotion_charge_people:"Gesamtzahl der Aufladungsnutzer",
    promotion_charge_count:"Gesamtanzahl der Aufladung",
    user_charge_detail:"Gespeicherter Wert von Benutzer",
    charge_time:"Zeit zum Speichern des Wertes",
    remind: "Hinweise",
    export_data: "Datei exportieren",
    coupon_detail:"Einzelheiten der Gutscheine",
    user_detail:"Einzelheiten der Benutzer",
    cancel: "abbrechen",
    start_download: "Download starten",
    cancel_download: "Download abbrechen",
    type: 'Nutzertypen',
    promotion_invoice_16:"Bitte geben Sie weniger als 500 Wörter für Ihre Aktivitätsbeschreibung ein",
    level_check_info:"Bitte wählen Sie Nutzerklasse aus",

    stored_00001: 'Einzelheiten',
    stored_00002: 'Marketing - Zentrum',
    stored_00003: 'Marketing - Aktivität',
    stored_00004: 'Neu erstellen',
    common_00271: 'ändern',
    common_00255: 'grunsätzliche Informationen',
    stored_00007: 'Aktivitätsinformation',
    stored_00008: 'Aktivitätsname',
    stored_00009: 'Aktivitätszeit',
    stored_00010: 'Aktivitätsobjekte',
    stored_00011: 'Wechatnutzer',
    stored_00012: 'Aktivitätsklasse',
    stored_00013: 'Klasse hinzufügen',
    stored_00014: '(mindestens 1 Klasse hinzufügen, bis zu 6 Klasse unterstützen)',
    stored_00015: 'Klasse',
    stored_00016: 'Aufladungswert (Yuan)',
    stored_00017: 'Betrag des Geschenks (Yuan)',
    stored_00018: 'Geschenk-Gutscheine',
    stored_00019: 'Operation',
    stored_00020: 'ändern',
    stored_00021: 'Anzahl der erfolgreiche Sammlung eines einzelnen Nutzers',
    stored_00021_1: 'Klasse löschen',
    stored_00022: 'Kumulierte Anzahl der erfolgreiche Sammlung aller Nutzer',
    stored_00023: 'Anzahl der erfolgreiche Aufladen eines einzelnen Nutzers',
    stored_00024: 'Gesamtbetrag des Geschenkbargeld',
    stored_00025: 'Rückerstattung des gespeicherten Wertes',
    stored_00026: 'Bereich der teilnehmenden Nutzer',
    stored_00027: 'Im Betrieb',
    stored_00028: 'Aufgehängt',
    stored_00029: 'abbrechen',
    stored_00030: 'speichern',
    stored_00031: 'ändern',
    stored_00032: 'löschen',
    stored_00033: 'Aktivitätswirkung',
    stored_00034: 'Aktivitätsbeschreibung',
    stored_00035: "Aktivitäts-ID",
}

