export default {
    pia_00001:'财务中心',
    pia_00002:'常用功能',
    pia_00003:'申请发票类型均为增值税普通发票，不包含赠送金额、优惠金额、团体分配金额。',
    pia_00004:'申请状态',
    pia_00005:'全部',
    pia_00006:'待处理',
    pia_00007:'发票已开',
    pia_00008:'已取消',
    pia_00009:'微信用户：',
    pia_000010:'查询',
    pia_000011:'重置',
    pia_000012:'导出数据',
    pia_000013:'待处理笔数',
    pia_000014:'待处理金额总计（元）',
    pia_000015:'已开发票笔数',
    pia_000016:'已开发票金额总计（元）',
    pia_000017:'昵称',
    pia_000018:'申请类型',
    pia_000019:'开票方式',
    pia_000020:'申请金额',
    pia_000021:'查询充电订单',
    pia_000022:'查询车位占用账单',
    pia_000023:'拒绝理由',
    pia_000024:'发票详情',
    pia_000025:'发票抬头',
    pia_000026:'公司税号',
    pia_000027:'公司地址',
    pia_000028:'拒开发票',
    pia_000029:'联系信息',
    pia_000030:'手机号',
    pia_000031:'邮箱',
    pia_000032:'每页显示',
    pia_000033:'拒开发票按钮提示',
    pia_000034:'请填写拒绝理由',
    pia_000035:'发票已开按钮提示',
    pia_000036:'纸质发票',
    pia_000037:'电子发票',
    pia_000038:'提示',
    pia_000039:'用户昵称/手机号',
    pia_000040:'申请时间：',
    pia_000041:'开始日期',
    pia_000042:'结束日期',
    pia_000043:'企业发票',
    pia_000044:'个人发票',
    pia_000045:'公司电话',
    pia_000046:'开户银行',
    pia_000047:'开户账号',
    pia_000048:'收件人',
    pia_000049:'收件地址',
    pia_000050:'暂无数据',
    pia_000051:'此操作将下载发票的Excel文件, 是否继续?',
    pia_000052:'提示',
    pia_000053:'确定',
    pia_000054:'取消',
    pia_000055:'开始下载',
    pia_000056:'取消下载',
    pia_000057:'发票列表',
    pia_000058:'纸质发票和电子发票',
    pia_000059: "用户昵称",
    pia_000060: "发票手机号",
    pia_000061: "获取电子发票地址",
    pia_000062: "人工重新开票",
    pia_000063: "百望云开票系统",
    pia_000064: "自动开票中",
    pia_000065: "自动开票失败",
}