export default {
  orderAnalysis_00001: 'Tickets Stats',
  orderAnalysis_00002: 'Analyze',
  orderAnalysis_00003: 'Station Requests Statistics',
  orderAnalysis_00004: 'number of Ticket Completed by Groups',
  orderAnalysis_00005: 'number of Ticket by Categories',
  orderAnalysis_00006: 'Requesr Priority  Statistics',
  orderAnalysis_00007: 'Tickets Problem Source Statistics',
  orderAnalysis_00008: 'Equipment Brand & Model Ticket Stastistics',
  orderAnalysis_00009: 'Reporting by Staff',
  orderAnalysis_00010: 'Customer Feedback',
  orderAnalysis_00011: 'Equipment Reporting',
  orderAnalysis_00012: 'Automatic trigger',
  orderAnalysis_00013: 'Other',
  orderAnalysis_00014: 'Normal',
  orderAnalysis_00015: 'Urgen',
  orderAnalysis_00016: 'Very Urgen',
  orderAnalysis_00017: 'Accessory Use Statistics',
}