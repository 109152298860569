export default {
    gia_00001:'财务中心',
    gia_00002:'常用功能',
    gia_00003:'申请发票类型均为增值税普通发票，不包含赠送金额、优惠金额。',
    gia_00004:'申请状态',
    gia_00005:'全部',
    gia_00006:'待处理',
    gia_00007:'发票已开',
    gia_00008:'已取消',
    gia_00009:'团体',
    gia_000010:'查询',
    gia_000011:'重置',
    gia_000012:'导出数据',
    gia_000013:'待处理笔数',
    gia_000014:'待处理金额总计（元）',
    gia_000015:'已开发票笔数',
    gia_000016:'已开发票金额总计（元）',
    gia_000017:'昵称',
    gia_000018:'申请类型',
    gia_000019:'开票方式',
    gia_000020:'申请金额',
    gia_000021:'查询充电订单',
    gia_000022:'查询车位占用账单',
    gia_000023:'拒绝理由',
    gia_000024:'发票详情',
    gia_000025:'发票抬头',
    gia_000026:'公司税号',
    gia_000027:'公司地址',
    gia_000028:'拒开发票',
    gia_000029:'联系信息',
    gia_000030:'手机号',
    gia_000031:'邮箱',
    gia_000032:'每页显示',
    gia_000033:'拒开发票按钮提示',
    gia_000034:'请填写拒绝理由',
    gia_000035:'发票已开按钮提示',
    gia_000036:'纸质发票',
    gia_000037:'电子发票',
    gia_000038:'提示',
    gia_000039:'用户昵称/手机号',
    gia_000040:'申请时间：',
    gia_000041:'开始日期',
    gia_000042:'结束日期',
    gia_000043:'企业发票',
    gia_000044:'个人发票',
    gia_000045:'公司电话',
    gia_000046:'开户银行',
    gia_000047:'开户账号',
    gia_000048:'收件人',
    gia_000049:'收件地址',
    gia_000050:'暂无数据',
    gia_000051:'此操作将下载发票的Excel文件, 是否继续?',
    gia_000052:'提示',
    gia_000053:'确定',
    gia_000054:'取消',
    gia_000055:'开始下载',
    gia_000056:'取消下载',
    gia_000057:'发票列表',
    gia_000058:'纸质发票和电子发票'
}