export default {
  groupRecord_00001: "充值日期",
  groupRecord_00002: "充值总金额({unit})",
  groupRecord_00003: "充值本金({0})",
  groupRecord_00004: "充值赠送金额({unit})",
  groupRecord_00005: "充值后账户余额({0})",
  groupRecord_00006: "充值方式",
  groupRecord_00007: "操作员",
  groupRecord_00008: "包含赠送{promotionBalance}{unit}",
  groupRecord_00009: "赠送金额({unit})",
  groupRecord_00010: "团体账户充值记录",
  
}