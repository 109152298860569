export default {
    configInfo_00001: 'Smart Charging System',
    configInfo_00002: 'C1 (4.3 inches  screen) charger screen configuration information',
    configInfo_00003: 'C1 (7 inches screen) charger screen configuration information',
    configInfo_00004: 'Uploading image',
    configInfo_00005: 'Drag the file here, or|click to upload',
    configInfo_00006: 'Only PNG format images are supported, size {area}px, no more than {size}kb。',
    configInfo_00007: 'Modify image',
    configInfo_00008: 'The image you have selected is too large, please select again',
    configInfo_00009: 'Please select a png format image to upload',
    configInfo_00010: 'Please upload an image that matches {0}px',
    configInfo_00011: 'Please upload an image',
    configInfo_00012: 'Modified successfully',
    configInfo_00013: 'WeChat scan code to start charging',
    configInfo_00014: 'WeChat scan the QR code on the left, follow the instructions for the next step',
    configInfo_00015: 'No more than 24 characters',
    configInfo_00016: 'Ads after wake up',
    configInfo_00017: 'Ads after swipe',
    configInfo_00018: 'Ads after Plugging in',
    configInfo_00019: 'Post-sweep and plugged out ads',
    configInfo_00020: 'Ads when available',
    configInfo_00021: 'Multiple image rotation or mp4 video method, choose one',
    configInfo_00022: 'Use image rotation',
    configInfo_00023: 'Click to upload',
    configInfo_00024: 'Only PNG, JPG images are supported, size {area}px, no more than {size}kb',
    configInfo_00025: 'Use video',
    configInfo_00026: '5 seconds',
    configInfo_00027: '10 seconds',
    configInfo_00028: '15 seconds',
    configInfo_00029: 'Up to 6 uploads',
    configInfo_00030: 'Please select png or jpg format for uploading',
    configInfo_00031: 'Upload up to 1 video',
    configInfo_00032: 'The video you have selected is too large, please select again',
    configInfo_00033: 'Please upload a picture or video',
    configInfo_00034: 'Play time',
    configInfo_00035: 'Only mp4 format videos are supported, size should not exceed {vsize} MB. (Recommended resolution max {varea} pixels, frame rate less than 30fps.)',
    configInfo_00036: 'Upload image or video',
    configInfo_00037: 'Use images',
    configInfo_00038: 'Only PNG, JPG, GIF format images are supported, size {area}px, no more than {size}kb',
    configInfo_00039: 'Use video',
    configInfo_00040: 'Please select mp4 for video upload',
    configInfo_00041: 'Please upload a video',
    configInfo_00042: 'Remove ads',
    configInfo_00043: 'Sure to remove ads from all charging posts of this type in the charging station?',
    configInfo_00044: 'Deletion will not be restored, do you want to continue?',
    configInfo_00045: 'Yes',
    configInfo_00046: 'Cancel',
    configInfo_00047: 'Select all',
    configInfo_00048: 'Name of charger',
    configInfo_00049: 'Device number',

    configInfo_00050: 'X-CHARGE',
    configInfo_00051: 'Welcome to use ingelligent DC charging system',
    configInfo_00052: 'Please use WeChat to charge or use NFC to charge by swipe card',
    configInfo_00053: 'Use the touch button to select the charging port',
    configInfo_00054: 'C401 charging station screen configuration information',
    configInfo_00055: 'C6 charging station screen configuration information',
    configInfo_00056: 'C6s charging station screen configuration information',
    configInfo_00116: 'C9Pro charging station screen configuration information',

    configInfo_00057: 'The top logo can be in either text or image format, either one.',
    configInfo_00058: 'Use text',
    configInfo_00059: 'Please enter content',
    configInfo_00060: '{num} words or less (including punctuation)',
    configInfo_00061: 'Please fill in the top screen text or upload an image',
    configInfo_00062: 'The text on the top screen is too long',
    configInfo_00063: "Please select 'Use image' first",
    configInfo_00064: "Please save the previous action first",
    configInfo_00065: "No more than {num} characters",
    configInfo_00066: "No more than 10 words",
    configInfo_00067: "Parameters are empty",
    configInfo_00068: "The text entered for the parameter cannot be more than 7 digits empty",
    configInfo_00069: "Sweep screen title cannot exceed 10 digits",
    configInfo_00070: "Parameter length is too long",
    configInfo_00071: "Please scan the code to charge or use the NFC card to charge",
    configInfo_00072: "A total of {allDevice} devices were pushed, {sucDevice} devices succeeded, {faiDevice} devices failed",
    configInfo_00073: "Charger name",
    configInfo_00074: "Result",

    // ***********
    configInfo_00075: "Charging site detail",
    configInfo_00076: "Screen configuration",
    configInfo_00077: "Configuration Information",
    configInfo_00078: "Power on Logo",
    configInfo_00079: "Modify",
    configInfo_00080: "Top logo",
    configInfo_00081: "Interface Text",
    configInfo_00082: "Welcome to use",
    configInfo_00083: "Please scan the code to charge",
    configInfo_00084: "Welcome to use",
    configInfo_00085: "Scan code interface title",
    configInfo_00086: "Save",
    configInfo_00087: "No more than 16 characters",
    configInfo_00088: "C2 Charging Station Screen Configuration Information",
    configInfo_00089: "Scan Screen Text",
    configInfo_00090: "Top Text",
    configInfo_00091: "Welcome Text Header",
    configInfo_00092: "Welcome Text Content",
    configInfo_00093: "Bottom prompt text",
    configInfo_00094: "Modify top logo",
    configInfo_00095: "Delete",
    configInfo_00096: "Ad info",
    configInfo_00097: "Device List",
    configInfo_00098: "Edit the configuration information, check the charging pile and push it to the charging pile. It is recommended that the configuration information is pushed after each change to ensure the charging pile is functioning properly",
    configInfo_00099: "Push configuration",
    configInfo_00100: "Push all",
    configInfo_00101: "Success",
    configInfo_00102: "Failed",
    configInfo_00103: "Pushing",
    configInfo_00104: "Close",
    configInfo_00114: "C1（7 inches screen）charger screen",
    configInfo_00113: "C2 charger screen",
    configInfo_00112: "C401 charger screen",
    configInfo_00111: "C6 charger screen",
    configInfo_00110: "C6s charger screen",
    configInfo_00117: "C9Pro charger screen",
    configInfo_00115: "C1（4.3 inches screen）charger screen",
    modify_success: "Modify success",
}