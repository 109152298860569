import {checkLogin} from "./network";
//对el-tab标签特殊处理，用于权限校验
export function checkTabs(permissionsV2) {
    let permissions = checkLogin("permissionsV2");
    let permissionRole = checkLogin("permissionDeniedStyle");
    if(permissions[permissionsV2] === undefined){
        permissions[permissionsV2] = [0,0]
    }
    if(permissions[permissionsV2][0] == 0 && permissions[permissionsV2][1] == 0){
    if(permissionRole == 1){
        return false;
    }
    }else if(permissions[permissionsV2][0] == 1 && permissions[permissionsV2][1] == 0){
        return false;
    }else if(permissions[permissionsV2][0] == 2 && permissions[permissionsV2][1] == 0){
        return false;
    }else{
        return true;
    }
}
//根据语言判断是否新开窗口
export function isOpenInCurrentPage(){
    return window.product_area == 'cn' ? true : false
}