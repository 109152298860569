export default {
    activityOverview_00001: "活动总览",
    activityOverview_00002: "未开始",
    activityOverview_00003: "进行中",
    activityOverview_00004: "已暂停",
    activityOverview_00005: "已结束",
    activityOverview_00006: "全部",
    activityOverview_00007: "",
    activityOverview_00008: "",
    activityOverview_00009: "",
    activityOverview_000010: "",
    activityOverview_000011: "",
    activityOverview_000012: "",
    activityOverview_000013: "",
    activityOverview_000014: "",
    activityOverview_000015: "",
    activityOverview_000016: "",
    activityOverview_000017: "",
    activityOverview_000018: "",
    activityOverview_000019: "",
    activityOverview_000020: "",
    activityOverview_000021: "",
    activityOverview_000022: "",
    activityOverview_000023: "",
    activityOverview_000024: "",
    activityOverview_000025: "",
    // *****
    not_begin: "未开始",
    in_progress: "进行中",
    paused: "已暂停",
    stopped: "已结束",
    all: "全部",
    stored_00013: '活动外发专用链接',
    stored_00014: '复制链接',
    stored_00015: '是否展示',
    stored_00016: '车主端-我的钱包-充值',
    stored_00018: '详情',
    stored_00019: '手动赠送',
    stored_00020: '储值送返活动',
    stored_00021: '自由领取活动',
    promotion_type1: "充值赠送(现金)",
    promotion_type2: "充值赠送(现金+优惠券)",
    promotion_type3: "充值赠送(优惠券)",
    promotion_type4: "赠送优惠券",
    promotion_type5: "销售优惠券",
    copy_link_success: "复制外链成功",
    member_00006: '开',
    member_00007: '关',
    handle_giv: "手动赠送",
    activate_limit: "活动限制条件",
    user_scope: "参与活动用户范围",
    level_giv: "直接赠送",
    add_givuser_text1: "要赠送的用户",
    add_givuser_text2: "还未添加要赠送的用户",
    giv: "赠送",
    giv_error_info: "失败原因",
    card_search_giv: "请输入昵称、手机号查找要赠送的用户",
    search: "查找",
    head_portrait: "头像",
    nickname: "昵称",
    cell_phone_number: "手机号",
    success_giv: "赠送成功",
    success_giv1: "赠送成功！",
    error_giv: "赠送失败",
    activityOverview_100000: "活动名称",
    activityOverview_100001: "活动类型",
    activityOverview_100002: "活动时间",
    activityOverview_100003: "活动状态",
    activityOverview_100004: "活动入口",
    activityOverview_100005: "是否展示",
    activityOverview_100006: "充电送返活动",
    activityOverview_100007: "",
    activityOverview_100008: "",

}