export default {
  diversionServices_00001: 'Flusszentrum',
  diversionServices_00002: 'Fluss öffnen',
  diversionServices_00003: 'Flussplattform Andockstufen',
  diversionServices_00004: 'Regierungsplattform Andockstufen',
  diversionServices_00005: "Schritt 1 Fluss Antrag &nbsp&nbsp&nbsp&nbspWenn Sie auf die Flow-Seite zugreifen möchten, wenden Sie sich bitte an die Plattform",
  diversionServices_00006: "Schritt 2: Geschäftsverhandlungen&nbsp&nbsp&nbsp&nbspErforderlich ist die Unterzeichnung eines Geschäftskooperationsvertrags mit XCharge, der die Rechte und Pflichten beider Parteien festlegt. Einschließlich, aber nicht beschränkt auf die Gebühren für das Andocken an das System, die finanzielle Abwicklung usw.",
  diversionServices_00007: "Schritt 3 Andocken des Systems&nbsp&nbsp&nbsp&nbspplattform, um das Andocken des Systems des Betreibers und des Verkehrsteilnehmers abzuschließen." ,
  diversionServices_00008: "Schritt 4 Standortöffnung&nbsp&nbsp&nbsp&nbspBetreiber können den Verkehr sehen, der unter dem Modul Offene Leads geöffnet werden kann, und selbständig offene Vorgänge für Ladestation öffnen und schließen.",
  diversionServices_00009: 'Geöffnete Ladestation',
  diversionServices_00010: 'nicht geöffnet Ladestation',
  diversionServices_00011: 'Zugegriffen',
  diversionServices_00012: 'Kein Zugang',
  diversionServices_00013: 'zu öffnen',
  diversionServices_00014: 'Kartenplattform',
  diversionServices_00015: 'CPOplattform',
  diversionServices_00016: 'Aggregationsplattform',
  diversionServices_00017: 'OEM-Plattform',
  diversionServices_00018: 'Regierungsplattform',
  diversionServices_00019: 'Regierung',
  diversionServices_00020: 'frei',
  diversionServices_00021: 'Direktanmeldung',
  diversionServices_00022: 'Xcharge-Paket',
}