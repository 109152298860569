export default {
    preferentialSite_00001: "Rabattseite",
    preferentialSite_00002: "Gruppenname",
    preferentialSite_00003: "Site-Name",
    preferentialSite_00004: "Aktuelle Tarifvorlage",
    preferentialSite_00005: "DC",
    preferentialSite_00006: "AC",
    preferentialSite_00007: "Aktuelle Angebote",
    preferentialSite_00008: "arbeiten",
    preferentialSite_00009: "Details zum Tarifrabatt",
    preferentialSite_00010: "Betreibertarif",
    preferentialSite_00011: "Kein Rabatt für gesamte Gebühr jedes einzelnen Aufladens",
    preferentialSite_00012: "Kein Rabatt für die Servicegebühr jedes einzelnen Aufladens",
    preferentialSite_00013: "{des}%-iger Rabatt für Servicegebühr jedes einzelnen Aufladens",
    preferentialSite_00014: "{des}%-iger Rabatt für gesamte Gebühr jedes einzelnen Aufladens",
    preferentialSite_00015: "Freistellung der Servicegebühr jedes einzelnen Aufladens",
    preferentialSite_00016: "Freistellung der gesamten Gebühr jedes einzelnen Aufladens",
    preferentialSite_00017: "Tariff details",
    preferentialSite_00018: "Zeitraum",
    preferentialSite_00019: "Ladegebühr (Stromgebühr + Servicegebühr)",
    preferentialSite_00020: "Verzugsgebühr",
  }