export default {
    powerAllocate_00001: "功率分配",
    powerAllocate_00002: "修改成功。请回到“功率分配”页面将配置信息推送至充电桩！",
    powerAllocate_00003: "最大充电时长支持一位小数",
    powerAllocate_00004: "组内限制充电总功率设置",
    powerAllocate_00005: "添加时段",
    powerAllocate_00006: "(设置为0表示该时段组内充电总功率不限制)",
    powerAllocate_00007: "请输入功率分配组名称",
    powerAllocate_00008: "请完成组内限制充电总功率设置！",
    powerAllocate_00009: "分配总功率(kW)",
    powerAllocate_00010: "实际充电总功率(kW)",
    powerAllocate_00011: "限制总功率(kW)",
    powerAllocate_00012: '功率分配组名称',
    powerAllocate_00013: '基本信息',
    powerAllocate_00014: '是否启用',
    powerAllocate_00015: '可以按时段设置组内限制充电总功率，确保站内充电桩的充电功率不超出各个时段的组内限制充电总功率。',
    powerAllocate_00016: "组内限制充电总功率设置",
    powerAllocate_00017: "组内预留充电功率及最大充电时长设置",
    powerAllocate_00018: "限制充电总功率(kW)",
    powerAllocate_00019: "请完成组内限制充电总功率设置！",
    powerAllocate_00020: "分配总功率(kW)",
    powerAllocate_00021: "实际充电总功率(kW)",
    powerAllocate_00022: "限制总功率(kW)",
    powerAllocate_00023: "可以为组内的充电口按产品型号设置预留充电功率、最大充电时长",
    powerAllocate_00024: "预留充电功率: 平台自动分配充电功率时为组内未充电的充电口保留的最低充电功率。默认为0kW，表示不预留充电功率",
    powerAllocate_00025: "最大充电时长: 平台自动分配充电功率时组内充电口最大充电时长。 默认24小时",
    powerAllocate_00026:"充电口离线最大充电功率是本设置中的预留充电功率",
    powerAllocate_00027: "产品型号",
    powerAllocate_00028: "充电口最大充电功率(kW)",
    powerAllocate_00029: "充电口最小充电功率(kW)",
    powerAllocate_00030: "预留充电功率(kW)",
    powerAllocate_00031: "最大充电时长(小时)",
    powerAllocate_00032: "组内充电总功率分配展示图",
    powerAllocate_00033: "近300天",
    powerAllocate_00034: "近24小时",
    powerAllocate_00035: "暂无数据",
    powerAllocate_00036: "请输入正确的数字格式",
    powerAllocate_00037: "请输入大于充电口最小功率,小于充电口最大功率的预留充电功率",
    powerAllocate_00038: "预留充电功率不得为负数",
    powerAllocate_00039: "预留充电功率最多支持两位小数",
    powerAllocate_00040: "最大充电时长需大于0",
    powerAllocate_00041: "限制充电总功率时段需从00:00开始至24:00结束",
    powerAllocate_00042: "整站功率不得为负数",
    powerAllocate_00043: "整站功率最多支持两位小数",
    powerAllocate_00044: "场站详情",
    powerAllocate_00045: "组内功率分配设置",
    powerAllocate_00046: "只有未启用状态下才可设置",
    powerAllocate_00047: "组内充电口数",
    powerAllocate_00048: "组内所有型号的充电口离线最大充电功率*组内充电口数之和，需小于等于组内限制充电总功率设置中的最小值",
    powerAllocate_00049: "组内限制充电总功率不支持负数、不支持0",
    powerAllocate_00050: "组内充电口离线最大充电功率需大于等于此型号充电口最小充电功率",
    powerAllocate_00051: "充电口离线最大充电功率(kW)",
    powerAllocate_00052: "可以为组内的充电口按产品型号设置预留的离线最大充电功率",
    powerAllocate_00053: "设置时，需大于等于此型号充电口最小充电功率。默认为此型号的充电口最小充电功率。",
    powerAllocate_00054: "充电口离线最大充电功率：平台自动分配充电功率时，当有充电口离线时，平台为此充电口预留的离线最大充电功率。",
    powerAllocate_00055:"所有型号的充电口离线最大充电功率*组内充电口之和，需小于等于组内限制充电总功率设置中的最小值。",
    powerAllocate_00056: '可以按时段设置组内限制充电总功率，确保站内充电桩的充电功率不超出各个时段的组内限制充电总功率。',
    powerAllocate_00057: "组内充电口离线最大充电功率设置",
}