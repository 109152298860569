export default {
    managementDepot_00001: 'Hofverwaltung',
    managementDepot_00002: 'Bahnhofszentrum',
    managementDepot_00003: 'Aufladestation',
    managementDepot_00004: 'Aufladestation',
    managementDepot_00005: 'verfügbar',
    managementDepot_00006: 'Verwenden von',
    managementDepot_00007: 'Fehler',
    managementDepot_00008: 'alle',
    managementDepot_00009: 'aktiviert',
    managementDepot_00010: 'Nicht aktiviert',
    managementDepot_00011: 'offen',
    managementDepot_00012: 'Nicht offen',
    managementDepot_00013: 'Personenkraftwagen',
    managementDepot_00014: 'Logistikfahrzeug',
    managementDepot_00015: 'Kleinbus',
    managementDepot_00016: 'Bus',
    managementDepot_00017: 'Motorrad',
    managementDepot_00018: 'DC',
    managementDepot_00019: 'AC',
    managementDepot_00020: 'Stationsstandort',
    managementDepot_00021: 'Nation:',
    managementDepot_00022: 'Provinz:',
    managementDepot_00023: 'Stadt:',
    managementDepot_00024: 'Bereich:',
    managementDepot_00025: 'Bitte wähle ein Land',
    managementDepot_00026: 'Bitte Provinz auswählen',
    managementDepot_00027: 'Bitte Stadt auswählen',
    managementDepot_00028: 'Bitte wählen Sie eine Region',
    managementDepot_00029: 'Zurücksetzen',
    managementDepot_00030: 'Anfragen',
    managementDepot_00031: 'Stationstyp',
    managementDepot_00032: 'Alle Stationen',
    managementDepot_00033: 'Eigenbetriebene Station',
    managementDepot_00034: 'Affiliate-Mall-Station',
    managementDepot_00035: 'Bitte geben Sie den Betreibernamen ein',
    managementDepot_00036: 'Model',
    managementDepot_00037: 'Alle',
    managementDepot_00038: 'Abbrechen',
    managementDepot_00039: 'Charge',
    managementDepot_00040: 'installieren',
    managementDepot_00041: 'Erstellen Sie eine Station',
    managementDepot_00042: 'Details',
    managementDepot_00043: 'Geräteverwaltung',
    managementDepot_00044: 'Tarif',
    managementDepot_00045: 'Betriebsführung',
    managementDepot_00046: 'Befehl',
    managementDepot_00047: 'Stationsname',
    managementDepot_00048: 'Alle Modelle',
    managementDepot_00049: 'Bitte wählen Sie einen Sender',

    managementDepot_00050: 'DC-Ladeanschluss',
    managementDepot_00051: 'AC-Ladeanschluss',
    managementDepot_00052: 'verfügbar',
    managementDepot_00053: 'Verwenden von',
    managementDepot_00054: 'Fehler',
    managementDepot_00055: 'suchen',
    managementDepot_00056: 'Konflikt beim Währungstyp'
}