export default {
    diversionDetail_00001: 'Access details',
    diversionDetail_00002: 'Data flow Center',
    diversionDetail_00003: 'Open data flow',
    diversionDetail_00004: 'This data flow is already compatible with the SmartCharging QR code, you need to upgrade to a fixed QR code in the charger details',
    diversionDetail_00005: 'This data flow is not compatible with the SmartCharging QR code, you need to print the QR code and paste it',
    diversionDetail_00006: 'Please enter the charging site name',
    diversionDetail_00007: 'List of charging site',
    diversionDetail_00008: 'Reserve management',
    diversionDetail_00009: 'Overall Discount',
    diversionDetail_00010: 'Charging site name',
    diversionDetail_00011: 'CPO',
    diversionDetail_00012: 'Charging site Status',
    diversionDetail_00013: 'Enabled',
    diversionDetail_00014: 'Not enabled',
    diversionDetail_00015: 'Charger',
    diversionDetail_00016: 'AC',
    diversionDetail_00017: 'DC',
    diversionDetail_00018: 'Open status to access data flow',
    diversionDetail_00019: 'Close Open',
    diversionDetail_00020: 'Agree to open',
    diversionDetail_00021: 'Operate',
    diversionDetail_00022: 'Bulk Export QR Code',
    diversionDetail_00023: 'Tariff settings',
    diversionDetail_00024: 'Charging site Discounts',
    diversionDetail_00025: 'Start/stop settings',
    diversionDetail_00026: 'EV operation platform',
    diversionDetail_00027: 'Aggregation platform',
    diversionDetail_00028: 'OEM platform',
    diversionDetail_00029: 'Government platform',
    diversionDetail_00030: 'Map Platform',
    diversionDetail_00031: 'Government',
    diversionDetail_00032: 'Free',
    diversionDetail_00033: 'direct sign up',
    diversionDetail_00034: 'SmartCharge General Contract',
    diversionDetail_00035: 'Access details-',
    diversionDetail_00036: 'Passenger Cars',
    diversionDetail_00037: 'Logistics vehicles',
    diversionDetail_00038: 'Medium bus',
    diversionDetail_00039: 'Bus',
    diversionDetail_00040: 'Motorbike',
    diversionDetail_00041: 'Opened - no start/stop',
    diversionDetail_00042: 'Open - can be started and stopped',
    diversionDetail_00043: 'Not open',
    diversionDetail_00044: 'Opened',
    diversionDetail_00045: 'No charger enabled at the station, please enable at least one charger.' ,
    diversionDetail_00046: 'Station information inside ',
    diversionDetail_00047: 'Country code,',
    diversionDetail_00048: 'Provincial and municipal codes,',
    diversionDetail_00049: 'Parking fee,',
    diversionDetail_00050: 'Charging site type,',
    diversionDetail_00051: 'Building site,',
    diversionDetail_00052: 'Number of parking spaces,',
    diversionDetail_00053: 'Customer service number,',
    diversionDetail_00054: 'Real-scene pictures,',
    diversionDetail_00055: 'Is empty',
    diversionDetail_00056: 'Please set "Open" in [Site Details] - [Site Info] - [Status Info].' ,
    diversionDetail_00057: 'Charging station',
    diversionDetail_00058: 'Smart Charging',
    diversionDetail_00059: 'Reserve Recharge History',
    diversionDetail_00060: 'Third party traffic platform',
    diversionDetail_00061: 'Whether reserve funds',
    diversionDetail_00062: 'Current balance',
    diversionDetail_00063: 'Reserve amount',
    diversionDetail_00064: 'Reserve top-up',
    diversionDetail_00065: 'Can be filled with a negative value to offset the account',
    diversionDetail_00066: 'Remind balance',
    diversionDetail_00067: 'Automatically stop charging when balance is less than 0',
    diversionDetail_00068: 'Yes',
    diversionDetail_00069: 'No',
    diversionDetail_00070: 'Top-up amount',
    diversionDetail_00071: 'Pre top-up Time',
    diversionDetail_00072: 'Return',
    diversionDetail_00073: 'Modified successfully',
    diversionDetail_00074: 'Please enter a number',
    diversionDetail_00075: 'Discount on service fee (%) granted by operator to SmartCharge',
    diversionDetail_00076: 'No discount on the total cost of a single charge (%)',
    diversionDetail_00077: 'Single charging service fee discount (%)',
    diversionDetail_00078: '85 means 15% off, 0 means total free',
    diversionDetail_00079: 'Discount per kWh for single charging session',
    diversionDetail_00080: 'Display name',
    diversionDetail_00081: 'Please enter the display name, recommended format: brand name of charging site (site name), the keyword "brand name" needs to be removed from the site name',
    diversionDetail_00082: 'Whether to allow third party start/stop chargers',
    diversionDetail_00083: 'Only allow certain users to charge',
    diversionDetail_00084: 'Think again',
    diversionDetail_00085: 'I got it',
    diversionDetail_00086: 'Fill in',
    diversionDetail_00087: 'Name cannot be empty',
    diversionDetail_00088: 'Open successfully',
    diversionDetail_00089: 'Prompt message',
    diversionDetail_00090: 'Fill in the information',
    diversionDetail_00091: 'OK',
    diversionDetail_00092: 'Allow third party start/stop chargers',
    diversionDetail_00093: 'Direct tariff setting',
    diversionDetail_00094: 'If the tariff has been changed, click on the "Push Tariff" button in Site Details - Tariff to make the tariff effective!' ,
    diversionDetail_00095: 'AC tariffs',
    diversionDetail_00096: 'Select AC tariff',
    diversionDetail_00097: 'DC tariff',
    diversionDetail_00098: 'Select DC tariff',
    diversionDetail_00099: 'Site discount settings',
    diversionDetail_00100: 'Add site discount',
    diversionDetail_00101: 'Start time',
    diversionDetail_00102: 'Now',
    diversionDetail_00103: 'End time',
    diversionDetail_00104: 'unlimited',
    diversionDetail_00105: 'Offer method',
    diversionDetail_00106: '85 discount means 15% off',
    diversionDetail_00107: 'Discount per kWh (€)',
    diversionDetail_00108: 'Use the charging service discount method',
    diversionDetail_00109: 'Use per kwh discount method',
    diversionDetail_00110: 'Status',
    diversionDetail_00111: 'Not started',
    diversionDetail_00112: 'Started',
    diversionDetail_00113: 'Valued',
    diversionDetail_00114: 'Not Valued',
    diversionDetail_00115: 'Site Discount History',
    diversionDetail_00116: 'Please save the previous action!',
    diversionDetail_00117: 'Start time cannot be empty',
    diversionDetail_00118: 'End time connot be empty',
    diversionDetail_00119: 'Preferred method cannot be left blank',
    diversionDetail_00120: 'Please enter a positive integer less than 100',
    diversionDetail_00121: 'Saved successfully',
    diversionDetail_00122: 'Service fee discount: {dis}%',
    diversionDetail_00123: 'Discount per kWh:{dis}%',
    diversionDetail_00124: 'Stop open to {name} site information, {name} will not show any charging site information.' ,
    diversionDetail_00125: 'Confirm stop open to {name} site information?' ,
    diversionDetail_00126: 'Push device status',
    diversionDetail_00127: 'Failed to push device status, please try again!',
    diversionDetail_00128: 'Push device status successfully!',
    diversionDetail_00129: 'Push actual power',
    detailDepot_30011: "Please fill out the form completely",
  }