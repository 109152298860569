var list = {
	1:"[GtoV] Only allow to charge the Vehicle using the Grid",
	2:"[BtoV] Only allow to charge the Vehicle using the NZS Battery（force discharge）",
	3:"[GtoB] Only allow to charge the NZS Battery using the Grid（force charge）",
	4:"[GtoV > BtoV] Don't allow to charge the NZS Battery, charge the Vehicle using the AC module first, then the DC module",
	5:"[GtoV > GtoB] Don't allow to Discharge the NZS Battery, charge the NZS Battery while no Vehicle is charging, and only use the AC module to charge the Vehicle", 
	6:"[BtoV > GtoV] Don't allow to charge the NZS Battery, charge the Vehicle using the DC mudule first, then the AC module",
	7:"[BtoV > GtoB] Use the NZS Battery to charge the Vehicle, and recharge the NZS Battery when no Vehicle is charging",
	8:"[GtoV > BtoV > GtoB] Charge the NZS Battery while no Vehicle is charging, charge the Vehicle using the AC module first, then the DC module",
	9:"[BtoV > GtoV > GtoB] Charge the NZS Battery while no Vehicle is charging, charge the Vehicle using the DC module first, then the AC module",
	10:"[GtoB > GtoV] Fully charge the NZS Battery first, then charge the Vehicle using the Grid",
	11:'[toG] Automatically connect to the Grid, not allowed to charge any Vehicle',
	12:'[toG > toV]  Perferentially connect to the Grid, allowed to charge Vehicles',
	13:'[Max] Full power mode',
	14:'[Eco] Economic model',
	15:'[Auto] Automatic mode',
}
module.exports = list;