export default {
    wra_10000:'暂无数据',

    wra_00000:'复制',
    wra_00001:'财务中心',
    wra_00002:'常用功能',
    wra_00003:'用户昵称/手机号',
    wra_00004:'开始日期',
    wra_00005:'结束日期',
    wra_00006:'申请状态',
    wra_00007:'查询',
    wra_00008:'重置',

    wra_00009:'复制成功！',

    wra_00010:'今天',
    wra_00011:'昨天',
    wra_00012:'7天前',
    wra_00013:'15天前',
    wra_00014:'30天前',

    wra_00015:'全部',
    wra_00016:'待确认',
    wra_00017:'已完成',
    wra_00018:'已取消',
    wra_00019:'待完成',

    wra_00020:'',
    wra_00021:'微信商户企业付款所需AppID',

    wra_00022:'导出已退款数据',
    wra_00023:'此操作将下载退款订单的Excel文件, 是否继续?',
    wra_00024: "提示",
    wra_00025: "确定",
    wra_00026: "取消",
    wra_00027: "开始下载",
    wra_00028: "取消下载",
    wra_00029: "下载批量退款文件",
    wra_00030: "下载批量退款文件(微信支付特约商户)",

    wra_00031:'昵称',
    wra_00032:'手机号',

    wra_00033:'元',

    wra_00034:'需退款金额',
    wra_00035:'已退',
    wra_00036:'未退',

    wra_00037:'完成退款',
    wra_00038:'确认受理',
    wra_00039: '取消受理',
    
    wra_00040:'需退款金额组成',
    wra_00041:'微信支付需退款金额',
    wra_00042:'支付宝需退款金额',
    wra_00043: '无充值订单匹配退款金额',
    
    wra_00044:'退款受理结果',
    wra_00045:'系统自动退款（微信支付）',
    wra_00046:'系统自动退款（支付宝）',
    wra_00047: '{name}人工退款',
    
    wra_00048:'未完成退款原因',
    wra_00049:'无',

    wra_00050:'您是否已在微信商户平台进行退款？',
    wra_00051:'修改',
    wra_00052:'操作成功,等待微信结果通知',
    wra_00053:'等待微信结果通知',
    wra_00054:'申请成功，等待微信结果通知',
    wra_00055:'“由于{cause}无法完成自动退款”的申请',
    wra_00056:'系统原因',
    wra_00057:'等待微信结果通知',
    wra_00058:'证书过期，无法自动退款',
    wra_00059:'充值订单超期，',
    wra_00060:'商户可用退款余额不足',
    wra_00061:'已部分退款成功，无法取消受理',
    wra_00062:'修改成功',

    wra_00063:'退款中...',

    wra_00064:'时间段超过31天，请重新选择'



}