<template lang="html">
    <el-dialog
        title="提示"
        :visible.sync="flag"
        width="30%"
        :before-close="close">
        <div class="confirm-title" v-if="invoiceMode===0">您是否已经寄出纸质发票？</div>
        <div class="confirm-title" v-else>您是否已经将电子发票发到用户手机上？</div>
        <span slot="footer" class="dialog-footer">
            <el-button  @click="close">取 消</el-button>
            <el-button type="primary" permissionInfo="invoiceInfo" v-setPermission @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {
    requestUrl,
    ajax,
} from "@/assets/utils/network";
export default {
    props: ["flag","message","invoiceMode"],
    methods: {
        close() {
            this.flag = false;
            this.$emit("backFlag", this.flag);
        },
        confirm(){
            if(!this.message || this.message != 0){
                var om = this;
                var url = requestUrl + "/wechatinvoice";
                var param = new Object();
                param.id = this.message;
                param.action = "finish";
                ajax({
                    type: "POST",
                    url: url,
                    dataType: "json",
                    data:JSON.stringify(param),
                    success: function(response) {
                        var result = JSON.parse(response);
                        if(result && result.msg){
                            om.$message({
                                message: result.msg,
                                type: 'error'
                            });
                        }else{
                            om.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                            var obj = new Object();
                            obj.type = 'finished';
                            obj.status='success';
                            om.$emit("dispatch", obj);
                            om.close();
                        }
                    }
                });
            }else{
                om.$message({
                    message: '操作失败',
                    type: 'error'
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog{
    min-width: 500px;
}
</style>
