export default {
    contrastCurve_00001: '充电曲线对比',
    contrastCurve_00002: '订单中心',
    contrastCurve_00003: '全部充电订单',
    contrastCurve_00004: '充电订单详情',
    contrastCurve_00005: '充电曲线对比',
    contrastCurve_00006: '订单信息',
    contrastCurve_00007: '充电信息',
    contrastCurve_00008: '当前订单',
    contrastCurve_00009: '同期同桩订单',
    contrastCurve_00010: '充电电量',
    contrastCurve_00011: '度',
    contrastCurve_00012: '查看充电订单详情',
    contrastCurve_00013: '功率',
    contrastCurve_00014: '电压',
    contrastCurve_00015: '需求电压',
    contrastCurve_00016: '电流',
    contrastCurve_00017: '需求电流',
    contrastCurve_00018: '天',
    contrastCurve_00019: '小时',
    contrastCurve_00020: '分钟',
    contrastCurve_00021: '团体',
    contrastCurve_00022: '微信用户',
    contrastCurve_00023: "管理员",

    contrastCurve_00024: '开始时间',
    contrastCurve_00025: '结束时间',
    contrastCurve_00026: '充电时长',
    contrastCurve_00027: "订单编号",
    contrastCurve_00028: "微信用户",
    contrastCurve_00029: "启停卡",
    contrastCurve_00030: "储值卡",
    contrastCurve_00031: "身份卡",
    contrastCurve_00032: "智充配置卡",
    contrastCurve_00033: "集团配置卡",
    contrastCurve_00034: "离线数据读取卡",
    contrastCurve_00035: "备份数据",
    contrastCurve_00036: "充电曲线",
    contrastCurve_00037: "车端需求电压(虚线)",
    contrastCurve_00038: "车端需求电流(虚线)",
    contrastCurve_00039: "银行卡",
}