export default {
    powerMaint_00001: 'The Charger need maintain power modules',
    powerMaint_00002: '',
    powerMaint_00003: 'Remind for maintenance of the power module',
    powerMaint_00004: 'Cumulative time from reset time',
    powerMaint_00005: 'Reset time: ',
    powerMaint_00006: '360 hours',
    powerMaint_00007: 'Normal',
    powerMaint_00008: 'Faulted',
    powerMaint_00009: "Are you sure you have completed this maintenance for this charging point's power module? After clicking OK, the power module needs maintenance prompt to retime.",
    powerMaint_00010: 'Tips',
    powerMaint_00011: 'Confirm',
    powerMaint_00012: 'Cancel',
    powerMaint_00013: 'Success',
    powerMaint_10000: 'The charger need maintenance',
    powerMaint_10001: 'Light time',
    powerMaint_10002: 'Used',
    powerMaint_10003: 'hour',
    powerMaint_10004: 'time',
    powerMaint_10005: 'Maintenance timing prompt threshold: ',
    powerMaint_10006: 'The cumulative usage time since the reset time is greater than or equal to',
  }