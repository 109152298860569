export default {
    stationMaintenanceAnalysis_00001: "Analyse der Stationsanomalie",
    stationMaintenanceAnalysis_00002: "Maintenance homepage",
    stationMaintenanceAnalysis_00003: "anormale Rate der Stapelverantwortung nach Anzahl der anormalen Ladeaufträge dieser Station an diesem Tag und Anzahl der Ladeaufträge dieser Station an diesem Tag",
    stationMaintenanceAnalysis_00004: "anormaler Satz der Fahrzeughaftung in Abhängigkeit von Anzahl anormaler Ladeaufträge dieser Station in Abhängigkeit von Anzahl anormaler Ladeaufträge dieser Station",
    stationMaintenanceAnalysis_00005: "Trendanalyse",
    stationMaintenanceAnalysis_00006: "tägliche Analyse",
    stationMaintenanceAnalysis_00007: "Analyse der anormalen Rate der Stapelverantwortung",
    stationMaintenanceAnalysis_00008: "Analyse der anormalen Haftung von Fahrzeugen",
    stationMaintenanceAnalysis_00009: "Analyse der anormalen Rate der Stationsausrüstung",
    stationMaintenanceAnalysis_00010: "Stapelverantwortung abnormale Ordnung",
    stationMaintenanceAnalysis_00011: "Ausnahmecode für Stapelverantwortung",
    stationMaintenanceAnalysis_00012: "anormale Rate der Stapelverantwortung {num}%",
    stationMaintenanceAnalysis_00013: "Bestellnummer",
    stationMaintenanceAnalysis_00014: "Ausnahme",
    stationMaintenanceAnalysis_00015: "Ladeanschluss",
    stationMaintenanceAnalysis_00016: "Device Exception Log",
    stationMaintenanceAnalysis_00017: "Bestelldetails",
    stationMaintenanceAnalysis_00018: "Gerätedetails",
    stationMaintenanceAnalysis_00019: "Produktionsauftrag einleiten",
    stationMaintenanceAnalysis_00020: "anormale Reihenfolge der Fahrzeugverantwortung",
    stationMaintenanceAnalysis_00021: "Stationsausrüstung",
    stationMaintenanceAnalysis_00022: "anormale Rate der Stapelverantwortung für die Anzahl der anormalen Aufladungsaufträge dieser Stapelverantwortung an diesem Tag und die Anzahl der Aufladungsaufträge dieses Stapels an diesem Tag",
    stationMaintenanceAnalysis_00023: "anormale Rate der Fahrzeugverantwortung für die Anzahl der anormalen Ladeaufträge dieses Stapels an diesem Tag und die Anzahl der Ladeaufträge dieses Stapels an diesem Tag",
    stationMaintenanceAnalysis_00024: "Analyse anormaler Raten",
    stationMaintenanceAnalysis_00025: "Fahrzeug-VIN",
    stationMaintenanceAnalysis_00026: "Kennzeichen",
    stationMaintenanceAnalysis_00027: "Fahrzeugtyp",
    stationMaintenanceAnalysis_00028: "Fahrzeugladeprotokoll",
    stationMaintenanceAnalysis_00029: "Gerätename",
    stationMaintenanceAnalysis_00030: "Gerätemodell",
    stationMaintenanceAnalysis_00031: "Seriennummer der Ausrüstung",
    stationMaintenanceAnalysis_00032: "anormale Rate der Stapelverantwortung",
    stationMaintenanceAnalysis_00033: "anormaler Satz der Fahrzeughaftung",
    stationMaintenanceAnalysis_00034: "Produktionsauftrag anzeigen",
    stationMaintenanceAnalysis_10000: "anormaler Satz der Fahrzeughaftung {num}%",
    stationMaintenanceAnalysis_10001: "Ausnahmecode für die Fahrzeugverantwortung",
    stationMaintenanceAnalysis_10002: "keine Ausnahme vorübergehend",
    stationMaintenanceAnalysis_10003: "Analyse der Abweichungsrate der Ausrüstung",
    stationMaintenanceAnalysis_10004: "Anteil der Ausnahmecodes",
    stationMaintenanceAnalysis_10005: "Ausnahmecode",
}