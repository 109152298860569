export default {
    login_00001: "Melden Sie sich bei der Betreiberplattform an",
    login_00002: "Login-Konto",
    login_00003: "Passwort eingeben",
    login_00004: "Anmelden",
    login_00005: "Nutzername und Passwort dürfen nicht leer sein",
    login_00006: "Betreiberplattform",
    login_00007: "Ich habe mich bereits registriert",
    login_00008: "Anmelden",
    login_00009: "Vereinfachung des<br/>gemeinsamen Ladens",
    login_00010: "XCHARGE hat ein eigenes, voll funktionsfähiges Managementsystem für digitale Ladevorgänge entwickelt: die XCHARGE SaaS Platform V3.0, die auf dem Geschäftsmodell verschiedener Betreiber und einem tiefen Einblick in die Kundenbedürfnisse basiert.",
    login_00011: "",
    login_00012: "Willkommen zur Betreiberplattform",
    login_00013: "Login Name",
    login_00014: "Bitte geben Sie den Login-Name ein",
    login_00015: "Passwort",
    login_00016: "Bitte geben Sie ein Passwort ein",
    login_00017: "Noch nicht registriert? ",
    login_00018: "Registrieren",
    login_00019: "Treten Sie dem CPO-Bundnis bei",
    login_00020: "Unsere privateigentum ist privat.",
    login_00021: "Jawohl, sir!",
    login_00022: "Nach der registrierung brauchst du einen eingaben",
    login_00023: "Dass sie sich der batterie-einheit anschließen möchten",
    login_00024: "Jeder kunde hat eine zahnklammer.",
    login_00025: "Jemand, der zahlungen leiht",
    login_00026: "Was ist mit den kosten zwischen den telefonleitungen und dem fortschritt zwischen ihnen zu tun?",
    login_00027: "Was sie mit ihren förderplattformen zu tun haben.",
    login_00028: "* Überprüfen sie die einladungen für den kostenvoranschlag",
    login_00029: 'Es kann die batterie des fahrzeugs neu laden und den authentifizierungscode zurückscannen',
    login_00030: 'Der eigentümer setzt den betrag einfach in das privatbankkonto ein',
    login_00031: "Katastercode. Nein.",
    login_00032: "Der benutzercode für den jeweiligen verkauf ist angegeben",
    login_00033: "Du hast die kombination vergessen?",
}