export default {
    rates_00001: '资费管理',
    rates_00002: '场站中心',
    rates_00003: '资费名称',
    rates_00004: '是否已应用',
    rates_00005: '全部',
    rates_00006: '是',
    rates_00007: '否',
    rates_00008: '重置',
    rates_00009: '查询',
    rates_00010: '新建资费',
    rates_00011: '操作',
    rates_00012: '已应用',
    rates_00013: '未应用',
    rates_00014: '修改',
    rates_00015: '复制',
    rates_00016: '详情',
    rates_00017: '删除',
    rates_00018: '推送资费',
    rates_00019: '请填写资费名称',
    rates_00020: '请填写资费标准',
    rates_00021: '请填写站点默认资费标准',
    rates_00022: '资费时段需从0开始至24结束',
    rates_00023: '请填写正确的资费标准',
    rates_00024: '该资费名称已被占用',
    rates_00025: '修改资费',
    rates_00026: '复制资费',
    rates_00027: '添加时段',
    rates_00028: '最多可以添加{0}个时段',
    rates_00029: '开始时间',
    rates_00030: '结束时间',
    rates_00031: '电费({type}/{unit})',
    rates_00032: '服务费({type}/{unit})',
    rates_00033: '延时费({type}/{unit})',
    rates_00034: '谷峰平时段',
    rates_00035: '未定义',
    rates_00036: '取 消',
    rates_00037: '确 定',
    rates_00038: '推送结果',
    rates_00039: '站点名称',
    rates_00040: '设备数量',
    rates_00041: '设备推送结果',
    rates_00042: '直',
    rates_00043: '交',
    rates_00044: '删除后将无法恢复, 是否继续?',
    rates_00045: '提示',
    rates_00046: '删除成功',
    rates_00047: '新建成功',
    rates_00048: '尖',
    rates_00049: '峰',
    rates_00050: '平',
    rates_00051: '谷',
    rates_00052: '成功',
    rates_00053: '推送中',
    rates_00054: '失败',
    rates_00055: '推送失败',
    rates_00056: '资费详情',
    rates_00057: '编辑成功',
    rates_00058: '确认推送',
    rates_00059: '关闭',
    rates_00060: '使用货币',
    // ****
    rates_00061: '推送',
    rates_00062: '应用范围',
    rates_00063: '场站公共资费',
    rates_00064: '团体资费',
    rates_00065: '第三方流量资费',
    rates_00066: '资费列表',
    rates_00067: '资费',
    rates_00068: '推送对象',
    rates_00069: '请选择站点',
    rates_00070: '请选择团体',
    rates_00071: '请选择第三方流量',
    rates_00072: '推送给交流桩',
    rates_00073: '推送给直流桩',
    rates_00074: '已绑定',
    rates_00075: '重试绑定',
    rates_00076: '请先绑定有桩的站点',
    // 电网资费
    rates_00077: '资费类型',
    rates_00078: '电网资费',
    rates_00079: '充电资费',
    rates_00080: '尖峰平谷对应价格',
    rates_00081: '尖峰谷平时段',
    rates_00082: '电网电费({type}/{unit})',
    rates_00083: '上一步',
    rates_00084: '下一步',
    rates_00085: '请选择资费的尖峰平谷时间段',
    rates_00086: '直流资费',
    rates_00087: '交流资费',
    rates_00088: '公共资费',
    rates_00089: '团体',
    rates_00090: '第三方',
    rates_00091: '是否确认绑定该资费到',
    rates_00092: '站点',
    rates_00093: '的',
    rates_00094: '储充',
    rates_00095: '留在资费管理',
    rates_00096: '查看推送任务',
    rates_00097: '选择推送方式',
    rates_00098: '计划推送时间',
    rates_00099: '请设置资费应用的范围，系统将在推送任务执行时，自动将当前资费设置到所选的设备上，自动推送资费成功后，设备才能以新的资费进行结算。注意：非智充桩可能会不支持该操作导致推送失败。',
    rates_00100: "复制应用范围",
    rates_00101: "当前资费没有被应用",
    rates_00102: "请选择资费",
    rates_00103: "",
    rates_00104: "",
    rates_00105: "",
    rates_00106: "",
};