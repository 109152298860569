export default {
  invoiceDetails_00001: "发票申请详情",
  invoiceDetails_00002: "申请信息",
  invoiceDetails_00003: "申请人",
  invoiceDetails_00004: "发票类型",
  invoiceDetails_00005: "公司名称",
  invoiceDetails_00006: "公司地址",
  invoiceDetails_00007: "纳税人识别号",
  invoiceDetails_00008: "开户行",
  invoiceDetails_00009: "开户行账号",
  invoiceDetails_00010: "公司电话",
  invoiceDetails_00011: "收件人",
  invoiceDetails_00012: "手机号",
  invoiceDetails_00013: "邮寄地址",
  invoiceDetails_00014: "备注",
  invoiceDetails_00015: "邮箱",
  invoiceDetails_00016: "申请进度",
  invoiceDetails_00017: "申请账单",
  invoiceDetails_00018: "账单实付金额",
  invoiceDetails_00019: "收费款项",
  invoiceDetails_00020: "账单编号",
  invoiceDetails_00021: "收费项目",
  invoiceDetails_00022: "收费时段",
  invoiceDetails_00023: "收费版本",
  invoiceDetails_00024: "生成日期",
  invoiceDetails_00025: "增值税普通发票",
  invoiceDetails_00026: "增值税专用发票",
  invoiceDetails_00027: "待处理",
  invoiceDetails_00028: "已开票",
  invoiceDetails_00029: "已取消",
  invoiceDetails_00030: "已拒绝",
  invoiceDetails_00031: "物联网卡",
  invoiceDetails_00032: "一次性收费",
  invoiceDetails_00033: "平台使用费",
  invoiceDetails_00034: "基础版",
  invoiceDetails_00035: "高级版",
  invoiceDetails_00036: "定制版",
  invoiceDetails_00037: "共计 {0}笔账单",
  invoiceDetails_00038: "您的发票已提交，请耐心等待处理，勿重复提交申请",
  invoiceDetails_00039: "原因",
  invoiceDetails_00040: "您申请的纸质发票已邮寄，物流信息",
  invoiceDetails_00041: "您申请的电子发票已发送，请检查您申请时填写手机号或邮箱",
  invoiceDetails_00042: "申请时间",

}