export default {
    feedback_00001: 'WeChat Customer Service',
    feedback_00002: 'customer service',
    feedback_00003: 'Please enter user nickname',
    feedback_00004: 'Please enter the content',
    feedback_00005: 'Start date',
    feedback_00006: 'End date',
    feedback_00007: 'to',
    feedback_00008: 'Message list',
    feedback_00009: 'Due to WeChat official account restrictions, messages cannot be responded to within 48 hours',
    feedback_00010: 'Replied-timed out',
    feedback_00011: 'No reply-timed out',
    feedback_00012: 'Unanswered - ',
    feedback_00013: 'Replied - ',
    feedback_00014: 'Official account background link',
    feedback_00015: 'reply',
    feedback_00016: 'Please enter the reply content',
    feedback_00017: 'send',
    feedback_00018: 'hour ago',
    feedback_00019: 'minutes ago',
    feedback_00020: 'The reply message cannot be empty',
    feedback_00021: 'Reply successfully',
    feedback_00022: 'Administrator',
    feedback_00023: "Text Message",
    feedback_00024: "Picture Message",
    feedback_00025: "Voice Message",
    feedback_00026: "Video Message",
    feedback_00027: "Small video message",
    feedback_00028: "Link Message",
    feedback_00029: "Location Message",
    feedback_00030: "The message type is {0}, please check it in the background of the WeChat official account!",
  }