export default {
  pushTask_00001: '任务列表',
  pushTask_00002: '场站公共资费',
  pushTask_00003: '团体资费',
  pushTask_00004: '第三方流量资费',
  pushTask_00005: '已应用',
  pushTask_00006: '未应用',
  pushTask_00007: '推送状态',
  pushTask_00008: '已推送',
  pushTask_00009: '未推送',
  pushTask_00010: '推送中',
  pushTask_00011: '计划推送时间：{0}',
  pushTask_00012: '推送结果({0}/{1})',
  pushTask_00013: '推送时间：{0}',
  pushTask_00014: '是否取消推送',
  pushTask_00015: '应用站点',
  pushTask_00016: '资费详情',
  pushTask_00017: '推送资费详情',
  pushTask_00018: '自动重新推送{0}次',
  pushTask_00019: '{0}分钟后再次重推',
  pushTask_00020: '已停止自动重推',
  pushTask_00021: '重推中',
  rates_00031: '电费({type}/{unit})',
  rates_00032: '服务费({type}/{unit})',
  rates_00033: '延时费({type}/{unit})',
  rates_00034: '谷峰平时段',
};