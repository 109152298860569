export default {
    selfHelpPayCost_00001: 'Pay Service',
    selfHelpPayCost_00002: 'Ladestation',
    selfHelpPayCost_00003: 'Standort',
    selfHelpPayCost_00004: 'Dauer',
    selfHelpPayCost_00005: 'Betrag (yuan)',
    selfHelpPayCost_00006: 'yuan',
    selfHelpPayCost_00007: 'Jahr',
    selfHelpPayCost_00008: 'Monat',
    selfHelpPayCost_00009: 'Bill details',
    selfHelpPayCost_00010: 'Pending bill details',
    selfHelpPayCost_00011: 'Internet of Things Card',
    selfHelpPayCost_00012: 'Angaben zum Konto',
    selfHelpPayCost_00013: 'Detaillierte Bilanz',
    selfHelpPayCost_00014: 'Aufladung',
    selfHelpPayCost_00015: 'Abzug der Gebühren',
    selfHelpPayCost_00016: 'Zeit',
    selfHelpPayCost_00017: 'Inhalt',
    selfHelpPayCost_00018: 'Menge (yuan)',
    selfHelpPayCost_00019: 'Bemerkungen',
    selfHelpPayCost_00020: 'Wechat Zahlung（CPO）',
    selfHelpPayCost_00021: 'Zahlung Alipay（CPO）',
    selfHelpPayCost_00022: 'Xcharge',
    selfHelpPayCost_00023: 'Rechnung',
    selfHelpPayCost_00024: 'Rechnung berechnen',
    selfHelpPayCost_00025: 'Die Charge des Vormonats wird am ersten Monat um 10:00 ausgestellt. Bitte stellen Sie sicher, dass der Kontostand vor 19:30 am zehnten Monat vollständig zurückerstattet werden kann, um überfällige Zahlungsrückstände zu vermeiden, die sich auf die entsprechenden Leistungen auswirken.',
    selfHelpPayCost_00026: 'Betrag der Abgabe',
    selfHelpPayCost_00027: 'Deduziert',
    selfHelpPayCost_00028: 'Kein Abzug',
    selfHelpPayCost_00029: 'Arrears',
    selfHelpPayCost_00030: 'Fakturiert',
    selfHelpPayCost_00031: 'Nicht in Rechnung gestellt',
    selfHelpPayCost_00032: 'Details',
    selfHelpPayCost_00033: '365 days after the charging pile is allocated to the operator, {money} yuan/pile/30 days.',
    selfHelpPayCost_00034: 'Datum der Abrechnung',
    selfHelpPayCost_00035: 'Aktueller Betrag',
    selfHelpPayCost_00036: 'Zyklus der Aufladung',
    selfHelpPayCost_00037: 'Saldo des Kontos',
    selfHelpPayCost_00038: 'Menge der Ladung',
    selfHelpPayCost_00039: 'Wählen Sie Zahlungsmethode',
    selfHelpPayCost_00040: 'Zahlung von Wechat',
    selfHelpPayCost_00041: 'Zahlung Alipay',
    selfHelpPayCost_00042: 'Sonstige Beträge',
    selfHelpPayCost_00043: 'Zahlung abgeschlossen',
    selfHelpPayCost_00044: 'Vorheriger Schritt',
    selfHelpPayCost_00045: 'Laden des Kontos',
    selfHelpPayCost_00046: 'Ungefähre Rechnung',
    selfHelpPayCost_00047: 'Gezahlter, aber nicht abgezogen Betrag',
    selfHelpPayCost_00048: 'Arrears',
    selfHelpPayCost_00049: 'insgesamt',
    selfHelpPayCost_00050: 'Bitte geben Sie die Lademenge ein',
    selfHelpPayCost_00051: 'Wählen Sie Zahlungsmethode',
    selfHelpPayCost_00052: 'Sie können keine negative Zahl eingeben',
    selfHelpPayCost_00053: 'Der Ladevorgang erfolgreich',
    selfHelpPayCost_00054: 'Aufladung fehlgeschlagen',
    selfHelpPayCost_00055: 'Bitte geben Sie die korrekte Lademenge ein',
    selfHelpPayCost_00056: 'Regeln für das Laden',
    selfHelpPayCost_00057: 'IOT Karte Netzwerkgebühr - nach der Zuweisung des Ladestapels an cpo365 ist es 12.5 yuan /pile / 30 Tage.',
    selfHelpPayCost_100058: '公对公转账',
    selfHelpPayCost_100059: '1、您可以使用您的企业网银公对公转账方式进行充值，平台会根据您转账交易流水中的付款人名称和转账金额自动匹配为您的平台账户充值。',
    selfHelpPayCost_100060: '2、请注意您的付款人名称需和您申请平台注册码时提供的公司名称（<span style="color:#FA545E">{title}</span>）一致，平台才能自动匹配账户充值成功。如您的公司名称有误，请联系平台修改。',
    selfHelpPayCost_100061: '3、公对公转账金额不会立即充值到您的平台账户中，会有几分钟延迟，最晚一般转账当天24时前充值成功。平台充值成功后会发送通知，也会给您注册手机号发送充值成功短信，请及时查看。可在余额明细中查看相关详情。',
    
    selfHelpPayCost_100062: '公对公转账收款人信息',
    selfHelpPayCost_100063: '收款人账号：110916224910502',
    selfHelpPayCost_100064: '收款人名称：北京智充科技有限公司',
    selfHelpPayCost_100065: '收款人开户行：招商银行股份有限公司北京大运村支行',
    selfHelpPayCost_100066: '收款人开户行联行号：308100005221',
    selfHelpPayCost_100067: '您有任何问题可拨打010-53609116 ，时间：工作日早10：00点至晚18：00点；您也可向您对接的智充业务同事进行咨询。',

    selfHelpPayCost_100068: '公对公转账（运营商）',
    selfHelpPayCost_100069: '流水号（收款行）:',
    selfHelpPayCost_100070: '交易日:',
    selfHelpPayCost_100071: '交易时间:',
    selfHelpPayCost_100072: '交易金额:',
    selfHelpPayCost_100073: '付款人账号:',
    selfHelpPayCost_100074: '付款人名称:',
    selfHelpPayCost_100075: '付款人开户行:',
  }