export default {
    userClassDetail_00001: 'Category details',
    userClassDetail_00002: 'Modify category name',
    userClassDetail_00003: 'Marketing Activities',
    userClassDetail_00004: 'Category User',
    userClassDetail_00005: 'Category conditions',
    userClassDetail_00006: 'The system automatically updates the list of category users in real time according to the category conditions',
    userClassDetail_00007: 'User name, mobile phone, license plate number',
    userClassDetail_00008: 'List of users',
    userClassDetail_00009: 'Export users',
    userClassDetail_00010: 'Area',
    userClassDetail_00011: "Last order paid in today's time",
    userClassDetail_00012: 'Charging order paid today',
    userClassDetail_00013: 'Single user non-group and paid charging orders:',
    userClassDetail_00014: 'Cumulative number of orders',
    userClassDetail_00015: 'To',
    userClassDetail_00016: 'Cumulative paid amount (€)',
    userClassDetail_00017: 'Cumulative charge (kWh)',
    userClassDetail_00018: 'First order paid on the day before',
    userClassDetail_00019: 'Are you sure you want to delete this user category?' ,
    userClassDetail_00020: 'Deleting this user category was successful',
    userClassDetail_00021: 'Input cannot be empty',
    userClassDetail_00022: "This operation will download the user's Excel file, do you want to continue?" ,
    userClassDetail_00023: 'Operation',
    userClassDetail_00024: 'All',
    userClassDetail_00025: "Average",
    userClassDetail_00026: "The average number of user's cumulative orders on the average (inclusive)",
  }