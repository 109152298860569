export default {
  diversionServices_00001: '流量中心',
  diversionServices_00002: '开放引流',
  diversionServices_00003: '流量平台对接步骤',
  diversionServices_00004: '政府平台对接步骤',
  diversionServices_00005: "第一步 流量申请&nbsp&nbsp&nbsp&nbsp如您想接入流量方，请与平台联系。",
  diversionServices_00006: "第二步 商务洽谈&nbsp&nbsp&nbsp&nbsp需与智充签署商务合作协议，约束双方权利与义务。包括但不限于 系统对接费用、资金结算等。",
  diversionServices_00007: "第三步 系统对接&nbsp&nbsp&nbsp&nbsp平台完成运营商与流量方系统对接。",
  diversionServices_00008: "第四步 站点开放&nbsp&nbsp&nbsp&nbsp运营商可以在开放引流模块下看到可以开放的流量，并自行对运营站点进行开放和关闭开放操作。",
  diversionServices_00009: '已开放站点',
  diversionServices_00010: '未开放站点',
  diversionServices_00011: '已接入',
  diversionServices_00012: '未接入',
  diversionServices_00013: '去开放',
  diversionServices_00014: '地图平台',
  diversionServices_00015: '运营车平台',
  diversionServices_00016: '聚合平台',
  diversionServices_00017: '整车厂平台',
  diversionServices_00018: '政府平台',
  diversionServices_00019: '政府',
  diversionServices_00020: '免费',
  diversionServices_00021: '直签',
  diversionServices_00022: '智充总包',
}