export default {
    chargeMonitor_00001: 'Select stations',
    chargeMonitor_00002: 'Charging station name',
    chargeMonitor_00003: 'CPO',
    chargeMonitor_00004: 'Charger monitor',
    chargeMonitor_00005: 'Transactions',
    chargeMonitor_00006: 'Transaction info',
    chargeMonitor_00007: 'Charging info',
    chargeMonitor_00008: 'Current transaction',
    chargeMonitor_00009: 'Charged amount',
    chargeMonitor_00010: "Gateway",
    chargeMonitor_00011: "Available",
    chargeMonitor_00012: "In use",
    chargeMonitor_00013: "Faulted/Offline",
    chargeMonitor_00014: "Unavailable",
    chargeMonitor_00015: "Available",
    chargeMonitor_00016: "Online",
    chargeMonitor_00017: "Offline",
    chargeMonitor_00018: "Faulted",
    chargeMonitor_00019: "Reserved",
    chargeMonitor_00020: "Preparing",
    chargeMonitor_00021: "Charging",
    chargeMonitor_00022: "Pending car removal",
    chargeMonitor_00023: "Finishing",
    chargeMonitor_00024: "Occupying ",
    chargeMonitor_00025: "Please enter CPO name",
    chargeMonitor_00026: "Returned",
    chargeMonitor_00027: "Un-returned",
    chargeMonitor_00028: "View all faulted chargers",
    chargeMonitor_00029: "View all offline chargers",
    chargeMonitor_00030: "Add new window tab",
    chargeMonitor_00031: "Save and Open",
    chargeMonitor_00032: "Do you want to open all monitoring stations according to the last browser tab settings?",
    chargeMonitor_00033: "Please select a station to monitor in the current window",
    chargeMonitor_00034: "Setup Help",
    chargeMonitor_00035: "Please set the stations you want to monitor (up to 10) in each tab, click the 'Open' button, and the corresponding browser tab will be opened to display the monitoring stations according to your settings",
    chargeMonitor_00036: 'If your browser stopped the newly opened window, please check the settings of your browser and allow pop-up windows.',
    chargeMonitor_00037: 'Select All',
    chargeMonitor_00038: 'Selected stations on the current page',
    chargeMonitor_00039: 'Emergency button triggered, charging suspended',
    chargeMonitor_00040: 'Charger malfunction, charging suspended',
    chargeMonitor_00041: 'Fully charged, or reached set conditions',
    chargeMonitor_00042: 'Charger malfunction, charging stopped',
    chargeMonitor_00043: 'Charging stopped remotely by operators',
    chargeMonitor_00044: 'Stop charging from the charging station',
    chargeMonitor_00045: 'Charging',
    chargeMonitor_00046: 'Preparing',
    chargeMonitor_00047: 'Finishing',
    chargeMonitor_00048: 'Occupying',
    chargeMonitor_00049: 'Stopped abnormally',
    chargeMonitor_00050: 'Charging completed',
    chargeMonitor_00051: 'Waiting for available power ',
    chargeMonitor_00052: 'Faulted Log',
    chargeMonitor_00053: "Estimated",
    chargeMonitor_00054: "Fully Charged",
    chargeMonitor_00055: 'Charged',
    chargeMonitor_00056: "Please switch the current tab window to delete the label",
    chargeMonitor_00057: "The first item cannot be deleted",

    eqDetails_30015: "Confirm and recover from emergency stop",
    eqDetails_30016: "The charger is in use, self-inspection is canceled, please operate after it is idle",
    eqDetails_30017: "The current charger is offline",
    eqDetails_30018: "Charger self-inspection timed out, please try again",
    eqDetails_30020: "Poorer",
    eqDetails_30021: "moderate",
    eqDetails_30022: "better",
    eqDetails_30023: "This inspection requires that the firmware version number of the Android application for the charger be 3.0.0 or higher",
    eqDetails_30024: "Last self-inspection result not found",
    eqDetails_30025: "Recovery immediately",
    eqDetails_30026: "Check the charger first, then consider recovery",
    eqDetails_30027: "Disclaimer",
    eqDetails_30028: "To ensure the safe operation of the charger, we recommend that you perform a hardware check before restoring the emergency stop button. If any hardware failure or safety hazard is found, please stop using the charger and contact our technical support team. We will provide a solution as soon as possible to ensure that your charger can operate safely and stably.",
    eqDetails_30029: "Please note that this disclaimer is intended to remind you that choosing to directly restore the emergency stop button without first determining the status of the charger may involve certain risks, and we will not be held responsible for any resulting consequences.",
    eqDetails_30030: "Confirm direct recovery",
    eqDetails_30031: "Self-check items",
    eqDetails_30032: "State",
    eqDetails_30033: "{msg},{code},self-inspection is canceled, Please operate after maintenance",
}