export default {
  addDevice_00001: "Bitte wählen Sie eine neue Station aus oder erstellen Sie eine neue, um das Ladegerät hinzuzufügen",
   addDevice_00002: "Bitte wählen Sie eine Station aus",
   addDevice_00003: "Ladegerät zur Station hinzufügen",
   addDevice_00004: "Bitte wählen Sie eine Station aus, um das Ladegerät hinzuzufügen",
  addDevice_00005: "",
  addDevice_00006: "",
  addDevice_00007: "",
  addDevice_00008: "",
  addDevice_00009: "",
  addDevice_00010: "",
};