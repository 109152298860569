export default {
    teamPlaylistActivity_00001: "",
    teamPlaylistActivity_00002: "",
    teamPlaylistActivity_00003: "",
    teamPlaylistActivity_00004: "",
    teamPlaylistActivity_00005: "",
    teamPlaylistActivity_00006: "",
    teamPlaylistActivity_00007: "",
    teamPlaylistActivity_00008: "",
    teamPlaylistActivity_00009: "",
    teamPlaylistActivity_000010: " ",
    teamPlaylistActivity_000011: " ",
    teamPlaylistActivity_000012: " ",
    teamPlaylistActivity_000013: " ",
    teamPlaylistActivity_000014: " ",
    teamPlaylistActivity_000015: " ",
    teamPlaylistActivity_000016: " ",
    teamPlaylistActivity_000017: " ",
    teamPlaylistActivity_000018: " ",
    teamPlaylistActivity_000019: " ",
    teamPlaylistActivity_000020: " ",
    teamPlaylistActivity_000021: " ",
    teamPlaylistActivity_000022: " ",
    teamPlaylistActivity_000023: " ",
    teamPlaylistActivity_000024: " ",
    teamPlaylistActivity_000025: " ",
    teamPlaylistActivity_000026: " ",
    teamPlaylistActivity_000027: " ",
    teamPlaylistActivity_000028: " ",
    teamPlaylistActivity_000029: " ",
    teamPlaylistActivity_000030: " ",
    teamPlaylistActivity_000031: " ",
    teamPlaylistActivity_000032: " ",
    teamPlaylistActivity_000033: " ",
    teamPlaylistActivity_000034: " ",
    teamPlaylistActivity_000035: " ",
    teamPlaylistActivity_000036: " ",
    
}