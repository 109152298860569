export default {
    homePage_00001: 'Charging sessions',
    homePage_00002: 'Order amount({type})',
    homePage_00003: 'Service Income({type})',
    homePage_00004: 'Total Energy({type})',
    homePage_00005: 'CO₂ Saved (kg)',
    homePage_00006: 'Charging users',
    homePage_00007: 'To Do List',
    homePage_00008: 'Chargers status',
    homePage_00009: 'Common Functions',
    homePage_00010: 'Business Advice',
    homePage_00011: 'Utilization',
    homePage_00012: 'User Rankings',
    homePage_00013: 'Revenue',
    homePage_00014: 'Energy',
    homePage_00015: 'Duration',
    homePage_00016: 'Users',
    homePage_00017: 'Today',
    homePage_00018: 'Yesterday',
    homePage_00019: 'Ratio',
    homePage_00020: 'Refund Application',
    homePage_00021: 'Personal Refund Application',
    homePage_00022: 'Group Invoice Application',
    homePage_00023: 'Payment Bill',
    homePage_00024: 'Number of stations',
    homePage_00025: '',
    homePage_00026: 'DC connectors',
    homePage_00027: 'AC connectors',
    homePage_00028: 'Available',
    homePage_00029: 'In-use',
    homePage_00030: 'Faulted/Offline',
    homePage_00031: 'Charging Monitor',
    homePage_00032: 'User Management',
    homePage_00033: 'Charging Orders',
    homePage_00034: 'Income Analysis',
    homePage_00035: 'Recharge Activities',
    homePage_00036: 'Abnormal Orders',
    homePage_00037: 'Group-User',
    homePage_00038: 'Gov Diversion',
    homePage_00039: 'How to Improve the Utilization Rate?',
    homePage_00040: 'Operation',
    homePage_00041: 'User Membership',
    homePage_00042: 'How to Access New Customers?',
    homePage_00043: 'How to Increase the Value-Added Income?',
    homePage_00044: 'AC',
    homePage_00045: 'DC',
    homePage_00046: 'Setting',
    homePage_00047: 'Cancel',
    homePage_00048: 'Confirm',
    homePage_00049: 'Fee',
    homePage_00050: 'Electricity sold',
    homePage_00051: 'Last Week',
    homePage_00052: 'Last Month',
    homePage_00053: 'Kyoto charging station',
    homePage_00054: 'DC Charge Jack',
    homePage_00055: 'Charging users',
    homePage_00056: 'First-time users',
    homePage_00057: 'Electric fee({type})',
    homePage_00058: 'Order amount({type})',
    homePage_00059: 'Service fee({type})',
    homePage_00060: 'Charging duration',
    homePage_00061: 'AC Charge Jack',
    homePage_00062: 'Guide services',
    homePage_00063: 'Invite friends',
    homePage_00064: 'Ad Service',
    homePage_00065: 'Business Management',
    homePage_00066: 'HomePage overview',

    homePage_00067: 'Not open yet',
    homePage_00068: 'Youth stand',
    homePage_00069: 'The sun bank',
    homePage_00070: 'Happy middle',
    homePage_00071: 'The east station',

    homePage_00072: 'Caocao Travel',
    homePage_00073: 'Didi Travel ',
    homePage_00074: 'DiDa Travel',
    homePage_00075: 'Kun Ling',
    homePage_00076: 'Park family',
    homePage_00077: 'Chow Yun-Fat',
    homePage_00078: 'Jay Chou',
    homePage_00079: 'Beijing old gun',

    homePage_00080: 'EUR',
    homePage_00081: 'Welcome {name}!',
    homePage_00082: 'Member levels',
    homePage_00083: 'Redirection service',
    homePage_00084: 'Invite friends',
    homePage_00085: 'Income analysis',
    homePage_00086: 'User analysis',
    homePage_00087: 'Charging real-time monitoring',
    homePage_00088: 'Fault handling',    
    homePage_00089: 'Electric fee ({type})',
    homePage_00090: 'View currency',
    homePage_00097: 'Your platform version does not contain this function, please upgrade to advanced version or contact business',
    homePage_00091: 'times',
    homePage_00092: 'Revenue',
    homePage_00093: 'Service income',
    homePage_00094: 'Electricity sold',
    homePage_00095: 'CO₂ saved',
    homePage_00096: 'Power rate',
}