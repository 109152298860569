<template>
  <el-dialog :title="title" width="800px" custom-class="deal_wrap" :lock-scroll="false" :visible="dealVisible" :before-close="close">
    <div class="wrap">
      <h3 v-if="tips">{{tips}}？</h3>
      <div class="close_back" v-if="dealData&&!['order','turn','resolved'].includes(dealData.type)">
        <p class="label">{{$t('orderMangement_00052')}}</p>
        <el-input type="textarea" :rows="4" :placeholder="placeholdTxt" v-model="note"></el-input>
      </div>
      <div class="close_back" v-if="dealData&&['resolved'].includes(dealData.type)">
        <p class="label"><span>* </span>{{$t('orderMangement_00054')}}</p>
        <!-- <el-input type="textarea" :rows="4" :placeholder="placeholdTxt" v-model="note"></el-input> -->
        <div class="my_editor_wrap">
          <editor :isShowVideo="'false'" :content="note" @change="solutionChange" />
        </div>
      </div>
      <div class="turn_wrap" v-if="dealData&&dealData.type=='turn'">
        <div class="left">
          <p class="label"><span>* </span>{{$t('orderMangement_00055')}}</p>
          <div class="sele_wrap">
            <el-select class="w140" v-model="person" filterable @change="personChange">
              <el-option v-for="item in personOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select class="w140" v-model="subPerson" filterable v-if="repeatArr.length > 0">
              <el-option v-for="item in subPersonOptions" :key="item.id" :label="item.nickname||item.username" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <!-- <div class="focus_wrap label">
            <span>* </span>{{$t('orderMangement_00082')}}<el-switch class="switch" v-model="focusSwitch"></el-switch>
          </div> -->
        </div>
        <div class="right">
          <p class="label">{{$t('orderMangement_00052')}}</p>
          <el-input type="textarea" :rows="4" :placeholder="placeholdTxt" v-model="note"></el-input>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="query_btn" @click="close">{{$t('common_00215')}}</el-button>
      <el-button type="primary" @click="confirm">{{confirmTxt}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  requestUrl,
  ajax,
  checkLogin,
  getLoginInfo,
  checkIdType,
  getQueryString,
} from "@/assets/utils/network";
import editor from "@/components/editor";
export default {
  components: {
    editor,
  },
  props: ["dealData", "dealVisible"],
  data() {
    return {
      type: "order",
      title: "",
      tips: "",
      confirmTxt: "",
      note: "", // 备注
      placeholdTxt: "",
      person: "", // 转派处理人
      personOptions: [],
      subPerson: "",
      subPersonOptions: [],
      rowData: {},
      focusSwitch: true,
      repeatArr: ['1'],
      personArr: [],
    };
  },
  watch: {
    dealData: {
      handler(val) {
        if (!val) return;
        this.rowData = val.rowData;
        switch (val.type) {
          case "order":
            console.log('order',val.type)
            this.type = "order";
            this.title = this.$t("orderMangement_00056");
            this.tips = this.$t("orderMangement_00057");
            this.confirmTxt = this.$t("orderMangement_00058");
            break;
          case "turn":
            this.type = "turn";
            this.title = this.$t("orderMangement_00059");
            this.tips = "";
            this.confirmTxt = this.$t("orderMangement_00060");
            this.placeholdTxt = this.$t("orderMangement_00061");
            break;
          case "close":
            this.type = "close";
            this.title = this.$t("orderMangement_00062");
            this.tips = this.$t("orderMangement_00063");
            this.confirmTxt = this.$t("orderMangement_00064");
            this.placeholdTxt = this.$t("orderMangement_00065");
            break;
          case "back":
            this.type = "back";
            this.title = this.$t("orderMangement_00066");
            this.tips = this.$t("orderMangement_00067");
            this.confirmTxt = this.$t("orderMangement_00068");
            this.placeholdTxt = this.$t("orderMangement_00069");
            break;
          case "resolved":
            this.type = "resolved";
            this.title = this.$t("orderMangement_00070");
            this.tips = this.$t("orderMangement_00071");
            this.confirmTxt = this.$t("orderMangement_00072");
            this.placeholdTxt = this.$t("orderMangement_00054");
            break;
          default:
            break;
        }
      },
    },
  },
  created() {
    this.getPerson();
  },
  methods: {
    // 获取角色
    getPerson() {
      ajax({
        type: "GET",
        url: requestUrl + "/role",
        dataType: "json",
        success: (response) => {
          this.personArr = JSON.parse(response).result || [];

          let res = JSON.parse(response);
          this.personOptions = res.result || [];
          this.person = this.personOptions.length
            ? this.personOptions[0].id
            : "";
          if (this.person) {
            this.personChange(this.person);
          }
          ajax({
            type: "GET",
            url: `${requestUrl}/workFlow/factory`,
            dataType: "json",
            success: response => {
              let data = JSON.parse(response).result || [];
              data.forEach(item=> {
                this.personOptions.push({name:`${item.title}（${this.$t("orderMangement_00083")}）`,id:item.id})
              })
            }
          });
        },
      });
    },
    personChange(val) {
      // console.log(this.rowData.siteId)
      this.repeatArr = [];
      this.personArr.forEach(item=>{
        if(item.id == val){
          this.repeatArr.push(val)
        }
      })
      let url = `${requestUrl}/employee`;
      if (val) {
        url = `${requestUrl}/employee?roleId=${val}`;
      }
      ajax({
        type: "GET",
        url,
        dataType: "json",
        success: (response) => {
          let res = JSON.parse(response).result;
          let arr = [];
          if(['912262203904892928','912262327079018496'].includes(this.person)){
            res.forEach(item =>{
              if(item.resources){
                if(item.resources.every(it=>it.resourceType!=3)){
                  arr.push(item)
                }
                if(item.resources.some(it=>it.resourceType==3&&it.resource == this.rowData.siteId)){
                  arr.push(item)
                }
              }else{
                arr.push(item)
              }
            })
            this.subPersonOptions = arr || [];
          }else{
            this.subPersonOptions = res || [];
          }
          this.subPerson = "";
        },
      });
    },
    // ajax
    sendAjax(url, params) {
      return new Promise((resolve, reject) => {
        ajax({
          type: "POST",
          url: url,
          dataType: "json",
          data: JSON.stringify(params),
          success: (response) => {
            let res = JSON.parse(response);
            resolve(res);
          },
          fail: (err) => {
            reject(err);
          },
        });
      });
    },
    // 解决方案
    solutionChange(val) {
      this.note = val;
    },
    async confirm() {
      //create创建 confirm确认 complete完成 return退回 forward转派 close关闭
      let params = {};
      let employeeId = getLoginInfo().employeeId;
      params.workFlowId = this.rowData.id;
      params.operatorEmployeeId = employeeId;
      if (this.rowData.handlerRoleId) {
        params.handlerRoleId = this.rowData.handlerRoleId;
      }
      if (this.rowData.handlerEmployeeId) {
        params.handlerEmployeeId = this.rowData.handlerEmployeeId;
      }
      switch (this.type) {
        case "order":
          params.operatorType = "confirm";
          params.handlerEmployeeId = employeeId;
          params.handlerRoleId = checkLogin("roles")[0].id;
          break;
        case "turn":
          params.operatorType = "forward";
          if (!this.person) {
            this.$message({
              type: "error",
              offset: 70,
              message: this.$parent.$t("orderMangement_00073"),
            });
            return;
          }
          // 转派并成为关注人
          let followerEmployeeRes = "";
          if (
            this.focusSwitch &&
            !this.rowData.followerEmployee.some((it) => it.id === employeeId)
          ) {
            let paramsObj = {
              id: this.rowData.id,
              followerEmployee: [
                ...this.rowData.followerEmployee,
                { id: employeeId },
              ],
            };
            followerEmployeeRes = await this.sendAjax(
              `${requestUrl}/workFlow`,
              paramsObj
            );
          }
          if (followerEmployeeRes.error) return;
          console.log(this.rowData)
          if(this.repeatArr.length>0){
            // 处理人
            params.handlerRoleId = this.person;
            if(this.subPerson != ''){
              // 处理人账号
              params.handlerEmployeeId = this.subPerson;
            }
          }else{
            params.factoryId = this.person;
          }
          params.msg = this.note;
          break;
        case "close":
          params.operatorType = "close";
          params.msg = this.note;
          break;
        case "back":
          params.operatorType = "return";
          params.msg = this.note;
          break;
        case "resolved":
          params.operatorType = "complete";
          if (!this.note) {
            this.$message({
              type: "error",
              offset: 70,
              message: this.$parent.$t("orderMangement_00073"),
            });
            return;
          }
          params.msg = this.note;
          break;
        default:
          break;
      }
      this.sendAjax(`${requestUrl}/workFlow/operate`, params).then((res) => {
        if (res.result) {
          this.$message({
            type: "success",
            offset: 70,
            message: this.$parent.$t("orderMangement_00074"),
          });
        } else {
          if(res.error && res.error.code == "ISSUE_NOT_SOLVE"){
            this.$message({
            type: "error",
              offset: 70,
              message: this.$t('work_10020'),
            });
          return;
          }
          this.$message({
            type: "error",
            offset: 70,
            message: res.error.msg == null ? res.error.code : res.error.msg,
          });
        }
        this.$emit("confirm", this.type);
      });
    },
    // 关闭弹窗
    close() {
      this.$emit("cancel", false);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  max-height: 500px;
  overflow-y: auto;
}
.deal_wrap {
  h3 {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1b2330;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  .label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #464b5c;
    line-height: 20px;
    margin-bottom: 10px;
    margin-left: 3px;
    > span {
      color: #ff453a;
    }
  }
  .turn_wrap {
    display: flex;
    justify-content: space-between;
    > div {
      flex: 1;
    }
    .w140 {
      width: 140px;
      &:first-of-type {
        margin-right: 20px;
      }
    }
    .focus_wrap {
      margin-top: 10px;
      .switch {
        margin-left: 5px;
      }
    }
  }
  .my_editor_wrap {
    position: relative;
    top: -30px;
    left: -10px;
  }
}
</style>