export default {
    energyStatistics_00001: "Entladungsenergie",
    energyStatistics_00002: "Energie von Grid",
    energyStatistics_00003: "Aufladung Energie für Fahrzeug",
    energyStatistics_00004: "Energieeinkommenstrend",
    energyStatistics_00005: "Einkommen von Energie für Aufladung  des Autos",
    energyStatistics_00006: "Aufgeladene Energie",
    energyStatistics_00007: "Analyse",
    energyStatistics_00008: "Kosten für die Aufladung von Grid",
    energyStatistics_00009: "Kosten für die Batterieaufladung",
    energyStatistics_00010: "Energiebelohnung",
    energyStatistics_00011: "Aufladung Energie für Fahrzeug",
    energyStatistics_00012: "Lädt das auto auf(kWh)",
    energyStatistics_00013: "Gesamtenergieeinnahmen",
    energyStatistics_00014: "Gesamtenergieeinnahmen",
    energyStatistics_00015: "",
    energyStatistics_00016: "",
   
} 