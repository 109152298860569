export default {
    analyzeCharge_00001: "Gesamtanalyse der Ladeelektrizität",
    analyzeCharge_00002: "Analyse der Ladestationsvolumens",
    analyzeCharge_00003: "Analyse des Ladesvolumens der Gruppe",
    analyzeCharge_00004: "Analyse der Ladevolumen nach Benutzerquellen",
    analyzeCharge_00005: "Analyse des Ladevolumens der Franchisenehmer",
    analyzeCharge_00006: "Trend zum Ladeelektizität",
    analyzeCharge_00007: "Analyse",
    analyzeCharge_00008: "Elektizität (kWh)",
    analyzeCharge_00009: "Gerätsmodell Verteilung nach Elektrizität",
    analyzeCharge_00010: "Städte Verteilung nach Elektrizität",
    analyzeCharge_00011: "Vergleich der Ladestationsvolumens",
    analyzeCharge_00012: "Trend der Ladeelektrizität",
    analyzeCharge_00013: "Vergleich des Ladesvolumens der Gruppe",
    analyzeCharge_00014: "Trend des Ladevolumens der Gruppe",
    analyzeCharge_00015: "Vergleich des Ladevolumens der Franchisenehmer",
    analyzeCharge_00016: "Trends des Ladevolumens der Franchisenehmer",
    analyzeCharge_00017: "Ausrüstungsmodell auswählen",
    analyzeCharge_00018: "Vergleich der Ladestationsnutzungsrate",
    analyzeCharge_00019: "Trend der Ladestationsnutzungsrate",
    analyzeCharge_00020: "Vergleich der Nutzungsrate von Ausrüstungsmodellen",
    analyzeCharge_00021: "Trends in der Modellverwendungsrate",
    analyzeCharge_00022: "Ladenutzungsrate",
    analyzeCharge_00023: "Vergleich der Nutzungsrate durch Franchisenehmer",
    analyzeCharge_00024: "Trends bei der Nutzungsrate durch Franchisenehmer",
    analyzeCharge_00025: "Vergleich der durchschnittlichen Leistung der Ladestation",
    analyzeCharge_00026: "Analyse der durchschnittlichen Leistung von Gerätemodellen",
    analyzeCharge_00027: "durchschnittlichen Leistung",
    analyzeCharge_00028: "Ladestation auswählen oder eingeben",
    analyzeCharge_00029: "Ladestecke in Betrieb",
    analyzeCharge_00030: "Belegtheitsrate des Ladestecke",
    analyzeCharge_00031: "Anzahl der Stecke, die zuständig sind",
    analyzeCharge_00032: "Ladepunktsstecke Belegtrate %",
    analyzeCharge_00033: "Charge Port Busy Rate = Anzahl der Ports, die gerade aufgeladen werden / Gesamtzahl der Ports",
    analyzeCharge_00034: "Anteil des nutzerfinanzierten Ladevolumens",
    analyzeCharge_00035: "Anteil des Verkehrs von Dritten nach Ladevolumen",
    analyzeCharge_00036: "Trends des nutzerfinanzierten Ladevolumens",
    analyzeCharge_00037: "Trends des Verkehrs von Dritten nach Ladevolumen",
    analyzeCharge_00038: "Average charging capacity trend by 0-24 hour",
    analyzeCharge_00039: "Charging capacity per connector",
    analyzeCharge_00040: "Charging degree per connector",
} 