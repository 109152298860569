<template>
<div>
  <el-dialog
    :title="$t('common_70005')"
    :visible.sync="visible"
    :lock-scroll="false"
    :close-on-click-modal="false"
    :before-close="close"
    width="1100px"
  >
    <div class="tip-wrap" v-if="type !=3">
        <!-- <i class="iconfont icon-yichang icon"></i> -->
      <p>{{$t('common_70033')}}</p>
    </div>
    <div class="t-title">
      <p>{{$t('common_70006')}}</p>
      <el-input v-model="customName" :disabled="type == 3" class="text-info" size="medium" style="width: 180px;margin-right: 20px;" :placeholder="$t('common_70007')"></el-input>
      <div><el-button  :disabled="type == 3" type="text" size="medium" @click="addDateRange()">{{$t('common_70015')}}</el-button></div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <div class="table-head">
        <div class="h-items">{{$t('common_00204')}}</div>
        <div class="h-items">{{$t('common_00205')}}</div>
        <div class="h-items">{{$t('common_70009')}}</div>
        <div class="h-items">{{$t('common_00282')}}</div>
      </div>
      <div class="table-content">
        <div class="c-items" v-for="(v,k) in datePlan" :key="k">
          <div class="c-date c-border">
            <template v-if="[1,2].includes(type)">
              <div class="d-row">
                <el-select v-model="v.dateStart1" filterable  @change="monthChange(v,1)" :placeholder="$t('')"  size="medium">
                  <el-option
                    v-for="(item,key) in 12"
                    :label="item" :value="item" :key="key">
                  </el-option>
                </el-select>
                {{$t('common_70010')}}
                <el-select v-model="v.dateEnd1" filterable :placeholder="$t('')"  size="medium">
                  <el-option
                    v-for="(item,key) in v.monthDays ? v.monthDays : 31"
                    :label="item" :value="item" :key="key">
                  </el-option>
                </el-select>
                {{$t('common_70011')}}
                <!-- <el-input v-model.trim="v.dateStart1"  size="medium"/>{{$t('common_70010')}}
                <el-input v-model.trim="v.dateEnd1"  size="medium"/>{{$t('common_70011')}} -->
              </div>
            </template>
            <template v-else>
              {{v.dateStart1}}{{$t('common_70010')}}{{v.dateEnd1}}{{$t('common_70011')}}
              <!-- {{v.from}} -->
            </template>
          </div>
          <div class="c-date c-border1">
            <template v-if="[1,2].includes(type)">
              <div class="d-row">
                <el-select v-model="v.dateStart2" filterable @change="monthChange(v,2,k)" :placeholder="$t('')"  size="medium">
                  <el-option
                    v-for="(item,key) in 12"
                    :label="item" :value="item" :key="key">
                  </el-option>
                </el-select>
                {{$t('common_70010')}}
                <el-select v-model="v.dateEnd2" filterable @change="endDateChange(datePlan,v,k)" :placeholder="$t('')"  size="medium">
                  <el-option
                    v-for="(item,key) in v.monthDays ? v.monthDays : 31"
                    :label="item" :value="item" :key="key">
                  </el-option>
                </el-select>
                {{$t('common_70011')}}
                <!-- <el-input v-model.trim="v.dateStart2"  size="medium"/>{{$t('common_70010')}}
                <el-input v-model.trim="v.dateEnd2"  size="medium"/>{{$t('common_70011')}} -->
              </div>
            </template>
            <template v-else>
              <!-- {{v.to}} -->
              {{v.dateStart2}}{{$t('common_70010')}}{{v.dateEnd2}}{{$t('common_70011')}}

              
            </template>
            
          </div>
          <div class="c-week">
            <el-button type="text" size="medium" @click="addWeekPlane(k)"  :disabled="type == 3">{{$t('common_70016')}}</el-button>
            <div class="week-time-list">
              <div class="week-items" v-for="(m,y) in v.dateTemplate" :key="y">
                <div class="week-range">
                  <div class="w-head">
                    <div class="w-left">
                      <div class="week-info" v-if="[1,2].includes(type)">
                        <el-select v-model="m.weekStart" :placeholder="$t('')"  size="medium">
                          <el-option
                            v-for="(item,key) in weekList"
                            :label="item" :value="Number(key)" :key="key">
                          </el-option>
                        </el-select>
                        <span>&nbsp;{{$t('common_70022')}}&nbsp;</span>
                        <el-select v-model="m.weekEnd"  @change="endWeekChange(v.dateTemplate,m.weekEnd,y)" :placeholder="$t('')"  size="medium">
                          <el-option
                            v-for="(item,key) in weekList"
                            :label="item" :value="Number(key)" :key="key"
                           >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="week-info" v-else>{{weekList[m.dayOfWeek[0]]}} <span>&nbsp;{{$t('common_70022')}}&nbsp;</span>{{weekList[m.dayOfWeek[m.dayOfWeek.length -1]]}}</div> 
                    </div>
                    <div class="w-right">
                      <!-- 已复制 -->
                      <el-button type="text" size="medium"  :disabled="type == 3" v-if="m.copyType == 1">
                        <template>{{$t('common_70032')}}</template>
                      </el-button>
                      <!-- 复制 -->
                        <el-button type="text" size="medium"  :disabled="type == 3"  @click="copeTime(k,y,m.template,1)" v-if="m.copyType != 1 &&  (m.template.length > 0 || !copeDate.length)">
                        {{$t('common_70012')}}                       
                      </el-button>
                      <!-- 粘贴 -->
                      <el-button type="text" size="medium"  :disabled="type == 3"  @click="copeTime(k,y,m.template,2)"  v-if="m.template.length == 0 && copeDate.length" >{{$t('common_70031')}}</el-button>
                      <el-button type="text" size="medium" @click="editPlane(k,y,m.template)"  :disabled="type == 3">{{$t('common_70014')}}</el-button>
                      <el-button type="text" size="medium" @click="delTime(k,y)"  :disabled="type == 3">{{$t('common_00214')}}</el-button>
                    </div>
                  </div>

                  <div class="day-time-list">
                    <div class="day-items">
                      <div class="table-time">
                        <div class="table-head-time">
                          <div class="h-items">{{$t('common_00204')}}</div>
                          <div class="h-items">{{$t('common_00205')}}</div>
                          <div class="h-items">{{$t('common_70020')}}</div>
                        </div>
                        <div class="table-content-time" v-for="(u,uk) in m.template" :key="uk">
                          <div class="t-items c-time">{{u.from}}</div>
                          <div class="t-items c-time">{{u.to}}</div>
                          <div class="t-items c-type">{{electricEnergyList[u.priorityType]}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-opt c-border2">
            <el-button type="text" size="medium"  @click="delDateItem(k)" class="i-del" :underline="false"  :disabled="type == 3">{{$t('common_00214')}}</el-button>
          </div>
        </div>
      </div>
    </div>

    <div slot="footer" class="dialog-footer" v-if="type !=3">
      <el-button @click="close">{{$t('common_00215')}}</el-button>
      <el-button type="primary" @click="toSubmit">{{$t('common_00234')}}</el-button>
    </div>
    
  </el-dialog>
  <timePlaneSchedule v-if="timePlaneScheduleShow" :scheduleList="scheduleList" @close="timePlaneScheduleShow = false"  @confirm="timePlaneScheduleConfirm"/>
</div>
</template>

<script>
import {
  requestUrl,
  ajax,
  getQueryString,
  getDateFromLongTime,
  checkLogin,
} from "@/assets/utils/network";
import timePlaneSchedule from "@/components/timePlaneSchedule";
import electricEnergyList_cn from '@/assets/js/electricEnergyList_cn.js'
import electricEnergyList_eu from '@/assets/js/electricEnergyList_eu.js'

export default {
  components:{timePlaneSchedule},
  props:['type','id','schedule'],  // type: 1 '创建' 2 '编辑' 3 '详情’ 4 复制  2/3需要传id
  data() {
    return {
      weekList:{
        1:this.$parent.$t('common_70023'),
        2:this.$parent.$t('common_70024'),
        3:this.$parent.$t('common_70025'),
        4:this.$parent.$t('common_70026'),
        5:this.$parent.$t('common_70027'),
        6:this.$parent.$t('common_70028'),
        7:this.$parent.$t('common_70029'),
      },
      scheduleList:[],
      indexX:"0",
      indexY:"0",
      electricEnergyList:{},
      customName:'',
      timePlaneScheduleShow:false,
      esEcoModeTowardPhase:"",
      visible: true,
      // list:[{title:"111"},{title:"222"},{title:"333"}],
      datePlan: [{ 
        dateStart1:1,
        dateEnd1:1,
        dateStart2:12,
        dateEnd2:31,
        from: '01-01', //开始日期，包含当天。格式：“MM-dd”。例如01-12为如1月12日
        to: '12-31', //结束日期，包含当天。格式：“MM-dd”。例如01-12为如1月12日
        dateTemplate: [{
          weekStart:1,
          weekEnd:7,
          dayOfWeek: [1,2,3,4,5,6,7], // 每周的第几天，值为1至7。例如：星期一=1、...、星期日=7
          template: [{
          from: '00:00', //时间格式：“HH:MM”  闭区间，如：“00:00”
          to: '24:00', //时间格式：“HH:MM”  开区间，如：“24:00”
          priorityType: 1, //工况优先级类型
        },]
        }]
      }],
      copeDate:[],
      copyX:"",
      copyY:""
    };
  },
  mounted () {
    this.product = window.product_area;
    if(this.product == 'cn'){
      this.electricEnergyList = electricEnergyList_cn
    }else{
      this.electricEnergyList = electricEnergyList_eu
    }
    console.log(this.type,'--------------',this.id)
    if([2,3,4].includes(this.type)){
      this.getPlaneDetail()
    }
  },

  methods:{
    monthChange (item,type,index) {
      // dateEnd1: (...)
      // dateEnd2: (...)
      // dateStart1: (...)
      // dateStart2: (...)
      let curM;
      if(type == 1) {
        curM = item.dateStart1;
        item.dateEnd1 = ''
      }
      if(type == 2) {
        curM = item.dateStart2;
        item.dateEnd2 = ''
      }
      let m31 = [1,3,5,7,8,10,12];
      let m28 = [2]
      let m30 = [4,6,9,11]
      // let mEnd = +curMonth.getTime()
      if(m31.includes(curM)){
        item.monthDays = 31
      }
      if(m28.includes(curM)){
        let y = new Date().getFullYear();
        item.monthDays = 29


        console.log(y,y%4 == 0 && y%100 != 0 || y%400 == 0)
        if(y%4 == 0 && y%100 != 0 || y%400 == 0) {
          // item.monthDays = 29
          // 润年 29天
        } else {
          // mEnd += 60*60*24*1000*27
          // 平年 28天
        }
        
      }
      if(m30.includes(curM)){
        item.monthDays = 30
      }
      // if(type == 2) {
      //   console.log('触发日期改变')
      //   this.endDateChange(this.datePlan,item,index)
      // }
    },
    endWeekChange (list,val,index) {
      console.log(list,'changeWeek',val,'00000000',index)
      let curItemVal = list[index].weekEnd;
      console.log(curItemVal,'curItemVal')
      if(list.length > index+1 && val !=7) {
        list[index+1].weekStart = curItemVal + 1
      } else {
        console.log('不触发')
      }
      console.log(list,'更改后的列表')
      
    },
    endDateChange (list,item,index) {
      const curTime = new Date()
      const curY = curTime.getFullYear();
      const oneDayTime = 24 * 60 * 60 * 1000 // 一天的总ms
      let monthFn = item.dateStart2;
      console.log(list,'changeDate',item,'00000000',index)
      // dateEnd1: (...)
      // dateEnd2: (...)
      // dateStart1: (...)
      // dateStart2: (...)
      let curItemVal =`${monthFn}/${item.dateEnd2}`;
      let nextDate;
      // 二月特殊处理方法
      if(monthFn == 2){
        let y = new Date().getFullYear();
        if(curItemVal == '2/29') {
          if(y%4 == 0 && y%100 != 0 || y%400 == 0) {
            console.log('29')
            nextDate = new Date(new Date(`${curY}/${curItemVal}`).getTime() + oneDayTime)
          } else {
            console.log('28')
            nextDate = new Date(new Date(`${curY}/${curItemVal}`).getTime())
            // 平年 28天
          }
          
        } else {
          nextDate = new Date(new Date(`${curY}/${curItemVal}`).getTime() + oneDayTime)
        }
        
      } else {
        nextDate = new Date(new Date(`${curY}/${curItemVal}`).getTime() + oneDayTime)

      }
      if(list.length > index+1) {
        if(monthFn == 2 && curItemVal == '2/28') {
          list[index+1].dateStart1 = 2
          list[index+1].dateEnd1 = 29
        } else if(monthFn == 2 && curItemVal == '2/29') {
          list[index+1].dateStart1 = 3
          list[index+1].dateEnd1 = 1
        } else {
          list[index+1].dateStart1 = nextDate.getMonth() + 1
          list[index+1].dateEnd1 = nextDate.getDate()
        }
        
        console.log(list[index+1])
      } else {
        console.log('不触发')
      }
      
      console.log(list,'更改后的列表')
      

    },
    getPlaneDetail () {
      // energystorage/custommodeplan? Paging & id=ID & keyword=String
      let id = this.id;
      var url = requestUrl + "/energystorage/custommodeplan?Paging&id=" + id;
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: (response)=> {
          var info = JSON.parse(response).result || [];
          console.log(info,'---------')
          // let infoDetail = info.EnergyStorageCustomModePlan || {}
          
          let infoDetail = info[0].datePlan || []
          console.log('infoDetail',infoDetail)
          if(this.type != 4) {
            this.customName = info[0].title;
          }
          infoDetail.forEach((z,k)=>{
            let dateStart1 = z.from.split('-')[0] || '';
            let dateEnd1 = z.from.split('-')[1] || '';
            let dateStart2 = z.to.split('-')[0] || '';
            let dateEnd2 = z.to.split('-')[1] || '';
            z.dateStart1 = dateStart1;
            z.dateEnd1 = dateEnd1;
            z.dateStart2 = dateStart2;
            z.dateEnd2 = dateEnd2;
            console.log(dateStart1,dateEnd1)
            z.dateTemplate.forEach((m,mk)=>{
              let weekStart = m.dayOfWeek[0];
              let weekEnd = m.dayOfWeek[m.dayOfWeek.length - 1]
              m.weekStart = weekStart
              m.weekEnd = weekEnd
            })
          })
          this.datePlan = infoDetail
        }
      });
    },

    //添加日期范围
    addDateRange () {
      let oDate = {
        dateStart1:1,
        dateEnd1:1,
        dateStart2:12,
        dateEnd2:31,
        from: '01-01', //开始日期，包含当天。格式：“MM-dd”。例如01-12为如1月12日
        to: '12-31', //结束日期，包含当天。格式：“MM-dd”。例如01-12为如1月12日
        dateTemplate: [{
          weekStart:1,
          weekEnd:7,
          dayOfWeek: [1,2,3,4,5,6,7], // 每周的第几天，值为1至7。例如：星期一=1、...、星期日=7
          template: []
        }]
      }
      this.datePlan.push(oDate)
    },
    //添加周计划
    addWeekPlane (index) {
      let oWeek = {
          weekStart:1,
          weekEnd:7,
          dayOfWeek: [1,2,3,4,5,6,7], // 每周的第几天，值为1至7。例如：星期一=1、...、星期日=7
          template: [{
            from: '', //时间格式：“HH:MM”  闭区间，如：“00:00”
            to: '', //时间格式：“HH:MM”  开区间，如：“24:00”
            //
            priorityType: "", //工况优先级类型
          }]
        }
      this.datePlan[index].dateTemplate.push(oWeek)
    },
    timePlaneScheduleConfirm(v) {
      if(!v) return;
      let indexX =this.indexX;
      let indexY =this.indexY;

      let tt = this.datePlan[indexX].dateTemplate[indexY].template = v
      this.$set(this.datePlan,indexX,this.datePlan[indexX])
      this.timePlaneScheduleShow = false;
    },

    copeTime(x,y,data,type) {
      console.log(type,'ddd',this.copeDate)
      //复制
      if(type == 1) {
        this.copyX = x;
        this.copyY = y;

        this.copeDate = data
        this.$message({
            message: this.$parent.$t('common_70030'),
            type: 'success'
        });
        this.datePlan[x].dateTemplate[y].copyType = 1
        this.$set(this.datePlan,x,this.datePlan[x])
      }
      //粘贴
      if(type == 2) {
        //已复制 1 粘贴 2
        this.datePlan[x].dateTemplate[y].template = this.copeDate;
        this.$set(this.datePlan,x,this.datePlan[x])
      }
    },
    editPlane(x,y,item){
      console.log(x,y)
      this.indexX = x;
      this.indexY = y;
      this.scheduleList = item
      console.log(this.scheduleList,'edit')
      this.timePlaneScheduleShow = true;
    },
    delTime(x,y){
      let list = this.datePlan[x].dateTemplate
      console.log(list)
      list.splice(y,1)
    },
    delDateItem(index) {
      let list = this.datePlan;
      list.splice(index,1)
    },
    check(v){
      console.log('v',v)
    },
    close() {
      this.$emit("close");
    },
    toSubmit() {
      if(this.customName == '') {
         this.$message.error(this.$t('common_70021'));
         return;
      }
      // this.$emit("confirm",this.esEcoModeTowardPhase);
      // /api/energystorage/custommodeplan? Paging & id=ID & keyword=String
      this.datePlan.forEach((z,k)=>{
        let dateStart1 = +z.dateStart1;
        let dateEnd1 = +z.dateEnd1;
        let dateStart2 = +z.dateStart2;
        let dateEnd2 = +z.dateEnd2;
        console.log(dateStart1,dateEnd1)
        z.from = (dateStart1 > 9 ? dateStart1 : ('0'+ dateStart1)) + '-' + (dateEnd1 > 9 ? dateEnd1 : ('0'+ dateEnd1))
        z.to = (dateStart2 > 9 ? dateStart2 : ('0'+ dateStart2)) + '-' + (dateEnd2 > 9 ? dateEnd2 : ('0'+ dateEnd2))
        z.dateTemplate.forEach((m,mk)=>{
          let weekStart = +m.weekStart;
          let weekEnd = +m.weekEnd;
          m.dayOfWeek = []
          for(let i =weekStart;i<=weekEnd;i++) {
            m.dayOfWeek.push(i)
          }
        })
      })
      let olist = JSON.parse(JSON.stringify(this.datePlan))

      olist.forEach((i,ik)=>{
        delete i.dateStart1;
        delete i.dateEnd1;
        delete i.dateStart2;
        delete i.dateEnd2;
        delete i.monthDays;
        i.dateTemplate.forEach((m,mk)=>{
          delete m.weekStart;
          delete m.weekEnd;
        })

      })

      console.log(this.datePlan,'---',olist)
      let timeScheduleObj = new Object();
      timeScheduleObj.title = this.customName
      timeScheduleObj.dealerId = checkLogin("id");
      timeScheduleObj.datePlan = olist;
      if(this.id && this.type == 2) {
        timeScheduleObj.id = this.id
      }
      console.log(timeScheduleObj,'timeScheduleObj')
      // var url = requestUrl + "/energystorage/custommodeplan?id=" + this.id;
      var url = requestUrl + "/energystorage/custommodeplan";
      ajax({
        type: "POST",
        url: url,
        dataType: "json",
        data: JSON.stringify(timeScheduleObj),
        success: (response)=> {
          var error = JSON.parse(response).error;
          if(error){
            this.$message.error(this.$t('common_70034'));
          } else {
            this.$emit('confirm')
          }
        },
        fail: (err)=>{
          this.$message.error(this.$t('common_70034'));
        }
      });

    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
}
/deep/.el-input__inner {
  height: 36px;
  line-height: 36px;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.m20 {
  margin: 0 20px;
}
.flex-container-left {
  margin-bottom: 20px;
  .tips {
    i {
      margin-right: 15px;
    }
    margin-bottom: 20px;
  }
}
.dialog-btn{
  display: block;
  width: 100%;
  text-align: right;
}
.mode-detail{
  .mode-info{
    display: flex;
    margin-bottom: 10px;
    p{
      width: 72px;
      flex-shrink: 0;
    }
    .mode-content{
      text-align: justify;
      
    }
  }
}
.tip-wrap {
  display: flex;
  margin-bottom: 30px;
  background: #f2f2f2;
  color: #333;
  padding: 10px 5px;
    .icon {
      // font-size: 20px;
      margin-right: 10px;
      margin-top: 2px;
      flex-shrink: 0;
    }
    p{
      flex: 1;
    }
}
.t-title{
  margin: 20px 0;
  p{
    margin-bottom: 10px;
  }
}
.table{
  .table-head {
    display: flex;
    width: 100%;
    height: 40px;
    background: #EFF2F6;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #1B2330;
    padding: 10px;
    box-sizing: border-box;
    .h-items{
      text-align: center;
      &:nth-child(1){
        width: 20%;
      }
      &:nth-child(2){
        width: 20%;
      }
      &:nth-child(3){
        width: 54%;
      }
      &:nth-child(4){
        width: 6%;
        
      }

    }
  }
  .table-content{
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid hsl(214, 28%, 95%);
    // padding-bottom: 16px;
    .c-items{
      display: flex;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px dashed #E6E7EC;
      position: relative;
      &:last-child{
        border-bottom: none;
      }
      .c-date{
        width: 20%;
        text-align: center;
        &.c-border{
          &::after{
            content: '';
            position: absolute;
            width: 1px;
            /* height: 100%; */
            /* background: #eff2f6; */
            border-right: 1px solid #eff2f6;
            top: 0;
            left: 20%;
            bottom: 0;
          }
        }
        &.c-border1{
          &::after{
            content: '';
            position: absolute;
            width: 1px;
            /* height: 100%; */
            /* background: #eff2f6; */
            border-right: 1px solid #eff2f6;
            top: 0;
            left: 40%;
            bottom: 0;
          }
        }
        .d-row{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        /deep/.el-input{
          width: 70px;
        }
      }
      .c-week{
        width: 54%;
        padding: 0 0 0 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        .week-time-list {
          width: 100%;
          height: 400px;
          overflow-y: scroll;
          .week-items {
            margin-bottom: 20px;
            .week-range {
              .w-head{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                .w-left{
                  .week-info{
                    display: flex;
                    align-items: center;
                    /deep/.el-select{
                      width: 82px;
                    }
                  }
                }
                
              }
              
              // justify-content: space-between;
              // align-items: center;
            }
            .day-time-list {
              .table-time{
                .table-head-time {
                  display: flex;
                  width: 100%;
                  height: 40px;
                  background: #EFF2F6;
                  border-radius: 4px 4px 0px 0px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #1B2330;
                  padding: 10px;
                  box-sizing: border-box;
                  .h-items{
                    text-align: center;
                    &:nth-child(1){
                      width: 15%;
                    }
                    &:nth-child(2){
                      width: 15%;
                    }
                    &:nth-child(3){
                      width: 70%;
                    }

                  }
                }
                .table-content-time{
                  display: flex;
                  background: #FFFFFF;
                  // border-radius: 4px;
                  border: 1px solid hsl(214, 28%, 95%);
                  padding: 0 10px 0px 10px;
                  .t-items{
                    display: flex;
                    align-items: center;
                    padding: 10px 0;
                    // width: 15%;
                    
                    &:last-child{
                      border-bottom: none;
                    }
                    &.c-time{
                      width: 15%;
                      display: flex;
                      justify-content: center;
                    }
                    &.c-type{
                      width: 70%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .c-opt{
        width: 6%;
        text-align: center;
        display: flex;
        justify-content: center;
        &.c-border2{
          &::after{
            content: '';
            position: absolute;
            width: 1px;
            border-right: 1px solid #eff2f6;
            top: 0;
            right: 6%;
            bottom: 0;
          }
        }
      }
    }
  }
}
.dialog-footer{
  
  text-align: center;
  .el-button{
    margin: 0 30px;
  }
}
</style>
