export default {
  pileDetail_00001: 'Push configuration',
  pileDetail_00002: 'NZS details',
  pileDetail_00003: 'Charger info',
  pileDetail_00004: 'Charger configuration',
  pileDetail_00005: 'Charging point management',
  pileDetail_00006: 'Charger maintenance',
  pileDetail_00007: 'Reboot the charger',
  pileDetail_00008: 'Reboot command has been sent, and the process would take about 5 minutes. Please stay on this page, do not refresh the page',
  pileDetail_00009: 'Confirm',
  pileDetail_00010: 'Start charging',
  pileDetail_00011: 'Charger rebooted successfully',
  pileDetail_00012: 'Remote start',
  pileDetail_00013: 'Charger rebooted failed',
  pileDetail_00014: 'Charger name ',
  pileDetail_00015: 'Owned charging site',
  pileDetail_00016: 'View',
  pileDetail_00017: 'Charger SN number',
  pileDetail_00018: 'Charger manufacturer',
  pileDetail_00019: 'Charger type',
  pileDetail_00020: 'Please enter the charger name',
  pileDetail_00021: 'After the configuration modification is saved, the configuration needs to be pushed to the device. After the configuration modification is saved, the configuration needs to be pushed to the device, and the configuration can take effect only after the device responds.',
  pileDetail_00022: 'Push configuration',
  pileDetail_00023: 'Push to the charger',
  pileDetail_00024: 'Enable the charger',
  pileDetail_00025: 'Electromagnetic lock',
  pileDetail_00026: 'Electromagnetic lock failed but allow charging',
  pileDetail_00027: 'Parking radar',
  pileDetail_00028: 'Charger open',
  pileDetail_00029: 'Start time ',
  pileDetail_00030: 'End time',
  pileDetail_00031: 'Max. charging current(A)',
  pileDetail_00032: 'Max. charging power(KW)',
  pileDetail_00033: 'Push failed',
  pileDetail_00034: 'Charger is offline',
  pileDetail_00035: 'Waiting for the charger response',
  pileDetail_00036: 'Push successfully',
  pileDetail_00037: 'Date setting incorrectly',
  pileDetail_00038: 'The power module needs maintenance timing reminder: accumulate the charging time since the reset time',
  pileDetail_00039: '{hours}Hours ',
  pileDetail_00040: 'Reset time:',
  pileDetail_00041: 'Normal',
  pileDetail_00042: 'Faulty',
  pileDetail_00043: 'Are you sure that this maintenance has been completed for these power modules? After clicking OK, the power modules need maintenance prompt to re-time.',
  pileDetail_00044: 'Reminder',
  pileDetail_00045: 'Charging point name',
  pileDetail_00046: 'Charging QR code',
  pileDetail_00047: 'dynamic QR code',
  pileDetail_00048: 'Static QR code',
  pileDetail_00049: 'Update to static QR code',
  pileDetail_00050: 'Custom QR code',
  pileDetail_00051: 'Remote control',
  pileDetail_00052: 'Restart the charging point',
  pileDetail_00053: 'Stop charging',
  pileDetail_00054: 'Set into available',
  pileDetail_00055: 'Charging point status',
  pileDetail_00056: 'EV management device',
  pileDetail_00057: 'None',
  pileDetail_00058: 'Parking lock controlled by charger',
  pileDetail_00059: 'Parking lock controlled by Cloud',
  pileDetail_00060: 'Parking space management equipment information',
  pileDetail_00061: 'Please enter charger code',
  pileDetail_00062: 'Enable parking management device',
  pileDetail_00063: 'Remote control parking management device',
  pileDetail_00064: 'Rise',
  pileDetail_00065: 'Fall',
  pileDetail_00066: 'Current parking space idle fee billing user',
  pileDetail_00067: 'Occupy start time',
  pileDetail_00068: 'User detail',
  pileDetail_00069: 'Manually stop this occupancy billing',
  pileDetail_00070: 'OR code for parking space management device ',
  pileDetail_00071: 'Generate static QR code',
  pileDetail_00072: 'Occupied',
  pileDetail_00073: 'Disabled',
  pileDetail_00074: 'Offline',
  pileDetail_00075: 'Faulty',
  pileDetail_00076: 'Reserved',
  pileDetail_00077: 'Available',
  pileDetail_00078: 'Connected',
  pileDetail_00079: 'Charging',
  pileDetail_00080: 'Waiting to move the EV',
  pileDetail_00081: 'Waiting for Plug out',
  pileDetail_00082: 'Charging point',
  pileDetail_00083: 'Start charging',
  pileDetail_00084: 'Please save the previous step',
  pileDetail_00085: 'The current parking space has a billing user who is occupying the parking space, please modify it later!',
  pileDetail_00086: 'Parameter is empty',
  pileDetail_00087: 'Max.20 characters',
  pileDetail_00088: 'Start charging command has been sent',
  pileDetail_00089: 'Stop charging command has been sent',
  pileDetail_00090: 'Sent successfully',
  pileDetail_00091: 'Update into static QR code',
  pileDetail_00092: 'Generate QR code successfully',
  pileDetail_00093: 'Enabled',
  pileDetail_00094: 'Disabled',
  pileDetail_00095: 'Parking lock controlled by cloud',
  pileDetail_00096: 'Parking lock controlled by EVSE',
  pileDetail_00097: 'Please enter the QR code parameter, the QR code would be shown on the charger',
  pileDetail_00098: 'Notice: Only Generate the static QR code, back to the original status is not allowed',
  pileDetail_00099: 'Custom QR code cannot be empty',
  pileDetail_00100: 'Successfully custom the QR code',
  pileDetail_00101: 'Setting failed',
  pileDetail_00102: 'lenth should not be longer than {0} ',
  pileDetail_00103: 'Current recharging duration(minutes)',
  pileDetail_00104: 'Current pre-idle fee({0})',
  pileDetail_00105: 'Modified idle fee({0})',
  pileDetail_00106: 'Please enter the price',
  pileDetail_00107: 'Please enter the modifed idle fee',
  pileDetail_00108: 'Please enter correct number',
  pileDetail_00109: 'Manually finish the idle fee',
  pileDetail_00110: 'Max number of QR code is 100,000 and cannot be recycled, please operate carefully, are you sure to update??',
  pileDetail_00111: 'Update successfully',
  pileDetail_00112: 'Finish',
  pileDetail_00113: 'Whether to generate a fixed QR code  for the parking management device',
  pileDetail_00114: 'No QR code yet',
  pileDetail_00115: 'If charger is offline, whitelist is only supported',
  pileDetail_00116: 'Clear whitelist',
  pileDetail_00117: 'Push whitelist',
  pileDetail_00118: 'Charger ID',
  pileDetail_00119: 'system version',
  pileDetail_00120: 'APK version',
  pileDetail_00121: 'DCB version',
  pileDetail_00122: 'Network',
  pileDetail_00123: 'Battery-charge mode',
  pileDetail_00124: 'Battery-box name',
  pileDetail_00125: 'Battery-box capacity',
  pileDetail_00126: 'Battery cycle time',
  pileDetail_00127: 'Battery-box SOC',
  pileDetail_00128: 'Battery-box SOH',
  pileDetail_00129: 'Battery-box temperature',
  pileDetail_00130: 'Battery-box status',
  pileDetail_00131: 'Start battery charging',
  pileDetail_00132: 'Stop battery charging',
  pileDetail_00133: 'Start battery discharging',
  pileDetail_00134: 'Stop battery discharging',
  pileDetail_00135: 'Air conditioner operation mode',
  pileDetail_00136: 'Economic mode',
  pileDetail_00137: 'Efficiency mode',
  pileDetail_00138: 'Max power mode',
  pileDetail_00139: 'Eco economic mode',
  pileDetail_00140: 'Auto mode',
  pileDetail_00141: 'Use the energy storage device to fill the charging power gap and quickly charging the vehicle',
  pileDetail_00142: 'Energy storage when electricity fee is low, and services when electricity fee high, reducing electricity costs',
  pileDetail_00143: 'The system automatically balances power and cost requirements',
  pileDetail_00144: 'Learn more',
  pileDetail_00145: 'NZS management',
  pileDetail_00146: 'NZS battery-box',
  pileDetail_00147: "NZS battery charging",
  pileDetail_00148: "NZS battery discharging",
  pileDetail_00149: "Available",








}