export default {
    detailDepot_00001: 'Depot Details',
    detailDepot_00002: "Passenger car",
    detailDepot_00003: "Commercial vehicle",
    detailDepot_00004: "Van",
    detailDepot_00005: "Bus",
    detailDepot_00006: "E-bike",
    detailDepot_00007: "Public",
    detailDepot_00008: "Private",
    detailDepot_00009: "Bus (special)",
    detailDepot_00010: "Sanitation (special)",
    detailDepot_00011: "Logistics (special)",
    detailDepot_00012: "Taxi (special)",
    detailDepot_00013: 'Time-sharing Leasing (Dedicated) (Interconnection Standard Extension)',
    detailDepot_00014: 'Cell sharing (Dedicated) (Interconnection standard Extension)',
    detailDepot_00015: 'Bit (Dedicated) (Interconnection Standard Extension)',
    detailDepot_00016: 'Othors',
    detailDepot_00017: 'please choose',
    detailDepot_00018: 'Residence community',
    detailDepot_00019: 'Public institutions',
    detailDepot_00020: 'Organization',
    detailDepot_00021: 'Office building',
    detailDepot_00022: 'Industrial area',
    detailDepot_00023: 'Transportation hub',
    detailDepot_00024: 'Large stylistic community',
    detailDepot_00025: 'City greening',
    detailDepot_00026: 'Large construction parking lot',
    detailDepot_00027: 'Street parking spot',
    detailDepot_00028: 'Intercity Express Service Area',
    detailDepot_00029: 'Please refer to the recommended national standard GB/T 2260-2007',
    detailDepot_00030: 'Edit success',
    detailDepot_00031: 'Incorrect country code format(2 uppercase letters)',
    detailDepot_00032: 'Image modified successfully',
    detailDepot_00033: 'Upload image',
    detailDepot_00034: 'Supports only PNG, JPG, and GIF images. The maximum size is 1 MB.',
    detailDepot_00035: 'Real picture',
    detailDepot_00036: 'Address, street, and house name',
    detailDepot_00037: 'Search',
    detailDepot_00038: 'Name',
    detailDepot_00039: 'Detailed address',
    detailDepot_00040: 'Current position',
    detailDepot_00041: 'Cancel',
    detailDepot_00042: 'Use this location',
    detailDepot_00043: 'Please choose the coordinate of charging staion',
    detailDepot_00044: 'Position selected does not match the address',
    detailDepot_00045: "Notice",
    detailDepot_00046: "I see",
    detailDepot_00047: "Address cannot be found",
    detailDepot_00048: "An unknown error has occurred on the Google map",
    detailDepot_00049: "Depots center",
    detailDepot_00050: "Depot management",
    detailDepot_00051: 'Depot information',
    detailDepot_00052: 'Equipment management',
    detailDepot_00053: 'Rates set',
    detailDepot_00054: 'Run Settings',
    detailDepot_00055: 'Traffic open',
    detailDepot_00056: 'Government docking',
    detailDepot_00057: 'Power distribution',
    detailDepot_00058: 'Bus Charge',
    detailDepot_00059: 'Basic information',
    detailDepot_00060: 'Save',
    detailDepot_00061: 'Edit',
    detailDepot_00062: 'Depot delete',
    detailDepot_00063: 'Depot name',
    detailDepot_00064: 'Depot position',
    detailDepot_00065: 'Map location',
    detailDepot_00066: 'Area code',
    detailDepot_00067: 'country code',
    detailDepot_00068: 'EV type',
    detailDepot_00069: 'Site type',
    detailDepot_00070: 'Depot contact',
    detailDepot_00071: 'Construction site',
    detailDepot_00072: 'Parking Location',
    detailDepot_00073: 'Number of parking spaces',
    detailDepot_00074: 'Real pictures',
    detailDepot_00075: 'Name',
    detailDepot_00076: 'Telephone',
    detailDepot_00077: 'Email',
    detailDepot_00078: 'Parking Fee',
    detailDepot_00079: 'Device offline',
    detailDepot_00080: 'Start charging',
    detailDepot_00081: 'Online',
    detailDepot_00082: 'Offline',
    detailDepot_00083: 'Fault',
    detailDepot_00084: 'Charging',
    detailDepot_00085: "All",
    detailDepot_00086: "change into available",
    detailDepot_00087: "Charger is not enabled",
    detailDepot_00088: "Occupying",
    detailDepot_00089: "Lanuage",
    detailDepot_00090: "Time zone",
    detailDepot_00091: "Daylight Saving Time",
    detailDepot_00092: "AC charger electromagnetic Lock",
    detailDepot_00093: "DC charger electromagnetic Lock",
    detailDepot_00094: "Can the electromagnetic lock be charged if it fails to lock or not",
    detailDepot_00095: "Parking space radar",
    detailDepot_00096: "AC device waiting time ",
    detailDepot_00097: "DC device waiting time",
    detailDepot_00098: "Maximum amount to be charged for time delay",
    detailDepot_00099: "Time Delay Charging Policy",
    detailDepot_00100: "AC device",
    detailDepot_00101: "DC device",
    detailDepot_00102: "AC device",
    detailDepot_00103: "DC device",
    detailDepot_00104: "Enabled",
    detailDepot_00105: "Disabled",
    detailDepot_00106: "Not rechargeable",
    detailDepot_00107: "Charging Policy for idle fee",
    detailDepot_00108: " Collecting fee in any case",
    detailDepot_00109: "No charge for ending charging due to pile failure",
    detailDepot_00110: "{num}minute",
    detailDepot_00111: "Set to 0 for no limit",
    detailDepot_00112: "Rechargeable",
    detailDepot_00113: "10 minutes",
    detailDepot_00114: "15 minutes",
    detailDepot_00115: "20 minutes",
    detailDepot_00116: "30 minutes",
    detailDepot_00117: "If the device is offline, only users in the whitelist are supported to start charging。",
    detailDepot_00118: "Push whitelist",
    detailDepot_00119: "Clear whitelist",
    detailDepot_00120: "View Whitelist",
    detailDepot_00121: "No",
    detailDepot_00122: "Yes",
    detailDepot_00123: "Input format error",
    detailDepot_00124: "Negative numbers cannot be entered",
    detailDepot_00125: "Push failed",
    detailDepot_00126: "A total of {allDevice} devices were pushed, {sucDevice} devices succeeded and {faiDevice} devices failed",
    detailDepot_00127: "No such charger",
    detailDepot_00128: "{num} chargers",
    detailDepot_00129: "Edit configuration information",
    detailDepot_00130: "C1 charger (4.3)",
    detailDepot_00131: "C1 charger(7寸)",
    detailDepot_00132: "C2 charger",
    detailDepot_00133: "C4 charger",
    detailDepot_00134: "C6 charger",
    detailDepot_00135: "C6s charger",
    detailDepot_00136: "{0} reported fault, fault code: {1}",
    detailDepot_00137: "The maximum amount of time delay charges can only be a number",
    detailDepot_00251: "C9Pro charger",
    zh: "Chinese",
    en: "English",
    de: "German",
    fr: "French",
    detailDepot_00138: "Device reboot successful",
    detailDepot_00139: "Remote reboot",
    detailDepot_00140: "Device reboot failed",
    processing: 'Rebooting in progress',
    detailDepot_00141: "Please select png or jpg format to upload",
    detailDepot_00142: "Up to 6 uploads",
    detailDepot_00143: "The image you have selected is too large, please reselect it",
    detailDepot_00144: "Upload image",
    detailDepot_00145: "Multiple image rotation or mp4 video method, one of two options",
    detailDepot_00146: "Use image rotation",
    detailDepot_00147: "Click to upload",
    detailDepot_00148: "Using the video",
    detailDepot_00149: "5 seconds",
    detailDepot_00150: "10 seconds",
    detailDepot_00151: "15 seconds",
    detailDepot_00152: "Upload up to 6 pictures ",
    detailDepot_00153: "Upload up to 1 video",
    detailDepot_00154: "The video you have selected is too large, please reselect it",
    detailDepot_00155: "Only PNG, JPG, GIF format images are supported, not exceeding {size}M.",
    detailDepot_00156: "Finish",
    detailDepot_00157: "Modified successfully",
    detailDepot_00158: "Modify Location",
    detailDepot_00159: "Please refer to the recommended national standard GB/T 2260-2007 to fill in",
    detailDepot_00160: "Please refer to ISO 3166-1 alpha-2 for completion",
    detailDepot_00161: "Please save the previous operation!" ,
    detailDepot_00162: "Parameter is empty",
    detailDepot_00163: "Please enter the correct amount (greater than 0)",
    detailDepot_00164: "Date set incorrectly",
    detailDepot_00165: "Please enter the country code",
    detailDepot_00166: "Country code formatting error (2 uppercase letters)",
    detailDepot_00167: "Please select site type",
    detailDepot_00168: "Please enter the number of parking spaces at the site",
    detailDepot_00169: "Error entering the number of parking spaces format",
    detailDepot_00170: "Number of parking spaces exceeds limit (<=10000)",
    detailDepot_00171: "The parking fee description cannot exceed 200 characters",
    detailDepot_00172: "Please enter the correct customer service telephone number",
    detailDepot_00173: "Up to 20 digits",
    detailDepot_00174: "Please select the user type and set the percentage",
    detailDepot_00175: "Please enter a positive integer less than 100",
    detailDepot_00176: "Confirmation",
    detailDepot_00177: "Cancel",
    detailDepot_00178: "Deletion successful",
    detailDepot_00179: "Operation cancelled",
    detailDepot_00180: "Sure to delete this field?" ,
    detailDepot_00181: "Total {num} sheets",
    detailDepot_00182: "Charging site supports whitelist",
    detailDepot_00183: "Push results",
    detailDepot_00184: "A total of {allDevice} devices were pushed, {sucDevice} devices succeeded and {faiDevice} devices failed",
    detailDepot_00185: "Charger No.",
    detailDepot_00186: "Device Name",
    detailDepot_00187: "AC Tariff",
    detailDepot_00188: "Select Tariff",
    detailDepot_00189: "New AC tariff",
    detailDepot_00190: "DC Tariff Rates",
    detailDepot_00191: "New DC Tariff",
    detailDepot_00192: "Current usage tariff:",
    detailDepot_00193: "Start time",
    detailDepot_00194: "Close time",
    detailDepot_00195: 'Electricity({type}/{unit})', //electricity({type}/unit)'
    detailDepot_00196: "Service Charge ({type}/{unit})",
    detailDepot_00197: "Idle fee ({type}/min)",
    detailDepot_00198: "Valley/Peak Weekdays",
    detailDepot_00199: 'Valley peak weekdays',
    detailDepot_00200: 'undefined',
    detailDepot_00201: 'Sharp',
    detailDepot_00202: 'peak',
    detailDepot_00203: 'flat',
    detailDepot_00204: 'Valley',
    detailDepot_00205: 'Tariff name',
    detailDepot_00206: 'Select',
    detailDepot_00207: 'New tariff',
    detailDepot_00208: 'Please enter the tariff name',
    detailDepot_00209: 'This tariff name is already occupied',
    detailDepot_00210: 'Add time slot',
    detailDepot_00211: 'Up to {0} time slots can be added',
    detailDepot_00212: 'Operation',
    detailDepot_00213: 'Delete',
    detailDepot_00214: 'Cancel',
    detailDepot_00215: 'OK',
    detailDepot_00216: 'Please select or create a new tariff',
    detailDepot_00217: 'Up to 12 time slots can be added',
    detailDepot_00218: 'Please fill in the tariff',
    detailDepot_00219: 'Tariff period needs to start at 0 and end at 24',
    detailDepot_00220: 'Please fill in the correct tariff',
    detailDepot_00221: 'New Creation Successful',
    detailDepot_00222: 'Push tariff',
    detailDepot_00223: 'Charging site name',
    detailDepot_00224: 'DC',
    detailDepot_00225: 'AC',
    detailDepot_00226: 'Success',
    detailDepot_00227: 'Push in progress',
    detailDepot_00228: 'Failed',
    detailDepot_00229: 'Number of devices',
    detailDepot_00230: 'Device push results',
    detailDepot_00231: 'Group name',
    detailDepot_00232: 'Current tariff',
    detailDepot_00233: 'Current discount',
    detailDepot_00234: 'Priority (from high to low)',
    detailDepot_00235: 'Move up',
    detailDepot_00236: 'Move down',
    detailDepot_00237: 'Set',
    detailDepot_00238: 'Push',
    detailDepot_00239: 'Delete',
    detailDepot_00240: 'No discount on the total cost of a single charging session',
    detailDepot_00241: 'No discount on single charging session service fee',
    detailDepot_00242: 'Total cost of a single charging session {des} discount',
    detailDepot_00243: 'Single charging session service fee {des} discount',
    detailDepot_00244: 'Full free single charging session service fee',
    detailDepot_00245: 'Total cost of a single charging session is fully waived',

    detailDepot_00246: 'Delete',
    detailDepot_00247: 'Delete',
    detailDepot_00248: 'Delete',
    detailDepot_00249: 'Delete',
    detailDepot_00250: 'Delete',


     /* Power distribution */
    detailDepot_00401: "Allows you to add a power allocation group, click to add the station charging posts to the group, set the total group limit charging power by time period, the charging power of the charging posts in the group is allocated from the total group limit charging power for each time period and does not exceed the total group limit charging power for each time period." ,
    detailDepot_00402: "Add power distribution group",
    detailDepot_00403: "There are {count} chargers to push!" ,
    detailDepot_00404: "In order to support offline charging post power allocation, it is necessary to push the maximum offline charging power of the charging port (reserved charging power) to the charging post." ,
    detailDepot_00405: "Push offline power configuration",
    detailDepot_00406: "Unallocated charger in the station",
    detailDepot_00407: "Are you sure you want to delete this power allocation group?" ,
    detailDepot_00408: "Click to add to power allocation group",
    detailDepot_00409: "Please enter the name of the power allocation group",
    detailDepot_00410: "Power allocation group name",

    // Operational settings --- Basic configuration
    abled:"",
    disabled:"",
    // modify: "modify",
    // save: "save",
    // cancel: "Cancel",
    // confirm: "OK",
    // delete: "Delete",

    detailDepot_00499: "Only specific users are allowed to charge",
    detailDepot_00500: "Site Enablement Status",
    detailDepot_00501: "Open to the public",
    detailDepot_00502: "Does it support coupons",
    detailDepot_00503: "Does it support queuing",
    detailDepot_00504: "Does it support reservations",
    detailDepot_00505: "Whether to allow charging after gun timeout",
    detailDepot_00506: "WeChat User Paid Mode",
    detailDepot_00507: "Online ID card user payment mode",
    detailDepot_00508: "Charging station SOC limit",
    detailDepot_00509: "Reminder of remaining electricity charges at the site",
    detailDepot_00510: "Control whether the site is displayed in WeChat Public",
    detailDepot_00511: "Information affecting the presentation of the site on the client",
    detailDepot_00512: "Allow group users in group tariff settings to charge after startup",
    detailDepot_00513: "In prepaid mode, only rechargeable users can recharge",
    detailDepot_00514: "In prepaid mode, only top-up users can charge",
    detailDepot_00515: "Only for DC charger",
    detailDepot_00516: "Reminder will be made when the remaining electricity bill is < reminder amount",
    detailDepot_00517: "Please enter a positive integer less than 100",
    detailDepot_00518: "Please select the user type",
    detailDepot_00519: "Enabled",
    detailDepot_00520: "Not enabled",
    detailDepot_00521: "Open",
    detailDepot_00522: "Not open",
    detailDepot_00523:'Yes',
    detailDepot_00524:'No',
    detailDepot_00525: "Opened",
    detailDepot_00526: "Not open",
    detailDepot_00527: "Supported",
    detailDepot_00528: "Not supported",
    detailDepot_00529: "Parameter is empty",
    detailDepot_00530: "Only supported groups are allowed to charge",
    detailDepot_00531: "Waiting time",
    detailDepot_00532: "minutes",
    detailDepot_00533: "Prepaid mode",
    detailDepot_00534: "Postpaid mode",
    detailDepot_00535: "Minimum balance",
    detailDepot_00536: "Third party user",
    detailDepot_00537: "WeChat users",
    detailDepot_00538: "When the battery power reaches",
    detailDepot_00539:"% when charging ends automatically",
    detailDepot_00540: "Price per kWh",
    detailDepot_00541: "€",
    detailDepot_00542: "Reminder amount",
    detailDepot_00543: "Remaining electricity charges",
    detailDepot_00544: "Please enter the correct amount (greater than 0)",
    detailDepot_00545: "Please save the previous operation!!!" ,
    detailDepot_00546: "Basic settings",

    // 运营设置 ---标签管理
    detailDepot_00547:'Tag Management',
    detailDepot_00548:'Add custom tag',
    detailDepot_00549:'Add site tag in WeChat public number, can find site in WeChat public number and show in site details',
    detailDepot_00550:'tagName',
    detailDepot_00551:'Label map',
    detailDepot_00552:'Tag Type',
    detailDepot_00553:'Display order',
    detailDepot_00554: "Move down",      
    detailDepot_00555: "Move up",
    detailDepot_00556: "Custom Tags",
    detailDepot_00557: "Site Tag Information",
    detailDepot_00558: "Label Name",
    detailDepot_00559: "Label Type",
    detailDepot_00560: "Custom Tags",
    detailDepot_00561: "label_map",
    detailDepot_00562: "Drag the file here, or|click to upload",
    detailDepot_00563: "When there is a tag image, the front displays the tag image. When there is no tag map, the front system default style displays the tag name." ,
    detailDepot_00564: "Only PNG, JPG format images are supported, size {area}px, no more than {size}kb.",
    detailDepot_00565: "Sure to delete?" ,
    detailDepot_00566: "Reminder",
    custom: "Custom",
    undefined: "undefined",
    detailDepot_00567: "Operation successful",
    detailDepot_00568: "Operation failed",
    detailDepot_00569: "Deletion successful",
    detailDepot_00570: "Deleted",
    detailDepot_00571: "Modified successfully",
    detailDepot_00572: "Please enter content, up to 5 characters supported",
    
    // Operational Setup - Charging Order Expansion
    detailDepot_00573: "Charging order expansion",
    detailDepot_00574: "Add display information for charging orders in WeChat Public",
    detailDepot_00575: "Add information",
    detailDepot_00576: "Title",
    detailDepot_00577: "Show link",
    detailDepot_00578: "Display Restrictions",
    detailDepot_00579:'Display order',
    // common_00282:'operation',
    detailDepot_00580: "Charging order display information",
    detailDepot_00581: "Show content",
    detailDepot_00582: "Paid orders only",
    detailDepot_00583: "Unlimited order status",
    detailDepot_00584: "Description",
    detailDepot_00585: "Please enter the URL address",
    detailDepot_00586: "Check this to display this information only after the payment has been completed." ,
    detailDepot_00587: "Please fill in the content",
    detailDepot_00588: "Please enter content",
    detailDepot_00589: "No more than {num} words",
    detailDepot_00590: "Please enter a number greater than 0!" ,
    detailDepot_00591: "Please enter the occupancy charge rate!" ,
    detailDepot_00592: "Please enter the length of time for which the idle fee is waived!" ,
    detailDepot_00593: "Please enter a positive integer",

    // Operational Settings --- Cloud-controlled Parking Lock Idle fee
    detailDepot_00594: "Cloud-controlled parking space lock idle fee",
    detailDepot_00595: "Occupancy Charge Rate({type}/{unit})",
    detailDepot_00596: "No Idle fee Hours (minutes)",
    detailDepot_00597: "For the length of time a vehicle owner drives in to prepare for charging or moves out of a parking space without being charged an idle fee",
    detailDepot_00598: "Online Identity Card",
    // detailDepot_00598: "Online Identity Card",


    // Car park docking
    detailDepot_00600: "Car park docking",
    detailDepot_00601: "The car park docking is for the purpose of giving users a reduction in parking fees or issuing parking coupons",
    detailDepot_00602: "1. Find out if the parking system where this site is located has been docked",
    detailDepot_00603: "2. If you have already docked, enter the car park number and associate the car park with it. (Please check with your car park for the car park system and car park number)",
    detailDepot_00604: "3. If you haven't docked before, you can contact Business and request a dock." ,
    detailDepot_00605: "Introduction to the brands of docked parking systems",
    detailDepot_00606: "Associations",
    detailDepot_00607: "Parking lot associated",
    detailDepot_00608: "Parking ID",
    detailDepot_00609: "Close association",
    detailDepot_00610: "Please enter parking lot ID (only numbers, letters, and underscores are supported)",
    detailDepot_00611: "Filter orders with charging time less than 10 minutes",
    detailDepot_00612: "Confirmation of closing association with {name}?" ,
    detailDepot_00613: "Re-consider",
    detailDepot_00614: "Confirm",
    detailDepot_00615: "Associated Parking",
    detailDepot_00616: "Prompt message",
    detailDepot_00617: "Cannot be empty",
    detailDepot_00618: "The parking lot ID only supports numbers, letters, and underscores.",
    // Open Flow + Government Matching
    detailDepot_00619: "Only government traffic that the operator has already connected to is shown here, for access to more traffic parties, please contact the platform. For more information on access, please visit:",
    detailDepot_00620: "Government Platform",
    detailDepot_00621: "Only free traffic and traffic already connected to the operator are shown here, for access to more traffic parties, please contact the platform. For more information on access, please visit:",
    detailDepot_00622: "Traffic Center",
    detailDepot_00623: "Free",
    detailDepot_00624: "Government",
    detailDepot_00625: "Direct signature",
    detailDepot_00626: "SmartCharge Total Package",
    detailDepot_00627: "This data flow is already compatible with the SmartCharge QR code and needs to be upgraded to a fixed QR code in the charging post details",
    detailDepot_00628: "This data flow is not compatible with the SmartCharge QR code, you need to print the QR code and paste it",
    detailDepot_00629: "Batch Export QR Code",
    detailDepot_00630: "Tariff settings",
    detailDepot_00631: "Site Discount",
    detailDepot_00632: "Start/Stop Settings",
    detailDepot_00633: "Refuse open",
    detailDepot_00634: "Agree to open",
    detailDepot_00635: "Opened - no start/stop",
    detailDepot_00636: "Opened - can be started and stopped",
    detailDepot_00637: "Not open",
    detailDepot_00638: "Opened",
    detailDepot_00639: "No charging posts enabled at the station, please enable at least one charging post." ,
    detailDepot_00640: "Inside charging site info",
    detailDepot_00641: "Country Code,",
    detailDepot_00642: "Province and city code,",
    detailDepot_00643: "Parking fees,",
    detailDepot_00644: "Site type,",
    detailDepot_00645: "Building site,",
    detailDepot_00646: "Number of parking spaces,",
    detailDepot_00647: "Customer Service Phone Number,",
    detailDepot_00648: "Live image,",
    detailDepot_00649: "empty",
    detailDepot_00650: 'Please set "Open or not" to "Open" in [Site Details] - [Site Info] - [Status Info].' ,
    detailDepot_00651: "Charging station",
    detailDepot_00652: "SmartCharge",
    // *******
    diversionDetail_00021: 'Operation',
    diversionDetail_00011: 'Operator',
    diversionDetail_00060: 'Third party data flow  platform',
    diversionDetail_00012: 'Site Status',
    diversionDetail_00013: 'Enabled',
    diversionDetail_00014: 'Not enabled',
    diversionDetail_00068: 'Yes',
    diversionDetail_00069: 'No',
    diversionDetail_00072: 'Return',
    diversionDetail_00073: 'Modified successfully',
    diversionDetail_00074: 'Please enter a number',
    diversionDetail_00075: 'Service fee discount granted by operator to SmartCharge (%)',
    diversionDetail_00076: 'No discount on the total cost of a single charging session (%)',
    diversionDetail_00077: 'Discount on single charging service fee (%)',
    diversionDetail_00078: '85 means 15% off, 0 means full free',
    diversionDetail_00079: 'Single charge at a discount per kWh',
    diversionDetail_00080: 'Display name',
    diversionDetail_00081: 'Please enter a display name, recommended format: brand name (charging site name), the keyword "brand name" needs to be removed from the site name',
    diversionDetail_00082: 'Whether to allow third party start/stop stakes',
    diversionDetail_00083: 'Allow only certain users to charge',
    diversionDetail_00084: 'Think again',
    diversionDetail_00085: 'I got it',
    diversionDetail_00086: 'Go to fill',
    diversionDetail_00087: 'Name cannot be empty',
    diversionDetail_00088: 'Open Success',
    diversionDetail_00089: 'Prompt message',
    diversionDetail_00090: 'Fill in the information',
    diversionDetail_00091: 'OK',
    diversionDetail_00092: 'Whether to allow third party start/stop charger',
    diversionDetail_00093: 'Direct tariff setting',
    diversionDetail_00094: 'If the tariff has been changed, click on the "Push Tariff" button in Site Details - Tariff to make the tariff effective!' ,
    diversionDetail_00095: 'Exchange rates',
    diversionDetail_00096: 'Select AC tariff',
    diversionDetail_00097: 'DC tariff',
    diversionDetail_00098: 'Select DC tariff rate',
    diversionDetail_00099: 'Site discount settings',
    diversionDetail_00100: 'Add charging site discount',
    diversionDetail_00101: 'Start time',
    diversionDetail_00102: 'Now',
    diversionDetail_00103: 'End time',
    diversionDetail_00104: 'unlimited',
    diversionDetail_00105: 'Preferred method',
    diversionDetail_00106: '85 means 15% off',
    diversionDetail_00107: 'Discount per kWh ($)',
    diversionDetail_00108: 'Use the service fee discount method',
    diversionDetail_00109: 'Use the per kWh discount method',
    diversionDetail_00110: 'Status',
    diversionDetail_00111: 'Not started',
    diversionDetail_00112: 'Started',
    diversionDetail_00113: 'Validated',
    diversionDetail_00114: 'Not valid',
    diversionDetail_00115: 'Site Discount History',
    diversionDetail_00116: 'Please save the previous operation!',
    diversionDetail_00117: 'Start time cannot be empty',
    diversionDetail_00118: 'The end time must not be empty',
    diversionDetail_00119: 'The preference method must not be empty',
    diversionDetail_00120: 'Please enter a positive integer less than 100',
    diversionDetail_00121: 'Saved successfully',
    diversionDetail_00122: 'Service Fee Discount:{dis}%',
    diversionDetail_00123: 'Discount per kWh:{dis}$',
    diversionDetail_00124: 'Refuse open to {name} site information, {name} will not display any site information.' ,
    diversionDetail_00125: 'Confirmation of refusing open to {name} site information?' ,
    // *******
    // Equipment Management - Equipment List
    diversionDetail_00126: 'Charger serial number or name',
    diversionDetail_00127: 'model',
    diversionDetail_00128: 'Status',
    diversionDetail_00129: 'Available',
    diversionDetail_00130: 'See only inserted guns',
    diversionDetail_00131: 'In use',
    diversionDetail_00132: 'Only view draws',
    diversionDetail_00133: 'Charging Post Self-Activation',
    diversionDetail_00134: 'Add device',
    diversionDetail_00135: 'Device serial number:',
    diversionDetail_00136: 'Manufacturer - Model:',
    diversionDetail_00137: 'Device Details',
    diversionDetail_00138: 'Charging point:',
    diversionDetail_00139: 'Pending diversion',
    diversionDetail_00140: 'Appointment in progress',
    diversionDetail_00141: 'Connected',
    diversionDetail_00142: 'Connected',
    diversionDetail_00143: 'View order',
    diversionDetail_00144: 'Self-activating charger',
    diversionDetail_00145: 'After following the steps below to self-activate the charging post, you can add the charging post to the charging station.' ,
    diversionDetail_00146: 'Step 1',
    diversionDetail_00147: 'Scan the QR code on the left using your mobile WeChat',
    diversionDetail_00148: "Kindly tips: You can save the page to WeChat's favorites, so you can easily open and use it at any time.",
    diversionDetail_00149: 'Step 2',
    diversionDetail_00150: 'Enter the SN of the charger on the mobile, initiate the activation and confirm it on the charger.' ,
    diversionDetail_00151: 'Enter full  serial number',
    diversionDetail_00152: 'Find chargers by device number',
    diversionDetail_00153: 'This device has been added, further additions will be transferred to the current charging station and will require a reboot before charging',
    diversionDetail_00154: '(e.g. AC #1)',
    diversionDetail_00155: 'Set the name of the device in the field',
    diversionDetail_00156: 'Device added successfully',
    diversionDetail_00157: 'You can do the following in "Charging station management" "Manage devices" "Device details":',
    diversionDetail_00158: '1. Modify the device name;',
    diversionDetail_00159: '2. The status of whether the change is enabled or not;',
    diversionDetail_00160: '3. Set whether the electromagnetic lock and radar are enabled;',
    diversionDetail_00161: 'This device has been added to the current site',
    diversionDetail_00162: 'Please enter the full device serial number',
    diversionDetail_00163: 'Please complete the device information',
    diversionDetail_00164: 'Normal',
    diversionDetail_00165: 'Locked car',
    diversionDetail_00166: 'Dormant',
    diversionDetail_00167: 'Unknown',
    diversionDetail_00168: 'Insert gun',
    diversionDetail_00169: 'End of charge',
    diversionDetail_00170: 'See only unreturned',
    diversionDetail_00171: 'Returned',
    diversionDetail_00172: 'Not homed',
    // POS payment setup
    diversionDetail_00173: 'POS Payment Setup',
    diversionDetail_00174: 'Push POS payment settings',
    diversionDetail_00175: 'Only charging posts with POS are supported for this configuration',
    diversionDetail_00176: 'Pre-authorized amount',
    diversionDetail_00177: 'Current tariff currency type of the site',
    diversionDetail_00178: 'Confirmation push',
    diversionDetail_00179: 'Current tariff currency type of the site',
    diversionDetail_00180: 'List of stakes with POS installed at this site',
    diversionDetail_00181: 'POS payment status',
    diversionDetail_00182: 'Select all',
    diversionDetail_00183: 'Device serial number',
    diversionDetail_00184: 'Manufacturer-Model',
    diversionDetail_00185: 'POS Currency Type',
    diversionDetail_00186: 'Please select device',
    diversionDetail_00187: 'Push successful',
    diversionDetail_00188: 'Waiting for response',
    diversionDetail_00189: 'POS currency type of charger does not match platform settings, please check',
    diversionDetail_00190: "The currency type of the POS at the charger is {list}, there is a conflict and it is recommended to overhaul the incorrect charger offline. Some chargers with a different currency type {default} than the site's selected tariff template will not enable POS payments. Please check the currency type of the pile or change the currency type of the site tariff.",
    diversionDetail_00191: 'Use currency',
    diversionDetail_00192: 'This site needs to select a tariff type of {type}, there is currently no such tariff template, please create a new tariff.' ,
    diversionDetail_00193: 'and',
    diversionDetail_00194: 'Please enter the correct amount to be withheld',
    diversionDetail_00195: 'Prompt message',
    diversionDetail_00196: 'Modify tariff',
    diversionDetail_00197: 'The current charging site settlement currency type is different from the public tariff currency type',
    diversionDetail_00198: 'Please reselect the tariff with currency type {type}',
    diversionDetail_00199: 'Site settlement currency type',
    diversionDetail_00200: 'The current site currency type does not support adding group rates and offers at this time.' ,
    diversionDetail_00201: 'After changing the currency type of the site, please change the public tariff and group tariff to the same currency type and push the tariff again',

    /* Public transport charging */
    detailDepot_00411: "Enter 0 for unrestricted",
    detailDepot_00412: "Basic Information Settings",
    detailDepot_00413: "Total charge power limit for the whole station (KW)",
    detailDepot_00414: "Basic Information Settings",
    detailDepot_00415: "0 means no limit on total charging power",
    detailDepot_00416: "Enabled or not",
    detailDepot_00417: "Please enter the correct value",
    detailDepot_00418: "Bus Vehicles",
    detailDepot_00419: "Line Schedule",
    detailDepot_00420: "Group Limiting Power",
    detailDepot_00421: "EV number",
    detailDepot_00422: "EV MAC",
    detailDepot_00423: "EV VIN",
    detailDepot_00424: "Total nominal energy of the vehicle power battery (kW-h)",
    detailDepot_00425: "Recording EV",
    detailDepot_00426: "Import EV",
    detailDepot_00427: "Download Import Template",
    detailDepot_00428: "Generate",
    detailDepot_00429: "Cannot be empty",
    detailDepot_00430: "Only two decimal places are allowed for the total nominal energy of the power battery of the complete vehicle",
    detailDepot_00431: "Vehicle MAC parameter is illegal",
    detailDepot_00432: "Download Form",
    detailDepot_00433: "Are you sure you want to delete this EV (EV No.: {num})?" ,
    detailDepot_00434: "Optional",
    detailDepot_00435: "Total power to limit charging in group (KW)",
    detailDepot_00436: "Please fill in all required fields!" ,
    detailDepot_00437: "Please enter the correct value",
    detailDepot_00438: "Modify power distribution group",
    detailDepot_00439: "View Live operation",
    detailDepot_00440: "Import",
    detailDepot_00441: "EV - Charging space recommendation",
    detailDepot_00442: "Download",
    detailDepot_00443: "Effective date",
    detailDepot_00444: "Currently in use",
    detailDepot_00445: "Recently scheduled use",
    detailDepot_00446: "Line",
    detailDepot_00447: "Shifts",
    detailDepot_00448: "Departure time",
    detailDepot_00449: "Return time",
    detailDepot_00450: "EV Code",
    detailDepot_00451: "Minimum required SOC at departure (%)",
    detailDepot_00452: "Please select a date",
    detailDepot_00453: "Effective immediately",
    detailDepot_00454: "Adjustment of frequency vehicles",
    detailDepot_00455: "Are you sure you want to delete the vehicle route schedule?" ,
    detailDepot_00456: "Click to upload",
    detailDepot_00457: "Imported files",
    detailDepot_00458: "Import of vehicle route schedules",
    detailDepot_00459: "",
    detailDepot_00460: "",
    detailDepot_00461: "",
    detailDepot_00462: "",
    detailDepot_00463: "",
    detailDepot_00464: "",
    detailDepot_00465: "",
    detailDepot_00466: "",
    detailDepot_00467: "",

    // Site Information
    detailDepot_10001: "Charging site Address",
    detailDepot_10002: "Map Location",
    detailDepot_10003: "Click the button on the right to get the location",
    detailDepot_10004: "Modify Location",
    detailDepot_10005: "Province and city code",
    detailDepot_10006: "Country Code",
    detailDepot_10007: "Supported models",
    detailDepot_10008: "Site Type",
    detailDepot_10009: "Customer Service Phone Number",
    detailDepot_10010: "Site Contact",
    detailDepot_10011: "Mobile number",
    detailDepot_10012: "Mailbox",
    detailDepot_10013: "Building site",
    detailDepot_10014: "Location of parking spaces",
    detailDepot_10015: "Number of parking spaces",
    detailDepot_10016: "Parking Fees",
    detailDepot_10017: "Live image",
    detailDepot_10018: "Click to upload image",
    detailDepot_10019: "Only PNG/JPG/GIG format images are supported",
    detailDepot_10020: "No more than 1M",
    detailDepot_10021: "Failed to get location",
    detailDepot_10022: "Close",
    detailDepot_10023: "Find",
    detailDepot_10024: "Search Results",
    detailDepot_10025: "Drag the file here, or ",
    detailDepot_10026: "Click to upload",
    detailDepot_10027: "Please enter the city code",
    detailDepot_10028: "Incorrect city code format (6 characters)",
    // Equipment Management
    detailDepot_10029: "List of devices",
    detailDepot_10030: "Device Configuration",
    detailDepot_10031: "Screen Configuration",
    detailDepot_10032: "Whitelist Configuration",
    detailDepot_10033: "Pushes configuration information after each modification to ensure the charging pile is functioning properly." ,
    detailDepot_10034: "Push Configuration",
    detailDepot_10035: "Charging Post Local Information Settings",
    detailDepot_10036: "Save",
    detailDepot_10037: "Edit",
    detailDepot_10038: "Information on the configuration of the charging post function",
    detailDepot_10039: "Compensation factor setting",
    detailDepot_10040: "Idle fee setting",
    detailDepot_10041: "Restrictions",
    detailDepot_10042: "Unrestricted",
    detailDepot_10043: "Success",
    detailDepot_10044: "Failed",
    detailDepot_10045: "Push in progress",
    detailDepot_10046: "The screen configuration includes images, copy and advertising on the charging post screen, depending on the device and screen size." ,
    detailDepot_10047: "Whitelist Settings",
    detailDepot_10048: "If the device is offline, only users in the whitelist are supported to start charging." ,
    detailDepot_10049: "Push Whitelist",
    detailDepot_10050: "Clear whitelist",
    detailDepot_10051: "View Whitelist",
    // Tariff setting
    detailDepot_10052: "Public tariff",
    detailDepot_10053: "Group Tariff",
    detailDepot_10054: "Please push the finished set tariff to the charging post so that the charging post can bill the user for the charge at the new tariff." ,
    detailDepot_10055: "Push tariffs",
    detailDepot_10056: "Please change the default tariff first",
    detailDepot_10057: "No devices under this site, unable to push",
    detailDepot_10058: "Push failed",
    detailDepot_10059: "Deleted",
    detailDepot_10060: "This action will remove the group from the list, do you want to continue?" ,
    detailDepot_10061: "Tip",
    detailDepot_10062: "List of groups",
    detailDepot_10063: "Adding groups",
    detailDepot_10064: "New tariff",
    detailDepot_10065: "Select group",
    detailDepot_10066: "Please enter the name of the group",
    detailDepot_10067: "Number of groups",
    detailDepot_10068: "Tariffs",
    detailDepot_10069: "Please select tariff",
    detailDepot_10070: "Discount Selection",
    detailDepot_10071: "No discount on the cost of a single charge",
    detailDepot_10072: "Discounted single charge service fee (%)",
    detailDepot_10073: "Example: If you set a single charging service fee of 8% off, and the charging cost 100 € (including €70  for electricity and €30 for service), the actual amount you need to pay this time is €95.5 (70+30*85%=95.5)",
    detailDepot_10074: "85 means 8% off, 0 means full free",
    detailDepot_10075: "Discount on the total cost of a single charge (%)",
    detailDepot_10076: "Example: If you set the total cost of a single charge at 20% off and the total cost of this charge is €100, the actual amount you need to pay this time is €85 (100*85%=85)",
    detailDepot_10077: "Group added successfully",
    detailDepot_10078: "Click the [ Push Charge ] button in the tariff to make the tariff effective!" ,
    detailDepot_10079: "You can do the following in [Charging station management Site details Site information]:",
    detailDepot_10080: "1. Remove added groups",
    detailDepot_10081: "2. Add a new group",
    detailDepot_10082: "Previous",
    detailDepot_10083: "Next",
    detailDepot_10084: "Please select a group",
    detailDepot_10085: "Please select a tariff",
    detailDepot_10086: "User groups cannot be added repeatedly",
    detailDepot_10087: "Please set the discount parameter",
    detailDepot_10088: "Please enter a positive integer less than 100",
    detailDepot_10089: "Select tariff and discount",
    detailDepot_10090: "No discount on the total cost of a single successful point (%)",
    detailDepot_10092: "Idle fee-free periods",
    detailDepot_10093: "No idle fee-free periods",
    detailDepot_10094: "There is an idle fee waiver period",
    detailDepot_10095: "The time slot is the local time slot where the station is located, up to 5 time slots can be added",
    detailDepot_10096: "Idle fee-free periods can be set here, depending on the needs of the site operator." ,
    detailDepot_10097: "There is an overlap in the time period!" ,
    detailDepot_10098: "Please fill in the occupation fee period",
    detailDepot_10099: "The end time needs to be greater than the start time",
    detailDepot_10100: "Add time slot",
    detailDepot_10101: "abnormal",
    detailDepot_10102:"According to the average value of 30 days, the current balance can take {num} days",
    detailDepot_11052: "Energy site",
    detailDepot_40002: "Charging configuration of the charging card when the device is offline",
    detailDepot_40003: "Allow all",
    detailDepot_40004: "Whitelist allowed",
    detailDepot_40005: "When offline, the charging card starts charging mode",
    detailDepot_51001: "The actual payment amount will not exceed the pre-authorized amount set here, including electricity fee, service fee, idle fee, taxes, etc.",
}