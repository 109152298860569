export default {
    accountManage_00001: "Bitte geben Sie Kontonummer, Inhaber, Handynummer ein.",
    accountManage_00002: "Kontonummer",
    accountManage_00003: "Inhaber",
    accountManage_00004: "Handynummer",
    accountManage_00005: "Rolle",
    accountManage_00006: "Kontonummer hinzufügen",
    accountManage_00007: "Operation",
    accountManage_00008: "Ladestationsbereich verwalten",
    accountManage_00009: "Konto hinzufügen",
    accountManage_000010: "Konten Liste",
    accountManage_000011: "Konto aufbereiten",
    accountManage_000012: "Betriebskonto anmelden",
    accountManage_000013: "Passwort",
    accountManage_000014: "Passwort zur Anmeldung",
    accountManage_000015: "Mindestens 6 ziffern und besteht aus mindestens einer zahl, einem buchstaben und einem spezialzeichen",
    accountManage_000016: "Inhaber",
    accountManage_000017: "Verwenden Sie Ihren echten Namen für eine einfache Nachverfolgung",
    accountManage_000018: "Handynummer",
    accountManage_000019: "Einfache Kontaktaufnahme mit dem Administrator für andere",
    accountManage_000020: "Rolle",
    accountManage_000021: "CPO Administrator",
    accountManage_000022: "Bitte auswählen",
    accountManage_000023: "Bitte Konto Rolle auswählen",
    accountManage_000024: "Erfolgreiche Operation",
    accountManage_000025: "Bitte geben Sie Ladestationsname ein",
    accountManage_000026: "Erfolgreich Ladestation hinzufügen",
    accountManage_000027: "ausgewählte Ladestation",
    accountManage_000028: "Erfolgreich Löschen",
    accountManage_000029: "Super-Administrator",
    accountManage_000030: "Sind Sie sicher, dass Sie löschen möchten?",
    accountManage_000031: "Löschen abgebrochen",
    accountManage_000032: "Zeit hinzufügen",
    accountManage_000033: "Briefkasten",
    accountManage_000034: "Verbindung hergestellt",
    accountManage_000035: "Entsichern",
    accountManage_000015: "Buchstaben und Zahlen unterstützen, nicht mehr als 20 Zeiche",
}