export default {
    parkRecommend_00001: "Download",
    parkRecommend_00002: "Charging simulation",
    parkRecommend_00003: "Vehicle - charging space recommendation",
    parkRecommend_00004: "Charging parking space",
    parkRecommend_00005: "Chargers",
    parkRecommend_00006: "Vehicle code",
    parkRecommend_00007: "Charging simulation generation parameters",
    parkRecommend_00008: "EV power consumption per kilometer（kW·h/km）",
    parkRecommend_00009: "EV speed（km/h）",
    parkRecommend_00010: "Error range of vehicle return time（+-）",
    parkRecommend_00011: "10 minutes",
    parkRecommend_00012: "30 minutes",
    parkRecommend_00013: "60 minutes",
    parkRecommend_00014: "Please enter a number greater than 0!",
    parkRecommend_00015: "Download form",
}