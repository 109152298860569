export default {
  orderMangement_00001: '工单管理',
  orderMangement_00002: '我们会通过“智充”公众号的的模板消息，来给工单的处理人和关注人通知工单的各个状态变动消息。',
  orderMangement_00003: '请使用微信扫描左侧的公众号二维码，关注“智充”公众号。',
  orderMangement_00004: '在公众号下方功能菜单中，找到并打开“智维保”小程序。用平台账号登录“智维保”小程序。',
  orderMangement_00005: '之后就可以通过小程序或者“智充”公众号，来接收到对应账号的工单通知了。',
  orderMangement_00006: '之后就可以通过小程序或者微信服务通知栏，来接收到对应账号的维保通知了。',
  orderMangement_00007: '我发起的工单',
  orderMangement_00008: '我处理的工单',
  orderMangement_00009: '我关注的工单',
  orderMangement_00010: '未关闭的工单',
  orderMangement_00011: '所有工单',
  orderMangement_00012: '发起工单',
  orderMangement_00013: '工单标题',
  orderMangement_00014: '全部站点',
  orderMangement_00015: '全部',
  orderMangement_00016: '工单顶级分类',
  orderMangement_11116: '工单二级分类',
  orderMangement_00017: '工单编号',
  orderMangement_00018: '全部厂商设备',
  orderMangement_00019: '全部型号',
  orderMangement_00020: '待处理工单',
  orderMangement_00021: '处理中工单',
  orderMangement_00022: '已处理工单',
  orderMangement_00023: '已关闭工单',
  orderMangement_00024: '工单列表',
  orderMangement_00025: '导出工单',
  orderMangement_00026: '工单编号',
  orderMangement_00027: '工单标题',
  orderMangement_00028: '分类',
  orderMangement_00029: '优先级',
  orderMangement_00030: '状态',
  orderMangement_00031: '问题场站',
  orderMangement_00032: '处理人',
  orderMangement_00033: '发起人',
  orderMangement_00034: '发起时间',
  orderMangement_00035: '更新时间',
  orderMangement_00036: '问题来源',
  orderMangement_00037: '操作',
  orderMangement_00038: '退回工单',
  orderMangement_00039: '去处理',
  orderMangement_00040: '接单',
  orderMangement_00041: '转派工单',
  orderMangement_00042: '关闭工单',
  orderMangement_00043: '加入知识库',
  orderMangement_00044: '详情',
  orderMangement_00045: '非常紧急',
  orderMangement_00046: '紧急',
  orderMangement_00047: '一般',
  orderMangement_00048: '待处理',
  orderMangement_00049: '处理中',
  orderMangement_00050: '已处理',
  orderMangement_00051: '已关闭',
  orderMangement_00052: '备注',
  orderMangement_00053: '请输入内容',
  orderMangement_00054: '解决方案',
  orderMangement_00055: '转派处理人',
  orderMangement_00056: '接单',
  orderMangement_00057: '是否确认接单',
  orderMangement_00058: '确认接单',
  orderMangement_00059: '转派工单',
  orderMangement_00060: '确认转派',
  orderMangement_00061: '转派原因',
  orderMangement_00062: '关闭工单',
  orderMangement_00063: '是否确认关闭工单',
  orderMangement_00064: '确认关闭',
  orderMangement_00065: '关闭原因',
  orderMangement_00066: '退回工单',
  orderMangement_00067: '是否确认退回工单',
  orderMangement_00068: '确认退回',
  orderMangement_00069: '退回原因',
  orderMangement_00070: '已处理工单',
  orderMangement_00071: '是否确认已处理工单',
  orderMangement_00072: '确认已处理',
  orderMangement_00073: '请填写完整',
  orderMangement_00074: '操作成功',
  orderMangement_00075: '工作人员上报',
  orderMangement_00076: '客户反馈',
  orderMangement_00077: '设备上报',
  orderMangement_00078: '自动触发',
  orderMangement_00079: '其他',
  orderMangement_00080: '工单优先级',
  orderMangement_00081: '工单状态',
  orderMangement_00082: '成为关注人',
  orderMangement_00083: '桩企',
  orderMangement_00084: '我们会通过发起人、处理人和关注人绑定的邮箱进行工单通知',
  
  // 8817新加
  orderMangement_00085: '备件编码或备件名称',
  orderMangement_00086: '确定已处理',
  orderMangement_00087: '添加备件物料',
  orderMangement_00088: '全部运营商',
  orderMangement_00089: '全部场站',
  orderMangement_00090: '运营商名称',
  orderMangement_00091: '桩企',
  orderMangement_00092: '我们将通过与启动器、处理器和跟踪器绑定的邮箱通知工作订单',
  orderMangement_00093: '物料名称',
  orderMangement_00094: '物料数量',
  orderMangement_00095: '物料SN',
  orderMangement_00096: '请输入所用物料SN，一行一个',
  orderMangement_10000: '设备SN',
  orderMangement_10001: '当前处理人',


  work_00058: '工单编号',
  work_00059: '解决方案',
  work_00060: '历史方案',
  work_10006: '问题描述',
  work_10007: '切换设备',
  work_10008: '添加问题',
  work_10009: '场站位置',
  work_10010: '入场须知',
  work_10011: '相关订单',
  work_10012: '选择订单',
  work_10013: '故障信息',
  work_10014: '故障代码',
  work_10015: '全部场站',
  work_10016: '订单编号',
  work_10017: '',
  work_10020: '请处理所有问题',
  work_00007: '工单编号',
  work_00008: '问题场站',
  work_00009: '问题设备品牌型号',
  work_00010: '问题来源',
  work_00011: '发起人',
  work_00012: '发起时间',
  work_00013: '更新时间',
  work_00014: '当前处理人',
  work_00016: '一般',
  work_00017: '紧急',
  work_00018: '非常紧急',
  work_00050: '手机：{mobile}',
  work_00051: '扫码接收通知',
}