<template>
  <el-dialog
    :title="$t('common_70014')"
    :visible.sync="visibleTime"
    :lock-scroll="false"
    :close-on-click-modal="false"
    :before-close="close"
    width="900px"
  >
    <div class="tip-wrap">
        <!-- <i class="iconfont icon-yichang icon"></i> -->
        <p>{{$t('common_70017')}}</p>
        <p>{{$t('common_70018')}}</p>

    </div>
    <div class="title">
      <el-button type="text" @click="addTime" class="text-5278E2">{{$t("common_70019")}}</el-button>
    </div>
    <!-- 时间段列表 -->
    <el-table :data="customList" :header-cell-style="{
      background: '#EFF2F6',
      color: '#000',
      borderRadius: '4px 4px 0px 0px',}" height="364px" style="width: 100%">
      <el-table-column  :label="$t('common_61008')">
        <template slot-scope="scope">
          <el-time-select size="medium" style="width: 100%" v-model="scope.row.from" :clearable="false" :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '24:00',
          }">
          </el-time-select>
        </template>
      </el-table-column>
      <el-table-column  :label="$t('common_61009')">
        <template slot-scope="scope">
          <el-time-select size="medium" style="width: 100%" v-model="scope.row.to" @blur="endTimeChange(scope.row,scope.$index)" :clearable="false" :picker-options="{
            start: '00:00',
            step: '00:15',
            end: '24:00',
          }">
          </el-time-select>
        </template>
      </el-table-column>

      <el-table-column :label="$t('common_70020')" >
        <template slot-scope="scope">
          <el-select v-model="scope.row.priorityType" :placeholder="$t('common_70020')"  size="medium">
            <el-option
              v-for="(item,key) in electricEnergyList"
              :label="item" :value="Number(key)" :key="key">
            </el-option>
          </el-select>
        </template>
        
      </el-table-column>
      <el-table-column :label="$t('common_00282')" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="text" class="text-FA545E" @click="deleteTime(scope.$index)">{{ $t("common_00214") }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">{{$t('common_00215')}}</el-button>
      <el-button type="primary" @click="toSubmit">{{$t('common_00234')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  requestUrl,
  ajax,
  getQueryString,
  getDateFromLongTime,
} from "@/assets/utils/network";
import electricEnergyList_cn from '@/assets/js/electricEnergyList_cn.js'
import electricEnergyList_eu from '@/assets/js/electricEnergyList_eu.js'


export default {
  props:['scheduleList'],
  watch:{
    scheduleList : {
      handler (val) {
        console.log(val,'-----scheduleList--------')
        if(val.length) {
          this.customList = JSON.parse(JSON.stringify(val)) 
        }
      },
      deep: true,
      immediate:true
    },
  },
  data() {
    return {
      electricEnergyList:{},
      product:"cn",
      lang: "zh",
      timePlaneScheduleShow:false,
      esEcoModeTowardPhase:"",
      visibleTime: true,
      // 时间段
      customList: [
        {
          from: '00:00', //时间格式：“HH:MM”  闭区间，如：“00:00”
          to: '24:00', //时间格式：“HH:MM”  开区间，如：“24:00”
          priorityType: 1, //工况优先级类型
        },
      ],
    };
  },
  
  created() {
    this.lang = window.localStorage.lang;
    this.product = window.product_area;
    if(this.product == 'cn'){
      this.electricEnergyList = electricEnergyList_cn
    }else{
      this.electricEnergyList = electricEnergyList_eu
    }
  },

  methods:{
    // 添加时间
    addTime() {
      if (this.customList.length >= (this.product == "cn" ? 16 : 12)) {
        this.$message({
          type: "error",
          offset: 70,
          message: this.$parent.$t("common_61007", [
            this.product == "cn" ? 16 : 12,
          ]),
        });
        return;
      }
      this.customList.push({
        from: '00:00', //时间格式：“HH:MM”  闭区间，如：“00:00”
        to: '24:00', //时间格式：“HH:MM”  开区间，如：“24:00”
        priorityType: '', //工况优先级类型
      });
    },
    // 结束时间===下一阶段开始时间
    endTimeChange(row, index) {
      if (index < this.customList.length - 1) {
        this.customList[index + 1].from = row.to;
      }
    },
    // 删除时间
    deleteTime(index) {
      this.customList.splice(index, 1);
    },
    copeTime() {},
    editPlane(){
      this.timePlaneScheduleShow = true;
    },
    delTime(){},
    check(v){
      console.log('v',v)
    },
    close() {
      this.$emit("close");
    },
    toSubmit() {
      if (this.customList.length == 0) {
        this.$message({
          type: "error",
          offset: 70,
          message: this.$t("common_70037"),
        });
        return false;
      }
      // 判断资费填写是否正确
      let timedPrice = JSON.parse(JSON.stringify(this.customList));
      let dataSite = timedPrice.length;
      let first = timedPrice[0];
      let last = timedPrice[dataSite - 1];
      if (first.from != "00:00" || last.to != "24:00") {
        this.$message({
          type: "error",
          offset: 70,
          message: this.$t("common_70036"),
        });
        return false;
      }
      for (let i = 1; i < timedPrice.length; i++) {
        if (timedPrice[i].from >= timedPrice[i].to) {
          this.$message({
            type: "error",
            offset: 70,
            message: this.$t("common_70036"),
          });
          return false;
        }
        if (timedPrice[i - 1].to != timedPrice[i].from) {
          this.$message({
            type: "error",
            offset: 70,
            message: this.$t("common_70036"),
          });
          return false;
        }
        if (
          Number(timedPrice[i].priorityType) < 1
        ) {
          this.$message({
            type: "error",
            offset: 70,
            message: this.$t("common_70035"),
          });
          return false;
        }
      }
      if (this.customList.length && (Number(this.customList[0].priorityType) < 1)) {
        this.$message({
          type: "error",
          offset: 70,
          message: this.$t("common_70035"),
        });
        return false;
      }

      console.log(this.customList,'dasf---------')
      this.$emit('confirm',this.customList)

    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
}
/deep/.el-input__inner {
  height: 36px;
  line-height: 36px;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.m20 {
  margin: 0 20px;
}
.flex-container-left {
  margin-bottom: 20px;
  .tips {
    i {
      margin-right: 15px;
    }
    margin-bottom: 20px;
  }
}
.dialog-btn{
  display: block;
  width: 100%;
  text-align: right;
}
.mode-detail{
  .mode-info{
    display: flex;
    margin-bottom: 10px;
    p{
      width: 72px;
      flex-shrink: 0;
    }
    .mode-content{
      text-align: justify;
      
    }
  }
}
.tip-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background: #f2f2f2;
  color: #333;
  padding: 10px 5px;
    .icon {
      // font-size: 20px;
      margin-right: 10px;
      margin-top: 2px;
      flex-shrink: 0;
    }
    p{
      flex: 1;
      margin-bottom: 10px;
      :last-child{
        margin-bottom: 0;
      }
    }
}
.t-title{
  margin: 20px 0;
  p{
    margin-bottom: 10px;
  }
}
.table{
  .table-head {
    display: flex;
    width: 100%;
    height: 40px;
    background: #EFF2F6;
    border-radius: 4px 4px 0px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #1B2330;
    padding: 10px;
    box-sizing: border-box;
    .h-items{
      &:nth-child(1){
        width: 20%;
      }
      &:nth-child(2){
        width: 20%;
      }
      &:nth-child(3){
        width: 48%;
      }
      &:nth-child(4){
        width: 12%;
        
      }

    }
  }
  .table-content{
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid hsl(214, 28%, 95%);
    padding-bottom: 16px;
    .c-items{
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border-bottom: 1px dashed #E6E7EC;
      &:last-child{
        border-bottom: none;
      }
      .c-date{
        width: 20%;
        text-align: center;
      }
      .c-week{
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        .week-time-list {
          width: 100%;
          height: 400px;
          overflow-y: scroll;
          .week-items {
            .week-range {
              .w-head{
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              
              // justify-content: space-between;
              // align-items: center;
            }
            .day-time-list {
              .table-time{
                .table-head-time {
                  display: flex;
                  width: 100%;
                  height: 40px;
                  background: #EFF2F6;
                  border-radius: 4px 4px 0px 0px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #1B2330;
                  padding: 10px;
                  box-sizing: border-box;
                  .h-items{
                    &:nth-child(1){
                      width: 20%;
                    }
                    &:nth-child(2){
                      width: 20%;
                    }
                    &:nth-child(3){
                      width: 60%;
                    }

                  }
                }
                .table-content-time{
                  display: flex;
                  background: #FFFFFF;
                  border-radius: 4px;
                  border: 1px solid hsl(214, 28%, 95%);
                  padding: 0 10px 16px 10px;
                  .t-items{
                    display: flex;
                    align-items: center;
                    padding: 20px 0;
                    &:last-child{
                      border-bottom: none;
                    }
                    &.c-time{
                      width: 20%;
                    }
                    &.c-type{
                      width: 60%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .c-opt{
        width: 12%;
        text-align: center;
      }
    }
  }
}
.dialog-footer{
  
  text-align: center;
  .el-button{
    margin: 0 30px;
  }
}
</style>
