export default {
    feedback_00001: 'WeChat-Kundenservice',
    feedback_00002: 'Kundendienst',
    feedback_00003: 'Bitte Benutzernamen eingeben',
    feedback_00004: 'Bitte geben Sie den Inhalt ein',
    feedback_00005: 'Startdatum',
    feedback_00006: 'Endtermin',
    feedback_00007: 'zu',
    feedback_00008: 'Nachrichtenliste',
    feedback_00009: 'Aufgrund von offiziellen WeChat-Kontobeschränkungen können Nachrichten nicht innerhalb von 48 Stunden beantwortet werden',
    feedback_00010: 'Zeitüberschreitung bei Antworten',
    feedback_00011: 'Keine Antwortzeitüberschreitung',
    feedback_00012: 'Unbeantwortet - ',
    feedback_00013: 'Beantwortet - ',
    feedback_00014: 'Offizieller Account-Hintergrundlink',
    feedback_00015: 'Antwort',
    feedback_00016: 'Bitte geben Sie den Antwortinhalt ein',
    feedback_00017: 'senden',
    feedback_00018: 'Vor einer Stunde',
    feedback_00019: 'Vor ein paar Minuten',
    feedback_00020: 'Die Antwortnachricht darf nicht leer sein',
    feedback_00021: 'Erfolgreich antworten',
    feedback_00022: 'Administrator',
    feedback_00023: "Textnachricht",
    feedback_00024: "Bildnachricht",
    feedback_00025: "Sprachnachricht",
    feedback_00026: "Videonachricht",
    feedback_00027: "Kleine Videobotschaft",
    feedback_00028: "Link-Nachricht",
    feedback_00029: "Standortnachricht",
    feedback_00030: "Der Nachrichtentyp ist {0}, bitte überprüfen Sie ihn im Hintergrund des offiziellen WeChat-Kontos!",
  }