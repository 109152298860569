
export default {
  userClassDetail_00001: 'Klassifikationseinzelheit',
  userClassDetail_00002: 'Klassifikationsname ändern',
  userClassDetail_00003: 'Marketing-Aktivität',
  userClassDetail_00004: 'Klassifikationsnutzer',
  userClassDetail_00005: 'Klassifikationsbedingung',
  userClassDetail_00006: 'Das System aktualisiert die Liste der eingestuften Benutzer automatisch nach den Einstufungskriterien',
  userClassDetail_00007: 'Spitzname, Mobiltelefonnummer, Kfz-Kennzeichen',
  userClassDetail_00008: 'Nutzerliste',
  userClassDetail_00009: 'Nutzer exportieren',
  userClassDetail_00010: 'Regionen',
  userClassDetail_00011: 'Zeit von letzter bezahlter Vorgang bis Heute',
  userClassDetail_00012: 'Abrechnungszeit bis heute',
  userClassDetail_00013: 'bezahlte Vorgang von einzelne nicht gruppenbezogene Nutzer',
  userClassDetail_00014: 'Kumulierte Anzahl der Vorgänge',
  userClassDetail_00015: 'bis',
  userClassDetail_00016: 'Kumulierter gezahlter Betrag (EUR)',
  userClassDetail_00017: 'Kumulierte Ladung (kWh)',
  userClassDetail_00018: 'Bestätigen Sie, dass Sie diese Benutzerkategorie löschen wollen',
  userClassDetail_00020: 'Die Löschung dieser Benutzerkategorie war erfolgreich',
  userClassDetail_00021: 'Eingabe kann nicht leer sein',
  userClassDetail_00022: 'Mit diesem Vorgang wird die Excel-Datei des Benutzers heruntergeladen, möchten Sie fortfahren?',
  userClassDetail_00023: 'Aktion',
  userClassDetail_00024: 'alle',
  userClassDetail_00025: 'Durchschnitt',
  userClassDetail_00026: 'Die durchschnittliche Anzahl der kumulierten Bestellungen des Benutzers im Durchschnitt (einschließlich)',
}