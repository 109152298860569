export default {
    eventRelease_00001: "homepage banner",
    eventRelease_00002: "Home Banner aktiviert",
    eventRelease_00003: "Preview",
    eventRelease_00004: "Bannerbild",
    eventRelease_00005: "Sprung page",
    eventRelease_00006: "Anzeigereihenfolge",
    eventRelease_00007: "Standardbild auswählen",
    eventRelease_00008: "Verstärkung",
    eventRelease_00009: "modifizieren",
    eventRelease_000010: "click to upload pictures",
    eventRelease_000011: "Hilfe einstellen",
    eventRelease_000012: "1. Das hochgeladene Bild unterstützt nur Bilder im PNG- und JPG-Format, und die Größe beträgt nicht mehr als 5m. Die empfohlene Größe ist 690 bis 260px (8:3). Wenn die Größe oder Skalierung des hochgeladenen Bildes nicht konsistent ist, wird es für die Anzeige gestreckt",
    eventRelease_000013: "",
    eventRelease_000014: "",
    eventRelease_000015: "Aktivitätstyp auswählen",
    eventRelease_000016: "Bitte fügen Sie Ihren offiziellen Account Artikel Link, wechat öffentliche Plattform: https://mp.weixin.qq.com/ ",
    eventRelease_000017: "Es können bis zu 8 Banner gesetzt werden",
    eventRelease_000018: "Bitte setzen Sie Bannerbild",
    eventRelease_000019: "please set the activity ID",
    eventRelease_000020: "Upload",
    eventRelease_000021: "kein Sprung",
    eventRelease_000022: "aktive Seite",
    eventRelease_000023: "offizieller Kontolink",
    eventRelease_000024: "Rückgabe des gespeicherten Werts",
    eventRelease_000025: "kostenlose Sammlung",
    eventRelease_000026: "Freunde einladen",
    eventRelease_000027: "Kartencoupon kaufen",
    eventRelease_000028: "Please set the jump page",
    eventRelease_000029: "Name der Tätigkeit",
    eventRelease_000030: "Aktivität auswählen",
    eventRelease_000031: "Neue Tätigkeit",
    
}