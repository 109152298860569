export default {
    stationRangeManage_00001: "Ladestationsumfang",
    stationRangeManage_00002: "Nummer des Kontos",
    stationRangeManage_00003: "Reichweite der Ladestation",
    stationRangeManage_00004: "Name der Ladestation",
    stationRangeManage_00005: "Betrieb",
    stationRangeManage_00006: "Ladestation hinzufügen",
    stationRangeManage_00007: "Betrieb",
    stationRangeManage_00008: "Details der Website",
    stationRangeManage_000024: "Operation erfolgreich",
    stationRangeManage_000025: "Bitte geben Sie den Namen der Ladestation ein",
    stationRangeManage_000026: "Ladestation erfolgreich hinzugefügt",
    stationRangeManage_000027: "Ausgewählte Ladestation",
    stationRangeManage_000028: "erfolgreiche Löschen",
    stationRangeManage_000029: "Sind Sie sicher, dass Sie löschen möchten?",
    stationRangeManage_000030: "Löschen abgebrochen",
}