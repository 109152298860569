<template>
  <el-dialog
    :title="$t('请阅读《用户服务协议》和《隐私政策》')"
    width="1100px"
    :show-close="false"
    :visible="visible"
    :center="true">
    <div class="agreement">
      <el-tabs v-model="activeName">
        <el-tab-pane label="用户服务协议" name="first">
          <div class="val">
            <div class="title_t">智充运营商平台用户服务协议</div>
            <div class="line"></div>
            <div class="content">
              <div>智充运营商平台由北京智充科技有限公司（下称“智充科技”或“我方”）负责运营。本协议双方为智充科技与智充运营商平台注册使用用户（下称“用户”或“贵方”），本协议具有合同效力，适用于贵方在智充运营商平台的全部活动。</div>
              <div>
                感谢贵方选择并使用智充运营商平台产品/服务（下称“本平台”或“服务”），在贵方使用本平台/服务前，<span class="weight">请贵方务必先仔细阅读、理解《智充运营商平台用户服务协议》（以下简称“本协议”），特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，贵方应重点阅读。一经贵方选择并接受本协议即视为贵方同意与智充科技缔结协议。</span>
              </div>
              <div class="weight">贵方拟使用本平台/服务的全部或部分需要首先同意本协议，如果贵方不同意本协议，将影响我方为贵方提供完整的产品/服务。</div>
              <div class="weight">如贵方未满18周岁，请在法定监护人的陪同下阅读本协议。</div>
              <div class="part_title mt50">定义及解释</div>
              <div class="weight">（一）智充运营商平台注册账号</div>
              <div>
                指贵方在智充运营商平台通过注册获得的注册账号（下称“注册账号”），以供贵方使用智充运营商平台提供的服务或功能，“注册账号”是贵方在本平台拥有一个唯一的身份凭证，且是贵方使用智充运营商平台提供的服务或功能的唯一凭证，代表贵方在智充运营商平台的全部活动。
              </div>
              <div class="weight">（二）本平台</div>
              <div>
                指本协议项下智充运营商平台，网址为：http://service.xcharger.net/ ，若该网址发生了变更，智充科技将提前通知贵方，届时以新的网址为准。
              </div>
              <div class="weight">（三）服务内容</div>
              <div>
                指贵方在智充运营商平台注册选择使用的平台类型，分为高级版平台及基础版平台，具体功能详见本平台上公布的规则，贵方可自行选择使用高级版平台或基础版平台，我方将根据贵方选择使用的平台类型提供相应的服务，并收取平台使用费。
              </div>
              <div>说明：</div>
              <div class="weight">
                1、贵方在选择使用高级版平台或基础版平台时只能二选一，且无论是贵方在本次协议签约前或签约后，贵方名下的所有新能源电动汽车充电桩只能以一种身份使用本平台功能，即贵方名下新能源电动汽车充电桩全部使用高级版平台或全部使用基础版平台，本平台不支持贵方名下新能源电动汽车充电桩部分使用高级版平台和部分使用基础版平台。
              </div>
              <div class="weight">
                2、如贵方停用智充运营商平台提供的服务，届时贵方在智充运营商平台名下使用的各类产品或设备也将无法使用。
              </div>
              <div class="weight">（四）平台停用、注册账号停用、注册账号锁定、智充科技中止或终止向贵方提供智充运营商平台服务</div>
              <div>
                本协议项下如贵方的平台停用、注册账号停用、注册账号锁定、智充科技中止或终止向贵方提供智充运营商平台服务等，<span class="weight">代表贵方可以正常登陆本平台缴纳平台使用费，但贵方均无法使用智充运营商平台提供的任何服务，即贵方在智充运营商平台注册账号下使用的各类产品或设备也将无法使用，包含但不限于新能源电动汽车充电桩无法启动充电、充电中断等。</span>
              </div>
              <div class="weight">（五）用户</div>
              <div>本协议中的用户指在智充运营商平台注册成功的运营商，即：智充运营商平台的使用方。</div>
              <div class="weight">（六）平台技术锁定</div>
              <div>指贵方在智充运营商平台的注册账号或用户在本平台使用账号管理功能自行添加的子权限账号，无法登录本平台使用本平台的全部平台功能，但贵方在智充运营商平台注册账号下使用的新能源电动汽车充电桩不影响使用，可正常启动充电。</div>
              <div class="part_title mt50">一、协议生效及变更</div>
              <div>
                1、请贵方在注册使用智充运营商平台服务时，应仔细阅读本协议。贵方同意本协议内容并完成认证程序后，才能正式使用本平台。<span class="weight">1、贵方点击“我已阅读并同意《用户服务协议》”按钮后，本协议即生效，对双方产生约束力。如果贵方不同意本协议约定，贵方应立即停止注册或停止使用本平台。</span>
              </div>
              <div class="weight">2、本协议包括协议正文及本平台已经发布的或将来可能发布的各类规则。所有规则均为本协议不可分割的组成部分，与本协议具有同等法律效力。如贵方使用智充运营商平台服务，视为贵方同意上述各类规则。</div>
              <div class="weight">
                3、贵方知晓并同意智充科技有权根据国家法律法规变化及维护交易秩序、保护消费者权益及自身交易成本变化等需要不定期地制订、修改本协议及/或各类规则，并在智充运营商平台公示，如本协议及规则有任何变更，一切变更以智充运营商平台最新公布的内容为准。
                <span style="font-weight: normal;">如贵方不同意变更事项，贵方有权于变更事项确定的生效日前联系智充科技反馈意见。如反馈意见得以采纳，智充科技将酌情调整变更事项。</span>
                <span class="weight">经修订的协议、规则自确定的生效日起对新协议、规则生效之后注册的用户发生法律效力。对于新协议、规则生效之前注册的用户，若用户在新协议、规则生效后继续使用智充运营商平台提供的服务，则表明用户已充分阅读、理解并认可和同意遵守修订后的协议及政策约束。若用户拒绝接受修订后的协议及政策，用户应当于变更事项确定的生效之日起停止使用智充科技运营商平台提供的全部服务，变更事项对贵方不产生效力。</span>  
              </div>
              <div class="weight">4、贵方使用智充运营商平台即表示贵方同意智充科技将贵方的身份信息、实名认证数据、签署过程数据、平台使用过程中需要存储的数据、签署文档的数据等永久保存，且上述信息不因贵方的注册账号注销而停止保存。</div>
              <div class="weight">5、贵方承诺接受并遵守本协议的约定，同时，承诺遵守中国法律、法规、规章及其他政府规范性文件的规定，如有违反而造成任何法律后果，贵方需自行独立承担所有的法律责任。</div>
              <div>6、贵方选择使用智充运营商平台的，贵方向我方保证具备使用本运营商平台所需的全部证照、资质等，并符合其经营范围之规定，保证其使用运营商平台不会侵犯任何第三方的合法权益及不侵犯任何主体的知识产权及不违反中国法律、法规的规定。并保证贵方已取得使用运营商平台相关功能的合法有效授权，并保证真实准确，可以许可我方用于向贵方提供运营商平台相关的功能进行使用。</div>

              <div class="part_title mt50">二、服务费用及发票</div>
              <div>
                1、当贵方使用智充运营商平台服务时，智充科技将向贵方收取相应平台使用费。平台使用费收费标准请详见平台上公布的定价及有关规则。<span class="weight">智充科技拥有单方面制定与调整平台使用费的权利，贵方承诺按照智充科技公布的收费标准向智充科技支付平台使用费。收费标准一旦被修改，即公布在本平台上，修改后的收费标准自公告中确定的生效日期立即生效，适用于收费标准修改后发生的交易。</span>
              </div>
              <div class="weight">2、智充科技在避免用户损失的前提下，有权单方决定暂时或永久地改变或停止提供某些服务。</div>
              <div>
                3、贵方通过智充运营商平台线上缴纳平台使用费，为后付费月结，每月1号会生成上月需要缴费的平台使用费账单，智充科技会在本平台向贵方发送通知，
                <span class="weight">请贵方在每月10日前完成上月平台使用费的缴纳，否则会影响贵方使用本平台提供的服务，为避免平台停用给贵方带来经济损失，请贵方务必按时缴费。</span>  
              </div>
              <div>
                4、除非另有说明或约定，
                <span class="weight">贵方同意智充科技有权自贵方使用本平台服务期间从贵方储值在智充运营商平台《账户管理》中的余额或其他资产中直接扣除应向智充科技缴纳的平台使用费。</span>  
              </div>
              <div>
                5、对于贵方储值在智充运营商平台《账户管理》中的余额，在贵方停止使用智充运营商平台并向智充科技付清全部平台使用费后7个工作日内，可向智充科技申请退款。
                <span class="weight">因智充科技向贵方收取的平台使用费为后付费，即先使用后付费，故除贵方储值在智充运营商平台《账户管理》中的余额情形外，智充科技不接受其它任何情况下非智充科技原因的退费申请。智充科技不因贵方注册账号注销、平台停用等原因而负有退还贵方已支付平台使用费的义务。</span>  
              </div>
              <div class="weight">
                6、贵方认可在智充运营商平台其注册账号的平台使用费账单包括但不限于使用数量、使用记录、应缴费账单金额、交易状态等数据等均以本平台系统记录的数据为准。
                <span style="font-weight: normal;">如贵方对该等数据存有异议的，应自贵方注册账号生成上月需要缴费的平台使用费账单之日起三日内向智充科技提出异议，并提供相关证据供智充科技核实。</span>
                <span class="weight">贵方逾期未提出异议、提出异议时未提供相关证据或者智充科技根据有关异议及证据确认贵方异议不成立的，平台使用费账单仍以本平台系统记录的数据为准。</span>
              </div>
              <div class="weight">
                7、对于贵方已缴纳的平台使用费，智充科技将向贵方开具6%电子增值税发票，发票类型为：贵方为一般纳税人，则向贵方开具（电子增值税专用发票）；贵方为小规模纳税人，则向贵方开具（电子增值税普通发票）。
                <span>贵方保证并承诺遵守中国法律、法规、税务要求，向智充科技申请开具发票填写的信息全部真实有效，不存在弄虚作假，否则由于贵方提供虚假信息给智充科技造成损失的，贵方需向智充科技赔偿一切损失。</span>
              </div>

              <div class="part_title mt50">三、用户注册</div>
              <div class="weight">（1）.用户注册类型</div>
              <div>1、智充运营商平台注册的用户包括企业用户和个体用户，企业用户是指通过本平台完成全部注册程序后，使用智充科技提供的服务，并且依据中华人民共和国法律在境内（香港、台湾、澳门除外）设立的，能够独立承担民事责任的法人和其他组织。个体用户是指中华人民共和国公民，在法律允许的范围内，依法经核准登记，从事工商业经营的家庭或自然人。通过本平台完成全部注册程序后，使用智充科技提供的服务，并且符合中华人民共和国法律规定的年满18周岁的自然人。</div>
              <div>
                2、贵方确认，在贵方开始注册程序使用智充运营商平台服务前，贵方应当具备中华人民共和国法律规定的与贵方行为相适应的民事行为能力。
                <span class="weight">若贵方不具备前述与行为相适应的民事行为能力，则贵方及贵方的监护人应依照法律规定承担因此而导致的一切后果。</span>
              </div>
              <div class="weight">（2）.用户注册与实名认证</div>
              <div>
                1、个体及企业在本平台注册方可成为智充运营商平台的用户。个体及企业注册成为智充运营商平台用户后须进行实名认证。在注册与实名认证过程中个体及企业必须根据本平台的要求提供最新、真实、有效及完整的资料。个体及企业需要提供的实名认证资料包括但不限于营业执照、联系人、电话、邮箱等。
              </div>
              <div>
                2、实名认证具体操作流程请严格按照本平台实名认证规定的流程履行，否则将影响实名审核的结果。 
              </div>
              <div class="weight">
                3、贵方同意并授权智充科技审核、核对贵方资料信息以确认贵方的用户身份，但贵方信息是由贵方自行提供的，故智充科技无法保证该信息之准确、有效和完整。
                <span style="font-weight: normal;">所提交的贵方认证信息将用于注册智充运营商平台的运营商身份使用，智充科技将对贵方提供的用户信息进行严格保密。用户信息及隐私保护请详见《隐私政策》。</span>  
              </div>
              <div class="weight">
                <span style="font-weight: normal;">4、贵方在智充运营商平台注册用户使用本平台提供的服务，贵方在此确认并同意，</span>
                <span class="weight">无论在本平台办理注册的是贵方法人或其他任何人，贵方均授权并同意其注册行为具有代表贵方行使注册的权利，并对本注册行为负责。</span>
              </div>
              <div class="weight">
                5、用户实名认证信息变更的，应及时在智充运营商平台进行更新并重新进行实名认证，由于未及时更新造成的问题及风险，智充科技不承担任何责任。
              </div>
              <div class="weight">（3）.账号安全</div>
              <div>
                1、智充科技将通过用户的注册账号、用户在本平台使用账号管理功能自行添加的子权限账号、密码以及登录后的其他操作识别用户的相关指示，同时智充科技会通过本服务应用识别来自用户使用的各类产品或设备的指示，
                <span class="weight">用户应当妥善保管智充运营商平台所有注册账号、密码、身份信息，各类产品或设备，对于因用户泄露注册账号、密码、身份信息，因产品或设备遗失，或者因用户遭受他人攻击、诈骗等行为导致的损失，由用户自行承担，</span>
                智充科技并不承担责任。用户应在登录本平台时段结束时，以正确步骤离开网站。用户如果发现有他人冒用或盗用其智充运营商平台注册账号及密码或任何其他未经合法授权之情形，或发生与智充运营商平台注册账号关联的手机或其他设备遗失或其他可能危及到智充运营商平台注册账号安全情形时，应立即以有效方式通知智充科技，向智充科技申请平台技术锁定。
                <span class="weight">贵方理解智充科技对贵方的任何请求采取行动均需要合理时间，且智充科技应贵方请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除智充科技存在法定过错外，智充科技不承担责任。</span>
              </div>
              <div>
                2、贵方应对其持有的智充运营商平台的注册账号负责，
                <span class="weight">只有贵方方可使用该注册账号。该注册账号不可继承，在贵方决定不再使用该注册账号时，贵方应将该注册账号内的数据导出或删除，并按智充科技规定流程向智充科技申请注销该注册账号。</span>
              </div>
              <div class="weight">3、智充科技可以暂时停止提供或者限制本平台部分功能，或提供新的功能，在任何功能减少、增加或者变化时，只要用户仍然使用本平台，即表示同意本协议或者变更后的协议。</div>
              <div class="weight">4、智充科技对因用户未能遵守本协议的约定而发生的任何直接或间接损失等不利后果不承担任何责任。</div>
              <div class="weight">5、智充科技有权了解贵方使用本平台或服务的真实交易背景及目的，用户应如实提供智充科技所需的真实、全面、准确的信息。如果智充科技有合理理由怀疑用户提供虚假信息、进行欺诈等违法违规行为的，智充科技有权根据相关国家法律法规的规定对用户个人信息以及签署的信息等进行核实、查询、披露，有权锁定相应注册账号并配合相关机关进行后续调查。</div>
              <div class="weight">（4）.注册账号转让与设备迁移</div>
              <div>
                1、对于贵方在智充运营商平台注册账号，其名下使用的各类产品或设备，如出现各类产品或设备包括但不限于全部或部分转让或转卖给任何第三方继续使用本平台提供的服务的，即：
                <span class="weight">第三方重新在智充运营商平台上注册新的账号或已在智充运营商平台注册过账号，贵方应与第三方共同向智充科技出具加盖公章的迁移函。</span>
                智充科技收到贵方与第三方共同出具的加盖公章的迁移函后办理迁移函中产品或设备迁移到第三方注册的账号名下。
              </div>
              <div>
                2、对于贵方在智充运营商平台注册账号，其名下使用的各类产品或设备全部转让或转卖给任何第三方继续使用本平台提供的服务的，即：
                <span class="weight">第三方继续使用贵方在智充运营商平台上注册的原注册账号，非第三方重新注册新注册账号，贵方应与第三方共同向智充科技出具加盖公章的注册账号转让声明函。</span>
                智充科技收到贵方与第三方共同出具的加盖公章的注册账号转让声明函后办理注册账号转让手续，原注册账号项下的所有权利义务转让给第三方，并由第三方（最终使用人）向智充科技缴纳平台使用费，第三方需按本平台要求更新相关资料并确认同意本协议后方可继续使用原注册账号。
              </div>
              <div class="weight">（5）.注册账号锁定</div>
              <div>
                1、用户在需要暂时停止本协议约定的服务时，可申请锁定智充运营商平台注册账号。用户申请锁定的智充运营商平台注册账号应当是依照本协议的约定注册并由智充运营商平台提供给用户本人的注册账号，用户应当依照智充运营商平台的规定程序申请注册账号锁定。
              </div>
              <div class="weight">
                2、用户申请锁定的智充运营商平台注册账号应当处于正常状态，不存在任何可能由于该注册账号锁定而被影响的合同关系或其他权利义务关系，以及智充科技认为锁定注册账号会由此产生未了结的权利义务而产生纠纷的情况。
              </div>
              <div class="weight">（6）.注册账号注销</div>
              <div>
                1、在贵方需要终止使用我方提供的服务时，贵方可以申请注销在智充运营商平台的注册账号，贵方可以联系我方的人工客服（电话：010-53609116），咨询时间：工作日早10：00点至晚18：00点；另外贵方也可向贵方对接的智充业务同事联系申请注销贵方的注册账号。
              </div>
              <div>
                2、我方在此善意地提醒贵方：
                <span class="weight">贵方注销注册账号的行为会使贵方无法登陆智充运营商平台且无法继续使用智充运营商平台提供的全部服务，包括但不限于本平台项下的全部功能及贵方在本平台注册账号下使用的各类产品或设备的充电服务。注销注册账号后贵方的个人信息会保持不可被检索、访问的状态，但贵方在使用智充运营商平台服务期间提供或产生的信息我方仍需按照法律法规及监管的要求的期限保存，且在保存的时间内依法配合有权机关的查询。</span>
              </div>

              <div class="part_title mt50">四、通知与送达</div>
              <div class="weight">（1）.通知方式</div>
              <div class="weight">1、贵方在注册成为智充运营商平台用户，并接受智充运营商平台服务时，贵方应该向智充科技提供真实有效的联系方式（包括贵方的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，贵方有义务及时更新有关信息，并保持可被联系的状态。</div>
              <div class="weight">2、智充科技将向贵方的上述联系方式的其中之一或其中若干向贵方送达各类通知，而此类通知的内容可能对贵方的权利义务产生重大的有利或不利影响，请贵方务必及时关注。</div>
              <div class="weight">3、本协议条款及任何其他的协议、公告或其他关于贵方使用本平台注册账号及服务的通知，贵方同意本平台使用电子方式通知贵方，电子方式包括但不限于以电子邮件、短信、微信或于本平台上公布、或无线通讯装置通知等方式。</div>
              <div class="weight">（2）.送达</div>
              <div>
                1、本平台的通知如以公示方式做出，一经在本平台公示即视为已经送达。除此之外，其他向贵方发布的具有专属性的通知将由本平台在贵方注册时或者注册后变更用户信息时向本平台提供的电子邮箱或绑定的手机号码发送，一经发送即视为已经送达。
                <span class="weight">请贵方密切关注用户的电子邮箱以及手机中的短信信息。以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</span>
              </div>
              <div>2、贵方同意司法机关可采取以上一种或多种送达方式向贵方送达法律文书，司法机关采取多种方式向贵方送达法律文书，送达时间以上述送达方式中最先送达的为准。</div>
              <div>3、贵方同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</div>
              <div class="weight">4、贵方应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使有关文书、信息、通知等无法送达或未及时送达，由贵方自行承担由此可能产生的法律后果。</div>
              <div class="weight">（3）.通知内容</div>
              <div class="weight">贵方同意智充科技利用在本平台登记的联系方式与贵方联络并向贵方传递相关的信息，包括但不限于智充运营商平台政策调整方面的通知、产品信息、以及针对性的广告等。</div>
              
              <div class="part_title mt50">五、使用事项</div>
              <div>
                1、贵方在使用智充运营商平台服务时，必须遵守国家各项法律、法规、规章以及政府规范性文件，并接受智充运营商平台相关的协议、规则、规定、程序和惯例的约束。禁止在本平台进行任何可能违反国家法律、法规、规章和政府规范性文件的行为（包括但不限于侵犯任何第三方著作权、专利权、商标权、商业秘密、隐私权、名誉权或其它权利的行为）或者任何未经授权使用本平台的行为（包括但不限于擅自进入本平台未公开的系统、不正当的使用密码和平台的任何内容等）。
              </div>
              <div>
                2、注册成功后，每位用户都拥有一个属于自己的注册账号和登录密码，用户可自行修改注册账号登录密码，通过使用该注册账号和登录密码可以登录智充运营商平台，从而使用智充运营商平台项下的服务。贵方须妥善保管贵方在智充运营商平台上的注册账号、用户在本平台使用账号管理功能自行添加的子权限账号和密码。本平台通过贵方注册账号、用户在本平台使用账号管理功能自行添加的子权限账号、密码以及登录后的操作来识别贵方的指令。
                <span class="weight">贵方确认，使用注册账号、用户在本平台使用账号管理功能自行添加的子权限账号和密码登录本平台后在本平台的一切行为均代表贵方意愿。使用贵方注册账号、用户在本平台使用账号管理功能自行添加的子权限账号和密码登录操作所产生的电子信息记录均为贵方行为的有效凭据，并由贵方承担相应的法律后果。贵方不得在未经智充科技许可的情况下出售或授权任何第三方使用贵方注册账号、用户在本平台使用账号管理功能自行添加的子权限账号。</span>
              </div>
              <div>
                3、贵方对通过智充运营商平台提供的所有信息（内容包括但不限于个人信息、商业信息，形式包括但不限于文字、图片）的真实性、准确性、即时性、完整性和合法性独立承担所有责任。同时，贵方不得蓄意毁坏、恶意干扰、秘密地截取和侵占本平台项下任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序。
              </div>
              <div>
                4、本平台项下服务可能涉及由第三方提供、控制或运营（以下称“第三方服务”），包括但不限于地图导流、第三方直连流量导流、地方政府平台等。
                <span class="weight">智充科技不能保证也没有义务保证第三方提供服务的信息真实性和有效性。</span>
                贵方应按照贵方与“第三方服务”的相关协议与规则使用“第三方服务”，而不是按照本协议。
                <span class="weight">4、由于贵方名下使用的各类产品或设备或“第三方服务”存在风险、故障、缺陷从而影响智充科技的，智充科技有权单方自行停止在本平台项下提供的“第三方服务”。“第三方服务”的内容、产品、广告和其他任何信息均由贵方自行判断并承担风险和责任，而与智充科技无关。</span>
              </div>
              <div class="weight">5、如果用户在使用智充运营商平台相关服务时有涉及违法违规行为的，智充科技有权通知贵方并单方自行停用贵方名下的全部或部分注册账号，届时贵方将无法使用本平台提供的服务且贵方名下使用的各类产品或设备也将无法使用，智充科技对因用户违法违规行为而发生的任何直接或间接损失等不利后果不承担任何责任。</div>
              
              <div class="part_title mt50">六、纠纷处理</div>
              <div>
                1、贵方发现任何可能侵害自己或智充科技权利的事实时，应及时通知智充科技并提供相应的证明材料。
                <span class="weight">因投诉不实给智充科技或第三方造成损失的，贵方应承担法律责任。</span>
              </div>
              <div class="weight">2、智充科技不介入贵方与任何第三方在贵方使用智充运营商平台的过程中产生的任何纠纷，均由贵方与第三方自行解决，由此给智充科技造成损失的，贵方需向智充科技赔偿所产生的直接或间接的任何支出或损失。</div>
              <div class="weight">3、经生效法律文书确认贵方存在违法或违反本协议行为或者智充科技自行判断贵方涉嫌存在违法或违反本协议行为的，智充科技有权单方对贵方的违法行为做出相应处理，包括但不限于向贵方终止服务、对贵方停用本平台、永久禁止使用智充运营商平台等。在此情况下，智充科技不承担任何责任，并且贵方同意自行承担因此所产生的直接或间接的任何支出或损失。</div>

              <div class="part_title mt50">七、隐私条款</div>
              <div>请贵方详见智充运营商平台隐私政策。</div>

              <div class="part_title mt50">八、知识产权声明</div>
              <div>
                无论是否明示，智充科技对智充运营商平台内所有信息内容享有知识产权（包括但不限于商标权、专利权、著作权），信息内容包括但不限于文字、图片、软件、音频、视频、数据、源代码、设计。非经智充科技书面授权同意，任何组织或个人都不得复制、打印和传播属于智充运营商平台的信息内容用于其他用途。
                <span class="weight">本平台所有的产品、技术及程序均属于智充科技知识产权，不因本协议关系而导致智充科技对其产品、技术、程序、知识产权等的全部或部分转让，未经智充科技书面授权许可，任何人不得擅自使用（包括但不限于以非法的方式打印、复制、传播、展示、下载等）。否则，智充科技将依法追究其法律责任。</span>
              </div>
              
              <div class="part_title mt50">九、免责声明</div>
              <div>
                1、贵方有义务在注册时提供贵方的真实、最新、完整的资料，并保证电子邮件地址、联系电话、营业执照、开票信息等注册所需资料的内容最新、有效性及安全性。
                <span class="weight">贵方有义务维护并立即更新贵方的注册资料，确保其真实、最新、有效及完整。若贵方提供任何错误、虚假、过时或不完整的资料，或者智充科技有合理的理由怀疑贵方提供的为错误、虚假、过时或不完整的资料时，智充科技有权暂停或停用贵方的注册账号，并拒绝贵方使用本平台部分或全部服务。在此情况下，智充科技不承担任何责任，并且贵方同意自行承担因此所产生的直接或间接的任何支出或损失。</span>
              </div>
              <div class="weight">2、在如下情况，智充科技有权中止、终止对贵方提供部分或全部服务，包括贵方名下使用的各类产品或设备无法使用本平台服务，而不承担任何责任：</div>
              <div class="weight">（1）.在智充科技未向贵方收费的情况下，智充科技可自行全权决定以合理理由 (包括但不限于贵方已违反本协议的字面意义和精神，或贵方以不符合本协议的字面意义和精神的方式行事) 终止停用贵方的登录密码、注册账号(或其任何部份) 或贵方对智充运营商平台服务的使用。</div>
              <div class="weight">（2）.贵方未按本协议要求向智充科技缴纳平台使用费。</div>
              <div class="weight">3、在出现下列任一情况时，智充科技可立即发出警告，中止或终止贵方的注册账号，对贵方停止提供本平台服务，以及停止展示贵方在本平台上展示的任何其他资料：</div>
              <div class="weight">(a)贵方违反本协议；</div>
              <div class="weight">(b)贵方无法核实或鉴定贵方向智充科技提供的任何资料；</div>
              <div class="weight">(c)智充科技相信贵方的行为可能会使贵方、智充科技及其他用户或通过智充科技提供服务的第三者服务供应商发生任何法律责任；</div>
              <div class="weight">(d)发现在本平台项下向贵方提供的服务，贵方从事涉及诈骗等违法违规活动，智充科技有权中止或终止向贵方提供服务。</div>
              <div class="weight">对于(a)、(b)、(c)三项，智充科技不限制贵方采取相关补救措施。</div>
              <div class="weight">4、对于本协议规定的采取的中止或终止措施或对贵方的平台停用等造成的贵方无法使用本平台项下部分或全部服务，造成贵方无法使用任何智充运营商平台服务、贵方名下使用的各类产品或设备无法使用本平台服务、对贵方的平台停用、或使用智充运营商平台服务等受到任何影响时，智充科技对贵方及任何第三方不负任何责任。</div>
              <div class="weight">5、根据本协议的任何规定中止或终止贵方使用服务之措施，智充科技将本着审慎的态度实施，并将履行告知义务，于采取有关措施后【5】个工作日内通知贵方不能继续使用智充运营商平台的服务，但在贵方的注册账号内的所有相关资料，智充科技将继续为贵方保存。</div>
              <div class="weight">6、贵方理解，由于互联网本身所具有的不稳定性，智充科技无法保证智充运营商平台服务不会中断。系统因有关状况无法正常运作，使用户无法使用任何智充运营商平台服务或使用智充运营商平台服务受到任何影响时，包括贵方在智充运营商平台名下使用的各类产品或设备也将无法使用，智充科技对用户或第三方因此受到的不利后果不负任何责任，前述状况包括但不限于：</div>
              <div class="weight">(a)智充运营商平台系统停机维护期间；</div>
              <div class="weight">(b)电信、电脑、服务器设备出现故障不能进行数据传输的；</div>
              <div class="weight">(c)由于黑客攻击、网络供应商技术调整或故障、服务器宕机、网站升级的问题等原因而造成的智充运营商平台服务中断或延迟；</div>
              <div class="weight">(d)因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成智充运营商平台系统障碍不能执行任务的。</div>
              <div class="weight">7、因用户的过错导致的任何损失，该过错包括但不限于：操作不当、遗忘或泄露密码、密码被他人破解、用户使用的计算机系统被第三方侵入、用户委托他人代己使用时他人恶意或不当操作而造成的损失，智充科技不承担任何责任。</div>
              <div class="weight">8、贵方在使用智充运营商平台期间，由贵方自行掌握贵方的客户数据，其中包括贵方自行编码的其个人客户的数据和业务数据(例如充电电量、功率、电压、电流、起止时间、充电站位置、时长、充电结束原因、充电费用等新能源电动汽车充电桩运作数据)。由于该等数据由贵方实质控制，智充科技无权基于其他目的进行访问、使用、加工或提供至任何其他第三方，因而该等数据不属于智充科技“掌握”的数据范围。因此，智充科技向贵方申明，智充科技不对贵方使用的本平台项下服务提供任何形式的鉴定或证明服务。除本协议另有规定外，如贵方与交易对方发生交易纠纷时，可自行调取本平台上存管数据，必要时可向智充科技申请相应的协助。贵方为解决纠纷而支出的一切费用均由贵方自行承担。</div>
              
              <div class="part_title mt50">十、违约责任</div>
              <div class="weight">1、因贵方的行为使智充科技及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），贵方应赔偿智充科技及/或其关联公司的上述全部损失。</div>
              <div class="weight">2、因贵方违反本协议或经在此提及而纳入本协议的其他文件，或因贵方违反了法律、法规、规章、政府规范性文件或侵害了第三方的权利，而使第三方对智充科技及或其关联公司之股东、职员、代理人提出索赔要求（包括司法费用和其他专业人士的费用），贵方必须对智充科技及/或其关联公司及股东、职员、代理人承担赔偿责任，使其等免遭损失。</div>
              <div class="weight">3、贵方同意智充科技从贵方储值在智充运营商平台《账户管理》中的余额或其他资产中划扣相应款项支付上述赔偿款项。如贵方储值在智充运营商平台《账户管理》中的余额或其他资产有关款项不足以支付上述赔偿款项的，智充科技及/或关联公司可直接抵减贵方在智充科技及/或其关联公司其它协议项下的权益，并可继续追偿。</div>
              
              
              <div class="part_title mt50">十一、法律适用与管辖</div>
              <div class="weight">1、本协议的订立、变更、执行和解释，以及与本协议有关的争议解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项没有法律规定或规定不明确，则应参照通用的国际商业惯例和行业惯例。</div>
              <div class="weight">2、如因本协议或智充运营商平台服务所引起或与其有关的任何争议应向智充科技所在地人民法院起诉。</div>
              
              
              <div class="part_title mt50">十二、其他</div>
              <div class="weight">1、本协议自贵方同意勾选并成功注册为本平台用户之日或贵方同意勾选并成功登陆本平台时即刻生效，除非智充科技终止本协议或者贵方丧失本平台用户资格，否则本协议始终有效。本协议终止并不免除贵方根据本协议或其他有关协议、规则所应承担的义务和责任。</div>
              <div>2、当本协议项下提供的服务与贵方与本协议签约前在本平台项下使用的服务存在不一致之处的，以本协议约定为准。</div>
              <div>3、本协议部分条款被认定为撤销、无效、终止或不可执行时，不影响本协议其他条款的效力。</div>
              <div>4、本协议中的标题仅为方便而设，在解释本协议时应被忽略。</div>
              <div class="weight">5、智充科技对本协议及相关政策享有最终的解释权。</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="隐私政策" name="second">
          <div class="val">
            <div class="title_t">智充运营商平台隐私政策</div>
            <div class="line"></div>
            <div class="content">
              <div class="part_title">引言</div>
              <div>您的信任对智充科技及或其关联公司（以下简称“我们”）非常重要，我们深知用户信息安全的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的用户信息安全可控。鉴此，智充科技制定《智充隐私政策》（下称“本隐私政策”）并提醒您：
                本隐私政策适用于智充科技及或其关联公司的产品及或服务。如智充科技关联公司（范围详见定义部分）的产品及或服务中使用了智充科技提供的产品及或服务（例如使用智充科技账号登录）但未设置独立的隐私政策的，或者智充科技关联公司的产品及或服务未设置单独的隐私政策的，则本隐私政策同样适用于该部分产品及或服务。
              </div>
              <div>需要特别说明的是，本隐私政策不适用于其他第三方向您提供的产品及服务，第三方向您提供的产品及服务适用其向您说明的隐私政策。</div>
              <div class="weight">>在使用智充科技及或其关联公司的各项产品及或服务前，请您务必仔细阅读并透彻理解本隐私政策，特别是以粗体粗体下划线标识的条款，您应重点阅读，请在确认充分理解并同意后使用智充科技及或其关联公司的产品及或服务。一旦您开始使用智充科技及或其关联公司的各项产品及或服务，即表示您已充分理解并同意本隐私政策。</div>
              <div>如对本隐私政策内容有任何疑问、意见或建议，您可通过本隐私政策“九、如何联系我们”中披露的联系方式与我们联系。</div>
              <div class="part_title mt50">第一部分 定义</div>
              <div>
                <span class="weight">用户：</span>
                指成功注册激活智充SAAS账号的自然人、法人或其他组织，和签署了本协议并使用智充科技关联公司账号登录智充SAAS平台的自然人、法人或其他组织。
              </div>
              <div>
                <span class="weight">智充科技：</span>
                北京智充科技有限公司。
              </div>
              <div>
                <span class="weight">关联公司：</span>
                指北京智充科技有限公司在本隐私政策中披露的关联公司，具体请点击。
              </div>
              <div>
                <span class="weight">智充SAAS平台：</span>
                指智充科技及或其关联公司运营的，为您提供各项产品及或服务的网站、客户端、移动应用程序、微信公众号、微信小程序等渠道（包括未来技术发展出现的新的形态）。
              </div>
              <div>
                <span class="weight">产品及或服务：</span>
                指智充科技及或其关联公司通过智充SAAS平台为您提供的各项产品及或服务。
              </div>
              <div>
                <span class="weight">平台规则：</span>
                指在智充SAAS平台上已经发布的及未来根据需要不定期更新的或新发布的所有服务规则、公告及各项产品及或服务的流程说明、使用方法规则介绍等。
              </div>
              <div>
                <span class="weight">个人信息：</span>
                指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我们将在本隐私权政策中对所涉及的个人信息以粗体方式进行显著标识。
              </div>
              <div>
                <span class="weight">个人敏感信息：</span>
                指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息（我们将在本隐私政策中对所涉及的个人敏感信息以粗体和加*号方式进行显著标识）。
              </div>
              <div>
                <span class="weight">企业信息：</span>
                指企业从事生产经营活动过程中形成的信息，以及智充科技及或其关联公司在为企业或个体提供产品及或服务过程中产生的能够反映企业状况的信息。
              </div>
              <div>除另有约定外，本隐私政策所用定义与《智充科技用户使用协议》中的定义具有相同的涵义。</div>
              <div class="part_title mt50">第二部分 隐私政策</div>
              <div class="part_title mt50" id="mao_0">一、我们如何收集和使用用户信息</div>
              <div>在您使用我们的产品及或服务时，我们需要可能需要收集和使用的您的用户信息包括如下两种：</div>
              <div class="weight">1、为实现向您提供我们产品及或服务的基本功能，您须授权我们收集、使用的必要信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及或服务；<br />
                2、为实现向您提供我们产品及或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供相应信息，您将无法正常使用相应附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及或服务的基本功能。
              </div>
              <div>您理解并同意：</div>
              <div class="weight">1、我们致力于打造多样的产品及或服务以满足您的需求。因我们向您提供的产品及或服务种类众多，且不同用户选择使用的具体产品及或服务内容存在差异，相应的，基本附加功能及收集、使用的用户信息类型、范围等会有所区别，请以具体的产品及或服务功能为准；<br />
                2、为给您带来更好的产品及或服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的用户信息或变更用户信息使用目的或方式。对此，我们将通过更新本隐私政策、弹窗、页面提示、邮件通知等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过本隐私政策“九、如何联系我们”中披露的联系方式与我们联系，我们会尽快为您作出解答。
              </div>
              <div>我们会出于本隐私政策所述的以下目的，收集和使用您的用户信息：</div>
              <div class="sub_title">1、基础服务</div>
              <div class="weight pl2em">1.1、注册激活智充科技账号、登录智充SAAS平台并完成产品及或服务的认证</div>
              <div class="pl4em">
                您注册激活智充科技账号、登录智充SAAS平台并完成产品及或服务的认证过程中，您可能需要向我们提供相关用户信息，其中<span class="weight"> 个人信息包括个人真实姓名*、身份证件号码*、个人生物识别信息*、电话号码*、车牌号*、车架号*、行驶证*、微信ID*、支付宝ID*、电子邮箱地址*；企业信息包括企业名称、企业营业执照信息、企业联系人信息、企业联系方式（电话号码、电子邮箱）、企业银行账户，这些基本用户信息用于创建智充科技账号。</span> 您在注册激活账号、登录智充SAAS平台中可以提供更多额外信息补全用户资料，例如您的个人车型、VIN号码、教育背景、工作职业等以及企业所属行业、主营业务、主要产品、场站地理位置、收费标准等，这些信息将有助于我们给您提供更个性化的服务，但不予提供并不会影响到您使用我们的基础服务。<br />

                在注册激活智充科技账号、登录智充SAAS平台并完成产品及或服务的认证过程中，我们还可能需要您主动上传主体资格证明文件照片（包括个人身份证件、企业注册文件），我们可能会根据您提供的上述信息校验您的用户身份，确保我们是在为您提供服务。
              </div>
              <div class="weight pl2em">1.2、我们在您使用产品及或服务过程中收集的信息</div>
              <div class="pl4em">
                为向您提供更契合您需求的页面展示和搜索结果、了解产品及或服务适配性、识别账号异常状态，我们会收集关于您使用的产品及或服务以及使用方式的信息并将这些信息进行关联，这些信息包括：<br />

                设备信息：我们会根据您在智充SAAS平台上和产品及或服务使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符、应用列表信息等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。<br />

                日志信息：当您登录智充SAAS平台、使用我们的产品及或服务时，我们会自动收集您对我们产品及或服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。<br />

                终端用户信息：当您通过智充SAAS平台对充电用户提供服务室，我们会自动收集您用户使用我们服务时的详细信息，并作为有关网络日志保存。列如订单号、车型、电池相关充电数据、充电桩相关运营数据等。<br />

                <span class="weight">请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息。当您与我们联系时，我们可能会保存您的通信通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span>
              </div>
              <div class="sub_title">2、附加服务</div>
              <div class="pl2em">为了向您提供更优质的产品及或服务，我们可能需要收集下述信息。如果您拒绝提供下述信息，不影响您正常使用本隐私政策第一部分“（一）”项所描述的基础服务，但我们无法向您提供某些特定功能和服务。</div>
              <div class="weight pl2em">2.1、当您使用定位服务时</div>
              <div class="pl4em">当您开启设备定位功能并使用我们提供的相关服务时，我们会收集有关您的位置信息（包括IP地址、GPS位置信息以及能够提供相关信息的WI-FI接入点、蓝牙、基站等传感器信息、您提供的账号信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息），您可以通过关闭定位功能，停止对您的地理位置信息的收集</div>
              <div class="weight pl2em">2.2、为您展示和推送产品及或服务时</div>
              <div class="pl4em">
                <span class="weight"> 为改善我们的产品及或服务、向您提供个性化的信息搜索及产品及或服务推荐服务，我们会根据您的浏览及搜索记录、位置信息、登录使用信息，提取您的浏览、搜索偏好、行为习惯等特征，基于特征标签进行间接人群企业画像并展示、推送信息。</span>
                <br />
                如果您不想接受我们给您发送的商业广告，您可通过短信或邮件提示回复退订或我们提供的其他方式进行退订。
              </div>
              <div class="weight pl2em">2.3、当您使用涉及支付的功能时</div>
              <div class="pl4em">
                我们的一些产品及或服务需要付费才能使用，当您需要使用这些付费产品及或服务时，您需要提供您的银行卡信息及或通过合作的第三方支付机构（微信支付、支付宝支付等）所提供的支付服务完成。费用支付通过合作的第三方支付机构（微信支付、支付宝支付等）所提供的支付服务完成，第三方支付服务有可能使用生物识别信息用于付款验证，您在使用第三方支付服务的时候请阅读该第三方支付机构的隐私政策，如对于该约定不同意，请您及时停止使用该第三方支付服务。
                <br />
                您可以为其他人订购产品及或服务，<span class="weight">您需要提供该实际订购人的前述用户信息。向我们提供该实际订购人的前述用户信息之前，您需确保您已经取得其授权同意。为确认交易状态及为您提供交易争议解决服务，我们会通过您基于交易所选择的交易对象、交易方式、第三方支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。</span>
              </div>
              <div class="weight pl2em">2.4、客服及争议处理</div>
              <div class="pl4em">
                当您与我们联系或提出问题处理、争议纠纷处理申请时，为了保障您的账号及系统安全，我们需要您提供必要的用户信息以核验您的用户身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
                <br />
                您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置信息 （地理位置）、存储、摄像头（相机）、相册（图片库）、麦克风（语音）、通讯录及或日历的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置或智充SAAS平台上逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。<span class="weight"> 请您注意，您开启任一权限即代表您授权我们可以收集和使用相关用户信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关用户信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span>
              </div>
              <div class="sub_title">3、为您提供安全保障</div>
              <div class="pl2em">
                为提高您使用我们及我们合作伙伴产品及或服务的安全性，保护您或其他用户或公众的人身、财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本隐私政策、平台规则的情况，<span class="weight">我们可能使用或整合您的用户信息、订购信息、车辆信息、登录使用信息、设备信息、有关网络日志以及我们合作伙伴取得您授权或依据法律共享的信息，来综合判断您的账号安全及经营风险、进行实名认证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</span>
              </div>
              <div class="sub_title">4、其他用途</div>
              <div class="weight pl2em">1、若你提供的信息中含有其他用户的用户信息，在向我们提供这些用户信息之前，您需确保您已经取得合法的授权。</div>
              <div class="weight pl2em">2、若我们将信息用于本隐私政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的用户信息，均会事先获得您的同意。</div>
              <div class="pl2em">
                若我们从第三方处间接获取您的信息的，<span class="weight">我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集您的信息，并向您告知共享的信息内容，且涉及个人敏感信息、企业重要信息的在提供给我们使用前需经过您的明确确认，要求第三方对用户信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求该第三方承担相应法律责任；同时，我们的专业安全团队对用户信息会进行安全加固（包括个人敏感信息、企业重要信息报备、加密存储、访问权限控制等）。我们会使用不低于我们对自身用户信息同等的保护手段与措施对间接获取的用户信息进行保护。</span>
              </div>
              <div class="sub_title">5、征得授权同意的例外</div>
              <div class="pl2em">
                根据相关法律法规规定，以下情形中收集用户信息无需征得您的授权同意：<br />
                1、与国家安全、国防安全有关的；<br />
                2、与公共安全、公共卫生、重大公共利益有关的；<br />
                3、与犯罪侦查、起诉、审判和判决执行等有关的；<br />
                4、出于维护用户或其他个人的生命、财产企业的财产等重大合法权益但又很难得到您亲自同意的；<br />
                5、所收集的用户信息是您自行向社会公众公开的；<br />
                6、从合法公开披露的信息中收集用户信息的，如合法的新闻报道、政府信息公开等渠道；<br />
                7、根据您的要求签订合同所必需的；<br />
                8、用于维护所提供的产品及或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；<br />
                9、为合法的新闻报道所必需的；<br />
                10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的用户信息进行去标识化处理的；<br />
                11、法律法规规定的其他情形。
              </div>
              <div class="weight pl2em">
                请知悉，您同意我们根据适用的法律，若我们对用户信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定用户且不能复原，或我们可能会对收集的信息（包括IP地址、操作系统版本信息、登录记录、指令信息、环境开通及关闭时间、操作行为信息）进行匿名化地研究、统计分析和预测，用于改善智充SAAS平台的内容和布局，为商业决策提供产品及或服务支撑，以及改进我们的产品及或服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。
              </div>
              <div class="pl2em">
                如我们停止智充SAAS平台的运营、产品及或服务的提供，我们将及时停止继续收集用户信息的活动，将停止运营、提供的通知以逐一送达或公告的形式通知您，对所持有的用户信息进行删除或匿名化处理。
              </div>
              <div class="part_title mt50" id="mao_1">二、我们如何使用Cookie和同类技术</div>
              <div class="sub_title">1、Cookie</div>
              <div class="pl2em">
                为确保智充SAAS平台相关的网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储相关信息：这些信息可能是Cookie、Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
                <br /><br />
                请您理解，我们的某些产品及或服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝我们的Cookie，但拒绝我们的Cookie在某些情况下可能会影响您安全访问网站和使用我们提供的产品及或服务。
                <br /><br />
                <span class="weight">如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。但如果您这么做，在某些情况下可能会影响您安全访问智充SAAS平台相关的网站，且可能需要在每一次访问智充SAAS平台相关的网站时更改用户设置。</span>
              </div>
              <div class="sub_title">2、网站信标和像素标签</div>
              <div class="pl2em">除Cookie外，我们还会在智充SAAS平台相关的网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至智充SAAS平台相关的网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品及或服务偏好以便于我们主动改善用户体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。</div>
              <div class="sub_title">3、Do Not Track（请勿追踪）</div>
              <div class="pl2em">很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么智充SAAS平台相关的所有网站都会尊重您的选择。您还可以使用您的浏览器设置来删除或阻止您计算机上的Cookie。</div>
              <div class="part_title mt50" id="mao_2">三、我们如何委托处理、共享、转让、公开披露用户信息</div>
              <div class="sub_title">1、委托处理</div>
              <div class="pl2em">
                智充SAAS平台、我们产品及或服务中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供技术支持。
                <br /><br />
                对我们委托处理用户信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。
              </div>
              <div class="sub_title">2、共享</div>
              <div class="pl2em">我们不会与我们服务提供商以外的公司、组织和个人共享用户信息，但以下情况除外：</div>
              <div class="pl4em">
                2.1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的信息。
                <br /><br />
                2.2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的信息。
                <br /><br />
                2.3、关联公司之间共享：因您可以使用智充科技账号登录智充SAAS平台、使用我们的产品及或服务，为便于我们基于统一的账号共同向您提供产品及或服务，<span class="weight">推荐您可能感兴趣的信息或保护我们或其他用户、公众的人身财产安全免遭侵害，您的信息可能会在我们之前共享。我们只会共享必要的用户信息（如为便于您使用智充科技账号使用智充科技关联公司的产品及或服务，智充科技会向关联公司共享您必要的账号信息），如果我们共享您的个人敏感信息、企业重要信息或关联公司改变用户信息的使用及处理目的，将再次征求您的授权同意。</span>
                <br /><br />
                2.4、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些产品及或服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，并且只会共享提供产品及或服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于与产品及或服务无关的其他用途。
              </div>
              <div class="pl2em">
                对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的数据保护协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。
                <br /><br />
                我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。
              </div>
              <div class="sub_title">3、转让</div>
              <div class="pl2em">我们不会将您的信息转让给任何公司、组织和个人，但以下情况除外：</div>
              <div class="pl4em">
                3.1、在您点击同意本隐私政策并使用智充科技关联公司账号登录智充SAAS平台时，您同意智充科技关联公司将您的信息转让给智充科技，以便我们对用户的账号和其他信息进行统一管理、加强用户信息安全保护并为用户提供更加优质、高效、全面的产品及或服务。
                <br /><br />
                3.2、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的信息。
                <br /><br />
                3.3、在我们产品及或服务的提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您信息的公司、组织和个人继续受本隐私政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
              </div>
              <div class="sub_title">4、公开披露</div>
              <div class="pl2em">我们仅会在以下情况下，公开披露用户信息：</div>
              <div class="pl4em">
                4.1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的信息；
                <br /><br />
                <span class="weight">4.2、如果我们确定您出现违反法律法规或严重违反本隐私政策、平台规则的情况，或为保护我们及其他用户、公众的人身财产安全免遭侵害，我们在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，可能会公开披露您的信息。</span>
              </div>
              <div class="sub_title">5、委托处理、共享、转让、公开披露用户信息时事先征得授权同意的例外</div>
              <div class="pl2em">以下情形中，委托处理、共享、转让、公开披露用户信息无需事先征得用户的授权同意：</div>
              <div class="pl4em">
                5.1、与国家安全、国防安全有关的；
                <br /><br />
                5.2、与公共安全、公共卫生、重大公共利益有关的；
                <br /><br />
                5.3、与犯罪侦查、起诉、审判和判决执行等有关的；
                <br /><br />
                5.4、出于维护用户或其他个人的生命、财产企业财产等重大合法权益但又很难得到用户亲自同意的；
                <br /><br />
                5.5、用户自行向社会公众公开的信息；
                <br /><br />
                5.6、从合法公开披露的信息中收集用户信息的，如合法的新闻报道、政府信息公开等渠道。
              </div>
              <div class="pl2em">根据法律规定，共享、转让经去标识化处理的用户信息，且确保数据接收方无法复原并重新识别该用户的，不属于用户信息的对外委托处理、共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</div>
              <div class="part_title mt50" id="mao_3">四、我们如何保护用户信息安全</div>
              <div>
                1、我们已采取符合业界标准、合理可行的安全防护措施保护用户信息安全，防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL（Secure Socket Layer）协议加密保护；我们同时对我们提供HTTPS（Hyper Text Transfer Protocol over Secure Socket Layer）协议安全浏览方式；我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。
              </div>
              <div>
                2、我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升智充SAAS平台及产品及或服务的安全性。
              </div>
              <div>
                3、我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本隐私政策所述目的所需的期限内保留用户信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：
              </div>
              <div class="pl4em">
                3.1、完成与您相关的产品及服务使用目的、维护相应业务记录、应对您可能的查询或投诉；
                <br /><br />
                3.2、保证我们为您提供产品及或服务的安全和质量；
                <br /><br />
                3.3、您是否同意更长的留存期间；
                <br /><br />
                3.4、是否存在保留期限的其他特别约定。
                <br /><br />
                <span class="weight">在用户信息超出保留期间后，我们会根据适用法律的要求删除用户信息，或使其匿名化处理。</span>
              </div>
              <div>
                4、互联网并非绝对安全的环境，我们强烈建议您不要使用非我们推荐的通信方式发送用户信息。您可以通过智充SAAS平台建立联系和相互分享。当您通过智充SAAS平台创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您使用的产品及或服务内容、联络方式、交流信息或分享内容等相关信息的第三方。
                <br /><br />
                请注意，您在智充SAAS平台上自愿共享甚至公开分享的信息，可能会涉及您或第三方的信息甚至个人敏感信息、企业重要信息。请您更加谨慎地考虑，是否在智充SAAS平台上共享甚至公开分享相关信息。
                <br /><br />
                请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
              </div>
              <div>5、我们将不定期更新并公开安全风险、用户信息安全影响评估报告等有关内容，您可通过我们公告方式获得。</div>
              <div>
                6、在不幸发生用户信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。
                <br /><br />
                同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
              </div>
              <div class="part_title mt50" id="mao_4">五、您如何管理用户信息</div>
              <div>您可以通过以下方式访问及管理您的信息：</div>
              <div class="sub_title">1、访问您的信息</div>
              <div class="pl2em">
                您有权访问您的信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的信息：
                <br /><br />
                账号信息——如果您希望访问或编辑您的账号中的基本用户信息、更改您的账号密码、添加安全信息或关闭您的账号等，您可以通过登录智充SAAS个人用户中心进行账号更改
                <br /><br />
                如果您无法通过上述路径访问该等用户信息，您可以随时通过本隐私政策中披露的联系方式与我们取得联系。我们将在15天内回复您的访问请求。
                <br /><br />
                对于您在使用我们的产品及或服务过程中产生的其他信息，我们将根据本条“（八）响应您的上述请求”中的相关安排向您提供。
              </div>
              <div class="sub_title">2、更正或补充您的信息</div>
              <div>当您发现我们处理的关于您的信息有错误时，您有权要求我们做出更正或补充。您可以通过“（一）访问您的信息”中列明的方式提出更正或补充申请。</div>
              <div class="sub_title">3、删除您的信息</div>
              <div class="pl2em">
                您可以通过“（一）访问您的信息”中列明的方式删除您的部分信息。
                <br /><br />
                在以下情形中，您可以向我们提出删除信息的请求：
              </div>
              <div class="pl4em">
                1、如果我们处理信息的行为违反法律法规；
                <br /><br />
                2、如果我们收集、使用您的信息，却未征得您的明确同意；
                <br /><br />
                3、如果我们处理信息的行为严重违反了与您的约定；
                <br /><br />
                4、如果您不再使用我们的产品及或服务，或您主动注销了账号；
                <br />
                5、如果我们永久不再为您提供产品及或服务。
              </div>
              <div class="pl2em">
                若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的信息的第三方，要求其及时删除，除非法律法规另有规定，或这些第三方获得您的独立授权。
                <br /><br />
                当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。
              </div>
              <div class="sub_title">4、改变您授权同意的范围</div>
              <div class="pl2em">
                我们产品及或服务的每个业务功能可能需要一些基本用户信息才能得以完成（见本隐私政策“第二部分”）。除此之外，对于额外用户信息的收集和使用，您可以通过登录账号、通过与我们联系给予或收回您的授权同意。
                <br /><br />
                <span class="weight">当您收回同意后，我们将不再处理相应的用户信息。但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。</span>
              </div>
              <div class="sub_title">5、用户注销账号</div>
              <div class="pl2em">
                您可以与我们联系提交账号注销申请。
                <br /><br />
                在您主动注销账号之后，我们将停止为您提供产品及或服务，根据适用法律的要求删除您的信息，或使其匿名化处理。
              </div>
              <div class="sub_title">6、获取用户信息副本</div>
              <div class="pl2em">您有权获取您的信息副本，您可以通过以下方式自行操作：我们在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的信息副本传输给您指定的第三方。</div>
              <div class="sub_title">7、约束信息系统自动决策</div>
              <div class="pl2em">在我们产品及或服务的某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害我们商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</div>
              <div class="sub_title">8、响应您的上述请求</div>
              <div class="pl2em">
                为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
                <br /><br />
                我们将在15天内做出答复。如您不满意，还可以通过联系我们发起投诉。
                <br /><br />
                对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
                <br /><br />
                在以下情形中，按照法律法规要求，我们将无法响应您的请求：
              </div>
              <div class="pl4em">
                1、与国家安全、国防安全有关的；
                <br /><br />
                2、与公共安全、公共卫生、重大公共利益有关的；
                <br /><br />
                3、与犯罪侦查、起诉、审判和执行判决等有关的；
                <br /><br />
                4、有充分证据表明用户存在主观恶意或滥用权利的；
                <br /><br />
                5、响应用户的请求将导致用户或其他个人、组织、公司的合法权益受到严重损害的；
                <br /><br />
                6、涉及商业秘密的。
              </div>
              <div class="part_title mt50" id="mao_5">六、我们如何处理儿童的个人信息</div>
              <div>
                如果没有父母或监护人的同意，儿童不得创建自己的智充科技账号。如您为儿童的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的产品及或服务或向我们提供信息。
                <br /><br />
                对于经父母或监护人同意使用我们的产品及或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。
                <br /><br />
                我们将不满 14 周岁的任何人均视为儿童，如果当地法律和习俗对儿童的定义不同，则适用当地法律和习俗的定义。
              </div>
              <div class="part_title mt50" id="mao_6">七、用户信息如何在全球范围转移</div>
              <div>
                原则上，我们在中华人民共和国境内收集和产生的用户信息，将存储在中华人民共和国境内。
                <br /><br />
                由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的信息可能会被转移到您使用产品及或服务所在国家地区的境外管辖区，或者受到来自这些管辖区的访问。
                <br /><br />
                此类管辖区可能有不同的数据保护法，甚至未制定相关法律。在此类情况下，我们会确保您的信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移用户信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
              </div>
              <div class="part_title mt50" id="mao_7">八、本隐私政策如何更新</div>
              <div>
                我们的隐私政策可能变更。
                <br /><br />
                未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在智充SAAS平台上发布对隐私政策所做的任何变更。
                <br /><br />
                对于重大变更，我们还会提供更为显著的通知（包括我们会通过我们公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）。
                <br /><br />
                本隐私政策所指的重大变更包括但不限于：
              </div>
              <div class="pl2em">
                1、我们的产品及或服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；
                <br /><br />
                2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；
                <br /><br />
                3、用户信息共享、转让或公开披露的主要对象发生变化；
                <br /><br />
                4、您参与用户信息处理方面的权利及其行使方式发生重大变化；
                <br /><br />
                5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化；
                <br /><br />
                6、用户信息安全影响评估报告表明存在高风险。
              </div>
              <div>我们还会将本隐私权政策的旧版本存档，供您查阅。</div>
              <div class="part_title mt50" id="mao_8">九、如何联系我们</div>
              <div>
                我们设有专门法务合规部门监督用户信息保护事宜，有关本隐私政策或我们的隐私措施相关问题可通过如下方式联系我们，我们将在15天内回复您的请求：
                <br /><br />
                用户信息保护负责人邮箱：b@xcharge.com；
                <br /><br />
                联系电话：010-53609116
                <br /><br />
                联系地址：北京市大兴区双羊路12号
                <br /><br />
                如果您对我们的回复不满意，特别是您认为我们的用户信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close(false)">不同意</el-button>
      <el-button type="primary" @click="close(true)" :disabled="count>0">我已阅读并同意《用户服务协议》和《隐私政策》
        <span v-if="count>0">（{{ count }}）</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      count: 10,
      timer: '',
    };
  },
  props: {
    visible: Boolean,
  },
  methods: {
    // 关闭弹窗
    close(val) {
      this.$emit("cancel", false);
      this.$emit('handleCheck',val)
    },
    // 倒计时
    countdown() { 
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--;
        } else {
          clearInterval(this.timer);

        }
      }, 1000);
    }
  },
  mounted() {
    this.countdown();
  }
};
</script>

<style lang="scss">
  .title_t {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }
  .val {
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(30, 30, 30, 0.05);
    border-radius: 10px;
    height: calc(100vh - 200px);
    padding: 50px 30px;
    box-sizing: border-box;
    overflow-y: scroll;
    .line {
      height: 1px;
      background-color: #E6E7EC;
      margin: 40px 0;
    }

    .content {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;

      >div {
        margin-bottom: 16px;
      }

      .mt50 {
        margin-top: 40px;
      }

      .pl2em {
        padding-left: 2em;
      }

      .pl4em {
        padding-left: 4em;
      }

      .part_title {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        margin-bottom: 20px;
      }

      .sub_title {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }

      .weight {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
    }
  }
</style>
