export default {
    settingDepot_00001: 'Betrieb der Charge',
    settingDepot_00004: 'Ausgewählte Standorte',
    settingDepot_00005: 'öffentliche Mittel',
    settingDepot_00006: 'Tarif der Gruppe',
    settingDepot_00007: 'Informationen',
    settingDepot_00008: 'Einstellungen der Operation',
    settingDepot_00009: 'Bitte schieben Sie den festgelegten Tarifstandard auf den Ladestapel, und der Ladestapel kann die Gebühren für den Benutzer mit dem neuen Tarifstandard begleichen',
    settingDepot_00010: 'Änderung übernehmen',
    settingDepot_00011: 'AC Stromtarif',
    settingDepot_00012: 'Aktuelle Nutzungsgebühren:',
    settingDepot_00013: 'Bitte Stromtarif auswählen',
    settingDepot_00014: 'Neuer AC',
    settingDepot_00015: 'Startzeit',
    settingDepot_00016: 'Endzeit',
    settingDepot_00017: 'Strom({type}/{unit})',
    settingDepot_00018: 'Service({type}/{unit})',
    settingDepot_00019: 'Verzug({type}/{unit})',
    settingDepot_00020: 'Haupt-/Nebentarif Zeiten',
    settingDepot_00021: 'Undefiniert',
    settingDepot_00022: 'Tip',
    settingDepot_00023: 'peak',
    settingDepot_00024: 'Flat',
    settingDepot_00025: 'offpeak',
    settingDepot_00026: 'AC Stromtarif',
    settingDepot_00027: 'DC Stromtarif',
    settingDepot_00028: 'Neuer Tarif',
    settingDepot_00029: 'Bezeichnung des Tarifs',
    settingDepot_00030: 'Geben Sie einen Namen ein',
    settingDepot_00031: 'Name bereits belegt',
    settingDepot_00032: 'Zeiten zufügen',
    settingDepot_00037: 'Bitte wählen Sie oder erstellen Sie einen neuen Tarifstandard',
    settingDepot_00038: 'Max.{0} Zeiten können hinzugefügt werden',
    settingDepot_00039: 'Tarifstandard eingeben',
    settingDepot_00040: 'Bitte den korrekten Tarif eingeben',
    settingDepot_00041: 'Neue erfolgreich',
    settingDepot_00043: 'Bitte verbessern Sie die Tarifinformationen',
    settingDepot_00044: 'Tarifperiode liegt zwischen 0 und 24',
    settingDepot_00045: "Kein Rabatt für gesamte Gebühr jedes einzelnen Aufladens",
    settingDepot_00046: "Kein Rabatt für die Servicegebühr jedes einzelnen Aufladens",
    settingDepot_00047: "{des}%-iger Rabatt für Servicegebühr jedes einzelnen Aufladens",
    settingDepot_00048: "{des}%-iger Rabatt für gesamte Gebühr jedes einzelnen Aufladens",
    settingDepot_00049: "Freistellung der gesamten Gebühr jedes einzelnen Aufladens",
    settingDepot_00050: "Freistellung der Servicegebühr jedes einzelnen Aufladens",
    settingDepot_00051: 'Korrekten Betrag (größer als 0) eingeben',
    settingDepot_00052: 'Erfolg',
    settingDepot_00053: 'Schieben',
    settingDepot_00054: 'fehlgeschlagen',
    settingDepot_00055: 'Drücken fehlgeschlagen',
    settingDepot_00056: 'Name des Gebiets',
    settingDepot_00057: 'Anzahl der Ausrüstung',
    settingDepot_00058: 'Ergebnis der Geräteübertragung',
    settingDepot_00059: 'DC',
    settingDepot_00060: 'AC',
    settingDepot_00061: 'Nutzergruppe zufügen',
    settingDepot_00062: 'Nutzergruppename',
    settingDepot_00063: 'Aktueller Tarifstandard',
    settingDepot_00064: 'Aktuelle Rabatte',
    settingDepot_00065: 'Priorität (hoch-niedrig)',
    settingDepot_00066: 'Nach oben gehen',
    settingDepot_00067: 'Nach unten gehen',
    settingDepot_00068: 'Einstellungen',
    settingDepot_00069: 'Schieben',
    settingDepot_00071: 'Kontakt zum Bahnhof',
    settingDepot_00072: 'Bei Ausfallalarm wird der Stationskontakt in Form von SMS benachrichtigt',
    settingDepot_00073: 'Name',
    settingDepot_00074: 'Bitte geben Sie Ihren Namen ein',
    settingDepot_00076: 'Bitte geben Sie die Handynummer ein',
    settingDepot_00077: 'E-Mail',
    settingDepot_00078: 'Bitte E-Mail-Adresse eingeben',
    settingDepot_00081: 'Status der Station',
    settingDepot_00082: 'Kontrollieren Sie, ob die Seite im öffentlichen Konto von wechat angezeigt wird',
    settingDepot_00083: 'Aktivieren',
    settingDepot_00084: 'nicht aktiviert',
    settingDepot_00085: 'Öffnung für die Außenwelt',
    settingDepot_00086: 'to open up',
    settingDepot_00087: 'Nicht geöffnet',
    settingDepot_00088: 'Nur unterstützte Gruppen dürfen berechnen',
    settingDepot_00089: 'Wenn diese Option aktiviert ist, können Gruppenbenutzer in Gruppentarifeinstellungen Gebühren verlangen.',
    settingDepot_00090: 'Yes',
    settingDepot_00091: 'No',
    settingDepot_00092: 'Unterstützen Sie Gutscheine',
    settingDepot_00093: 'Ob Warteschlangen unterstützt werden',
    settingDepot_00094: 'Ob Reservierung unterstützt wird',
    settingDepot_00095: 'Dauer der Reservierung',
    settingDepot_00096: '15 Minuten',
    settingDepot_00097: '30 Minuten',
    settingDepot_00098: 'Wechat Benutzer Zahlungsmodus',
    settingDepot_00099: 'Vorauszahlung',
    settingDepot_00100: 'postpaid',
    settingDepot_00101: 'Minimale Bilanzgrenze',
    settingDepot_00102: 'cny',
    settingDepot_00103: 'Online ID Karte / Plug and Play Card Payment Modus',
    settingDepot_00104: 'Befindet sich auf die Anzeige der Website auf dem Client',
    settingDepot_00105: 'Öffnungszeiten (Öffnungszeiten)',
    settingDepot_00106: 'Im Prepaid-Modus können nur Anwender aufladen',
    
    settingDepot_00107: 'Erfolg',
    settingDepot_00108: 'Schieben',
    settingDepot_00109: 'Fehler',
    settingDepot_00110: 'Drücken fehlgeschlagen',
    settingDepot_00111: 'Bitte ändern Sie den Standardtarif oder fügen Sie zuerst eine Gruppe hinzu',
    settingDepot_00112: 'Drücken fehlgeschlagen. Es gibt kein Gerät unter der Website',
    settingDepot_00113: 'Diese Operation löscht die Gruppe aus der Liste. Möchten Sie fortfahren?',
    settingDepot_00114: 'Tipps',
    settingDepot_00115: 'bestätigen',
    settingDepot_00116: 'Stornieren',
    settingDepot_00117: 'Löschen abgebrochen',
    settingDepot_00118: 'Bitte bestätigen Sie, um andere Operationen zuerst zu speichern',
    settingDepot_00119: 'Bitte wählen Sie eine Seite',
    settingDepot_00120: 'Keine Änderung von Informationen, Operation abgebrochen',
    settingDepot_00121: 'Bitte geben Sie den richtigen Kontaktnamen ein',
    settingDepot_00122: 'Bitte geben Sie die richtige Telefonnummer ein',
    settingDepot_00123: 'Bitte geben Sie das richtige E-Mail-Format ein',
    settingDepot_00124: 'Falsche Datumseinstellung',
    settingDepot_00125: 'Gruppe auswählen',
    settingDepot_00126: 'Bitte geben Sie den Community-Namen ein',
    settingDepot_00127: 'Zahl der anwender',
    settingDepot_00128: 'Standard des Tarifs',
    settingDepot_00129: 'Wenn der Tarifstandard geändert wird, klicken Sie auf den Button "Tarif drücken" in den Standortdetails - Tarifstandard, um den Tarif wirksam zu machen!',
    settingDepot_00130: 'AC',
    settingDepot_00131: 'Tarif auswählen',
    settingDepot_00132: 'DC',
    settingDepot_00133: 'Auswahl der Rabatte',
    settingDepot_00134: 'Kein Rabatt für gesamte Gebühr jedes einzelnen Aufladens',
    settingDepot_00135: 'Rabatt für Servicegebühr jedes einzelnen Aufladens',
    settingDepot_00136: "Beispiel: Wenn für einzelnes Aufladen ein Rabatt von 15% auf die Servicegebühr unterstellt wird und eine Gesamtgebühr von 100 CNY (inkl. Ladekosten und Servicegebühr i. H. v. 70 bzw. 30 CNY) anfällt, beträgt die tatsächlich zu zahlende Summe 95,5 CNY (70+30*85%=95,5).",
    settingDepot_00137: '85 steht für einen Rabatt von 15%, 0 entspricht der Gebührfreiheit.',
    settingDepot_00138: 'Rabatt für gesamte Gebühr jedes einzelnen Aufladens',
    settingDepot_00139: 'Beispiel: Wenn für einzelnes Aufladen ein Rabatt von 15% auf die Gesamtgebühr festgelegt ist und eine Gesamtgebühr von 100 CNY anfällt, beträgt die tatsächlich zu zahlende Summe 85 CNY (100*85%=85).',
    settingDepot_00140: '85 bedeutet 8,5% ab und 0 bedeutet frei',
    settingDepot_00141: 'Gruppe erfolgreich hinzugefügt',
    settingDepot_00142: 'Klicken Sie auf die Schaltfläche [Laden] im Tarifstandard, um den Tarif wirksam zu machen!',
    settingDepot_00143: 'Folgende Operationen können durchgeführt werden in [Ladestation-Management - Site-Informationen]:',
    settingDepot_00144: '1. Neue Community entfernen',
    settingDepot_00145: '2. Eine neue Gruppe hinzufügen',
    settingDepot_00146: 'Vorheriger Schritt',
    settingDepot_00147: 'nächster Schritt',
    settingDepot_00148: 'bestimmen',
    settingDepot_00149: 'Gruppe auswählen',
    settingDepot_00150: 'Bitte Stromtarif auswählen.',
    settingDepot_00151: 'Gruppen können nicht wiederholt hinzugefügt werden',
    settingDepot_00152: 'Einstellung der Rabattparameter',
    settingDepot_00153: 'Bitte geben Sie eine positive Ganzzahl unter 100',
    settingDepot_00154: 'Tarif und Rabatt auswählen',
    settingDepot_00155: 'vollständig',
    settingDepot_00156: 'Ergebnisse der Chargenänderung',
    settingDepot_00157: 'Warten auf Antwort',
    settingDepot_00158: 'Grund',
    settingDepot_00159: 'schließen',
    settingDepot_00160: 'Mindestsaldo',
    settingDepot_00161: 'Währung verwenden',
    settingDepot_00162: '1. Eine hinzugefügte Gruppe',
}