export default {
vehicleChargingLogDetail_00001: "日志详情",
vehicleChargingLogDetail_00002: "充电曲线",
vehicleChargingLogDetail_00003: "",
vehicleChargingLogDetail_00004: "车牌号",
vehicleChargingLogDetail_00005: "车型",
vehicleChargingLogDetail_00006: "车辆{tag}",
vehicleChargingLogDetail_00007: "车主",
vehicleChargingLogDetail_00008: "手机号",
vehicleChargingLogDetail_00009: "充电开始时间",
vehicleChargingLogDetail_00010: "充电结束时间",
vehicleChargingLogDetail_00011: "",
vehicleChargingLogDetail_00012: "",
vehicleChargingLogDetail_00013: "",
vehicleChargingLogDetail_00014: "",
vehicleChargingLogDetail_00015: "",
billDetail_00025: "充电曲线",
billDetail_00026: "车端需求电压(虚线)",
billDetail_00027: "车端需求电流(虚线)",
billDetail_00028: "车端测量电压(虚线)",
billDetail_00029: "车端测量电流(虚线)",
billDetail_00030: "电池初始电量",
billDetail_00031: "电池当前电量",
billDetail_00032: "瞬时功率",
billDetail_00033: "平均功率",
billDetail_00034: "最高功率",
billDetail_00035: "车型",
billDetail_00036: "功率",
billDetail_00037: "电流",
billDetail_00038: "电压",
billDetail_00039: "电池电量",
billDetail_00040: "最高功率范围，功率范围{powerBegin}-{powerEnd}Kw，共{minutes}分钟(占总时长的{timePercent})，共{powerCharged}度电，占总电量{chargePercent}",
billDetail_00041: "累计电量",
billDetail_00042: "度",
billDetail_00043: "创建充电订单",
billDetail_00044: "充电桩开始充电",
billDetail_00045: "开始计费",
billDetail_00046: "充电桩停止充电",
billDetail_00047: "充电桩结束充电",
billDetail_00048: "开始收取延时费",
billDetail_00049: "充电完成",
billDetail_00050: "结束计费",
billDetail_00051: "支付完成",
billDetail_00091: "最高功率范围，功率范围{powerBegin}-{powerEnd}Kw，共{minutes}分钟(占总时长的{timePercent})，共{powerCharged}度电，占总电量{chargePercent}",
}