export default {
    stationMaintenanceAnalysis_00001: "场站异常率分析",
    stationMaintenanceAnalysis_00002: "维保首页",
    stationMaintenanceAnalysis_00003: "桩责异常率=此站此日桩责异常充电订单数/此站此日充电订单数",
    stationMaintenanceAnalysis_00004: "车责异常率=此站此日车责异常充电订单数/此站此日充电订单数",
    stationMaintenanceAnalysis_00005: "趋势分析",
    stationMaintenanceAnalysis_00006: "每日分析",
    stationMaintenanceAnalysis_00007: "桩责异常率分析",
    stationMaintenanceAnalysis_00008: "车责异常率分析",
    stationMaintenanceAnalysis_00009: "场站设备异常率分析",
    stationMaintenanceAnalysis_00010: "桩责异常订单",
    stationMaintenanceAnalysis_00011: "桩责异常码",
    stationMaintenanceAnalysis_00012: "桩责异常率{num}%",
    stationMaintenanceAnalysis_00013: "订单号",
    stationMaintenanceAnalysis_00014: "异常",
    stationMaintenanceAnalysis_00015: "充电口",
    stationMaintenanceAnalysis_00016: "设备异常日志",
    stationMaintenanceAnalysis_00017: "订单详情",
    stationMaintenanceAnalysis_00018: "设备详情",
    stationMaintenanceAnalysis_00019: "发起工单",
    stationMaintenanceAnalysis_00020: "车责异常订单",
    stationMaintenanceAnalysis_00021: "场站设备",
    stationMaintenanceAnalysis_00022: "桩责异常率=此桩此日桩责异常充电订单数/此桩此日充电订单数",
    stationMaintenanceAnalysis_00023: "车责异常率=此桩此日车责异常充电订单数/此桩此日充电订单数",
    stationMaintenanceAnalysis_00024: "异常率分析",
    stationMaintenanceAnalysis_00025: "车辆VIN",
    stationMaintenanceAnalysis_00026: "车牌号",
    stationMaintenanceAnalysis_00027: "车型",
    stationMaintenanceAnalysis_00028: "车辆充电日志",
    stationMaintenanceAnalysis_00029: "设备名称",
    stationMaintenanceAnalysis_00030: "设备型号",
    stationMaintenanceAnalysis_00031: "设备序列号",
    stationMaintenanceAnalysis_00032: "桩责异常率",
    stationMaintenanceAnalysis_00033: "车责异常率",
    stationMaintenanceAnalysis_00034: "查看工单",
    stationMaintenanceAnalysis_10000: "车责异常率{num}%",
    stationMaintenanceAnalysis_10001: "车责异常码",
    stationMaintenanceAnalysis_10002: "暂无异常",
    stationMaintenanceAnalysis_10003: "设备异常率分析",
    stationMaintenanceAnalysis_10004: "异常码占比",
    stationMaintenanceAnalysis_10005: "异常码",
}