<template lang="html">
    <el-dialog
        title="请填写拒绝理由(必填)"
        :visible.sync="flag"
        :lock-scroll="false"
        width="30%"
        :before-close="close">
        <div class="refusebox">
            <el-input
                type="textarea"
                :maxlength="50"
                show-word-limit
                :autosize="{ minRows: 4, maxRows: 5}"
                placeholder="请输入内容"
                v-model="textarea">
            </el-input>
            <!-- <div class="refuse-text">还可以输入{{50-textarea.length}}个字</div> -->
            <div class="tags">
                <el-tag type="info" @click="textarea=item" v-for="item in tagList" :key="item">{{item}}</el-tag>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取消</el-button>
                <el-button type="primary" v-setPermission permissionInfo="invoiceInfo" @click="confirm" :disabled="!textarea">确认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import {
    requestUrl,
    ajax,
} from "@/assets/utils/network";
export default {
    props: ["flag","message"],
    data() {
        return {
            textarea: "",
            tagList: ['本月发票额度用完，等开完通知您','填写发票信息不完整或不正确'],
        };
    },
    methods: {
        close() {
            this.flag = false;
            this.$emit("backFlag", this.flag);
        },
        confirm(){
            if(!this.textarea) return false;
            if (!this.message || this.message != 0) {
                var om = this;
                var url = requestUrl + "/wechatinvoice";
                var param = new Object();
                param.id = this.message;
                param.action = "cancel";
                param.memo = this.textarea;
                ajax({
                    type: "POST",
                    url: url,
                    dataType: "json",
                    data:JSON.stringify(param),
                    success: function(response) {
                        var result = JSON.parse(response);
                        if(result && result.msg){
                            om.$message({
                                message: result.msg,
                                type: 'error'
                            });
                        }else{
                            om.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                            var obj = new Object();
                            obj.type = 'cancelled';
                            obj.status='success';
                            om.$emit("dispatch", obj);
                            om.close();
                        }
                    }
                });
            } else {
                om.$message({
                    message: '操作失败',
                    type: 'error'
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.refusebox{
    & .refuse-text{
        text-align: right;
        margin-top: 6px;
    }
    .tags{
        margin-top: 10px;
       .el-tag{
            margin-left: 20px;
            cursor: pointer;
            &:first-child{
                margin-left: 0;
            }
       } 
    }
}
/deep/.el-dialog{
    min-width: 500px;
}
</style>
