export default {
    user_export_remind_1: "此操作将下载用户的Excel文件, 是否继续?",
    remind: "提示",
    confirm: "确认",
    cancel: "取消",
    start_download: "开始下载",
    cancel_download: "取消下载",
    modify_success: "修改成功",
    enabled: "启用",
    disabled: "未启用",
    open: "开放",
    unopen: "未开放",
    ac:"交",
    dc:"直",
    common_00241: "添加成功",
    charging_capacity:"充电量 (度)",
    service_fee_amount:"服务费 (元)",
    paid_amount:"实付总费用 (元)",
    notUserGroup_order_paid:"非团体已支付充电订单",
    level_day_task1:"各等级用户无需保级任务",
    level_day_task2:"各等级用户每30日保级任务",
    level_day_task3:"各等级用户每90日保级任务",
    level_initial:"分配会员等级",
    level_initial_time:"历史数据开始时间:",
    selectTime: "选择时间",
    initial:"初始化",
    think_again:"再想想",
    initial_info_wait:"正在对用户初始化等级，请稍等...",
    initial_info_success:"对用户初始化等级成功！",
    fee_policy_push_remind1:"如果资费标准有修改，在站点详情-资费标准中点击“推送资费”按钮，资费才可以生效！",
    fee_policy_push_remind2:"单次充电总费用不打折（%）",
    fee_policy_push_remind3:"单次充电服务费打折（%）",
    fee_policy_push_remind4:"单次充电总费用打折（%）",
    member_00000: '会员管理',
    member_00001: '会员信息',
    member_00002: '会员营销',
    member_00003: '会员等级设置',
    member_00004: '会员权益设置',
    member_00005: '会员等级启用',
    member_00006: '开',
    member_00007: '关',
    member_00008: '导出用户',
    member_00009: '等级(由低到高)',
    member_00010: '级',
    member_00011: '等级名称',
    member_00012: '用户数',
    member_00013: '营销活动数',
    member_00014: '营销活动',
    member_00015: '查看用户',
    member_00016: '营销活动控制',
    member_00017: '是否启用卡券优惠',
    member_00018: '设置帮助',
    member_00019: '1.如设置为启用卡券优惠，则等级用户支付订单时，可以使用卡券；如设置为不启用卡券优惠，等级用户支付订单时不能使用卡券。',
    member_00020: '2.支付订单时优先使用等级用户特权，使用等级用户特权后的账单金额再根据卡券满足条件选择使用的卡券。',
    member_00020_1: '例：VIP用户此次充电费用100元(含电费70元、服务费30元)，可享受特权(单次充电服务费8.5折)，且有1张卡券(单次充电服务费满足20元服务费9折)，',
    member_00020_2: '则使用等级用户特权后后，账单金额为95.5元(含电费70元，服务费25.5元(30*85%))，满足卡券(单次充电服务费满足20元服务费9折)。则使用卡券后，账单金额为92.95元(含电费70元+服务费22.95元(25.5*90%))，最终此次需实际支付92.95元。',
    member_00021: '排除场站',
    member_00022: '添加充电站后，等级用户到此充电站充电不享受特权',
    member_00023: '添加排除场站',
    member_00024: '场站名称',
    member_00025: '运营商',
    member_00026: '场站详情',
    member_00027: '删除',
    member_00028: '确定删除该站点吗?',
    member_00029: '删除成功',
    member_00030: '用户范围：微信用户',
    member_00031: '分配会员等级',
    member_00032: '编辑',
    member_00033: '取消',
    member_00034: '保存',
    member_00036: '分配会员等级:',
    member_00035: '启用后请慎重设置用户等级相关参数，频繁修改可能会引起充电用户反感投诉。如重新设置，请参考设置帮助使用"分配会员等级"功能。',
    member_00037: '升级/保级任务类型',
    member_00038: '(非团体且已支付充电订单)',
    member_00039: '设置帮助',
    member_00040: '升级任务：系统自动根据各等级用户所需的成长值进行升级，达到成长值即升级。',
    member_00040_1: '保级任务：系统自动根据各等级用户所需的免降值进行保级，达到免降值即保级，未达到免降值即降级。您可以选择是否开启保级任务。',

    member_00041: '免降值设置',
    member_00041_1: '用户通过充电消费获得免降值，当用户成长到新的等级时，需要在您设置的保级任务完成时间期内，获得保级要求的免降值，即可保级成功，反则降级。',
    member_00041_2: '例：当您设置开启保级任务，并设置为"每30日完成一次"，将VIP2的免降值设置为200，当用户从VIP1升级为VIP2时，需从升级当天起每30日获得200免降值，才能保持VIP2等级，否则将降级成为VIP1。保级成功或失败的结果，将从保级任务完成周期后的第一天开始执行。',
    member_00042: '会员等级设置',
    member_00043: '开启保级任务',
    member_00044: '每30日完成一次',
    member_00045: '每90日完成一次',
    member_00046: '序列',
    member_00047: '会员等级(等级由低到高)',
    member_00048: '请输入等级名称',
    member_00049: '成长值',
    member_00050: '请输入整数',
    member_00051: '免降值',
    member_00052: '添加等级',
    member_00053: '升级任务/保级任务格式错误',
    member_00054: '请填写用户等级名称/升级任务/保级任务',


    member_00055: '会员权益',
    member_00056: '等级用户权益类型',
    member_00057: '输入85折表示8.5折，输入0表示全免，输入100表示不打折。',
    member_00058: '等级名称(等级由低到高)',
    member_00059: '会员等级优惠：',
    member_00060: '根据用户不同等级对单次充电消费打折。如果等级用户去绑定其所在的团体的站点充电，使用团体优惠，不使用等级用户特权。',
    member_00061: '例：如设置VIP用户单次充电服务费8.5折，VIP用户此次充电费用100元(含电费70元、服务费30元），则此次需实际支付金额95.5（70+30*85%=95.5）。',
    member_00062: '如设置VIP用户单次充电总费用8.5折，VIP用户此次充电费用100元，则此次需实际支付金额85元（100*85%=85）。',
    member_00063: '会员等级优惠格式错误',
    member_00064: '请输入会员等级优惠',


    member_00065: '请输入场站名称/运营商名称',
    member_00066: '查询',
    member_00067: '场站名称',
    member_00068: '运营商',
    member_00069: '场站状态',
    member_00070: '对外开放',
    member_00071: '充电桩',
    member_00072: '取消',
    member_00073: '添加',
    member_00074: '成长值设置：',
    member_00075: '用户通过充电消费获得成长值，当用户成长值达到相应等级要求时，用户即成为相应等级会员。成长值不跨级累计，每次用户升级后，初始成长值为0。',
    member_00076: '例：VIP1所需成长值为100，VIP2所需的成长值为200，当用户为VIP1时，需再获取200成长值才能成为VIP2。',

    // 2022/1/17
    member_10000: '储值送返活动',
    member_10001: '自由领取活动',
    member_00077: '等级奖励',
    member_00078: '当用户完成升级任务/保级任务时，赠送给用户的奖励。升级/保级奖励只在启用会员等级设置的情况下生效。',
    member_00079: '等级名称(由低到高）',
    member_00080: '升级奖励',
    member_00081: '保级奖励',
    member_00082: '奖励设置',
    member_00083: '添加卡券',
    member_00084: '如果添加有效期位绝对时间的卡券，请注意在卡券失效时间前将卡券从奖励中删除。',
    member_00084: '请输入卡券名称',
    member_00085: '卡券类型',
    member_00086: '卡券名称',
    member_00087: '类型',
    member_00088: '请输入100的倍数,最大10000',
    member_00089: '充电量',
    member_00090: '服务费',
    member_00091: '参考数据',
    member_00092: '单个用户非团队且已支付充电订单',
    member_00093: '实付总费用',
    member_00094: '会员等级优惠',
    member_00095: '*系统会计算每一位充电用户的充电数据，从开始时间至今所累积的成长值，并根据您当前设置的等级规则分配等级(计算过程可能需要一段时间，请您耐心等待）。<br>*当您首次开启会员等级功能，设置等级参数后，如需根据用户历史充电数据分配会员等级，请使用“分配会员等级”功能。<br>*当您修改等级参数时<br>1.如设置修改等级名称、增加等级、增加成长值、增加免降值，设置完成后点击“保存”按钮即可生效,不需要使用“分配会员等级”。<br>2.如设置减少成长值、减少免降值，设置完点击“保存”按钮即可生效。部分用户之前累计的成长值或免降值并未能够升级成功或保级成功，由于您设置减少成长值或免降值后，达到了升级或保级要求，这种情况下当用户进行一次充电后，即可完成升级或保级任务。<br>例：之前您设置VP2所需的成长为200，当前您已修改为150、某用户在修改前已累计了150成长值，那么当您修改设置后，该用户下一次充电后即可升级VIP2。<br>3.如设置减少等级，设置完成后请使用“分配会员等级”功能，保证用户能获得相应的新等级。<br>例：之前您设置等级为五级，最高会员等级为VIP5，现设置会员等级为三级，最高会员等级为VIP3，某用户会员等级之前为VIP5，需您使用“分配会员等级”后，该用户才能分配到新的会员等级',
    member_00096: '继续',
    member_00097: '换算比例',
    member_00098: '成长值/免降值',
    member_00099: '1度充电量',
    member_000100: '1元服务费',
    member_000101: '1元实付总费用',
    member_000102: '上次分配会员等级时间:',
    member_000103: '退款后免去会员权益',
    member_000104: "3.退款后免去会员权益：开启后，当用户退款成功，该用户将成为普通用户（失去原有会员等级权益），成长值、免降值也会清零。",
    
    
}
