export default {
    detailDepot_00001: 'Ladestationseinzelheit',
    detailDepot_00002: "Personenkraftwagen",
    detailDepot_00003: "Logistikfahrzeuge",
    detailDepot_00004: "Mittlerer Bus",
    detailDepot_00005: "Busse",
    detailDepot_00006: "E-Fahrräder",
    detailDepot_00007: "Öffentlich",
    detailDepot_00008: "Persönlich",
    detailDepot_00009: "Öffentliche Verkehrsmittel (dediziert)",
    detailDepot_00010: "Sanitärbereich (dediziert)",
    detailDepot_00011: "Logistik (dediziert)",
    detailDepot_00012: "Taxi(dediziert)",
    detailDepot_00013: 'Time-Share-Vermietung (dediziert) (Erweiterung des Interoperabilitätsstandards)',
    detailDepot_00014: 'Community-Sharing (dediziert) (Erweiterung des Interoperabilitätsstandards)',
    detailDepot_00015: 'Firma (dediziert) (Erweiterung des Interoperabilitätsstandards)',
    detailDepot_00016: 'Sonstige',
    detailDepot_00017: 'Bitte auswählen',
    detailDepot_00018: 'Wohngebäude',
    detailDepot_00019: 'Öffentliche Institute',
    detailDepot_00020: 'Institutionen',
    detailDepot_00021: 'Bürogebäude',
    detailDepot_00022: 'Gewerbegebiete',
    detailDepot_00023: 'Verkehrsknotenpunkt',
    detailDepot_00024: 'Große Kultur- und Sportgemeinde',
    detailDepot_00025: 'Stadtbegrünung',
    detailDepot_00026: 'Großes Gebäude mit Parkplatz',
    detailDepot_00027: 'Parkplätze abseits der Straße',
    detailDepot_00028: 'Autobahn-Raststätte',
    detailDepot_00029: 'Bitte geben Sie nach nationale Norm GB/T 2260-2007 ein',
    detailDepot_00030: 'Erfolgreich bearbeiten',
    detailDepot_00031: 'Ländercode-Formatfehler (2 Großbuchstaben)',
    detailDepot_00032: 'Bild erfolgreich geändert',
    detailDepot_00033: 'Bild hochladen',
    detailDepot_00034: 'Es werden nur Bilder im PNG-, JPG- und GIF-Format unterstützt, nicht mehr als 1M',
    detailDepot_00035: 'Live-Bild',
    detailDepot_00036: 'Adresse, Straße, Hausname',
    detailDepot_00037: 'suchen',
    detailDepot_00038: 'Name',
    detailDepot_00039: 'Adressangaben',
    detailDepot_00040: 'aktuelle Standort',
    detailDepot_00041: 'abbrechen',
    detailDepot_00042: 'diese Standort benutzen',
    detailDepot_00043: 'Ladestationskoordinate auswählen',
    detailDepot_00044: 'Ihr geänderter Mapstandort stimmt nicht mit der Adresse überein, der Fehler beträgt mehr als 200 Meter! ',
    detailDepot_00045: "Hinweise",
    detailDepot_00046: "Ich habe gewusstHinweise",
    detailDepot_00047: "Adresse wird nicht gefunden",
    detailDepot_00048: "Auf der Gaudet-Map ist ein unbekannter Fehler aufgetreten",
    detailDepot_00049: "Ladestationszentrum",
    detailDepot_00050: "Ladestationsverwaltung",
    detailDepot_00051: 'Ladestationsinformation',
    detailDepot_00052: 'Gerätsverwaltung',
    detailDepot_00053: 'Tarifeinstellung',
    detailDepot_00054: 'Betriebseinstellung',
    detailDepot_00055: 'Offene Fluss',
    detailDepot_00056: 'Regierungskupplung',
    detailDepot_00057: 'Leistungsverteilung',
    detailDepot_00058: 'Ordentliches Laden von Bussen',
    detailDepot_00059: 'Grundlegende Informationen',
    detailDepot_00060: 'sepeichern',
    detailDepot_00061: 'bearbeiten',
    detailDepot_00062: 'Ladestation löschen',
    detailDepot_00063: 'Ladestationsname',
    detailDepot_00064: 'Ladestationsstandort',
    detailDepot_00065: 'Mapstandort',
    detailDepot_00066: 'Provinz und Stadtcode',
    detailDepot_00067: 'Ländercode',
    detailDepot_00068: 'unterstützte Modelle',
    detailDepot_00069: 'Ladestationstyp',
    detailDepot_00070: 'Ladestationskontakt',
    detailDepot_00071: 'Baustelle',
    detailDepot_00072: 'Standort des Parkplatzes',
    detailDepot_00073: 'Anzahl der Parkplätze',
    detailDepot_00074: 'Aktuelles Bild',
    detailDepot_00075: 'Name',
    detailDepot_00076: 'Handynummer',
    detailDepot_00077: 'Email',
    detailDepot_00078: 'Parkgebühr',
    detailDepot_00079: 'Gerät offline',
    detailDepot_00080: 'Ladebeginn',
    detailDepot_00081: 'online',
    detailDepot_00082: 'offline',
    detailDepot_00083: 'Störung',
    detailDepot_00084: 'im Laden',
    detailDepot_00085: "alle",
    detailDepot_00086: "In den Leerlauf gehen",
    detailDepot_00087: "Gerät ist nicht aktiviert",
    detailDepot_00088: "besetzt",
    detailDepot_00089: "Sprache",
    detailDepot_00090: "Zeitzone",
    detailDepot_00091: "Sommerzeit",
    detailDepot_00092: "AC Pfahlmagnetverschluss",
    detailDepot_00093: "DC Pfahlmagnetverschluss",
    detailDepot_00094: "Ist Laden möglich, wenn es mit elektromagnetische Schloss nicht verriegelt",
    detailDepot_00095: "Parkplatz-Radar",
    detailDepot_00096: "Wartezeit AC-Gerät",
    detailDepot_00097: "Wartezeit DC-Gerät",
    detailDepot_00098: "Höchstbetrag, der für die Zeitverzögerung berechnet wird",
    detailDepot_00099: "Politik zur Erhebung von Gebühren mit Zeitverzögerung",
    detailDepot_00100: "AC Gerät",
    detailDepot_00101: "DC Gerät",
    detailDepot_00102: "AC Gerät",
    detailDepot_00103: "DC Gerät",
    detailDepot_00104: "aktiviert",
    detailDepot_00105: "nicht aktiviert",
    detailDepot_00106: "nicht ladbar",
    detailDepot_00107: "Politik zur Erhebung von Gebühren mit Zeitverzögerung",
    detailDepot_00108: "Erhebung auf jeden Fall",
    detailDepot_00109: "Keine Gebühr für die Ladevorgangsende aufgrund eines Ausfalls",
    detailDepot_00110: "{num}min",
    detailDepot_00111: "0 bedeutet keine Begrenzung",
    detailDepot_00112: "ladbar",
    detailDepot_00113: "10 Minuten",
    detailDepot_00114: "15 Minuten",
    detailDepot_00115: "20 Minuten",
    detailDepot_00116: "30 Minuten",
    detailDepot_00117: "Wenn das Gerät offline ist, werden nur Benutzer zum Ladestart in der Whitelist unterstützt.",
    detailDepot_00118: "Push-Whitelist",
    detailDepot_00119: "Whitelist entfernen",
    detailDepot_00120: "Whitelist anzeigen",
    detailDepot_00121: "Nein",
    detailDepot_00122: "Ja",
    detailDepot_00123: "Falsches Eingabesformat",
    detailDepot_00124: "Negative Zahlen können nicht eingegeben werden",
    detailDepot_00125: "Push fehlgeschlagen",
    detailDepot_00126: "Insgesamt wurden {allDevice} Geräte gepusht, {sucDevice} Geräte erfolgreich und {faiDevice} fehlgeschlagen",
    detailDepot_00127: "Kein solches Gerät",
    detailDepot_00128: "{num} Geräte",
    detailDepot_00129: "Konfigurationsinformationen bearbeiten",
    detailDepot_00130: "C1 Ladestation(4.3'')",
    detailDepot_00131: "C1 Ladestation(7'')",
    detailDepot_00132: "C2 Ladestation",
    detailDepot_00133: "C4 Ladestation",
    detailDepot_00134: "C6 Ladestation",
    detailDepot_00135: "C6s Ladestation",
    detailDepot_00136: "{0} gemeldeter Fehler, Fehlercode: {1}",
    detailDepot_00137: "Der Höchstbetrag des Verzögerungsgebühren kann nur Zahl sein.",
    detailDepot_00251: "C9Pro Ladestation",
    zh: "Chinesisch",
    en: "Englisch",
    de: "Deutsch",
    fr: "Französisch",
    detailDepot_00138: "Neustart erfolgreich",
    detailDepot_00139: "Fernneustart",
    detailDepot_00140: "Neustart des Geräts fehlgeschlagen",
    processing: 'Neustart läuft',
    detailDepot_00141: "Bitte wählen Sie das Format png oder jpg hochzuladen",
    detailDepot_00142: "bis zu 6 Bildern hochzuladen",
    detailDepot_00143: "Das von Ihnen ausgewählte Bild ist zu groß, bitte wählen Sie es erneut aus",
    detailDepot_00144: "Bild hochladen",
    detailDepot_00145: "Mehrere Bilddrehungen oder mp4-Videomethode, wählen Sie eine",
    detailDepot_00146: "Bilddrehung verwenden",
    detailDepot_00147: "klicken hochzuladen",
    detailDepot_00148: "Video verwenden",
    detailDepot_00149: "5 Sekunde",
    detailDepot_00150: "10 Sekunde",
    detailDepot_00151: "15 Sekunde",
    detailDepot_00152: "bis zu 6 Bildern hochzuladen",
    detailDepot_00153: "max. 1 Video hochzuladen",
    detailDepot_00154: "Ausgewähltes Video ist zu groß, bitte wählen Sie erneut aus",
    detailDepot_00155: "Es werden nur Bilder im PNG-, JPG- und GIF-Format unterstützt, die nicht größer als {size}M sind.",
    detailDepot_00156: "erledigt",
    detailDepot_00157: "erfolgreich geändert",
    detailDepot_00158: "Standort ändern",
    detailDepot_00159: "Bitte geben Sie nach nationale Norm GB/T 2260-2007 ein",
    detailDepot_00160: "Bitte geben Sie nach ISO 3166-1 alpha-2 ein",
    detailDepot_00161: "Bitte speichern Sie den letzte Schritt.",
    detailDepot_00162: "Parameter ist leer",
    detailDepot_00163: "Bitte geben Sie den richtigen Betrag ein (größer als 0)",
    detailDepot_00164: "Datum falsch eingestellt",
    detailDepot_00165: "Bitte geben Sie den Ländercode ein",
    detailDepot_00166: "Falsches Ländercode-Format (2 Großbuchstaben)",
    detailDepot_00167: "Bitte wählen Sie den Standorttyp",
    detailDepot_00168: "Bitte geben Sie Partplatzanzahl ein.",
    detailDepot_00169: "Falsches Format für die Anzahl der Parkplätze",
    detailDepot_00170: "Anzahl der Parkplätze überschreitet das Limit (<=10000)",
    detailDepot_00171: "Die Beschreibung der Parkgebühr darf nicht länger als 200 Zeichen sein",
    detailDepot_00172: "Bitte geben Sie die richtige Kundendienstnummer ein",
    detailDepot_00173: "Sie können nur maximal 20 Ziffern eingeben",
    detailDepot_00174: "Bitte wählen Sie den Benutzertyp aus und stellen Sie den Prozentsatz ein",
    detailDepot_00175: "Bitte geben Sie eine positive ganze Zahl kleiner als 100 ein",
    detailDepot_00176: "bestätigen",
    detailDepot_00177: "abbrechen",
    detailDepot_00178: "erfolgrech gelöscht",
    detailDepot_00179: "Aktion abgebrochen",
    detailDepot_00180: "Bestätigen Sie diese Ladestation zu löschen",
    detailDepot_00181: "insgesamt {num}",
    detailDepot_00182: "unterstützte Whitelist",
    detailDepot_00183: "Push-Ergebnis",
    detailDepot_00184: "Insgesamt wurden {allDevice} Geräte gepusht, {sucDevice} Geräte erfolgreich und {faiDevice} fehlgeschlagen",
    detailDepot_00185: "Gerätsnummer",
    detailDepot_00186: "Gerähtsname",
    detailDepot_00187: "AC-Tarifsatz",
    detailDepot_00188: "Tarif auswählen",
    detailDepot_00189: "AC-Tarif neu erstellen",
    detailDepot_00190: "DC-Tarifsatz",
    detailDepot_00191: "DC-Tarif neu erstellen",
    detailDepot_00192: "aktuell genutzte Tarif",
    detailDepot_00193: "Beginn Datum",
    detailDepot_00194: "Endedatum",
    detailDepot_00195: "Elektrizität\n({type}/{unit})",
    detailDepot_00196: "Servicegebühr\n({type}/{unit})",
    detailDepot_00197: "Verzögerungsgebühr\n({type}/min)",
    detailDepot_00198: "Valley Peak Wochentage",
    detailDepot_00199: 'Valley Peak Wochentage',
    detailDepot_00200: 'nicht definiert',
    detailDepot_00201: 'Tipp',
    detailDepot_00202: 'Spitze',
    detailDepot_00203: 'flach',
    detailDepot_00204: 'Tal',
    detailDepot_00205: 'Tarifname',
    detailDepot_00206: 'auswählen',
    detailDepot_00207: 'Tarif neu erstellen',
    detailDepot_00208: 'Bitte geben Sie einen Tarifnamen ein',
    detailDepot_00209: 'Dieser Tarifname ist bereits belegt',
    detailDepot_00210: 'Ein Zeitfenster hinzufügen',
    detailDepot_00211: 'Sie können bis zu {0} Zeitfenster hinzufügen',
    detailDepot_00212: 'Aktion',
    detailDepot_00213: 'löschen',
    detailDepot_00214: 'abbrechen',
    detailDepot_00215: 'bestätigen',
    detailDepot_00216: 'Bitte wählen Sie einen neuen Tarif aus oder legen Sie einen neuen an',
    detailDepot_00217: 'Sie können bis zu 12 Zeitfenster hinzufügen',
    detailDepot_00218: 'Bitte geben Sie einen Tarifsatz ein',
    detailDepot_00219: 'Der Tarifzeitraum muss bei 0 beginnen und bei 24 enden',
    detailDepot_00220: 'Bitte geben Sie den richtigen Tarif ein',
    detailDepot_00221: 'Neues Konto erfolgreich erstellt',
    detailDepot_00222: 'Tarif-Push',
    detailDepot_00223: 'Ladestationsname',
    detailDepot_00224: 'DC',
    detailDepot_00225: 'AC',
    detailDepot_00226: 'Erfolg',
    detailDepot_00227: 'Push läuft',
    detailDepot_00228: 'fehlgeschlagen',
    detailDepot_00229: 'Gerätsanzahl',
    detailDepot_00230: 'Push-ergebnisse',
    detailDepot_00231: 'Gruppename',
    detailDepot_00232: 'aktuelle Tarifsätze',
    detailDepot_00233: 'aktuelle Angebote',
    detailDepot_00234: 'Priorität(hoch zum niedrig)',
    detailDepot_00235: 'nach oben',
    detailDepot_00236: 'nach unten',
    detailDepot_00237: 'einstellen',
    detailDepot_00238: 'push',
    detailDepot_00239: 'löschen',
    detailDepot_00240: 'Kein Rabatt auf die Gesamtkosten einer einzelnen Ladung',
    detailDepot_00241: 'Keine Ermäßigung auf die einmalige Servicegebühr',
    detailDepot_00242: '{des} Rabatt auf die Gesamtkosten einer einzelnen Ladung',
    detailDepot_00243: 'Einmalige Servicegebühr {des} Rabatt',
    detailDepot_00244: 'Kostenlose einmalige Servicegebühr',
    detailDepot_00245: 'Kostenlose Gesamtbetrag für einmalige Laden',

    detailDepot_00246: 'löschen',
    detailDepot_00247: 'löschen',
    detailDepot_00248: 'löschen',
    detailDepot_00249: 'löschen',
    detailDepot_00250: 'löschen',


    /* 功率分配 */
    detailDepot_00401: "Ermöglicht das Hinzufügen einer Leistungszuweisungsgruppe, klicken Sie, um die Ladesäulen der Gruppe hinzuzufügen, legen Sie die Gesamtgruppengrenzladeleistung nach Zeitraum fest, die Ladeleistung der Ladesäulen in der Gruppe wird aus der Gesamtgruppengrenzladeleistung für jeden Zeitraum zugewiesen und überschreitet die Gesamtgruppengrenzladeleistung für jeden Zeitraum nicht.",
    detailDepot_00402: "Leistungsverteilunggruppe hinzufügen",
    detailDepot_00403: "{count} Ladesäulen zu push",
    detailDepot_00404: "Um die Zuweisung von Offline-Ladeleistung zu unterstützen, muss die maximale Offline-Ladeleistung des Ladeanschlusses (reservierte Ladeleistung) an die Ladestation übertragen werden.",
    detailDepot_00405: "offline Leistungskonfiguration push",
    detailDepot_00406: "Nicht verteilte Ladesäulen an der Station",
    detailDepot_00407: "Bestätigen Sie diese Verteilungsgruppe zu löschen",
    detailDepot_00408: "Klicken, zu Verteilungsgruppe hinzuzufügen",
    detailDepot_00409: "Bitte geben Verteilungsgruppename ein",
    detailDepot_00410: "Verteilungsgruppename",

    // 运营设置 ---基本配置
    abled: "",
    disabled: "",
    // modify:"修改",ändern
    // save:"保 存",spechern
    // cancel:"取 消",abbrechen
    // confirm:"确定",bestätigen
    // delete:"删除",löschen
    detailDepot_00500: "Status der Ladestation",
    detailDepot_00501: "Für die Öffentlichkeit zugänglich",
    detailDepot_00502: "Unterstützt es Gutscheine oder nicht",
    detailDepot_00503: "Unterstützt es Warteschlangen oder nicht",
    detailDepot_00504: "Unterstützt es Reservierungen oder nicht",
    detailDepot_00505: "Ob das Laden nach dem Timeout des Einsteckens erlaubt werden soll",
    detailDepot_00506: "WeChat Benutzerzahlungsmodus",
    detailDepot_00507: "Online-Zahlungsmodus für Ausweisnutzer",
    detailDepot_00508: "Beschränkungen für die Ladestation SOC",
    detailDepot_00509: "Hinweise an verbleibende Elektrizität",
    detailDepot_00510: "Kontrollieren, ob die Ladestation in WeChat Public angezeigt wird",
    detailDepot_00511: "Beeinflussen Sie die Anzeige der Informationen der Ladestation",
    detailDepot_00512: "Nach Start wird das Laden in Gruppentarif-Einstellungen",
    detailDepot_00513: "Im Prepaid-Modus können nur aufladende Nutzer laden",
    detailDepot_00514: "Im Prepaid-Modus können nur aufladende Nutzer laden",
    detailDepot_00515: "Nur für Gleichstrom-Ladesäulen",
    detailDepot_00516: "Resteletrizität < Mahnbetrag wird alarmiert",
    detailDepot_00517: "Bitte geben Sie eine positive ganze Zahl kleiner als 100 ein",
    detailDepot_00518: "Besorg besorg mir bitte einen benutzertyp, der sich einem bereits leidtut.",
    detailDepot_00519: "aktiviert",
    detailDepot_00520: "nicht aktiviert",
    detailDepot_00521: "Offen",
    detailDepot_00522: "Nicht geöffnet",
    detailDepot_00523: 'Ja',
    detailDepot_00524: 'Nein',
    detailDepot_00525: "geöffnet",
    detailDepot_00526: "Nicht geöffnet",
    detailDepot_00527: "unterstützen",
    detailDepot_00528: "nicht unterstützen",
    detailDepot_00529: "Parameter ist leer",
    detailDepot_00530: "Nur Gruppeladen wird erlaubt",
    detailDepot_00531: "Wartzeit",
    detailDepot_00532: "Minuten",
    detailDepot_00533: "Prepaid-Modus",
    detailDepot_00534: "Postpaid-Modus",
    detailDepot_00535: "Mindestguthaben",
    detailDepot_00536: "Benutzer von Dritten",
    detailDepot_00537: "Wechat Benutzer",
    detailDepot_00538: "Wenn die Batterieleistung erreicht",
    detailDepot_00539: "Der Ladevorgang wird automatisch beendet, wenn die Batterie den Wert % erreicht.",
    detailDepot_00540: "Preis pro kWh",
    detailDepot_00541: "Yuan",
    detailDepot_00542: "Errinerungsbetrag",
    detailDepot_00543: "Verbleibende Elektrizitätskosten",
    detailDepot_00544: "Bitte geben Sie den richtigen Betrag ein (größer als 0)",
    detailDepot_00545: "Bitte speichern Sie den vorherigen Vorgang!",
    detailDepot_00546: "Grundeinstellungen",

    // 运营设置 ---标签管理
    detailDepot_00547: 'Tag Management',
    detailDepot_00548: 'Benutzerdefinierte Tags hinzufügen',
    detailDepot_00549: 'Ladestationstag in der WeChat öffentlichen Konto hinzufügen, kann Ladestation in der WeChat öffentlichen Konto gefunden und in Ladestation-Details anzeigen',
    detailDepot_00550: 'Tagsname',
    detailDepot_00551: 'Tag-Bild',
    detailDepot_00552: 'Tag-Typen',
    detailDepot_00553: 'Reihefolge anzeigen',
    detailDepot_00554: "nach unten",
    detailDepot_00555: "nach oben",
    detailDepot_00556: "Selbstdefinierte Tag",
    detailDepot_00557: "Ladestationstaginfo",
    detailDepot_00558: "Tagname",
    detailDepot_00559: "Tag-typen",
    detailDepot_00560: "Selbstdefinierte Tag",
    detailDepot_00561: "Tag-Bild",
    detailDepot_00562: "Ziehen Sie die Datei hierher oder klicken Sie zum Hochladen",
    detailDepot_00563: "Wenn ein Tag-Bild vorhanden ist, zeigt das Front-End das Tag-Bild an. Wenn kein Etikettenbild vorhanden ist, zeigt das Front-End-System standardmäßig den Etikettennamen an.",
    detailDepot_00564: "Unterstützt nur Bilder im PNG- und JPG-Format, Größe {area}px, nicht mehr als {size}kb.",
    detailDepot_00565: "Bestätigen Sie zu löschen?",
    detailDepot_00566: "Hinweise",
    custom: "Selbstdefiniert",
    undefined: "nicht definiert",
    detailDepot_00567: "Aktion Erfolg",
    detailDepot_00568: "Aktion fehlgeschlagen",
    detailDepot_00569: "Erfolgreich gelöscht",
    detailDepot_00570: "Löschen abgebrochen",
    detailDepot_00571: "Erfolgrech geändert",
    detailDepot_00572: "Bitte Inhalt eingeben, bis zu 5 Zeichen möglich",

    // 运营设置 ---充电订单拓展
    detailDepot_00573: "Vorgangserweiterung aufladen",
    detailDepot_00574: "Hinzufügen der Informationen der Ladevorgänge in WeChat Public",
    detailDepot_00575: "hinzufügen",
    detailDepot_00576: "Titel",
    detailDepot_00577: "Link anzeigen",
    detailDepot_00578: "Beschränkungen anzeigen",
    detailDepot_00579: 'Reihenfolge anzeigen',
    // common_00282:'操作',Aktion
    detailDepot_00580: "Informationen zur Anzeige des Ladevorgangs",
    detailDepot_00581: "Inhalt anzeigen",
    detailDepot_00582: "Nur bezahlte Vorgänge anzeigen",
    detailDepot_00583: "Unbegrenzter Vorgangsstatus",
    detailDepot_00584: "beschreiben",
    detailDepot_00585: "Bitte geben Sie die URL-Adresse ein",
    detailDepot_00586: "Markieren Sie dies, um diese Informationen erst nach Abschluss der Zahlung anzuzeigen.",
    detailDepot_00587: "Bitte geben Sie den Inhalt ein",
    detailDepot_00588: "Bitte geben Sie den Inhalt ein",
    detailDepot_00589: "Nicht mehr als {num} Zeichen",
    detailDepot_00590: "Bitte geben Sie eine Zahl größer als 0 ein!",
    detailDepot_00591: "Bitte geben Sie den Belegungstarif ein!",
    detailDepot_00592: "Bitte geben Sie die Dauer des Belegungsgebührenerlasses ein!",
    detailDepot_00593: "Bitte geben Sie eine positive ganze Zahl ein",

    // 运营设置 ---云控车位锁占用费
    detailDepot_00594: "Cloud-gesteuerte Parkplatzsperre Belegungsgebühr",
    detailDepot_00595: "Belegungsgebührsatz({type}/{unit})",
    detailDepot_00596: "Dauer der Belegungsgebührfrei (Minute)",
    detailDepot_00597: "Die Zeitspanne, in der der Kunde einfährt, um Laden vorzubereiten, oder aus dem Parkplatz hinausfährt, ohne Belegungsgebühr zu berechnen",
    detailDepot_00598: "Online-Ausweis",
    // detailDepot_00598: "在线身份卡",Online-Ausweis


    // 停车场对接
    detailDepot_00600: "Parkplatzkupplung",
    detailDepot_00601: "Parkplatzkupplung dient der Ermäßigung der Parkgebühr oder der Ausgabe von Parkgutscheinen an die Nutzer",
    detailDepot_00602: "1. herausfinden, ob das Parksystem an diesem Standort bereits angedockt ist",
    detailDepot_00603: "2. falls bereits verbunden, geben Sie die Parkplatznummer ein und verknüpfen sie mit dem Parkplatz. (Bitte überprüfen Sie das Parkhaussystem und die Parkhausnummer)",
    detailDepot_00604: "3. wenn Sie noch nie angedockt haben, können Sie das Unternehmen kontaktieren, um eine Andockung zu beantragen.",
    detailDepot_00605: "Einführung der Marke Docked Parking System",
    detailDepot_00606: "Verbindungen",
    detailDepot_00607: "Verknüpfte Parkplätze",
    detailDepot_00608: "Parkplatz-ID",
    detailDepot_00609: "Verbindung schließen",
    detailDepot_00610: "Bitte geben Sie die Parkhausnummer ein (Unterstützen sie nur zahlen, buchstaben und unterstriche)",
    detailDepot_00611: "Bestellungen mit einer Ladezeit von weniger als 10 Minuten filtern",
    detailDepot_00612: "Bestätigen Sie, dass Sie die Verknüpfung mit {Name} schließen wollen?",
    detailDepot_00613: "Denken Sie noch einmal nach",
    detailDepot_00614: "OK",
    detailDepot_00615: "Zugehöriger Parkplatz",
    detailDepot_00616: "Hinweise",
    detailDepot_00617: "Kann nicht leer sein",
    detailDepot_00618: "Ein parkschild unterstützt nur zahlen, buchstaben und unterstriche.",
    // 流量开放+政府对接
    detailDepot_00619: "Hier wird nur der staatliche Verkehr angezeigt, mit dem der Betreiber bereits verbunden ist. Für den Zugang zu weiteren Verkehrsteilnehmern wenden Sie sich bitte an die Plattform. Weitere Anweisungen für den Zugang finden Sie unter:",
    detailDepot_00620: "Regierungsplattform",
    detailDepot_00621: "Hier wird nur freier Verkehr und bereits von Betreibern angeschlossener Verkehr angezeigt, bitte kontaktieren Sie die Plattform, wenn Sie Zugang zu weiteren Verkehrsteilnehmern benötigen. Weitere Informationen zum Zugang finden Sie unter:",
    detailDepot_00622: "Flusszentrum",
    detailDepot_00623: "Kostenfrei",
    detailDepot_00624: "Regierung",
    detailDepot_00625: "Direkte Verbingdung",
    detailDepot_00626: "XCharge-Gesamtepaket",
    detailDepot_00627: "Dieser Flussteilnehmer ist bereits mit dem SmartCharge QR-Code kompatibel und muss in den Ladesäulendetails auf einen festen QR-Code umgestellt werden",
    detailDepot_00628: "Dieser Flulssteilnehmer ist nicht mit dem SmartCharge QR-Code kompatibel, Sie müssen den QR-Code ausdrucken und einfügen",
    detailDepot_00629: "Massweise-Export QR-Code",
    detailDepot_00630: "arifeinstellungen",
    detailDepot_00631: "Ladestationsrabatt",
    detailDepot_00632: "Start/Stop-Einstellungen",
    detailDepot_00633: "schließen",
    detailDepot_00634: "genehmigen",
    detailDepot_00635: "geöffnet-nicht Start/Stopp",
    detailDepot_00636: "geöffnet-Start/Stopp",
    detailDepot_00637: "nicht geöffnet",
    detailDepot_00638: "geöffnet",
    detailDepot_00639: "Es gibt keine aktivierten Ladesäulen an der Station, bitte aktivieren Sie mindestens eine Ladesäule.",
    detailDepot_00640: "Ladestationsinformationen",
    detailDepot_00641: "Ländercode、",
    detailDepot_00642: "Provinz- und Stadtcode、",
    detailDepot_00643: "Parkgebühr、",
    detailDepot_00644: "Ladestationstypen、",
    detailDepot_00645: "Baustelle、",
    detailDepot_00646: "Anzahl der Parkplätze、",
    detailDepot_00647: "Telefonnummer des Kundendienstes、",
    detailDepot_00648: "Tatsächliches Bild、",
    detailDepot_00649: "leer",
    detailDepot_00650: "Bitte setzen Sie 'ob Offen' auf 'Offen' in [Ladestation-Details] - [Ladestation-Info] - [Status Info]",
    detailDepot_00651: "Ladestation",
    detailDepot_00652: "XCHARGE",
    // *******
    diversionDetail_00021: 'Aktion',
    diversionDetail_00011: 'CPO',
    diversionDetail_00060: 'Flussplattform eines Dritten',
    diversionDetail_00012: 'Ladestationsstatus',
    diversionDetail_00013: 'Aktiviert',
    diversionDetail_00014: 'Nicht aktiviert',
    diversionDetail_00068: 'Ja',
    diversionDetail_00069: 'Nein',
    diversionDetail_00072: 'Rückkehr',
    diversionDetail_00073: 'Erfolgreich geändert',
    diversionDetail_00074: 'Bitte geben Sie ein Nummer ein',
    diversionDetail_00075: 'Vom CPO für XCharge gewährter Rabatt auf die Servicegebühr (%)',
    diversionDetail_00076: 'Keine Ermäßigung auf die Gesamtkosten einer einzelnen Gebühr (%)',
    diversionDetail_00077: '(%) Rabatt für einmalige Servicegebühren',
    diversionDetail_00078: '85 bedeutet 85% Rabatt, 0 bedeutet völlig kostenlos',
    diversionDetail_00079: 'Rabatt pro kWh für eine einmalige Laden',
    diversionDetail_00080: 'Anzeigename',
    diversionDetail_00081: 'Bitte geben Sie den Anzeigenamen ein, empfohlenes Format: Markenname (Ladestationsname), das Schlüsselwort "Markenname" muss aus dem Ladestationsname entfernt werden',
    diversionDetail_00082: 'Ob Ladestation von Drittanbietern starten oder stopen dürfen.',
    diversionDetail_00083: 'Nur bestimmten Nutzern das Laden erlauben',
    diversionDetail_00084: 'Noch einmal nachdenken',
    diversionDetail_00085: 'Ich habe es verstanden',
    diversionDetail_00086: 'Füllen Sie aus',
    diversionDetail_00087: 'Name kann nicht leer sein',
    diversionDetail_00088: 'Erfolgreich öffnen',
    diversionDetail_00089: 'Hinweise',
    diversionDetail_00090: 'Eingabe',
    diversionDetail_00091: 'OK',
    diversionDetail_00092: 'Ob Ladestation von Drittanbietern starten oder stopen dürfen.',
    diversionDetail_00093: 'direkt Tarifeinstellung unterzeichen',
    diversionDetail_00094: 'Wenn der Tarif geändert wurde, klicken Sie auf die Schaltfläche "Push Tariff" in Site Details - Tariff, um den Tarif wirksam zu machen! ',
    diversionDetail_00095: 'AC-Tarif',
    diversionDetail_00096: 'AC-Tarif wählen',
    diversionDetail_00097: 'DC-Tariff',
    diversionDetail_00098: 'Gleichstromtarif wählen',
    diversionDetail_00099: 'Einstellungen für Ladestationrabatte',
    diversionDetail_00100: 'Rabatt hinzufügen',
    diversionDetail_00101: 'Startzeit',
    diversionDetail_00102: 'Jetzt',
    diversionDetail_00103: 'Endzeit',
    diversionDetail_00104: 'unbegrenzt',
    diversionDetail_00105: 'Angebotsmethode',
    diversionDetail_00106: '85 bedeutet 85% Rabatt',
    diversionDetail_00107: 'Rabatt pro kWh (Yuan)',
    diversionDetail_00108: 'Verwenden Sie die Methode des Nebenkostenabschlags',
    diversionDetail_00109: 'Verwendung der Rabattmethode pro kWh',
    diversionDetail_00110: 'Status',
    diversionDetail_00111: 'nicht gestartet',
    diversionDetail_00112: 'gestarte',
    diversionDetail_00113: 'in Wirkung',
    diversionDetail_00114: 'nicht in Wirkung',
    diversionDetail_00115: 'Geschichte der Rabatte',
    diversionDetail_00116: 'Bitte speichern Sie die vorherige Aktion!',
    diversionDetail_00117: 'Startzeit kann nicht leer sein',
    diversionDetail_00118: 'Endzeit darf nicht leer sein',
    diversionDetail_00119: 'Angebotemethode darf nicht leer sein',
    diversionDetail_00120: 'Bitte geben Sie eine positive ganze Zahl kleiner als 100 ein',
    diversionDetail_00121: 'Erfolgreich gespeichert',
    diversionDetail_00122: 'Ermäßigung der Dienstleistungsgebühr: {dis}%',
    diversionDetail_00123: 'Rabatt pro kWh:{dis}Yuan',
    diversionDetail_00124: 'Ablehnen Ladestationsinformation{name} zu öffenen, {name} wird kein Info gezeigt.',
    diversionDetail_00125: 'Bestätigen Sie {name} Ladestationsinformation zu schließen',
    diversionDetail_10126: 'Die tanks sind voll',
    diversionDetail_10127: 'Versuche es noch einmal. Die tanks sind nicht an.',
    diversionDetail_10128: 'Maschinen in betrieb!',
    // *******
    // 设备管理--设备列表
    diversionDetail_00126: 'Gerätseriennummer',
    diversionDetail_00127: 'Gerätetyp',
    diversionDetail_00128: 'Gerätestatus',
    diversionDetail_00129: 'verfügbar',
    diversionDetail_00130: 'Nur gesteckte sehen',
    diversionDetail_00131: 'Im Einsatz',
    diversionDetail_00132: 'Nur gezogene sehen',
    diversionDetail_00133: 'Ladestation Selbstaktivierung',
    diversionDetail_00134: 'Gerät hinzufügen',
    diversionDetail_00135: 'Seriennummer des Geräts:',
    diversionDetail_00136: 'Hersteller - Modell:',
    diversionDetail_00137: 'Gerätedetails',
    diversionDetail_00138: 'Ladepunkte：',
    diversionDetail_00139: 'Zu bewegendes Fahrzeug',
    diversionDetail_00140: 'Termin in Bearbeitung',
    diversionDetail_00141: 'gesteckte',
    diversionDetail_00142: 'zu ziehende',
    diversionDetail_00143: 'Vorgänge anzeigen',
    diversionDetail_00144: 'Ladesäule selbsttätig aktivieren',
    diversionDetail_00145: 'Nachdem Sie die nachstehenden Schritte zur Selbstaktivierung der Ladesäule ausgeführt haben, können Sie die Ladesäule zur Ladestation hinzufügen',
    diversionDetail_00146: 'Schritt 1',
    diversionDetail_00147: 'Benutzen Sie Ihr Mobiltelefon WeChat, um den QR-Code auf der linken Seite zu scannen',
    diversionDetail_00148: 'Warmer Tipp: Sie können die Seite in Ihrer WeChat-Sammlung speichern, so dass Sie sie jederzeit problemlos öffnen können',
    diversionDetail_00149: 'Schritt 2',
    diversionDetail_00150: 'Geben Sie die SN der Ladesäule auf dem Mobiltelefon ein, leiten Sie die Aktivierung ein und bestätigen Sie sie an der Ladesäule.',
    diversionDetail_00151: 'Geben Sie die vollständige Seriennummer des Geräts ein',
    diversionDetail_00152: 'Suche des Ladesäule anhand der Gerätenummer',
    diversionDetail_00153: 'Dieses Gerät wurde hinzugefügt, wenn Sie es weiter hinzufügen, wird es an die aktuelle Ladestation übertragen und muss vor dem Aufladen neu gestartet werden',
    diversionDetail_00154: '(z. B. AC 1)',
    diversionDetail_00155: 'Legen Sie den Namen des Geräts im Feld fest',
    diversionDetail_00156: 'Gerät erfolgreich hinzugefügt',
    diversionDetail_00157: "Unter 'Ladestationsverwaltung' 'Geräte verwalten' 'Gerätedetails' können Sie Folgendes tun",
    diversionDetail_00158: '1. den Gerätenamen zu ändern',
    diversionDetail_00159: '2. den Status ändern, ob das Gerät aktiviert ist oder nicht',
    diversionDetail_00160: '3. einstellen, ob die elektromagnetische Sperre und das Radar aktiviert sind;',
    diversionDetail_00161: 'Dieses Gerät wurde der aktuellen Station hinzugefügt',
    diversionDetail_00162: 'Bitte geben Sie die vollständige Seriennummer des Geräts ein',
    diversionDetail_00163: 'Bitte vervollständigen Sie die Geräteinformationen',
    diversionDetail_00164: 'Normal',
    diversionDetail_00165: 'Das Auto abschließen',
    diversionDetail_00166: 'Ruhend',
    diversionDetail_00167: 'Unbekannt',
    diversionDetail_00168: 'einstecken',
    diversionDetail_00169: 'Ende der Ladung',
    diversionDetail_00170: 'Nur nicht zurückgegebene anzeigen',
    diversionDetail_00171: 'zurückgegebene',
    diversionDetail_00172: 'nicht zurückgegebene',
    // POS机支付设置
    diversionDetail_00173: 'POS-Zahlungseinstellungen',
    diversionDetail_00174: 'Push POS Zahlungseinstellungen',
    diversionDetail_00175: 'Diese Konfiguration wird nur für Ladesäulen mit POS unterstützt',
    diversionDetail_00176: 'Abzugsbetrag',
    diversionDetail_00177: 'Aktueller Währungstyp der Ladestation',
    diversionDetail_00178: 'bestätigen zu push',
    diversionDetail_00179: 'Aktueller Währungstyp der Ladestation',
    diversionDetail_00180: 'Liste der Ladestationen mit POS in diese Ladestation',
    diversionDetail_00181: 'POS-Zahlungsstatus',
    diversionDetail_00182: 'Alle',
    diversionDetail_00183: 'Seriennummer',
    diversionDetail_00184: 'Hersteller - Modell',
    diversionDetail_00185: 'POS-Währungstyp',
    diversionDetail_00186: 'Bitte wählen Sie ein Gerät',
    diversionDetail_00187: 'Push erfolgreich',
    diversionDetail_00188: 'Warten auf Antwort',
    diversionDetail_00189: 'POS-Währungstyp an Ladesäule stimmt nicht mit den Plattformeinstellungen überein, bitte überprüfen',
    diversionDetail_00190: 'POS-Währungstyp ist widersprüchlich {list}, schlagen Sie vor, die falsche Ladesäule offline zu reparieren. Die Ladesäule mit einem widersprüchliche Währungstyp {default} können keine POS-Zahlungen aktivieren. Bitte überprüfen Sie den Währungstyp der Ladesäule oder ändern Sie den Währungstyp des Website-Tarifs.',
    diversionDetail_00191: 'Währung verwenden',
    diversionDetail_00192: 'Die Ladestation muss eine Tarifart von {type} auswählen, eine solche Tarifvorlage existiert derzeit nicht, bitte erstellen Sie einen neuen Tarif.',
    diversionDetail_00193: 'und',
    diversionDetail_00194: 'Bitte geben Sie den richtige Abzugsbetrag ein',
    diversionDetail_00195: 'Hinweise',
    diversionDetail_00196: 'Tarif ändern',
    diversionDetail_00197: 'Der aktuelle Rechnungswährungstyp unterscheidet sich vom Währungstyp des öffentlichen Tarifs',
    diversionDetail_00198: 'Bitte wählen Sie erneut den Tarif mit Währungstyp {type}',
    diversionDetail_00199: 'Abrechnungswährungstyp',
    diversionDetail_00200: 'Der aktuelle Ladestationswährungstyp unterstützt derzeit nicht das Hinzufügen von Gruppentarifen und Angeboten.',
    diversionDetail_00201: 'Nachdem Sie den Währungstyp der Ladestation geändert haben, ändern Sie bitte den öffentlichen Tarif und den Gruppentarif auf denselben Währungstyp und push Sie den Tarif erneut',

    /* 公交充电 */
    detailDepot_00411: "Geben Sie 0 für unbeschränkt ein",
    detailDepot_00412: "Einstellung der Basisinformationen",
    detailDepot_00413: "Begrenzung der Gesamtladeleistung für die gesamte Station (KW)",
    detailDepot_00414: "Einstellung der Basisinformationen",
    detailDepot_00415: "0 bedeutet keine Begrenzung der Gesamtladeleistung",
    detailDepot_00416: "Aktivieren oder nicht",
    detailDepot_00417: "Bitte geben Sie den richtigen Wert ein",
    detailDepot_00418: "Bus",
    detailDepot_00419: "Fahrplan",
    detailDepot_00420: "Grenzleistung",
    detailDepot_00421: "Fahrzeugnummer",
    detailDepot_00422: "Fahrzeug-MAC",
    detailDepot_00423: "Fahrzeug-VIN",
    detailDepot_00424: "Gesamtkapazität der Fahrzeugbatterie (kWh)",
    detailDepot_00425: "Fahrzeug eingeben",
    detailDepot_00426: "Importfahrzeug",
    detailDepot_00427: "Importvorlage herunterladen",
    detailDepot_00428: "Erzeugen",
    detailDepot_00429: "Kann nicht leer sein",
    detailDepot_00430: "Für Gesamtkapazität der Fahrzeugbatterie sind nur zwei Nachkommastellen zulässig",
    detailDepot_00431: "Fahrzeug-MAC-Parameter ist illegal",
    detailDepot_00432: "Formular herunterladen",
    detailDepot_00433: "Bestätigen Sie, dass Sie dieses Fahrzeug (Fahrzeugnummer: {num}) löschen möchten",
    detailDepot_00434: "Optional",
    detailDepot_00435: "Gesamtleistung (KW) in der Gruppe begrenzen",
    detailDepot_00436: "Bitte füllen Sie alle Pflichtfelder aus!",
    detailDepot_00437: "Bitte geben Sie den richtigen Wert ein",
    detailDepot_00438: "Leistungverteilungsgruppe ändern",
    detailDepot_00439: "Echtzeit-Betrieb anzeigen",
    detailDepot_00440: "einführen",
    detailDepot_00441: "Fahrzeug - Vorschlage von Ladeplatz",
    detailDepot_00442: "Herunterladen",
    detailDepot_00443: "Gültigkeitszeit",
    detailDepot_00444: "Derzeitige Verwendung",
    detailDepot_00445: "Kürzlich geplante Verwendung",
    detailDepot_00446: "Fahrlinie",
    detailDepot_00447: "Fahrplan",
    detailDepot_00448: "Abfahrtszeit",
    detailDepot_00449: "Ankunftszeit",
    detailDepot_00450: "Fahrzeug-Code",
    detailDepot_00451: "Erforderlicher Mindest-SOC(%) bei Abfahrt ",
    detailDepot_00452: "Bitte Datum auswählen",
    detailDepot_00453: "Mit sofortiger Wirkung",
    detailDepot_00454: "Fahrplan umstellen",
    detailDepot_00455: "Bestätigen Sie den Tourenplan zu löschen?",
    detailDepot_00456: "Klicken hochzuladen",
    detailDepot_00457: "Datei importieren",
    detailDepot_00458: "Fahrzeugen importieren",
    detailDepot_00459: "",
    detailDepot_00460: "EV MAC2(If the vehicle has a second MAC, please enter it here)",
    detailDepot_00461: "EV MAC2",
    detailDepot_00462: "",
    detailDepot_00463: "",
    detailDepot_00464: "",
    detailDepot_00465: "",
    detailDepot_00466: "",
    detailDepot_00467: "",

    // 场站信息
    detailDepot_10001: "Adresse der Ladestation",
    detailDepot_10002: "Kartenstandort",
    detailDepot_10003: "Klicken Sie auf der rechten Knopf, um den Standort zu bekommen",
    detailDepot_10004: "Standort zu ändern",
    detailDepot_10005: "Stadt- und Provinzcode",
    detailDepot_10006: "Ländercode",
    detailDepot_10007: "Unterstützte Modelle",
    detailDepot_10008: "Ladestationstyp",
    detailDepot_10009: "Nummer des Kundendienstes",
    detailDepot_10010: "Ladestationskontakt",
    detailDepot_10011: "Handynummer",
    detailDepot_10012: "E-Mail",
    detailDepot_10013: "Baustelle",
    detailDepot_10014: "Standort des Parkplatzes",
    detailDepot_10015: "Anzahl der Parkplätze",
    detailDepot_10016: "Parkgebühren",
    detailDepot_10017: "Tatsächliches Bild",
    detailDepot_10018: "Klicken Sie, ein Bild hochzuladen",
    detailDepot_10019: "Es werden nur Bilder im Format PNG/JPG/GIG unterstützt",
    detailDepot_10020: "Nicht mehr als 1M",
    detailDepot_10021: "Standortermittelung fehlgeschlagen",
    detailDepot_10022: "Schließen",
    detailDepot_10023: "suchen",
    detailDepot_10024: "Suchergebnisse",
    detailDepot_10025: "Ziehen Sie die Datei hierher, oder ",
    detailDepot_10026: "zum Hochladen klicken",
    detailDepot_10027: "Bitte geben Sie den Stadtcode ein",
    detailDepot_10028: "Falsches Format des Gemeindecodes (6 Zeichen)",
    detailDepot_11026: "ID",
    detailDepot_21026: "Check deine position",
    // 设备管理
    detailDepot_10029: "Geräteliste",
    detailDepot_10030: "Gerätekonfiguration",
    detailDepot_10031: "Bildschirmkonfiguration",
    detailDepot_10032: "Whitelist-Konfiguration",
    detailDepot_10033: "Nach jeder Änderung muss Konfigurationsinformation gepusht werden, um den ordnungsgemäßen Betrieb der Ladesäule sicherzustellen",
    detailDepot_10034: "Push-Konfiguration",
    detailDepot_10035: "Lokale Infomationseinstellung der Ladesäule",
    detailDepot_10036: "speichern",
    detailDepot_10037: "ändern",
    detailDepot_10038: "Funktionsinformation-Konfiguration",
    detailDepot_10039: "Kompensationsfaktion-Einstellung",
    detailDepot_10040: "Verzögerungsgebühr-Einstellung",
    detailDepot_10041: "Beschränkung",
    detailDepot_10042: "Kein Beschränkung",
    detailDepot_10043: "Erfolg",
    detailDepot_10044: "Fehlgeschlagen",
    detailDepot_10045: "In Push",
    detailDepot_10046: "Die Bildschirmkonfiguration umfasst Bilder, Texte und Anzeigen auf dem Ladesäulenbildschirm, die je nach Gerät und Bildschirmgröße eingestellt werden",
    detailDepot_10047: "Whitelist-Einstellungen",
    detailDepot_10048: "Wenn das Gerät offline ist, werden nur Benutzer in der Whitelist unterstützt, Laden zu starten.",
    detailDepot_10049: "Push-Whitelist",
    detailDepot_10050: "Whitelist löschen",
    detailDepot_10051: "Whitelist anzeigen",
    // 资费设置
    detailDepot_10052: "Öffentlicher Tarif",
    detailDepot_10053: "Gruppentarife",
    detailDepot_10054: "Bitte pushen Sie den eingestellten Tarif an die Ladesäule, damit die Ladesäule die Gebühr zum neuen Tarif in Rechnung stellen kann.Achtung: unbeabsichtigte handlungen können ein versagen der organisation zur folge haben, indem sie die operation nicht stützen",
    detailDepot_10055: "Push-Tarif",
    detailDepot_10056: "Bitte ändern Sie zuerst den Standardtarif.",
    detailDepot_10057: "Es gibt keine Geräte unter dieser Ladestation zu pushen",
    detailDepot_10058: "Push-fehlgeschlagen",
    detailDepot_10059: "Löschen abgebrochen",
    detailDepot_10060: "Mit der Aktion wird die Gruppe aus der Liste entfernen, möchten Sie fortfahren?",
    detailDepot_10061: "Hinweise",
    detailDepot_10062: "Gruppeliste",
    detailDepot_10063: "Gruppe hinzufügen",
    detailDepot_10064: "Tarif neu erstellen",
    detailDepot_10065: "Gruppe auswählen",
    detailDepot_10066: "Bitte geben Sie einen Gruppename ein",
    detailDepot_10067: "Zahl der anwender",
    detailDepot_10068: "Tarif",
    detailDepot_10069: "Bitte wählen Sie einen Tarif",
    detailDepot_10070: "Rabatt auswählen",
    detailDepot_10071: "Kein Rabatt bei einmaliger Laden",
    detailDepot_10072: "(%) Rabatt auf einmalige Servicegebühr",
    detailDepot_10073: "Beispiel: Wenn Sie die einmalige Servicegebühr so einstellen, dass der 15% Rabatt von 20EUR Servicegebühr eingehalten wird, kostet die Gebühr dieses Mal 100EUR (einschließlich 70EUR Stromgebühr und 30EUR Servicegebühr), dann beträgt der tatsächlich zu zahlende Betrag dieses Mal 95,5 EUR (70+30*85%=95,5)",
    detailDepot_10074: "85% bedeutet 85% Rabatt, 0 bedeutet kostenlos",
    detailDepot_10075: "(%)Diskont der Gesamtkosten der einzelnen Kosten",

    detailDepot_10076: "Beispiel: Wenn Sie die Gesamtkosten für eine einzelne Gebühr so festlegen, dass sie den Gesamtkosten von 60EUR und 15 % Rabatt entsprechen, und die Gesamtkosten für diese Gebühr 100EUR betragen, beträgt der tatsächlich zu zahlende Betrag diesmal 85EUR (100*85%=85)",
    detailDepot_10077: "Gruppe erfolgreich hinzugefügt",
    detailDepot_10078: "Klicken Sie im Tarif auf die Taste [ push Tarif ], damit der Tarif wirksam ist!",
    detailDepot_10079: "Sie können Folgendes in [ Ladestationsverwaltung Ladestationseinzelheit Ladestationsinformation ] tun:",
    detailDepot_10080: "1. hinzugefügte Gruppen entfernen",
    detailDepot_10081: "2. eine neue Gruppe hinzufügen",
    detailDepot_10082: "zurück",
    detailDepot_10083: "nächste",
    detailDepot_10084: "Bitte wählen Sie eine Gruppe aus",
    detailDepot_10085: "Bitte wählen Sie einen Tarif aus",
    detailDepot_10086: "Benutzergruppen können nicht wiederholt hinzugefügt werden",
    detailDepot_10087: "Bitte stellen Sie die Rabattparameter ein",
    detailDepot_10088: "Bitte geben Sie eine positive ganze Zahl kleiner als 100 ein",
    detailDepot_10089: "Tarif und Rabatt auswählen",
    detailDepot_10090: "Kein Rabatt auf die Gesamtkosten für einzige Erfolg ",
    detailDepot_10092: "Belegungsgebührenfreier Zeitraum",
    detailDepot_10093: "Keine Belegungsgebührenbefreiung",
    detailDepot_10094: "Mit Belegungsgebührenfreier Zeitraum",
    detailDepot_10095: "Das Zeitfenster ist lokal, in dem sich die Ladestation befindet, es können bis zu 5 Zeitfenster hinzugefügt werden",
    detailDepot_10096: "Hier können Sie belegungsgebührenfreier Zeitraum nach den Bedürfnissen einstellen",
    detailDepot_10097: "Die Zeitfenster überschneiden sich!",
    detailDepot_10098: "Bitte füllen Sie Belegungsgebührenfreier Zeitraum aus",
    detailDepot_10099: "Die Endzeit muss später als die Anfangszeit sein",
    detailDepot_10100: "Zeitfenster hinzufügen",
    detailDepot_10101: "abnormal",
    detailDepot_10102: "Nach dem Durchschnittswert von 30 Tagen kann der aktuelle Saldo {num} Tage dauern",
    detailDepot_10103: "benutzerdefinierte Zeit ist die gleiche wie die vorherige Option, bitte wählen Sie diese Option direkt oder füllen Sie die benutzerdefinierte Zeit erneut aus",
    //储能
    detailDepot_10104: "Der Speicher darf aufladen",
    detailDepot_10105: "Das Auto Auto system",
    detailDepot_10106: "Das echo der schwiegerwirtschaft",
    detailDepot_10107: "Max Modus",
    detailDepot_10108: "Füllen sie den laderaum mit einer laderungseinheit aus, um die laderungslücke zu überbrücken.",
    detailDepot_10109: "Gemeinsam wird der preis für energie und strom sinkt.",
    detailDepot_10110: "Das system gleicht kraft und preis aus.",
    detailDepot_10111: "Mehr Wissen.",
    detailDepot_10112: "Modus zur speicherung Von wasserspeicher und klimaanlage",
    detailDepot_10123: "Modelle der wirtschaft",
    detailDepot_10124: "Der effiziente modus",
    detailDepot_10125: "Zur regulierung der wasserspeicherung",
    detailDepot_10126: "Für die neue speicherung",
    detailDepot_10127: "Gebühren zum aufladen.",

    detailDepot_10128: "Speicher",
    detailDepot_10129: "Der gipfel des tal entspricht dem preis",
    detailDepot_10130: 'Strom für das stromnetz({type}/{unit})',
    detailDepot_10131: 'Schritt für schritt',
    detailDepot_10132: 'Ich meine als nächstes',
    detailDepot_10133: 'Bitte wählen sie den preis für die veranstaltung des aszendenz aus',
    detailDepot_10134: 'Tipp',
    detailDepot_10135: 'Spitze',
    detailDepot_10136: 'flach',
    detailDepot_10137: 'Tal',
    detailDepot_10138: 'Das nennt man gewöhnlich die spitze',
    //配置检查
    detailDepot_10139: '1. Check the status of the device hardware, which takes tens of seconds',
    detailDepot_10140: '2. This check requires that the firmware version number of the Android application for the charger be above 3.0.0.',
    detailDepot_10141: '3. This check is not supported by third-party devices.',
    detailDepot_10142: "Start inspection",
    detailDepot_10143: "Please wait during self-inspection",
    detailDepot_10144: "Re-inspection",
    detailDepot_10145: "Inspect time",
    detailDepot_10146: "Status inspection",
    detailDepot_10147: "Hardware lifetime",
    detailDepot_10148: "Self-inspection items",
    detailDepot_10149: "Confirm and recover from emergency stop",
    detailDepot_10150: "The charger is in use, self-inspection is canceled, please operate after it is idle",
    detailDepot_10151: "The current charger is offline",
    detailDepot_10152: "Charger self-inspection timed out, please try again",
    detailDepot_10153: "Poorer",
    detailDepot_10154: "moderate",
    detailDepot_10155: "better",
    detailDepot_10156: "This inspection requires that the firmware version number of the Android application for the charger be 3.0.0 or higher",
    detailDepot_10157: "Last self-inspection result not found",
    detailDepot_10158: "Recovery immediately",
    detailDepot_10159: "Check the charger first, then consider recovery",
    detailDepot_10160: "Disclaimer",
    detailDepot_10161: "To ensure the safe operation of the charger, we recommend that you perform a hardware check before restoring the emergency stop button. If any hardware failure or safety hazard is found, please stop using the charger and contact our technical support team. We will provide a solution as soon as possible to ensure that your charger can operate safely and stably.",
    detailDepot_10162: "Please note that this disclaimer is intended to remind you that choosing to directly restore the emergency stop button without first determining the status of the charger may involve certain risks, and we will not be held responsible for any resulting consequences.",
    detailDepot_10163: "Confirm direct recovery",
    detailDepot_10164: "The following device configurations are inconsistent",
    detailDepot_10165: "The following device configurations are consistent",
    detailDepot_10166: "No device supporting inspection was found in the site",
    detailDepot_10167: "Response timed out, please try again",
    detailDepot_10168: "Configuration check",
    // add
    accountManage_00002: "Kontonummer",
    accountManage_00003: "Zu dieser zeit",
    accountManage_00004: "Das handy",
    accountManage_00005: "Und rollen",
    accountManage_00006: "Fügen sie das konto hinzu",
    accountManage_00007: "betrieb",
    accountManage_00008: "Stellen sie die verwaltungsfläche ein",
    accountManage_00009: "Givorgang hinzufügen",
    accountManage_000010: "Kontoliste. - gut",
    accountManage_000011: "Kontonummer bearbeiten",
    accountManage_000012: "Loggen sie sich in das account der aktiven plattform ein",
    accountManage_000013: "kennwort",
    accountManage_000014: "Codes für das passwort",
    accountManage_000015: "Du unterstützt buchstaben und zahlen, höchstens 20 wörter",
    accountManage_000016: "Zu dieser zeit",
    accountManage_000017: "F) mit hilfe Von real - namen kannst du später ausfindig machen",
    accountManage_000018: "Das handy",
    accountManage_000019: "Als kontaktperson zu den behörden",
    accountManage_000020: "Und rollen",
    accountManage_000021: "Die bibliothekarin des lagerhauses.",
    accountManage_000022: "Wählen sie aus.",
    accountManage_000023: "Wählen sie die rolle für die hauptkundenrolle",
    accountManage_000024: "Operation erfolgreich.",
    accountManage_000025: "Bitte geben sie den namen des füllwerks ein",
    accountManage_000026: "Der einsatz Von ladestationen funktioniert",
    accountManage_000027: "Los geht's, schaltstation",
    accountManage_000028: "Löschen erfolgreich.",
    accountManage_000029: "Der superfreak?",
    accountManage_000030: "Soll ich sie wirklich entfernen?",
    accountManage_000031: "Abbrechen - löschen",
    accountManage_000032: "Zeit hinzufügen",
    accountManage_000033: "Briefkasten",
    detailDepot_11051: 'Die maximale anzahl der ladeeingänge auf der plattform hat die obergrenze erreicht: geben sie die anzahl der ladeeingänge auf der seite "funktionierende plattform - eröffnung" weiter',
    detailDepot_11052: "energieseiten",
    detailDepot_11152: "Sitzung abgebrochen. Die summe der Max [en] ladefäden aller art innerhalb der gruppe muss kleiner sein als der minimale wert, der der maximale ladeleistung innerhalb der gruppe entspricht",
    detailDepot_11053: "Energiespeicherschrank",
    //百度翻译 小橘
    detailDepot_20001: "Business trends",
    detailDepot_20002: "Revenue Analysis",
    detailDepot_20003: "Charging capacity analysis",
    detailDepot_20004: "Efficiency Analysis",
    detailDepot_20005: "User analysis",
    detailDepot_20006: "Analysis",
    detailDepot_20007: "Total Revenue Trends",
    detailDepot_20008: "Service charge (yuan)",
    detailDepot_20009: "Electricity charge (yuan)",
    detailDepot_20010: "Income (yuan)",
    detailDepot_20011: "Proportion of revenue from users",
    detailDepot_20012: "Proportion of third-party traffic",
    detailDepot_20013: "Revenue trends from users",
    detailDepot_20014: "Third party traffic revenue trends",
    detailDepot_20015: "Trend of electricity utilization rate",
    detailDepot_20016: "On time long utilization trend",
    detailDepot_20017: "Utilization rate=daily charge/daily full load charge<br/>daily full load charge=rated power of charging pile * 24 hours",
    detailDepot_20018: "Utilization rate=daily charging time of charging gun/24 hours",
    detailDepot_20019: "AC equipment charging utilization rate (%)",
    detailDepot_20020: "Utilization rate of DC equipment charging (%)",
    detailDepot_20021: "Charging capacity (degree)",
    detailDepot_20022: "Charging capacity trend",
    detailDepot_20023: "Proportion of user source charging",
    detailDepot_20024: "Trend of user source charging capacity",
    detailDepot_20025: "Third party flow charging amount trend",
    detailDepot_20026: "Trend of users",
    detailDepot_20027: "Proportion of charging orders from users",
    detailDepot_20028: "Number of charging users",
    detailDepot_20029: "Number of users charging for the first time",
    detailDepot_20030: "Trend of charging orders from users",
    detailDepot_20031: "Third party flow charging order number trend",
    detailDepot_20032: "Trend of average gun income",
    detailDepot_20033: "Idle fee ({0})",
    detailDepot_20034: "0-24 hour average charging capacity trend",
    detailDepot_20035: "Average charging capacity of gun",
    detailDepot_20036: "Average degree of gun",
    detailDepot_20037: "Average charging capacity of gun",
    detailDepot_20038: "Average single quantity of guns",
    detailDepot_20039: "Number of orders per gun",
    detailDepot_20040: "Single",
    detailDepot_20041: "Number of charging orders",
    detailDepot_20042: "Average service charge for guns ({0})",
    detailDepot_20043: "Average gun electricity charge ({0})",
    detailDepot_20044: "Idle fee per gun ({0})",
    detailDepot_20045: "Utilization rate=daily charge/daily full load charge<br/>daily full load charge=rated power of charging pile * 24 hours",
    detailDepot_20046: "Utilization rate=daily charging time of charging gun/24 hours",
    detailDepot_20047: "Total income ({0})",
    detailDepot_20048: "Total service charge ({0})",
    detailDepot_20049: "Total electricity charge ({0})",
    detailDepot_20050: "Total idle fee ({0})",
    detailDepot_20051: "Total revenue from third-party diversion sources ({0})",
    detailDepot_20052: "Total charge (kWh)",
    detailDepot_20053: "Total self operated&franchisee source charging capacity (degree)",
    detailDepot_20054: "Total charging capacity of third-party diversion source (degree)",
    detailDepot_20055: "Total number of charging users (person)",
    detailDepot_20056: "Total number of charging orders (single)",
    detailDepot_20057: "Total number of first charging orders (single)",
    detailDepot_20058: "Total number of charging orders (orders) of self operated&franchisees",
    detailDepot_20059: "Total third-party diversion charging orders (single)",
    // 能源微网设置
    detailDepot_30000: "Nach jeder Änderung müssen die Konfigurationsinformationen hochgeladen werden, um den normalen Betrieb des Mikronetzwerkhardwaresystems zu gewährleisten. Die hier eingestellten Parameter werden in Chargen an das Mikronetzwerkgerät gesendet, das die Parameter in der Station ausführen kann.",
    detailDepot_30001: "NZS Battery Settings",
    detailDepot_30002: "NZS Battery Lowes Discharge SOC Limit",
    detailDepot_30003: "Dies soll den Stromnetzausfall oder die Überbelastung der Batterie verhindern",
    detailDepot_30004: "NZS Battery Highest Charge SOC Limit",
    detailDepot_30005: "Damit wird verhindert, dass die Batterie durch Batterieverfall überladen wird",
    detailDepot_30006: "Wenn sich NZS-Ladegeräte in der Station befinden, setzen Sie diese Option auf 'Ja', Sie können die Betriebs- und Umsatzänderungen der NZS-Station im 'Energy Center' anzeigen",
    detailDepot_30007: "",
    detailDepot_30010: "Push Settings",
    detailDepot_30011: "Please fill out the form completely",
    detailDepot_30012: "Dual Platform",
    detailDepot_40002: "Ladeeinstellungen für die ladekarte beim abschalten der anlage",
    detailDepot_40003: "Erlaubt allen möglichen",
    detailDepot_40004: "Die weiße liste ist genehmigt",
    detailDepot_40005: "Beim abschalten kommt die ladekarte in den lademodus",
    detailDepot_40006: "Batch Add Devices",
    detailDepot_40007: "Please enter the complete device SN. Supports multiple device SN inputs, with a maximum of 50 inputs.",
    detailDepot_40008: "One device SN per line, please do not enter symbols, letters, spaces, etc. outside of SN.",
    detailDepot_40009: "Please enter the device name",
    detailDepot_40010: "Verification failed",
    detailDepot_40011: "Verification successful",
    detailDepot_40012: "Please check if the device SN is correct",
    detailDepot_40013: "A maximum of 50 can be entered",
    detailDepot_40014: "The device SN has been added by another station",
    detailDepot_40015: "Muzzle limit is exceeded",
    detailDepot_40016: "Please enter the device name",
    detailDepot_40017: "{0}",
    detailDepot_40018: "A total of {0} device SNs were input, with {1} successfully verified and {2} failed",
    detailDepot_40019: "Add Devices",
    detailDepot_40020: "Successfully added {0} devices!",
    detailDepot_51000: "Bitte geben sie die prozentzahl an.",
    detailDepot_51001: "The actual payment amount will not exceed the pre-authorized amount set here, including electricity fee, service fee, idle fee, taxes, etc.",

    //设备列表自检
    eqDetails_30003: "Hardware self-inspection",
    eqDetails_30004: "1. Check the status of the device hardware, which takes tens of seconds",
    eqDetails_30005: "2. This check requires that the firmware version number of the Android application for the charger be above 3.0.0.",
    eqDetails_30006: "3. This check is not supported by third-party devices.",
    eqDetails_30007: "Last inspection result",
    eqDetails_30008: "Start inspection",
    eqDetails_30009: "Please wait during self-inspection",
    eqDetails_30010: "Re-inspection",
    eqDetails_30011: "Inspect time",
    eqDetails_30012: "Status inspection",
    eqDetails_30013: "Hardware lifetime",
    eqDetails_30014: "Self-inspection items",
    eqDetails_30015: "Confirm and recover from emergency stop",
    eqDetails_30016: "The charger is in use, self-inspection is canceled, please operate after it is idle",
    eqDetails_30017: "The current charger is offline",
    eqDetails_30018: "Charger self-inspection timed out, please try again",
    eqDetails_30020: "Poorer",
    eqDetails_30021: "moderate",
    eqDetails_30022: "better",
    eqDetails_30023: "This inspection requires that the firmware version number of the Android application for the charger be 3.0.0 or higher",
    eqDetails_30024: "Last self-inspection result not found",
    eqDetails_30025: "Recovery immediately",
    eqDetails_30026: "Check the charger first, then consider recovery",
    eqDetails_30027: "Disclaimer",
    eqDetails_30028: "To ensure the safe operation of the charger, we recommend that you perform a hardware check before restoring the emergency stop button. If any hardware failure or safety hazard is found, please stop using the charger and contact our technical support team. We will provide a solution as soon as possible to ensure that your charger can operate safely and stably.",
    eqDetails_30029: "Please note that this disclaimer is intended to remind you that choosing to directly restore the emergency stop button without first determining the status of the charger may involve certain risks, and we will not be held responsible for any resulting consequences.",
    eqDetails_30030: "Confirm direct recovery",
    eqDetails_30031: "Self-check items",
    eqDetails_30032: "State",
}