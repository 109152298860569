export default {
    validity: '有效期',
    charging_package2:"卡券销售链接",
    all:"全部",
    copy: '复制',
    charging_package:"卡券销售管理",
    charging_package0:"卡券销售",
    charging_package1:"添加卡券后，勾选上架售卖，可以在[车主端]-[卡券/充电卡券]-[购买卡券]中售卖。",
    charging_package3:"请输入卡券名称",
    charging_package4:"卡券名称",
    charging_package5:"卡券类型",
    charging_package6:"卡券额度",
    charging_package7:"售价（元）",
    charging_package8:"卡券详情",
    charging_package9:"添加卡券销售",
    charging_package10:"卡券额度（元）",
    charging_package11:"卡券售价（元）",
    charging_package12:"用户购买/兑换卡券之日起{count}日",
    charging_package13:"管理兑换码",
    charging_package14:"生成兑换码",
    charging_package15:"兑换码",
    charging_package16:"分发渠道",
    charging_package17:"改成价格（元）",
    charging_package18:"生成数量",
    charging_package19:"兑换次数",
    charging_package20:"兑换人数",
    charging_package21:"购买卡券金额（元）",
    charging_package22:"兑换卡券金额（元）",
    charging_package23:"未消耗电量（度）",
    charging_package24:"已失效电量（度）",
    charging_package25:"已使用电量（度）",
    charging_package26:"用电量抵扣金额（元）",
    charging_package27:"上架售卖",
    charging_package28:"请输入数值",
    charging_package29:"卡券销售详情",
    charging_package30:"统计信息",
    charging_package31:"卡券改价",
    charging_package32:"用户获得详情",
    charging_package33:"支付金额（元）",
    charging_package34:"获得时间",
    charging_package35:"当前价格（元）",
    charging_package36:"改成价格（元）",
    charging_package37:"未兑换",
    charging_package38:"已兑换",
    charging_package39:"已失效",
    charging_package40:"请输入兑换码",
    charging_package41:"失效日期",
    charging_package42:"最多10000",
    charging_package43:"兑换码生成成功！",
    charging_package44:"确认在列表中删除此卡券！",
    charging_package45:"用现金抵用电费及服务费",
    charging_package46:"用电量同时抵用电费及服务费",
    charging_package47:"用现金抵用服务费",
    charging_package48:"用电量抵用服务费",
    charging_package49:"卡券额度（度）",
    charging_package50:"请输入少于16个字的卡券名称",
    charging_package51:"复制成功！",
    charging_package52:"用户购买/兑换卡券之日起{day}日",
    charging_package53:"请完善卡券销售信息",
    charging_package54:"请选择需要添加卡券销售的站点",
    charging_package55:"购买",
    charging_package56:"兑换",
    charging_package57:"永久",
    charging_package58:"已使用现金（元）",
    charging_package59:"未消耗现金（元）",
    charging_package60:"已失效现金（元）",
    charging_package61:"可使用",
    charging_package62:"已用完",
    charging_package63:"待生效",
    charging_package64:"剩余额度",
    charging_package65:"此次优惠",
    charging_package66:"抵用{power}度充电量，优惠{money}元",
    charging_package67:"优惠{money}元",
    charging_package68:"输入格式有误",
    charging_package69:"用户购买/兑换卡券之日起",
    charging_package70:"您确定导出此卡券兑换码？",
    delete_success:"删除成功",

    parameters_is_empty: "参数为空",
    park_placeholder1:"最多支持小数点后两位！",
    modify_success: '修改成功',

    charging_00001: '卡券上架',
    charging_00002: '营销中心',
    charging_00003: '卡券销售',
    charging_00004: '请输入卡券名称',
    charging_00005: '卡券类型',
    charging_00006: '代金券',
    charging_00007: '电量包',
    charging_00008: '重置',
    charging_00009: '查询',
    charging_00010: "添加卡券后，勾选上架售卖，可以在[车主端]-[卡券/充电卡券]-[购买卡券]中售卖。",
    charging_00011: '未定价',
    charging_00012: '操作',
    charging_00013: '定价',
    charging_00014: '卡券详情',
    charging_00015: '定价',
    charging_00016: '可支持两位小数',
    charging_00017: '取消',
    charging_00018: '确定',
    charging_00019: '卡券上架',
    charging_00020: '请输入大于0的数字',
}
