export default {
    validity: 'Gültigkeitsdauer',
    charging_package2:"Gutschein-Verkaufslink",
    all:"alle",
    copy: 'kopieren',
    charging_package:"Karte Verkaufsmanagement",
    charging_package0:"Karteverkauf",
    charging_package1:"Nachdem Sie die Kartengutscheine hinzugefügt haben, ankreuzen Sie zu verkaufen, dann können sie unter [WeChat Öffentliche Konto] - [Personal Zenter] - [Kartengutscheineinkauf] verkaufen.",
    charging_package3:"Bitte geben Sie Kartename ein",
    charging_package4:"Gutscheinname",
    charging_package5:"Gutscheintypen",
    charging_package6:"Gutscheinsumme",
    charging_package7:"Preis(Yuan)",
    charging_package8:"Gutscheineinzelheiten",
    charging_package9:"Gutscheinverkauf hinzufügen",
    charging_package10:"Gutscheinsumme(Yuan)",
    charging_package11:"Gutscheinpreis(Yuan)",
    charging_package12:"ab den Einkaufstag {count}Tagen",
    charging_package13:"Einlösecode verwalten",
    charging_package14:"Einlösecode generieren",
    charging_package15:"Einlösecode",
    charging_package16:"Vertriebskanal",
    charging_package17:"zum Preis(Yuan) geändert",
    charging_package18:"generierte Menge",
    charging_package19:"Anzahl der Einlösung",
    charging_package20:"Anzahl der Einlösenede",
    charging_package21:"Betrag der gekauften Gutscheine(Yuan)",
    charging_package22:"eingelöste Gutscheine(Yuan)",
    charging_package23:"nicht verbrauchte Elektrizität(kWh)",
    charging_package24:"abgelaufte Elektrizität(kWh)",
    charging_package25:"verbrauchte Elektrizität(kWh)",
    charging_package26:"Ermäßtigte Betrag mit Elektrizität(Yuan)",
    charging_package27:"Zum Verkauf",
    charging_package28:"Bitte geben Sie einen Wert ein",
    charging_package29:"Verkaufseinzelheiten der Gutscheine",
    charging_package30:"Statistische Informationen",
    charging_package31:"Gutscheinpreisänderung",
    charging_package32:"Nutzereinzelheit",
    charging_package33:"bezahlte Betrag(Yuan)",
    charging_package34:"Zeit",
    charging_package35:"aktuelle Preis(Yuan)",
    charging_package36:"geänderte Preis(Yuan)",
    charging_package37:"nicht eingelöst",
    charging_package38:"eingelöst",
    charging_package39:"abgelauft",
    charging_package40:"Bitte geben Sie Einlösecode ein",
    charging_package41:"Ablaufsdatum",
    charging_package42:"bis zu 10000",
    charging_package43:"Einlösecode erfolgreich generieren",
    charging_package44:"Bitte bestätigen Sie, diese Gutschein in Liste zu löschen!",
    charging_package45:"Bargeld als Strom und Servicebetrag",
    charging_package46:"Elektrizität als Strom und Servicebetrag",
    charging_package47:"Bargeld als Servicebetrag",
    charging_package48:"Elektrizität als Servicebetrag",
    charging_package49:"Gutscheinsummer(kWh)",
    charging_package50:"Bitte geben Sie einen Gutscheinnamen mit weniger als 16 Zeichen ein",
    charging_package51:"erfolgreich kopiert",
    charging_package52:"ab den Einkaufstag {day} Tagen",
    charging_package53:"Bitte füllen Sie die Verkaufsinformationen aus",
    charging_package54:"Bitte wählen Sie Gutschein hinzuzufügende Ladestationen ein",
    charging_package55:"Einkaufen",
    charging_package56:"Einlösen",
    charging_package57:"Dauerhaft",
    charging_package58:"genutzte Bargeld(Yuan)",
    charging_package59:"nicht verbrauchte Bargeld(Yuan)",
    charging_package60:"abgelaufte Bargeld(Yuan)",
    charging_package61:"verfügbar",
    charging_package62:"verbraucht",
    charging_package63:"zu aktivierende",
    charging_package64:"restliches Guthaben",
    charging_package65:"diese Angebot",
    charging_package66:"Ausgleich für {power}kWh Elektrizität, Ermäßigung von {money}(Yuan)",
    charging_package67:"Rabatt von {money}(Yuan)",
    charging_package68:"Fehler im Eingabeformat",
    charging_package69:"ab den Einkaufstag",
    charging_package70:"Bestätigen Sie diese Einlösecode zu exportieren?",
    delete_success:"Erfolgreich gelöscht",

    parameters_is_empty: "Parameter ist leer",
    park_placeholder1:"Bis zu zwei Dezimalstellen unterstützen!",
    modify_success: 'Erfolgreich geändert',

    charging_00001: 'Im Regal',
    charging_00002: 'Marketing Zentrum',
    charging_00003: 'Gutscheinverkauf',
    charging_00004: 'Bitte geben Sie Gutscheinname ein',
    charging_00005: 'Gutscheintypen',
    charging_00006: 'Gutschein',
    charging_00007: 'Elektrizitätpaket',
    charging_00008: 'zurücksetzen',
    charging_00009: 'suchen',
    charging_00010: 'Nachdem Sie Gutscheine hinzugefügt haben, markieren Sie "Im Regal verkaufen", dann können Sie im Regal verkaufen unter [WeChat Öffentliche Konto] - [Personal Center] - [Ladegutschein] - [Gutscheineinkauf]',
    charging_00011: 'Preis nicht festgesetzt',
    charging_00012: 'Aktion',
    charging_00013: 'Preis festsetzen',
    charging_00014: 'Gutscheineinzelheit',
    charging_00015: 'Preis festsetzen',
    charging_00016: 'zwei Dezimalstellen unterstützen',
    charging_00017: 'abbrechen',
    charging_00018: 'bestätigen',
    charging_00019: 'Im Regal',
    charging_00020: 'Bitte geben Sie eine Zahl größer als 0 ein',
}
