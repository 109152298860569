export default {
  createKnowledge_00001: '新建知识',
  createKnowledge_00002: '知识库',
  createKnowledge_00003: '工单分类',
  createKnowledge_00004: '相关工单',
  createKnowledge_00005: '知识标题',
  createKnowledge_00006: '问题描述关键词',
  createKnowledge_00007: '问题设备品牌型号',
  createKnowledge_00008: '全部厂商设备',
  createKnowledge_00009: '全部型号',
  createKnowledge_00010: '问题描述',
  createKnowledge_00011: '解决方案',
  createKnowledge_00012: '请填写完整',
  createKnowledge_00013: '创建成功',
  createKnowledge_00014: '',
}