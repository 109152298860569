export default {
    configInfo_00001: '智能充电系统',
    configInfo_00002: 'C1（4.3英寸屏幕）充电桩屏幕配置信息',
    configInfo_00003: 'C1（7英寸屏幕）充电桩屏幕配置信息',
    configInfo_00004: '上传图片',
    configInfo_00005: '将文件拖到此处，或|点击上传',
    configInfo_00006: '仅支持PNG格式图片，尺寸{area}px，不超过{size}kb。',
    configInfo_00007: '修改图片',
    configInfo_00008: '您所选的图片过大，请重新选择',
    configInfo_00009: '请选择png格式的图片上传',
    configInfo_00010: '请上传符合{0}px的图片',
    configInfo_00011: '请上传图片',
    configInfo_00012: '修改成功',
    configInfo_00013: '微信扫码 启动充电',
    configInfo_00014: '微信扫描左侧二维码,按提示进行下一步操作',
    configInfo_00015: '不超过24个字',
    configInfo_00016: '唤醒后广告',
    configInfo_00017: '扫码后广告',
    configInfo_00018: '拔枪后广告',
    configInfo_00019: '扫码和拔枪后广告',
    configInfo_00020: '空闲时广告',
    configInfo_00021: '多张图片轮播或者mp4视频方式，二选一',
    configInfo_00022: '使用图片轮播',
    configInfo_00023: '点击上传',
    configInfo_00024: '仅支持PNG、JPG格式图片，尺寸{area}px，不超过{size}kb。',
    configInfo_00025: '使用视频',
    configInfo_00026: '5秒',
    configInfo_00027: '10秒',
    configInfo_00028: '15秒',
    configInfo_00029: '最多上传6张',
    configInfo_00030: '请选择png或jpg格式的图片上传',
    configInfo_00031: '最多上传1个视频',
    configInfo_00032: '您所选的视频过大，请重新选择',
    configInfo_00033: '请上传图片或视频',
    configInfo_00034: '播放时间',
    configInfo_00035: '仅支持mp4格式视频，大小不超过{vsize}MB。（建议分辨率最大{varea}像素，帧率小于30fps。）',
    configInfo_00036: '上传图片或者视频',
    configInfo_00037: '使用图片',
    configInfo_00038: '仅支持PNG、JPG、GIF格式图片，尺寸{area}px，不超过{size}kb。',
    configInfo_00039: '使用视频',
    configInfo_00040: '请选择mp4的视频上传',
    configInfo_00041: '请上传视频',
    configInfo_00042: '删除广告',
    configInfo_00043: '确定将充电站内所有此类型的充电桩上的广告删除吗？',
    configInfo_00044: '删除后将无法恢复, 是否继续?',
    configInfo_00045: '确定',
    configInfo_00046: '取消',
    configInfo_00047: '全选',
    configInfo_00048: '充电桩名称',
    configInfo_00049: '设备编号',

    configInfo_00050: 'X-CHARGE智充',
    configInfo_00051: '欢迎使用智能直流充电系统',
    configInfo_00052: '请使用微信扫码充电或使用NFC刷卡充电',
    configInfo_00053: '使用触摸按键选择充电口',
    configInfo_00054: 'C401充电桩屏幕配置信息',
    configInfo_00055: 'C6充电桩屏幕配置信息',
    configInfo_00056: 'C6s充电桩屏幕配置信息',
    configInfo_00116: 'C9 Pro充电桩屏幕配置信息',
    configInfo_00057: '顶部logo可以使用文字也可以使用图片格式，二选一。',
    configInfo_00058: '使用文字',
    configInfo_00059: '请输入内容',
    configInfo_00060: '{num}个字以内(包括标点符号)',
    configInfo_00061: '请填写顶部界面文字或上传图片',
    configInfo_00062: '顶部界面文字过长',
    configInfo_00063: "请先选择'使用图片'",
    configInfo_00064: "请先保存上一步操作",
    configInfo_00065: "不超过{num}个字",
    configInfo_00066: "不超过10个字",
    configInfo_00067: "参数为空",
    configInfo_00068: "参数输入的文字不能超过7位为空",
    configInfo_00069: "扫码界面标题不能超过10位",
    configInfo_00070: "参数长度过长",
    configInfo_00071: "请扫码充电或使用NFC刷卡充电",
    configInfo_00072: "共推送了{allDevice}台设备，{sucDevice}台成功，{faiDevice}台失败",
    configInfo_00073: "设备名称",
    configInfo_00074: "推送结果",

    // ***********
    configInfo_00075: "场站详情",
    configInfo_00076: "屏幕配置",
    configInfo_00077: "配置信息",
    configInfo_00078: "开机 logo",
    configInfo_00079: "修改",
    configInfo_00080: "顶部 logo",
    configInfo_00081: "界面文字",
    configInfo_00082: "欢迎使用",
    configInfo_00083: "请扫码充电",
    configInfo_00084: "欢迎使用",
    configInfo_00085: "扫码界面标题",
    configInfo_00086: "保存",
    configInfo_00087: "不超过16个字",
    configInfo_00088: "C2充电桩屏幕配置信息",
    configInfo_00089: "扫码界面文字",
    configInfo_00090: "顶部文字",
    configInfo_00091: "欢迎文字标题",
    configInfo_00092: "欢迎文字内容",
    configInfo_00093: "底部提示文字",
    configInfo_00094: "修改顶部logo",
    configInfo_00095: "删除",
    configInfo_00096: "广告信息",
    configInfo_00097: "设备列表",
    configInfo_00098: "编辑配置信息，勾选充电桩，将其推送到充电桩上。建议每次修改后都推送配置信息，确保充电桩正常运行。",
    configInfo_00099: "推送配置",
    configInfo_00100: "推送全部",
    configInfo_00101: "成功",
    configInfo_00102: "失败",
    configInfo_00103: "推送中",
    configInfo_00104: "关闭",
    configInfo_00114: "C1（7英寸屏幕）充电桩屏幕",
    configInfo_00113: "C2充电桩屏幕",
    configInfo_00112: "C401充电桩屏幕",
    configInfo_00111: "C6充电桩屏幕",
    configInfo_00110: "C6s充电桩屏幕",
    configInfo_00117: "C9 Pro充电桩屏幕",
    configInfo_00115: "C1（4.3英寸屏幕）充电桩屏幕",
    modify_success: "修改成功",
}