export default {
  weChatRecord_00001: "充值记录",
  weChatRecord_00002: "充值日期",
  weChatRecord_00003: "充值金额({0})",
  weChatRecord_00004: "本金({0})",
  weChatRecord_00005: "赠送({0})",
  weChatRecord_00006: "充值方式",
  weChatRecord_00007: "操作人",
  weChatRecord_00008: "备注",
  weChatRecord_00009: "(不支持退款)活动充值",
}