export default {
    begin_date_select_remind: "开始日期",
    end_date_select_remind: "结束日期",
    to: "至",
    use_condition:"使用条件",
    fee_at_least:"单次充电总费用满足金额",
    yuan:"元",
    fee_discount:"抵用总费用金额",
    description_info_1:"说明：如设置单次充电总费用满足60元抵用总费用10元，此次充电总费用100元，则此次需实际支付金额90元（100-10=90）。",
    service_fee_at_least:"单次充电服务费满足金额",
    service_fee_discount:"抵用服务费金额",
    description_info_2:"说明：如设置单次充电服务费满足20元抵用服务费10元，此次充电总费用100元(含电费70元、服务费30元），则此次需实际支付金额90元（70+(30-10)=90）。",
    description_info_3:"说明：如设置单次充电总费用满足60元总费用8.5折，此次充电总费用100元，则此次需实际支付金额85元（100*85%=85）。",
    description_info_4:"说明：如设置单次充电服务费满足20元服务费8.5折，此次充电费用100元(含电费70元、服务费30元），则此次需实际支付金额95.5元（70+30*85%=95.5）。",
    percent_discount:"总费用打折",
    percent:"%",
    all_site:"全部站点",
    self_run_site:"自营站点",
    custom_site:"自定义站点",
    add_site:"添加站点",
    site_name: "站点名称",
    operator: "运营商",
    enter_site:"输入站点名、运营商名",
    query: "查询",
    confirm: "确认",
    siteStatus: "站点状态",
    isOpen: "对外开放",
    enable: "启用",
    enabled: "已启用",
    disabled: "未启用",
    open: "开放",
    unopen: "不开放",
    device_type_dc: "直流",
    device_type_ac: "交流",
    chargeEquipment: "充电桩",
    member_00072: '取消',
    cancel: '取消',
    member_00073: '添加',
    ac:"交",
    dc:"直",
    delete_success: "删除成功",
    charging_package44:"您确定删除此信息！",
    operate_canceled: "操作已取消",
    discount_amount_5:"85表示8.5折，0表示全免",
    service_percent_discount:"服务费打折",
    remind: "提示",
    coupon_error_message_1:"请输入少于16个字的优惠券名称",
    coupon_error_message_2:"请完善优惠券信息",
    coupon_error_message_3:"请输入正确的有效期",
    coupon_error_message_4:"开始日期要小于或等于结束日期",
    coupon_error_message_5:"开始日期要大于或等于当前日期",
    coupon_error_message_6:"请输入正确的抵用金额",
    coupon_error_message_7:"请输入正确的折扣数",
    coupon_error_message_8:"请输入正确的金额数",
    coupon_error_message_9:"请完善优惠券信息",
    coupon_error_message_10:"请选择需要添加优惠券的站点",
    common_00241: "添加成功",
    text_rule1: '请输入数字，小数点后最多两位',
    coupon_00001: '营销中心',
    coupon_00002: '优惠工具',
    coupon_00003: '名称',
    coupon_00004: '请输入',
    coupon_00005: '重置',
    coupon_00006: '查询',
    coupon_00007: '列表',
    coupon_00008: '新建',
    coupon_00009: '使用条件',
    coupon_00010: '有效期',
    coupon_00011: '操作',
    coupon_00012: '详情',
    coupon_00013: '抵用券',
    coupon_00014: '折扣券',
    coupon_00015: '修改',
    coupon_00016: '信息',
    coupon_00017: '取消',
    coupon_00018: '保存',
    coupon_00019: '修改',
    coupon_00020: '删除',
    coupon_00021: '相对',
    coupon_00022: '绝对',
    coupon_00023: '用户获取之日起',
    coupon_00024: '请输入自然数',
    coupon_00025: '日',
    coupon_00025_1: '至',
    coupon_00026: '使用条件',
    coupon_00027: '可用站点',
    coupon_00028: '站点名称',
    coupon_00029: '查询',
    coupon_00030: '批量移除',
    coupon_00031: '操作',
    coupon_00032: '移除',
    coupon_00033: '后不可恢复',
}
