export default {
  cardSales_00001: '优惠商城销售订单',
  cardSales_00002: '订单编号',
  cardSales_00003: '请输入用户名/手机号',
  cardSales_00004: '开始日期',
  cardSales_00005: '结束日期',
  cardSales_00006: '至',
  cardSales_00007: '销售额(元)',
  cardSales_00008: '购买次数',
  cardSales_00009: '购买人数',
  cardSales_00010: '订单列表',
  cardSales_00011: '导出订单',
  cardSales_00012: '全部',
  cardSales_00013: '电量包',
  cardSales_00014: '代金券',
  cardSales_00015: '支付金额',
  cardSales_00016: '微信支付',
  cardSales_00017: '(微信公众号)',
  cardSales_00018: '下载订单列表的Excel格式文件？',
  cardSales_00019: '提示',
  cardSales_00020: '开始下载',
  cardSales_00021: '取消下载',
  cardSales_00022: '特权会员',
}