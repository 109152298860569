export default {
    userAllocate_00001: "Group Name",
    userAllocate_00002: "Balance Allocation",
    userAllocate_00003: "Basic Information",
    userAllocate_00004: "User name, card number, mobile number",
    userAllocate_00005: "User Type",
    userAllocate_00006: "All",
    userAllocate_00007: "WeChat user",
    userAllocate_00008: "Online ID card",
    userAllocate_00009: "Query",
    userAllocate_00010: "Reset",
    userAllocate_00011: "Assigned {1}{3} to {0} people, {2}{3} left",
    userAllocate_00012: "OK to assign",
    userAllocate_00013: "Avatar",
    userAllocate_00014: "User (mobile number)",
    userAllocate_00015: "Card number",
    userAllocate_00016: "Type",
    userAllocate_00017: "Account Balance({0})",
    userAllocate_00018: "Allocation amount({0})",
    userAllocate_00019: "List of users",
    userAllocate_00020: "Please enter the allocation amount for user group members!" ,
    userAllocate_00021: "User group account balance is insufficient to allocate!" ,
    userAllocate_00022: "Prompt",
    userAllocate_00023: "Continue allocating",
    userAllocate_00024: "Confirm",
    userAllocate_00025: "",
  }