export default {
  preferentialSite_00001: "优惠站点",
  preferentialSite_00002: "团体名称",
  preferentialSite_00003: "站点名称",
  preferentialSite_00004: "当前资费模板",
  preferentialSite_00005: "直流",
  preferentialSite_00006: "交流",
  preferentialSite_00007: "当前优惠",
  preferentialSite_00008: "操作",
  preferentialSite_00009: "资费优惠详情",
  preferentialSite_00010: "运营商资费",
  preferentialSite_00011: "单次充电总费用不打折",
  preferentialSite_00012: "单次充电服务费不打折",
  preferentialSite_00013: "单次充电总费用{des}折",
  preferentialSite_00014: "单次充电服务费{des}折",
  preferentialSite_00015: "单次充电服务费全免",
  preferentialSite_00016: "单次充电总费用全免",
  preferentialSite_00017: "资费详情",
  preferentialSite_00018: "时段",
  preferentialSite_00019: "充电费(电费+服务费)",
  preferentialSite_00020: "延时费",
}