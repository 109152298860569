export default {
    financingSettings_00001: '开启充值功能后，用户可以在微信公众号中充值，充电后会自动从余额中扣费。',
    financingSettings_00002: "是否启用充值",
    financingSettings_00003: "充值面板键盘设置",
    financingSettings_00004: '编辑',
    financingSettings_00005: '保存',
    financingSettings_00006: '取消',
    financingSettings_00007: '删除',
    financingSettings_00008: '添加',
    financingSettings_00009: '启用申请退款后，用户可以在微信公众号中申请钱包余额退款',
    financingSettings_00010: '是否启用退款',
    financingSettings_00011: '退款方式',
    financingSettings_00012: '全自动退款',
    financingSettings_00013: '半自动退款',
    financingSettings_00014: '手动退款',
    financingSettings_00015: '修改',
    financingSettings_00016: '退款资金来源',
    financingSettings_00017: '未结算资金退款',
    financingSettings_00018: '可用余额退款',
    financingSettings_00019: '退款提示信息',
    financingSettings_00020: '(会显示在微信公众号和小程序的余额退款页)',
    financingSettings_00021: '开启后申请发票入口将在微信公众号中显示',
    financingSettings_00022: '是否启用发票申请入口',
    financingSettings_00023: '未输入最低开票金额或输入有误',
    financingSettings_00024: '最低开票金额',
    financingSettings_00025: '支持的开票方式',
    financingSettings_00026: '纸质发票',
    financingSettings_00027: '电子发票',
    financingSettings_00028: '纸质发票和电子发票',
    financingSettings_00029: '发票提示信息',
    financingSettings_00030: '(会显示在微信公众号的申请发票页)',
    financingSettings_00031: '充值面板行数不能超过4行!',
    financingSettings_00032: '您的面板中存在空值,请输入金额!',
    financingSettings_00033: '面板中金额必须为非零正整数，请重新输入!',
    financingSettings_00034: '面板中金额长度最多5位，请重新输入!',
    financingSettings_00035: '您的面板中存在重复值，请重新输入!',
    financingSettings_00036: '修改成功',
    reserveManagement_00005: '请输入流量名称',
    reserveManagement_00006: '是否启用储备金',
    reserveManagement_00007: '全部',
    reserveManagement_00008: '启用',
    reserveManagement_00009: '未启用',
    reserveManagement_00010: '查询',
    reserveManagement_00011: '重置',
    reserveManagement_00012: '已接入第三方流量名称',
    reserveManagement_00013: '已开放站点',
    reserveManagement_00014: '未开放站点',
    reserveManagement_00015: '接入方式',
    reserveManagement_00016: '是否启用储备金',
    reserveManagement_00017: '当前余额',
    reserveManagement_00018: '储备金额',
    reserveManagement_00019: '操作',
    reserveManagement_00020: '储备金管理',
    reserveManagement_00021: '是',
    reserveManagement_00022: '否',
    reserveManagement_00023: '第三方流量平台',
    reserveManagement_00024: '运营商',
    reserveManagement_00025: '是否储备资金',
    reserveManagement_00026: '可以负值冲账',
    reserveManagement_00027: '提醒余额',
    reserveManagement_00028: '修改',
    reserveManagement_00029: '充值储备金额',
    reserveManagement_00030: '保存',
    reserveManagement_00031: '取消',
    reserveManagement_00032: '余额小于0自动停止充电',
    reserveManagement_00033: '储备金充值历史',
    reserveManagement_00034: '充值金额 (元)',
    reserveManagement_00035: '预充值时间',
    reserveManagement_00036: '返 回',
    reserveManagement_00037: '政府',
    reserveManagement_00038: '直签',
    reserveManagement_00039: '输入错误',
    selfInvoicingSetting_00004: '团体名称',
    selfInvoicingSetting_00005: '查询',
    selfInvoicingSetting_00006: '重置',
    selfInvoicingSetting_00007: '支付方式',
    selfInvoicingSetting_00008: '成员总数',
    selfInvoicingSetting_00009: '是否支持自助管理端在线申请发票',
    selfInvoicingSetting_00010: '操作',
    selfInvoicingSetting_00011: '开票设置',
    selfInvoicingSetting_00012: '最低开票金额',
    selfInvoicingSetting_00013: '请输入数字，小数点后最多两位',
    selfInvoicingSetting_00014: '支持的开票方式',
    selfInvoicingSetting_00015: '纸质发票',
    selfInvoicingSetting_00016: '电子发票',
    selfInvoicingSetting_00017: '纸质发票和电子发票',
    selfInvoicingSetting_00018: '发票提示信息',
    selfInvoicingSetting_00019: '发票提示信息会显示在自助端的申请发票页',
    selfInvoicingSetting_00020: '请输入发票提示信息',
    selfInvoicingSetting_00021: "个人支付",
    selfInvoicingSetting_00022: "集团支付",
    selfInvoicingSetting_00023: "是",
    selfInvoicingSetting_00024: "否",
    selfInvoicingSetting_00025: "元",
    selfInvoicingSetting_00026: "确定",
    selfInvoicingSetting_00027: "取消",
    selfRechargeSetting_00004: '团体名称',
    selfRechargeSetting_00005: '查询',
    selfRechargeSetting_00006: '重置',
    selfRechargeSetting_00007: '支付方式',
    selfRechargeSetting_00008: '成员总数',
    selfRechargeSetting_00009: '是否分配金额给团体成员',
    selfRechargeSetting_00010: '是否支持团体自助管理端充值',
    selfRechargeSetting_00011: "个人支付",
    selfRechargeSetting_00012: "集团支付",
    selfRechargeSetting_00013: "不支持",
    selfRechargeSetting_00014: "请输入数字",
    selfRechargeSetting_00015: "选择全自动退款，无需人工干预自动进行退款操作，确认受理后将无法取消受理",
    selfRechargeSetting_00016: "选择半自动退款，则需要在退款页面点击【确认受理】，确认受理后将无法取消受理",
    selfRechargeSetting_00017: "选择手动退款，支付宝按半自动退款处理（需要在退款页面点击【确认受理】，确认受理后将无法取消受理）",
    selfRechargeSetting_00018: "退款后用户钱包赠金处理",
    selfRechargeSetting_00019: "赠金全部清空",
    selfRechargeSetting_00020: "只清空当前可退余额中支持退款的储值送返活动的所赠金额",
    selfRechargeSetting_00021: "",
}