export default {
    safetyLogDetail_00001: "安全评估日志详情",
    safetyLogDetail_00002: "事故备注",
    safetyLogDetail_00003: "您确定删除此条事故备注？",
    safetyLogDetail_00004: "车辆信息",
    safetyLogDetail_00005: "车牌号",
    safetyLogDetail_00006: "品牌车系",
    safetyLogDetail_00007: "查看用户",
    safetyLogDetail_00008: "充电信息",
    safetyLogDetail_00009: "充电订单编号",
    safetyLogDetail_00010: "查看订单",
    safetyLogDetail_00011: "充电完成时间",
    safetyLogDetail_00012: "充电场站",
    safetyLogDetail_00013: "查看场站",
    safetyLogDetail_00014: "场站所在城市",
    safetyLogDetail_00015: "充电设备",
    safetyLogDetail_00016: "查看设备",
    safetyLogDetail_00017: "车责故障",
    safetyLogDetail_00018: "车辆日志数据",
    safetyLogDetail_00019: "当日同城同车系年款安全参考数据",
    safetyLogDetail_00020: "得分",
    safetyLogDetail_00021: "最高温度",
    safetyLogDetail_00022: "最大温差",
    safetyLogDetail_00023: "最大温速",
    safetyLogDetail_00024: "最大压差",
    safetyLogDetail_00025: "不均衡性",
  }