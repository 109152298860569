export default {
    managementDepot_00001: '场站管理',
    managementDepot_00002: '场站中心',
    managementDepot_00003: '充电口',
    managementDepot_00004: '充电口',
    managementDepot_00005: '空闲充电口',
    managementDepot_00006: '使用充电口',
    managementDepot_00007: '离线/故障充电口',
    managementDepot_00008: '全部',
    managementDepot_00009: '已启用',
    managementDepot_00010: '未启用',
    managementDepot_00011: '开放',
    managementDepot_00012: '不开放',
    managementDepot_00013: '乘用车',
    managementDepot_00014: '物流车',
    managementDepot_00015: '中巴车',
    managementDepot_00016: '大巴车',
    managementDepot_00017: '电单车',
    managementDepot_00018: '直流桩',
    managementDepot_00019: '交流桩',
    managementDepot_00020: '场站位置',
    managementDepot_00021: '国家：',
    managementDepot_00022: '省：',
    managementDepot_00023: '市：',
    managementDepot_00024: '区：',
    managementDepot_00025: '请选择国家',
    managementDepot_00026: '请选择省份',
    managementDepot_00027: '请选择市区',
    managementDepot_00028: '请选择区域',
    managementDepot_00029: '重 置',
    managementDepot_00030: '查 询',
    managementDepot_00031: '场站类型',
    managementDepot_00032: '全部场站',
    managementDepot_00033: '自营场站',
    managementDepot_00034: '加盟商场站',
    managementDepot_00035: '请输入运营商名称',
    managementDepot_00036: '支持车型',
    managementDepot_00037: '全 选',
    managementDepot_00038: '取 消',
    managementDepot_00039: '批量设置',
    managementDepot_00040: '去设置',
    managementDepot_00041: '创建场站',
    managementDepot_00042: '场站详情',
    managementDepot_00043: '设备管理',
    managementDepot_00044: '资费设置',
    managementDepot_00045: '运营管理',
    managementDepot_00046: '查看订单',
    managementDepot_00047: '场站名称',
    managementDepot_00048: '全部车型',
    managementDepot_00049: '请选择场站',

    managementDepot_00050: '直流充电口',
    managementDepot_00051: '交流充电口',
    managementDepot_00052: '空闲充电口',
    managementDepot_00053: '使用充电口',
    managementDepot_00054: '异常充电口',
    managementDepot_00055: '搜索场站',
    managementDepot_00056: '所选场站货币类型冲突'
}