export default {
  knowledgeDetail_00001: '知识详情',
  knowledgeDetail_00002: '知识库',
  knowledgeDetail_00003: '更新时间',
  knowledgeDetail_00004: '删除',
  knowledgeDetail_00005: '编辑',
  knowledgeDetail_00006: '相关分类',
  knowledgeDetail_00007: '相关设备品牌型号',
  knowledgeDetail_00008: '相关工单',
  knowledgeDetail_00009: '相关设备',
  knowledgeDetail_00010: '问题描述',
  knowledgeDetail_00011: '平台通信日志查询',
  knowledgeDetail_00012: '相关订单',
  knowledgeDetail_00013: '相关设备异常日志',
  knowledgeDetail_00014: '故障异常代码',
  knowledgeDetail_00015: '故障异常描述',
  knowledgeDetail_00016: '相关用户',
  knowledgeDetail_00017: '微信用户',
  knowledgeDetail_00018: '卡用户',
  knowledgeDetail_00019: '解决方案',
  knowledgeDetail_00020: '处理方式',
  knowledgeDetail_00021: '删除知识后不可恢复',
  knowledgeDetail_00022: '',
  knowledgeDetail_00023: '',
  knowledgeDetail_00024: '',
  knowledgeDetail_00025: '',
  knowledgeDetail_00026: '',
  knowledgeDetail_00027: '',
  knowledgeDetail_00028: '',
}