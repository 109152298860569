export default {
  systemInfoSetting_00001: "Basic information",
  systemInfoSetting_00002: "Contact information",
  systemInfoSetting_00003: "Public Account Information",
  systemInfoSetting_00004: "Client-side license plate number input page prompt text",
  systemInfoSetting_00005: "CPO Name",
  systemInfoSetting_00006: "CPO ID",
  systemInfoSetting_00007: "Supported currency",
  systemInfoSetting_00008: "euro（Yuan）",
  systemInfoSetting_00009: "Contact",
  systemInfoSetting_000010: "Mobile number",
  systemInfoSetting_000011: "Email",
  systemInfoSetting_000012: "Get verification code",
  systemInfoSetting_000013: "Please enter the verification code",
  systemInfoSetting_000014: "Account",
  systemInfoSetting_000015: "Super administrator",
  systemInfoSetting_000016: "Account Name",
  systemInfoSetting_000017: "Bank Account",
  systemInfoSetting_000018: "Opening bank of Account ",
  systemInfoSetting_000019: "Please enter the content",
  systemInfoSetting_000020: "Information cannot be empty",
  systemInfoSetting_000021: "Modified successfully",
  systemInfoSetting_000022: "not enabled",
  systemInfoSetting_000023: "The name cannot exceed {0} characters",
  systemInfoSetting_000024: "The number of words entered should not exceed 20",
  systemInfoSetting_000025: "Please enter the correct mailbox",
  systemInfoSetting_000026: "",
  //---------国际版-------------------------------
  systemInfoSetting_000600: "add",
  systemInfoSetting_000601: "Please select",
  systemInfoSetting_000602: "Please select a currency type",
  systemInfoSetting_000603: "Company name",
}