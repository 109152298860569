export default {
    parkRecommend_00001: "Download",
    parkRecommend_00002: "Simulation laden",
    parkRecommend_00003: "Empfehlung für den Parkplatz",
    parkRecommend_00004: "Ladefläche laden",
    parkRecommend_00005: "Stapel laden",
    parkRecommend_00006: "Code des Fahrzeugs",
    parkRecommend_00007: "Parameter der Simulation",
    parkRecommend_00008: "Stromverbrauch pro Kilometer (kW 183rd; H /km)",
    parkRecommend_00009: "Laufgeschwindigkeit des Fahrzeugs (km /h)",
    parkRecommend_00010: "Fehlerbereich der Fahrzeug-Rücklaufzeit（+-）",
    parkRecommend_00011: "10 Minuten",
    parkRecommend_00012: "30 Minuten",
    parkRecommend_00013: "60 Minuten",
    parkRecommend_00014: "Bitte geben Sie eine Nummer größer als 0 ein!",
    parkRecommend_00015: "Formular herunterladen",
}