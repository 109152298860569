export default {
    orderlyAnalogCharging_00001: "Bus orderly charging simulation operation",
    orderlyAnalogCharging_00002: "Help",
    orderlyAnalogCharging_00003: "Vehicle charging",
    orderlyAnalogCharging_00004: "Vehicle driving",
    orderlyAnalogCharging_00005: "Limit the total charging power",
    orderlyAnalogCharging_00006: "Actual charging power",
    orderlyAnalogCharging_00007: "Vehicle code",
    orderlyAnalogCharging_00008: "Minimum SOC required for departure(%)",
    orderlyAnalogCharging_00009: "departure time",
    orderlyAnalogCharging_00010: "Return time",
    orderlyAnalogCharging_00011: "Electricity",
    orderlyAnalogCharging_00012:"1. When the charging pile is offline, start charging. The platform cannot control the overall load. Be careful< Br / > 2. The idle fee is set for the station tariff, and the orderly charging function of the bus cannot be used< Br / > 3. The connector is disconnected when the vehicle is charging, and the charging needs to be started manually again!",
}