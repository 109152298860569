export default {
    managementDepot_00001: 'Depot Management',
    managementDepot_00002: 'Depot Center',
    managementDepot_00003: 'Charging port',
    managementDepot_00004: 'Charging port',
    managementDepot_00005: 'Available',
    managementDepot_00006: 'In-use',
    managementDepot_00007: 'Faulty/Offline',
    managementDepot_00008: 'All',
    managementDepot_00009: 'Enabled',
    managementDepot_00010: 'Not Enabled',
    managementDepot_00011: 'Open',
    managementDepot_00012: 'Not Open',
    managementDepot_00013: 'Passenger car',
    managementDepot_00014: 'Commercial vehicle',
    managementDepot_00015: 'Van',
    managementDepot_00016: 'Bus',
    managementDepot_00017: 'E-bike',
    managementDepot_00018: 'Dc pile',
    managementDepot_00019: 'Ac pile',
    managementDepot_00020: 'Terminal position',
    managementDepot_00021: 'Country:',
    managementDepot_00022: 'Province:',
    managementDepot_00023: 'City:',
    managementDepot_00024: 'District:',
    managementDepot_00025: 'Select Country',
    managementDepot_00026: 'Select Province',
    managementDepot_00027: 'Select City',
    managementDepot_00028: 'Select District',
    managementDepot_00029: 'Reset',
    managementDepot_00030: 'Query',
    managementDepot_00031: 'Site type',
    managementDepot_00032: 'All site',
    managementDepot_00033: 'Proprietary site',
    managementDepot_00034: 'Franchisee site',
    managementDepot_00035: 'Enter the carrier name',
    managementDepot_00036: 'EV type',
    managementDepot_00037: 'Select all',
    managementDepot_00038: 'Cancel',
    managementDepot_00039: 'Volume set',
    managementDepot_00040: 'To set',
    managementDepot_00041: 'Add site',
    managementDepot_00042: 'Terminal Details',
    managementDepot_00043: 'Equipment Management',
    managementDepot_00044: 'Rates Set',
    managementDepot_00045: 'Operations Management',
    managementDepot_00046: 'View Order',
    managementDepot_00047: 'Site Name',
    managementDepot_00048: 'All vehicle type',
    managementDepot_00049: 'Please choose a station',
    managementDepot_00050: 'DC charging points',
    managementDepot_00051: 'AC charging points',
    managementDepot_00052: 'Available charging point',
    managementDepot_00053: 'In-use charging point',
    managementDepot_00054: 'Abnormal charging point',
    managementDepot_00055: 'Search',
    managementDepot_00056: '所选场站货币类型冲突'
}