export default {
    from_get_coupon_on:"{validityDays} Tage ab Erhalt des Gutscheins",
    rewardSetting_0001: 'Belohnungseinstellung',
    rewardSetting_0002: 'Menge',
    rewardSetting_0003: 'Gutschein hinzufügen',
    rewardSetting_0004: 'Bitte geben Sie den Kartennamen ein',
    rewardSetting_0005: 'Gutscheintyp',
    rewardSetting_0006: 'Gutscheinname',
    rewardSetting_0007: 'Typ',
    rewardSetting_0008: 'Ablaufdatum',
    rewardSetting_0009: 'Mission-{name} upgraden',
    rewardSetting_0010: 'Abstiegsmission-{name}',
    rewardSetting_00011: 'Wenn Sie einen Coupon mit absoluter Gültigkeitsdauer hinzufügen, beachten Sie bitte, dass der Coupon aus der Prämie entfernt wird, bevor der Coupon abläuft. ',
    rewardSetting_00012: 'Bitte geben Sie eine Zahl ein, die Zahl darf 100 nicht überschreiten',
    rewardSetting_00013: 'Benutzer-Mitgliedschaftsstufe',
    rewardSetting_00014: "Innerhalb von {validityDays} Tagen nach Erwerb",
}