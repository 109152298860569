export default {
    group_00001: "enter the fleet name",
    group_00002: "User",
    group_00003: "Group Management",
    group_00004: "Fleet name",
    group_00005: "Payment",
    group_00006: "Balance({unit})",
    group_00007: "Maximum unpaid amount per member({unit})",
    group_00008: "Maximum number of unpaid transactions per member",
    group_00009: "Paid by the individual",
    group_00010: "Paid by the fleet",
    group_00011: 'Include giveaway {promotionBalance}{unit}',
    group_00012: 'Transactions',
    group_00013: 'Members',
    group_00014: 'New Fleet',
    group_00015: 'Fleets',
    group_00016: 'Fleet name',
    group_00017: 'Give fleet a name',
    group_00018: 'Max unpaid amount',
    group_00019: "(No more charging if the total amount of unpaid  transactions reaches the setting)",
    group_00020: "(No more charging if the total number of unpaid transactions reaches the setting)",
    group_00021: "Save",
    group_00022: "Maximum number of unpaid transactions",
    group_00023: "New group created successfully!" ,
    group_00024: "You can do the following in 「User Center」「Groups 「Details」:",
    group_00025: "1. Modify the group name;",
    group_00026: "2. Change the payment method;",
    group_00027: "3. Adjust the amount and number of times the unpaid amount is limited;",
    group_00028: "4. recharge the online account (only if the payment method is group account payment);",
    group_00029: "Please improve the group information",
    group_00030: "Input error ",
}