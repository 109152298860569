export default {
  login_00001: "登录运营商平台",
  login_00002: "登录账号",
  login_00003: "输入密码",
  login_00004: "登 录",
  login_00005: "用户名密码不能为空",
  login_00006: "· 运营商平台",
  login_00007: "我已注册，现在登录",
  login_00008: "登录",
  registerEmail_00001: "注册",
  registerEmail_00002: "加盟运营商",
  registerEmail_00003: "邮箱认证",
  registerEmail_00004: "填写注册信息",
  registerEmail_00005: "查找充电运营商",
  registerEmail_00006: "查询",
  registerEmail_00007: "请输入运营商发给您的邀请码",
  registerEmail_00008: "运营商名称",
  registerEmail_00009: "结算货币",
  registerEmail_00010: "加入该运营商",
  registerEmail_00011: "加入充电运营商联盟",
  registerEmail_00012: "填写邮箱",
  registerEmail_00013: "我们已经向您填写的{email}发送一封邮件，请点击邮件中的验证链接完成验证并填写注册信息",
  registerEmail_00014: "联系人姓名|该如何称呼您",
  registerEmail_00015: "请输入姓名",
  registerEmail_00016: "运营商名称|充电用户查看到的名称",
  registerEmail_00017: "不超过{0}个字",
  registerEmail_00018: "登录账号|如：xcharge",
  registerEmail_00019: "使用字母和数字",
  registerEmail_00020: "登录密码|最少6位，且至少包含1个数字及1个字母以及至少1个特殊字符",
  registerEmail_00021: "确认密码|与登录密码一致",
  registerEmail_00022: "手机号验证",
  registerEmail_00023: "绑定微信公众号",
  registerEmail_00024: "建议使用中文",
  registerEmail_00025: "请输入运营商名称",
  registerEmail_00026: "没有找到符合条件的运营商",
  registerEmail_00027: "微信号",
  registerEmail_00028: "邀请码已使用",
  registerEmail_00029: "邀请码不存在",
  registerEmail_00030: "邮箱不能为空",
  registerEmail_00031: "请输入您的邮箱地址，以便我们可以联系到您",
  registerEmail_00032: "请填写正确的邮箱",
  registerEmail_00033: "操作失败",
  registerEmail_00034: "服务器响应超时",
  registerEmail_00035: "请先获取公众号",
  registerEmail_10036: "运营商平台",
  registerEmail_10037: "请选择语言",
  registerEmail_10038: "我已注册，现在登录",
  registerEmail_10039: "登录",
  registerEmail_10040: "登录运营商平台",
  registerEmail_10041: "登录用户组管理平台",
  registerEmail_10042: "设置成功",
  registerEmail_10043: "货币类型",
  registerEmail_10044: "请再次输入密码",
  registerEmail_10045: "两次输入密码不一致",
  registerEmail_10046: "请输入验证码",
  registerEmail_10047: "请输入数字值",
  registerEmail_10048: "请输入4位数字",
  registerEmail_10049: "请输入正确的联系人姓名",
  registerEmail_10050: "账号格式为数字字母组合",
  registerEmail_10051: "密码为字母或数字组合",
  registerEmail_10052: "请输入联系人姓名",
  registerEmail_10053: "请输入运营商名称",
  registerEmail_10054: "名称不超过 {0} 个字符",
  registerEmail_10055: "请输入账号",
  registerEmail_10056: "请输入密码",
  registerEmail_10057: "长度在 6 到 20 个字符",
  registerEmail_10058: "请先仔细阅读《智充运营商平台隐私政策》，确认是否同意！",
  registerEmail_10059: "请输入手机号",
  registerEmail_10060: "请输入正确的手机号",
  // ******
  registerEmail_20053: "货币",
  registerEmail_20054: "充电用户看到的交易货币",
  registerEmail_20055: "请选择",
  // *****
  registerEmail_20056: "请填写正确的邮箱地址，并在邮箱中打开链接进行认证。",
  registerEmail_20057: "请填写右侧全部信息即可完成注册。",
  // ****2022/1/4
  registerEmail_30058: "请选择货币",
  registerEmail_30059: "请再次输入",

  registerEmail_20051: "我已阅读并同意",
  registerEmail_20052: "《智充运营商平台隐私政策》",
  // 注册码
  registerEmail_21000: "注册码|如需注册码请联系我们，电话：4008770227",
  registerEmail_21001: "请输入注册码",
  registerEmail_21002: "注册码错误",
  registerEmail_21004: "验证注册码",
  registerEmail_21005: "如需获取注册码请联系我们",
  registerEmail_21006: "请输入",
}