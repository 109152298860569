export default {
  user_export_remind_1: "Dieser Vorgang lädt die Excel-Datei des Benutzers herunter. Möchten Sie fortfahren?",
  remind: "Hinweise",
  confirm: "bestätigen",
  cancel: "abbrechen",
  start_download: "Herunterladen starten",
  cancel_download: "Herunterladen abbrechen",

  userLevel_00001: 'aktuell anzeigen',
  userLevel_00002: 'Nutzer exportieren',
  userLevel_00003: 'Nutzer anzeigen',
  userLevel_00004: 'Mitgliedermanagement',
  userLevel_00005: 'Nutzer anzeigen',
  userLevel_00006: 'Avatar',
  userLevel_00007: 'Spitzname',
  userLevel_00008: 'Geschlecht',
  userLevel_00009: 'Handynummer',
  userLevel_00010: 'Kfz-Kennzeichen',
  userLevel_00011: 'Guthaben',
  userLevel_00012: 'Vorgänge suchen',
  userLevel_00013: 'Details',
  userLevel_00014: 'Männlich',
  userLevel_00015: 'Weiblich',
  userLevel_00016: '',
  userLevel_00017: '',
  userLevel_00018: '',
}