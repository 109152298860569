export default {
    chargeMonitor_00001: 'Select site',
    chargeMonitor_00002: 'Charging station name',
    chargeMonitor_00003: 'Operator',
    chargeMonitor_00004: 'Charging Real-time monitor',
    chargeMonitor_00005: 'View order',
    chargeMonitor_00006: 'Order information',
    chargeMonitor_00007: 'Charging information',
    chargeMonitor_00008: 'Current bill',
    chargeMonitor_00009: 'Charged amount',
    chargeMonitor_00010: "Gateway",
    chargeMonitor_00011: "Idle",
    chargeMonitor_00012: "In use",
    chargeMonitor_00013: "Abnormal",
    chargeMonitor_00014: "Disabled",
    chargeMonitor_00015: "Enabled",
    chargeMonitor_00016: "Online",
    chargeMonitor_00017: "Offline",
    chargeMonitor_00018: "Error",
    chargeMonitor_00019: "Reserving",
    chargeMonitor_00020: "Plugged",
    chargeMonitor_00021: "Charging",
    chargeMonitor_00022: "Pending car removal",
    chargeMonitor_00023: "Pending unplugging",
    chargeMonitor_00024: "Occupied",
    chargeMonitor_00025: "Please enter CPO name",
    chargeMonitor_00026: "Returned",
    chargeMonitor_00027: "Un-returned",
    chargeMonitor_00028: "View all faulty devices",
    chargeMonitor_00029: "View all offline devices",
    chargeMonitor_00030: "Add new window tab",
    chargeMonitor_00031: "On",
    chargeMonitor_00032: "Do you want to open all monitoring sites according to the last browser tab settings?",
    chargeMonitor_00033: "Please select a station to monitor in the current window",
    chargeMonitor_00034: "Setup Help",
    chargeMonitor_00035: "Please set the stations you want to monitor (up to 10) in each tab, click the 'Open' button, and the corresponding browser tab will be opened to display the monitoring stations according to your settings",
    chargeMonitor_00036: "If your browser blocks opening new windows, please check your browser settings and adjust to allow new windows to pop up",
}