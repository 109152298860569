export default {
    homePage_00001: 'Charging Sessions(time)',
    homePage_00002: 'Electricity Income({type})',
    homePage_00003: 'Service Income({type})',
    homePage_00004: 'Total Energy({type})',
    homePage_00005: 'CO₂ Saved (kg)',
    homePage_00006: 'Charging Users',
    homePage_00007: 'To Do List',
    homePage_00008: 'Chargers Status',
    homePage_00009: 'Common Functions',
    homePage_00010: 'Business Advice',
    homePage_00011: 'Utilization Rate',
    homePage_00012: 'User Rankings',
    homePage_00013: 'Revenue Trends',
    homePage_00014: 'Charging Energy Trends',
    homePage_00015: 'Charging Duration Trends',
    homePage_00016: 'User Trends',
    homePage_00017: 'Today',
    homePage_00018: 'Yesterday',
    homePage_00019: 'Ratio',
    homePage_00020: 'Refund Application',
    homePage_00021: 'Personal Refund Application',
    homePage_00022: 'Group Invoice Application',
    homePage_00023: 'Payment Bill',
    homePage_00024: 'Number of Stations',
    homePage_00025: '',
    homePage_00026: 'DC Charger',
    homePage_00027: 'AC Charger',
    homePage_00028: 'Available',
    homePage_00029: 'In-use',
    homePage_00030: 'Faulty/Offline',
    homePage_00031: 'Charging Monitor',
    homePage_00032: 'User Management',
    homePage_00033: 'Charging Orders',
    homePage_00034: 'Income Analysis',
    homePage_00035: 'Recharge Activities',
    homePage_00036: 'Abnormal Orders',
    homePage_00037: 'Group-User',
    homePage_00038: 'Gov Diversion',
    homePage_00039: 'How to Improve the Utilization Rate?',
    homePage_00040: 'Operation',
    homePage_00041: 'User Membership',
    homePage_00042: 'How to Access New Customers?',
    homePage_00043: 'How to Increase the Value-Added Income?',
    homePage_00044: 'AC',
    homePage_00045: 'DC',
    homePage_00046: 'Setting',
    homePage_00047: 'Cancel',
    homePage_00048: 'Confirm',
    homePage_00049: 'Fee',
    homePage_00050: 'Energy',
    homePage_00051: 'Last Week',
    homePage_00052: 'Last Month',
    homePage_00053: 'Kyoto charging station',
    homePage_00054: 'DC Charge Jack',
    homePage_00055: 'Charging users',
    homePage_00056: 'First-time users',
    homePage_00057: 'Electricity({type})',
    homePage_00058: 'Order amount({type})',
    homePage_00059: 'Fee({type})',
    homePage_00060: 'Charge time',
    homePage_00061: 'AC Charge Jack',
    homePage_00062: 'Guide services',
    homePage_00063: 'Invite friends',
    homePage_00064: 'Ad Service',
    homePage_00065: 'Business Management',
    homePage_00066: 'HomePage overview',

    homePage_00067: 'Wanda plaza',
    homePage_00068: 'Youth stand',
    homePage_00069: 'The sun bank',
    homePage_00070: 'Happy middle',
    homePage_00071: 'The east station',

    homePage_00072: 'Caocao Travel',
    homePage_00073: 'Didi Travel ',
    homePage_00074: 'DiDa Travel',
    homePage_00075: 'Kun Ling',
    homePage_00076: 'Park family',
    homePage_00077: 'Chow Yun-Fat',
    homePage_00078: 'Jay Chou',
    homePage_00079: 'Beijing old gun',

    homePage_00080: 'EUR',
    homePage_00081: 'Welcome {name}!',
    homePage_00082: '用户会员等级',
    homePage_00083: '导流服务',
    homePage_00084: '邀请好友',
    homePage_00085: '收入分析',
    homePage_00086: '用户分析',
    homePage_00087: '充电实时监控',
    homePage_00088: '故障处理',
    
    homePage_00089: '电费金额 ({type})',
    homePage_00090: '查看货币',
    homePage_00097: 'Your platform version does not contain this function, please upgrade to advanced version or contact business',
    homePage_00091: 'times',
    homePage_00092: 'Order amount',
    homePage_00093: 'Service Income',
    homePage_00094: 'Total Energy',
    homePage_00095: 'CO₂ Saved',
    homePage_00096: 'Power rate',
}