export default {
    analyzeXcharge_00001: "Analyse des Gesamttrends der Nutzungsrate",
    analyzeXcharge_00002: "Analyse der Ladestationsnutzungsrate",
    analyzeXcharge_00003: "Analyse der Nutzungsrate von Ausrüstungsmodellen",
    analyzeXcharge_00004: "Analyse der Hauptverkehrszeiten",
    analyzeXcharge_00005: "Analyse der durchschnittlichen Leistung",
    analyzeXcharge_00006: "Analyse der Nutzungsrate durch Franchisenehmer",
    analyzeXcharge_00007: "Analyse",
    analyzeXcharge_00008: "Analyse nach Elektrizität",
    analyzeXcharge_00009: "Analyse nach Stunden",
    analyzeXcharge_00010: "Auslastungsrate = tägliches Ladevolumen / tägliches Volllast-Ladevolumen<br/>tägliches Volllast-Ladevolumen = Nennleistung der Ladesäule*24 Stunden",
    analyzeXcharge_00011: "Auslastungsrate = tägliches Ladedauer der Ladestecke / 24 Stunden",
    analyzeXcharge_00012: "Nutzungsgrad der AC-Ladegeräte (%)",
    analyzeXcharge_00013: "Auslastung der Gleichstromladegeräte (%)",
    analyzeXcharge_00014: "Franchisenehmer auswählen",
    analyzeXcharge_00015: "Ladestation auswählen",
    analyzeXcharge_00016: "Gruppe auswählen",
    analyzeXcharge_00017: "Ausrüstungsmodell auswählen",
    analyzeXcharge_00018: "Vergleich der Ladestationsnutzungsrate",
    analyzeXcharge_00019: "Trend der Ladestationsnutzungsrate",
    analyzeXcharge_00020: "Vergleich der Nutzungsrate von Ausrüstungsmodellen",
    analyzeXcharge_00021: "Trends in der Modellverwendungsrate",
    analyzeXcharge_00022: "Nutzungsgrad",
    analyzeXcharge_00023: "Vergleich der Nutzungsrate durch Franchisenehmer",
    analyzeXcharge_00024: "Trends bei der Nutzungsrate durch Franchisenehmer",
    analyzeXcharge_00025: "Vergleich der durchschnittlichen Leistung der Ladestation",
    analyzeXcharge_00026: "Analyse der durchschnittlichen Leistung von Gerätemodellen",
    analyzeXcharge_00027: "durchschnittlichen Leistung",
    analyzeXcharge_00028: "Ladestation auswählen oder eingeben",
    analyzeXcharge_00029: "Ladestecke in Betrieb",
    analyzeXcharge_00030: "Belegtheitsrate des Ladestecke",
    analyzeXcharge_00031: "Anzahl der Stecke, die zuständig sind",
    analyzeXcharge_00032: "Ladepunktsstecke Belegtrate %",
    analyzeXcharge_00033: "Charge Port Busy Rate = Anzahl der Ports, die gerade aufgeladen werden / Gesamtzahl der Ports",
}