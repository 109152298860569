export default {
    billDetail_00001: "Vorgänge Zentrum",
    billDetail_00002: "Einzelheiten der Vorgänge",
    billDetail_00003: "alle Ladevorgänge",
    billDetail_00004: "Parkstation melden",
    billDetail_00005: "Vorgängeinformationen",
    billDetail_00006: "Fahrzeug- und Batterieinformationen",
    billDetail_00007: "Ladeinformation",
    billDetail_00008: "Gebührensätze",
    billDetail_00009: "Vorgangsverfolgung",
    billDetail_00010: "Einzelheiten der Rechnungsstellung",
    billDetail_00011: "Fahrzeug-Identifizierungsnummer",
    billDetail_00012: "Batterie-Identifizierungsnummer",
    billDetail_00013: "Batteriehersteller",
    billDetail_00014: "Batterietyp",
    billDetail_00015: "Maximal zulässige Batterietemperatur",
    billDetail_00016: "Maximal zulässige Batterieladespannung",
    billDetail_00017: "Maximal zulässige Batteriespannung pro Zelle",
    billDetail_00018: "Maximal zulässige Gesamtladespannung der Batterie",
    billDetail_00019: "Maximal zulässiger Batterieladestrom",
    billDetail_00020: "Gesamtspannung der Batterie",
    billDetail_00021: "Nennspannung der gesamten Fahrzeugbatterie",
    billDetail_00022: "Gesamtnennleistung der Fahrzeugbatterie",
    billDetail_00023: "Nennkapazität der Energiebatterie des Fahrzeugs",
    billDetail_00024: "Vergleich der Ladekurven",
    billDetail_00025: "Aufladekurven",
    billDetail_00026: "Fz.-Bedarfsspannung",
    billDetail_00027: "Fz.-Bedarfsstrom",
    billDetail_00028: "Fz.-Messspannung",
    billDetail_00029: "Fz.-Messstrom",
    billDetail_00030: "Erste Batterieladung",
    billDetail_00031: "Aktuelle Batterieladung",
    billDetail_00032: "Momentane Leistung",
    billDetail_00033: "Durchschnittliche Leistung",
    billDetail_00034: "Maximale Leistung",
    billDetail_00035: "Modell",
    billDetail_00036: "Leistung",
    billDetail_00037: "Strom",
    billDetail_00038: "Spannung",
    billDetail_00039: "Batteriestand",
    billDetail_00040: "Maximaler Leistungsbereich, Leistungsbereich {powerBegin}-{powerEnd}Kw, insgesamt {minutes} Minuten (von insgesamt {timePercent}), insgesamt {powerCharged} kWh, von insgesamt {chargePercent}",
    billDetail_00041: "Kumulierte Elektrizität",
    billDetail_00042: "kWh",
    billDetail_00043: "Ladevorgang erstellen",
    billDetail_00044: "Ladenstart von Ladesäule",
    billDetail_00045: "Abrechnung beginnen",
    billDetail_00046: "Ladesäule hört auf zu laden",
    billDetail_00047: "Ladesäule beendet den Ladevorgang",
    billDetail_00048: "Beginn der Ladeverzögerungsbetrag",
    billDetail_00049: "Laden abgeschlossen",
    billDetail_00050: "Ende der Abrechnung",
    billDetail_00051: "Zahlung abgeschlossen",
    billDetail_00052: "(Not-Aus-Taste gedrückt, Ladevorgang unterbrochen)",
    billDetail_00053: "(Gerätefehler, Ladevorgang unterbrochen)",
    billDetail_00054: "(voll aufgeladen, oder eingestellte Endbedingung erreicht)",
    billDetail_00055: "(Gerätestörung, Ende des Ladevorgangs)",
    billDetail_00056: "(menschliche remote Ladeende)",
    billDetail_00057: "Beenden des Ladevorgangs durch Betätigung an der Ladesäule, z. B. Durchziehen der Karte, Drücken des Notausschalters usw.",
    billDetail_00058: "Aktueller Ladestrom des Geräts",
    billDetail_00059: "Gelöscht",
    billDetail_00060: "Ladestrom",
    billDetail_00061: "Gesamtstrom",
    billDetail_00062: "Elektrizitätsgebühr",
    billDetail_00063: "Servicegebühr",
    billDetail_00064: "Benutzerinformationen",
    billDetail_00065: "Bestellungsnummer",
    billDetail_00066: "{pay}",
    billDetail_00067: "Start Methode",
    billDetail_00068: "Zahlungsmethode",
    billDetail_00069: "WeChat-Zahlung",
    billDetail_00070: "WeChat-Saldozahlung",
    billDetail_00071: "Zahlung des Saldos des Grupp",
    billDetail_00072: "APP-Zahlung durch Dritte",
    billDetail_00073: "Benutzer-Geldkonto",
    billDetail_00074: "Offline gespeicherte Wertkartenzahlungen",
    billDetail_00075: "Online-Bezahlung mit dem Personalausweis",
    billDetail_00076: "WeChat Öffentliche Konto (Alipay)",
    billDetail_00077: "Rabattmethode",
    billDetail_00078: "Geschenk",
    billDetail_00079: "Für diese Bestellung wurde kein Coupon verwendet",
    billDetail_00080: "Keine zusätzlichen Belohnungen für diese Bestellung",
    billDetail_00081: "Bestellhinweise",
    billDetail_00082: "Bitte füllen Sie die Bestellhinweise aus",
    billDetail_00083: "Sind Sie sicher, dass Sie diesen Bestellkommentar löschen möchten?",
    billDetail_00084: "bestätigen zu löschen",
    billDetail_00085: "Hinweise",
    billDetail_00086: "Tag",
    billDetail_00087: "Stunde",
    billDetail_00088: "Minuten",
    billDetail_00089: "aktuelle Vorgangsbetrag",
    billDetail_00090: "tatsächliche Vorgangsbetrag",
    billDetail_00091: "Maximaler Leistungsbereich, Leistungsbereich {powerBegin}-{powerEnd}Kw, gesamt {minutes} Minuten (insgesamt {timePercent}), gesamt {powerCharged} kWh, von insgesamt Leistung {chargePercent}",
    billDetail_00092: "Wechat Öffentliche Konto (Wechat bezahlen ohne Passwörte)",
    billDetail_00093: "Wechat Öffentliche Konto (Alipay)",
    billDetail_00094: "Nur Rechnungsstellung",
    billDetail_00095: "Kein Rabatt auf die Gesamtkosten einer einzelnen Ladung",
    billDetail_00096: "Kein Rabatt auf die einmalige Servicegebühr",
    billDetail_00097: "Einmalige Servicegebühr {des} Rabatt",
    billDetail_00098: "Einmalige Gebühr insgesamt {des} Rabatt",
    billDetail_00099: "Die Gesamtkosten für eine einzige Aufladung sind kostenlos",
    billDetail_00100: "Kostenlose einmalige Servicegebühr",
    billDetail_00101: "Stufe der Benutzerberechtigungen",
    billDetail_00102: "Guthaben {money} yuan kostenlosen Betrag beim Bezahlen",
    billDetail_00103: "Gruppenzuweisung Guthaben von {money}Yuan bei Zahlung",
    billDetail_00104: "Grund für die Bezahlung dieser Bestellung:",
    billDetail_00105: "Das Drittsystem wurde nicht darüber informiert, dass der Abrechnungsauftrag bezahlt wurde.",
    billDetail_00106: "Der Bestellbetrag ist aufgrund einer Verzugsgebühr höher als der Kontostand des Benutzers zu diesem Zeitpunkt",
    billDetail_00107: "Im Rahmen der Regeln der Gruppe, die eine maximale Anzahl von unbezahlten Leistungen zulassen.",
    billDetail_00108: "Der Abrechnungsauftrag wurde nicht genau gemäß der Endbedingung (innerhalb der Toleranz) beendet, was zu einem Auftragsbetrag führte, der geringfügig höher war als der Kontostand des Benutzers zu diesem Zeitpunkt.",
    billDetail_00109: "Die Abrechnung ist zum Zeitpunkt der Abrechnung offline und der Bestellbetrag ist größer als der Kontostand des Benutzers zu dem Zeitpunkt, an dem die rückwirkende Bestellung online gemeldet wird",
    billDetail_00110: "Der Bezahlmodus der Ladestation ist Postpaid",
    billDetail_00111: "Unbekannter Grund dafür, dass der Bestellbetrag höher ist als der aktuelle Saldo des Benutzerkontos",
    billDetail_00112: "Bearbeitung des zu zahlenden Auftrags:",
    billDetail_00113: "Wenn das Konto aufgeladen ist und der Saldo größer ist als der zu zahlende Betrag, wird das System den Auftrag automatisch ausgleichen. <br/>2.Der Nutzer wird vor der nächsten Abbuchung an die Zahlung erinnert, und die unbezahlte Bestellung kann nicht abgebucht werden. <br/>3. Wenn der Benutzer lange Zeit nicht bezahlt hat, können Sie den Betrag ändern, der kleiner oder gleich dem Kontostand ist, und das System wird die Bestellung automatisch ausgleichen.",
    billDetail_00114: "Couponsname",
    billDetail_00115: "Couponstypen",
    billDetail_00116: "Nutzungsbedingung",
    billDetail_00117: "Couponsmanagement",
    billDetail_00118: "gelöscht",
    billDetail_00119: "Einzelheiten des Coupons",
    billDetail_00120: "Angebotemethode",
    billDetail_00121: "Kredit für Strom und Nebenkosten",
    billDetail_00122: "Guthaben für Stromverbrauch gegen Nebenkosten",
    billDetail_00123: "Bargeld gegen Strom und Nebenkosten",
    billDetail_00124: "Bargeld gegen Servicegebühr",
    billDetail_00125: "Gutschrift für {power} Ladeeinheiten, Rabatt {money}",
    billDetail_00126: "Rabatt von {money}Yuan",
    billDetail_00127: "Stromgepack",
    billDetail_00128: "Gutschein",
    billDetail_00129: "Start/Stopp-Karte",
    billDetail_00130: "Speicherwertkarte",
    billDetail_00131: "Ausweis",
    billDetail_00132: "XCHARGE Karte",
    billDetail_00133: "Gruppenkonfigurierte Karte",
    billDetail_00134: "Offline-Datenlesekarte",
    billDetail_00135: "bezahlt",
    billDetail_00136: "Zu ziehende Waffe",
    billDetail_00137: "im Laden",
    billDetail_00138: "zu zahlende",
    billDetail_00139: "In der Verzögerungsrechnungsstellung",
    billDetail_00140: "Bearbeitung der gestoppten Bestellung",
    billDetail_00141: "Vorübergehende Stornierung eines blockierten Vorgangs",
    billDetail_00142: "Abschluss und Erstellung der Rechnung",
    billDetail_00143: "Wenn der Benutzer den Ladevorgang nicht fortsetzen kann oder der Ladeanschluss nicht wieder in den Ruhezustand versetzt werden kann, weil die Rechnung für diesen Auftrag nicht rechtzeitig gemeldet wurde, wählen Sie bitte die folgende Bearbeitungsmethode",
    billDetail_00144: "1. Vorübergehende Stornierung: Stornieren Sie den Auftrag vorübergehend und warten Sie, bis die Ladestation die Rechnung später meldet. Wenn das Ladegerät lange Zeit nicht abgerechnet oder neu gestartet wurde, ist es möglich, dass der Auftrag nach der vorübergehenden Stornierung nicht mehr abgerechnet wird, und es wird empfohlen, die Option 2 - Beenden und eine Rechnung erstellen zu verwenden. ",
    billDetail_00145: "2. Close and generate bill: Der Auftrag wird durch das Betriebssystem der Plattform geschlossen und es wird automatisch eine Rechnung über das aktuelle Entgelt erstellt und versucht, diese zur Zahlung abzubuchen. Bitte beachten Sie: Nachdem die Rechnung hier erstellt wurde, wird die vom Ladegerät gemeldete tatsächliche Rechnung ignoriert.",
    billDetail_00146: "Benutzer-Kennzeichen",
    billDetail_00147: "Wartung der Benachrichtigungsmeldung",
    billDetail_00148: "Das eingegebene Kfz-Kennzeichen kann nicht leer sein.",
    billDetail_00149: "Bitte geben Sie das richtige Kfz-Kennzeichen ein",
    billDetail_00150: "Vorgangsnummer von Dritten",
    billDetail_00151: "Vorgänge schieben",
    billDetail_00152: "Betrag ändern",
    billDetail_00153: "Gesamtbetrag der Vorgänge",
    billDetail_00154: "Bitte geben Sie den Zahlungsbetrag ein",
    billDetail_00155: "Geänderter Betrag",
    billDetail_00156: "Betrag nach Rabatt",
    billDetail_00157: "Rabatt",
    billDetail_00158: "Bezahlen von Dritten",
    billDetail_00159: "Push fehlgeschlagen",
    billDetail_00160: "erfolgreiche Push",
    billDetail_00165: "Der geänderte Betrag hat nicht das richtige Format",
    billDetail_00166: "Doppelte Waffenlieferung",
    billDetail_00167: "Anschließen und aufladen",

    // 团体管理员Gruppenadministrator
    billDetail_00200: "Name der Gruppe",
    billDetail_00201: "Ladevorgang suchen",
    // pos机 POS
    billDetail_00202: 'Laufende Nummer der POS',
    billDetail_00203: 'Bankkarte',
    billDetail_00204: 'Aktivierungsmethode',
    billDetail_00205: 'POS-Benutzer',
    billDetail_00206: 'No idle fee',
    billDetail_00211: "Cancel the order",
    billDetail_00212: "Before canceling the order, if the user has already paid the order, please refund the fees already paid to the user.Are you sure you want to cancel this order? After cancellation, the order data will not be included in the statistics.",
    billDetail_00213: "The meters' NO. when charging starts",
    billDetail_00214: "The meters’ NO. when charging ends",
    billDetail_00215: "Cancelled order",
    bill_00071: "Strommenge beträgt weniger als 1 kWh",
    bill_00072: "ungewöhnlich lange Ladezeit",
    bill_00073: "Gerät wurde unerwartet beendet",
    bill_00074: "Fahrzeug wurde unerwartet beendet",
    bill_00101: "Ladung über 500 'ne halbe tonne",
    bill_00102: "Maximale ladung der anlage im lauf der aufgeladenen zeit",
    billDetail_10216: "{username} has modified the order amount {oldFeeTotal}{currencyType} to {feeTotal}{currencyType}.",
    billDetail_10217: "{username} has processed the stalled order and the processing method is the Temporary cancellation.",
    billDetail_10218: "{username} has processed the stalled order and the processing method is the Closing and Billing.",
    billDetail_00217: 'OCPP Transaction ID',
    billDetail_10221: "${0} due, reduced for exceeding pre-authorization or account limits",
}