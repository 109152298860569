export default {
    parkRecommend_00001: "下载",
    parkRecommend_00002: "充电模拟",
    parkRecommend_00003: "车辆-充电车位推荐",
    parkRecommend_00004: "充电车位",
    parkRecommend_00005: "充电桩",
    parkRecommend_00006: "车辆编码",
    parkRecommend_00007: "充电模拟生成参数",
    parkRecommend_00008: "车辆每公里耗电量（kW·h/km）",
    parkRecommend_00009: "车辆行驶速度（km/h）",
    parkRecommend_00010:"车辆回站时间误差范围（+-）",
    parkRecommend_00011: "10分钟",
    parkRecommend_00012: "30分钟",
    parkRecommend_00013: "60分钟",
    parkRecommend_00014: "请输入大于0的数字！",
    parkRecommend_00015: "下载表格",
}