export default {
    work_00001: 'Ticket details',
    work_00002: 'Diagnostics',
    work_00003: 'Ticket system',
    work_00004: 'Ticket management',
    work_00005: 'Very urgen',
    work_00006: 'Send',
    work_00007: 'Ticket num',
    work_00008: 'Issue station',
    work_00009: 'Issue site brand and model',
    work_00010: 'Issue source',
    work_00011: 'Initiator',
    work_00012: 'Initiate time',
    work_00013: 'Update time',
    work_00014: 'Current processor',
    work_00015: 'Issue description',
    work_00016: 'Related charger',
    work_00017: 'Abnormal log of related charger',
    work_00018: 'Realted saftey evaluation log',
    work_00019: 'Related user',
    work_00020: 'Solving solution',
    work_00021: 'Ticket process',
    work_00022: 'Completed',
    work_00023: 'Pending',
    work_00024: 'Resolved',
    work_00025: 'Closed',
    work_00026: 'Transfer',
    work_00027: 'Close',
    work_00028: 'Platform communication log query',
    work_00029: 'Fault exception code',
    work_00030: 'Fault exception description',
    work_00031: 'Urgent',
    work_00032: 'General',
    work_00033: 'Pending',
    work_00034: 'Processing',
    work_00035: 'Resolved',
    work_00036: 'Closed',
    work_00037: 'Equipment number',
    work_00038: 'Related transaction',
    work_00039: 'Reporting by staff',
    work_00040: 'Customer feedback',
    work_00041: 'Equipment reporting',
    work_00042: 'Automatic trigger',
    work_00043: 'Other',
    work_00044: 'Send',
    work_00045: 'Cannot restore after deleting a ticket',
    work_00046: 'Add to knowledge bank',
    work_00047: 'Return',
    work_00048: 'Receiving',
    work_00049: 'Initiate ticket',
    work_00050: 'Mobile：{mobile}',

    work_00058: 'Ticket Num',
    work_00059: 'Description of action',
    work_00060: 'Historical actions ',
    work_00061: 'Please enter the content',

    // 工单状态弹窗 before
    orderMangement_00052: 'Remarks',
    orderMangement_00053: 'Please enter the content',
    orderMangement_00054: 'Solution',
    orderMangement_00055: 'Transfer processor',
    orderMangement_00056: 'Accept ticket',
    orderMangement_00057: 'Whether to accept the ticke?',
    orderMangement_00058: 'Confirm accept',
    orderMangement_00059: 'Transfer tickets',
    orderMangement_00060: 'Confirm transfer',
    orderMangement_00061: 'Reasons for transfer',
    orderMangement_00062: 'Close the ticket',
    orderMangement_00063: 'Are you sure to close the tickets',
    orderMangement_00064: 'Confirm to close',
    orderMangement_00065: 'Close reason',
    orderMangement_00066: 'Return to tickets',
    orderMangement_00067: 'Are you sure to return to tickets?',
    orderMangement_00068: 'Confirm reject',
    orderMangement_00069: 'Return reason',
    orderMangement_00070: 'The ticket has been resolved',
    orderMangement_00071: 'Do you confirm that the tickets has been resolved',
    orderMangement_00072: 'Confirmation resolved',
    orderMangement_00073: 'Please fill in completely',
    orderMangement_00074: 'Operation succeed',
    orderMangement_00082: 'Become a follower',
    orderMangement_00083: 'Charger manufacturer',



    work_10006: 'Issue Description',
    work_10007: 'Another Charger',
    work_10008: 'Add a question',
    work_10009: 'Station Address',
    work_10010: 'Admission Notice',
    work_10011: 'Related Transaction',
    work_10012: 'Select Transaction',
    work_10013: 'Error Information',
    work_10014: 'Error Code',
    work_10015: 'Stations',
    work_10016: 'Transactions ID',
    work_10018: 'Issue closed confirmation',
    work_10019: 'Take action',
    work_10020: 'Please take actions to all the issues',
    work_10021: "No message",
    work_10022: "picture",
    work_10023: "video",
    
    // orderMangement_00052: 'Remarks',
    // orderMangement_00053: 'Please enter the content',
    // orderMangement_00054: 'Solution',
    // orderMangement_00055: 'Transfer Processor',
    // orderMangement_00056: 'Accept Ticket',
    // orderMangement_00057: 'Whether to accept the ticket？',
    // orderMangement_00058: 'Confirm Accept',
    // orderMangement_00059: 'Transfer Tickets',
    // orderMangement_00060: 'Confirm Transfer',
    // orderMangement_00061: 'Reasons for transfer',
    // orderMangement_00062: 'Close the Ticket',
    // orderMangement_00063: 'Are you sure to close the Tickets',
    // orderMangement_00064: 'Confirm to close',
    // orderMangement_00065: 'Close Reason',
    // orderMangement_00066: 'Return to Tickets',
    // orderMangement_00067: 'Are you sure to return to Tickets?',
    // orderMangement_00068: 'Confirm Return',
    // orderMangement_00069: 'Return Reason',
    // orderMangement_00070: 'The Ticket has been resolved',
    // orderMangement_00071: 'Do you confirm that the Tickets has been resolved',
    // orderMangement_00072: 'Confirmation Resolved',
    // orderMangement_00073: 'Please fill in completely',
    // orderMangement_00074: 'Operation Succeed',
    // orderMangement_00075: 'Staff Report',
    // orderMangement_00076: 'Customer Report',
    // orderMangement_00077: 'Equipment Report',
    // orderMangement_00078: 'Auto Trigger',
    // orderMangement_00079: 'Other',
    // orderMangement_00080: 'Tickets Priority',
    // orderMangement_00081: 'Tickets Status',
    // orderMangement_00082: 'Please select a replacement part',
    // orderMangement_00083: 'The material and quantity of replacement parts used for processing this Tickets',
    // orderMangement_00084: 'Quantity Used',
    // orderMangement_00085: 'Replacement parts code or replacement part name',
    // orderMangement_00086: 'Completion Confirmed',
    // orderMangement_00087: 'Add Replacement Part',
    // orderMangement_00088: 'All CPOs',
    // orderMangement_00089: 'All Stations',
    // orderMangement_00090: 'CPO Name',
    // orderMangement_00091: 'Charger Manufacturer',
    // orderMangement_00093: 'Material Name',
    // orderMangement_00094: 'Material Number',
    // orderMangement_00095: 'Material SN',
    // orderMangement_00096: 'Please enter the Material SN used, one per line',

    detailDepot_00035: 'Real picture',
    detailDepot_00036: 'Address, street, and house name',
    detailDepot_00037: 'Search',
    detailDepot_00038: 'Name',
    detailDepot_00039: 'Detailed address',
    detailDepot_00040: 'Current position',
    detailDepot_00041: 'Cancel',
    detailDepot_00042: 'Use this location',
    detailDepot_00043: 'Please choose the coordinate of charging staion',
    detailDepot_00044: 'Position selected does not match the address',
    detailDepot_00045: "Notice",
    detailDepot_00046: "I see",
    detailDepot_00047: "Address cannot be found",
    detailDepot_00048: "An unknown error has occurred on the Google map",

    work_21000: "Material code",
    work_21001: "Material name",
    work_21002: "Specification and model",
    work_21003: "Number",


}
