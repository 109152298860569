export default {
    managementCharger_00001: 'Ladekartenverwaltung des Betreibers',
    managementCharger_00002: 'Online-Kartenverwaltung',
    managementCharger_00003: 'Plug-and-Charge-Kartenverwaltung',
    managementCharger_00004: 'Offline-Start-Stopp-Kartenverwaltung',
    managementCharger_00005: 'Offline-Wertkartenverwaltung',
    managementCharger_00006: 'Nummer der Ladekarte',
    managementCharger_00007: 'Ladekartenliste',
    managementCharger_00008: 'Neue Plug-and-Charge-Ladekarte',
    managementCharger_00009: 'Neue einfache Online-Identitäts-Ladekarte',
    managementCharger_00010: 'NFC (APP) herunterladen',
    managementCharger_00011: 'Kartennummer',
    managementCharger_00012: 'Speicherkarten-Typ',
    managementCharger_00013: 'Start/Stopp-Karte',
    managementCharger_00014: 'Prepaidkarte',
    managementCharger_00015: 'plug and charge card',
    managementCharger_00016: 'Online-ID-Karte',
    managementCharger_00017: 'plug and charge',
    managementCharger_00018: 'Nummerierung',
    managementCharger_00019: 'Nummernschild',
    managementCharger_00020: 'Name (Telefonnummer)',
    managementCharger_00021: 'Eigene Ladekartengruppe',
    managementCharger_00022: 'Gleichgewicht',
    managementCharger_00023: 'arbeiten',
    managementCharger_00024: 'Bestellung prüfen',
    managementCharger_00025: "{id}(Als verloren gemeldet)",
    managementCharger_00026: "Neue Plug-and-Charge-Karte",
    managementCharger_00027: "Passende Fahrzeuge abfragen{type}",
    managementCharger_00028: "Vollständige Karteninformationen",
    managementCharger_00029: "Komm ins Deck",
    managementCharger_00030: "Komplett neu",
    managementCharger_00031: "Bitte geben Sie die Handynummer des Benutzers ein",
    managementCharger_00032: "Diese Handynummer stimmt innerhalb von 2 Wochen mit mehreren Fahrzeugen ({type}) überein, bitte das Fahrzeug ({type}) manuell ermitteln!",
    managementCharger_00033: "Benutzerbild",
    managementCharger_00034: "Spitzname|Telefonnummer",
    managementCharger_00035: "Fahrzeug{type}",
    managementCharger_00036: "Letztes Mal|Endzeit des Ladevorgangs",
    managementCharger_00037: "Letztes Mal | Ladestation",
    managementCharger_00038: "Sicher",
    managementCharger_00039: "Kartennummer",
    managementCharger_00040: "Quelle",
    managementCharger_00041: "Nummerierung",
    managementCharger_00042: "Kontaktname",
    managementCharger_00043: "Kontakt Telefonnummer",
    managementCharger_00044: "Nummernschild",
    managementCharger_00045: "Taste",
    managementCharger_00046: "Aufladebetrag",
    managementCharger_00047: "Passwort",
    managementCharger_00048: "Tritt dem Deck bei (erforderlich)",
    managementCharger_00049: "Bitte wählen Sie eine Kartengruppe",
    managementCharger_00050: "Ladekarte erfolgreich erstellt",
    managementCharger_00051: "zulässig",
    managementCharger_00052: "「Ladekartenverwaltung」",
    managementCharger_00053: "Ladekartengruppe",
    managementCharger_00054: "「Einzelheiten」",
    managementCharger_00055: "Gehen Sie wie folgt vor:",
    managementCharger_00056: "1. Ladekarten vom Speichertyp aufladen;",
    managementCharger_00057: "2. Überprüfen Sie das Aufladeprotokoll;",
    managementCharger_00058: "3. Ändern Sie die Kontaktinformationen der Ladekarte;",
    managementCharger_00059: "Vorherige",
    managementCharger_00060: "Nächster Schritt",
    managementCharger_00061: "Beenden",
    managementCharger_00062: "Bitte geben Sie die Ausweisnummer ein",
    managementCharger_00063: "Bitte geben Sie die richtige Kartennummer ein",
    managementCharger_00064: "Bitte geben Sie die Kartenquelle ein",
    managementCharger_00065: "Bitte geben Sie das richtige Nummernschild ein",
    managementCharger_00066: "Das Format des Aufladebetrags ist falsch",
    managementCharger_00067: "Bitte geben Sie das Passwort ein",
    managementCharger_00068: "Online-Ausweiseingabe ist erfolgreich",
    managementCharger_00069: "Neuer einfacher Personalausweis",
    managementCharger_00070: "Kartennummer darf kein Chinesisch enthalten",
    managementCharger_00071: "Die Quelle darf 'plug and charge' nicht ausfüllen",
    managementCharger_00072: "Bitte geben Sie eine gültige Telefonnummer ein",
  }