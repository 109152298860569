export default {
  cardRecord_00001: "充值总金额({unit})",
  cardRecord_00002: "充值本金({0})",
  cardRecord_00003: "充值赠送金额({0})",
  cardRecord_00004: "卡组充值记录",
  cardRecord_00005: "运营商充电卡管理",
  cardRecord_00006: "充电卡组管理",
  cardRecord_00007: "充值次数(次)",
  cardRecord_00008: "充值金额({0})",
  cardRecord_00009: "赠送金额({unit})",
  cardRecord_00010: "下载充值记录",
  cardRecord_00011: "充值日期",
  cardRecord_00012: "姓名",
  cardRecord_00013: "充值方式",
  cardRecord_00014: "操作员",
  cardRecord_00015: "此操作将下载充值记录的Excel文件, 是否继续?",
  cardRecord_00016: "微信公众号",
  cardRecord_00017: "（支付宝）",
  cardRecord_00018: "（微信支付）",
  cardRecord_00019: "平台",
  cardRecord_00020: "在线卡组",
  cardRecord_00021: "离线卡组",
  cardRecord_00022: "超级卡组",
  cardRecord_00023: "至",
  cardRecord_00024: "开始日期",
  cardRecord_00025: "结束日期",
  cardRecord_00026: "卡号",
}