export default {
    vehicleChargingLogDetail_00001: "log details",
    vehicleChargingLogDetail_00002: "charging curve",
    vehicleChargingLogDetail_00003: "",
    vehicleChargingLogDetail_00004: "Plate number",
    vehicleChargingLogDetail_00005: "vehicle type",
    vehicleChargingLogDetail_00006: "vehicle {tag}",
    vehicleChargingLogDetail_00007: "owner",
    vehicleChargingLogDetail_00008: "mobile number",
    vehicleChargingLogDetail_00009: "start time",
    vehicleChargingLogDetail_00010: "end time",
    vehicleChargingLogDetail_00011: "",
    vehicleChargingLogDetail_00012: "",
    vehicleChargingLogDetail_00013: "",
    vehicleChargingLogDetail_00014: "",
    vehicleChargingLogDetail_00015: "",
    billDetail_00025: "Charging curve",
    billDetail_00026: "Demand voltage at the EV end (dashed line)",
    billDetail_00027: "Demand current at the EV end (dashed line)",
    billDetail_00028: "Measured voltage at the EV end (dashed line)",
    billDetail_00029: "Measured current at the EV end (dashed line)",
    billDetail_00030: "Initial battery charge",
    billDetail_00031: "Current battery charge",
    billDetail_00032: "Instantaneous power",
    billDetail_00033: "Average power",
    billDetail_00034: "Largest power",
    billDetail_00035: "EV model",
    billDetail_00036: "Power",
    billDetail_00037: "Current",
    billDetail_00038: "Voltage",
    billDetail_00039: "Battery capacity",
    billDetail_00040: "Maximum power range, power range {powerBegin}-{powerEnd}Kw, total {minutes} minutes (of total {timePercent}), total {powerCharged} kWh, of total power {chargePercent}",
    billDetail_00041: "Accumulated electricity",
    billDetail_00042: "kwh",
    billDetail_00043: "Create charging order",
    billDetail_00044: "Start charging",
    billDetail_00045: "Start billing",
    billDetail_00046: "Stop charging",
    billDetail_00047: "End charging",
    billDetail_00048: "Start charging for postpone time ",
    billDetail_00049: "Complete charging",
    billDetail_00050: "End billing ",
    billDetail_00051: "Payment completed",
    billDetail_00091: "Maximum power range, power range {powerBegin}-{powerEnd}Kw, total {minutes} minutes (of total {timePercent}), total {powerCharged} kWh, of total power {chargePercent}",

}