export default {
    selfHelpPayCost_00001: 'Charge items',
    selfHelpPayCost_00002: 'Charger',
    selfHelpPayCost_00003: 'Charging station',
    selfHelpPayCost_00004: 'Duration',
    selfHelpPayCost_00005: 'Amount（Euro）',
    selfHelpPayCost_00006: 'Euro',
    selfHelpPayCost_00007: 'Year',
    selfHelpPayCost_00008: 'Month',
    selfHelpPayCost_00009: 'Bill details',
    selfHelpPayCost_00010: 'Pending bill details',
    selfHelpPayCost_00011: 'IoT Card',
    selfHelpPayCost_00012: 'Account details',
    selfHelpPayCost_00013: 'Balance details',
    selfHelpPayCost_00014: 'Top-up',
    selfHelpPayCost_00015: 'Charging bill deduction',
    selfHelpPayCost_00016: 'Duration',
    selfHelpPayCost_00017: 'Content',
    selfHelpPayCost_00018: 'Amount（Euro）',
    selfHelpPayCost_00019: 'Remark',
    selfHelpPayCost_00020: 'WeChat Pay (operator)',
    selfHelpPayCost_00021: 'WeChat Pay (operator)',
    selfHelpPayCost_00022: 'XCharge',
    selfHelpPayCost_00023: 'Bill',
    selfHelpPayCost_00024: 'Charging bill',
    selfHelpPayCost_00025: 'A bill for the previous month’s charges will be issued at 10:00 on the 1st of each month. Please ensure that the account balance can be refunded in full before 19:30 on the 10th of each month to avoid overdue arrears affecting related services.',
    selfHelpPayCost_00026: 'Amount',
    selfHelpPayCost_00027: 'Deducted',
    selfHelpPayCost_00028: 'Undeducted',
    selfHelpPayCost_00029: 'Arrears',
    selfHelpPayCost_00030: 'Invoiced',
    selfHelpPayCost_00031: 'Uninvoiced',
    selfHelpPayCost_00032: 'Details',
    selfHelpPayCost_00033: '365 days after the charging pile is allocated to the operator, #simPrice# yuan/pile/30 days.',
    selfHelpPayCost_00034: 'Billing date',
    selfHelpPayCost_00035: 'Current amount',
    selfHelpPayCost_00036: 'Charging cycle',
    selfHelpPayCost_00037: 'Account Balance"',
    selfHelpPayCost_00038: 'Top-up amount',
    selfHelpPayCost_00039: 'Select Payment Method',
    selfHelpPayCost_00040: 'WeChat Pay',
    selfHelpPayCost_00041: 'pay by AliPay',
    selfHelpPayCost_00042: 'other_money',
    selfHelpPayCost_00043: 'Payment completed',
    selfHelpPayCost_00044: 'Previous step',
    selfHelpPayCost_00045: 'Account top-up',
    selfHelpPayCost_00046: 'Amount of bill to be billed',
    selfHelpPayCost_00047: 'Amount of bills that have not deducted',
    selfHelpPayCost_00048: 'Arrears',
    selfHelpPayCost_00049: 'Total Amount',
    selfHelpPayCost_00050: 'Please enter the recharge amount',
    selfHelpPayCost_00051: 'Select payment method',
    selfHelpPayCost_00052: 'You cannot enter a negative number',
    selfHelpPayCost_00053: 'Top-up succeeded',
    selfHelpPayCost_00054: 'Top-up failed',
    selfHelpPayCost_00055: 'Please enter the correct top-up amount',
    selfHelpPayCost_00056: 'Charging principles',
    selfHelpPayCost_00057: 'IOT card network fee - 365 days after the charger is allocated to the operator, 12.5 yuan / pile / 30 days.',
    selfHelpPayCost_100058: '公对公转账',
    selfHelpPayCost_100059: '1、您可以使用您的企业网银公对公转账方式进行充值，平台会根据您转账交易流水中的付款人名称和转账金额自动匹配为您的平台账户充值。',
    selfHelpPayCost_100060: '2、请注意您的付款人名称需和您申请平台注册码时提供的公司名称（<span style="color:#FA545E">{title}</span>）一致，平台才能自动匹配账户充值成功。如您的公司名称有误，请联系平台修改。',
    selfHelpPayCost_100061: '3、公对公转账金额不会立即充值到您的平台账户中，会有几分钟延迟，最晚一般转账当天24时前充值成功。平台充值成功后会发送通知，也会给您注册手机号发送充值成功短信，请及时查看。可在余额明细中查看相关详情。',
    
    selfHelpPayCost_100062: '公对公转账收款人信息',
    selfHelpPayCost_100063: '收款人账号：110916224910502',
    selfHelpPayCost_100064: '收款人名称：北京智充科技有限公司',
    selfHelpPayCost_100065: '收款人开户行：招商银行股份有限公司北京大运村支行',
    selfHelpPayCost_100066: '收款人开户行联行号：308100005221',
    selfHelpPayCost_100067: '您有任何问题可拨打010-53609116 ，时间：工作日早10：00点至晚18：00点；您也可向您对接的智充业务同事进行咨询。',

    selfHelpPayCost_100068: '公对公转账（运营商）',
    selfHelpPayCost_100069: '流水号（收款行）:',
    selfHelpPayCost_100070: '交易日:',
    selfHelpPayCost_100071: '交易时间:',
    selfHelpPayCost_100072: '交易金额:',
    selfHelpPayCost_100073: '付款人账号:',
    selfHelpPayCost_100074: '付款人名称:',
    selfHelpPayCost_100075: '付款人开户行:',
  }