export default {
  weChatRecord_00001: "Aufladerekord",
  weChatRecord_00002: "Aufladedatum",
  weChatRecord_00003: "Ladebetrag({0})",
  weChatRecord_00004: "Kapitel({0})",
  weChatRecord_00005: "Geschenk({0})",
  weChatRecord_00006: "Aufladenmethode",
  weChatRecord_00007: "Betreiber",
  weChatRecord_00008: "Backup",
  weChatRecord_00009: "(Erstattung nicht unterstützen) Aufladeaktivität",
}