<template>
    <div class="base">
        <div class="info">
            <div :class="['carInfo','shadow',!showTitle ? 'noneShadow':'']">
                <div class="title" v-if="showTitle">
                    <span>{{$t("billDetail_00007")}}</span>
                    <el-button type="text" v-if="!isuserGroupMenu" @click="toDetail">{{$t("billDetail_00024")}}</el-button>
                </div>
                <div class="charging-info isEcharts" v-if="!billInfo.occupation && measurable && billInfo.status !='created' && billInfo.status !='cancelled' && billInfo.status !='occupied'">
                    <charging-info-ac v-show="billInfo.device != null && billInfo.device.type == 'slowCharger'" :info="billInfo" :showTitle="showTitle"></charging-info-ac>
                    <charging-info-dc v-show="billInfo.device != null && billInfo.device.type == 'fastCharger'" :info="billInfo" :showTitle="showTitle"></charging-info-dc>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ChargingInfoAc from "./chargingInfo-AC.vue";
import ChargingInfoDc from "./chargingInfo-DC.vue";
export default {
    data() {
        return {
            isuserGroupMenu: false,
        };
    },
    props:['billInfo','measurable','showTitle'],
    methods: {
      toDetail(){
          window.open(`./contrastCurve.html?id=${this.billInfo.id}`)
      },
    },
    components: {
        ChargingInfoAc,
        ChargingInfoDc
    },
    created(){
        console.log('measurable',this.measurable)
        if(window.localStorage.getItem('userGroupMenu')=='userGroupMenu'){
            this.isuserGroupMenu = true;
        }
    }
}
</script>
<style scoped lang="scss">
    .info{
        display: flex;
        margin-top: 5px;
        .shadow{
            background: #FFFFFF;
            box-shadow: 0px 2px 12px 0px rgba(30, 30, 30, 0.05);
            border-radius: 6px;
            &.noneShadow{
                box-shadow: none!important;
            }
        }
        .carInfo{
            flex: 1;
            .title{
                height: 61px;
                line-height: 61px;
                padding:0 16px;
                font-size: 16px;
                color: #41526D;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                .el-button--text{
                    color: #5486EA;
                }
            }
        }
    }
</style>