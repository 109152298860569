<template>
  <div class="wrap">
    <!-- 推送资费--结果 -->
    <el-table :data="pushRatesData" :header-cell-style="{
      background:'#EFF2F6',
      color:'#000',
      borderRadius: '4px 4px 0px 0px'}" style="width: 100%">
      <el-table-column :label="$t('common_45000')" width="200">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.title" placement="top">
            <p>{{scope.row.title}}</p>
          </el-tooltip>
          <p style="color:red;margin-top:10px;" v-if="retry&&scope.row.failNum">
            {{$t('common_45007')}}：{{scope.row.failNum}}
            <el-button type="text" @click="retryEvent(scope.row)" style="margin-left:10px;">{{$t('common_61142')}}</el-button>
          </p>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common_45001')" width="140">
        <template slot-scope="scope">
          <div>{{$t('common_45003')}} : {{scope.row.fastChargers}}</div>
          <div>{{$t('common_45004')}} : {{scope.row.slowChargers}}</div>
          <div v-if="ratesType==1">{{$t('common_45005')}} : {{scope.row.energyStorageChargers}}</div>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" :label="$t('common_45002')" width="480">
        <template slot-scope="scope">
          <div v-if="Array.isArray(scope.row.result)" class="pushResult_wrap">
            <div class="pushResult" v-for="(item,index) in scope.row.result" :key="index">
              <div class="resultCon" style="width:0;">
                <el-tooltip class="item" effect="dark" :content="`${item.sourceId}    ${item.title}`" placement="top">
                  <span>{{item.sourceId}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.title}}</span>
                </el-tooltip>
              </div>
              <div class="resultStatus">
                <span v-if="item.status=='SUCCESS'" class="bag-00B99C">{{$t("common_00299")}}</span>
                <span v-else-if="item.status == 'UNKNOWN'" class="bag-5278E2">{{$t('common_45006')}}</span>
                <span v-else class="bag-FA545E">{{$t("common_00300")}}</span>
              </div>
            </div>
          </div>
          <div v-else class="special_tips">{{scope.row.result}}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { requestUrl, ajax } from "@/assets/utils/network";
export default {
  /* 
  pushData 模板表格数据
  展示场站名称-交直流/储能设备数量--推送结果
  pushData: [
    {
      siteId: "", // 场站id
      siteName: "", // 场站名称 siteName--title 两个字段任何一个都可以
      fastChargers: "", // 当前场站的直流设备数量
      slowChargers: "", //  当前场站的交流设备数量
      energyStorageChargers: "", //  当前场站的储能设备数量
    },
  ],
 */

  props: {
    ratesType: Number, // 是否展示储能设备数量 1:代表展示
    immediatePush: {
      type: Boolean,
      default: false,
    },
    retry: {
      type: Boolean,
      default: false,
    },
    energy: {
      type: Boolean,
      default: true,
    }, // 是否推送能源站点
    pushVisible: {
      type: Boolean,
      default: true,
    }, // 停止查询结果
    pushData: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      // ****推送结果
      pushRatesData: [],
      selectLength: 0,
      deviceSettingStatusCount: 0,
      FLAG_PUSH: false,
      interval: 0,
      sidAndSiteId: [],
      catchsidAndSiteId: [], // 缓存正在查询推送结果的sid
    };
  },
  watch: {
    pushData: {
      handler(val) {
        this.pushRatesData = JSON.parse(JSON.stringify(val));
        // ****
        if (this.immediatePush) {
          // 立即推送---资费模板
          this.confirmPushCustom();
        }
      },
      deep: true,
      immediate: true,
    },
    pushVisible: {
      handler(val) {
        if (!val && this.interval) {
          for (let i = 1; i <= this.interval; i++) {
            window.clearInterval(i);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 某个站点推送失败数量
    formmatNum(val) {
      val = val || [];
      if (!val?.length) return 0;
      let temp = val.filter(
        (it) => !["SUCCESS", "UNKNOWN"].includes(it.status)
      );
      return temp.length;
    },
    // 重新推送某一个整站
    retryEvent(site) {
      for (let j = 0; j < this.catchsidAndSiteId.length; j++) {
        if (this.catchsidAndSiteId[j].site?.id == site.id) {
          this.catchsidAndSiteId.splice(j, 1);
          window.clearInterval(this.interval);
          this.sidAndSiteId = this.catchsidAndSiteId;
          this.polling();
          break;
        }
      }
      site.result = [];
      site.failNum = 0;
      this.getSiteById(site, 3);
    },
    // 确认推送
    confirmPushCustom() {
      console.log("确认推送数据：", this.pushRatesData);
      let info = this.pushRatesData;
      this.selectLength = 0;
      let ids = "";
      this.pushRatesData = [];
      if (info) {
        for (var i = 0; i < info.length; i++) {
          let obj = new Object();
          obj.id = info[i].siteId;
          if (ids.indexOf(obj.id) > 0) {
            continue;
          }
          ids = ids + "_" + obj.id;
          obj.title = info[i].siteName || info[i].title;
          obj.fastChargers = info[i].fastChargers || 0;
          obj.slowChargers = info[i].slowChargers || 0;
          obj.energyStorageChargers = info[i].energyStorageChargers;
          obj.chargeCount = obj.fastChargers + obj.slowChargers;
          if (this.energy) {
            obj.chargeCount += obj.energyStorageChargers;
          }
          if (obj.chargeCount && obj.chargeCount > 0) {
            this.selectLength++;
          }
          obj.info = info;
          this.pushRatesData.push(obj);
        }
      }
      this.deviceSettingStatusCount = 0;
      //得到选中的fee
      this.FLAG_PUSH = true;
      window.clearInterval(this.interval);
      //确认推送，按照站点依次推送
      let tableData = this.pushRatesData;
      if (!tableData || tableData.length == 0) {
        this.FLAG_PUSH = false;
      }
      this.sidAndSiteId = [];
      for (let i = 0; i < tableData.length; i++) {
        let site = tableData[i];
        if (site.chargeCount && site.chargeCount > 0) {
          this.getSiteById(site);
        }
      }
    },
    getSiteById(site, type = 1) {
      let url = requestUrl + "/site?id=" + site.id;
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: (response) => {
          let tableData = JSON.parse(response).result;
          let pushPay = tableData.userGroup;
          let slowList = [];
          let fastList = [];
          let setting = {};
          let defaultSlow = "";
          let defaultFast = "";
          for (var i = 0; i < pushPay.length; i++) {
            let pay = pushPay[i];
            slowList.push(pay.feePolicySlow);
            fastList.push(pay.feePolicyFast);
            if (pay.userGroupId == "0") {
              defaultSlow = pay.feePolicySlow;
              defaultFast = pay.feePolicyFast;
              continue;
            }
          }
          if (defaultSlow != "") {
            setting.defaultFeePolicyForSlowCharger = defaultSlow;
          }
          if (defaultFast != "") {
            setting.defaultFeePolicyForFastCharger = defaultFast;
          }

          setting.siteId = site.id;
          setting.feePolicyForSlowCharger = slowList;
          setting.feePolicyForFastCharger = fastList;
          if (this.energy) {
            //取能源id
            setting.energyStorageFeePolicyId =
              tableData.deviceSetting.esFeepolicyId;
          }
          this.pushSetting(setting, site, type);
        },
      });
    },
    pushSetting(setting, site, type = 1) {
      let om = this;
      ajax({
        type: "POST",
        url: requestUrl + "/device/setting/site",
        data: JSON.stringify(setting),
        dataType: "json",
        success(response) {
          let info = JSON.parse(response);
          let sid = info.sid;
          if (info.error != null) {
            if (info.error.code === "NOT_LOGIN") {
              window.location.href = "./login.html?l=0";
            }
            om.selectLength--; // 有失败的站点时---总站数减1
            if(info.error.code=='NOT_SUPPORT'&&info.error.reason?.type=='mutiFeePolicy') {
              om.$set(site, "result", om.$t('common_80007',{type:(info.error.reason.details||[]).join(',')}));
              return;
            }
            om.$message({
              type: "error",
              offset: 70,
              message:
                info.error.msg == null ? info.error.code : info.error.msg,
            });
          } else {
            if (!sid) {
              om.$message({
                type: "error",
                offset: 70,
                message: om.$parent.$t("common_45007"),
              });
              return;
            }
            window.setTimeout(() => {
              om.getSendDeviceSettingStatus(sid, site, type);
            }, 3000);
          }
        },
      });
    },
    getSendDeviceSettingStatus(sid, site, type) {
      let om = this;
      ajax({
        type: "GET",
        url: requestUrl + "/device/setting/status?sid=" + sid,
        dataType: "json",
        success: function (response) {
          if (type == 1) {
            om.deviceSettingStatusCount++;
          }
          let res = JSON.parse(response);
          if (res.error != null) {
            if (res.error.code == "NOT_LOGIN") {
              window.location.href = "./login.html?l=0";
            }
            om.$message({
              type: "error",
              offset: 70,
              message:
                info.error.msg == null ? info.error.code : info.error.msg,
            });
          } else {
            let result = res.result;
            if (result && result.length > 0) {
              let res = new Array();
              for (var i = 0; i < result.length; i++) {
                let o = result[i];
                if (o.status == "SUCCESS" || o.status == "success") {
                  o.status = "SUCCESS";
                  //返回结果时删除轮询的站点
                  for (var j = 0; j < om.sidAndSiteId.length; j++) {
                    if (om.sidAndSiteId[j].sid == sid) {
                      om.sidAndSiteId.slice(j, 1);
                      break;
                    }
                  }
                } else if (o.status == "UNKNOWN") {
                  o.status = "UNKNOWN";
                  var isExit = false;
                  for (var j = 0; j < om.sidAndSiteId.length; j++) {
                    if (om.sidAndSiteId[j].sid == sid) {
                      isExit = true;
                      break;
                    }
                  }
                  if (!isExit) {
                    om.sidAndSiteId.push({ sid: sid, site: site });
                  }
                } else {
                  o.status = false;
                  for (var j = 0; j < om.sidAndSiteId.length; j++) {
                    if (om.sidAndSiteId[j].sid == sid) {
                      om.sidAndSiteId.slice(j, 1);
                      break;
                    }
                  }
                }
                res.push(o);
              }
              om.$set(site, "result", res);
              if (om.retry) om.$set(site, "failNum", om.formmatNum(res));
              // om.chargeData();
              //当最后执行完最后一个、并且unknown的数量大于0时开启一次轮询
              // 全部站点推送
              if (
                type == 1 &&
                om.deviceSettingStatusCount == om.selectLength &&
                om.sidAndSiteId.length > 0
              ) {
                om.FLAG_PUSH = false;
                om.polling();
              }
              // 单个站点-重试推送
              if (type == 3 && om.sidAndSiteId.length > 0) {
                om.polling();
              }
            }
          }
        },
      });
    },
    polling() {
      var om = this;
      this.catchsidAndSiteId = this.sidAndSiteId;
      this.interval = window.setInterval(() => {
        if (om.sidAndSiteId && om.sidAndSiteId.length > 0) {
          var sidAndSite = om.sidAndSiteId;
          om.sidAndSiteId = [];
          for (var i = 0; i < sidAndSite.length; i++) {
            var obj = sidAndSite[i];
            om.getSendDeviceSettingStatus(obj.sid, obj.site, 2);
          }
        } else {
          window.clearInterval(om.interval);
        }
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  max-height: 556px;
  overflow-y: auto;
}
.special_tips {
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #fa545e;
}
.pushResult_wrap {
  max-height: 156px;
  overflow-y: auto;
}
.pushResult {
  display: flex;
  margin-top: 12px;
  line-height: 30px;
  border-bottom: 1px dashed #e6e7ec;
  .resultCon {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .resultStatus {
    width: 120px;
  }
}
.btn-bonner {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 12px;
  line-height: 1;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  border-radius: 20px;
}
.bag-838AA2 {
  @extend .btn-bonner;
  color: #838aa2;
  background: #eeeff1;
  border: 1px solid #838aa2;
}
.bag-5278E2 {
  @extend .btn-bonner;
  color: #5278e2;
  background: #e7f0ff;
  border: 1px solid #5278e2;
}
.bag-FA545E {
  @extend .btn-bonner;
  color: #fa545e;
  background: #feeeef;
  border: 1px solid #fa545e;
}
.bag-00B99C {
  @extend .btn-bonner;
  color: #00b99c;
  background: #e6f7f5;
  border: 1px solid #00b99c;
}
</style>

<style lang="scss" scoped>
.wrap,
.pushResult_wrap {
  /*滚动条整体样式*/
  &::-webkit-scrollbar {
    /*高宽分别对应横竖滚动条的尺寸*/
    width: 5px;
    background: #eff2f6;
    border-radius: 4px;
  }
  /*滚动条里面滑块*/
  &::-webkit-scrollbar-thumb {
    background: #9fa4b7;
    opacity: 0.4;
    border-radius: 4px;
  }

  // 上箭头
  &::-webkit-scrollbar-button:vertical:single-button:start {
    display: none;
  }
  // 下箭头
  &::-webkit-scrollbar-button:vertical:single-button:end {
    display: none;
  }
}
</style>