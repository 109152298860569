export default {
    platformLog_00001: "Trigger time",
    platformLog_00002: "Start date",
    platformLog_00003: "End date",
    platformLog_00004: "Charger SN",
    platformLog_00005: "Transaction ID",
    platformLog_00006: "Communication protocol",
    platformLog_00007: "Communication method",
    platformLog_00008: "Message key value",
    platformLog_00009: "Message data",
    platformLog_00010: "Message Translation",
    platformLog_00011: "Cloud Delivery",
    platformLog_00012: "Device report",
    platformLog_00013: "View",
    platformLog_00014: "Switch the original",
    platformLog_00015: 'Fuzzy matching, multiple separated by commas',
    platformLog_00016: "The date range cannot exceed 90 days, please select again!",
    platformLog_00018: "Please select a date!",
    platformLog_00019: "Please input content",
    platformLog_00020: "Message content",
    platformLog_00021: "Please enter message data segment",
    platformLog_00022: "Signed Data",
    platformLog_00023: "Signed Data",
    platformLog_00024: "Public Key",
}