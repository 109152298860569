export default {
    eur: "EUR",
    eur_name: "EUR",
    gbp: "GBP",
    gbp_name: "British Pound",
    sek: "SEK",
    sek_name: "Swedish Krona",
    hcf: "HCF",
    hcf_name: "Swiss Franc",
    cny: "CNY",
    cny_name: "CNY",
    usd: "USD",
    usd_name: "Dollar",
    rub: "RUB",
    rub_name: "Russian ruble",
    ils: "ILS",
    ils_name: "Israeli shekel",

    common_00001: "Log out",
    common_00002: "YYYY/MM/DD",
    common_00003: "YYYY/MM/DD HH:mm:ss",
    common_00004: "Stations",
    common_00005: "Transactions",
    common_00006: "Diagnostics",
    common_00007: "Security",
    common_00008: "Users",
    common_00009: "Marketing",
    common_00010: "Data",
    common_00011: "Finance",
    common_00012: "Diversion",
    common_00013: "Settings",
    common_00014: "Monitoring overview",
    common_00015: "Home",
    common_00016: "overview",
    common_00017: "Charger monitor",
    common_00018: "Annual statistics",
    common_00019: "Maintenance index",
    common_00020: "Abnormal transactions",
    common_00021: "Charging station management",
    common_00022: "Create charging station",
    common_00023: "Pricing management",
    common_00024: "Pricing management",
    common_00025: "Management of charging payment method",
    common_00026: "Pre-paid",
    common_00027: "Post-paid",
    common_00028: "Charger management",
    common_00029: "Management of charging payment methods for idle fees",
    common_00030: "Cloud-controlled parking lock",
    common_00031: "Stake-controlled parking  lock",
    common_00032: "Advertising",
    common_00033: "Charger advertising",
    common_00034: "Public advertising",
    common_00035: "Client site display tag",
    common_00036: "System-level marketing tag",
    common_00037: "Site-level operation tag",
    common_00038: "Energy consumption management",
    common_00039: "Energy consumption Statistics",
    common_00040: "Station Energy Management",
    common_00041: "Government platform docking",
    common_00042: "Franchise management",
    common_00043: "Invite franchise",
    common_00044: "Charging transactions",
    common_00045: "Individual transactions",
    common_00046: "Fleet transactions",
    common_00047: "Third-party transactions",
    common_00048: "Abnomal transactions",
    common_00049: "All transactions",
    common_00050: "Transactions",
    common_00051: "Occupancy invoice",
    common_00052: "Operation monitoring",
    common_00053: "Real time monitoring",
    common_00054: "Abnormal transactions",
    common_00055: "Maintenance index",
    common_00056: "Logs",
    common_00057: "Error logs",
    common_00058: "EV Charging Log",
    common_00059: "System logs",
    common_00060: "Troubleshooting",
    common_00061: "Current faults",
    common_00062: "Power module maintenance",
    common_00063: "Charging safety Policy",
    common_00064: "Station EV Charging Restrictions",
    common_00065: "High Risk EV Management",
    common_00066: "Active real-time protection",
    common_00067: "blacklist",
    common_00068: "Users",
    common_00069: "User management",
    common_00070: "User classification",
    common_00071: "Category Management",
    common_00072: "Fleets",
    common_00073: "Paid by the fleet",
    common_00074: "Paid by the individual",
    common_00075: "Fleets",
    common_00076: "Charging Cards",
    common_00077: "Charging Card Groups",
    common_00078: "PnC card",
    common_00079: "Charging Cards",
    common_00080: "Offline Start-stop card",
    common_00081: "Offline Stored-value card",
    common_00082: "EV car",
    common_00083: "EV car Management",
    common_00084: "Wechat",
    common_00085: "Customer service",
    common_00086: "Member",
    common_00087: "Member Management",
    common_00088: "Deals&Coupons",
    common_00089: "Vouchers",
    common_00090: "Discounts",
    common_00091: "Credits",
    common_00092: "Electricity Tickets",
    common_00093: "Service fee voucher",
    common_00094: "Marketing Campaigns",
    common_00095: "Top-up rebate",
    common_00096: "Tickets For free",
    common_00097: "Sign up & refund activity",
    common_00098: "Charging refund",
    common_00099: "Total fefund Campaign",
    common_00100: "Accumulate charging activity",
    common_00101: "Gaining customers",
    common_00102: "Inviting friends",
    common_00103: "Deals&Coupons sale",
    common_00104: "New Deals&Coupons",
    common_00105: "Orders",
    common_00106: "Sales Consumption Statistics",
    common_00107: "Deals&Coupons distribution",
    common_00108: "Redeem code",
    common_00109: "Records",
    common_00110: "Consumption statistics of redeemed offers",
    common_00111: "Utilization Analysis",
    common_00112: "Utilization",
    common_00113: "Utilization By Station",
    common_00114: "Charger-level analysis",
    common_00115: "Peak Hours",
    common_00116: "Average power analysis",
    common_00117: "Utilization By Franchisee",
    common_00118: "Electricity Analysis",
    common_00119: "Electricity",
    common_00120: "Electricty By Station",
    common_00121: "Electricty By Fleet",
    common_00122: "User source level analysis",
    common_00123: "Electricty By Franchisee",
    common_00124: "Revenue Analysis",
    common_00125: "Revenue",
    common_00126: "Revenue By Station",
    common_00127: "Revenue By Fleet",
    common_00128: "Gross interest analysis",
    common_00129: "User source level analysis",
    common_00130: "Revenue By Franchisee",
    common_00131: "Charging price amount Analysis",
    common_00132: "User Analysis",
    common_00133: "User",
    common_00134: "User By Station",
    common_00135: "User source level analysis",
    common_00136: "User contribution analysis",
    common_00137: "User RFM modeling",
    common_00138: "User By Franchisee",
    common_00139: "Card&coupon Analysis",
    common_00140: "Sales trend Analysis",
    common_00141: "Sales distribution Analysis",
    common_00142: "Top-up analysis",
    common_00143: "Top-up amount analysis",
    common_00144: "Analysis of top-up number",
    common_00145: "Refund analysis",
    common_00146: "Refund amount analysis",
    common_00147: "Refund number analysis",
    common_00148: "Wallet analysis",
    common_00149: "Wallet balance trend analysis",
    common_00150: "Analysis of wallet balance consumption patterns",
    common_00151: "Common Functions",
    common_00152: "Individual Invoice",
    common_00153: "Group Invoice",
    common_00154: "Wallet refund acceptance",
    common_00155: "Order Inquiry",
    common_00156: "Financial Statistics",
    common_00157: "Recharge amount",
    common_00158: "Refund amount",
    common_00159: "User Wallet Balance",
    common_00160: "Amount of card coupon sales",
    common_00161: "Financial Settings",
    common_00162: "User top-up settings",
    common_00163: "User refund settings",
    common_00164: "User invoicing settings",
    common_00165: "Third Party Reserve Management",
    common_00166: "Group self-service end invoicing settings",
    common_00167: "Group self-service Top-Up Settings",
    common_00168: "Self-service payment",
    common_00169: "Platform Billing",
    common_00170: "Account Management",
    common_00171: "Ticket an invoice",
    common_00172: "Invoice details",
    common_00173: "Platform invoicing",
    common_00174: "Open Leads",
    common_00175: "Map Platform",
    common_00176: "Operating Vehicle Platform",
    common_00177: "Integration Platform",
    common_00178: "Government Platform",
    common_00179: "Data flow analysis",
    common_00180: "Third-party data flow charging",
    common_00181: "Operation Log",
    common_00182: "Account Management",
    common_00183: "Limit of authority Management",
    common_00184: "Other settings",
    common_00185: "API interface view",
    common_00186: "Operator",
    common_00187: "Information Setting",
    common_00188: "Accounts & Permissions",
    common_00189: "Client Setting",
    common_00190: "Wechat",
    common_00191: "Alipay",
    common_00192: "Message template",
    common_00193: "Add vehicle",
    common_00194: "Edit vehicle information",
    common_00195: "Vehicle license no",
    common_00196: "Vehicle VIN (order read)",
    common_00197: "telephone",
    common_00198: "Vehicle model|(optional)",
    common_00199: "Location|(optional)",
    common_00200: "Province/state, city and district",
    common_00201: "Modified successfully",
    common_00202: "Information",
    common_00203: "Charger",
    common_00204: "Start time",
    common_00205: "End time",
    common_00206: "Electricity fee",
    common_00207: "Service fee",
    common_00208: "Idle fee",
    common_00209: "KWh",
    common_00210: "Minute",
    common_00211: "App user",
    common_00212: "Add",
    common_00213: "Modify",
    common_00214: "Delete",
    common_00215: "Cancel",
    common_00216: "Deleted successful",
    common_00217: "Save",
    common_00218: "Station",
    common_00219: "Connector",
    common_00220: "Create time",
    common_00221: "Charging start time",
    common_00222: "Charging End Time",
    common_00223: "Charge duration",
    common_00224: "Elect charged",
    common_00225: "Discount",
    common_00226: "Private card",
    common_00227: "Deposit card",
    common_00228: "Online card",
    common_00229: "Details",
    common_00230: "Fleet",
    common_00231: "third-party user",
    common_00232: "Coupon sales order",
    common_00233: "Notify",
    common_00234: "Confirm",
    common_00235: "All",
    common_00236: "Self-owned station",
    common_00237: "Franchisee",
    common_00238: "Please enter site name",
    common_00239: "Search",
    common_00240: "Clear",
    common_00241: "Added successfully",
    common_00242: "Hour",
    common_00243: "Administrator",
    common_00244: "No",
    common_00245: "All station",
    common_00246: "Change password",
    common_00247: "Current login password",
    common_00248: "New login password",
    common_00249: "Please use letters and numbers",
    common_00250: "Current account login password",
    common_00251: "Please enter current password",
    common_00252: "Please enter password",
    common_00253: "Please enter password again",
    common_00254: "The confirmation password is not identical",
    common_00255: "Search",
    common_00256: "M",
    common_00257: "F",
    common_00258: "Profile",
    common_00259: "Name",
    common_00260: "Gender",
    common_00261: "Wallet balance",
    common_00262: "User details",
    common_00263: "View charging order",
    common_00264: "WeChat Official Account",
    common_00265: "Reminder",
    common_00266: "Cancel",
    common_00267: "Start to download",
    common_00268: "Enter again",
    common_00269: "User EV certification",
    common_00270: "User blacklist",
    common_00271: "Reset",
    common_00272: "Finish",
    common_00273: "Yes",
    common_00274: "No",
    common_00275: "Parameter is empty",
    common_00276: "Please enter a positive integer less than 100.",
    common_00277: "Operator tariff",
    common_00278: "Top-up",
    common_00279: "Unit",
    common_00280: "required",
    common_00281: "Setup succeeded",
    common_00282: "Operation",
    common_00283: "Vehicle make",
    common_00284: "Vehicle model",
    common_00285: "Input manually",
    common_00286: "Vehicle model|make",
    common_00287: "Please save the previous operation first",
    common_00288: "Cancel operation",
    common_00289: "DC",
    common_00290: "AC",
    common_00291: "Gateway",
    common_00292: "Unavailable",
    common_00293: "Enabled",
    common_00294: "Setting",
    common_00295: "Push feedback",
    common_00296: "{allDevice} equipments totally, {sucDevice} succeeded, {faiDevice} failed.",
    common_00297: "Equipment number",
    common_00298: "Equipment name",
    common_00299: "Success",
    common_00300: "Fail",
    common_00301: "Waiting for response",
    common_00302: "Offline",
    common_00303: "Close",
    common_00304: "Station management",
    common_00305: "Push tariff",
    common_00306: "Ad Management",
    common_00307: "Charger maintenance",
    common_00308: "Third-party functions",
    common_00309: "Remove",
    common_00310: "Select",
    common_00311: "Back",
    common_00312: "Start date",
    common_00313: "End date",
    common_00314: "Select Stations",
    common_00315: "Select Franchisees",
    common_00316: "Select equipment model",
    common_00317: "Select Fleets",
    common_00318: "OEM platform",
    common_00319: "Start",
    common_00320: "Charging Operation Platform",
    common_00321: "Full Screen",
    common_00322: "Zoom out",
    common_00323: "Export data",
    common_00324: "Location",
    common_00325: "EV MAC",
    common_00326: "Optional",
    common_00327: "Select",
    common_00360: "Basic information",
    common_00361: "Offer site",
    common_00362: "Team member management",
    common_00363: "Order query",
    common_00364: "Charge statistics",
    common_00365: "Edit",
    common_10000: 'The activity type',
    common_10001: 'Number of activities',
    common_10002: 'Create activities',
    common_10003: 'Maintenance homepage',
    common_10004: 'Maintenance center',
    common_10005: 'Analysis of yard maintenance',
    common_10006: "Maintenance page",
    common_10007: "custom",
    common_10008: "Financial homepage",
    common_10009: "Are you sure to access the old version V2 operating platform?<br/>Since February 28, 2022, the boss V2 operating platform will not be maintained, please be informed!",
    common_20008: 'Please enter a station name',
    common_20009: "Activity overview",
    //能源中心
    common_20010: 'Energy monitor',
    common_20011: 'Performance monitor',
    common_20012: 'Net Zero pile management',
    common_20013: 'Energy statistics',
    common_20014: 'Energy storage',
    common_20015: 'Statistical',
    common_20016: 'Energy',
    common_20017: 'Max power mode',
    common_20018: 'Eco economic mode',
    common_20019: 'Auto mode',
    common_20020: 'Available',
    common_20021: 'Battery charging',
    common_20022: 'Battery discharging',
    common_20023: 'minute full',
    common_20024: 'Current working mode',
    common_20025: 'Mode',
    common_20026: 'NZS',
    common_20027: 'Battary standby',
    common_20028: 'Battery failure',
    common_20029: 'Eco mode operates according to your grid tariff during valley hours and peak hours. But you need to choose whether the normal period cost is closer to the valley period cost or the peak period cost. ',
    common_20030: 'closer to the valley period',
    common_20031: 'closer to peak hours',
    common_20032: 'It is found that you have not set the grid tariff for this station',
    common_20033: 'Select grid tariff',
    common_20034: 'New grid tariff',
    common_20035: 'The grid tariff must be set with three periods of peak, flat and valley or four label periods of peak, peak, flat and valley. ',
    common_20036: 'Please select which time period is closer to the normal time cost',
    common_20037: 'The grid tariff must be set to three periods of peak, flat and valley or four label periods of peak, peak, flat and valley',
    common_20038: 'Economic mode',
    common_20039: 'Efficient mode',
    common_20040: 'Storage and charge air conditioner operation mode',
    common_20041: 'Storage and charge working mode',
    common_20042: "The power demand of the vehicle is the first priority, and it is replenished as needed. The DCDC module is used first to charge the vehicle.",
    common_20043: "To make up for the insufficient output power of the station, use energy storage to provide power that is in short supply.",
    common_20044: "The lowest CPO cost can be achieved based on the peak and off-peak status of electricity prices issued by the platform.",
    common_20045: "Peak shaving and valley filling are used to recharge the energy storage when electricity prices are off-peak, and energy storage is used to charge the car during peak electricity prices.",
    common_20046: "Automatically control charging and discharging according to the electricity bill policy issued by the platform.",
    common_20047: "Automatically adjust the power demand and electricity price cost to meet the vehicle charging power while still having enough energy for energy storage and replenishment.",
    common_20048: "Strategy Introduction",
    common_20049: "Application Scenario",
    common_20050: "Please enter the card number",
    common_20051: "Please enter the source",
    common_20052: "Faulted",
    common_20053: "Battery standby",
    common_20054: "Today",
    common_20055: "Yesterday",
    common_20056: "Ratio",
    common_20057: "Working Mode Introduction",
    common_22000: "WhiteList",
    common_22001: "SOC limit",



    common_10010: "v2 entry",
    // 工单
    common_31000: "Ticket system",
    common_31001: "Ticket management",
    common_31002: "Ticket category",
    common_31003: "Tickets stats",
    common_31004: "Knowledge bank",
    common_31005: "Please bind the official account first",
    common_31006: "Initiate ticket",
    common_31007: "Automatic notification",
    common_40000: 'Charger life maintenance',
    common_41000: 'Please fill in correctly',
    common_41001: 'Automatic ticket trigger',

    common_50004: "CPO Management",
    common_50005: "Mobile",
    common_50006: "Charger Management",
    common_51000: "Transactions",
    common_51001: "Abnormal transactions",
    common_51002: "Abnormal transactions",
    common_20053: "Standby",
    common_62001: "Processing suggestions:",
    common_62002: "Troubleshooting suggestions:",

    // 资费推送结果补充
    common_45000: "Site name",
    common_45001: "Number of devices",
    common_45002: "Device push results",
    common_45003: "DC",
    common_45004: "AC",
    common_45005: "Net Zero",
    common_45006: "Push in progress",
    common_45007: "Push failed",
    common_45008: "Set group",
    // 到期倒计时
    common_11000: "The charging service has been stopped",
    common_11001: "Due in {0} days",
    common_11002: "Automatic renewal failed, and the charging service will stop after {0} days",
    common_11003: "Platform bill deduction failure, {0} bill involved pile will stop charging service ",
    common_11004: "The platform has expired",
    common_110001: "The charging service of the pile involved in this month's bill has been stopped ", 
    common_11006: "No Data",
    common_45009: "Open the service",
    common_45010: "Privacy Policy",
    // 卡券
    common_00366: "卡券消耗分析",
    common_45011: "Users of affiliated carriers do not have permission to view the information",
    // 充电口状态
    common_60000: "Occupied",
    common_60002: "Battery abnormal",
    common_60003: "Faulted/Offline",
    common_60004: "Reserved",
    common_60005: "view",
    common_60006: "Inserted",
    common_60007: "Charging",
    common_60008: "Pending EV move",
    common_60009: "Finished",
    common_60010: "Microgrid overview",
    common_60011: "Microgrid settings",
    // 资费弹窗
    common_61000: "Charges name",
    common_61001: "Please enter the tariff name",
    common_61002: "This tariff name is already occupied",
    common_61003: "charging",
    common_61004: "Grid Tariff",
    common_61005: "Use currency",
    common_61006: "Add time",
    common_61007: "A maximum of {0} time periods can be added",
    common_61008: "Start time",
    common_61009: "End time",
    common_61010: "Grid electricity({type}/{unit})",
    common_61011: "Electricity\n({type}/{unit})",
    common_61012: "Service Charge\n({type}/{unit})",
    common_61013: "Idle fee\n({type}/min)",
    common_61014: "Peak/valley/flat time period",
    common_61015: "Peak pinggu corresponding price",
    common_61016: "Peak valley normal section",
    common_61017: "Peak",
    common_61018: "Mid-Peak",
    common_61019: "Off-Peak",
    common_61020: "Super Off-Peak",
    common_61021: "Please enter the tariff",
    common_61022: "Please select the time-period label",
    common_61023: "Please set up the time interval continuously from 0:00 to 24:00",
    common_61024: "Please enter the correct tariff",
    common_61025: "Operation successful",
    common_61026: "Operation failed",
    common_61027: "Back",
    common_61028: "Next",
    common_61029: "Undefined",
    common_61030: "Please change the default tariff first",
    common_61031: "Scope",
    common_61032: "Public rates",
    common_61033: "Fleet rates",
    common_61034: "Apply rates",
    common_61035: "AC chargers",
    common_61036: "DC chargers",
    common_61037: " ({0}Stations)",
    common_61038: "The application scope of the tariff is as follows. Are you sure to continue to modify?",
    common_61040: "New charges",
    common_61041: "Edit Charges",
    common_61042: "0-If 0-24 hours is one single period type, please select the 'flat' time-period label",
    common_61043: "If 0-24 hours has multiple time period type, please select more than two time-period labels",
    common_61044: "The label of the time-period corresponding to the electricity price is incorrect, please check, it should be 'Peak > Mid-Peak > Off-Peak > Super Off-Peak'",
    common_61045: "Working mode",
    common_61046: "Station working mode",
    common_61047: "Learn more",
    common_61048: "Station air condition mode",
    common_61049: "There is no grid tariff selected for the current station. Please select the grid tariff first and then switch the working mode",
    common_61050: 'There is only one time period lable for current grid tariff, and "Eco" and "Auto" modes cannot be selected',
    common_61051: 'The current grid tariff has only two time period labels, and the "Auto" mode cannot be selected',
    common_61052: "The current grid tariff period and working mode do not match. Please modify the grid tariff or working mode",
    common_61053: "Push successful",
    common_61054: "Device offline",
    common_61055: "Select Tariff",
    common_61056: "This site needs to select a tariff type of {type}, there is currently no such tariff template, please create a new tariff.",
    common_61057: "charges",
    common_61058: "Apply scope",
    common_61059: "Please select site",
    common_61060: "DC",
    common_61061: "AC",
    common_61062: "Public",
    common_61063: "group",
    common_61064: "The third party",
    common_61065: "Confirm whether to bind the tariff to",
    common_61066: "site",
    common_61067: "the",
    common_61068: "binding",
    common_61069: "retry",
    common_61070: "Please bind the staked site first",
    common_61071: "Please select a group",
    common_61072: "Select third-party traffic",
    common_61073: "Work mode time SCH",
    common_61074: "Please select the time schedule of custom work mode",
    common_61075: "Used",
    common_61076: "Unused",
    common_61077: "push",
    common_61078: "Grid-connected Electricity Fees({type}/{unit})",
    common_61080: "Allow to use PV energy",
    common_61081: "When you choose yes, it means that use PV energy to storage or to charge vehicle is allowed. When you choose no, it means that use PV energy is not allowed.",
    common_61082: "Max PV power of the station",
    common_61083: "The time to receive PV power",
    common_61084: "PV settings",
    common_61085: "PV management",
    common_61086: "supplying energy",
    common_61087: "not supplying energy",
    common_61088: "Total energy supplied today",

    common_63010: "Please enter content",
    common_63013: "Add charger",

    // 自定义模式
    common_70000: "Custom mode",
    common_70001: "View all time schedules",
    common_70002: "CPO can freely configure the work mode according to your own experience",
    common_70003: "The electricity price of the power grid changes from time to time, and the busy and idle conditions of the stations change periodically, or the energy demand of the stations is adjusted periodically, such as customized energy dispatching.",
    common_70004: "New",
    common_70005: "Customized working mode time schedule (year)",
    common_70006: "Time schedule name",
    common_70007: "Please enter time schedule name",
    common_70008: "Add date range",
    common_70009: "Weekly schedule",
    common_70010: "Month",
    common_70011: "Day",
    common_70012: "Duplicate",
    common_70013: "Electric energy usage mode",
    common_70014: "Edit 0-24 hour schedule",
    common_70015: "Add date range",
    common_70016: "Add weekly schedule",
    common_70017: "NZS supports Grid to Vehicle (GtoV for short), Battery to Vehicle (BtoV for short), Grid to Battery (GtoB for short), Battery to Grid (B2G for short), PV to Battery (P2B for short). Please select the use mode of electric energy by dividing time periods according to experience.",
    common_70018: 'For example, the priority arrangement of GtoV>BtoV>GtoB means "recharge the battery when there is no car to charge, use the power grid first when there is a car to charge, and then use the battery"; the priority arrangement of BtoV>GtoV>GtoB means "recharge the battery when there is no car to charge, use the battery first when there is a car to charge, and then use the power grid", and so on',
    common_70019: "Add time period",
    common_70020: "Electric energy usage mode",
    common_70021: "Please enter the name",
    common_70022: "to",
    common_70023: "Mon",
    common_70024: "Tue",
    common_70025: "Wed",
    common_70026: "Thu",
    common_70027: "Fri",
    common_70028: "Sat",
    common_70029: "Sun",
    common_70030: "Copy Succeeded",
    common_70031: "Paste",
    common_70032: "Copied",
    common_70033: "According to the date range and the weekly schedule in the date range, to formulate a schedule for the work mode of equipment in a year. After being pushed to NZS charger end, it will be carried out circularly every year according to the schedule. If there is any modification, it needs to be pushed to NZS charger end again, after the schedule is changed.",
    common_70034: "Incorrect fill the time and date",
    common_70035: "Electric energy usage mode cannot be empty",
    common_70036: "The period should start from 0 to end at 24",
    common_70037: "Please fill in the 0-24 hour schedule",

    common_63000: "Search ",
    common_63001: "Please enter the order number ",
    common_63002: "Please enter nickname/phone number/license plate number/frame number ",
    common_63003: "Please enter device name/device serial number ",
    common_63004: "Please enter the station name ",
    common_63005: "Device ",
    common_63006: "Please enter a community name ",
    common_63007: "Order ",
    common_63008: "Field Station ",
    common_63009: "Plug and Charge card ",
    common_63010: "Please enter content ",
    common_63011: "Already exists ",
    common_63012: "Invalid ",
    common_63013: "Add Device ",
    common_62000: "Opening Privileged Members",
    common_62001: "Processing suggestions:",
    common_62002: "Troubleshooting suggestions:",
    common_61134: "Apply rates",
    common_61147: "select all",
    common_61148: "Apply all AC chargers",
    common_61149: "Apply all DC chargers",
    common_61150: "Confirm to bind rates to all AC chargers",
    common_61151: "Confirm to bind rates to all DC chargers",
    common_61152: "All binding successful",
    common_61153: "Some sites failed to bind. Please try again",
    common_61154: "Do not close popover while binding",

    common_61160: "Please fill in the power correctly",
    common_61161: "Please fill in the correct time",
    common_80000: "Contains at least 6 characters, including at least 1 digit, 1 letter, and 1 special character",
    common_61145: "Parameter error",

    common_71100: "Admission Notice",
    common_71101: "Issue",
    common_71102: "Suggestion",
    common_71003: "Contact Name",
    common_71004: "Please add a issue",
    common_71005: "Description of actio",
    //工单编辑器
    common_71006: "Upload file ",
    common_71007: "Video Upload ",
    common_71008: "Add Video Link ",
    common_71009: "Please enter video link ",
    common_71010: "Drag file here, or ",
    common_71011: "Click to upload ",
    common_71012: "Only MP4 files can be uploaded and do not exceed {maxSize}M",
    common_71013: "Local Video Upload ",
    common_71014: "The associated information sent to the XCHARGE ERP system has not been set. Please contact the administrator or your sales consultant to set it up.",
    common_71015: "This operator has not set the associated information sent to the Kingdee ERP system. Please contact the administrator to set it up.",
    common_71016: "Use Accessories",
    common_71017: "Please enter the valid period",
    common_80005: "Remaining",
    common_80006: "Exceeded",
    common_80007: "{type} currently does not support multi-rate tariff settings. Please modify the tariff to a single-rate tariff.",
    common_80008: "To modify the tariff",
    common_80009: "Please confirm that this tariff has not been applied to any stations with C6AM,C7NE,C7NA equipment, as C6AM,C7NE,C7NA currently does not support multi-rate tariff settings.",

    // 腾讯地图
    common_80010: "Please choose the coordinate of charging staion",
    common_80011: "Address, street, and house name",
    common_80012: "Search",
    common_80013: "Name",
    common_80014: "Detailed address",
    common_80015: "Current position",
    common_80016: "Cancel",
    common_80017: "Use this location",

    //急停恢复
    common_13001: "Emergency stop recovery",
    common_13002: "This operation requires the device firmware version to be above 3.0.0",
    common_13003: "Component issue detected. Please repair soon.",
    common_13004: "SuspendedEV",

}
