export default {
  safetyFence_00001: "针对Xcharge的直流充电桩设置，可以在每次充电中监控车和桩的安全保护项信号，如果信号触碰到阈值围栏，则会发送平台告警码告警，并根据严重程度，进行告警、降功率或停充操作。此操作要求充电桩的Android应用的固件版本号在4.0.0以上。",
  safetyFence_00002: "开启安全围栏功能",
  safetyFence_00003: "(取值范围{min}-{max}{uint})",
  safetyFence_00004: "请等待，关闭窗口后，会在后台继续推送，但无法查看推送结果",
  safetyFence_00005: "场站名称",
  safetyFence_00006: "请先保存其他操作",
  safetyFence_00007: "请按照取值范围填写阈值",
  safetyFence_00008: "安全围栏功能未启用，请启用后再推送。",
  safetyFence_00009: "每次最多推送30个设备",
  safetyFence_00010: "保护项",
  safetyFence_00011: "围栏说明",
  safetyFence_00012: "围栏阈值",
  safetyFence_00013: "告警码",
  safetyFence_00014: "保护策略",
  safetyFence_00015: "请先选择设备",
  safetyFence_00016: "",
  safetyFence_00017: "",
  safetyFence_00018: "",
  safetyFence_00019: "",
  safetyFence_00020: "",
  safetyFence_00021: "",
  safetyFence_00022: "",
  safetyFence_00023: "",
  safetyFence_00024: "",
  safetyFence_00025: "",
  safetyFence_00026: "",
  safetyFence_00027: "",
  safetyFence_00028: "",
  safetyFence_00029: "",
  safetyFence_00030: "",
  safetyFence_00031: "",
  safetyFence_00032: "",
};