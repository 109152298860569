export default {
    powerAllocate_00001: "Setting",
    powerAllocate_00002: 'Successfully modified. Please return to the "Power Allocation" page to push the configuration information to the charging pile!',
    powerAllocate_00003: "The maximum charging time supports one decimal place",
    powerAllocate_00004: "Total charging power limit setting in the group",
    powerAllocate_00005: "Add period",
    powerAllocate_00006: "(If it is set to 0, the total charging power in the group in this period is not limited)",
    powerAllocate_00007: "Please enter the power allocation group name",
    powerAllocate_00008: "Please complete the setting of limiting the total charging power in the group!",
    powerAllocate_00009: "Total distributed power(kW)",
    powerAllocate_00010: "Actual total charging power(kW)",
    powerAllocate_00011: "Total limited power(kW)",
    powerAllocate_00012: 'Power distribution group name',
    powerAllocate_00013: 'Basic information',
    powerAllocate_00014: 'Enable',
    powerAllocate_00015: 'The total charging power of the whole group can be set according to the time period to ensure that the charging power of the charging pile in the group does not exceed the total charging power of the whole group in each time period.',
    powerAllocate_00016: "Total charging power limit setting in the group",
    powerAllocate_00017: "Setting of reserved charging power and maximum charging time in the group",
    powerAllocate_00018: "Total limited charging power(kW)",
    powerAllocate_00019: "Please complete the setting of limiting the total charging power in the group!",
    powerAllocate_00020: "Total distributed power(kW)",
    powerAllocate_00021: "Actual total charging power(kW)",
    powerAllocate_00022: "Limit total power(kW)",
    powerAllocate_00023: "You can set the reserved charging power and maximum charging time for the charging port in the group according to the product model",
    powerAllocate_00024: "Reserved charging power: the minimum charging power reserved for uncharged charging ports in the group when the platform automatically allocates charging power. The default value is 0kw, which means no charging power is reserved",
    powerAllocate_00025: "Maximum charging time: the maximum charging time of the charging port in the group when the platform automatically allocates charging power. Default 24 hours",
    powerAllocate_00026: "The offline maximum charging power of the charging port is the reserved charging power in this setting",
    powerAllocate_00027: "Product model",
    powerAllocate_00028: "Maximum charging power of charging port (kw)",
    powerAllocate_00029: "Minimum charging power of charging port(kW)",
    powerAllocate_00030: "Reserved charging power(kW)",
    powerAllocate_00031: "Maximum charging time(hour)",
    powerAllocate_00032: "Display diagram of total charging power distribution in the group",
    powerAllocate_00033: "Nearly 300 days",
    powerAllocate_00034: "Nearly 24 hours",
    powerAllocate_00035: "No data",
    powerAllocate_00036: "Please enter the correct number format",
    powerAllocate_00037: "Please enter the reserved charging power that is greater than the minimum power of the charging port and less than the maximum power of the charging port",
    powerAllocate_00038: "The reserved charging power shall not be negative",
    powerAllocate_00039: "The reserved charging power supports up to two decimal places",
    powerAllocate_00040: "The maximum charging time shall be greater than 0",
    powerAllocate_00041: "The period of limiting the total charging power shall start at 00:00 and end at 24:00",
    powerAllocate_00042: "The power of the whole station shall not be negative",
    powerAllocate_00043: "The power of the whole station supports up to two decimal places",
    powerAllocate_00044: "Station details",
    powerAllocate_00045: "Set intra-group power allocation",
    powerAllocate_00046: "You can set this parameter only when it is disabled",
    powerAllocate_00047: "Number of charging ports",
    powerAllocate_00048: "The sum of the maximum offline charging power of all models of charging ports x the number of charging ports in the group must be less than or equal to the minimum value in the setting of the total limited charging power in the group",
}