export default {
    orderlyAnalogCharging_00001: "Simulationslauf",
    orderlyAnalogCharging_00002: "Hilfe",
    orderlyAnalogCharging_00003: "Aufladen des Fahrzeugs",
    orderlyAnalogCharging_00004: "Autofahren",
    orderlyAnalogCharging_00005: "Begrenzen Sie die Gesamtladeleistung",
    orderlyAnalogCharging_00006: "Tatsächliche Ladeleistung",
    orderlyAnalogCharging_00007: "Fahrzeugcode",
    orderlyAnalogCharging_00008: "Mindest-SOC für den Abflug erforderlich (%)",
    orderlyAnalogCharging_00009: "Abfahrtszeit",
    orderlyAnalogCharging_00010: "Zeit zurück drehen",
    orderlyAnalogCharging_00011: "Elektrizität",
    orderlyAnalogCharging_00012:"1. Starten Sie den Ladevorgang, wenn der Ladestapel offline ist. Die Plattform kann die Gesamtlast nicht kontrollieren, also bitte mit Vorsicht vorgehen! <br/>2. Der Bahnhofstarif ist auf Verspätungsentgelt eingestellt und die geordnete Ladefunktion von Bussen kann nicht genutzt werden! <br/> 3. Beim Aufladen des Fahrzeugs springt die Waffe und der Ladevorgang muss manuell neu gestartet werden!",
}