
export default {
  cardGroup_00001: "Confirm to remove the charge card group {title}?",
  cardGroup_00002: "A total of {allDevice} devices were pushed, {sucDevice} succeeded and {faiDevice} failed.",
  cardGroup_00003: "Operating mode|(choose one)",
  cardGroup_00004: "{id}(Lost)",
  cardGroup_00005: "Card cannot be read! Tip: Make sure the card reader is connected and place the NFC card on the card reader.",
  cardGroup_00006: "Card is not empty ",
  cardGroup_00007: "Card is emptu",
  cardGroup_00008: "Card writing failure：{msg}",
  cardGroup_00009: "Charging card group details",
  cardGroup_00010: "Operator charging card management",
  cardGroup_00011: "Charging Cards Group",
  cardGroup_00012: "Group information",
  cardGroup_00013: "Stations",
  cardGroup_00014: "Send whitelist",
  cardGroup_00015: "Check top-up records",
  cardGroup_00016: "Transactions",
  cardGroup_00017: "Delete card group",
  cardGroup_00018: "Operation is cancelled",
  cardGroup_00019: "Charging card group Details - Online card group",
  cardGroup_00020: "Charging card group Details - Offline card group",
  cardGroup_00021: "Charging card group Details - Super card group",
  cardGroup_00022: "If the device is offline, after applying the whitelist, charging cards from this charging card group can be supported to initiate charging. After creating and losing a card, the whitelist needs to be applied again.",
  cardGroup_00023: "Retry",
  cardGroup_00024: "Device No.",
  cardGroup_00025: "Device name",
  cardGroup_00026: "Push Results",
  cardGroup_00027: "Success",
  cardGroup_00028: "False",
  cardGroup_00029: "Waiting for response",
  cardGroup_00030: "Close",
  cardGroup_00031: "Push failed, no related charging site.",
  cardGroup_00032: "Push failure",
  cardGroup_00033: "Send whitelist",
  cardGroup_00034: "Online card group",
  cardGroup_00035: "Offline card group",
  cardGroup_00036: "Super card group",
  cardGroup_00037: "Charging card group name",
  cardGroup_00038: "Modify",
  cardGroup_00039: "Save",
  cardGroup_00040: "Cancel",
  cardGroup_00041: "Login account",
  cardGroup_00042: "Login password",
  cardGroup_00043: "Download NFC（APP）",
  cardGroup_00044: "New ID card",
  cardGroup_00045: "ID cards are entered for users. The card number needs to be recognized by the NFC card reader program. The ID card can be topped up. You don't need to configure the charger to swipe your card for charging.",
  cardGroup_00046: "New plug-and-charge card",
  cardGroup_00047: "Plug-and-charge cards are entered for users. The card number needs to be the vehicle MAC. The Plug-and-charge card can be topped up. It is necessary to configure the charger to enable plug and play charging.",
  cardGroup_00048: "Please enter your card no., user name, mobile number and reference number",
  cardGroup_00049: "Card type",
  cardGroup_00050: "Card number",
  cardGroup_00051: "Aux number",
  cardGroup_00052: "User name(Mobile number)",
  cardGroup_00053: "Vehicle license number",
  cardGroup_00054: "Card type",
  cardGroup_00055: "Offline start/stop card",
  cardGroup_00056: "Offline value-stored card",
  cardGroup_00057: "Online identity card",
  cardGroup_00058: "PnC card",
  cardGroup_00059: "Configuration card",
  cardGroup_00060: "Balance",
  cardGroup_00061: "Operate",
  cardGroup_00062: "Detail",
  cardGroup_00063: "Please save the previous operation",
  cardGroup_00064: "Parameter is empty",
  cardGroup_00065: "New ID card",
  cardGroup_00066: "Source",
  cardGroup_00067: "Contact name",
  cardGroup_00068: "Mobile",
  cardGroup_00069: "Key",
  cardGroup_00070: "Recharge amount",
  cardGroup_00071: "Login password",
  cardGroup_00072: "Successfully made charging card",
  cardGroup_00073: "allowable",
  cardGroup_00074: "「Charging card management」",
  cardGroup_00075: "Charging card group",
  cardGroup_00076: "「Detail」",
  cardGroup_00077: "Perform the following operations in:",
  cardGroup_00078: "1、Top-up for the memory type charge card；",
  cardGroup_00079: "2、Check top-up record；",
  cardGroup_00080: "3、Modify contact details of charging card；",
  cardGroup_00081: "Confirm",
  cardGroup_00082: "Next step",
  cardGroup_00083: "Please enter indentity No.",
  cardGroup_00084: "Please enter correct No.",
  cardGroup_00085: "Chinese should not be included in card No. ",
  cardGroup_00086: "Please enter the source of card",
  cardGroup_00087: 'Source not allowed to fill in "PnC"',
  cardGroup_00088: "Please enter correct license plate number",
  cardGroup_00089: "Please enter correct mobile number",
  cardGroup_00090: "Incorrect format of top-up amount",
  cardGroup_00091: "Please enter password",
  cardGroup_00092: "Online ID card entry success",
  cardGroup_00093: "New plug-and-charge card",
  cardGroup_00094: "Check the VIN of the vehicle matched by the user",
  cardGroup_00095: "Complete the card information",
  cardGroup_00096: "Complete creation",
  cardGroup_00097: "This mobile number matches multiple EVs ({type}) within 2 weeks, please identify the EV manually ({type})！",
  cardGroup_00098: "User name|Mobile number",
  cardGroup_00099: "EV VIN",
  cardGroup_00100: "Lastest|charging end time",
  cardGroup_00101: "Latest|Charging site",
  cardGroup_00102: "Confirm",
  cardGroup_00103: "Previous",
  cardGroup_00104: "Next",
  cardGroup_00105: "PnC",
  cardGroup_00106: "Binding Restricted Stations",
  cardGroup_00107: "Add charging site",
  cardGroup_00108: "Station",
  cardGroup_00109: "CPO",
  cardGroup_00110: "Site detail",
  cardGroup_00111: "Delete",
  cardGroup_00112: "Confirm to delete this site?",
  cardGroup_00113: "Reminder",
  cardGroup_00114: "Confirm",
  cardGroup_00115: "Enter site name, operator name",
  cardGroup_00116: "Charging site status",
  cardGroup_00117: "Open to public",
  cardGroup_00118: "Charger",
  cardGroup_00119: "AC",
  cardGroup_00120: "DC",
  cardGroup_00121: "Add",
  cardGroup_00122: "Added",
  cardGroup_00123: "Enabled",
  cardGroup_00124: "Unavailable",
  cardGroup_00125: "Available",
  cardGroup_00126: "Not open",
  cardGroup_00127: "EV MAC",
  cardGroup_00128: "Query the MAC of the EV matched by the user",
  cardGroup_10000: "New Charging Cards",
  cardGroup_10001: "SOC limit for in-group card charging",
  cardGroup_10002: "Please enter an integer of 1-100",
  cardGroup_10003: "Download batch import template",
  cardGroup_10004: "Batch import of ID cards",
  cardGroup_10005: "Batch import of plug-and-charge cards",
  cardGroup_10006: "1. Please download the batch import template first and fill in the charging card related information in the template.",
  cardGroup_10007: "If you import Plug-and-Charge cards in bulk, please enter the card number in the template by the vehicle MAC used in vehicle charging.",
  cardGroup_10008: "If you import ID cards in bulk, please enter the card number recognized by the NFC card reader in the template.",
  cardGroup_10009: "2. Please select the corresponding button to import the charging card based on the type of charging card.",
  cardGroup_10010: "Please use the template to batch import!",
  cardGroup_10011: "In the template file, please input  Mobile and Vehicle license number correctly for the following cards.",
  cardGroup_10012: "In the template file, the following cards already exist on the platform.",
  cardGroup_10013: "{totalCount} cards in total,successfully imported {successCount} cards.",
  cardGroup_10014: "{totalCount} cards in total,successfully imported {successCount} cards, failed to import {failCount} cards.",
  cardGroup_10015: "In the template file, the following cards  already exist in other card groups on the platform.",
  cardGroup_10016: "card groups：{0}",
  cardGroup_10017: "cards：",
  
  
  
  support_u2_card: "Offline stored value card supported",
  support_u1_card: "Offline start/stop card support",
  working_mode_title: "Welcome message| (charger display message)",
  config_card_welcome: "Welcome to use XCharge charging station",
  config_card_tip_1: "Rated current| (actual current is based on charging post equipment)",
  addTimeInterval: "Add time slot",
  addTimeIntervalPrompt: "Max. 12 time slots",
  beginTime: "Start time",
  endTime: "Close time",
  electricity_fee: "Electricity fee",
  service_fee: "Service fee",
  operation: "Operation",
  electric_unit: "kwh",
  config_card_wifi_ssid: "Wi-Fi SSID",
  config_card_wifi_pass: "Wi-Fi password",
  select_input: "Optional",
  elec_lock: "Electromagnetic lock",
  elec_lock_status: "Electromagnetic lock status",
  unavailable: "Unavailable",
  available: "Available",
  unlock_before_pay: "Unlock when fully charged",
  unlock_after_pay: "Unlock after settelment",
  radar: "Radar",
  radar_status: "Radar status",
  config_card_make_success: "Configuration card created successfully",
  add_charge_card_remind_3: "1、Top-up a memory type charge card；",
  add_charge_card_remind_4: " 2、Search top-up record；",
  add_charge_card_remind_5: "3、Changing the contact details of charging card；",
  finish: "Finish",
  nextStep: "Next",
  preStep: "Previous",
  ac: "AC",
  dc: "DC",
  user_control_add: "Add",
  user_control_added: "Added",
  enabled: "Enabled",
  disabled: "Disabled",
  open: "Available",
  unopen: "Unavailable",
  charge_card_operation_remind_1: "Card cannot be read! Reminder: Make sure the card reader is connected and place the NFC card on the card reader。",
  charge_card_operation_remind_2: "Card is not empty",
  charge_card_operation_remind_3: "Card is empty",
  charge_card_operation_remind_4: "Card is not part of a current group account",
}