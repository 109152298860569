export default {
  pileDetail_00001: 'Konfiguration hochladen',
  pileDetail_00002: 'Details der Ladesation',
  pileDetail_00003: 'Information des Geräts',
  pileDetail_00004: 'Gerätekonfiguration',
  pileDetail_00005: 'Verwaltung der Ladepunkte',
  pileDetail_00006: 'Instandhaltung der Geräte',
  pileDetail_00007: 'Gerät neu starten',
  pileDetail_00008: 'Der Befehl zum Neustart des Geräts wurde gesendet, der Neustart wird etwa 5 Minuten dauern. Bitte bleiben Sie auf dieser Seite und aktualisieren Sie die Seite nicht.',
  pileDetail_00009: 'zustimmen',
  pileDetail_00010: 'Ladevorgang starten',
  pileDetail_00011: 'Ferngesteuerter Neustart erfolgt',
  pileDetail_00012: 'Ferngesteuerter Neustart',
  pileDetail_00013: 'Ferngesteuerter Neustart fehlgeschlagen',
  pileDetail_00014: 'Gerätname',
  pileDetail_00015: 'zugehörige Station',
  pileDetail_00016: 'Ansicht',
  pileDetail_00017: 'Seriennummer der Geräte',
  pileDetail_00018: 'Hersteller der Geräte',
  pileDetail_00019: 'Modell der Geräte',
  pileDetail_00020: 'Bitte geben Sie den Gerätenamen ein',
  pileDetail_00021: 'Nachdem die Konfigurationsänderungen gespeichert wurden, muss die Konfiguration an das Gerät übertragen werden, bitte warten Sie ab, bis das Gerät auf die neue Konfiguration reagiert.',
  pileDetail_00022: 'Konfiguration hochladen',
  pileDetail_00023: 'Ins Gerät hochenladen',
  pileDetail_00024: 'Gerät hochladen',
  pileDetail_00025: 'Elektromagnetische Veriegelung',
  pileDetail_00026: 'Verriegelung fehlgeschalgen, Ladestation bereits zu laden',
  pileDetail_00027: 'Parkplatz Radar',
  pileDetail_00028: 'Ladestation freigegeben',
  pileDetail_00029: 'Startzeitpunkt',
  pileDetail_00030: 'Endzeitpunkt',
  pileDetail_00031: 'Max.Strom Amp(A)',
  pileDetail_00032: 'Max.Ladeleistung(kW)',
  pileDetail_00033: 'Konfiguration nicht angenommen',
  pileDetail_00034: 'Gerät offline',
  pileDetail_00035: 'Warten auf Rückmeldung des Geräts',
  pileDetail_00036: 'Konfiguration angenommen',
  pileDetail_00037: 'Datum falsch',
  pileDetail_00038: 'Timer für nächste Service der Power Module: Duration von letzten Meldung der Rückstellung des Timers.',
  pileDetail_00039: '{hours}Stunden',
  pileDetail_00040: 'Zeit rückstellen',
  pileDetail_00041: 'verfügbar',
  pileDetail_00042: 'störung',
  pileDetail_00043: 'Sind Sie sicher, dass Sie die Wartung für dieses Ladesäulen-Leistungsmodul abgeschlossen haben? Nachdem Sie auf Zustimmen geklickt haben,die Wartungstimer wird neugestart.',
  pileDetail_00044: 'Anmerkung',
  pileDetail_00045: 'Name des Ladepunkts',
  pileDetail_00046: 'QR Code des ladevorgangs',
  pileDetail_00047: 'Dynamischer QR Code',
  pileDetail_00048: 'Statischer QR Code',
  pileDetail_00049: 'Neuer statischer QR Code hochladen',
  pileDetail_00050: 'Benutzerdefinierter QR code',
  pileDetail_00051: 'Fernsteuerung',
  pileDetail_00052: 'Ladepunkt Neustart',
  pileDetail_00053: 'Ladevorgang beenden',
  pileDetail_00054: 'Verfügbar stellen',
  pileDetail_00055: 'Status des Ladepunkts',
  pileDetail_00056: 'Verwaltungsgerät des Parkplatz ',
  pileDetail_00057: 'None',
  pileDetail_00058: 'Ladestation- gesteuerte Parksperre',
  pileDetail_00059: 'Cloud- gesteuerte Parksperre',
  pileDetail_00060: 'Informationen zur Parkrplatzsverwaltungsgeräte',
  pileDetail_00061: 'Bitte Gerätecode eingeben',
  pileDetail_00062: 'Parkplatzverwaltung aktivieren',
  pileDetail_00063: ' Parkraumverwaltungsausrüstung ferngesteuern',
  pileDetail_00064: 'Erheben',
  pileDetail_00065: 'Sinken',
  pileDetail_00066: 'Aktueller Nutzer der Parkplatzbelegungsgebührenabrechnung',
  pileDetail_00067: 'Startzeitpunkt der Parkplatzbelegeung',
  pileDetail_00068: 'Nutzerdetails',
  pileDetail_00069: 'Benutzungsgebührenabrechnung manuell beenden',
  pileDetail_00070: 'Parkplatzverwaltung QR-Code genieren',
  pileDetail_00071: 'Statischer QR-Code generieren',
  pileDetail_00072: 'im Einsatz',
  pileDetail_00073: 'Nicht aktiviert',
  pileDetail_00074: 'Offline',
  pileDetail_00075: 'störung',
  pileDetail_00076: 'Reserviert',
  pileDetail_00077: 'Verfügbar',
  pileDetail_00078: 'Ladepistole eingesteckt枪',
  pileDetail_00079: 'im ladevorgang',
  pileDetail_00080: 'Parkplatz besetzt',
  pileDetail_00081: 'auspluggen',
  pileDetail_00082: 'Ladepunkt',
  pileDetail_00083: 'Aufladen starten',
  pileDetail_00084: 'Bitte speichern Sie die vorherige Operation!',
  pileDetail_00085: 'Der aktuelle Parkplatz hat Benutzer, die die Parkplatzbelegungsgebühr erheben, bitte ändern Sie sie später!!',
  pileDetail_00086: 'Kein Parameter',
  pileDetail_00087: 'Maximal 20 Zeichen',
  pileDetail_00088: 'Startbefehl gesendet',
  pileDetail_00089: 'Beendbefehl gesendet',
  pileDetail_00090: 'Erfolgreich gesendet',
  pileDetail_00091: 'Upgrade auf statischen QR-Code',
  pileDetail_00092: 'Der QR-Code wurde erfolgreich generiert',
  pileDetail_00093: 'Ladestation aktiviert',
  pileDetail_00094: 'Nicht aktiviert',
  pileDetail_00095: 'Cloud-gesteuerte Parksperre',
  pileDetail_00096: 'Ladestation- gesteuerte Parksperre',
  pileDetail_00097: 'Bitte geben Sie den Inhalt des QR-Codes ein, der von Ihnen konfigurierte QR-Code wird auf dem Stapel angezeigt',
  pileDetail_00098: 'Hinweis: der code kann nur in einem festen zustand erzeugt werden, nicht aber wieder in den ursprungszustand wiederhergestellt werden',
  pileDetail_00099: 'enutzerdefinierter QR-Code darf nicht leer sein!',
  pileDetail_00100: 'Benutzerdefinierter QR-Code erfolgt',
  pileDetail_00101: 'Einrichtung fehlgeschlagen',
  pileDetail_00102: 'Länge darf {0} Zeichen nicht überschreiten',
  pileDetail_00103: 'Aktuelle Abrechnungsdauer (Minuten)',
  pileDetail_00104: 'Aktuelle vorausbezahlte Belegungsgebühr ',
  pileDetail_00105: 'Belegungsgebühr nach Änderung',
  pileDetail_00106: 'Bitte geben Sie den Betrag ein',
  pileDetail_00107: 'Bitte geben Sie den geänderten Belegungspreis ein',
  pileDetail_00108: 'Bitte geben Sie die richtige Nummer ein',
  pileDetail_00109: 'Die Belegungsabrechnung wurde erfolgreich manuell beendet',
  pileDetail_00110: 'Der statische QR-Code hat eine Größe von bis zu 100.000 und kann nicht wiederverwendet werden. Gehen Sie bitte vorsichtig vor. Möchten Sie wirklich upgraden??',
  pileDetail_00111: 'Aktualisierung erfolgreich',
  pileDetail_00112: 'Fertig',
  pileDetail_00113: 'Ob ein statischer QR-Code für Parkplatzverwaltungsgeräte generiert werden soll?',
  pileDetail_00114: 'Kein QR-Code',
  pileDetail_00115: 'Wenn das Gerät offline ist, werden nur Benutzer in der Whitelist unterstützt, um den Ladevorgang einzuleiten.',
  pileDetail_00116: 'Whitelist löschen',
  pileDetail_00117: 'Whitelist hochladen',
  pileDetail_00118: 'Geräte ID',
  pileDetail_00119: 'System Version',
  pileDetail_00120: 'Andriod version',
  pileDetail_00121: 'DCB-Version',
  pileDetail_00122: 'Netzwerk',
  pileDetail_00123: 'Auf-entladen mode',
  pileDetail_00124: 'Name des Energiespeicher',
  pileDetail_00125: 'Kapazität des Energiespeichers',
  pileDetail_00126: 'Akkuzyklen',
  pileDetail_00127: 'SOC',
  pileDetail_00128: 'SOH',
  pileDetail_00129: 'Temperature',
  pileDetail_00130: 'Status des Energiespeicher',
  pileDetail_00131: 'Aufladen starten',
  pileDetail_00132: 'Aufladen beenden',
  pileDetail_00133: 'Entladung starten',
  pileDetail_00134: 'Entladung stoppen',
  pileDetail_00135: 'Klimalisieren mit Auf-entladen mode',
  pileDetail_00136: 'Wirtschaft Mode',
  pileDetail_00137: 'Effizienter mode',
  pileDetail_00138: 'Max-Power mode',
  pileDetail_00139: 'Ökoökonomisches mode',
  pileDetail_00140: 'Auto Mode',
  pileDetail_00141: 'Mit dem Energiespeicher die Ladeleistung boosten um das Fahrzeug schnell aufladen.',
  pileDetail_00142: 'Energiebei niedrigen Strompreisen aufladen, und bei hohen Strompreisen Strom entladen, wodurch die Stromkosten gesenkt werden',
  pileDetail_00143: 'System gleicht Energie- und Kostenbedarf automatisch aus.',
  pileDetail_00144: 'Lern mehr',
  pileDetail_00145: 'Energiespeicher Verwaltung',
  pileDetail_00146: 'Energiespeicher',
  pileDetail_00147: "Energiespeicher aufladen",
  pileDetail_00148: "Energiespeicher entladen",
  pileDetail_00149: "Verfügbar",








}