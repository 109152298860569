export default {
  occupancyInfo_00001: '充电位占用账单详情',
  occupancyInfo_00002: '账单信息',
  occupancyInfo_00003: '收费标准',
  occupancyInfo_00004: '账单明细',
  occupancyInfo_00005: '账单备注',
  occupancyInfo_00006: '账单编号',
  occupancyInfo_00007: '初始创建',
  occupancyInfo_00008: '已取消',
  occupancyInfo_00009: '已支付',
  occupancyInfo_00010: '待支付',
  occupancyInfo_00011: '计费中',
  occupancyInfo_00012: '当前收费时长',
  occupancyInfo_00013: '天',
  occupancyInfo_00014: '小时',
  occupancyInfo_00015: '分钟',
  occupancyInfo_00016: '账单金额',
  occupancyInfo_00017: '优惠',
  occupancyInfo_00018: '实际支付金额',
  occupancyInfo_00019: '占用开始时间',
  occupancyInfo_00020: '占用结束时间',
  occupancyInfo_00021: '占用时长',
  occupancyInfo_00022: '免收占用费时长',
  occupancyInfo_00023: '充电时长',
  occupancyInfo_00024: '用户信息',
  occupancyInfo_00025: '支付方式',
  occupancyInfo_00026: '{pay}',
  occupancyInfo_00027: '微信支付',
  occupancyInfo_00028: '微信余额支付',
  occupancyInfo_00029: '团体资金账户余额支付',
  occupancyInfo_00030: '第三方APP支付',
  occupancyInfo_00031: '用户资金账户',
  occupancyInfo_00032: '在线身份卡支付',
  occupancyInfo_00033: '微信公众号（支付宝）',
  occupancyInfo_00034: '微信公众号（微信支付免密支付）',
  occupancyInfo_00035: '折扣方式',
  occupancyInfo_00036: '修改金额',
  occupancyInfo_00037: '支付时赠送金额抵扣{money}元',
  occupancyInfo_00038: '账单备注',
  occupancyInfo_00039: '收费标准',
  occupancyInfo_00040: '占用费收费标准： {time}元/分钟',
  occupancyInfo_00041: '免收占用费时长：{time}',
  occupancyInfo_00042: '充电时长详情',
  occupancyInfo_00043: '充电订单号',
  occupancyInfo_00044: '充电开始时间',
  occupancyInfo_00045: '充电结束时间',
  occupancyInfo_00046: '充电时长(分钟)',
  occupancyInfo_00047: '查看充电订单',
  occupancyInfo_00048: '修改金额',
  occupancyInfo_00049: '当前账单金额',
  occupancyInfo_00050: '修改后账单金额',
  occupancyInfo_00051: '请输入付款金额',
  occupancyInfo_00052: '修改的金额格式不符',
  occupancyInfo_00053: '您确定删除此条账单备注？',
  occupancyInfo_00054: '提示',
  occupancyInfo_00055: '确认删除',
  occupancyInfo_00056: '账单备注',
  occupancyInfo_00057: '请填写账单备注',
  occupancyInfo_00058: '',
  occupancyInfo_00059: '',
  occupancyInfo_00060: '',
}