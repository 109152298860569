export default {
    accountManage_00001: "Please input account name、owner、mobile number",
    accountManage_00002: "Account(login id)",
    accountManage_00003: "Owner",
    accountManage_00004: "Mobile Number",
    accountManage_00005: "Role",
    accountManage_00006: "New account",
    accountManage_00007: "Operation",
    accountManage_00008: "Stations",
    accountManage_00009: "Create an account",
    accountManage_000010: "Account List",
    accountManage_000011: "Edit",
    accountManage_000012: "log into the platform",
    accountManage_000013: "Password",
    accountManage_000014: "log into the platform",
    accountManage_000015: "Contains at least 6 characters, including at least 1 digit, 1 letter, and 1 special character",
    accountManage_000016: "Owner",
    accountManage_000017: "Your real name",
    accountManage_000018: "Mobile Number",
    accountManage_000019: "Easy to contact you",
    accountManage_000020: "Role",
    accountManage_000021: "Operator Administrator",
    accountManage_000022: "Select",
    accountManage_000023: "Please select account role",
    accountManage_000024: "Operation success",
    accountManage_000025: "Name of charging staion",
    accountManage_000026: "Charging stations added successfully",
    accountManage_000027: "Selected charging stations",
    accountManage_000028: "Deleted successfully",
    accountManage_000029: "Super Administrator",
    accountManage_000030: "Are you sure you want to delete?",
    accountManage_000031: "Deletion cancelled",
    accountManage_000032: "Create Time",
    accountManage_000033: "Email",
    accountManage_000034: "binding",
    accountManage_000035: "Remove the binding",
    accountManage_000036: "Letters and numbers,<=20",
}