
export default {
    charge_00001: "{num}",
    charge_00002: "Sie können neue Offline-Karten in dieser Gruppe erstellen. Die Offline-Karten in dieser Gruppe müssen zur Verwendung konfiguriert werden.",
    charge_00003: "CPO Kartegruppe management",
    charge_00004: "Kartegruppename",
    charge_00005: "Kartegruppeliste",
    charge_00006: "Kartegruppe neu erstellen",
    charge_00007: "Kartegruppetypen",
    charge_00008: "Online-Kartegruppe",
    charge_00009: "Offline-Kartegruppe",
    charge_00010: "Super-Kartegruppe",
    charge_00011: "Anzahl der Ladekarte", 
    charge_00012: "Vorgänge suchen",
    charge_00013: "Aktionen",
    charge_00014: " Im 「Einzelheit」 der Kartegruppe von 「Kartemanagement」kann Folgendes zu tun:",
    charge_00015: "Kartegruppename",
    charge_00016: "z.B. Interes Personal des Guobin-Gebäude",
    charge_00017: "Bitte geben Sie ein Name für Kartegruppe",
    charge_00018: "Online-Kartegruppe",
    charge_00019: "In dieser Gruppe können Sie neue Online-Karte erstellen",
    charge_00020: "Offline-Kartegruppe",
    charge_00021: "1. Gruppename ändern",
    charge_00022: "Neue Kartegruppe erfolgreich erstellt",
    charge_00023: "NFC Konfigurationskarte erstellen",
    charge_00024: "NFC Ladekarte hinzufügen",
    charge_00025: "erledigt",
    charge_00026: "Bitte geben Sie Kartegruppesname ein.",
}