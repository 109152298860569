export default {
    minProgramBanner_00001: "homepage banner",
    minProgramBanner_00002: "home banner enabled",
    minProgramBanner_00003: "Preview",
    minProgramBanner_00004: "banner picture",
    minProgramBanner_00005: "jump page",
    minProgramBanner_00006: "display order",
    minProgramBanner_00007: "select default picture",
    minProgramBanner_00008: "amplification",
    minProgramBanner_00009: "modify",
    minProgramBanner_000010: "click to upload pictures",
    minProgramBanner_000011: "setting help",
    minProgramBanner_000012: "1. Banner can add up to 8 pages. The display time of each banner image is 3 seconds, and it will be displayed in turn according to the sequence number;",
    minProgramBanner_000013: "2. If you set a jump link, click it to jump to the page you set; if you do not set a jump link, only pictures will be displayed",
    minProgramBanner_000014: "3. The uploaded image only supports PNG and JPG format images, and the size is not more than 5m. The recommended size is 690 * 260px (8:3). If the size or scale of the uploaded image is not consistent, it will be stretched for display",
    minProgramBanner_000015: "select activity type",
    minProgramBanner_000016: "please paste your official account article link, wechat public platform: https://mp.weixin.qq.com/ ",
    minProgramBanner_000017: "up to 8 banners can be set",
    minProgramBanner_000018: "please set banner picture",
    minProgramBanner_000019: "please set the activity ID",
    minProgramBanner_000020: "Upload",
    minProgramBanner_000021: "no jump",
    minProgramBanner_000022: "active page",
    minProgramBanner_000023: "official account link",
    minProgramBanner_000024: "return of stored value",
    minProgramBanner_000025: "free collection",
    minProgramBanner_000026: "invite friends",
    minProgramBanner_000027: "purchase card coupon",
    minProgramBanner_000028: "Please set the jump page",
    minProgramBanner_000029: "Activity name",
    minProgramBanner_000030: "Select Activity",
    minProgramBanner_000031: "New Banner",
    
}