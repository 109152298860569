export default {
    analyzeCharge_00001: "Electricity",
    analyzeCharge_00002: "Electricty By Station",
    analyzeCharge_00003: "Electricty By Fleet",
    analyzeCharge_00004: "Analysis of user source charging volume",
    analyzeCharge_00005: "Electricty By Franchisee",
    analyzeCharge_00006: "Electricity trend",
    analyzeCharge_00007: "Analysis",
    analyzeCharge_00008: "Electricity(kWh)",
    analyzeCharge_00009: "Electricity distribution by charger model",
    analyzeCharge_00010: "Electricity distribution by city",
    analyzeCharge_00011: "Comparison of stations by electricity",
    analyzeCharge_00012: "Electricty trends of selected stations",
    analyzeCharge_00013: "Comparison of fleets by electricity",
    analyzeCharge_00014: "Electricty trends of selected fleets",
    analyzeCharge_00015: "Comparison of Franchisees by electricity",
    analyzeCharge_00016: "Electricty trends of selected Franchisees",
    analyzeCharge_00017: "select charger model",
    analyzeCharge_00018: "Site Utilization Comparison",
    analyzeCharge_00019: "Site utilization trend",
    analyzeCharge_00020: "Comparison of charger type utilization",
    analyzeCharge_00021: "Charger type utilization trend",
    analyzeCharge_00022: "Charging Utilisation",
    analyzeCharge_00023: "Franchisee Utilization Comparison",
    analyzeCharge_00024: "Franchisee Utilization Trend",
    analyzeCharge_00025: "comparison of site average power ",
    analyzeCharge_00026: "Average power analysis of Charger types",
    analyzeCharge_00027: "Average power",
    analyzeCharge_00028: "Select or enter a charging site",
    analyzeCharge_00029: "Ports in charging status",
    analyzeCharge_00030: "Busy rate of charging port",
    analyzeCharge_00031: "Number of ports in charging status ",
    analyzeCharge_00032: "Charging ports busy rate %",
    analyzeCharge_00033: "Charging ports busy rat = Ports in charging status / All charging ports",
    analyzeCharge_00034: "Percentage of user-source charging",
    analyzeCharge_00035: "Share of each traffic in third-party traffic",
    analyzeCharge_00036: "User source charging volume trend",
    analyzeCharge_00037: "Third-party charging volume trend",
    analyzeCharge_00038: "Average charging capacity trend by 0-24 hour",
    analyzeCharge_00039: "Charging capacity per connector",
    analyzeCharge_00040: "Charging degree per connector",
  
}