export default {
    systemInfoSetting_00001: "Basisinformationen",
    systemInfoSetting_00002: "Kontakte",
    systemInfoSetting_00003: "Informationen zum Konto",
    systemInfoSetting_00004: "Tips auf der Seite zur Eingabe von Nummernschildern",
    systemInfoSetting_00005: "Name des Betreibers",
    systemInfoSetting_00006: "Betreiber-ID",
    systemInfoSetting_00007: "Währung",
    systemInfoSetting_00008: "Renminbi(Yuan)",
    systemInfoSetting_00009: "Kontakte",
    systemInfoSetting_000010: "Tel-Nr.",
    systemInfoSetting_000011: "E-Mail",
    systemInfoSetting_000012: "Bestätigungscode abrufen",
    systemInfoSetting_000013: "Bitte geben Sie den Bestätigungscode ein",
    systemInfoSetting_000014: "Nummer des Kontos",
    systemInfoSetting_000015: "Super Administrator",
    systemInfoSetting_000016: "Kontoname",
    systemInfoSetting_000017: "Bankkonto und",
    systemInfoSetting_000018: "Bank",
    systemInfoSetting_000019: "Inhalt eingeben",
    systemInfoSetting_000020: "Kann nicht leer sein",
    systemInfoSetting_000022: "deaktiviert",
    systemInfoSetting_000023: "Der Name darf nicht aus mehr als {0} Zeichen bestehen",
    systemInfoSetting_000024: "Die Anzahl der eingegebenen Wörter darf 20 nicht überschreiten",
    systemInfoSetting_000025: "Bitte geben Sie die korrekte E-Mail-Adresse ein",
    systemInfoSetting_000601: "Bitte wählen",
    systemInfoSetting_000602: "Bitte wählen Sie einen Währungstyp",
    systemInfoSetting_000603: "Name der firma:",
}