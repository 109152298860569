export default {
    user_export_remind_1: "This operation will download the user's Excel file, do you want to continue?" ,
    remind: "prompt",
    confirm: "Confirm",
    cancel: "Cancel",
    start_download: "Start downloading",
    cancel_download: "Cancel download",

    userLevel_00001: 'Currently view',
    userLevel_00002: 'Export user',
    userLevel_00003: 'View users',
    userLevel_00004: 'Member Management',
    userLevel_00005: 'View users',
    userLevel_00006: 'Photo profile',
    userLevel_00007: 'Nickname', 
    userLevel_00008: 'Gender',
    userLevel_00009: 'Mobile number',
    userLevel_00010: 'License plate number', 
    userLevel_00011: 'Balance',
    userLevel_00012: 'Check order',
    userLevel_00013: 'Details',
    userLevel_00014: 'Male',
    userLevel_00015: 'Female',
    userLevel_00016: '',
    userLevel_00017: '',
    userLevel_00018: '',
}
