<template lang="html">
  <el-dialog :title="$t('diversionDetail_00099')":visible.sync="visible" :lock-scroll="false" width="860px" :before-close="close">
    <div class="title">
      <p>
        <span class="name">{{$t('diversionDetail_00060')}}：</span>
        <span>{{thirdplatform}}</span>
      </p>
      <p class="hr">
        <span class="name">{{$t('diversionDetail_00011')}}：</span>
        <span>{{dealer}}</span>
      </p>
    </div>
    <div class="wrap" v-show="pageFlag">
      <el-row class="add-wrap">
        <el-col :span="24" align="right">
          <el-button type="text" @click="addDiscount">{{$t("diversionDetail_00100")}}</el-button>
        </el-col>
      </el-row>
      <div class="table">
        <el-table :data="disCountList" :header-cell-style="{
          height: '54px',
          fontSize: '14px',
          background:'#EFF2F6',
          color:'#1B2330',
          borderRadius: '4px 4px 0px 0px'}">
          <el-table-column :label="$t('diversionDetail_00101')" width="195">
            <template slot-scope="scope">
              <div v-if="scope.row.flag && !scope.row.statusFlag">
                <el-date-picker type="datetime" v-model="scope.row.startTime" size="mini" class="inp" :disabled="scope.row.sFlag" :picker-options="pickerOptions"></el-date-picker>
                <el-checkbox v-model="scope.row.sFlag" size="mini">{{$t('diversionDetail_00102')}}</el-checkbox>
              </div>
              <span v-else>{{formmatTime(scope.row.startTime)}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('diversionDetail_00103')" width="195">
            <template slot-scope="scope">
  <div v-if="scope.row.flag">
    <el-date-picker type="datetime" v-model="scope.row.endTime" size="mini" class="inp" :disabled="scope.row.eFlag" :picker-options="pickerOptions"></el-date-picker>
    <el-checkbox v-model="scope.row.eFlag" size="mini">{{$t('diversionDetail_00104')}}</el-checkbox>
  </div>
  <span v-else>{{scope.row.endTime == "20991230235959"?$t('diversionDetail_00104'):formmatTime(scope.row.endTime)}}</span>
</template>
          </el-table-column>
          <el-table-column :label="$t('diversionDetail_00105')" width="180">
            <template slot-scope="scope">
  <div class="disCss">
    <div v-if="scope.row.flag && !scope.row.statusFlag">
      <el-input :placeholder="$t('diversionDetail_00106')" v-model="scope.row.discount" size="mini" class="inp" v-if="scope.row.type == 0" />
      <el-input :placeholder="$t('diversionDetail_00107')" v-model="scope.row.discount" size="mini" class="inp" v-if="scope.row.type == 1" />
      <el-radio-group v-model="scope.row.type" style="margin-top: 5px;">
        <el-radio :label="0">{{$t('diversionDetail_00108')}}</el-radio>
        <el-radio :label="1">{{$t('diversionDetail_00109')}}</el-radio>
      </el-radio-group>
    </div>
    <div v-else class="disText">
      {{forDis(scope.row)}}
    </div>
  </div>
</template>
          </el-table-column>
          <el-table-column :label="$t('diversionDetail_00110')" width="125">
            <template slot-scope="scope">
  <div v-if="scope.row.status == 0"><span>{{$t('diversionDetail_00111')}}</span></div>
  <div v-if="scope.row.status == 1"><span style="color: #FFBF00;">{{$t('diversionDetail_00112')}}</span>-<span style="color: #31B404;">{{$t('diversionDetail_00113')}}</span></div>
  <div v-if="scope.row.status == 2"><span>{{$t('stopped')}}</span></div>
  <div v-if="scope.row.status == 3"><span style="color: #FFBF00;">{{$t('diversionDetail_00112')}}</span>-<span>{{$t('diversionDetail_00114')}}</span></div>
</template>
          </el-table-column>
          <el-table-column :label="$t('diversionDetail_00021')">
            <template slot-scope="scope">
  <div v-if="scope.row.flag">
    <el-button type="text" @click="save(scope.row)">{{$t('common_00217')}}</el-button>
    <el-button type="text" @click="cancel(scope.row,scope.$index)">{{$t('common_00215')}}</el-button>
  </div>
  <div v-else>
    <el-button type="text" @click="modify(scope.row)">{{$t('common_00213')}}</el-button>
    <el-button type="text" @click="remove(scope.row)">{{$t('common_00214')}}</el-button>
  </div>
</template>
          </el-table-column>
        </el-table>
      </div>
      <el-row class="add-wrap">
        <el-col :span="24" align="right">
          <el-button type="text" @click="getHistory">{{$t("diversionDetail_00115")}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="wrap" v-show="!pageFlag">
      <el-row class="list-wrap">
        <el-col :span="24" align="left">
          <p>{{$t("diversionDetail_00115")}}</p>
        </el-col>
      </el-row>
      <div class="table">
        <el-table :data="disHistoryList" :header-cell-style="{
          height: '54px',
          fontSize: '14px',
          background:'#EFF2F6',
          color:'#1B2330',
          borderRadius: '4px 4px 0px 0px'}">
          <el-table-column :label="$t('diversionDetail_00101')">
            <template slot-scope="scope">
  <div>{{formmatTime(scope.row.startTime)}}</div>
</template>
          </el-table-column>
          <el-table-column :label="$t('diversionDetail_00103')">
            <template slot-scope="scope">
  <span>{{scope.row.endTime == "20991230235959"?$t('diversionDetail_00104'):formmatTime(scope.row.endTime)}}</span>
</template>
          </el-table-column>
          <el-table-column :label="$t('diversionDetail_00105')">
            <template slot-scope="scope">
  <div class="disText">
    {{forDis(scope.row)}}
  </div>
</template>
          </el-table-column>
          <el-table-column :label="$t('diversionDetail_00110')">
            <template slot-scope="scope">
  <div v-if="scope.row.status == 0"><span>{{$t('diversionDetail_00111')}}</span></div>
  <div v-if="scope.row.status == 1"><span style="color: #FFBF00;">{{$t('diversionDetail_00112')}}</span>-<span style="color: #31B404;">{{$t('diversionDetail_00113')}}</span></div>
  <div v-if="scope.row.status == 2"><span>{{$t('stopped')}}</span></div>
  <div v-if="scope.row.status == 3"><span style="color: #FFBF00;">{{$t('diversionDetail_00112')}}</span>-<span>{{$t('diversionDetail_00114')}}</span></div>
</template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" layout="total, prev, pager, next, jumper" :total="dataNum" :page-size="3">
          </el-pagination>
        </div>
        <div class="btn">
          <el-button @click="returnPage" size="medium" type="primary">{{$t('diversionDetail_00072')}}</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  requestUrl,
  ajax,
  getBjTimeFromLocalTime,
  getQueryString,
} from "@/assets/utils/network";
import { formmatTime } from "@/assets/utils/time";
export default {
  data() {
    return {
      disCountList: [],
      disHistoryList: [],
      currentPage: 1,
      tableData: [],
      dataNum: 0,
      pageFlag: true,
      cacheS: "",
      cacheE: "",
      cacheD: "",
      cacheT: "",
      cacheSF: "",
      cacheEF: "",
      addFlag: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  props: ["visible", "dealer", "thirdplatform", "id"],
  methods: {
    timeQuery(row) {
      var time = "";
      time =
        "" +
        new Date(row).getFullYear() +
        (new Date(row).getMonth() + 1 < 10
          ? "0" + (new Date(row).getMonth() + 1)
          : new Date(row).getMonth() + 1) +
        (new Date(row).getDate() < 10
          ? "0" + new Date(row).getDate()
          : new Date(row).getDate()) +
        (new Date(row).getHours() < 10
          ? "0" + new Date(row).getHours()
          : new Date(row).getHours()) +
        (new Date(row).getMinutes() + 1 < 10
          ? "0" + (new Date(row).getMinutes() + 1)
          : new Date(row).getMinutes() + 1) +
        (new Date(row).getSeconds() < 10
          ? "0" + new Date(row).getSeconds()
          : new Date(row).getSeconds());
      return getBjTimeFromLocalTime(time);
    },
    formmatTime(time) {
      return formmatTime(time, true);
    },
    close() {
      this.$emit("close", false);
    },
    save() {
      const data = {
        id: this.id,
      };
      let om = this;
      ajax({
        type: "POST",
        url: requestUrl + "/outsideowner/devicegroup",
        dataType: "json",
        data: JSON.stringify(data),
        success: function (response) {
          var info = JSON.parse(response);
          if (info.error != null) {
            if (info.error.code == "NOT_LOGIN") {
              window.location.href = "./login.html?l=0";
            }
            om.$message.error(
              info.error.msg == null ? info.error.code : info.error.msg
            );
          } else {
            om.$message.success(om.$t("diversionDetail_00073"));
            om.close();
          }
        },
      });
    },
    initData(url) {
      let om = this;
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: function (response) {
          let arrNew = [];
          JSON.parse(response).result.map((item, index) => {
            if (item.type == 1) {
              item.discount = item.discount / 100;
            } else {
              item.discount = 100 - item.discount;
            }
            arrNew.push(
              Object.assign({}, item, {
                flag: false,
                sFlag: false,
                eFlag: false,
                statusFlag: false,
              })
            );
          });
          om.disCountList = arrNew;
          om.addFlag = false;
        },
      });
    },
    getHistory() {
      this.pageFlag = false;
      var url =
        requestUrl +
        "/outsideowner/site/discount/rule?from=0&length=3&returnCount=true&status=2&deviceGroupOutsideownerId=" +
        this.id;
      this.initHistory(url);
    },
    initHistory(url) {
      let om = this;
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: function (response) {
          om.disHistoryList = JSON.parse(response).result;
          om.dataNum = JSON.parse(response).totalCount;
          om.disHistoryList.forEach((item) => {
            if (item.type == 0) {
              item.discount = 100 - item.discount;
            } else {
              item.discount = item.discount / 100;
            }
          });
        },
      });
    },
    handleCurrentChange(val) {
      let page = `${val}`;
      if (this.currentPage == page) return;
      this.currentPage = Number(page);
      let url =
        requestUrl +
        "/outsideowner/site/discount/rule?from=" +
        (parseInt(page) - 1) * 3 +
        "&length=3&returnCount=true&status=0,1,2&deviceGroupOutsideownerId=" +
        this.id;
      this.initHistory(url);
    },
    addDiscount() {
      if (this.addFlag) {
        return;
      }
      this.disCountList.push({
        startTime: "",
        endTime: "",
        flag: true,
        isAdd: true,
        sFlag: false,
        eFlag: false,
        type: 0,
        discount: "",
      });
      this.addFlag = true;
    },
    returnPage() {
      this.pageFlag = true;
    },
    forDis(row) {
      if (row.type == 0) {
        return this.$t("diversionDetail_00122", { dis: row.discount });
      } else {
        return this.$t("diversionDetail_00123", { dis: row.discount });
      }
    },
    isNumberTofixed2(num) {
      return /^[0-9]\d*$/.test(num);
    },
    remove(row) {
      var om = this;
      ajax({
        type: "DELETE",
        url: requestUrl + "/outsideowner/site/discount/rule?id=" + row.id,
        dataType: "json",
        success: function (response) {
          var url =
            requestUrl +
            "/outsideowner/site/discount/rule?status=0,1&deviceGroupOutsideownerId=" +
            om.id;
          om.initData(url);
        },
      });
    },
    modify(row) {
      var s = 0;
      this.disCountList.forEach(function (val, idx, arr) {
        s += val.flag;
      }, 0);
      if (s != 0) {
        this.$message.error(this.$parent.$t("diversionDetail_00116"));
        return;
      }
      row.flag = true;
      if (row.status == 0) {
        this.cacheS = row.startTime;
        this.cacheE = row.endTime;
        this.cacheD = row.discount;
        this.cacheT = row.type;
        row.startTime = formmatTime(row.startTime, true);
        row.endTime = formmatTime(row.endTime, true);
      } else {
        row.statusFlag = true;
        this.cacheE = row.endTime;
        row.endTime = formmatTime(row.endTime, true);
      }

      console.log(row.startTime);
      /* this.cacheSF = row.sFlag;
      this.cacheEF = row.eFlag; */
    },
    cancel(row, index) {
      if (row.isAdd) {
        this.disCountList.pop();
        this.addFlag = false;
        return;
      }
      row.flag = false;
      if (row.status == 0) {
        row.startTime = this.cacheS;
        row.endTime = this.cacheE;
        row.discount = this.cacheD;
        row.type = this.cacheT;
      } else {
        row.endTime = this.cacheE;
      }

      /* row.sFlag = this.cacheSF
      row.eFlag = this.cacheEF */
    },
    save(row) {
      if (row.sFlag) {
        var c = new Date();
        row.startTime = c;
      }
      if (row.startTime == "" && !row.sFlag) {
        this.$message.error(this.$t("diversionDetail_00117"));
        return;
      }
      if (row.endTime == "" && !row.eFlag) {
        this.$message.error(this.$t("diversionDetail_00118"));
        return;
      }
      if (row.discount == "") {
        this.$message.error(this.$t("diversionDetail_00119"));
        return;
      }
      let chargeNumReg = /[^\d]/g;
      if (row.type == 0) {
        if (Number(row.discount) > 100 || chargeNumReg.test(row.discount)) {
          this.$message.error(this.$t("diversionDetail_00120"));
          return false;
        }
      }
      let data = {
        endTime: this.timeQuery(row.endTime),
        type: row.type,
        feeScope: 0,
        discount: 100 - row.discount,
      };
      if (row.isAdd) {
        data.deviceGroupOutsideownerId = this.id;
      } else {
        data.id = row.id;
      }
      if (row.status == 0 || row.status == undefined) {
        data.startTime = this.timeQuery(row.startTime);
      }
      if (row.type == 1) {
        data.discount = row.discount * 100;
        data.feeScope = 1;
      }
      if (row.eFlag) {
        data.endTime = "20991230235959";
      }
      let om = this;
      ajax({
        type: "POST",
        url: requestUrl + "/outsideowner/site/discount/rule",
        dataType: "json",
        data: JSON.stringify(data),
        success: function (response) {
          var info = JSON.parse(response);
          if (info.error != null) {
            if (info.error.code == "NOT_LOGIN") {
              window.location.href = "./login.html?l=0";
            }
            om.$message.error(
              info.error.msg == null ? info.error.code : info.error.msg
            );
          } else {
            om.$message.success(om.$t("diversionDetail_00121"));
            var url =
              requestUrl +
              "/outsideowner/site/discount/rule?status=0,1&deviceGroupOutsideownerId=" +
              om.id;
            om.initData(url);
          }
        },
      });
    },
  },
  created() {
    var url =
      requestUrl +
      "/outsideowner/site/discount/rule?status=0,1&deviceGroupOutsideownerId=" +
      this.id;
    this.initData(url);
  },
};
</script>

<style lang="scss" scoped>
.title {
  > p {
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px dashed #e6e7ec;
    .name {
      max-width: 200px;
      min-width: 150px;
      color: #464b5c;
      font-size: 14px;
      font-weight: 400;
    }
    span {
      color: #464b5c;
      font-size: 14px;
    }
  }
}
.wrap {
  .add-wrap {
    margin: 10px 0;
  }
  .list-wrap {
    margin: 10px 0;
    height: 40px;
    line-height: 40px;
  }
  .table {
    .inp {
      width: 99%;
    }
    .btn {
      text-align: right;
      margin-top: 20px;
    }
  }
}

.el-button {
  font-weight: 600;
}
/*
* 分页器
*/
.page {
  width: inherit;
  height: auto;
  margin-top: 30px;
  text-align: center;
}

/deep/.el-dialog {
  border-radius: 4px;
  max-height: 800px;
  overflow-y: auto;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
</style>
