export default {
  knowledgeDetail_00001: 'Knowledge bank details',
  knowledgeDetail_00002: 'Knowledge bank',
  knowledgeDetail_00003: 'Update time',
  knowledgeDetail_00004: 'Delete',
  knowledgeDetail_00005: 'Edit',
  knowledgeDetail_00006: 'category',
  knowledgeDetail_00007: "Related charger's brand and model",
  knowledgeDetail_00008: 'Ticket',
  knowledgeDetail_00009: 'Chargers',
  knowledgeDetail_00010: 'Issue description',
  knowledgeDetail_00011: 'Transactions',
  knowledgeDetail_00012: 'Transactions',
  knowledgeDetail_00013: "Charger's error logs",
  knowledgeDetail_00014: 'Equipment fault code',
  knowledgeDetail_00015: 'Equipment fault description',
  knowledgeDetail_00016: 'Users',
  knowledgeDetail_00017: 'WeChat userS',
  knowledgeDetail_00018: 'Card userS',
  knowledgeDetail_00019: 'Solution',
  knowledgeDetail_00020: 'Processing method',
  knowledgeDetail_00021: 'Shared with relevant CPOs',
}