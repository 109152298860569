export default {
    begin_date_select_remind: "开始日期",
    end_date_select_remind: "结束日期",
    to: "至",
    all_site:"全部站点",
    self_run_site:"自营站点",
    custom_site:"自定义站点",
    add_site:"添加站点",
    site_name: "站点名称",
    operator: "运营商",
    charging_package50:"请输入少于16个字的代金券名称",
    charging_package53:"请完善充电套餐信息",
    charging_package68:"输入格式有误",
    charging_package69:"代金券输入格式有误",
    coupon_error_message_3:"请输入正确的有效期",
    coupon_error_message_4:"开始日期要小于或等于结束日期",
    coupon_error_message_5:"开始日期要大于或等于当前日期",
    common_00241: "添加成功",
    charging_package54:"请选择需要添加充电套餐的站点",
    enter_site:"输入站点名、运营商名",
    query: "查询",
    confirm: "确认",
    siteStatus: "站点状态",
    isOpen: "对外开放",
    enable: "启用",
    enabled: "已启用",
    disabled: "未启用",
    open: "开放",
    unopen: "不开放",
    device_type_dc: "直流",
    device_type_ac: "交流",
    chargeEquipment: "充电桩",
    member_00072: '取消',
    cancel: '取消',
    member_00073: '添加',
    ac:"交",
    dc:"直",
    delete_success: "删除成功",
    charging_package44:"您确定删除此信息！",
    remind: "提示",
    operate_canceled: "操作已取消",


    cash_coupon0001: '营销中心',
    cash_coupon0002: '优惠工具',
    cash_coupon0003: '新建',
    cash_coupon0004: '详情',
    cash_coupon0005: '修改',
    cash_coupon0006: '信息',

    cash_coupon0007: '取消',
    cash_coupon0008: '保存',
    cash_coupon0009: '删除',

    cash_coupon0010: '现金抵用电费及服务费',
    cash_coupon0011: '用电量同时抵用电费及服务费',
    cash_coupon0012: '现金抵用服务费',
    cash_coupon0013: '用电量抵用服务费',
    cash_coupon0014: '请输入数字，小数点后最多两位',
    cash_coupon0015: '相对',
    cash_coupon0016: '绝对',
    cash_coupon0017: '用户获取之日起',
    cash_coupon0018: '请输入自然数',
    cash_coupon0019: '日',
    cash_coupon0020: '至',
    cash_coupon0021: '可用站点',
    cash_coupon0022: '站点名称',
    cash_coupon0023: '查询',
    cash_coupon0024: '批量移除',
    cash_coupon0025: '操作',
    cash_coupon0026: '移除',
    cash_coupon0027: '电量包',
    cash_coupon0028: '代金券',
    cash_coupon0029: '后不可恢复',

}
