export default {
  userInvoice_00001: "团体名称",
  userInvoice_00002: "开票明细",
  userInvoice_00003: "基本信息",
  userInvoice_00004: "申请状态",
  userInvoice_00005: "全部",
  userInvoice_00006: "待处理",
  userInvoice_00007: "发票已开",
  userInvoice_00008: "已取消",
  userInvoice_00009: "查询",
  userInvoice_00010: "纸质发票",
  userInvoice_00011: "电子发票",
  userInvoice_00012: "纸质发票和电子发票",
  userInvoice_00013: "至",
  userInvoice_00014: "开始日期",
  userInvoice_00015: "结束日期",
  userInvoice_00016: "用户取消",
  userInvoice_00017: "昵称",
  userInvoice_00018: "申请类型",
  userInvoice_00019: "开票方式",
  userInvoice_00020: "申请金额",
  userInvoice_00021: "发票详情",
  userInvoice_00022: "发票抬头",
  userInvoice_00023: "公司税号",
  userInvoice_00024: "公司地址",
  userInvoice_00025: "公司电话",
  userInvoice_00026: "开户银行",
  userInvoice_00027: "开户账号",
  userInvoice_00028: "联系信息",
  userInvoice_00029: "收件人",
  userInvoice_00030: "手机号",
  userInvoice_00031: "收件地址",
  userInvoice_00032: "个人发票",
  userInvoice_00033: "企业发票",
  userInvoice_00034: "订单查询",
  userInvoice_00035: "邮箱",
  userInvoice_00036: "",
  userInvoice_00037: "",
  userInvoice_00038: "",
  userInvoice_00039: "",
  userInvoice_00040: "",
  userInvoice_00041: "",
  userInvoice_00042: "",
  userInvoice_00043: "",
  userInvoice_00044: "",
  userInvoice_00045: "",
  userInvoice_00046: "",
  userInvoice_00047: "",
  userInvoice_00048: "",
  userInvoice_00049: "",
  userInvoice_00050: "",
}