export default {
    group_00001: "请输入团体名称",
    group_00002: "用户",
    group_00003: "团体管理",
    group_00004: "团体名称",
    group_00005: "支付方式",
    group_00006: "余额({unit})",
    group_00007: "单个成员最多未支付金额({unit})",
    group_00008: "单个成员最多未支付单数",
    group_00009: "个人支付",
    group_00010: "集团支付",
    group_00011: '包含赠送{promotionBalance}{unit}',
    group_00012: '查看订单',
    group_00013: '成员总数',
    group_00014: '新建团体',
    group_00015: '团体列表',
    group_00016: "团体名称",
    group_00017: "给团体起个名称",
    group_00018: "最大未支付金额",
    group_00019: "（未支付充电订单总额达到设定则不能再充电）",
    group_00020: "（未支付充电订单总数达到设定则不能再充电）",
    group_00021: "保存并新建",
    group_00022: "最大未支付单数",
    group_00023: "团体新建成功！",
    group_00024: "可以在「用户中心」「团体」「详情」中进行如下操作:",
    group_00025: "1、修改团体名称；",
    group_00026: "2、变更支付方式；",
    group_00027: "3、调整未支付的金额和次数限制；",
    group_00028: "4、给在线账户充值（仅限于支付方式为集团账户支付时）；",
    group_00029: "请完善团体信息",
    group_00030: "输入有误",
    group_00031: "集团支付总余额({0})",
    group_00032: "集团支付现金余额({0})",
    group_00033: "集团支付总赠送金额({0})",
}