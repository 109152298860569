export default {
  whiteList_00001: 'Whiteliste', 
  whiteList_00002: 'Ladestationsmanagement',
  whiteList_00003: 'Gerätsmanagement',
  whiteList_00004: 'Whitelistkonfiguration',
  whiteList_00005: 'Kartenummer, Name, Handynummer, Nummer',
  whiteList_00006: 'alle',
  whiteList_00007: 'Online-Ausweis',
  whiteList_00008: 'Plug&Charge Karte',
  whiteList_00009: 'zurücksetzen',
  whiteList_00010: 'suchen',
  whiteList_00011: 'Name',
  whiteList_00012: 'Handynummer',
  whiteList_00013: 'Nummer',
  whiteList_00014: 'Kartenummer',
  whiteList_00015: 'Kartetypen',
  whiteList_00016: 'Quelle der Autorität',
  whiteList_00017: 'Aktion',
  whiteList_00018: 'Details anzeigen',
  whiteList_00019: 'Offline-Start/Stopp Karte',
  whiteList_00020: 'Offline-Reservekarte',
  whiteList_00021: 'Plug&Charge',
}