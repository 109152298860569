export default {
    followOfficialAccount_00001: "如您设置了跳转链接，将自动展示“关注领福利”按钮；如未设置跳转链接，将自动隐藏按钮。",
    followOfficialAccount_00002: "跳转页面",
    followOfficialAccount_00003: "预览",
    followOfficialAccount_00004: "设置帮助",
    followOfficialAccount_00005: "1.您可在微信公众号编辑引导关注公众号的文章内容，将链接设置在此处，点击“关注领福利”按钮后，将跳转您设置的文章页面；",
    followOfficialAccount_00006: "2.您可在引导关注公众号的文章中，插入公众号卡片或公众号二维码，便于充电用户快速关注；",
    followOfficialAccount_00007: "3.您可在公众号后台设置“自动回复- 被关注回复（如下图）”，将一个领取卡券活动的链接添加在消息中，以便充电用户关注后领取福利。（建议创建自由领取活动，参与活动用户范围设置为：从未在平台上充过电的用户）",
    followOfficialAccount_00008: "微信公众平台：",
    followOfficialAccount_00009: "修改",
    followOfficialAccount_000010: "点击上传图片",
    followOfficialAccount_000011: "设置帮助",
    followOfficialAccount_000012: "1.Banner可最多添加8页，每张Banner图片展示时长为3秒，按序号顺序轮播展示；",
    followOfficialAccount_000013: "2.如您设置跳转链接，点击后可跳转您所置的页面; 如您未设置跳转链接，则只展示图片",
    followOfficialAccount_000014: "3.上传图片仅支持PNG、JPG格式图片，大小不超过5M。建议尺寸690*260px（8: 3），如您上传的图片尺寸或比例不符，则会拉伸展示",


}