<template>
  <el-date-picker :size="size" @focus="pickerFocus" :clearable="clearable" :style="`width:${width}px`" v-model="date" unlink-panels @change='pickerChange' :type="pickerType" :value-format="valueFormat" :picker-options="pickerOptions" range-separator="-" :start-placeholder="$t('common_00312')" :end-placeholder="$t('common_00313')">
  </el-date-picker>
</template>
<script>
// 应用
// 选择一天
// <date-picker pickerType="date" :width="160" valueFormat="yyyyMMdd" @chooseTime="chooseTime" defaultTime="20230208000000" />
// 选择时间范围
// <date-picker @chooseTime="chooseTime" :defaultTime="['20230206000000','20230208000000']" />
export default {
  props: {
    width: {
      type: [Number, String],
      default: 226,
    },
    pickerType: {
      type: String,
      default: "daterange",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    valueFormat: {
      type: String,
      default: "yyyyMMddHHmmss",
    },
    defaultTime: {
      type: [Array, String],
      default: "",
    },
    pickerOptions: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 时间
      date: "",
      // 开始日期
      startTime: "",
      // 结束日期
      endTime: "",
    };
  },
  watch: {
    defaultTime: {
      handler(val) {
        this.date = val;
        this.pickerChange(val);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {
    // 时间选择器时间变化
    pickerChange(val) {
      switch (this.pickerType) {
        case "date":
          this.$emit("chooseTime", val);
          break;
        case "daterange":
          this.startTime = val?.length ? val[0] : "";
          this.endTime = val?.length ? val[1].substr(0, 8) + "235959" : "";
          this.$emit("chooseTime", [this.startTime, this.endTime]);
          break;
        case "datetime":
          this.$emit("chooseTime", val);
          break;
      }
    },
    pickerFocus() {
      if (this.pickerType == "datetime") this.$emit("pickerFocus");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>