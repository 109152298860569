export default {
  orderMangement_00001: 'Ticket Management',
  orderMangement_00002: "We will notify the handlers and followers of status changes of the ticket on XCHARGE's official WeChat account. '",
  orderMangement_00003: "Please use WeChat to scan the QR code of the official account on the left, and follow XCHARGE's official WeChat account. '",
  orderMangement_00004: 'In the function menu below the official account, find and open the "Smart Maintenance" MiniApp. Log in to the "Smart Maintenance" MiniApp with your platform account.',
  orderMangement_00005: 'You can receive ticket notifications for the corresponding account through the MiniApp or the "Smart Charge" official account. ',
  orderMangement_00006: 'You can receive the maintenance notification of the corresponding account through the MiniApp or WeChat service notification bar. ',
  orderMangement_00007: 'Tickets initiated by me',
  orderMangement_00008: 'Tickets processed by me',
  orderMangement_00009: 'Tickets followed by me',
  orderMangement_00010: 'Unclosed Tickets',
  orderMangement_00011: 'All Tickets',
  orderMangement_00012: 'Initiate Ticket',
  orderMangement_00013: 'Issue Description Keywords',
  orderMangement_00014: 'Station Name',
  orderMangement_00015: 'All',
  orderMangement_00016: 'Top Priority Ticket',
  orderMangement_11116: 'Second Priority Ticket',
  orderMangement_00017: 'Tickets Number',
  orderMangement_00018: 'All Manufacturers' ,
  orderMangement_00019: 'All Models',
  orderMangement_00020: 'Pending Tickets',
  orderMangement_00021: 'Processing Tickets',
  orderMangement_00022: 'The Ticket has been resolved',
  orderMangement_00023: 'The Ticket has been closed',
  orderMangement_00024: 'List of Tickets',
  orderMangement_00025: 'Export Tickets',
  orderMangement_00026: 'Tickets Number',
  orderMangement_00027: 'Tickets Title',
  orderMangement_00028: 'Categories',
  orderMangement_00029: 'Priority',
  orderMangement_00030: 'Status',
  orderMangement_00031: 'Station with issue',
  orderMangement_00032: 'Processor',
  orderMangement_00033: 'Initiator',
  orderMangement_00034: 'Initiate Time',
  orderMangement_00035: 'Update Time',
  orderMangement_00036: 'Issue Source',
  orderMangement_00037: 'Operation',
  orderMangement_00038: 'Return to Tickets',
  orderMangement_00039: 'Resolved',
  orderMangement_00040: 'Accept',
  orderMangement_00041: 'Transfer',
  orderMangement_00042: 'Close',
  orderMangement_00043: 'Add knowledge',
  orderMangement_00044: 'Details',
  orderMangement_00045: 'Very Urgent',
  orderMangement_00046: 'Urgent',
  orderMangement_00047: 'General',
  orderMangement_00048: 'Pending',
  orderMangement_00049: 'Processing',
  orderMangement_00050: 'Processed',
  orderMangement_00051: 'closed',
  orderMangement_00052: 'Remarks',
  orderMangement_00053: 'Please enter the content',
  orderMangement_00054: 'Solution',
  orderMangement_00055: 'Transfer Processor',
  orderMangement_00056: 'Accept Ticket',
  orderMangement_00057: 'Whether to accept the ticke?',
  orderMangement_00058: 'Confirm Accept',
  orderMangement_00059: 'Transfer Tickets',
  orderMangement_00060: 'Confirm transfer',
  orderMangement_00061: 'Reasons for transfer',
  orderMangement_00062: 'Close the Ticket',
  orderMangement_00063: 'Are you sure to close the Tickets',
  orderMangement_00064: 'Confirm to close',
  orderMangement_00065: 'Close reason',
  orderMangement_00066: 'Return to Tickets',
  orderMangement_00067: 'Are you sure to return to Tickets?',
  orderMangement_00068: 'Confirm Reject',
  orderMangement_00069: 'Return Reason',
  orderMangement_00070: 'The Ticket has been resolved',
  orderMangement_00071: 'Do you confirm that the Tickets has been resolved',
  orderMangement_00072: 'Confirmation Resolved',
  orderMangement_00073: 'Please fill in completely',
  orderMangement_00074: 'Operation succeed',
  orderMangement_00075: 'Staff Report',
  orderMangement_00076: 'Customer Report',
  orderMangement_00077: 'Equipment report',
  orderMangement_00078: 'Auto Trigger',
  orderMangement_00079: 'Other',
  orderMangement_00080: 'Tickets Priority',
  orderMangement_00081: 'Tickets Status',
  orderMangement_00082: 'Become a follower',
  orderMangement_00083: 'Charger Manufacturer',
  orderMangement_00084: 'We will notify the work order through the mailbox bound by the initiator, processor and follower',
}