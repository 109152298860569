export default {
    user_export_remind_1: "此操作将下载用户的Excel文件, 是否继续?",
    remind: "提示",
    confirm: "确认",
    cancel: "取消",
    start_download: "开始下载",
    cancel_download: "取消下载",

    userLevel_00001: '当前查看',
    userLevel_00002: '导出用户',
    userLevel_00003: '查看用户',
    userLevel_00004: '会员管理',
    userLevel_00005: '查看用户',
    userLevel_00006: '头像',
    userLevel_00007: '昵称',
    userLevel_00008: '性别',
    userLevel_00009: '手机号',
    userLevel_00010: '车牌号',
    userLevel_00011: '余额',
    userLevel_00012: '查询订单',
    userLevel_00013: '详情',
    userLevel_00014: '男',
    userLevel_00015: '女',
    userLevel_00016: '特权会员',
    userLevel_00017: '当前生效中&待生效：',
    userLevel_00018: '',
}
