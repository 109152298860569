export default {
    analyze_BI_00001:"整体概览",
    analyze_BI_00002:"总订单金额（元）",
    analyze_BI_00003:"总充电电量 (度)",
    analyze_BI_00004:"总充电用户数 (人)",
    analyze_BI_00005:"充电站 (个)）",
    analyze_BI_00006:"收入 (元)",
    analyze_BI_00007:"充电电量 (度)",
    analyze_BI_00008:"充电用户数(人)",
    analyze_BI_00009:"充电口(个)",
    analyze_BI_00010:"当前充电用户",
    analyze_BI_00011:"充电次数前5车型",
    analyze_BI_00012:"平均功率前5车型",
    analyze_BI_00013:"30天 X 24小时充电量热力图",
    analyze_BI_00014:"充电量前5充电站（度）",
    analyze_BI_00015:"利用率趋势",
    analyze_BI_00016:"充电量趋势",
    analyze_BI_00017:"截止",
    analyze_BI_00018:"充电量 (度)",
    analyze_BI_00019:"交流设备充电利用率（%）",
    analyze_BI_00020:"直流设备充电利用率（%）",
} 