export default {
  errorLog_00001: 'Charging station',
  errorLog_00002: 'Device model',
  errorLog_00003: 'Start time',
  errorLog_00004: 'End time',
  errorLog_00005: 'to',
  errorLog_00006: 'Please enter the device serial number',
  errorLog_00007: 'Report time',
  errorLog_00008: 'Log classification',
  errorLog_00009: 'All',
  errorLog_00010: 'Fault',
  errorLog_00011: 'Exception',
  errorLog_00012: 'Offline',
  errorLog_00013: 'Please enter the fault exception code',
  errorLog_00014: 'Fault exception description',
  errorLog_00015: 'Fault exception code',
  errorLog_00016: 'Additional description',
  errorLog_00017: 'Charging station',
  errorLog_00018: 'operator',
  errorLog_00019: 'Recovery time',
  errorLog_00020: 'Recovered',
  errorLog_00021: 'Not restored',
  errorLog_00022: '',
  errorLog_00023: '',
}
