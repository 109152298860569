export default {
    copy: '复制',
    invite_manage:"邀请好友管理",
    invite_enabled:"邀请好友启用",
    invite_open_description:"开启后，邀请好友（从未关注过公众号的用户）功能可用并在车主端 - 个人中心/我的中显示",
    invite_friend_link:"邀请好友链接",
    invite_friend_link_copy:"邀请链接复制成功",
    invite_friend_link_description:"可在微信公众号内添加邀请好友菜单, 在「微信公众号」 「自定义菜单」中添加菜单，菜单内容选择「跳转网页」, 将上方URL地址复制到「页面地址」中。",
    invite_set_rewords:"设置奖励",
    invite_stats_invite_count:"发起邀请次数",
    invite_stats_invite_userCount:"发起邀请的人数",
    invite_stats_receive_userCount:"接受邀请的好友人数",
    invite_stats_chargeCompleted_userCount:"完成首次充电的好友人数",
    invite_stats_totalBalance:"邀请人累计获得现金奖励({unit})",
    invite_stats_totalCoupon:"邀请人累计获得卡券奖励(张)",
    invite_stats_receive_totalBalance:"接受邀请的好友<br/>累计获得现金奖励({unit})",
    invite_stats_receive_totalCoupon:"接受邀请的好友<br/>累计获得卡券奖励(张)",
    invite_wechat_nickname:"邀请人微信昵称",
    invite_receive_usercount_item:"接受邀请的好友(人数)",
    invite_receive_charge_completed:"完成首次充电的好友(人数)",
    invite_receive_totalBalance:"累计获得现金奖励({unit})",
    invite_receive_totalCoupon:"累计获得卡券(张)",
    invite_open_remind:"您还没有设置奖励，无法开启邀请好友功能",
    invite_enable_remind:"是否{option}邀请好友功能",
    invite_rewords:"邀请人奖励",
    invite_reword_amount:"现金奖励({unit})",
    invite_reword_coupon:"卡券奖励",
    invite_reword_coupon_remind:"只能添加一种卡券，最多支持100张",
    invite_code:"邀请码",
    invite_object:"邀请对象",
    invite_object_tip:"必填，不超过20个字",
    invite_object_input_remind:"请输入邀请对象",
    invite_object_input_template:"还可输入{num}个字",
    invite_code_or_object:"邀请码/邀请对象",
    notify:"通知",
    clear: "清空",
    operation: "操作",
    save: "保存",
    user_detail:"用户详情",
    confirm: '确认',
    cancel: "取消",
    modify: "修改",
    remind: "提示",
    generate_invite_code_success_template:"生成邀请码成功，邀请码：{code}",
    export_invited_data: "导出",
    export_invited_data_excel:"此操作将下载邀请好友数据的Excel文件，是否继续？",
    head_portrait: "头像",
    coupon_title:"卡券名称",
    coupon_amount:"数量",

    coupon_type:"卡券类型",
    choose_coupon:"添加卡券",
    voucher_coupon:"抵用券",
    discount_coupon:"折扣券",
    type: "类型",

    detail: '详情',
    reword_set_rules_remind01:"以下奖励可单独设置或组合设置",
    reword_set_rules_remind02:"接受邀请的好友（从未关注过公众号的用户）完成首次充电并支付，给予邀请人发放奖励",
    reword_set_rules_remind03:"邀请人奖励内容至少设置一项",
    reword_set_rules_remind04:"接受邀请好友的奖励内容至少设置一项",
    reword_set_rules_remind05:"邀请人现金奖励输入错误",
    reword_set_rules_remind06:"被邀请人现金奖励输入错误",
    reword_set_rules_remind07:"卡券张数输入错误",
    reword_set_rules_remind08:"卡券张数不能超过100",
    coupon_base_invoice_5:"每种卡券最多100张",
    invitee_rewords:"接受邀请的好友奖励",
    invitee_rewords_place_rule_remind:"接收邀请的好友（从未关注过公众号的用户）接受邀请后立即发放奖励",
    close: '关闭',
    open: '打开',
    begin_date_select_remind: "选择开始日期",
    end_date_select_remind: "选择结束日期",
    until: "至",
    enter_activity_explain:"请输入活动说明",
    no_limit:"不限制",
    limit:"限制",
    single_user_charge_amount:"单个用户累计充电总费用满足",
    user_charge_amount:"单个用户累计充电量满足",
    only_no_charged:"只能是从未在平台上充过电的用户",
    userLevel:"用户等级",
    userClass:"用户分类",
    promotion_type1: "充值赠送(现金)",
    promotion_type2: "充值赠送(现金+卡券)",
    promotion_type3: "充值赠送(卡券)",
    promotion_type4: "赠送卡券",
    promotion_type5: "销售卡券",
    cycle_type1:"次/活动全程",
    cycle_type2:"次/每月",
    cycle_type3:"次/每周",
    cycle_type4:"次/每日",
    default_userLevel:"非等级用户",
    mouth_1:"1个月",
    mouth_2:"3个月",
    mouth_3:"12个月",
    promotion_date:"活动期间",
    promotion_invoice_1:"请输入少于20个字的活动名称",
    promotion_invoice_2:"请输入活动开始时间",
    promotion_invoice_3:"请输入活动结束时间",
    promotion_invoice_4:"请输入正确的活动时间",
    promotion_invoice_5:"请选择活动对象",
    promotion_invoice_6:"请输入少于150个字的活动名称",
    promotion_invoice_7:"充值金额/赠送金额输入多于4位",
    promotion_invoice_8:"单个用户充值成功次数输入有误",
    promotion_invoice_9:"单个用户累计充电总费用输入有误",
    promotion_invoice_10:"所有用户累计领取成功次数输入有误",
    promotion_invoice_11:"赠送现金总额输入有误",
    promotion_invoice_14:"最多支持6个档位",
    promotion_invoice_15:"确认删除此活动档位",
    enter_amount:"请输入金额",
    week:"1星期",
    enter_charge_placeholder:"请输入电量",
    support_refund:"支持退款",
    no_refund:"不支持退款",
    enter_success_times_1:"请输入充值成功次数",
    enter_success_times_2:"请输入领取成功次数",
    enter_discount_count:"请输入赠送现金总额",
    enter_coupon_name:"请输入卡券名称",
    query: "查询",
    common_00241: "添加成功",
    wechat_user: "微信用户",
    confirm_delete_activate:"确认删除此活动",
    delete_success: "删除成功",
    validity:"有效期",
    activity_detail: "详情",
    day:"天",
    select_coupon_invoice_1:"最多选择6种卡券",
    select_coupon_invoice_2:"最多选择1种卡券",
    less_than_or_equal_to:"小于等于",
    greater_than_or_equal_to:"大于等于",
    promotion_invoice_17:"单个用户累计充电总费用满足活动期间{equal}{feeAmount}元",
    promotion_invoice_12:"单个用户累计充电总费用满足{feeAmountPeriod}个月{equal}{feeAmount}元",
    promotion_invoice_24:"单个用户累计充电量满足活动期间{equal}{powerAmount}度",
    promotion_invoice_21:"单个用户累计充电量输入有误",
    promotion_invoice_22:"单个用户累计充电量满足{powerAmountPeriod}个月{equal}{powerAmount}度",
    promotion_invoice_23:"单个用户累计充电量满足7天{equal}{powerAmount}度",
    modify_success: '修改成功',
    count_time:"统计时间",
    count_coupons:"卡券发放总数",
    get_coupon_people:"领取总人数",
    get_coupon_count:"领取总次数",
    provide_coupon:"卡券发放情况",
    all_coupons_count:"累计发放数量",
    fee_total:"总费用",
    use_condition:"使用条件",
    used:"已用完数量",
    unused:"可使用数量",
    expired: "已失效数量",
    user_collection_detail:"用户领取情况",
    nickname: "昵称",
    sex: "性别",
    user_mobile:"用户名(手机号)",
    card_number: "卡号",
    collection_time:"领取时间",
    gifts_coupon_amount:"赠送券额",
    gifts_coupon_length:"赠送卡券个数",
    u3_card: "在线身份卡",
    promotion_use_coupon_condition:"单次充电{msg}满足{feeAtLeast}元",
    service_fee: "服务费",
    promotion_amount:"赠送总金额(元)",
    amount_balance:"充值总金额(元)",
    promotion_charge_people:"充值总人数",
    promotion_charge_count:"充值总次数",
    user_charge_detail:"用户储值情况",
    charge_time:"储值时间",
    export_data: "导出数据",
    coupon_detail:"卡券详情",
    start_download: "开始下载",
    cancel_download: "取消下载",
    promotion_invoice_16:"请输入少于500个字的活动说明",

    enable: "启用",




    invite_00001: '邀请好友',
    invite_00002: '营销中心',
    invite_00003: '是',
    invite_00004: '否',
    invite_00005: '邀请好友管理',
    invite_00006: '邀请人列表',
    invite_00007: '邀请好友奖励设置',
    invite_00008: '取消',
    invite_00009: '保存',
    invite_00010: '编辑',
    invite_00011: '卡券类型',

}
