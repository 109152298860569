export default {
    userClassList_00001: "Category Management",
    userClassList_00002: "Category List",
    userClassList_00003: "User Categories",
    userClassList_00004: "Category Name",
    userClassList_00005: "Number of current users",
    userClassList_00006: "Number of marketing campaigns",
    userClassList_00007: "Marketing Activity",
    userClassList_00008: "Category Details",
    userClassList_00009: "Operation",
  }