export default {
    orderlyCharging_00001: "公交车有序充电实际运行",
    orderlyCharging_00002: "帮助",
    orderlyCharging_00003: "车辆充电",
    orderlyCharging_00004: "车辆行车",
    orderlyCharging_00005: "限制充电总功率",
    orderlyCharging_00006: "实际充电总功率",
    orderlyCharging_00007: "车辆编码",
    orderlyCharging_00008: "出发时最低要求SOC (%)",
    orderlyCharging_00009: "出发时间",
    orderlyCharging_00010: "回站时间",
    orderlyCharging_00011: "电量",
    orderlyCharging_00012:"1、充电桩里离线时启动充电，平台无法控制总体负载，应谨慎操作！<br/>2、站点资费设置延时费，公交车有序充电功能无法使用！<br/>3、车辆充电时跳枪，需人工再次启动充电！",
}
