export default {
    platformLog_00001: "触发时间",
    platformLog_00002: "开始日期",
    platformLog_00003: "结束日期",
    platformLog_00004: "设备序列号",
    platformLog_00005: "订单编号",
    platformLog_00006: "通讯协议",
    platformLog_00007: "通讯方式",
    platformLog_00008: "报文键值",
    platformLog_00009: "报文数据",
    platformLog_00010: "报文翻译",
    platformLog_00011: "云端下发",
    platformLog_00012: "设备上报",
    platformLog_00013: "查看",
    platformLog_00014: "切换原文",
    platformLog_00015: "多个以英文逗号隔开",
    platformLog_00016: "日期范围不能超过90天，请重新选择！",
    platformLog_00018: "请选择日期！",
    platformLog_00019: "请输入内容",
    platformLog_00020: "报文内容",
    platformLog_00021: "报文数据片段",
    platformLog_00022: "签名数据",
    platformLog_00023: "数据",
    platformLog_00024: "公钥",
}