export default {
    teamPlaylistActivity_00001: "加盟商邀请",
    teamPlaylistActivity_00002: "创建邀请",
    teamPlaylistActivity_00003: "已发送邀请",
    teamPlaylistActivity_00004: "查询",
    teamPlaylistActivity_00005: "生成邀请码",
    teamPlaylistActivity_00006: "修改邀请对象",
    teamPlaylistActivity_00007: "邀请码/邀请对象",
    teamPlaylistActivity_00008: "邀请码",
    teamPlaylistActivity_00009: "生成时间",
    teamPlaylistActivity_000010: "邀请对象",
    teamPlaylistActivity_000011: "状态",
    teamPlaylistActivity_000012: "操作",
    teamPlaylistActivity_000013: "请输入邀请对象",
    teamPlaylistActivity_000014: "必填，不超过20个字",
    teamPlaylistActivity_000015: "生成",
    teamPlaylistActivity_000016: "状态",
    teamPlaylistActivity_000017: "全部",
    teamPlaylistActivity_000018: "未使用",
    teamPlaylistActivity_000019: "已使用",
    teamPlaylistActivity_000020: "生成邀请码成功",
    teamPlaylistActivity_000021: "服务器响应超时",
    teamPlaylistActivity_000022: "请保存上一步操作！！",
    teamPlaylistActivity_000023: "请填写邀请对象",
    teamPlaylistActivity_000024: "输入的字数不超过20个字",
    teamPlaylistActivity_000025: "邀请列表",
    teamPlaylistActivity_000026: "",

    //---------已发送邀请-------------------------------
    screen_time: "筛选时间",
    teamPlaylistActivity_000027: "开始时间",
    teamPlaylistActivity_000028: "结束时间",
    teamPlaylistActivity_000029: "运营商名称/邀请码",
    teamPlaylistActivity_000030: "查询",
    teamPlaylistActivity_000031: "运营商名称",
    teamPlaylistActivity_000032:"创建时间",
    teamPlaylistActivity_000033:"联系人姓名",
    teamPlaylistActivity_000034:"手机号",
    teamPlaylistActivity_000035:"邀请码"
    
}