export default {
    deviceMessageTemplate_00001: "自定义编辑配置信息模版,在充电站中可以直接添加下方模版,或者基于模版再次编辑",
    deviceMessageTemplate_00002: "C1（4.3英寸屏幕）充电桩屏幕配置信息",
    deviceMessageTemplate_00003: "C1（7英寸屏幕）充电桩屏幕配置信息",
    deviceMessageTemplate_00004: "智能充电系统",
    deviceMessageTemplate_00005: "查看大图",
    deviceMessageTemplate_00006: "开机logo",
    deviceMessageTemplate_00007: "顶部logo",
    deviceMessageTemplate_00008: "界面文字",
    deviceMessageTemplate_00009: "不超过16个字",
    deviceMessageTemplate_000010: "C2充电桩屏幕配置信息",
    deviceMessageTemplate_000011: "扫码界面标题",
    deviceMessageTemplate_000012: "微信扫码 启动充电",
    deviceMessageTemplate_000013: "微信扫描左侧二维码,按提示进行下一步操作",
    deviceMessageTemplate_000014: "扫码界面文字",
    deviceMessageTemplate_000015: "不超过10个字",
    deviceMessageTemplate_000016: "不超过24个字",
    deviceMessageTemplate_000017: "C4充电桩屏幕配置信息",
    deviceMessageTemplate_000018: "C6充电桩屏幕配置信息",
    deviceMessageTemplate_000019: "C6s充电桩屏幕配置信息",
    deviceMessageTemplate_000020: "欢迎使用智能直流充电系统",
    deviceMessageTemplate_000021: "请使用微信扫码充电或使用NFC刷卡充电",
    deviceMessageTemplate_000022: "使用触摸按键选择充电口",
    deviceMessageTemplate_000023: "顶部文字",
    deviceMessageTemplate_000024: "欢迎文字标题",
    deviceMessageTemplate_000025: "不超过{num}个字",
    deviceMessageTemplate_000026: "欢迎文字内容",
    deviceMessageTemplate_000027: "底部文字",
    deviceMessageTemplate_000028: "将文件拖到此处，或|点击上传",
    deviceMessageTemplate_000029: "仅支持PNG格式图片，尺寸{area}px，不超过{size}kb。",
    deviceMessageTemplate_000030: "修改图片",
    deviceMessageTemplate_000031: "上传图片",
    deviceMessageTemplate_000032: "请上传图片",
    deviceMessageTemplate_000033: "您所选的图片过大，请重新选择",
    deviceMessageTemplate_000034: "请选择png格式的图片上传",
    deviceMessageTemplate_000035: "请上传符合{0}px的图片",
    deviceMessageTemplate_000036: "修改顶部logo",
    deviceMessageTemplate_000037: "顶部logo可以使用文字也可以使用图片格式，二选一。",
    deviceMessageTemplate_000038: "使用文字",
    deviceMessageTemplate_000039: "请输入内容",
    deviceMessageTemplate_000040: "{num}个字以内(包括标点符号)",
    deviceMessageTemplate_000041: "使用图片",
    deviceMessageTemplate_000042: "X-CHARGE智充",
    deviceMessageTemplate_000043: "顶部界面文字过长",
    deviceMessageTemplate_000044: "请先选择'使用图片",
    deviceMessageTemplate_000045: "您所选的图片过大，请重新选择",
    deviceMessageTemplate_000046: "请选择png格式的图片上传",
    deviceMessageTemplate_000047: "参数为空",
    deviceMessageTemplate_000048: "参数长度过长",
    deviceMessageTemplate_000049: "请扫码充电或使用NFC刷卡充电",
    deviceMessageTemplate_000050: "扫码界面标题不能超过10位",
    deviceMessageTemplate_000051: "扫码界面文字不能超过24位",
    deviceMessageTemplate_000052: "扫码启动充电",
    deviceMessageTemplate_000053: "扫描左侧二维码,按提示进行下一步操作",
    deviceMessageTemplate_000054: "请先保存上一步操作",
    deviceMessageTemplate_000055: "输入的文字不能超过7位",
    

    
    
    
    
}