export default {
    begin_date_select_remind: "Select start date",
    end_date_select_remind: "Select expiry date",
    until: "until",
    enter_activity_explain:"Please input the activities' illustration",
    no_limit:"unlimited",
    limit:"limited",
    single_user_charge_amount:"if total cumulative charging cost for a single user meets",
    user_charge_amount:"if total cumulative charging cost for a single user meets",
    only_no_charged:"Only available for the users who have never charged from the platform",
    userLevel:"User level",
    userClass:"User tag",
    promotion_type1: "Top-up refund(cash)",
    promotion_type2: "Top-up refund(cash+voucher)",
    promotion_type3: "Top-up refund(voucher)",
    promotion_type4: "Complimentary Voucher",
    promotion_type5: "Sales coupons",
    cycle_type1:"Time(s)/ whole event",
    cycle_type2:"Time(s)/month",
    cycle_type3:"Time(s)/week",
    cycle_type4:"Time(s)/day",
    default_userLevel:"Non-ranked users",
    mouth_1:"1 month",
    mouth_2:"3 months",
    mouth_3:"12 months",
    promotion_date:"During the event",
    promotion_invoice_1:"Please enter an event name of less than 20 characters",
    promotion_invoice_2:"Please enter the event start time",
    promotion_invoice_3:"Please enter the event end date",
    promotion_invoice_4:"Please enter the correct event time",
    promotion_invoice_5:"Please select the target users",
    promotion_invoice_6:"Please enter an event description of less than 150 characters",
    promotion_invoice_7:"Enter more than 4 digits for the recharge amount/cash gift amount",
    promotion_invoice_8:"The number of successful top-ups for single user has been entered incorrectly",
    promotion_invoice_9:"The total cumulative charge cost for single user is incorrectly entered",
    promotion_invoice_10:"The cumulative number of successful claims for all users has been entered incorrectly",
    promotion_invoice_11:"The total amount of the cash gift was entered incorrectly",
    promotion_invoice_14:"Supports up to 6 levels",
    promotion_invoice_15:"Confirm deletion of this level",
    enter_amount:"Please enter the amount",
    week:"1 week",
    enter_charge_placeholder:"Please enter electricity amount",
    support_refund:"refund is supported",
    no_refund:"Refunds are not supported",
    enter_success_times_1:"Please enter the number of successful top-ups",
    enter_success_times_2:"Please enter the number of successful claims",
    enter_discount_count:"Please enter the total amount of cash gifted",

    enter_coupon_name:"Please enter the name of the coupon",
    query: "Search",
    confirm: "Confirm",
    coupon_title:"Ticket name",
    coupon_type:"Ticket type",
    common_00241: "Added successfully",
    wechat_user: "Wechat user",
    confirm_delete_activate:"Confirm the deletion",
    delete_success: "Deleted successfully",
    
    validity:"Expiry date",
    operation: "Operate",
    activity_detail: "Detail",
    voucher_coupon:"Vouchers",
    discount_coupon:"Discount Coupons",
    day:"Day(s)",
    select_coupon_invoice_1:"Choose up to 6 coupons",
    select_coupon_invoice_2:"Select up to 1 coupon",
    less_than_or_equal_to:"Less than or equal to",
    greater_than_or_equal_to:"More than or equal to",
    promotion_invoice_17:"The total cumulative charging cost for single user meets the campaign period {equal}{feeAmount}euro",
    promotion_invoice_12:"单个用户累计充电总费用满足{feeAmountPeriod}个月{equal}{feeAmount}元",
    promotion_invoice_24:"Cumulative charging for a single user meets {equal}{powerAmount} kwh during the campaign period",
    promotion_invoice_21:"Incorrect input of the cumulative charge for single user",
    promotion_invoice_22:"Cumulative charge for a single user meets {powerAmountPeriod} for {equal}{powerAmount} kwh per month",
    promotion_invoice_23:"Cumulative charge for a single user meets 7 days {equal}{powerAmount} kwh",
    modify_success: 'Modified successfully',

    count_time:"Statistical time",
    count_coupons:"Total number of tickets issued",
    get_coupon_people:"Total number of recipients",
    get_coupon_count:"Total number of Collection",
    provide_coupon:"Tickets distribution detail",
    all_coupons_count:"Cumulative number of issues",
    fee_total:"Total cost",

    use_condition:"Conditions for use",
    used:"Quantity used up",
    unused:"available quantity",
    expired: "Number of expired",
    user_collection_detail:"detail of User collection",
    head_portrait: "Photo profile",
    nickname: "Name",
    sex: "Gender",
    user_mobile:"User name(mobile number)",
    card_number: "Card No.",
    collection_time:"Collection time",
    gifts_coupon_amount:"Voucher amount",
    gifts_coupon_length:"Number of vouchers",
    u3_card: "Online Identity Card",
    promotion_use_coupon_condition:"Single charge {msg} satisfies euro{feeAtLeast}",
    service_fee: "Service fee",
    promotion_amount:"Total cash amount of gift (€)",
    amount_balance:"Total amount of top-up(€)",
    promotion_charge_people:"Total number of recharges",
    promotion_charge_count:"Total times of recharges",
    user_charge_detail:"User top-up Status",
    charge_time:"Top-up date",
    remind: "Tips",
    export_data: "Export data",
    coupon_detail:"Ticket detail",
    user_detail:"User detail",
    cancel: "Cancel",
    start_download: "Start downloading",
    cancel_download: "Cancel download",
    type: 'User type',
    promotion_invoice_16:"Please enter less than 500 words for event description",
    level_check_info:"Please select the user level",




    stored_00001: 'detail',
    stored_00002: 'Marketing Center',
    stored_00003: 'Mareting Activity',
    stored_00004: 'Create',
    stored_00005: 'Modify',
    stored_00006: 'General info',
    stored_00007: 'Event Info',
    stored_00008: 'Event name',
    stored_00009: 'Event date',
    stored_00010: 'Event target',
    stored_00011: 'Wechat user',
    stored_00012: 'Event level(s)',
    stored_00013: 'Add level',
    stored_00014: '（Add at least 1 leve, support up to 6 levels)',
    stored_00015: 'Level',
    stored_00016: 'Top-up value(€)',
    stored_00017: 'Cash gift amount(€)',
    stored_00018: 'Gift Vouchers',
    stored_00019: 'Operate',
    stored_00020: 'Modified',
    stored_00021: 'Number of successful claims by single user',
    stored_00021_1: 'Delete level',
    stored_00022: 'Total number of successful claims for all users',
    stored_00023: 'Number of successful single-user Top-up values',
    stored_00024: 'Total cash gifted',
    stored_00025: 'Refund of top-up',
    stored_00026: 'Range of participating users',
    stored_00027: 'Ongoing',
    stored_00028: 'Paused',
    stored_00029: 'Cancel',
    stored_00030: 'Save',
    stored_00031: 'Modify',
    stored_00032: 'Delete',
    stored_00033: 'Event result',
    stored_00034: 'Event description',
    stored_00035: 'Activity ID',
    


}
