export default {
    analyzeUser_00001: "Gesamtanalyse der Nutzeranzahl",
    analyzeUser_00002: "Analyse der Anzahl der Nutzer von einer Ladestation",
    analyzeUser_00003: "Analyse der Nutzungsrate von Ausrüstungsmodellen",
    analyzeUser_00004: "Analyse der Nutzerbeiträge",
    analyzeUser_00005: "Benutzer RFM-Modellanalyse",
    analyzeUser_00006: "Trends der Nutzeranzahl",
    analyzeUser_00007: "Analyse",
    analyzeUser_00008: "Analyse nach Elektrizität",
    analyzeUser_00009: "Analyse nach Stunden",
    analyzeUser_00010: "Anzahl der Nutzer",
    analyzeUser_00011: "Anzahl der ersten Laden",
    analyzeUser_00012: "Vergleich der Anzahl der Nutzer von einer Ladestation",
    analyzeUser_00013: "Trend der Anzahl der Nutzer von einer Ladestation",
    analyzeUser_00014: "Durchschnittlicher Einkommenbeitrag pro Nutzer",
    analyzeUser_00015: "Durchschnittlicher Ladeelektrizität pro Nutzer",
    analyzeUser_00016: "Durchschnittliche Ladedauer pro Nutzer",
    analyzeUser_00017: "Durchschnittliche Anzahl pro Nutzer",
    analyzeUser_00018: "Kumulierter Ladebetrag(€)",
    analyzeUser_00019: "Name",
    analyzeUser_00020: "Nutzertypen", 
    analyzeUser_00021: "Kartenummer",
    analyzeUser_00022: "Zeit der letzte Laden", 
    analyzeUser_00023: "Kumulierte Ladebetrag", 
    analyzeUser_00024: "Anzahl der Ladung", 
    analyzeUser_00025: "Anzahl der Ladung ", 
    analyzeUser_00026: "Nutzer, deren letzte Laden weniger als 30 Tage zurückliegt",
    analyzeUser_00027: "Nutzer, deren letzte Laden zwischen 30 und 90 Tagen zurückliegt",
    analyzeUser_00028: "Nutzer mit einer letzten Laden zwischen 90 und 180 Tagen",
    analyzeUser_00029: "Vergleich der Nutzeranzahl für Franchisenehmer",
    analyzeUser_00030: "Analyse der Nutzeranzahl für Franchisenehmer",
    analyzeUser_00031: "Trend der Franchisenehmer-Nutzer",
    analyzeUser_00032: "Analyse der Vorgängeanzahl nach Benutzerquellen",
    analyzeUser_00033: "Anzahl der Ladevorgänge", 
    analyzeUser_00034: "Anteil des nutzerfinanzierten Vorgängeanzahl", 
    analyzeUser_00035: "Anteil des Verkehrs von Dritten nach Ladevolumen",
    analyzeUser_00036: "Trends des nutzerfinanzierten Vorgängeanzahl",
    analyzeUser_00037: "Trends des Verkehrs von Dritten nach Vorgängeanzahl",
    analyzeUser_00038: 'Transaction',
    analyzeUser_00039: 'Transaction number per connector',
}