export default {
    eventRelease_00001: "homepage banner",
    eventRelease_00002: "home banner enabled",
    eventRelease_00003: "Preview",
    eventRelease_00004: "banner picture",
    eventRelease_00005: "jump page",
    eventRelease_00006: "display order",
    eventRelease_00007: "select default picture",
    eventRelease_00008: "amplification",
    eventRelease_00009: "modify",
    eventRelease_000010: "click to upload pictures",
    eventRelease_000011: "setting help",
    eventRelease_000012: "1. The uploaded image only supports PNG and JPG format images, and the size is not more than 5m. The recommended size is 690 * 260px (8:3). If the size or scale of the uploaded image is not consistent, it will be stretched for display",
    eventRelease_000013: "",
    eventRelease_000014: "",
    eventRelease_000015: "select activity type",
    eventRelease_000016: "please paste your official account article link, wechat public platform: https://mp.weixin.qq.com/ ",
    eventRelease_000017: "up to 8 banners can be set",
    eventRelease_000018: "please set banner picture",
    eventRelease_000019: "please set the activity ID",
    eventRelease_000020: "Upload",
    eventRelease_000021: "no jump",
    eventRelease_000022: "active page",
    eventRelease_000023: "official account link",
    eventRelease_000024: "return of stored value",
    eventRelease_000025: "free collection",
    eventRelease_000026: "invite friends",
    eventRelease_000027: "purchase card coupon",
    eventRelease_000028: "Please set the jump page",
    eventRelease_000029: "Activity name",
    eventRelease_000030: "Select Activity",
    eventRelease_000031: "New Activity",
    
}