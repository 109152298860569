export default {
  addDevice_00001: "请选择或新建要添加设备的场站",
  addDevice_00002: "请选择场站",
  addDevice_00003: "添加场站设备",
  addDevice_00004: "请选择场站添加设备。",
  addDevice_00005: "",
  addDevice_00006: "",
  addDevice_00007: "",
  addDevice_00008: "",
  addDevice_00009: "",
  addDevice_00010: "",
};