export default {
  knowledge_00001: 'Knowledge Bank',
  knowledge_00002: 'Title/Issue description/Solution',
  knowledge_00003: 'All',
  knowledge_00004: 'All Models',
  knowledge_00005: 'List',
  knowledge_00006: 'Create Knowledge',
  knowledge_00007: 'Knowledge Title',
  knowledge_00008: 'Ticket',
  knowledge_00009: 'Related Chargers',
  knowledge_00010: '',
  knowledge_00011: '',
  knowledge_00012: '',
  knowledge_00013: '',
  knowledge_00014: 'Second Priority Ticket',
  knowledge_00015: 'All Categories',
  knowledge_00016: 'Top Priority Ticket',
}