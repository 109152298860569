export default {
    managementCharger_00001: 'Operator charging card management',
    managementCharger_00002: 'charging card management',
    managementCharger_00003: 'Plug & Charge charging card management',
    managementCharger_00004: 'Offline start/stop card management',
    managementCharger_00005: 'Offline stored value card management',
    managementCharger_00006: 'Enter the card number',
    managementCharger_00007: 'Charging cards',
    managementCharger_00008: 'New plug-and-charge charging card',
    managementCharger_00009: 'New ID card',
    managementCharger_00010: 'Download NFC (APP)',
    managementCharger_00011: 'Card number',
    managementCharger_00012: 'Card type',
    managementCharger_00013: 'Offline start-stop card',
    managementCharger_00014: 'Offline stored value card',
    managementCharger_00015: 'Plug and charge card',
    managementCharger_00016: 'ID card',
    managementCharger_00017: 'Plug and charge',
    managementCharger_00018: 'Aux Number',
    managementCharger_00019: 'Vehicle license number',
    managementCharger_00020: 'Name (mobile)',
    managementCharger_00021: 'Card group',
    managementCharger_00022: 'Balance',
    managementCharger_00023: 'operate',
    managementCharger_00024: 'Transactions',
    managementCharger_00025: "{id}(Reported lost)",
    managementCharger_00026: "New plug-and-charge card",
    managementCharger_00027: "Query the vehicle matched by the user{type}",
    managementCharger_00028: "Complete card information",
    managementCharger_00029: "Join the deck",
    managementCharger_00030: "Complete new",
    managementCharger_00031: "Please enter the user's mobile phone number",
    managementCharger_00032: "This mobile phone number matches multiple vehicles ({type}) within 2 weeks, please manually determine the vehicle ({type})!",
    managementCharger_00033: "Avatar",
    managementCharger_00034: "User nickname|phone number",
    managementCharger_00035: "vehicle{type}",
    managementCharger_00036: "Last time|Charging end time",
    managementCharger_00037: "Last time | Charging site",
    managementCharger_00038: "confirm",
    managementCharger_00039: "card number",
    managementCharger_00040: "source",
    managementCharger_00041: "Aux number",
    managementCharger_00042: "Contact name",
    managementCharger_00043: "Mobile",
    managementCharger_00044: "Vehicle license number",
    managementCharger_00045: "Key",
    managementCharger_00046: "Recharge amount",
    managementCharger_00047: "Login password",
    managementCharger_00048: "Join the deck (required)",
    managementCharger_00049: "Please select a card group",
    managementCharger_00050: "Successfully made charging card",
    managementCharger_00051: "allowable",
    managementCharger_00052: "「Charging card management」",
    managementCharger_00053: "Charging card group",
    managementCharger_00054: "「detail」",
    managementCharger_00055: "Perform the following operations in:",
    managementCharger_00056: "1, Top-up storage type charging cards;",
    managementCharger_00057: "2. View the top-up record；",
    managementCharger_00058: "3. Modify the contact information of charging card;",
    managementCharger_00059: "Previous",
    managementCharger_00060: "Next step",
    managementCharger_00061: "confirm",
    managementCharger_00062: "Please enter the ID card number",
    managementCharger_00063: "Please enter the correct card number",
    managementCharger_00064: "Please enter the card source",
    managementCharger_00065: "Please enter the correct license plate number",
    managementCharger_00066: "The format of the top-up amount is incorrect",
    managementCharger_00067: "Please enter the password",
    managementCharger_00068: "Online ID card entry is successful",
    managementCharger_00069: "New ID card",
    managementCharger_00070: "Card number cannot contain Chinese",
    managementCharger_00071: "The source is not allowed to fill in 'plug and charge'",
    managementCharger_00072: "please enter a valid phone number",
  }