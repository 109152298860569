export default {
    energyStatistics_00001: "Discharging amount of NZS ",
    energyStatistics_00002: "Usage of grid",
    energyStatistics_00003: "EV charging amount(kwh)",
    energyStatistics_00004: "Energy income trence",
    energyStatistics_00005: "Total income of EV charging",
    energyStatistics_00006: "NZS charging(kwh)",
    energyStatistics_00007: "Analysis",
    energyStatistics_00008: "Discharging cost of grid part",
    energyStatistics_00009: "Charging cost of grid part",
    energyStatistics_00010: "Energy income",
    energyStatistics_00011: "Electricity amount of EV charging",
    energyStatistics_00012: "EV charging usage(kwh)",
    energyStatistics_00013: "Total income of energy",
    energyStatistics_00014: "Total income of energy",
    energyStatistics_00015: "",
    energyStatistics_00016: "",
   
} 