export default {
    coupon_charge_condition_1:"The total cost of a single charging session meets {feeAtLeast}€",
    voucher_amount_1:"Credit the total cost of {discount}€",
    discount_amount_4:"Total charge is free",
    coupon_charge_condition_2:"Single charging service fee meets {feeAtLeast}€",
    voucher_amount_2:"Credit for service charge of {discount} ",
    discount_amount_3:"Free service fee",
    voucher_coupon:"Voucher",
    discount_coupon:"Discount",
    from_get_coupon_on:"voucher_coupon_date{validityDays}",
    discount_amount_1:"Total fee{discount} discount",
    discount_amount_2:"Service fee {discount}discount",

    coupon_00001: 'Marketing center',
    coupon_00002: 'Discount tools',
    coupon_00003: 'Name',
    coupon_00004: 'Please enter',
    coupon_00005: 'Reset',
    coupon_00006: 'Search',
    coupon_00007: 'List',
    coupon_00008: 'Create',
    coupon_00009: 'Using condition',
    coupon_00010: 'Expiry date',
    coupon_00011: 'Operation',
    coupon_00012: 'Detail',
    coupon_00013: 'Voucher',
    coupon_00014: 'Discount',


}
