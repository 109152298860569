<template>
  <div class="mode_wrap">
    <el-row type="flex" align="middle" class="row">
      <el-col :span="12">{{$t('common_61045')}}</el-col>
      <el-col :span="12" align="right">
        <el-button size="medium" @click="cancelEdit()" v-if="isEdit == true">{{$t('common_00215')}}</el-button>
        <el-button type="primary" size="medium" @click="saveEdit('save')" v-if="isEdit == true">{{$t('common_00217')}}</el-button>
        <el-button type="primary" size="medium" @click="changeEdit()" v-if="isEdit == false">{{$t('common_00365')}}</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" class="row">
      <el-col :span="6">
        <span>{{$t('common_61046')}}</span>
        <!-- <span class="more" @click="tipsDialog=true">{{$t('common_61047')}}</span> -->
        <el-tooltip class="item" effect="dark" :content="$t('common_61047')" placement="top">
          <i class="el-icon-info" @click="tipsDialog=true"></i>&nbsp;
        </el-tooltip>
      </el-col>
      <el-col :span="18">
        <div class="content_box" v-if="isEdit">
          <el-radio-group v-model="modeWorking">
            <el-radio label="Max">{{$t('common_20017')}}</el-radio>
            <el-radio label="Eco">{{$t('common_20018')}}</el-radio>
            <el-radio label="Auto">{{$t('common_20019')}}</el-radio>
            <el-radio label="Custom">{{$t('common_70000')}}</el-radio>
          </el-radio-group>
          <div class="custom-content" v-if="modeWorking == 'Custom'">
            <el-select class="w200" v-model="planId" :placeholder="$t('common_61074')">
              <el-option v-for="item in planList" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
            <el-button class="m20" type="text" size="medium" @click="toDetail(1)">{{$t('common_70004')}}</el-button>
            <a href="./workSchedule.html" target="_blank">
              <el-button type="text" size="medium">{{$t('common_70001')}}</el-button>
            </a>
          </div>
        </div>
        <div class="value" v-else>
          {{modeValueText[modeWorking]}}
          <span class="plan_title" v-if="modeWorking=='Custom'" @click="toDetail(3)">{{planTitle}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" class="row">
      <el-col :span="6">
        <span>{{$t('common_61048')}}</span>
      </el-col>
      <el-col :span="18">
        <div v-if="isEdit">
          <el-radio-group v-model="modeAirConditioning">
            <el-radio label="Eco">{{$t('common_20038')}}</el-radio>
            <el-radio label="Max">{{$t('common_20039')}}</el-radio>
          </el-radio-group>
        </div>
        <div class="value" v-else>{{modeAirConditioningText[modeAirConditioning]}}</div>
      </el-col>
    </el-row>
    <!-- 弹窗start -->
    <!-- 储充桩工作模式 -->
    <EnergyTips v-if="tipsDialog" :modeType="1" @close="v => tipsDialog = v" />
    <!-- eco经济模式弹框 -->
    <ecoTimeDialog v-if="ecoDialogShow" @close="ecoDialogShow = false" @confirm="ecoConfirm" />

    <customTimeSchedule v-if="customTimeScheduleShow" :type='operationType' :id="planId" @close="customTimeScheduleShow = false" @confirm="customTimeScheduleConfirm()" />
  </div>
</template>
<script>
import { requestUrl, ajax } from "@/assets/utils/network";
import EnergyTips from "@/components/energyTipsUs";
import customTimeSchedule from "@/components/customTimeSchedule";

import ecoTimeDialog from "@/components/ecoTimeDialog.vue";
export default {
  props: ["modeData", "feeDetail"],
  components: { EnergyTips, ecoTimeDialog, customTimeSchedule },
  data() {
    return {
      operationType: 1,
      planId: "",
      planList: [], // 自定义工作模式时间计划
      customTimeScheduleShow: false,
      tipsDialog: false, // 储充桩工作模式
      ecoDialogShow: false, // eco经济模式弹框
      energyStorageFeePolicyId: "",
      isEdit: false,
      modeWorking: "Max",
      modeAirConditioning: "Max",
      cache: {
        modeWorking: "",
        modeAirConditioning: "",
      },
      modeValueText: {
        Max: this.$parent.$t("common_20017"),
        Auto: this.$parent.$t("common_20019"),
        Eco: this.$parent.$t("common_20018"),
        Custom: this.$parent.$t("common_70000"),
      },
      modeAirConditioningText: {
        Max: this.$parent.$t("common_20039"),
        Eco: this.$parent.$t("common_20038"),
      },
    };
  },
  watch: {
    modeData: {
      handler(val) {
        let deviceSetting = val || {};
        this.energyStorageFeePolicyId = deviceSetting.esFeepolicyId || ""; // 储能资费id
        this.modeWorking = deviceSetting.modeWorking || "Max";
        this.modeAirConditioning = deviceSetting.modeAirConditioning || "Max";
        this.planId = deviceSetting.esCustomModePlanId || "";
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    planTitle() {
      let res = this.planList.filter((it) => it.id == this.planId);
      return res.length ? res[0].title : "";
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    toDetail(type){
      var id = this.planId
      this.operationType = type;
      window.open(`./workScheduleDetail.html?type=${type}${id && type == 3 ? `&id=${id}` : ''}`, "_blank");
    },
    // 自定义工作模式时间计划
    getList() {
      ajax({
        type: "GET",
        url: `${requestUrl}/energystorage/custommodeplan`,
        dataType: "json",
        success: (response) => {
          this.planList = JSON.parse(response).result || [];
        },
      });
    },
    addCustomSchedule(type) {
      this.operationType = type;
      this.customTimeScheduleShow = true;
    },
    customTimeScheduleConfirm(v) {
      console.log("v", v);
      this.customTimeScheduleShow = false;
      this.operationType == 1 && this.getList();
    },
    changeEdit() {
      this.cache.modeWorking = this.modeWorking;
      this.cache.modeAirConditioning = this.modeAirConditioning;
      this.isEdit = true;
    },
    cancelEdit() {
      this.modeWorking = this.cache.modeWorking;
      this.modeAirConditioning = this.cache.modeAirConditioning;
      this.isEdit = false;
    },
    saveEdit() {
      if (!this.energyStorageFeePolicyId) {
        this.$message.error(this.$t("common_61049"));
        return;
      }
      let phase = this.feeDetail.phase;
      let phaseArr = [];
      phase.forEach((item, index) => {
        if (!phaseArr.includes(item["phase"])) {
          phaseArr.push(item["phase"]);
        }
      });
      if (phaseArr.length == 1 && ["Eco", "Auto"].includes(this.modeWorking)) {
        this.$message.error(this.$t("common_61050"));
        return;
      }
      if (phaseArr.length == 2 && ["Auto"].includes(this.modeWorking)) {
        this.$message.error(this.$t("common_61051"));
        return;
      }
      if ([3, 4].includes(phaseArr.length) && this.modeWorking == "Eco") {
        this.ecoDialogShow = true;
        return;
      }
      const info = {
        modeWorking: this.modeWorking,
        modeAirConditioning: this.modeAirConditioning,
        esCustomModePlanId: this.planId,
      };
      this.$emit("saveEdit", info);
    },
    ecoConfirm(v) {
      if (!v) {
        this.$message.error(this.$parent.$t("common_20036"));
        return;
      }
      let info = {
        modeWorking: this.modeWorking,
        esEcoModeTowardPhase: v,
        modeAirConditioning: this.modeAirConditioning,
      };
      this.ecoDialogShow = false;
      this.$emit("saveEdit", info);
    },
  },
};
</script>
<style lang="scss" scoped>
.mode_wrap {
  line-height: 1;
  .row {
    padding: 13px 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #464b5c;
    line-height: 20px;
    &:first-of-type {
      border-bottom: 1px dashed #e6e7ec;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #41526d;
      line-height: 22px;
    }
    .more {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #5278e2;
      margin-left: 20px;
      cursor: pointer;
    }
    .value {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1b2330;
      .plan_title {
        margin-left: 20px;
        cursor: pointer;
        color: #5278e2;
      }
    }
    .content_box {
      display: flex;
      align-items: center;
      .custom-content {
        margin-left: 10px;
        .w200 {
          width: 250px;
        }
        .m20 {
          margin: 0 10px;
        }
      }
    }
  }
}
.el-icon-info {
  cursor: pointer;
  &::before {
    padding: 0 10px;
  }
  font-size: 14px;
  font-weight: 400;
  color: #41526d;
}
</style>