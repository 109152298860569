export default {
    chargeMonitor_00001: '设置监控场站',
    chargeMonitor_00002: '场站名称',
    chargeMonitor_00003: '运营商',
    chargeMonitor_00004: '充电实时监控',
    chargeMonitor_00005: '查看订单',
    chargeMonitor_00006: '订单信息',
    chargeMonitor_00007: '充电信息',
    chargeMonitor_00008: '当前订单',
    chargeMonitor_00009: '充电电量',
    chargeMonitor_00010: "网关",
    chargeMonitor_00011: "空闲",
    chargeMonitor_00012: "使用中",
    chargeMonitor_00013: "异常",
    chargeMonitor_00014: "未启用",
    chargeMonitor_00015: "已启用",
    chargeMonitor_00016: "在线",
    chargeMonitor_00017: "离线",
    chargeMonitor_00018: "故障",
    chargeMonitor_00019: "预约中",
    chargeMonitor_00020: "已插枪",
    chargeMonitor_00021: "充电中",
    chargeMonitor_00022: "待挪车",
    chargeMonitor_00023: "待拔枪",
    chargeMonitor_00024: "占用中",
    chargeMonitor_00025: "请输入运营商名称",
    chargeMonitor_00026: "已归位",
    chargeMonitor_00027: "未归位",
    chargeMonitor_00028: "查看当前所有故障设备",
    chargeMonitor_00029: "查看当前所有离线设备",
    chargeMonitor_00030: "添加新窗口标签",
    chargeMonitor_00031: "保存并打开",
    chargeMonitor_00032: "是否按上次的浏览器标签页设置打开所有监控场站？",
    chargeMonitor_00033: "请选择当前窗口要监控的场站",
    chargeMonitor_00034: "设置帮助",
    chargeMonitor_00035: "请先设置在每一个标签页中，您要监控的场站，点击“打开”按钮，将按照您的设置，打开对应的浏览器标签页展示监控场站",
    
    chargeMonitor_00036: "如果您的浏览器拦截了打开的新窗口,请检查您的浏览器设置，调整为允许弹出新窗口",  
    chargeMonitor_00037: '全选',
    chargeMonitor_00038: '当前页面已选场站',
    chargeMonitor_00039: "急停按钮被按下，暂停充电",
    chargeMonitor_00040: "设备故障，暂停充电",
    chargeMonitor_00041: "已充满，或已达到设定的结束条件",
    chargeMonitor_00042: "设备故障，结束充电",
    chargeMonitor_00043: "人为远程结束充电",
    chargeMonitor_00044: "在充电桩上操作结束充电",
    chargeMonitor_00045: "充电中",
    chargeMonitor_00046: "已插枪",
    chargeMonitor_00047: "待拔枪",
    chargeMonitor_00048: "占用中",
    chargeMonitor_00049: "异常结束",
    chargeMonitor_00050: "充电完成",
    chargeMonitor_00051: "等待功率分配中",
    chargeMonitor_00052: "异常日志",
    chargeMonitor_00053: "预计",
    chargeMonitor_00054: "充满",
    chargeMonitor_00055: "已充",
    chargeMonitor_00056: "请切换当前tab窗口删除标签",
    chargeMonitor_00057: "不可以删除第一项",


    eqDetails_30015: "确认并恢复急停",
    eqDetails_30016: "设备使用中，自检取消，请空闲后操作",
    eqDetails_30017: "当前设备离线",
    eqDetails_30018: "设备自检超时，请重试",
    eqDetails_30020: "差",
    eqDetails_30021: "中",
    eqDetails_30022: "良",
    eqDetails_30023: "此检查要求充电桩的Android应用的固件版本号在4.0.0及以上",
    eqDetails_30024: "未查询到上次自检结果",
    eqDetails_30025: "立即恢复",
    eqDetails_30026: "先检查设备，再考虑恢复",
    eqDetails_30027: "免责申明",
    eqDetails_30028: "为了确保充电桩的安全运行，我们建议您在恢复急停按钮前进行硬件检查，如发现任何硬件故障或安全隐患，请停止使用充电桩，并联系我们的技术支持团队。我们将尽快提供解决方案，以确保您的充电桩能够安全、稳定地运行。",
    eqDetails_30029: "请注意，此免责申明旨在提醒您，在不确定充电桩状态地前提下，选择直接恢复急停按钮，会存在一定风险，我们将不承担任何由此产生的责任。",
    eqDetails_30030: "确定直接恢复",
    eqDetails_30031: "自检项",
    eqDetails_30032: "状态",
    eqDetails_30033: "因{msg},{code}自检取消，请检修后操作",
}