export default {
    financingSettings_00001: 'When the top-up function is enabled, users can top-up in WeChat Public, and the recharge will be automatically deducted from the balance.' ,
    financingSettings_00002: 'Whether to enable top-up function',
    financingSettings_00003: 'Top-up panel keyboard settings',
    financingSettings_00004: 'Edit',
    financingSettings_00005: 'Save',
    financingSettings_00006: 'Cancel',
    financingSettings_00007: 'Delete',
    financingSettings_00008: 'Add',
    financingSettings_00009: 'With refund request enabled, users can request a refund of their wallet balance in WeChat Public',
    financingSettings_00010: 'Whether to enable refunds',
    financingSettings_00011: 'Refund method',
    financingSettings_00012: 'Fully automatic refund',
    financingSettings_00013: 'Semi-automatic refunds',
    financingSettings_00014: 'Manual refund',
    financingSettings_00015: 'Modify',
    financingSettings_00016: 'Refund funding source',
    financingSettings_00017: 'Refund of unsettled funds',
    financingSettings_00018: 'Refund of available balance',
    financingSettings_00019: 'Refund reminder message',
    financingSettings_00020: '(Will be displayed on the balance refund page of the WeChat public website)',
    financingSettings_00021: 'The invoice application portal will be displayed in WeChat Public after opening',
    financingSettings_00022: 'Whether to enable the invoice application portal',
    financingSettings_00023: 'Minimum invoicing amount not entered or entered incorrectly',
    financingSettings_00024: 'Minimum invoicing amount',
    financingSettings_00025: 'Supported invoicing methods',
    financingSettings_00026: 'Paper invoices',
    financingSettings_00027: 'E-invoices',
    financingSettings_00028: 'Paper invoices and e-invoices',
    financingSettings_00029: 'Invoice reminder message',
    financingSettings_00030: '(will be displayed on the invoice request page of the WeChat public website)',
    financingSettings_00031: 'No more than 4 rows in the recharge panel!' ,
    financingSettings_00032: 'There are empty values in your panel, please enter the amount!',
    financingSettings_00033: 'The amount in the panel must be a non-zero positive integer, please re-enter it!',
    financingSettings_00034: 'The maximum length of the amount in the panel is 5 digits, please re-enter!',
    financingSettings_00035: 'There are duplicate values in your panel, please re-enter!' ,
    financingSettings_00036: 'Modification successful',
    reserveManagement_00005: 'Please enter the data flow name',
    reserveManagement_00006: 'Is reserve enabled',
    reserveManagement_00007: 'All',
    reserveManagement_00008: 'Enabled',
    reserveManagement_00009: 'Not enabled',
    reserveManagement_00010: 'Query',
    reserveManagement_00011: 'Reset',
    reserveManagement_00012: 'Accessed third party traffic name',
    reserveManagement_00013: 'Opened Sites',
    reserveManagement_00014: 'No open sites',
    reserveManagement_00015: 'Access Method',
    reserveManagement_00016: 'Whether to enable reserves',
    reserveManagement_00017: 'Current balance',
    reserveManagement_00018: 'Reserve amount',
    reserveManagement_00019: 'Operation',
    reserveManagement_00020: 'Reserve Management',
    reserveManagement_00021: 'Yes',
    reserveManagement_00022: 'No',
    reserveManagement_00023: 'Third party data flow platform',
    reserveManagement_00024: 'Operator',
    reserveManagement_00025: 'Whether to reserve funds',
    reserveManagement_00026: 'Can negative charge-offs',
    reserveManagement_00027: 'Remind balance',
    reserveManagement_00028: 'Modify',
    reserveManagement_00029: 'Top up reserve amount',
    reserveManagement_00030: 'Save',
    reserveManagement_00031: 'Cancel',
    reserveManagement_00032: 'Automatic stopping of charging if the balance is less than 0',
    reserveManagement_00033: 'Reserve recharge history',
    reserveManagement_00034: 'Recharge amount (€)',
    reserveManagement_00035: 'Pre-charge time',
    reserveManagement_00036: 'Return',
    reserveManagement_00037: 'Government',
    reserveManagement_00038: 'Direct Signing',
    reserveManagement_00039: 'Input error',
    selfInvoicingSetting_00004: 'Group name',
    selfInvoicingSetting_00005: 'Enquiry',
    selfInvoicingSetting_00006: 'Reset',
    selfInvoicingSetting_00007: 'Payment Method',
    selfInvoicingSetting_00008: 'Total number of members',
    selfInvoicingSetting_00009: 'Whether to support online invoice request from the self-service administration',
    selfInvoicingSetting_00010: 'Operation',
    selfInvoicingSetting_00011: 'Invoicing settings',
    selfInvoicingSetting_00012: 'Minimum invoicing amount',
    selfInvoicingSetting_00013: 'Please enter a number, up to two decimal places',
    selfInvoicingSetting_00014: 'Supported invoicing methods',
    selfInvoicingSetting_00015: 'Paper invoice',
    selfInvoicingSetting_00016: 'E-invoices',
    selfInvoicingSetting_00017: 'Paper invoices and electronic invoices',
    selfInvoicingSetting_00018: 'Invoice reminder message',
    selfInvoicingSetting_00019: 'Invoice reminder messages are displayed on the self-service invoice request page',
    selfInvoicingSetting_00020: 'Please enter invoice reminder information',
    selfInvoicingSetting_00021: 'Individual payment',
    selfInvoicingSetting_00022: 'Group payment',
    selfInvoicingSetting_00023: "Yes",
    selfInvoicingSetting_00024: "No",
    selfInvoicingSetting_00025: "€",
    selfInvoicingSetting_00026: "OK",
    selfInvoicingSetting_00027: "Cancel",
    selfRechargeSetting_00004: 'Group Name',
    selfRechargeSetting_00005: 'Query',
    selfRechargeSetting_00006: 'Reset',
    selfRechargeSetting_00007: 'Payment Method',
    selfRechargeSetting_00008: 'Total number of members',
    selfRechargeSetting_00009: 'Whether to allocate amount to group members',
    selfRechargeSetting_00010: 'Whether to support group self-administration recharge',
    selfRechargeSetting_00011: 'Individual payment',
    selfRechargeSetting_00012: 'Group payment',
    selfRechargeSetting_00013: "Not supported",
    selfRechargeSetting_00014: "Please enter a number",
    selfRechargeSetting_00015: "if you select automatic refund, you can automatically refund without manual intervention, and the acceptance cannot be cancelled after confirmation",
    selfRechargeSetting_00016: "if you select semi-automatic refund, you need to click [confirm acceptance] on the refund page, and the acceptance cannot be cancelled after confirmation",
    selfRechargeSetting_00017: "choose manual refund. Alipay will be processed by semi automatic refund."
}