export default {
    login_00001: "Login operator platform",
    login_00002: "Enter the username",
    login_00003: "Enter the password",
    login_00004: "Log in",
    login_00005: "User name and password cannot be blank",
    login_00006: "· Operation",
    login_00007: "I have already registered, now",
    login_00008: "Login",
    login_00009: "Make shared charging easier",
    login_00010: "According to the business layout of different operators, XCHARGE has an in-depth insight into customer needs and created its own digital charging operation full-function management system:XCHARGE SaaS platform V3.0",
    login_00011: "",
    login_00012: "Welcome to operator platform",
    login_00013: "name",
    login_00014: "Please enter a login name",
    login_00015: "password",
    login_00016: "Please enter your password",
    login_00017: "Not registered yet? ",
    login_00018: "registered",
    login_00019: "Join the Operation",
    login_00020: "Privacy policy",
    login_00021: "Is ready to",
    login_00022: "Prepare information for registration",
    login_00023: "Tips for joining the Charging Operation Alliance",
    login_00024: "Self-run wechat official account (service fee required)",
    login_00025: "Open wechat Pay",
    login_00026: "Users and devices of the upper-layer carrier can be shared.",
    login_00027: "Have their own operator platform.",
    login_00028: "* The invitation code provided by the superior carrier must be available",
    login_00029: 'The owner can start charging by scanning the wechat code',
    login_00030: "The owner's wechat pay money is directly transferred to the wechat Pay merchant platform account",
    login_00033: "Forgot your password?",
}