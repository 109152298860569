export default {
  onlineAnalysis_00001: "online rate of chargers by station",
  onlineAnalysis_00002: "Report time",
  onlineAnalysis_00003: " - ",
  onlineAnalysis_00004: "Analysis",
  onlineAnalysis_00005: "Station Equipment",
  onlineAnalysis_00006: "Online rate = (1 - offline duration in this query time period / all query time period)",
  onlineAnalysis_00007: "Equipment Name",
  onlineAnalysis_00008: "Equipment Model",
  onlineAnalysis_00009: "Equipment Serial Number",
  onlineAnalysis_000010: "Online Rate",
  onlineAnalysis_000011: "Operation",
  onlineAnalysis_000012: "Equipment Details",
  onlineAnalysis_000013: "Equipment error log",
}