export default {
    systemInfoSetting_00001: "基本信息",
    systemInfoSetting_00002: "联系人信息",
    systemInfoSetting_00003: "对公账户信息",
    systemInfoSetting_00004: "客户端车牌号输入页面提示文案",
    systemInfoSetting_00005: "运营商名称",
    systemInfoSetting_00006: "运营商ID",
    systemInfoSetting_00007: "支持货币",
    systemInfoSetting_00008: "人民币(元)",
    systemInfoSetting_00009: "联系人",
    systemInfoSetting_000010: "手机号",
    systemInfoSetting_000011: "邮箱",
    systemInfoSetting_000012: "获取验证码",
    systemInfoSetting_000013: "请输入验证码",
    systemInfoSetting_000014: "账号",
    systemInfoSetting_000015: "超级管理员",
    systemInfoSetting_000016: "账户名称",
    systemInfoSetting_000017: "银行账户",
    systemInfoSetting_000018: "开户行",
    systemInfoSetting_000019: "请输入内容",
    systemInfoSetting_000020: "信息不能为空",
    systemInfoSetting_000021: "修改成功",
    systemInfoSetting_000022: "未启用",
    systemInfoSetting_000023: "名称不能超过{0}位",
    systemInfoSetting_000024: "输入的字数不超过20个字",
    systemInfoSetting_000025: "请输入正确的邮箱",
    systemInfoSetting_000026: "下载查看《用户服务协议》",
    systemInfoSetting_000027: "注册账号{loginId}于{time}已阅读并同意《用户服务协议》。",
    systemInfoSetting_000028: "纳税人识别号",
    systemInfoSetting_000029: "是否可以开增值说专用发票",
    systemInfoSetting_000030: "",

    //---------国际版-------------------------------
    systemInfoSetting_000600: "添加",
    systemInfoSetting_000601: "请选择",
    systemInfoSetting_000602: "请选择货币类型",
    systemInfoSetting_000603: "公司名称",
}