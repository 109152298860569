
export default {
    charge_00001: "{num}",
    charge_00002: "You can create new offline cards in this group|The offline cards in this group need to be configured to the charger in order to be used",
    charge_00003: "CPO charging card management",
    charge_00004: "Group name",
    charge_00005: "Charging Card Groups",
    charge_00006: "New Card Group",
    charge_00007: "Card type",
    charge_00008: "ID Card",
    charge_00009: "Offline card group",
    charge_00010: "Super card group",
    charge_00011: "Cards",
    charge_00012: "Transactions",
    charge_00013: "Operation",
    charge_00014: 'This can be done in the "Details" section of the "Charging Card Management" charging card group as follows:',
    charge_00015: "Group name",
    charge_00016: "(Example: Internal staff at State House)",
    charge_00017: "Give a name to the charging card group",
    charge_00018: "Online card group",
    charge_00019: "You can create new online cards in this group",
    charge_00020: "Offline card group",
    charge_00021: "1、Change the name of the charging card group；",
    charge_00022: "New charging card group created successfully",
    charge_00023: "2、Creating NFC configuration card；",
    charge_00024: "3、Creating NFC charging card；",
    charge_00025: "Finish",
    charge_00026: "Please enter the name of the charging card group",
  }