<template>
  <div>
    <div class="backlog">
      <div class="titleCon">
        <div class="title" style="width: 0;">{{$t('homePage_00007')}}</div>
        <div class="set" @click="openEdit" v-if="editFlag">
          <i class="el-icon-setting"></i>
        </div>
      </div>
      <div v-for="item in todoList" :key="item.id">
        <a :href="item.url" target="_blank" :permissionInfo="item.permissions" v-setPermission>
          <div class="backlog-piece">
            <div class="con" style="width: 0;">{{item.label}}</div>
            <div class="num" :class="parseInt(item.value)==0?'bac-gray':'bac-blue'">{{item.value>99?'99+':item.value}}</div>
          </div>
        </a>
      </div>
    </div>
    <event-dialog :title="'自定义待办事项'" :dialogVisible="dialogVisible" :type="typeVlaue" :checkList="checkList" @cancle="colseEdit">
      <span slot="listTitle" class="slot_title">当前已选功能（最多选择4个）</span>
      <span slot="listTotal" class="slot_title">待办事项</span>
    </event-dialog>
  </div>
</template>

<script>
import { requestUrl, ajax, checkLogin } from "@/assets/utils/network";
import eventDialog from "../eventDialog.vue";
import funMapping from "../funMapping.js";
import { checkTabs } from "@/assets/utils/permission";
import PubSub from "pubsub-js";
export default {
  props: {
    editFlag: {
      type: Boolean,
      default: true,
    },
  },
  name: "Backlog",
  components: {
    eventDialog,
  },
  data() {
    return {
      refund: "",
      processingRefund: "",
      individualInvoice: "",
      groupsInvoice: "",
      unpaidCount: "",
      dialogVisible: false,
      typeVlaue: "", // 编辑标识
      todoList: [],
      checkList: [],
      urlList: {
        1: "/device/status/stats", // 当前故障
        2: "/wechatOAMsg/unread/count", // 微信客服
        3: "/wechat/refund/stats", // 钱包退款受理
        4: "/dealer/bill/stats", // 平台账单
        5: "/wechat/invoice/stats?userType=wechat", // 个人发票申请
        6: "/wechat/invoice/stats?userType=userGroup", // 团体发票申请
        7: "/device/status/stats", // 电源模块保养
        8: "/device/life/stats?needMaintain=true", // 设备寿命保养
      },
    };
  },
  created() {
    PubSub.subscribe("customEdit", (name, content) => {
      this.getList(content);
    });
  },
  methods: {
    getList(content) {
      let temp = content.todoItem || [];
      !temp.length && (temp = [1, 2, 3, 4]);
      this.todoList = [];
      temp.forEach((item) => {
        this.todoList.push(funMapping.find((it) => it.id == item));
      });
      this.todoList.forEach((it) => {
        this.formmatCount(it.id);
      });
      this.checkList = this.todoList
        .filter((it) => checkTabs(it.permissions))
        .map((i) => i.id);
    },
    openEdit() {
      this.typeVlaue = "event";
      this.dialogVisible = true;
    },
    colseEdit(v) {
      this.dialogVisible = v;
      this.typeVlaue = "";
    },
    formmatCount(id) {
      this.sendAjax(this.urlList[id]).then((res) => {
        let item = this.todoList.find((it) => it.id == id);
        if (res.error) {
          this.$set(item, "value", 0);
          return;
        }
        let value = 0;
        switch (id) {
          case 1:
            value =
              (res.result.errorDevices || 0) + (res.result.offlineDevices || 0);
            break;
          case 2:
            value = res.totalCount || 0;
            break;
          case 3:
            value = res.result.totalNum || 0;
            break;
          case 4:
            value = res.unpaidCount || 0;
            break;
          case 5:
          case 6:
            value = res.result.processingNum || 0;
            break;
          case 7:
            value = res.result.powerModules || 0;
            break;
          case 8:
            let tableData = this.formatList(res.result || []);
            value = tableData.length || 0;
            break;
        }
        this.$set(item, "value", value);
      });
    },
    sendAjax(url) {
      return new Promise((resolve, reject) => {
        ajax({
          type: "GET",
          url: `${requestUrl}${url}`,
          dataType: "json",
          success: (response) => {
            resolve(JSON.parse(response));
          },
        });
      });
    },
    // 设备寿命保养数
    formatList(arr) {
      let sites = [];
      arr.forEach((item) => {
        if (
          !sites.some(
            (it) => it.id === item.id && item.deviceId === it.deviceId
          )
        ) {
          let obj = {
            deviceId: item.deviceId,
            deviceName: item.deviceName,
            siteId: item.siteId,
            siteName: item.siteName,
            deviceType: item.deviceType,
            children: [],
          };
          sites.push(obj);
        }
      });
      sites.forEach((item) => {
        let temp = [];
        arr.forEach((it) => {
          if (it.id === item.id && item.deviceId === it.deviceId) {
            it.isEdit = false;
            it.cache = "";
            temp.push(it);
          }
        });
        item.children = temp || [];
      });
      return sites;
    },
  },
};
</script>

<style lang="scss" scoped>
.backlog {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  .titleCon {
    display: flex;
    .set {
      margin: 20px 16px 0 0;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      background: #f2f2f2;
      color: #8087a0;
      border-radius: 15px;
      cursor: pointer;
      &:hover {
        color: #5278e2;
      }
    }
    .handle {
      margin: 13px 16px 0 0;
    }
    .isSelect {
      color: #5278e2;
      border-color: #5278e2;
      background-color: #fff;
      font-weight: 600;
    }
    .noSelect {
      background: #eff2f6;
      border: 1px solid #cdd0da;
      color: #8087a0;
    }
  }
  .title {
    flex: 1;
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #8087a0;
    line-height: 22px;
    text-align: left;
    text-indent: 16px;
    margin-top: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .bac-blue {
    color: #fff;
    background: #5278e2;
  }
  .bac-gray {
    color: #fff;
    background: #d2d5dd;
  }
  .backlog-piece {
    cursor: pointer;
    display: flex;
    margin: 10px 16px 0;
    height: 32px;
    line-height: 32px;
    background: #eff2f6;
    border-radius: 4px;
    font-size: 14px;
    &:hover {
      background: #e7f0ff;
    }
    .con {
      flex: 1;
      font-weight: 400;
      color: #1c2237;
      text-align: left;
      text-indent: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        color: #5278e2;
      }
    }
    .num {
      margin: 7px 10px 0 0;
      width: 16px;
      font-size: 12px;
      border-radius: 10px;
      display: inline-block;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      padding: 0 6px;
      text-align: center;
      white-space: nowrap;
    }
  }
}
</style>