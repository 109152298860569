export default {
    occupancyInfo_00001: 'Charging space occupation bill details',
    occupancyInfo_00002: 'Billing information',
    occupancyInfo_00003: 'Charging standard',
    occupancyInfo_00004: 'Bill details',
    occupancyInfo_00005: 'Bill remarks',
    occupancyInfo_00006: 'Bill No',
    occupancyInfo_00007: 'Initial creation',
    occupancyInfo_00008: 'Cancelled',
    occupancyInfo_00009: 'Paid',
    occupancyInfo_00010: 'To be paid',
    occupancyInfo_00011: 'Charging',
    occupancyInfo_00012: 'Current charge duration',
    occupancyInfo_00013: 'day',
    occupancyInfo_00014: 'hour',
    occupancyInfo_00015: 'minute',
    occupancyInfo_00016: 'Bill amount',
    occupancyInfo_00017: 'Discount',
    occupancyInfo_00018: 'Actual payment amount',
    occupancyInfo_00019: 'Occupancy start time',
    occupancyInfo_00020: 'Occupancy end time',
    occupancyInfo_00021: 'Occupancy duration',
    occupancyInfo_00022: 'Free occupancy time',
    occupancyInfo_00023: 'Charging time',
    occupancyInfo_00024: 'User information',
    occupancyInfo_00025: 'Payment method',
    occupancyInfo_00026: '{pay}',
    occupancyInfo_00027: 'Wechat payment',
    occupancyInfo_00028: 'Wechat balance payment',
    occupancyInfo_00029: 'Group fund account balance payment',
    occupancyInfo_00030: 'Third party app payment',
    occupancyInfo_00031: 'User fund account',
    occupancyInfo_00032: 'Online ID card payment',
    occupancyInfo_00033: 'WeChat official account (Alipay)',
    occupancyInfo_00034: 'WeChat official account (WeChat payment is confidential payment)',
    occupancyInfo_00035: 'Discount method',
    occupancyInfo_00036: 'Modified amount',
    occupancyInfo_00037: 'Deduction of gift amount during payment{money} yuan',
    occupancyInfo_00038: 'Bill remarks',
    occupancyInfo_00039: 'Charging standard',
    occupancyInfo_00040: 'Charging standard of idle fee： {time}Yuan/minute',
    occupancyInfo_00041: 'Free occupancy time：{time}',
    occupancyInfo_00042: 'Charging duration details',
    occupancyInfo_00043: 'Charging order No',
    occupancyInfo_00044: 'Charging start time',
    occupancyInfo_00045: 'Charging end time',
    occupancyInfo_00046: 'Charging time(minute)',
    occupancyInfo_00047: 'View charging order',
    occupancyInfo_00048: 'Modified amount',
    occupancyInfo_00049: 'Current bill amount',
    occupancyInfo_00050: 'Account document amount after modification',
    occupancyInfo_00051: 'Please enter payment amount',
    occupancyInfo_00052: 'The modified amount format is inconsistent',
    occupancyInfo_00053: 'Are you sure to delete this Bill Note?',
    occupancyInfo_00054: 'Trips',
    occupancyInfo_00055: 'confirm deletion',
    occupancyInfo_00056: 'Bill remarks',
    occupancyInfo_00057: 'Please fill in the bill remarks',
    occupancyInfo_00058: '',
    occupancyInfo_00059: '',
    occupancyInfo_00060: '',
  }