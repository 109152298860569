var triggerList = [
  {
    value: 'tx',
    label: '充电桩内部通信',
    children: [
      {
      value: '4',
      label: '充电桩离线',
      code:"offline",
      disabled: true,
      }, 
      {
        value: '5',
        label: '急停按钮被按下[A0403]',
        code:"A0403",
        disabled: true,
      },
      {
        value: '6',
        label: '电源模块报警[A0301]',
        code: "A0301",
        disabled: true,
      }, 
      {
        value: '7', 
        label: '电磁锁上锁/解锁操作失败[A0502/A0503] ',
        code: "A0502/A0503",
        disabled: true,
      }, 
      {
        value: '8',
        label: '充电桩主机与DCB通讯失败[A0101]',
        code:"A0101"
      }, 
      {
        value: '9',
        label: '充电桩主机与分机DCB通讯失败[A0102]',
        code:"A0102",
      }, 
    {
      value: '10',
      label: '直流电表通讯485检测失败[A0103]',
      code:"A0103"
    },
      {
        value: '11',
        label: '电源模块CAN通信失败[A0104]',
        code:"A0104",
      },
      {
        value: '12',
        label: '充电口绝缘通信模块报警[A0105]',
        code: "A0105",
      },
      {
        value: '13',
        label: '自检超时[A0106]',
        code:"A0106"
      },
  ]
  }, 
  {
    value: 'dy',
    label: '电源模块',
    children: [{
      value: '14',
      label: 'C6桩电源模块地址冲突/C2充电桩未初始化[A0302]',
      code: "A0302",
      disabled: true,
    }]
  },
  {
    value: 'cgq',
    label: '传感器',
    children: [
      {
        value: '15',
        label: '充电主机门禁故障[A0401]',
        code:"A0401",
      },
      {
        value: '16',
        label: '液位报警[A0402]',
        code:"A0402",
      },
      {
        value: '17',
        label: '交流/直流防雷报警[A0404/A0405]',
        code: "A0404/A0405"
      },
      {
        value: '18',
        label: '充电口接触器故障[A0406]',
        code:"A0406"
      },
      {
        value: '19',
        label: '充电主机烟感故障[A0407]',
        code: "A0407"
      },
      {
        value: '20',
        label: '机箱温度报警[A0408]',
        code:"A0408"
      },
      
      {
        value: '21',
        label: '液冷枪头/枪尾温度故障[A040B/A040D/A040F/A0411/A0414/A0416]',
        code:"A040B/A040D/A040F/A0411/A0414/A0416",
      },
      {
        value: '22',
        label: '液冷故障[A0417]',
        code: "A0417"
      },
      {
        value: '23',
        label: '主机故障[A0418]',
        code:"A0418"
      },
      {
        value: '24',
        label: '倾斜角报警/故障[A040A/A0419]',
        code: "A040A/A0419",
      },
    ]
  },
  {
    value: 'jl',
    label: '交流输入',
    children: [
      {
        value: '25',
        label: 'A/B/C相电压过高/过低[A0701/A0702/A0703/A0704/A0705/A0706]',
        code: "A0701/A0702/A0703/A0704/A0705/A0706"
      },
      {
        value: '26',
        label: '地线报警[A0707]',
        code: "A0707"
      },
      {
        value: '27',
        label: 'N零线未接[A0708]',
        code: 'A0708'
      },
      {
        value: '28',
        label: '交流输入断路器故障[A0709]',
        code: 'A0709'
      },
      {
        value: '29',
        label: '交流输入接触器拒动/粘连故障[A070A/A070B]',
        code:'A070A/A070B'
      },
      {
        value: '30',
        label: '系统掉电故障，无输入[A070C]',
        code:"A070C"
      },
      {
        value: '31',
        label: '交流输入缺相[A070E]',
        code:"A070E",
      },
    ]
  },
  {
    value: 'zl',
    label: '直流输出',
    children: [
    {
      value: '32',
      label: '直流母线输出熔断器故障[A080A]',
      code: "A080A"
    },
      {
        value: '33',
        label: '电压异常，过压/欠压/短路/过载[A0801/A0802/A0803/A0804]',
        code:"A0801/A0802/A0803/A0804"
      },
      {
        value: '34',
        label: '直流检测失败[A0806]',
        code:"A0806"
      },
      {
        value: '35',
        label: '泄放检测失败[A0807]',
        code:"A0807"
      },
      {
        value: '36',
        label: '并联接触器拒动/粘连故障[A0808/A0809]',
        code: "A0808/A0809"
      },
      {
        value: '37',
        label: '充电主机预充电失败故障[A080B]',
        code:"A080B"
      },
      {
        value: '38',
        label: '直流电表读数异常[A080F]',
        code:"A080F"
      },
      {
        value: '39',
        label: '枪头直流输出接触器拒动/粘连故障[A0810/A0811]',
        code:"A0810/A0811"
      },
  ]
  },
  {
    value: 'cws',
    label: '车位锁',
    children: [{
      value: '40',
      label: '车位锁落锁失败[C0103]',
      code:"C0103"

    }]
  },
  {
    value: 'fzdy',
    label: '辅助电源',
    children: [{
      value: '41',
      label: '辅助电源没有上电[A0901]',
      code:"A0901"
    }]
  },
  {
    value: 'txbw',
    label: '通信报文',
    children: [{
      value: '42',
      label: 'CMS通信报文超时停止[A0A01/A0A02/A0A03/A0A04/A0A05/A0A06/A0A07/A0A08/A0A09/A0A0A]',
      code: 'A0A01/A0A02/A0A03/A0A04/A0A05/A0A06/A0A07/A0A08/A0A09/A0A0A'
    }]
  },
  {
    value: 'fzdy',
    label: '充电状态异常',
    children: [{
      value: '43',
      label: '充电桩输出电流大于6A，直流电表读数无变化，持续180s[A0B01]',
      code: 'A0B01'
    }]
  },
  {
    value: 'jycw',
    label: '绝缘错误',
    children: [{
      value: '44',
      label: '绝缘故障[A0202]',
      code:'A0202'
    }]
  },
  {
    value: 'cdq',
    label: '充电枪',
    children: [{
      value: '45',
      label: '充电枪口温度报警[A0501]',
      code:'A0501'
    },
    {
      value:'46',
      label:'充电枪口发生异常（电压改变）[A0504]',
      code:'A0504'
      }
  ]
  },
];

module.exports = triggerList;
