export default {
    begin_date_select_remind: "Start date",
    end_date_select_remind: "Close date",
    to: "to",
    all_site:"All charging site",
    self_run_site:"Self-run_ charging site",
    custom_site:"Custom site",
    add_site:"Add site",
    site_name: "Charging site name",
    operator: "Operator",
    charging_package50:"Please enter a credit name of less than 16 characters",
    charging_package53:"Please complete the charging package information",
    charging_package68:"Input format error",
    charging_package69:"Credit input format error",
    coupon_error_message_3:"Please enter correct expiry date",
    coupon_error_message_4:"The start date should be less than or equal to the end date",
    coupon_error_message_5:"The start date should be greater than or equal to the current date",
    common_00241: "Added successfully",
    charging_package54:"Please select the site where you want to add the charging package",
    enter_site:"Enter charging site name, operator name",
    query: "Search",
    confirm: "Confirm",
    siteStatus: "Charging site status",
    isOpen: "Open to public",
    enable: "Enable",
    enabled: "Enabled",
    disabled: "Not enabled",
    open: "Open",
    unopen: "Not open",
    device_type_dc: "DC",
    device_type_ac: "AC",
    chargeEquipment: "Charger",
    member_00072: 'Cancel',
    cancel: 'Cancel',
    member_00073: 'Add',
    ac:"AC",
    dc:"DC",
    delete_success: "Deletion success",
    charging_package44:"Are you sure to delete this message?",
    remind: "Reminder",
    operate_canceled: "Operation cancelled",


    cash_coupon0001: 'Marketing center',
    cash_coupon0002: 'Offer tool',
    cash_coupon0003: 'Create',
    cash_coupon0004: 'Detail',
    cash_coupon0005: 'Modify',
    cash_coupon0006: 'Information',

    cash_coupon0007: 'Cancel',
    cash_coupon0008: 'Save',
    cash_coupon0009: 'Delete',

    cash_coupon0010: 'Cash credit for electricity and service fee',
    cash_coupon0011: 'Use electricity to offset both electricity and service fee',
    cash_coupon0012: 'Cash offset service fee',
    cash_coupon0013: 'Use electricity to offset service fee',
    cash_coupon0014: 'Please enter a number, up to two decimal places',
    cash_coupon0015: 'Relative',
    cash_coupon0016: 'Absolute',
    cash_coupon0017: 'Date of user acquisition',
    cash_coupon0018: 'Please enter a natural number',
    cash_coupon0019: 'Day',
    cash_coupon0020: 'To',
    cash_coupon0021: 'Available sites',
    cash_coupon0022: 'Site name',
    cash_coupon0023: 'Search',
    cash_coupon0024: 'Bulk Remove',
    cash_coupon0025: 'Operation',
    cash_coupon0026: 'Remove',
    cash_coupon0027: 'Electricity ticket',
    cash_coupon0028: 'Credit',
    cash_coupon0029: 'Unrecoverable after',

}
