<template lang="html">
    <div class="title">
        <div class="title-wrap">
            <p>{{text}}</p>
            <el-button type="primary" @click="select" size="medium">{{title}}</el-button>
        </div>
        <el-dialog :title="title" :visible.sync="dialogFlag" :lock-scroll="false" width="780px">
            <div class="border">
                <el-input type="text" :placeholder="$t('common_00327')"  v-model.trim="searchSiteName" clearable style="width: 240px;margin: 0 30px 20px 0;" />
                <el-button @click="dialogFlag = false" size="medium">{{$t("common_00215")}}</el-button>
                <el-button type="primary" @click="save" :disabled="checkList.length>5" size="medium">
                    {{$t("common_00234")}}
                </el-button>
            </div>
            <el-checkbox-group v-model="checkList">
                <el-checkbox
                        v-for="item in filterList"
                        :label="item[1]"
                        :key="item[1]">
                    {{item[0]}}
                </el-checkbox>
            </el-checkbox-group>
        </el-dialog>
    </div>
</template>
<script>
    import {requestUrl,ajax} from "@/assets/utils/network";
    export default {
        data() {
            return {
                dialogFlag: false,
                checkList: [],
                list: [],
                title: "",
                biList:[],
                searchSiteName:"",
            }
        },
        props: ['text','type','chartPage'],
        computed:{
            filterList: function() {
                var search = this.searchSiteName;
                if (search) {
                    var reg = new RegExp(search, 'ig')
                    return this.list.filter(function(e) {                    
                        return e[0].match(reg);
                    })
                };
                return this.list;
            }
        },
        methods: {
            //保存选择站点
            save() {
                this.searchSiteName = '';
                let ids = [];
                for(var i=0;i<this.list.length;i++){
                    for(var j=0;j<this.checkList.length;j++){
                        if(this.list[i][1] === this.checkList[j]){
                            ids.push(this.list[i][1])
                        }
                    }
                }
                for(let i in this.biList){
                    if(this.chartPage == i){
                       this.checkList =this.biList[i]
                    }
                } 
                let saveUrl = requestUrl + "/bi/config";
                let chartPage = this.chartPage;
                const saveParam = {
                    'chartTerm': {
                        [chartPage]:ids
                    },
                }
                ajax({
                    type: "POST",
                    url: saveUrl,
                    dataType: "json",
                    data: JSON.stringify(saveParam),
                });
                this.dialogFlag = false;
                this.$emit("callback", ids);
            },
            //打开弹窗获取站点、团体、加盟商ID
            select() {
                console.log(this.checkList)
                this.dialogFlag = true;
                let url = requestUrl + "/bi/stats";
                const param = {
                    'chart': 'nameList',
                    'aggregation': [this.type],
                }
                ajax({
                    type: "POST",
                    url: url,
                    dataType: "json",
                    data: JSON.stringify(param),
                    success: (response)=> {
                        let info = JSON.parse(response).result;
                        this.list = info;
                    }
                });
                for(let i in this.biList){
                    if(this.chartPage == i){
                       this.checkList =this.biList[i]
                    }
                }
            },
            //回显站点勾选框
            getBiConfig(){
                ajax({
                    type: "GET",
                    url: requestUrl + "/bi/config",
                    success:  (response)=>{
                        let info = JSON.parse(response);
                        this.biList = info;
                        for(let i in info.chartTerm){
                            if(this.chartPage == i){
                                this.checkList = info.chartTerm[i]
                            }
                        }
                        this.$emit("callback", this.checkList);
                    }
                });
            }
        },
        mounted() {
            this.getBiConfig()
            if (this.type === 'site') {
                this.title = this.$t('common_00314');
            }
            if (this.type === 'dealer') {
                this.title = this.$t('common_00315');
            }
            if (this.type === 'deviceFamily') {
                this.title = this.$t('common_00316');
            }
            if (this.type === 'userGroup') {
                this.title = this.$t('common_00317');
            }
        },
        
    }
</script>
<style lang="scss">
    .title-wrap{
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        p{
            font-size: 16px;
            font-weight: 600;
            color: #41526D;
        }
    }
    .el-dialog {
        .border {
            border-bottom: solid 1px #ccc;
            padding: 10px 0;
            margin-bottom: 10px;
        }

        .el-checkbox-group {
            max-height: 500px;
            height: auto;
            overflow: hidden;
            overflow-y: scroll;
            display: flex;
            flex-wrap:wrap;
            .el-checkbox {
                width: 30%;
                margin: 1px;
                display: flex;
                align-items: center;
                .el-checkbox__label {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 150px;
                    display: inline-block;
                }
            }
        }
    }
</style>