export default {
    analyzeUser_00001: "整体充电用户数分析",
    analyzeUser_00002: "站点充电用户数分析",
    analyzeUser_00003: "设备型号利用率分析",
    analyzeUser_00004: "用户贡献分析",
    analyzeUser_00005: "用户RFM模型分析",
    analyzeUser_00006: "充电用户数趋势",
    analyzeUser_00007: "分析",
    analyzeUser_00008: "按电量分析",
    analyzeUser_00009: "按时长分析",
    analyzeUser_00010: "充电用户数",
    analyzeUser_00011: "第一次充电用户数",
    analyzeUser_00012: "站点用户数对比",
    analyzeUser_00013: "站点用户数趋势",
    analyzeUser_00014: "每用户平均贡献收入",
    analyzeUser_00015: "每用户平均充电量",
    analyzeUser_00016: "每用户平均充电时长",
    analyzeUser_00017: "每用户平均充电次数",
    analyzeUser_00018: "累计充电金额（元）",
    analyzeUser_00019: "姓名",
    analyzeUser_00020: "用户类型",
    analyzeUser_00021: "卡号",
    analyzeUser_00022: "最近一次充电时间",
    analyzeUser_00023: "充电累计金额",
    analyzeUser_00024: "充电次数",
    analyzeUser_00025: "充电次数 (次)",
    analyzeUser_00026: "最近一次充电在30日内用户",
    analyzeUser_00027: "最近一次充电在30日-90日之间用户",
    analyzeUser_00028: "最近一次充电在90日-180日之间用户",
    analyzeUser_00029: "加盟商用户数对比",
    analyzeUser_00030: "加盟商充电用户数分析",
    analyzeUser_00031: "加盟商用户数趋势",
    analyzeUser_00032: "用户来源充电订单数分析",
    analyzeUser_00033: "充电订单数",
    analyzeUser_00034: "用户来源充电订单数占比",
    analyzeUser_00035: "第三方流量中各流量占比",
    analyzeUser_00036: "用户来源充电订单数趋势",
    analyzeUser_00037: "第三方流量充电订单数趋势",
    analyzeUser_00038: "单",
    analyzeUser_00039: "枪均单量",
} 