<template lang="html">
  <div ref='normalLogin'>
    <el-dialog :title="title" :visible="flag_normal" :lock-scroll="false" @close="close" width="520px"
    :close-on-click-modal="false" :close-on-press-escape="false" append-to-body modal-append-to-body>
      <div class="normal-center">
        <div class="normal-input">
          <el-input
            size="large"
            :placeholder="$t('login_00002')"
            v-model="username">
          </el-input>
        </div>
        <div class="normal-input">
          <el-input
            size="large"
            :placeholder="$t('login_00003')"
            type="password"
            v-model="password">
          </el-input>
        </div>
        <div class="normal-btn">
          <el-button type="primary" class="normal-el-btn" @click="normal_login"> {{$t('login_00004')}} </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {requestUrl} from '../../../assets/utils/constants'
import {ajax} from '../../../assets/utils/network'
export default {
  data() {
    return {
      username: '',
      password: '',
    }
  },
  props:['flag_normal','title'],
  methods: {
    close() {
      this.flag_normal = false;
      this.$emit('normalDispatch', this.flag_normal);
    },
    /*
     * login button
     */
    normal_login() {
      var om = this;
        if (this.username == '' || this.password == '') {
          om.$message.error(this.$t('login_00005'));
          return false;
        }
        var user = new Object();
        user.loginId = this.username;
        user.password = this.password;
        ajax({
          type: "POST",
          url: requestUrl + "/login",
          data: JSON.stringify(user),
          dataType: "json",
          success: function(response) {
            var info = JSON.parse(response);
            if (info.error != null) {
              if (info.error.code == 'NOT_LOGIN') {
                window.location.href = './login.html?l=0';
              }
              om.$message.error(info.error.msg == null ? info.error.code : info.error.msg);
              return false;
            }
            if (info.result != null) {
              if(info.result.isSupervisor){
                document.cookie = `spvid=${info.result.resources[0].resource}`
              }
               if (info.result.idType == 'userGroup'){
                window.location.href = "./groupBaseInfo.html";
              } else {
                window.location.href = "./preview.html";
              }
            }
          },
          fail: function(response) {
            om.$message.error(response);
          }
        });
    }
  },
  mounted() {
      var that = this;
      that.$refs.normalLogin.onkeydown = function(e) {
        if (!e) e = window.event;
        if ((e.keyCode || e.which) == 13) {
          that.normal_login()
        }
      }
  }
}
</script>

<style lang="scss">
.normal-center {
    height: 220px;
    padding-top: 20px;
    margin: 0 auto;
    & .normal-input {
        max-width: 320px;
        height: 48px;
        margin: 18px auto 0;
    }
    & .normal-btn {
        width: 150px;
        height: 48px;
        margin: 30px auto 0;
        & .normal-el-btn {
            width: 150px;
            height: 48px;
            background-color: #2081D1;
            font-size: 18px;
        }
    }
}

.normal-input{
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition-delay: 111111s;
    transition: color 11111s ease-out, background-color 111111s ease-out;
  }
}
</style>
