export default {
    userOrderQuery_00001: "Group Name",
    userOrderQuery_00002: "Charging order query",
    userOrderQuery_00003: "Order Number",
    userOrderQuery_00004: "Reset",
    userOrderQuery_00005: "Revenue (€)",
    userOrderQuery_00006: "Order amount (€)",
    userOrderQuery_00007: "Amount of electricity (€)",
    userOrderQuery_00008: "Service charge amount (€)",
    userOrderQuery_00009: "Extension fee amount (€)",
    userOrderQuery_00010: "Charging power (kWh)",
    userOrderQuery_00011: "Charging duration (min)",
    userOrderQuery_00012: "Charging times (times)",
    userOrderQuery_00013: "To",
    userOrderQuery_00014: "Start date",
    userOrderQuery_00015: "End date",
    userOrderQuery_00016: "Order List",
    userOrderQuery_00017: "Export Orders",
    userOrderQuery_00018: "Charging post - charging port",
    userOrderQuery_00019: "Charging hours",
    userOrderQuery_00020: "Deleted",
    userOrderQuery_00021: "Start charging",
    userOrderQuery_00022: "End charging",
    userOrderQuery_00023: "Amount of charged (kwh)",
    userOrderQuery_00024: "Created",
    userOrderQuery_00025: "Cancelled",
    userOrderQuery_00026: "Charging in progress",
    userOrderQuery_00027: "Pending payment",
    userOrderQuery_00028: "Paid",
    userOrderQuery_00029: "Group",
    userOrderQuery_00030: "WeChat User",
    userOrderQuery_00031: "Administrator",
    userOrderQuery_00032: "Current order amount",
    userOrderQuery_00033: "Actual payment amount",
    userOrderQuery_00034: "Order details",
    userOrderQuery_00035: "This operation will download an Excel file of the bill, do you want to continue?" ,
    userOrderQuery_00036: "Reminder",
    userOrderQuery_00037: "OK",
    userOrderQuery_00038: "Cancel",
    userOrderQuery_00039: "The data is being prepared, please be patient.When the data is ready it will automatically close this popup and start downloading, please do not close or refresh the page in the meantime..." ,
    userOrderQuery_00040: "Cancel download",
    userOrderQuery_00041: "Days",
    userOrderQuery_00042: "Hours",
    userOrderQuery_00043: "Minutes",
    userOrderQuery_00044: "Start/stop card",
    userOrderQuery_00045: "Stored value card",
    userOrderQuery_00046: "Backup data",
    userOrderQuery_00047: "Identity Card",
    userOrderQuery_00048: "Double Shot Order",
    userOrderQuery_00049: "Power less than 1 kwh",
    userOrderQuery_00050: "Charging duration is too long",
    userOrderQuery_00051: "Charger ended abnormally",
    userOrderQuery_00052: "EV abnormally ended",
    userOrderQuery_00053: "",
    userOrderQuery_00054: "",
    userOrderQuery_00055: "",
    userOrderQuery_00056: "",
    userOrderQuery_00057: "",
    userOrderQuery_00058: "",
    userOrderQuery_00059: "",
    userOrderQuery_00060: "",
  }