export default {
    user_00001: "Username/telephone/license plate",
    user_00002: "User",
    user_00003: "Followed",
    user_00004: "Unfollwed",
    user_00005: "Export user",
    user_00006: "This will download Excel files of users, continue?",
    user_00007: "List",
    user_00008: "Users that match this category",
    user_00009: "Operate",
    user_00010: "User information is not authorized",
}