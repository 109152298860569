export default {
  addDevice_00001: "Please select or create a new station to add charger to",
  addDevice_00002: "Please select a station",
  addDevice_00003: "Add charger to the station",
  addDevice_00004: "Please select a station to add charger",
  addDevice_00005: "",
  addDevice_00006: "",
  addDevice_00007: "",
  addDevice_00008: "",
  addDevice_00009: "",
  addDevice_00010: "",
};