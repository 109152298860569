export default {
    eqDetails_00001: 'Apply configuration',
    eqDetails_00002: 'Charger detail',
    eqDetails_00003: 'Charger info',
    eqDetails_00004: 'Charger configuration',
    eqDetails_00005: 'Charge point management',
    eqDetails_00006: 'Charger maintenance',
    eqDetails_00007: 'Restart',
    eqDetails_00008: 'Charger reboot command has been sent, the reboot process will take approximately 5 minutes. Please stay on this page and do not refresh the page.',
    eqDetails_00009: 'Confirm',
    eqDetails_00010: 'Start charging',
    eqDetails_00011: 'Charger reboot successfully',
    eqDetails_00012: 'Remote reboot',
    eqDetails_00013: 'Charger reboot failed',
    eqDetails_00014: 'Charger name',
    eqDetails_00015: 'Charging station belonging to',
    eqDetails_00016: 'Check',
    eqDetails_00017: 'Serial number',
    eqDetails_00018: 'Manufacture',
    eqDetails_00019: 'Charger model',
    eqDetails_00020: 'Please enter the charger name',
    eqDetails_00021: 'Charger available, electromagnetic lock and other configurations will automatically apply the configuration to the charger after modification, you need to wait for the charger response before updating the page',
    eqDetails_00022: 'Apply configuration',
    eqDetails_00023: 'Apply to charger',
    eqDetails_00024: 'Charger available',
    eqDetails_00025: 'Electromagnetic lock',
    eqDetails_00026: 'Failed to lock can be charged',
    eqDetails_00027: 'Parking area radar',
    eqDetails_00028: 'Charger available',
    eqDetails_00029: 'Start time',
    eqDetails_00030: 'End time',
    eqDetails_00031: 'Max. charging current(A)',
    eqDetails_00032: 'Max. charging power(kW)',
    eqDetails_00033: 'Apply failed',
    eqDetails_00034: 'Charger is offline',
    eqDetails_00035: 'Waiting for charger response',
    eqDetails_00036: 'Apply successfully',
    eqDetails_00037: 'Date set incorrectly',
    eqDetails_00038: 'Power module requires maintenance timing prompt: accumulated charging hours from reset time',
    eqDetails_00039: '{hours}hours',
    eqDetails_00040: 'Reset time：',
    eqDetails_00041: 'Normal',
    eqDetails_00042: 'Faulted',
    eqDetails_00043: 'Are you sure you have completed this maintenance for this charging post power module? After clicking OK, the power module requires a maintenance prompt to retime.',
    eqDetails_00044: 'Reminder',
    eqDetails_00045: 'Connector name',
    eqDetails_00046: 'Charging QR code',
    eqDetails_00047: 'Dynamic QR code',
    eqDetails_00048: 'Fixed QR Code',
    eqDetails_00049: 'Upgrade to fixed QR code',
    eqDetails_00050: 'Custom QR Code',
    eqDetails_00051: 'Remote control',
    eqDetails_00052: 'Restart connector',
    eqDetails_00053: 'End charging',
    eqDetails_00054: 'Reset to available',
    eqDetails_00055: 'Connector status',
    eqDetails_00056: 'Car parking management device',
    eqDetails_00057: 'N/A',
    eqDetails_00058: 'Stake-controlled parking space lock',
    eqDetails_00059: 'Cloud-controlled parking space lock',
    eqDetails_00060: 'Parking space management device information',
    eqDetails_00061: 'Please enter device code',
    eqDetails_00062: 'Enable parking space management device',
    eqDetails_00063: 'Remote control of the parking space management device',
    eqDetails_00064: 'Rise',
    eqDetails_00065: 'Descending',
    eqDetails_00066: 'Current parking space occupancy billing user',
    eqDetails_00067: 'Occupancy start time',
    eqDetails_00068: 'User details',
    eqDetails_00069: 'Manual end of this occupancy charge billing',
    eqDetails_00070: 'QR code of parking space management device lowering ',
    eqDetails_00071: 'Generate fixed QR code',
    eqDetails_00072: 'Occupying',
    eqDetails_00073: 'Unavailable',
    eqDetails_00074: 'Offline',
    eqDetails_00075: 'Faulted',
    eqDetails_00076: 'Reserved',
    eqDetails_00077: 'Available',
    eqDetails_00078: 'Preparing',
    eqDetails_00079: 'Charging',
    eqDetails_00080: 'EV to be moved',
    eqDetails_00081: 'Finishing',
    eqDetails_00082: 'Connector',
    eqDetails_00083: 'Start charging',
    eqDetails_00084: 'Please save the previous operation！！',
    eqDetails_00085: 'Current space has users who are being billed for space occupancy, please modify later!',
    eqDetails_00086: 'Parameter is empty',
    eqDetails_00087: 'Maximum 20 characters',
    eqDetails_00088: 'Start charging command has been sent',
    eqDetails_00089: 'End charging command has been sent',
    eqDetails_00090: 'Sent successfully',
    eqDetails_00091: 'Upgrade to fixed QR code',
    eqDetails_00092: 'Generated QR code successfully',
    eqDetails_00093: 'Available',
    eqDetails_00094: 'Unavailable',
    eqDetails_00095: 'Cloud-controlled parking space lock',
    eqDetails_00096: 'Stake-controlled parking space lock',
    eqDetails_00097: 'Please enter the content of the QR code, the QR code you have configured will be displayed on the pile',
    eqDetails_00098: 'Note: Only fixed QR codes can be generated and cannot be restored to their original state',
    eqDetails_00099: 'The custom QR code cannot be empty!',
    eqDetails_00100: 'Custom QR code is empty ',
    eqDetails_00101: 'Configuration failed',
    eqDetails_00102: 'The length cannot exceed {0} characters',
    eqDetails_00103: 'Current charging duration (minutes)',
    eqDetails_00104: 'Current pre-charged idle fee ({0})',
    eqDetails_00105: 'Revised Idle fee ({0})',
    eqDetails_00106: 'Please enter the amount',
    eqDetails_00107: 'Please enter the revised idle fee',
    eqDetails_00108: 'Please enter the correct number',
    eqDetails_00109: 'Manual end of billing for this occupancy charge was successful',
    eqDetails_00110: 'The maximum number of fixed QR codes is 100,000 and is not recallable, please proceed with caution, sure to upgrade?',
    eqDetails_00111: 'Upgrade successful',
    eqDetails_00112: 'Done',
    eqDetails_00113: 'Did you generate a fixed QR code for the car parking management device drop?',
    eqDetails_00114: 'No QR code at this time',
    eqDetails_00115: 'If the device is offline, only users in the whitelist are supported to start charging',
    eqDetails_00116: 'Clear whitelist',
    eqDetails_00117: 'Push whitelist',
    eqDetails_00118: 'Charger ID',
    eqDetails_00119: '',
    eqDetails_10000: 'Are you sure you have completed this maintenance for all {0} charging points? After clicking OK, all {0} service required reminders are re-timed. ',
    eqDetails_10001: 'Modify all valves',
    eqDetails_10002: 'hour',
    eqDetails_10003: 'time',
    eqDetails_10004: 'Tips',
    eqDetails_10005: 'Apply to all',
    eqDetails_10006: 'Reset all',
    eqDetails_10007: 'Service time',
    eqDetails_10008: 'Used',
    eqDetails_10009: 'Maintenance timing prompt threshold:',
    eqDetails_10010: 'The cumulative usage time since the reset time is greater than or equal to',
    eqDetails_10011: 'Please complete',
    eqDetails_10012: 'Power Module',
    eqDetails_10013: 'Storage battery',
    eqDetails_10014: 'Fan',
    eqDetails_10015: 'Connector',
    eqDetails_10016: 'Contactor',
    eqDetails_10017: 'Wire puller',
    eqDetails_10018: 'Surge protector',
    eqDetails_10019: 'LCD screen',
    eqDetails_10020: 'Bleed resistor',
    eqDetails_10021: 'Force charge',
    eqDetails_10022: 'Force discharge',
    eqDetails_10023: 'Disabled',
    eqDetails_10024: 'Autorun',
    eqDetails_10025: 'Charger info',
    eqDetails_10026: 'Charger settings',
    eqDetails_10027: 'About the settings',
    eqDetails_10028: 'Connectors',
    eqDetails_10029: 'Available',
    eqDetails_10030: 'Connector name',
    eqDetails_10031: 'Battery-charge mode',
    eqDetails_10032: 'Air conditioner operation mode',
    eqDetails_10033: 'Operating mode settings',
    eqDetails_10034: 'NZS batterys',
    eqDetails_10035: 'Battery',
    eqDetails_10036: 'Battery cabinet name',
    eqDetails_10037: 'Battery cabinet capacity(kWh)',
    eqDetails_10038: 'Battery cycle time(times)',
    eqDetails_10039: 'Battery cabinet SOC(%)',
    eqDetails_10040: 'Battery cabinet SOH(%)',
    eqDetails_10041: 'Reboot',
    eqDetails_10042: 'Send whitelist',
    eqDetails_10043: 'Error logs',
    eqDetails_10044: 'Setting description',
    eqDetails_10045: 'Charger swtich, electromagnetic lock and other settings will automatically apply the setting to the charger after modification, you need to wait for the charger response before updating the page',
    eqDetails_10046: 'If the charger is offline, only users in the whitelist are supported to start charging',
    eqDetails_10047: 'Learn more',
    eqDetails_10047: 'more',
    eqDetails_10048: 'Charging SOC limit',
    eqDetails_10049: 'Discharging SOC limit',
    eqDetails_10050: "NZS Battery charge power limit(kW)",
    eqDetails_10051: 'NZS Battery discharge power limit（kW）',
    eqDetails_10052: 'Grid import power limit (kW)',
    eqDetails_10053: 'Are you sure to restart the device?',
    eqDetails_10054: 'Range',
    eqDetails_10055: "Fault",
    eqDetails_10056: "No limit {0}%",
    eqDetails_10057: "Restart Battery",
    eqDetails_10058: "limit{0}%",
    eqDetails_10059: "The command has been sent successfully, the device will be executed after charging, please refresh the page later to view the execution result",
    eqDetails_10060: "Charged",
    eqDetails_10061: "The push is successful, waiting for the device to respond",
    eqDetails_10062: "The working status of the battery cabinet has been switched successfully",
    eqDetails_10063: "Failed to switch the working state of the battery cabinet",
    eqDetails_10064: "Battery cabinet working state switching overtime",
    eqDetails_10065: "The connector is charging, no need to start charging again",
    eqDetails_10066: "Please disable the parking space management device first",
    eqDetails_10067: "Set to available",
    eqDetails_10068: "This function is only used for emergency processing when the platform and muzzle information are out of sync. Are you sure to set it to available?",
    eqDetails_10069: "Unlimited, Rated maximum charging power is {0} kW",
    eqDetails_10070: "Unlimited, Rated maximum current is {0} A",
    eqDetails_10071: "Charged duration",
    eqDetails_10072: "Full expected",
    detailDepot_00182: "Site support white list",
    detailDepot_00545: "Please save the previous operation!",
    detailDepot_00112: "Can be charged",
    detailDepot_00106: "Charging is not allowed",
    // #7662新增
    detailDepot_20000: "CPO",
    detailDepot_20001: "OS version",
    detailDepot_20002: "Android app version",
    detailDepot_20003: "DCB version",
    detailDepot_20004: "Upgrade firmware",
    detailDepot_20005: "Lock the MagLock",
    detailDepot_20006: "Unlock the MagLock",
    detailDepot_20007: "Delete",
    detailDepot_20008: "Are you sure to delete this charger?",
    detailDepot_20009: "Operation canceled",
    detailDepot_20010: "Turn off the enable switch in the charger configuration before deleting the charger",
    eqDetails_10074: "The rated maximum charging power is not set in the system for this charger model and cannot be modified temporarily. Please contact the administrator.",
    eqDetails_10075: "The rated maximum current is not set in the system for this charger model and cannot be modified temporarily. Please contact the administrator.",
    eqDetails_10073: "Please enter the correct range",
    detailDepot_00107: "Forced grid connection",
    detailDepot_00108: 'Battery to grid power limit(kW)',
    detailDepot_00109: 'Apply to charger',
    detailDepot_00110: 'The charger restart command has been sent, and the restart process will take approximately 5 minutes',
    detailDepot_00111: 'Dual Platform',
    detailDepot_10112: "Refers to the maximum operating power of the ACDC module, which cannot be exceeded whether it is used to charge the NZS battery or the vehicle.",

    //维保类型（英语）
    eqDetails_21000: "DC meter",
    eqDetails_21001: "AC meter",
    eqDetails_21002: "Switching relay",
    eqDetails_21003: "Access control sensor",
    eqDetails_21004: "Tip sensor",
    eqDetails_21005: "Smoke sensor",
    eqDetails_21006: "Liquid level sensor",
    eqDetails_21007: "Liquid cooling pump",
    eqDetails_21008: "Liquid cooling pump fan",
    eqDetails_21009: "Light bar",
    eqDetails_21010: "Filter",
    eqDetails_21011: "Energy storage battery health",
    eqDetails_21012: "AC contactor",
    eqDetails_21013: "Lance tip relay (DC-)",
    eqDetails_21014: "Manufacturer",
    eqDetails_21015: "UUGreenPower",
    eqDetails_21016: "INFY Power",
    eqDetails_21017: "Megmeet",
    eqDetails_21018: "Lance tip relay (DC+)",
    eqDetails_21019: "Battery cabinet tilt",
    eqDetails_21020: "Tilt Correction",
    eqDetails_21021: "SOH Is lower than",
    eqDetails_21022: "Current",

    //自检
    eqDetails_30001: "Charger recovery emergency stop state",
    eqDetails_30002: "Whether to perform automatic self-inspection after that",
    eqDetails_30003: "Hardware self-inspection",
    eqDetails_30004: "1. Check the status of the device hardware, which takes tens of seconds",
    eqDetails_30005: "2. This check requires that the firmware version number of the Android application for the charger be above 3.0.0.",
    eqDetails_30006: "3. This check is not supported by third-party devices.",
    eqDetails_30007: "Last inspection result",
    eqDetails_30008: "Start inspection",
    eqDetails_30009: "Please wait during self-inspection",
    eqDetails_30010: "Re-inspection",
    eqDetails_30011: "Inspect time",
    eqDetails_30012: "Status inspection",
    eqDetails_30013: "Hardware lifetime",
    eqDetails_30014: "Self-inspection items",
    eqDetails_30015: "Confirm and recover from emergency stop",
    eqDetails_30016: "The charger is in use, self-inspection is canceled, please operate after it is idle",
    eqDetails_30017: "The current charger is offline",
    eqDetails_30018: "Charger self-inspection timed out, please try again",
    eqDetails_30020: "Poorer",
    eqDetails_30021: "moderate",
    eqDetails_30022: "better",
    eqDetails_30023: "This inspection requires that the firmware version number of the Android application for the charger be 3.0.0 or higher",
    eqDetails_30024: "Last self-inspection result not found",
    eqDetails_30025: "Recovery immediately",
    eqDetails_30026: "Check the charger first, then consider recovery",
    eqDetails_30027: "Disclaimer",
    eqDetails_30028: "To ensure the safe operation of the charger, we recommend that you perform a hardware check before restoring the emergency stop button. If any hardware failure or safety hazard is found, please stop using the charger and contact our technical support team. We will provide a solution as soon as possible to ensure that your charger can operate safely and stably.",
    eqDetails_30029: "Please note that this disclaimer is intended to remind you that choosing to directly restore the emergency stop button without first determining the status of the charger may involve certain risks, and we will not be held responsible for any resulting consequences.",
    eqDetails_30030: "Confirm direct recovery",
    eqDetails_30031: "Self-check items",
    eqDetails_30032: "State",
    eqDetails_30033: "{msg},{code},self-inspection is canceled, Please operate after maintenance",
    // 设备储能
    EqDetails_41000: "Sleep",
    EqDetails_41001: "Battery cabinet reset",
    // #10400
    eqDetails_50000: "Maximum Current Limit",  
    eqDetails_50001: "The maximum charging current of the equipment can be limited by time periods, but cannot exceed the rated maximum current of {0}A.",  
    eqDetails_50002: "Limit Maximum Charging Current (A)",  
    eqDetails_50003: "Please fill in the time period correctly",  
    eqDetails_50004: "There are blank or overlapping time periods in the settings, please check and save after modification",  
    eqDetails_50005: "The limited maximum charging current cannot exceed the rated maximum current value",

}
