export default {
    monthlyStatistics_00001:'Finanzzentrum',
    monthlyStatistics_00002:'Startdatum',
    monthlyStatistics_00003:'Enddatum',
    monthlyStatistics_00004:'Suche',
    monthlyStatistics_00005:'',
    monthlyStatistics_00006:'Aufladung',
    monthlyStatistics_00007:'Pauschalbetrag',
    monthlyStatistics_00008:'Yuan',
    monthlyStatistics_00009:'Formular herunterladen',
    monthlyStatistics_00010:'heute',
    monthlyStatistics_00011:'gestern',
    monthlyStatistics_00012:'vor 7 Tagen',
    monthlyStatistics_00013:'vor 15 Tagen',
    monthlyStatistics_00014:'vor 30 Tagen',
    monthlyStatistics_00015:'Rückerstattung',
    
    monthlyStatistics_00016:'Aufladeobjekt',
    monthlyStatistics_00017:'Anzahl der Aufladungen',
    monthlyStatistics_00018:'Aufladebetrag(Yuan)',
    monthlyStatistics_00019:'Rektor(Yuan)',
    monthlyStatistics_00020:'Hergeben(Yuan)',
    monthlyStatistics_00021:'arbeiten',
    
    monthlyStatistics_00022:'Rückerstattungsobjekt',
    monthlyStatistics_00023:'Anzahl der Rückerstattungen',
    monthlyStatistics_00024:'Rückerstattungsbetrag(Yuan)',
    
    monthlyStatistics_00025:'Statistikobjekt',
    monthlyStatistics_00026:'Anzahl der Benutzer',
    monthlyStatistics_00027:'Gleichgewicht(Yuan)',
    monthlyStatistics_00028:'Rektor(Yuan)',
    monthlyStatistics_00029:'Hergeben(Yuan)',
    
    monthlyStatistics_00030:'Umsatzziel',
    monthlyStatistics_00031:'Anzahl der Käufe',
    monthlyStatistics_00032:'Anzahl der Käufer',
    monthlyStatistics_00033:'Verkaufsmenge(Yuan)',
    
    monthlyStatistics_00034:'Alle Gutscheinverkäufe',
    monthlyStatistics_00035:'Alle Guthaben der Benutzer-Wallet',

    monthlyStatistics_00036:'Jahr',
    monthlyStatistics_00037:'Finanzielle Statistiken'

}