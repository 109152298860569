export default {
    carDetail_00001: "EV information",
    carDetail_00002: "EV car",
    carDetail_00003: "Delete EV",
    carDetail_00004: "This operation will permanently delete the vehicle information, whether to continue",
    carDetail_00005: "EV information",
    carDetail_00006: "Contact information",
    carDetail_00007: "Contact name",
    carDetail_00008: "Group management",
    carDetail_00009: "Export group member",
    carDetail_00010: "Delete group",
    carDetail_00011: "No recovery after deletion of groups",
    carDetail_00012: "Search order",
    carDetail_00013: "This operation will download the Excel file of the group user, whether to continue？",
}