var checkHealth = {
	"CH1001": {
		"msg": "Connector's electromagnetic lock",
		"idMsg": "Connector-",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1002": {
		"msg": "Connector's CP voltage",
		"idMsg": "Connector-",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1003": {
		"msg": "Connector's PP voltage",
		"idMsg": "Connector-",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1004": {
		"msg": "Connector's CC voltage",
		"idMsg": "Connector-",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1005": {
		"msg": "Connector's temperature",
		"idMsg": "Connector-",
		"unit": "℃",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1006": {
		"msg": "Liquid level of liquid cooling pump of connector",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1007": {
		"msg": "Pump speed of liquid cooling pump of connector",
		"idMsg": "",
		"unit": "rpm",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1008": {
		"msg": "Speed of liquid cooling pump fan of connector",
		"idMsg": "",
		"unit": "rpm",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1009": {
		"msg": "Liquid temperature of charging gun liquid cooling pump (feed liquid)",
		"idMsg": "",
		"unit": "℃",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1010": {
		"msg": "Liquid temperature of charging gun liquid cooling pump (return liquid)",
		"idMsg": "",
		"unit": "℃",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1011": {
		"msg": "Auxiliary power voltage condition 12V",
		"idMsg": "",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1012": {
		"msg": "Auxiliary power voltage condition 24V",
		"idMsg": "",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1013": {
		"msg": "ACDC module",
		"idMsg": "Module-",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1014": {
		"msg": "DCDC module",
		"idMsg": "Module-",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1017": {
		"msg": "AC input voltage level",
		"idMsg": "",
		"unit": "V",
		"unitExt": {},
		"idMsgExt": {
			"1": "A",
			"2": "B",
			"3": "C"
		}
	},
	"CH1018": {
		"msg": "DCB to PCB communication status",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1021": {
		"msg": "Emergency stop status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1022": {
		"msg": "LED indicator light status",
		"idMsg": "Connector-",
		"unit": "",
		"unitExt": {
			"1": "Off",
			"2": "Red",
			"3": "Green",
			"4": "Blue"
		},
		"idMsgExt": {}
	},
	"CH1024": {
		"msg": "Screen on/off status",
		"idMsg": "",
		"unit": "",
		"unitExt": {
			"1": "On",
			"2": "Off"
		},
		"idMsgExt": {}
	},
	"CH1026": {
		"msg": "Light sensor status",
		"idMsg": "",
		"unit": "Ω",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1027": {
		"msg": "NFC status/POS machine status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1028": {
		"msg": "Cooling component status",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1029": {
		"msg": "Insulation detection component status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1030": {
		"msg": "Internal temperatures",
		"idMsg": "",
		"unit": "℃",
		"unitExt": {},
		"idMsgExt": {
			"1": "Casing"
		}
	},
	"CH1031": {
		"msg": "Lightning protection device status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1032": {
		"msg": "Advertising server connection status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1033": {
		"msg": "Advertising screen status",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1034": {
		"msg": "OCPP/X-Cloud auxiliary platform connection status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1035": {
		"msg": "GSM network signal quality",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1036": {
		"msg": "LAN connection DNS",
		"idMsg": "",
		"unit": "millisecond",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1038": {
		"msg": "Smoke alarm status",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1039": {
		"msg": "Water immersion detector status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1040": {
		"msg": "Status of each access control",
		"idMsg": "",
		"unit": "",
		"unitExt": {},
		"idMsgExt": {
			"1": "Front",
			"2": "Rear",
			"3": "Left",
			"4": "Right"
		}
	},
	"CH1041": {
		"msg": "Electricity meter status",
		"idMsg": "Connector-",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1042": {
		"msg": "Fuse status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1043": {
		"msg": "Leakage protector status",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1044": {
		"msg": "Dump sensor",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1045": {
		"msg": "DCB memory",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1046": {
		"msg": "DCB disk",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1047": {
		"msg": "AC contactor",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	},
	"CH1048": {
		"msg": "DC contactor",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {
			"1": "Connector 1+",
			"-1": "Connector 1-",
			"2": "Connector 2+",
			"-2": "Connector 2-"
		}
	},
	"CH1049": {
		"msg": "Parallel/transfer contactor",
		"idMsg": "",
		"unitExt": {},
		"idMsgExt": {}
	}
}
module.exports = checkHealth;