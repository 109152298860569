export default {
  deviceMessageTemplate_00001: "Benutzerdefinierte Konfigurationsinformation Vorlage zu bearbeiten, Sie können die Vorlage direkt in die Ladestation einfügen oder sie auf der grundlegende Vorlage erneut bearbeiten",
  deviceMessageTemplate_00002: 'C1 (4,3"-Bildschirm) Bildschirmkonfigurationsinformationen',
  deviceMessageTemplate_00003: 'C1 (7"-Bildschirm) Bildschirmkonfigurationsinformationen',
  deviceMessageTemplate_00004: "Intelligentes Ladesystem",
  deviceMessageTemplate_00005: "Größeres Bild anzeigen",
  deviceMessageTemplate_00006: "Logo beim Einschalten",
  deviceMessageTemplate_00007: "Logo oben",
  deviceMessageTemplate_00008: "Schnittstelle Text",
  deviceMessageTemplate_00009: "Nicht mehr als 16 Zeichen",
  deviceMessageTemplate_000010: "C2 Konfigurationsinformation des Bildschirms",
  deviceMessageTemplate_000011: "Scannenbildschirm-titel",
  deviceMessageTemplate_000012: "WeChat Code Scannen und Ladenstart",
  deviceMessageTemplate_000013: "WeChat scannt den linke QR-Code, folgen Sie den Anweisungen, um zum nächsten Schritt zu gelangen",
  deviceMessageTemplate_000014: "Scannenbildschirm-Text",
  deviceMessageTemplate_000015: "Nicht mehr als 10 Zeichen",
  deviceMessageTemplate_000016: "Nicht mehr als 24 Zeichen",
  deviceMessageTemplate_000017: "C4 Bildschirmkonfigurationsinformationen",
  deviceMessageTemplate_000018: "C6 Bildschirmkonfigurationsinformationen",
  deviceMessageTemplate_000019: "C6s Bildschirmkonfigurationsinformationen",
  deviceMessageTemplate_000020: "Willkommen beim Intelligentes Ladesystem",
  deviceMessageTemplate_000021: "Bitte verwenden Sie WeChat, um den Code zu scannen, oder verwenden Sie NFC, um die Karte durchzuziehen",
  deviceMessageTemplate_000022: "Verwenden Sie die Berührungstaste, um den Ladepunkt auszuwählen",
  deviceMessageTemplate_000023: "Text oben",
  deviceMessageTemplate_000024: "Begrüßungstexttitel",
  deviceMessageTemplate_000025: "Nicht mehr als {num} Wörter",
  deviceMessageTemplate_000026: "Inhalt des Begrüßungstextes",
  deviceMessageTemplate_000027: "Unterer Text",
  deviceMessageTemplate_000028: "Ziehen Sie die Datei hierher, oder |klicken Sie zum Hochladen",
  deviceMessageTemplate_000029: "Es werden nur Bilder im PNG-Format unterstützt, Größe {area}px und nicht mehr als {size}kb.",
  deviceMessageTemplate_000030: "Bild ändern",
  deviceMessageTemplate_000031: "Bild hochladen",
  deviceMessageTemplate_000032: "Bitte Bild hochladen",
  deviceMessageTemplate_000033: "Das Bild, das Sie ausgewählt haben, ist zu groß, bitte wählen Sie es erneut",
  deviceMessageTemplate_000034: "Bitte wählen Sie ein png-Bild zum Hochladen",
  deviceMessageTemplate_000035: "Bitte laden Sie ein Bild hoch, das {0}px entspricht",
  deviceMessageTemplate_000036: "Oberes Logo ändern",
  deviceMessageTemplate_000037: "Das oben Logo kann entweder im Text- oder im Bildformat sein.",
  deviceMessageTemplate_000038: "Text verwenden",
  deviceMessageTemplate_000039: "Bitte Inhalt eingeben",
  deviceMessageTemplate_000040: "{num} Wörter oder weniger (einschließlich Interpunktion)",
  deviceMessageTemplate_000041: "Bild verwenden",
  deviceMessageTemplate_000042: "X-CHARGE",
  deviceMessageTemplate_000043: "Der Text am oben ist zu lang",
  deviceMessageTemplate_000044: "Bitte wählen Sie 'Bild zuerst verwenden'",
  deviceMessageTemplate_000045: "Das Bild, das Sie ausgewählt haben, ist zu groß, bitte wählen Sie es erneut",
  deviceMessageTemplate_000046: "Bitte wählen Sie ein png-Bild zum Hochladen",
  deviceMessageTemplate_000047: "Der Parameter ist leer",
  deviceMessageTemplate_000048: "Die Parameterlänge ist zu lang",
  deviceMessageTemplate_000049: "Bitte scannen Sie den Code zum Laden oder verwenden Sie NFC zum Laden mit der Karte",
  deviceMessageTemplate_000050: "Der Titel des Scannenbildschirms darf nicht mehr als 10 Ziffern sein", 
  deviceMessageTemplate_000051: "Der Titel des Scannenbildschirms darf nicht mehr als 24 Ziffern sein",
  deviceMessageTemplate_000052: "Scannen um Laden zu starten",
  deviceMessageTemplate_000053: "Scannen Sie den QR-Code auf der linken Seite und folgen Sie den Anweisungen, um zum nächsten Schritt zu gelangen",
  deviceMessageTemplate_000054: "Bitte speichern Sie zuerst den vorherigen Vorgang",
  deviceMessageTemplate_000055: "Geben Sie nicht mehr als 7 Ziffern Text ein",
}