export default {
    userRanking_00001: '当前查看',
    userRanking_00002: '导出用户',
    userRanking_00003: '查看用户',
    userRanking_00004: '会员管理',
    userRanking_00005: '查看用户',
    userRanking_00006: '头像',
    userRanking_00007: '昵称',
    userRanking_00008: '性别',
    userRanking_00009: '手机号',
    userRanking_00010: '车牌号',
    userRanking_00011: '余额',
    userRanking_00012: '查询订单',
    userRanking_00013: '详情',
    userRanking_00014: '男',
    userRanking_00015: '女',
    userRanking_00016: '',
    userRanking_00017: '',
    userRanking_00018: '',
}
