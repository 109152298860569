export default {
  energyHome_00001: "非NZS充电桩",
  energyHome_00002: "储能柜",
  energyHome_00003: "当前储能SOC",
  energyHome_00004: "今日自供电比例",
  energyHome_00005: "空闲",
  energyHome_00006: "功率模式",
  energyHome_00007: "当前场站工作模式",
  energyHome_00008: "微网设备管理",
  energyHome_00009: "储能装机容量",
  energyHome_00010: "功率",
  energyHome_00011: "电压",
  energyHome_00012: "电流",
  energyHome_00013: "自定义模式时间计划",
  energyHome_00014: "今日车充电量",
  energyHome_00015: "今日车充收益",
  energyHome_00016: "设置电网用电成本",
  energyHome_00017: "充电桩装机容量",
  
}