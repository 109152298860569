export default {
    userCharge_00001: "Group Name",
    userCharge_00002: "Top-up Record",
    userCharge_00003: "Basic Information",
    userCharge_00004: "Date of recharge",
    userCharge_00005: "Total amount of recharge({0})",
    userCharge_00006: "Principal amount({0})",
    userCharge_00007: "Recharge amount({0})",
    userCharge_00008: "Payment Method",
    userCharge_00009: "Operating User",
    userCharge_00010: "Balance({0})",
    userCharge_00011: "Top-up History",
    userCharge_00012: "Include bonus{promotionBalance}{unit}",
    userCharge_00013: "To",
    userCharge_00014: "Start Date",
    userCharge_00015: "End date",
    userCharge_00016: "Platform",
    userCharge_00017: "Wechat official account(Wechat pay)",
    userCharge_00018: "Wechat official account(Alipay)",
    userCharge_00019: "Other",
    userCharge_00020: "",
    userCharge_00021: "",
    userCharge_00022: "",
    userCharge_00023: "",
    userCharge_00024: "",
    userCharge_00025: "",
    userCharge_00026: "",
    userCharge_00027: "",
    userCharge_00028: "",
    userCharge_00029: "",
    userCharge_00030: "",
  }