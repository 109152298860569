export default {
    

    manageUsers_00001: 'Gruppenmitgliederverwaltung',
    manageUsers_00002: "Gruppenname",
    manageUsers_00003: "WeChat-Benutzermitglied",
    manageUsers_00004: "Mitglieder mit Ladekarte",
    manageUsers_00005: "Gruppenmitglieder hinzufügen",
    manageUsers_00006: "Gruppenmitglieder exportieren",
    manageUsers_00007: "Nickname/Telefonnummer des Benutzers",
    manageUsers_00008: "Anfragen",
    manageUsers_00009: "Bestellung prüfen",
    manageUsers_00010: "Entfernen",
    manageUsers_00011: "Benutzerbild",
    manageUsers_00012: "Spitzname",
    manageUsers_00013: "Geschlecht",
    manageUsers_00014: "Telefonnummer",
    manageUsers_00015: "Nummernschild",
    manageUsers_00016: "arbeiten",
    manageUsers_00017: "Kartennummer",
    manageUsers_00018: "Nutzername",
    manageUsers_00019: "Vorgang abgebrochen",
    manageUsers_00020: "Möchten Sie [{nickname}] wirklich aus der Gruppe löschen?",
    manageUsers_00021: "Der Benutzer hat auch ein Gruppenzuweisungssaldo von {num} Yuan, das nach dem Löschen automatisch vom System wiederhergestellt wird.",
    manageUsers_00022: "[{nickname}] Fehler beim Löschen aus der Benutzergruppe! Der Benutzer hat eine nicht abgeschlossene Bestellung und kann das seiner Benutzergruppe zugewiesene Guthaben von {num} Yuan nicht abheben.",
    manageUsers_00023: "Erfolgreich eingestellt",
    manageUsers_00024: "Dieser Vorgang lädt die Excel-Datei des Gruppenbenutzers herunter. Möchten Sie fortfahren?",
    manageUsers_00025: "Hinweis",
    manageUsers_00026: "Starte Download",
    manageUsers_00027: "Download abbrechen",
    manageUsers_00028: "Bitte geben Sie Ihren Spitznamen, Ihre Mobiltelefonnummer, Ihre Kartennummer und mehrere unterstützte Abfragebedingungen ein, getrennt durch Komma (,), Zeilenvorschub, Leerzeichen und Komma (,).",
    manageUsers_00029: "Klar",
    manageUsers_00030: "Suchergebnis",
    manageUsers_00031: "hinzugefügt",
    manageUsers_00032: "Hinzufügen",
    manageUsers_00033: "Keine Berechtigung zum Anzeigen!",
    manageUsers_00034: "Bitte geben Sie den Spitznamen, die Handynummer oder die Kartennummer ein, die Sie anfragen möchten",
  }