export default {
  report_00001: "整体",
  report_00002: "直营",
  report_00003: "加盟商",
  report_00004: "场站",
  report_00005: "{0} 第 {1} 周",
  report_00006: "环比",
  report_00007: "收入趋势",
  report_00008: "服务费来源金额占比",
  report_00009: "不含赠金",
  report_00010: "充电量 (度)",
  report_00011: "充电次数（次）",
  report_00012: "充电时长（小时）",
  report_00013: "充电用户数（人）",
  report_00014: "首次充电用户数（人）",
  report_00015: "车主钱包充值（{0}）",
  report_00016: "车主钱包退款（{0}）",
  report_00017: "团体账户充值（{0}）",
  report_00018: "充电卡账户充值（{0}）",
  report_00019: "服务费",
  report_00020: "经营效率",
  report_00021: "利用率",
  report_00022: "按时长",
  report_00023: "按电量",
  report_00024: "直流设备利用率(%)",
  report_00025: "交流设备利用率(%)",
  report_00026: "比同城",
  report_00027: "比同行业",
  report_00028: "枪均充电量（度）",
  report_00029: "时间范围和场站范围内开始充电的充电订单总充电量/场站范围内的总枪口数。",
  report_00030: "枪均充电次数（次）",
  report_00031: "时间范围和场站范围内开始充电的充电订单总订单数/场站范围内的总枪口数。",
  report_00032: "枪均服务费（{0}）",
  report_00033: "时间范围和场站范围内的充电完成的充电订单总服务费/场站范围内的总枪口数。",
  report_00034: "度均服务费（{0}/度）",
  report_00035: "时间范围和场站范围内的完成充电的充电订单，总服务费收入/总充电量。",
  report_00036: "维保回顾",
  report_00037: "在线率",
  report_00038: "桩责异常率",
  report_00039: "营销回顾",
  report_00040: "卡券销售金额（{0}）",
  report_00041: "特权会员销售金额（{0}）",
  report_00042: "邀请好友",
  report_00043: "储值送返活动",
  report_00044: "自由领取活动",
  report_00045: "卡券销售额",
  report_00046: "发起邀请次数（次）",
  report_00047: "邀请人现金奖励（{0}）",
  report_00048: "发起邀请人数（人）",
  report_00049: "邀请人卡券奖励（张）",
  report_00050: "接受邀请人数（人）",
  report_00051: "接受邀请人现金奖励（{0}）",
  report_00052: "完成首次充电邀请人数（人）",
  report_00053: "接受邀请人卡券奖励（张）",
  report_00054: "充值金额（{0}）",
  report_00055: "赠送金额（{0}）",
  report_00056: "充值次数（次）",
  report_00057: "充值人数（人）",
  report_00058: "赠送卡券数（张）",
  report_00059: "领取次数（次）",
  report_00060: "领取人数（人）",
  report_00061: "特权会员金额",
  report_00062: "用户回顾",
  report_00063: "第三方流量",
  report_00064: "第三方流量充电量（度）",
  report_00065: "第三方流量收入（{0}）",
  report_00066: "第三方流量充电次数（次）",
  report_00067: "个人",
  report_00068: "个人充电量（度）",
  report_00069: "个人充电收入（{0}）",
  report_00070: "个人充电次数（次）",
  report_00071: "团体",
  report_00072: "团体充电量（度）",
  report_00073: "团体充电收入（{0}）",
  report_00074: "团体充电次数（次）",
  report_00075: "充电量",
  report_00076: "度",
  report_00077: "直流设备利用率",
  report_00078: "交流设备利用率",
  report_00079: "经营成果",
  report_00080: "经营效率",
  report_00081: "营销回顾",
  report_00082: "用户回顾",
  report_00083: "维保回顾",
  report_00084: "利用率(按时长)=在时间范围和场站范围内的总充电时长/场站范围内的充枪口数*时间范围时长",
  report_00085: "利用率(按电量)=在时间范围和场站范围内的总充电量/场站范围内的充电桩额定功率之和*时间范围时长",
  report_00086: "",
  report_00087: "",
  report_00088: "",
  report_00089: "",
  report_00090: "",
};
