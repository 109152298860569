export default {
    powerAllocate_00001: "Leistungverteilung",
    powerAllocate_00002: "Erfolgreich geändert. Bitte gehen Sie zurück zu 'Leistungverteilung' und richten die Configuration an die Ladesäule ein!",
    powerAllocate_00003: "Max. eine Nachkommastelle für Maximalladedauer erlaubt",
    powerAllocate_00004: "Einstellung der Gesamtleistungsgrenze",
    powerAllocate_00005: "Zeitraum zufügen",
    powerAllocate_00006: "(Wert auf 0 setzen, falls keine Leistungsbegrenzung erforderlich ist)",
    powerAllocate_00007: "Bitte erstellen Sie einen Gruppennamen",
    powerAllocate_00008: "bitte vollständigen Sie die Einstellungen für das Leistungslimit in der Gruppe !",
    powerAllocate_00009: "Zugewiesene Gesamtleistung(kW)",
    powerAllocate_00010: "Tatsächliche Gesamtladeleistung(kW)",
    powerAllocate_00011: "Begrenzung der Gesamtleistung(kW)",
    powerAllocate_00012: 'Gruppenname',
    powerAllocate_00013: 'Basisinformationen',
    powerAllocate_00014: 'ob aktivieren',
    powerAllocate_00015: 'Die Maximalleistung eines Standorts kann für verschiedenen Zeiten gesetzt werden, um eine Leistungsbegrenzung in der jeweiligen Zeit zu erreichen.',
    powerAllocate_00016: "Einstellung der Gesamtleistungsgrenze",
    powerAllocate_00017: "Einstellung der reservierten Ladeleistung und Maximalladedauer",
    powerAllocate_00018: "Maximalleistung(kW)",
    powerAllocate_00019: "Entschuldigung, bitte vollständigen Sie die Einstellungen für das Leistungslimit in der Gruppe !",
    powerAllocate_00020: "Zugewiesene Gesamtleistung(kW)",
    powerAllocate_00021: "Tatsächliche Gesamtladeleistung(kW)",
    powerAllocate_00022: "Begrenzung der Gesamtleistung(kW)",
    powerAllocate_00023: "Die reservierte Ladeleistung und die Maximalladedauer können entsprechend dem Produktmodell der Ladestation eingestellt werden.",
    powerAllocate_00024: "Reservierte Ladeleistung: Die Ladeleistung, die für die noch verfügbaren Ladepunkten automatische reserviert wird. Der Standardwert beträgt 0kW, d.h. es wird keine Ladeleistung für sie reserviert.",
    powerAllocate_00025: "Maximalladedauer: Die maximale Ladedauer der Ladepunkte, wenn die Plattform automatisch Ladeleistung zuweist. Standardeinstellung beträgt 24 Stunden",
    powerAllocate_00026: "Stellen Sie die reservierte Leistung ein (Offline-Ladeleistung).",
    powerAllocate_00027: "Produktmodell",
    powerAllocate_00028: "Max. Leistung des LP (kW)",
    powerAllocate_00029: "Min. Leistung des LP (kW)",
    powerAllocate_00030: "Reservierte Leistung (kW)",
    powerAllocate_00031: "Max. Ladedauer (h)",
    powerAllocate_00032: "Übersicht der max. Standortladeleistung",
    powerAllocate_00033: "Letzten 300 Tagen",
    powerAllocate_00034: "Letzten 24h",
    powerAllocate_00035: "Noch keine Daten vorhanden",
    powerAllocate_00036: "Bitte korrektes Zahlenformat verwenden",
    powerAllocate_00037: "Bitte die reservierte Ladeleistung eingeben, die zwischen der Minimalleistung und der Maximalleistung des Ladepunkts liegen soll.",
    powerAllocate_00038: "Reservierte Ladeleistung >= 0",
    powerAllocate_00039: "Max. zwei Nachkommastellen erlaubt",
    powerAllocate_00040: "Max. Ladedauer > 0",
    powerAllocate_00041: "Die Zeitperioden für Leistungsbegrenzung sollten zwischen 00:00 und 24:00 liegen",
    powerAllocate_00042: "Max. Standortladeleistung >= 0",
    powerAllocate_00043: "Max. zwei Nachkommastellen erlaubt",
    powerAllocate_00044: "Details der Station",
    powerAllocate_00045: "Festlegen der leistungsverteilung innerhalb der gruppe",
    powerAllocate_00046: "Es kann nur aktiviert werden, wenn es nicht aktiviert ist",
    powerAllocate_00047: "Ladeklappen innerhalb der gruppe",
    powerAllocate_00048: "Die summe der Max [en] ladefäden aller art innerhalb der gruppe muss kleiner sein als der minimale wert, der der maximale ladeleistung innerhalb der gruppe entspricht",

}