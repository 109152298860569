export default {
    minProgramBanner_00001: "homepage banner",
    minProgramBanner_00002: "Home Banner aktiviert",
    minProgramBanner_00003: "Preview",
    minProgramBanner_00004: "Bannerbild",
    minProgramBanner_00005: "Sprung page",
    minProgramBanner_00006: "Anzeigereihenfolge",
    minProgramBanner_00007: "Standardbild auswählen",
    minProgramBanner_00008: "Verstärkung",
    minProgramBanner_00009: "modifizieren",
    minProgramBanner_000010: "click to upload pictures",
    minProgramBanner_000011: "Hilfe einstellen",
    minProgramBanner_000012: "1. Banner kann bis zu acht Seiten addieren. Die Anzeigezeit jedes Bannerbildes beträgt 3 Sekunden, und es wird nacheinander entsprechend der Sequenznummer angezeigt;",
    minProgramBanner_000013: "2. Wenn Sie einen Sprunglink gesetzt haben, klicken Sie darauf, um zur Seite zu springen, die Sie gesetzt haben; wenn Sie keinen Sprunglink setzen, werden nur Bilder angezeigt",
    minProgramBanner_000014: "3. Das hochgeladene Bild unterstützt nur Bilder im PNG- und JPG-Format und die Größe beträgt nicht mehr als 5m. Die empfohlene Größe beträgt 690 bis 260px (8:3). Wenn die Größe oder Skalierung des hochgeladenen Bildes nicht konsistent ist, wird es für die Anzeige gestreckt",
    minProgramBanner_000015: "Aktivitätstyp auswählen",
    minProgramBanner_000016: "Bitte fügen Sie Ihren offiziellen Account Artikel Link, wechat öffentliche Plattform: https://mp.weixin.qq.com/ ",
    minProgramBanner_000017: "Es können bis zu 8 Banner gesetzt werden",
    minProgramBanner_000018: "Bitte setzen Sie Bannerbild",
    minProgramBanner_000019: "please set the activity ID",
    minProgramBanner_000020: "Upload",
    minProgramBanner_000021: "kein Sprung",
    minProgramBanner_000022: "aktive Seite",
    minProgramBanner_000023: "offizieller Kontolink",
    minProgramBanner_000024: "Rückgabe des gespeicherten Werts",
    minProgramBanner_000025: "kostenlose Sammlung",
    minProgramBanner_000026: "Freunde einladen",
    minProgramBanner_000027: "Kartencoupon kaufen",
    minProgramBanner_000028: "Please set the jump page",
    minProgramBanner_000029: "Name der Tätigkeit",
    minProgramBanner_000030: "Aktivität auswählen",
    minProgramBanner_000031: "New Banner",
    
}