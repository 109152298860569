<template lang="html">
  <el-dialog :title="dialogTitle" :visible.sync="visible" :lock-scroll='false' :before-close="close" width="580px">
    <div class="page" v-if="!tag">
      <el-radio class="radio" v-model="outsideOwnerChargeDisabledData" :label="false">{{$t('diversionDetail_00068')}}</el-radio>
      <el-radio class="radio" v-model="outsideOwnerChargeDisabledData" :label="true">{{$t('diversionDetail_00069')}}</el-radio>
    </div>
    <div v-if="tag" class="close-site">
      <p>{{$t('diversionDetail_00124',{name})}}</p>
      <!-- <p>{{`关闭开放给${name}站点信息，${name}将不会显示任何站点信息。`}}</p> -->
      <p>{{$t('diversionDetail_00125',{name})}}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">{{$t('diversionDetail_00084')}}</el-button>
     <el-button type="primary" @click="save">{{$t('diversionDetail_00091')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  requestUrl,
  ajax,
  formmatTime,
  getQueryString,
} from "@/assets/utils/network";
export default {
  data() {
    return {
      dialogTitle: "",
      name: "",
      outsideOwnerChargeDisabledData: "",
    };
  },
  props: ["tag", "visible", "id", "outsideOwnerChargeDisabled"],
  created() {
    if (!this.tag) {
      this.dialogTitle = this.$t("diversionDetail_00092");
      this.outsideOwnerChargeDisabledData = this.outsideOwnerChargeDisabled;
    } else {
      this.dialogTitle = this.$t("diversionDetail_00089");
      this.name = this.tag.name;
    }
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    save() {
      let om = this;
      if (this.tag) {
        ajax({
          type: "DELETE",
          url: requestUrl + "/outsideowner/devicegroup?id=" + this.tag.delId,
          dataType: "json",
          success: function (response) {
            om.$parent.upDateSite();
            om.close();
          },
        });
      } else {
        const data = {
          id: this.id,
          outsideOwnerChargeDisabled: this.outsideOwnerChargeDisabledData,
        };
        ajax({
          type: "POST",
          url: requestUrl + "/outsideowner/devicegroup",
          dataType: "json",
          data: JSON.stringify(data),
          success: function (response) {
            var info = JSON.parse(response);
            if (info.error != null) {
              if (info.error.code == "NOT_LOGIN") {
                window.location.href = "./login.html?l=0";
              }
              om.$message.error(
                info.error.msg == null ? info.error.code : info.error.msg
              );
            } else {
              om.$message.success(om.$t("diversionDetail_00073"));
              om.close();
              om.$emit("confirm");
            }
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b2330;
  flex-direction: column;
  .radio:first-of-type {
    margin-bottom: 20px;
    margin-right: 0;
  }
}
.close-site {
  text-align: center;
  color: #1b2330;
  p:first-of-type {
    margin-bottom: 20px;
  }
}
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.el-button + .el-button {
  margin-left: 20px;
}
</style>
