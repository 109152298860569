export default {
  cash_coupon0001: 'Marketing-Zentrum',
  cash_coupon0002: 'Angebote-Instrumente',
  cash_coupon0003: 'neu erstellen',
  cash_coupon0004: 'Einzelheiten',
  cash_coupon0005: 'ändern',
  cash_coupon0006: 'Information',

  cash_coupon0007: 'abbrechen',
  cash_coupon0008: 'speichern',
  cash_coupon0009: 'löschen',

  cash_coupon0010: 'Bargeld als Strom und Servicebetrag',
  cash_coupon0011: 'Elektrizität als Stom und Servicebetrag',
  cash_coupon0012: 'Bargeld als Servicebetrag',
  cash_coupon0013: 'Elektrizität als Servicebetrag',
  cash_coupon0014: 'Bitte geben Sie eine Zahl mit bis zu zwei Dezimalstellen ein',
  cash_coupon0015: 'relativ',
  cash_coupon0016: 'absolut',
  cash_coupon0017: 'ab den Benutzererfassungstag',
  cash_coupon0018: 'Bitte geben Sie eine natürliche Zahl ein',
  cash_coupon0019: 'Tag',
  cash_coupon0020: 'bis',
  cash_coupon0021: 'verfügbare Ladestation', 
  cash_coupon0022: 'Ladestationsname',
  cash_coupon0023: 'suchen',
  cash_coupon0024: 'Massenweise entfernen',
  cash_coupon0025: 'Aktion',
  cash_coupon0026: 'entfernen',
  cash_coupon0027: 'Elektrizitätspaket',
  cash_coupon0028: 'Gutschein',
  cash_coupon0029: 'nicht wiederherstellbar',
}