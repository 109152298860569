export default {
  userAllocate_00001: "团体名称",
  userAllocate_00002: "余额分配",
  userAllocate_00003: "基本信息",
  userAllocate_00004: "用户昵称、卡号、手机号",
  userAllocate_00005: "用户类型",
  userAllocate_00006: "全部",
  userAllocate_00007: "微信用户",
  userAllocate_00008: "在线身份卡",
  userAllocate_00009: "查询",
  userAllocate_00010: "重置",
  userAllocate_00011: "已为{0}人分配{1}{3}，剩余{2}{3}",
  userAllocate_00012: "确定分配",
  userAllocate_00013: "头像",
  userAllocate_00014: "用户(手机号)",
  userAllocate_00015: "卡号",
  userAllocate_00016: "类型",
  userAllocate_00017: "账户余额({0})",
  userAllocate_00018: "分配金额({0})",
  userAllocate_00019: "用户列表",
  userAllocate_00020: "请为用户组成员输入分配金额！",
  userAllocate_00021: "用户组账户余额不足，无法分配！",
  userAllocate_00022: "提示",
  userAllocate_00023: "继续分配",
  userAllocate_00024: "确定",
  userAllocate_00025: "",
}