export default {
  userDetail_00001: "Aktualisieren von WeChat-Nachrichten",
  userDetail_00002: "Männlich",
  userDetail_00003: "Weiblich",
  userDetail_00004: "Ändern",
  userDetail_00005: "Speichern",
  userDetail_00006: "Abbrechen",
  userDetail_00007: "Benutzer WeChat OpenID",
  userDetail_00008: "Beitrittszeit",
  userDetail_00009: "Öffentliche Konto",
  userDetail_000010: "Gefolgt",
  userDetail_000011: "nicht gefolgt",
  userDetail_000012: "Letzte Ladezeit",
  userDetail_000013: "WeChat Pay Zahlung ohne Passwörte",
  userDetail_000014: "berechtigt",
  userDetail_000015: "Nicht berechtigt",
  userDetail_000016: "Gebundene Fahrzeug-VIN",
  userDetail_000017: "Aufladung",
  userDetail_000018: "Guthaben der Geldbörse",
  userDetail_000019: "EUR",
  userDetail_000020: "Aufladungsverlauf anzeigen",
  userDetail_000021: "Erstattungsverlauf anzeigen",
  userDetail_000022: "Akzeptieren Sie die Ladebeschränkungen",
  userDetail_000023: "Benutzer-Fahrzeuge",
  userDetail_000024: "Modell",
  userDetail_000025: "Kfz-Kennzeichen",
  userDetail_000026: "Gruppen beigetreten",
  userDetail_000027: "Ladedaten für die letzten 30 Tage",
  userDetail_000028: "Bitte speichern Sie den vorherigen Vorgang!!!" ,
  userDetail_000029: "Die eingegebene Mobiltelefonnummer kann nicht leer sein",
  userDetail_000030: "Das eingegebene Kennzeichen kann nicht leer sein",
  userDetail_000031: "Bitte geben Sie das richtige Format ein: Fahrzeugmarke|Modell",
  userDetail_000032: "Bitte wählen Sie die Marke oder das Modell des Fahrzeugs",
  userDetail_000033: "Erfolgreich modifiziert",
  userDetail_000034: "Ladeanzahl",
  userDetail_000035: "Ladestrom",
  userDetail_000036: "Ladekosten",
  userDetail_000037: "",
  userDetail_000038: "kWh",
  userDetail_000039: "Marke auswählen",
  userDetail_000040: "Modell auswählen",
  userDetail_000041: "Automarke|Modell",
  userDetail_000042: "Manuell eingeben",
  userDetail_000043: "Normale Benutzer",
  userDetail_000044: "Upgrade auf {titel} ist zu kurz für {num}",
  userDetail_000045: "Die Aktualisierung auf {Titel} fehlt noch {Nummer} ",
  userDetail_000046: "Keine Abstiegsaufgabe erforderlich",
  userDetail_000047: "Geschenkbetrag",
  userDetail_000048: "Einheiten",
  userDetail_000049: "Anmeldekennwort",
  userDetail_000050: "Erforderlich",
  userDetail_000051: "Bitte geben Sie Ihr Passwort ein",
  userDetail_000052: "Bemerkung",
  userDetail_000053: "Erforderlich, nicht mehr als 20 Zeichen",
  userDetail_000054: "Bitte Inhalt eingeben",
  userDetail_000055: "Bestätigen",
  userDetail_000056: "Zur schwarzen Liste der Benutzer hinzugefügt",
  userDetail_000057: "Benutzerzentrum",
  userDetail_000058: "Grundlegende Informationen",
  userDetail_000059: "Kartenbelege im Besitz",
  userDetail_000060: "Ladevorgang suchen",
  userDetail_000061: "Belegungsaufträgen suchen",
  userDetail_000062: "Zur schwarzen Liste der Benutzer hinzufügen",
  userDetail_000063: "Operation erfolgreich",
  userDetail_000064: "Mitgliedschaftsstufe",
  userDetail_000065: "Niveau anpassen",
  userDetail_000066: "Offen",
  userDetail_000067: "Aus",
  userDetail_000068: "Laden Sie Ihr Konto auf",
  userDetail_000069: "Mitgliedsstufe anpassen",
  userDetail_000070: "Geben Sie bis zu 20 Zeichen für Notizen ein",
  userDetail_000071: "Bitte geben Sie einen Kommentar ein",
  userDetail_000072: "Bitte geben Sie den Aufladebetrag oder Bonusbetrag ein",
  userDetail_000073: "Bitte geben Sie den richtigen Betrag für das Geschenk ein",
  userDetail_000073: "Bitte geben Sie den richtigen Aufladebetrag ein",
  userDetail_000074: "Zur schwarzen Liste der Benutzer hinzufügen",
  userDetail_000075: "Nach dem Beitritt zur Benutzer-Blacklist können das Mikrosignal und die Handynummer des Benutzers sowie andere Mikrosignale, die der gleichen Handynummer entsprechen, das Fahrzeug nicht mehr aufladen!" ,
  userDetail_000076: "Enddatum",
  userDetail_000077: "Grund für den Beitritt",
  userDetail_000078: "Datum auswählen",
  userDetail_000079: "Bitte geben Sie den Grund für die Aufnahme in die schwarze Liste an (erforderlich)",
  userDetail_000080: "Hinzufügen,",
  userDetail_000081: "Der Grund kann nicht leer sein!",
  userDetail_000082: "Enddatum darf nicht leer sein!",
  userDetail_000083: "Enthält ein Geschenk von {num}EUR",
  userDetail_000084: "Umfasst die Gruppenzuweisung von {num}{unit}EUR",
  userDetail_000085: "Benutzer",
  userDetail_000086: "Benutzerverwaltung",
  userDetail_000087: "Administrator",
  userDetail_000088: "VIN abbinden",
  userDetail_000089: "Kommentare zum Spitznamen",
  userDetail_000090: "Der eingegebene Spitznamen-Kommentar kann nicht leer sein",
  userDetail_000091: "Bitte geben Sie Ihre Handynummer an",
  userDetail_000092: "Keine Mobiltelefonnummer ist gebunden",
  userDetail_000093: "Keine Gruppen beigetreten",
  userDetail_000094: "Bitte geben Sie Ihr Passwort ein",
  userDetail_000095: "Bitte geben Sie einen Kommentarnamen mit weniger als 10 Zeichen ein",
  userDetail_000096: "Bitte geben Sie eine Notiz mit bis zu 10 Zeichen ein",
  userDetail_000097: "Quelle des Benutzers",
  userDetail_000098: "Status",
  userDetail_000099: "Name",
  userDetail_000100: "Gültigkeitsdatum",
  userDetail_000101: "Erfassungsmethode",
  userDetail_000102: "Erfassungszeit",
  userDetail_000103: "Aktion",
  userDetail_000104: "Gutscheindetails",
  userDetail_000105: "Details zum Rabattgutschein",
  userDetail_000105: "Details zum Rabattgutschein",
  userDetail_000106: "Ereignisdetails",
  userDetail_000107: "Guthaben",
  userDetail_000108: "Elektrizitätspaket Einzelheit",
  userDetail_000109: "Gutscheindetails",
  userDetail_000110: "Coupon",
  userDetail_000111: "Rabatt-Gutscheine",
  userDetail_000112: "Elektrizitätspaket", 
  userDetail_000113: "Coupon",
  userDetail_000114: "Alle",
  userDetail_000115: "Zu validieren",
  userDetail_000116: "Available",
  userDetail_000117: "Aufgebraucht",
  userDetail_000118: "Abgelaufen",
  userDetail_000119: "Laden Sie einen Freund ein: Einladungsbelohnung",
  userDetail_000120: "Einen Freund einladen: Belohnung für das Annehmen einer Einladung",
  userDetail_000121: "kWh",
  userDetail_000122: "Kaufen",
  userDetail_000123: "Einlösen",
  userDetail_000124: "Die junior high ist beendet",
  userDetail_000135: "Loslöschung von Karten und Gutscheinen",
}