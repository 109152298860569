export default {
    maintenanceHome_00001: "当前故障设备数",
    maintenanceHome_00002: "当前离线设备数",
    maintenanceHome_00003: "未关闭工单数",
    maintenanceHome_00004: "电源模块保养设备数",
    maintenanceHome_00005: "模块寿命保养设备数",
    maintenanceHome_00006: "查看",
    maintenanceHome_00007: "场站名称",
    maintenanceHome_00008: "实时监控",
    maintenanceHome_00009: "今日桩责异常率",
    maintenanceHome_000010: "今日车责异常率",
    maintenanceHome_000011: "异常率分析",
    maintenanceHome_000012: "在线率分析",

    
}