export default {
    userRanking_00001: '累计实付金额(元)',
    userRanking_00002: '累计充电量(度)',
    userRanking_00003: '累计订单数',
    userRanking_00004: '导出用户',
    userRanking_00005: '排行列表',
    userRanking_00006: '全部省',
    userRanking_00007: '全部市',
    userRanking_00008: '统计订单类型',
    userRanking_00009: '团体(仅微信启动)',
    userRanking_00010: '排行条件',
    userRanking_00011: '添加条件',
    userRanking_00012: '仅排除“电量小于1度”的充电订单',
    userRanking_00013: '排行规则：按照所选条件的先后顺序权重依次降低，所有条件都是根据由小到大筛选',
    userRanking_00014: '请选择排行条件',
    userRanking_00015: '',
    userRanking_00016: '',
    userRanking_00017: '',
    userRanking_00018: '',
};
