<template>
  <div>
    <el-dialog
      :title="$t('common_46018')"
      width="900px"
      :lock-scroll="false"
      :visible="visible"
      @close="close"
    >
      <div class="active-list">
        <div class="flex-container margint15 padding15 radius">
          <div class="flex-container-left">
              <div class="flex-container-left text-info">
                  <el-input v-model="activityName"  class="text-info" size="medium" style="width: 180px;margin-right: 20px;" :placeholder="$t('common_46008')"></el-input>
                  <el-button icon="el-icon-search"  size="medium" type="primary" @click="search">{{ $t('common_00255') }}</el-button>
              </div>
              <div class="flex-container-left text-info" style="margin:20px 0;">
                  <label class="fs14 marginr15" >{{ $t('common_46009') }}:  </label>
                  <el-radio-group v-model="statusType">
                      <el-radio label="all">{{$t('common_46016')}}</el-radio>
                      <el-radio label="0">{{$t('common_46012')}}</el-radio>
                      <el-radio label="1">{{$t('common_46013')}}</el-radio>
                      <el-radio label="2">{{$t('common_46014')}}</el-radio>
                      <el-radio label="3">{{$t('common_46015')}}</el-radio>
                  </el-radio-group>
              </div>
          </div> 
      </div>
      <div class="e-table">
        <el-table
          :key="toogleIndex"
          :header-cell-style="{height: '54px',fontSize: '14px',background:'#EFF2F6',color:'#1B2330',borderRadius: '4px 4px 0px 0px'}"
          :data="tableData"
          highlight-current-row
          @current-change="handleCurrentChangeRadio">
          <el-table-column width="70">
            <template slot-scope="scope">
              <el-radio class="radio" v-model="currentRow" :label="scope.row">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common_46008')" prop="title"></el-table-column>
          <el-table-column :label="$t('common_46010')" prop="id" width="230"></el-table-column>
          <el-table-column :label="$t('common_46011')" prop="time" width="230"  :formatter="time"></el-table-column>
          <el-table-column :label="$t('common_46009')" prop="status" :formatter="status">
              <template slot-scope="scope">
                  <span :class="status(scope.row, 1)">{{status(scope.row)}}</span>
              </template>
          </el-table-column>
          <el-table-column :label="$t('common_00282')" align="center">
              <template slot-scope="scope">
                  <a class="text"  :href="'./addStoredValue.html?id='+ scope.row.id +'&tag=' + tag.slice(1)"  :target="isOpenInCurrentPage() && '_blank'">
                    <el-button class="fw400" type="text">{{$t('common_00229')}}</el-button>
                  </a>
              </template>
          </el-table-column>
        </el-table>
    </div>
    <div class="flex-container-center">
        <el-pagination
            class="margint30 marginb30"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="total, prev, pager, next, jumper"
            :total="dataNum">
        </el-pagination>
    </div>
      <el-button  size="medium" type="primary" @click="submitActive">{{ $t('common_00234') }}</el-button>
      </div>
        
    </el-dialog>
  </div>
</template>

<script>
import { formmatTime } from "@/assets/utils/time";


import {
  requestUrl,
  ajax,
} from "@/assets/utils/network";
export default {
  props: {
    tag: {
      type: String,
      default: "#value",
    },
  },
  computed:{
      typeMap() {
          return 1 ? this.$t('stored_00020'):this.$t('stored_00021')
      }
  },
  data() {
    return {
      toogleIndex: 1,
      activityName:"",
      statusType:"all",
      tableData:[],
      currentPage: 1,
      dataNum: 10,
      val:'',
      visible:true,
      loading:false,
      // tag: '#value',
      params:[],
      currentRow:"",
      tagMap:{
        '#value': '1,2,3',
        '#free': '4',
        '#charge': '9',
      },
    };
  },
  mounted() {
    var type = this.tagMap[this.tag];
    // this.tag === '#value' ? type ='1,2,3':type ='4'
    var refundStats = requestUrl + "/promotion?type="+ type + "&from=0&length=10&returnCount=true";
    this.getPromotionList(refundStats);
  },
  methods: {
    close (){
      this.$emit('close')
    },
    handleCurrentChangeRadio(val) {
      if(val) {
        this.currentRow = val;
        console.log(val)
      }
    },
    submitActive () {
      if(!this.currentRow){
        return;
      }
      this.$emit('confirm',this.currentRow)
    },
    
    formmatTime(time) {
        return formmatTime(time + "", false);
    },
    time(row) {
      if (row.startTime && row.endTime) {
          return (
              this.formmatTime(row.startTime) +
              " - " +
              this.formmatTime(row.endTime)
          );
      }
      return "-";
    },
    status(row, type) {
            if(type) {
                if (row.status == 0) {
                    return 'bag-FFA00E'
                }
                if (row.status == 1) {
                    return 'bag-00B99C'
                }
                if (row.status == 2) {
                    return 'bag-FA545E'
                }
                if (row.status == 3) {
                    return 'bag-838AA2'
                }
            } else {
                if (row.status == 0) {
                    return this.$t('common_46012');
                }
                if (row.status == 1) {
                    return this.$t('common_46013');
                }
                if (row.status == 2) {
                    return this.$t('common_46014');
                }
                if (row.status == 3) {
                    return this.$t('common_46015');
                }
                return '-';
            }

        },
    search() {
        this.params['title'] = this.activityName;
        this.params['status'] = this.statusType === 'all' ? '' : this.statusType;
        var queryString = "";
        var om = this;
        Object.keys(this.params).forEach(function(key){
            queryString += "&" + key + "=" + om.params[key];
        });
        let type =this.tagMap[this.tag]
        // this.tag === '#value' ? type ='1,2,3':type ='4'
        var url = requestUrl + "/promotion?type="+ type + "&from="+(parseInt(1) - 1)*10+"&length=10&returnCount=true" + queryString;
        this.getPromotionList(url);
    },
    handleCurrentChange(val) {
        this.loading = true;
        var page = val;
        if (this.currentPage == page) {
            return;
        }
        this.currentPage = page;
        var queryString = "";
        var om = this;
        Object.keys(this.params).forEach(function(key){
            queryString += "&" + key + "=" + om.params[key];
        });
        let type =this.tagMap[this.tag]
        // this.tag === '#value' ? type ='1,2,3':type='4'
        var url = requestUrl + "/promotion?type="+ type + "&from=" + (parseInt(page) - 1) * 10 + "&length=10&returnCount=true" + queryString;
        this.getPromotionList(url);
    },
    getPromotionList(url) {
      var om = this;
      ajax({
          type: "GET",
          url: url,
          dataType: "json",
          success: function(response) {
              setTimeout(function() {
                  om.loading = false;
              }, 200);
              om.tableData = JSON.parse(response).result;
              for (var i = 0; i < om.tableData.length; i++) {
                  om.tableData[i].showInWechat = om.tableData[i].showInWechat &&  om.tableData[i].showInWechat === 1;
              }
              console.log(om.tableData,'om.tableData')
              om.dataNum = JSON.parse(response).totalCount;
          }
      });
    },
  },
};
</script>

<style lang="scss">
.active-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .a-items{
    width: 340px;
    height: 196px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #E6E7EC;
    margin-bottom: 30px;
    box-sizing: border-box;
    color: #1B2330;
    cursor: pointer;
    &.active{
      color:#5278E2;
      border: 2px solid #5278E2;

    }
    p{
      margin-bottom: 10px;
      color: #1B2330;
    }
  }
  .e-table{
    width: 100%;
    height: 320px;
    overflow-y: scroll;
    margin-bottom: 10px;
  }
}

</style>
