export default {
  energyStoragePileManagement_00001: 'Besetzt',
  energyStoragePileManagement_00002: 'Nicht aktiviert',
  energyStoragePileManagement_00003: 'Offline',
  energyStoragePileManagement_00005: 'Verfügbar',
  energyStoragePileManagement_00006: 'Laden',
  energyStoragePileManagement_00008: 'Reserviert',
  energyStoragePileManagement_00009: 'Kein Fehler',
  energyStoragePileManagement_00010: "Ferngesteuerter Neustart erfolgt",
  energyStoragePileManagement_00011: "Ferngesteuerter Neustart",
  energyStoragePileManagement_00012: "Ferngesteuerter Neustart fehlgeschlagen",
  energyStoragePileManagement_00013: "Gerätverwaltung",
  energyStoragePileManagement_00014: "Aktuelle Störungen",
  energyStoragePileManagement_00015: "Strörungbehebung",
  energyStoragePileManagement_00016: "Bitte geben Sie den Gerätname oder Seriennummer ein.",
  energyStoragePileManagement_00017: "Wählen Sie oder geben Sie den Namen der Station ein.",
  energyStoragePileManagement_00018: "Alle",
  energyStoragePileManagement_00019: "Art der Geräte",
  energyStoragePileManagement_00020: "DC",
  energyStoragePileManagement_00021: "AC",
  energyStoragePileManagement_00022: "Gateway",
  energyStoragePileManagement_00023: "Störung",
  energyStoragePileManagement_00024: "Offline",
  energyStoragePileManagement_00025: "Verfügbar",
  energyStoragePileManagement_00026: "Anzeigen eingesteckte Pistole",
  energyStoragePileManagement_00027: "Im Einsatz",
  energyStoragePileManagement_00028: "Anzeigen ausgesteckte Pistole",
  energyStoragePileManagement_00029: "Offline",
  energyStoragePileManagement_00030: "Nicht aktiviert",
  energyStoragePileManagement_00031: "Seriennummer des Geräts",
  energyStoragePileManagement_00032: "Hersteller - Modell：",
  energyStoragePileManagement_00033: "Ladestation：",
  energyStoragePileManagement_00034: "Neustarten",
  energyStoragePileManagement_00035: "Information der Geräte",
  energyStoragePileManagement_00036: "Ladepunkt",
  energyStoragePileManagement_00037: "Offline",
  energyStoragePileManagement_00038: "Nicht aktiviert",
  energyStoragePileManagement_00039: "Störung",
  energyStoragePileManagement_00040: "besetzt",
  energyStoragePileManagement_00041: "Im Einsatz",
  energyStoragePileManagement_00042: "Verfügbar",
  energyStoragePileManagement_00043: "Parkplatz besetzt",
  energyStoragePileManagement_00044: "Reserviert",
  energyStoragePileManagement_00045: "Ladespistole ausgesteckt",
  energyStoragePileManagement_00046: "Bitte Ladepsitole auspluggen",
  energyStoragePileManagement_00047: "Quittung anschauen",
  energyStoragePileManagement_00048: "Neustarten des Ladepunkt",
  energyStoragePileManagement_00049: "Der Befehl zum Neustart des Geräts wurde gesendet, der Neustart wird etwa 5 Minuten dauern. Bitte bleiben Sie auf dieser Seite und aktualisieren Sie die Seite nicht.",
  energyStoragePileManagement_00050: "Anmerkungen",
  energyStoragePileManagement_00051: "Zustimmen",
  energyStoragePileManagement_00052: "Gerätverwaltung",
  energyStoragePileManagement_00053: "Aktuelle Störungen",
  energyStoragePileManagement_00054: "Ladepistole einstecken",
  energyStoragePileManagement_00055: "Ladevorgang beenden",
  energyStoragePileManagement_00056: "Nicht aktiviert",
  energyStoragePileManagement_00057: "Verfügbar",
  energyStoragePileManagement_00058: "Störung",
  energyStoragePileManagement_00059: "fahrzeug anschliessen",
  energyStoragePileManagement_00060: "Einschlafen",
  energyStoragePileManagement_00061: "Unklar",
  energyStoragePileManagement_00062: "status des gerätes",
  energyStoragePileManagement_00063: "Störung",
  energyStoragePileManagement_00064: "Anzeigen nur Offline - Geräte ",
  energyStoragePileManagement_00065: "Gerät gefunden",
  energyStoragePileManagement_00066: "Kein Gerät gefunden",
  energyStoragePileManagement_00067: "Fehler",
  energyStoragePileManagement_00068: "Bitte geben Sie den Gerätname oder Seriennummer ein.",
  energyStoragePileManagement_00069: "Aktuelle Arbeitsmode",
  energyStoragePileManagement_00070: "Verfügbar",
  energyStoragePileManagement_00071: "Ladepistole eingesteckt",
  energyStoragePileManagement_00072: "Im Einsatz",
  energyStoragePileManagement_00073: "Bitte Ladepsitole auspluggen",
  energyStoragePileManagement_00074: "Störung",
  energyStoragePileManagement_00075: "Offline",
  energyStoragePileManagement_00076: "Nicht aktiviert",
  energyStoragePileManagement_00077: "Besetzt",
  energyStoragePileManagement_00078: "Energiespeicher  aufladen",
  energyStoragePileManagement_00079: "Energiespeicher  entladen",
  energyStoragePileManagement_00080: "Energiespeicher verfügbar",
  energyStoragePileManagement_00081: "minuten bis vollgeladen ",
  energyStoragePileManagement_00082: " mode",


}