export default {
    detailDepot_00001: 'Station details',
    detailDepot_00002: "Private car",
    detailDepot_00003: "Logistics vehicle",
    detailDepot_00004: "Van",
    detailDepot_00005: "Bus",
    detailDepot_00006: "E-bike",
    detailDepot_00007: "Public",
    detailDepot_00008: "Private",
    detailDepot_00009: "Bus (special)",
    detailDepot_00010: "Sanitation (special)",
    detailDepot_00011: "Logistics (special)",
    detailDepot_00012: "Taxi (special)",
    detailDepot_00013: 'Time-sharing Leasing (Dedicated) (Interconnection Standard Extension)',
    detailDepot_00014: 'Cell sharing (Dedicated) (Interconnection standard Extension)',
    detailDepot_00015: 'Bit (Dedicated) (Interconnection Standard Extension)',
    detailDepot_00016: 'Others',
    detailDepot_00017: 'please choose',
    detailDepot_00018: 'Residence community',
    detailDepot_00019: 'Public institutions',
    detailDepot_00020: 'Organization',
    detailDepot_00021: 'Office building',
    detailDepot_00022: 'Industrial area',
    detailDepot_00023: 'Transportation hub',
    detailDepot_00024: 'Large stylistic community',
    detailDepot_00025: 'City greening',
    detailDepot_00026: 'Large construction parking lot',
    detailDepot_00027: 'Street parking spot',
    detailDepot_00028: 'Intercity Express Service Area',
    detailDepot_00029: 'Please refer to the recommended national standard GB/T 2260-2007',
    detailDepot_00030: 'Edit success',
    detailDepot_00031: 'Incorrect country code format(2 uppercase letters)',
    detailDepot_00032: 'Image modified successfully',
    detailDepot_00033: 'Upload image',
    detailDepot_00034: 'Supports only PNG, JPG, and GIF images. The maximum size is 1 MB.',
    detailDepot_00035: 'Real picture',
    detailDepot_00036: 'Address, street, and house name',
    detailDepot_00037: 'Search',
    detailDepot_00038: 'Name',
    detailDepot_00039: 'Detailed address',
    detailDepot_00040: 'Current position',
    detailDepot_00041: 'Cancel',
    detailDepot_00042: 'Use this location',
    detailDepot_00043: 'Please choose the coordinate of charging staion',
    detailDepot_00044: 'Position selected does not match the address',
    detailDepot_00045: "Notice",
    detailDepot_00046: "I see",
    detailDepot_00047: "Address cannot be found",
    detailDepot_00048: "An unknown error has occurred on the Google map",
    detailDepot_00049: "Depots center",
    detailDepot_00050: "Depot management",
    detailDepot_00051: 'Station information',
    detailDepot_00052: 'Charger management',
    detailDepot_00053: 'Rates set',
    detailDepot_00054: 'Policies',
    detailDepot_00055: 'Traffic open',
    detailDepot_00056: 'Government docking',
    detailDepot_00057: 'Power distribution',
    detailDepot_00058: 'Orderly charging of buses',
    detailDepot_00059: 'Basic information',
    detailDepot_00060: 'Save',
    detailDepot_00061: 'Edit',
    detailDepot_00062: 'Delete station',
    detailDepot_00063: 'Depot name',
    detailDepot_00064: 'Depot position',
    detailDepot_00065: 'Map location',
    detailDepot_00066: 'Area code',
    detailDepot_00067: 'country code',
    detailDepot_00068: 'EV type',
    detailDepot_00069: 'Site type',
    detailDepot_00070: 'Depot contact',
    detailDepot_00071: 'Construction site',
    detailDepot_00072: 'Parking Location',
    detailDepot_00073: 'Number of parking spaces',
    detailDepot_00074: 'Real pictures',
    detailDepot_00075: 'Name',
    detailDepot_00076: 'Telephone',
    detailDepot_00077: 'Email',
    detailDepot_00078: 'Parking Fee',
    detailDepot_00079: 'Charger offline',
    detailDepot_00080: 'Start charging',
    detailDepot_00081: 'Online',
    detailDepot_00082: 'Offline',
    detailDepot_00083: 'Fault',
    detailDepot_00084: 'Charging',
    detailDepot_00085: "All",
    detailDepot_00086: "change into available",
    detailDepot_00087: "Charger is not enabled",
    detailDepot_00088: "Occupying",
    detailDepot_00089: "Language",
    detailDepot_00090: "Time zone",
    detailDepot_00091: "Daylight saving time",
    detailDepot_00092: "AC charger electromagnetic Lock",
    detailDepot_00093: "DC charger electromagnetic Lock",
    detailDepot_00094: "If electromagnetic lock fail",
    detailDepot_00095: "Parking space radar",
    detailDepot_00096: "AC device waiting time ",
    detailDepot_00097: "DC device waiting time",
    detailDepot_00098: "Idle fee charged maximum amount",
    detailDepot_00099: "Time delay charging policy",
    detailDepot_00100: "AC device",
    detailDepot_00101: "DC device",
    detailDepot_00102: "AC device",
    detailDepot_00103: "DC device",
    detailDepot_00104: "Available",
    detailDepot_00105: "Unavailable",
    detailDepot_00106: "Not rechargeable",
    detailDepot_00107: "Charging policy for idle fee",
    detailDepot_00108: " Collecting fee in any case",
    detailDepot_00109: "No charge for ending charging due to pile failure",
    detailDepot_00110: "{num}min",
    detailDepot_00111: "Set to 0 for no limit",
    detailDepot_00112: "Rechargeable",
    detailDepot_00113: "10 minutes",
    detailDepot_00114: "15 minutes",
    detailDepot_00115: "20 minutes",
    detailDepot_00116: "30 minutes",
    detailDepot_00117: "If the device is offline, only users in the whitelist are supported to start charging。",
    detailDepot_00118: "Apply whitelist",
    detailDepot_00119: "Clear whitelist",
    detailDepot_00120: "View Whitelist",
    detailDepot_00121: "No",
    detailDepot_00122: "Yes",
    detailDepot_00123: "Input format error",
    detailDepot_00124: "Negative numbers cannot be entered",
    detailDepot_00125: "Push failed",
    detailDepot_00126: "A total of {allDevice} chargers were applied, {sucDevice} chargers succeeded and {faiDevice} chargers failed",
    detailDepot_00127: "No such charger",
    detailDepot_00128: "{num} chargers",
    detailDepot_00129: "Edit configuration information",
    detailDepot_00130: "C1 charger (4.3)",
    detailDepot_00131: "C1 charger(7寸)",
    detailDepot_00132: "C2 charger",
    detailDepot_00133: "C4 charger",
    detailDepot_00134: "C6 charger",
    detailDepot_00135: "C6s charger",
    detailDepot_00136: "{0} reported fault, fault code: {1}",
    detailDepot_00137: "The maximum amount of time delay charges can only be a number",
    detailDepot_00251: "C9Pro charger",
    zh: "Chinese",
    en: "English",
    de: "German",
    fr: "French",
    detailDepot_00138: "Charger reboot successfully",
    detailDepot_00139: "Remote reboot",
    detailDepot_00140: "Charger reboot failed",
    processing: 'Rebooting in progress',
    detailDepot_00141: "Please select png or jpg format to upload",
    detailDepot_00142: "Up to 6 uploads",
    detailDepot_00143: "The image you have selected is too large, please reselect it",
    detailDepot_00144: "Upload image",
    detailDepot_00145: "Multiple image rotation or mp4 video method, one of two options",
    detailDepot_00146: "Use image rotation",
    detailDepot_00147: "Click to upload",
    detailDepot_00148: "Using the video",
    detailDepot_00149: "5 seconds",
    detailDepot_00150: "10 seconds",
    detailDepot_00151: "15 seconds",
    detailDepot_00152: "Upload up to 6 pictures ",
    detailDepot_00153: "Upload up to 1 video",
    detailDepot_00154: "The video you have selected is too large, please reselect it",
    detailDepot_00155: "Only PNG, JPG, GIF format images are supported, not exceeding {size}M.",
    detailDepot_00156: "Finish",
    detailDepot_00157: "Modified successfully",
    detailDepot_00158: "Modify Location",
    detailDepot_00159: "Please refer to the recommended national standard GB/T 2260-2007 to fill in",
    detailDepot_00160: "Please refer to ISO 3166-1 alpha-2 for completion",
    detailDepot_00161: "Please save the previous operation!",
    detailDepot_00162: "Parameter is empty",
    detailDepot_00163: "Please enter the correct amount (greater than 0)",
    detailDepot_00164: "Date set incorrectly",
    detailDepot_00165: "Please enter the country code",
    detailDepot_00166: "Country code formatting error (2 uppercase letters)",
    detailDepot_00167: "Please select station type",
    detailDepot_00168: "Please enter the number of parking spaces at the station",
    detailDepot_00169: "Error entering the number of parking spaces format",
    detailDepot_00170: "Number of parking spaces exceeds limit (<=10000)",
    detailDepot_00171: "The parking fee description cannot exceed 200 characters",
    detailDepot_00172: "Please enter the correct customer service telephone number",
    detailDepot_00173: "Up to 20 digits",
    detailDepot_00174: "Please select the user type and set the percentage",
    detailDepot_00175: "Please enter a positive integer less than 100",
    detailDepot_00176: "Confirmation",
    detailDepot_00177: "Cancel",
    detailDepot_00178: "Deletion successful",
    detailDepot_00179: "Operation cancelled",
    detailDepot_00180: "Sure to delete this field?",
    detailDepot_00181: "Total {num} sheets",
    detailDepot_00182: "Charging site supports whitelist",
    detailDepot_00183: "Apply results",
    detailDepot_00184: "A total of {allDevice} chargers were applied, {sucDevice} chargers succeeded and {faiDevice} chargers failed",
    detailDepot_00185: "Charger No.",
    detailDepot_00186: "Charger name",
    detailDepot_00187: "AC rates",
    detailDepot_00188: "Select rates",
    detailDepot_00189: "New AC rates",
    detailDepot_00190: "DC rates",
    detailDepot_00191: "New DC rates",
    detailDepot_00192: "Current usage rates:",
    detailDepot_00193: "Start time",
    detailDepot_00194: "End time",
    detailDepot_00195: 'Electricity fee\n({type}/{unit})', //electricity({type}/unit)'
    detailDepot_00196: "Service fee\n({type}/{unit})",
    detailDepot_00197: "Idle fee\n({type}/min)",
    detailDepot_00198: "Period type",
    detailDepot_00199: 'Period type',
    detailDepot_00200: 'undefined',
    detailDepot_00201: 'Super Peak',
    detailDepot_00202: 'Peak',
    detailDepot_00203: 'Off-Peak',
    detailDepot_00204: 'Super Off-Peak',
    detailDepot_00205: 'Rates name',
    detailDepot_00206: 'Select',
    detailDepot_00207: 'New rates',
    detailDepot_00208: 'Please enter the rates name',
    detailDepot_00209: 'This tariff name is already occupied',
    detailDepot_00210: 'Add time slot',
    detailDepot_00211: 'Up to {0} time slots can be added',
    detailDepot_00212: 'Operation',
    detailDepot_00213: 'Delete',
    detailDepot_00214: 'Cancel',
    detailDepot_00215: 'OK',
    detailDepot_00216: 'Please select or create a new rates',
    detailDepot_00217: 'Up to 12 time slots can be added',
    detailDepot_00218: 'Please fill in the rates',
    detailDepot_00219: 'Rates period needs to start at 0 and end at 24',
    detailDepot_00220: 'Please fill in the correct rates',
    detailDepot_00221: 'New Creation Successful',
    detailDepot_00222: 'Apply rates',
    detailDepot_00223: 'Charging station name',
    detailDepot_00224: 'DC',
    detailDepot_00225: 'AC',
    detailDepot_00226: 'Success',
    detailDepot_00227: 'Apply in progress',
    detailDepot_00228: 'Failed',
    detailDepot_00229: 'Number of devices',
    detailDepot_00230: 'Charger apply results',
    detailDepot_00231: 'Fleet name',
    detailDepot_00232: 'Current rates',
    detailDepot_00233: 'Current discount',
    detailDepot_00234: 'Priority (from high to low)',
    detailDepot_00235: 'Move up',
    detailDepot_00236: 'Move down',
    detailDepot_00237: 'Set',
    detailDepot_00238: 'Apply',
    detailDepot_00239: 'Delete',
    detailDepot_00240: 'No discount on the total cost of a single charging session',
    detailDepot_00241: 'No discount on single charging session service fee',
    detailDepot_00242: 'Total cost of a single charging session {des} discount',
    detailDepot_00243: 'Single charging session service fee {des} discount',
    detailDepot_00244: 'Full free single charging session service fee',
    detailDepot_00245: 'Total cost of a single charging session is fully waived',

    detailDepot_00246: 'Delete',
    detailDepot_00247: 'Delete',
    detailDepot_00248: 'Delete',
    detailDepot_00249: 'Delete',
    detailDepot_00250: 'Delete',


    /* Power distribution */
    detailDepot_00401: "Allows you to add a power allocation group, click to add the station chargers to the group, set the total group limit charging power by time period, the charging power of the chargers in the group is allocated from the total group limit charging power for each time period and does not exceed the total group limit charging power for each time period.",
    detailDepot_00402: "Add power distribution group",
    detailDepot_00403: "There are  0 chargers to apply!",
    detailDepot_00404: "In order to support offline charger power allocation, it is necessary to apply the maximum offline charging power of the charger connector (reserved charging power) to the charger.",
    detailDepot_00405: "Apply offline power configuration",
    detailDepot_00406: "Unallocated charger in the station",
    detailDepot_00407: "Are you sure you want to delete this power allocation group?",
    detailDepot_00408: "Click to add to power allocation group",
    detailDepot_00409: "Please enter the name of the power allocation group",
    detailDepot_00410: "Power allocation group name",

    // Operational settings --- Basic configuration
    abled: "",
    disabled: "",
    // modify: "modify",
    // save: "save",
    // cancel: "Cancel",
    // confirm: "OK",
    // delete: "Delete",

    detailDepot_00499: "Only specific users are allowed to charge",
    detailDepot_00500: "Station enablement status",
    detailDepot_00501: "Open to the public",
    detailDepot_00502: "Does it support coupons",
    detailDepot_00503: "Does it support queuing",
    detailDepot_00504: "Does it support reservations",
    detailDepot_00505: "Whether charging is allowed after the charger connector preparing timeout",
    detailDepot_00506: "WeChat User Paid Mode",
    detailDepot_00507: "Charging card user payment mode",
    detailDepot_00508: "Station SOC limit",
    detailDepot_00509: "Reminder of remaining electricity cost at the station",
    detailDepot_00510: "Control whether the station is displayed in WeChat Public",
    detailDepot_00511: "Information affecting the presentation of the station on the client",
    detailDepot_00512: "Allow fleet users in fleet rates settings to charge after startup",
    detailDepot_00513: "In prepaid mode, only rechargeable users can recharge",
    detailDepot_00514: "In prepaid mode, only top-up users can charge",
    detailDepot_00515: "Only for DC charger",
    detailDepot_00516: "Reminder will be made when the remaining electricity bill is < reminder amount",
    detailDepot_00517: "Please enter a positive integer less than 100",
    detailDepot_00518: "Please select user type",
    detailDepot_00519: "Available",
    detailDepot_00520: "Unavailable",
    detailDepot_00521: "Open",
    detailDepot_00522: "Not open",
    detailDepot_00523: 'Yes',
    detailDepot_00524: 'No',
    detailDepot_00525: "Opened",
    detailDepot_00526: "Not open",
    detailDepot_00527: "Supported",
    detailDepot_00528: "Not supported",
    detailDepot_00529: "Parameter is empty",
    detailDepot_00530: "Only supported fleets are allowed to charge",
    detailDepot_00531: "Waiting time",
    detailDepot_00532: "minutes",
    detailDepot_00533: "Prepaid mode",
    detailDepot_00534: "Postpaid mode",
    detailDepot_00535: "Minimum balance",
    detailDepot_00536: "Third party user",
    detailDepot_00537: "WeChat users",
    detailDepot_00538: "When the vehicle battery's SOC reaches",
    detailDepot_00539: "%, stop charging automatically.",
    detailDepot_00540: "Price per kWh",
    detailDepot_00541: "€",
    detailDepot_00542: "Reminder amount",
    detailDepot_00543: "Remaining electricity fee",
    detailDepot_00544: "Please enter the correct amount (greater than 0)",
    detailDepot_00545: "Please save the previous operation!!!",
    detailDepot_00546: "Basic policies",

    // 运营设置 ---标签管理
    detailDepot_00547: 'Tag management',
    detailDepot_00548: 'Add custom tag',
    detailDepot_00549: 'Add station tag in WeChat public number, can find station in WeChat public number and show in station details',
    detailDepot_00550: 'Tag name',
    detailDepot_00551: 'Tag map',
    detailDepot_00552: 'Tag type',
    detailDepot_00553: 'Display order',
    detailDepot_00554: "Move down",
    detailDepot_00555: "Move up",
    detailDepot_00556: "Custom tags",
    detailDepot_00557: "Station tag info",
    detailDepot_00558: "Tag name",
    detailDepot_00559: "Tag type",
    detailDepot_00560: "Custom Tags",
    detailDepot_00561: "Tag map",
    detailDepot_00562: "Drag the file here, or|click to upload",
    detailDepot_00563: "When there is a tag image, the front displays the tag image. When there is no tag map, the front system default style displays the tag name.",
    detailDepot_00564: "Only PNG, JPG format images are supported, size {area}px, no more than {size}kb.",
    detailDepot_00565: "Sure to delete?",
    detailDepot_00566: "Reminder",
    custom: "Custom",
    undefined: "Undefined",
    detailDepot_00567: "Operate successfully",
    detailDepot_00568: "Operate failed",
    detailDepot_00569: "Delete successfully",
    detailDepot_00570: "Undelete",
    detailDepot_00571: "Modified successfully",
    detailDepot_00572: "Please enter content, up to 5 characters supported",

    // Operational Setup - Charging Order Expansion
    detailDepot_00573: "Transaction expansion",
    detailDepot_00574: "Add display information for charging orders in WeChat Public",
    detailDepot_00575: "Add information",
    detailDepot_00576: "Title",
    detailDepot_00577: "Show link",
    detailDepot_00578: "Display restriction",
    detailDepot_00579: 'Display order',
    // common_00282:'operation',
    detailDepot_00580: "Transaction display info",
    detailDepot_00581: "Display content",
    detailDepot_00582: "Paid transactions only",
    detailDepot_00583: "Unlimited transaction status",
    detailDepot_00584: "Description",
    detailDepot_00585: "Please enter the URL address",
    detailDepot_00586: "Check this to display this information only after the payment has been completed.",
    detailDepot_00587: "Please fill in the content",
    detailDepot_00588: "Please enter content",
    detailDepot_00589: "No more than {num} words",
    detailDepot_00590: "Please enter a number greater than 0!",
    detailDepot_00591: "Please enter the idle fee rates!",
    detailDepot_00592: "Please enter the length of time for which the idle fee is waived!",
    detailDepot_00593: "Please enter a positive integer",

    // Operational Settings --- Cloud-controlled Parking Lock Idle fee
    detailDepot_00594: "Cloud-controlled parking space lock idle fee",
    detailDepot_00595: "Idle fee rates({type}/{unit})",
    detailDepot_00596: "Free idle fee duration (minutes)",
    detailDepot_00597: "For the length of time a vehicle owner drives in to prepare for charging or moves out of a parking space without being charged an idle fee",
    detailDepot_00598: "Online Identity Card",
    // detailDepot_00598: "Online Identity Card",


    // Car park docking
    detailDepot_00600: "Car park docking",
    detailDepot_00601: "The car park docking is for the purpose of giving users a reduction in parking fees or issuing parking coupons",
    detailDepot_00602: "1. Find out if the parking system where this site is located has been docked",
    detailDepot_00603: "2. If you have already docked, enter the car park number and associate the car park with it. (Please check with your car park for the car park system and car park number)",
    detailDepot_00604: "3. If you haven't docked before, you can contact Business and request a dock.",
    detailDepot_00605: "Introduction to the brands of docked parking systems",
    detailDepot_00606: "Associations",
    detailDepot_00607: "Parking lot associated",
    detailDepot_00608: "Parking ID",
    detailDepot_00609: "Close association",
    detailDepot_00610: "Please enter parking lot ID (only numbers, letters, and underscores are supported)",
    detailDepot_00611: "Filter orders with charging time less than 10 minutes",
    detailDepot_00612: "Confirmation of closing association with {name}?",
    detailDepot_00613: "Re-consider",
    detailDepot_00614: "Confirm",
    detailDepot_00615: "Associated Parking",
    detailDepot_00616: "Prompt message",
    detailDepot_00617: "Cannot be empty",
    detailDepot_00618: "The parking lot ID only supports numbers, letters, and underscores.",
    // Open Flow + Government Matching
    detailDepot_00619: "Only government traffic that the operator has already connected to is shown here, for access to more traffic parties, please contact the platform. For more information on access, please visit:",
    detailDepot_00620: "Government Platform",
    detailDepot_00621: "Only free traffic and traffic already connected to the operator are shown here, for access to more traffic parties, please contact the platform. For more information on access, please visit:",
    detailDepot_00622: "Traffic Center",
    detailDepot_00623: "Free",
    detailDepot_00624: "Government",
    detailDepot_00625: "Direct signature",
    detailDepot_00626: "SmartCharge Total Package",
    detailDepot_00627: "This data flow is already compatible with the SmartCharge QR code and needs to be upgraded to a fixed QR code in the charging post details",
    detailDepot_00628: "This data flow is not compatible with the SmartCharge QR code, you need to print the QR code and paste it",
    detailDepot_00629: "Batch Export QR Code",
    detailDepot_00630: "Tariff settings",
    detailDepot_00631: "Station discount",
    detailDepot_00632: "Start/Stop Settings",
    detailDepot_00633: "Refuse open",
    detailDepot_00634: "Agree to open",
    detailDepot_00635: "Opened - no start/stop",
    detailDepot_00636: "Opened - can be started and stopped",
    detailDepot_00637: "Not open",
    detailDepot_00638: "Opened",
    detailDepot_00639: "No charging posts enabled at the station, please enable at least one charging post.",
    detailDepot_00640: "Inside charging station info",
    detailDepot_00641: "Country Code,",
    detailDepot_00642: "Province and city code,",
    detailDepot_00643: "Parking fees,",
    detailDepot_00644: "Station type,",
    detailDepot_00645: "Building station,",
    detailDepot_00646: "Number of parking spaces,",
    detailDepot_00647: "Customer Service Phone Number,",
    detailDepot_00648: "Live image,",
    detailDepot_00649: "empty",
    detailDepot_00650: 'Please set "Open or not" to "Open" in [Site Details] - [Site Info] - [Status Info].',
    detailDepot_00651: "Charging station",
    detailDepot_00652: "SmartCharge",
    // *******
    diversionDetail_00021: 'Operation',
    diversionDetail_00011: 'Operator',
    diversionDetail_00060: 'Third party data flow  platform',
    diversionDetail_00012: 'Station status',
    diversionDetail_00013: 'Enabled',
    diversionDetail_00014: 'Not enabled',
    diversionDetail_00068: 'Yes',
    diversionDetail_00069: 'No',
    diversionDetail_00072: 'Return',
    diversionDetail_00073: 'Modified successfully',
    diversionDetail_00074: 'Please enter a number',
    diversionDetail_00075: 'Service fee discount granted by operator to SmartCharge (%)',
    diversionDetail_00076: 'No discount on the total cost of a single charging session (%)',
    diversionDetail_00077: 'Discount on single charging service fee (%)',
    diversionDetail_00078: '85 means 15% off, 0 means full free',
    diversionDetail_00079: 'Single charge at a discount per kWh',
    diversionDetail_00080: 'Display name',
    diversionDetail_00081: 'Please enter a display name, recommended format: brand name (charging site name), the keyword "brand name" needs to be removed from the site name',
    diversionDetail_00082: 'Whether to allow third party start/stop stakes',
    diversionDetail_00083: 'Allow only certain users to charge',
    diversionDetail_00084: 'Think again',
    diversionDetail_00085: 'I got it',
    diversionDetail_00086: 'Go to fill',
    diversionDetail_00087: 'Name cannot be empty',
    diversionDetail_00088: 'Open Success',
    diversionDetail_00089: 'Prompt message',
    diversionDetail_00090: 'Fill in the information',
    diversionDetail_00091: 'OK',
    diversionDetail_00092: 'Whether to allow third party start/stop charger',
    diversionDetail_00093: 'Direct tariff setting',
    diversionDetail_00094: 'If the tariff has been changed, click on the "Apply rates" button in Site Details - Tariff to make the tariff effective!',
    diversionDetail_00095: 'AC-Rates',
    diversionDetail_00096: 'Select AC tariff',
    diversionDetail_00097: 'DC-Rates',
    diversionDetail_00098: 'Select DC tariff rate',
    diversionDetail_00099: 'Site discount settings',
    diversionDetail_00100: 'Add charging site discount',
    diversionDetail_00101: 'Start time',
    diversionDetail_00102: 'Now',
    diversionDetail_00103: 'End time',
    diversionDetail_00104: 'unlimited',
    diversionDetail_00105: 'Preferred method',
    diversionDetail_00106: '85 means 15% off',
    diversionDetail_00107: 'Discount per kWh ($)',
    diversionDetail_00108: 'Use the service fee discount method',
    diversionDetail_00109: 'Use the per kWh discount method',
    diversionDetail_00110: 'Status',
    diversionDetail_00111: 'Not started',
    diversionDetail_00112: 'Started',
    diversionDetail_00113: 'Validated',
    diversionDetail_00114: 'Not valid',
    diversionDetail_00115: 'Site Discount History',
    diversionDetail_00116: 'Please save the previous operation!',
    diversionDetail_00117: 'Start time cannot be empty',
    diversionDetail_00118: 'The end time must not be empty',
    diversionDetail_00119: 'The preference method must not be empty',
    diversionDetail_00120: 'Please enter a positive integer less than 100',
    diversionDetail_00121: 'Saved successfully',
    diversionDetail_00122: 'Service Fee Discount:{dis}%',
    diversionDetail_00123: 'Discount per kWh:{dis}$',
    diversionDetail_00124: 'Refuse open to {name} site information, {name} will not display any site information.',
    diversionDetail_00125: 'Confirmation of refusing open to {name} site information?',
    diversionDetail_10126: 'Push device status',
    diversionDetail_10127: 'Failed to push device status, please try again!',
    diversionDetail_10128: 'Push device status successfully!',
    // *******
    // Equipment Management - Equipment List
    diversionDetail_00126: 'Charger serial number or name',
    diversionDetail_00127: 'Model',
    diversionDetail_00128: 'Status',
    diversionDetail_00129: 'Available',
    diversionDetail_00130: 'View preparing only',
    diversionDetail_00131: 'In use',
    diversionDetail_00132: 'View finishing only',
    diversionDetail_00133: 'View finishing only',
    diversionDetail_00134: 'Add charger',
    diversionDetail_00135: 'SN:',
    diversionDetail_00136: 'Manufacturer - Model:',
    diversionDetail_00137: 'Charger details',
    diversionDetail_00138: 'Connector:',
    diversionDetail_00139: 'Pending diversion',
    diversionDetail_00140: 'Appointment in progress',
    diversionDetail_00141: 'Connected',
    diversionDetail_00142: 'Connected',
    diversionDetail_00143: 'Transactions',
    diversionDetail_00144: 'Self-activating charger',
    diversionDetail_00145: 'After following the steps below to self-activate the charger, you can add the charger to the charging station.',
    diversionDetail_00146: 'Step 1',
    diversionDetail_00147: 'Scan the QR code on the left using your mobile WeChat',
    diversionDetail_00148: "Kindly tips: You can save the page to WeChat's favorites, so you can easily open and use it at any time.",
    diversionDetail_00149: 'Step 2',
    diversionDetail_00150: 'Enter the SN of the charger on the mobile, initiate the activation and confirm it on the charger.',
    diversionDetail_00151: 'Enter full  serial number',
    diversionDetail_00152: 'Find chargers by charger ID',
    diversionDetail_00153: 'This charger has been added, further additions will be transferred to the current charging station and will require a reboot before charging',
    diversionDetail_00154: '(e.g. AC #1)',
    diversionDetail_00155: 'Set the name of the charger in the field',
    diversionDetail_00156: 'Charger added successfully',
    diversionDetail_00157: 'You can perform the following operations in Station Details - Charger management - Device Details:',
    diversionDetail_00158: '1. Modify device name',
    diversionDetail_00159: '2. Change activation status',
    diversionDetail_00160: '3. Set whether the electromagnetic lock and radar are enabled',
    diversionDetail_00161: 'This charger has been added to the current station',
    diversionDetail_00162: 'Please enter the full charger serial number',
    diversionDetail_00163: 'Please complete the charger information',
    diversionDetail_00164: 'Normal',
    diversionDetail_00165: 'Locked car',
    diversionDetail_00166: 'Dormant',
    diversionDetail_00167: 'Unknown',
    diversionDetail_00168: 'Insert connector',
    diversionDetail_00169: 'End of charge',
    diversionDetail_00170: 'View unreturned only',
    diversionDetail_00171: 'Returned',
    diversionDetail_00172: 'Not homed',
    // POS payment setup
    diversionDetail_00173: 'POS Payment Setup',
    diversionDetail_00174: 'Apply POS payment settings',
    diversionDetail_00175: 'Only charging posts with POS are supported for this configuration',
    diversionDetail_00176: 'Pre-authorized amount',
    diversionDetail_00177: 'Current rates currency type of the station',
    diversionDetail_00178: 'Confirmation apply',
    diversionDetail_00179: 'Current rates currency type of the station',
    diversionDetail_00180: 'List of stakes with POS installed at this station',
    diversionDetail_00181: 'POS payment status',
    diversionDetail_00182: 'Select all',
    diversionDetail_00183: 'Device serial number',
    diversionDetail_00184: 'Manufacturer-Model',
    diversionDetail_00185: 'POS Currency Type',
    diversionDetail_00186: 'Please select device',
    diversionDetail_00187: 'Apply successful',
    diversionDetail_00188: 'Waiting for response',
    diversionDetail_00189: 'POS currency type of charger does not match platform settings, please check',
    diversionDetail_00190: "The currency type of the POS at the charger is {list}, there is a conflict and it is recommended to overhaul the incorrect charger offline. Some chargers with a different currency type {default} than the station's selected rates template will not enable POS payments. Please check the currency type of the charger or change the currency type of the station rates.",
    diversionDetail_00191: 'Use currency',
    diversionDetail_00192: 'This station needs to select a rates type of {type}, there is currently no such rates, please create a new rates.',
    diversionDetail_00193: 'and',
    diversionDetail_00194: 'Please enter the correct amount to be withheld',
    diversionDetail_00195: 'Prompt message',
    diversionDetail_00196: 'Modify rates',
    diversionDetail_00197: 'The current charging station settlement currency type is different from the public rates currency type',
    diversionDetail_00198: 'Please reselect the rates with currency type {type}',
    diversionDetail_00199: 'Station settlement currency type',
    diversionDetail_00200: 'The current station currency type does not support adding group rates and offers at this time.',
    diversionDetail_00201: 'After changing the currency type of the station, please change the public rates and fleet rates to the same currency type and apply the rates again',

    /* Public transport charging */
    detailDepot_00411: "Enter 0 for unrestricted",
    detailDepot_00412: "Basic information settings",
    detailDepot_00413: "Total charge power limit for the whole station (KW)",
    detailDepot_00414: "Basic Information Settings",
    detailDepot_00415: "0 means no limit on total charging power",
    detailDepot_00416: "Is orderly charging enabled",
    detailDepot_00417: "Please enter the correct value",
    detailDepot_00418: "Bus vehicles",
    detailDepot_00419: "Line schedule",
    detailDepot_00420: "Group limiting power",
    detailDepot_00421: "EV number",
    detailDepot_00422: "EV MAC",
    detailDepot_00423: "EV VIN",
    detailDepot_00424: "Total nominal energy of the vehicle power battery (kW-h)",
    detailDepot_00425: "Recording EV",
    detailDepot_00426: "Import EV",
    detailDepot_00427: "Download import template",
    detailDepot_00428: "Generate",
    detailDepot_00429: "Cannot be empty",
    detailDepot_00430: "Only two decimal places are allowed for the total nominal energy of the power battery of the complete vehicle",
    detailDepot_00431: "Vehicle MAC parameter is illegal",
    detailDepot_00432: "Download Form",
    detailDepot_00433: "Are you sure you want to delete this EV (EV No.: {num})?",
    detailDepot_00434: "Optional",
    detailDepot_00435: "Total power to limit charging in group (KW)",
    detailDepot_00436: "Please fill in all required fields!",
    detailDepot_00437: "Please enter the correct value",
    detailDepot_00438: "Modify power distribution group",
    detailDepot_00439: "View live operation",
    detailDepot_00440: "Import",
    detailDepot_00441: "EV - Charging space recommendation",
    detailDepot_00442: "Download",
    detailDepot_00443: "Effective date",
    detailDepot_00444: "Currently in use",
    detailDepot_00445: "Recently scheduled use",
    detailDepot_00446: "Line",
    detailDepot_00447: "Shifts",
    detailDepot_00448: "Departure time",
    detailDepot_00449: "Return time",
    detailDepot_00450: "EV code",
    detailDepot_00451: "Minimum required SOC at departure (%)",
    detailDepot_00452: "Please select a date",
    detailDepot_00453: "Effective immediately",
    detailDepot_00454: "Adjustment of frequency vehicles",
    detailDepot_00455: "Are you sure you want to delete the vehicle route schedule?",
    detailDepot_00456: "Click to upload",
    detailDepot_00457: "Imported files",
    detailDepot_00458: "Import of vehicle route schedules",
    detailDepot_00459: "",
    detailDepot_00460: "EV MAC2(If the vehicle has a second MAC, please enter it here)",
    detailDepot_00461: "EV MAC2",
    detailDepot_00462: "MAC address cannot be duplicate",
    detailDepot_00463: "Automatic start of vehicle air conditioning",
    detailDepot_00464: "Automatic start configuration of vehicle air conditioning",
    detailDepot_00465: "Air conditioning automatic start distance departure time（min）",
    detailDepot_00466: "Is the automatic start of the vehicle's air conditioning enabled",
    detailDepot_00467: "After modifying the configuration of  Bus Vehicles,Line Schedule, Air conditioning automatic activation distance departure time,and Is the automatic activation of the vehicle's air conditioning enabled, it is necessary to push the configuration again.",
    detailDepot_00468: "Push vehicle air conditioning automatic start configuration",
    detailDepot_00469: "Are you sure to push the automatic start configuration of the vehicle's air conditioning?",
    detailDepot_00470: "Start air conditioning",
    detailDepot_00471: "Command sent",
    detailDepot_00472: "Bus Vehicles or Line Schedule cannot be empty!",

    // Site Information
    detailDepot_10001: "Station address",
    detailDepot_10002: "Map location",
    detailDepot_10003: "Click the button on the right to get the location",
    detailDepot_10004: "Modify Location",
    detailDepot_10005: "Province and city code",
    detailDepot_10006: "Country code",
    detailDepot_10007: "Supported models",
    detailDepot_10008: "Station Type",
    detailDepot_10009: "Customer Service Phone Number",
    detailDepot_10010: "Station contact",
    detailDepot_10011: "Mobile number",
    detailDepot_10012: "Mailbox",
    detailDepot_10013: "Building station",
    detailDepot_10014: "Location of parking spaces",
    detailDepot_10015: "Number of parking spaces",
    detailDepot_10016: "Parking Fees",
    detailDepot_10017: "Live image",
    detailDepot_10018: "Click to upload image",
    detailDepot_10019: "Only PNG/JPG/GIG format images are supported",
    detailDepot_10020: "No more than 1M",
    detailDepot_10021: "Failed to get location",
    detailDepot_10022: "Close",
    detailDepot_10023: "Find",
    detailDepot_10024: "Search Results",
    detailDepot_10025: "Drag the file here, or ",
    detailDepot_10026: "Click to upload",
    detailDepot_10027: "Please enter the city code",
    detailDepot_10028: "Incorrect city code format (6 characters)",
    detailDepot_11026: "Station ID",
    detailDepot_21026: "Check",
    // Equipment Management
    detailDepot_10029: "Charger list",
    detailDepot_10030: "Charger configuration",
    detailDepot_10031: "Screen configuration",
    detailDepot_10032: "Whitelist",
    detailDepot_10033: "Applies configuration information after each modification to ensure the charging pile is functioning properly.",
    detailDepot_10034: "Apply configuration",
    detailDepot_10035: "Charger local information settings",
    detailDepot_10036: "Save",
    detailDepot_10037: "Edit",
    detailDepot_10038: "Charger function configuration",
    detailDepot_10039: "Compensation factor setting",
    detailDepot_10040: "Idle fee settings",
    detailDepot_10041: "Restrictions",
    detailDepot_10042: "Unrestricted",
    detailDepot_10043: "Success",
    detailDepot_10044: "Failed",
    detailDepot_10045: "Apply in progress",
    detailDepot_10046: "The screen configuration includes images, copy and advertising on the charger's screen, depending on the charger model and screen size.",
    detailDepot_10047: "Whitelist settings",
    detailDepot_10048: "If the charger is offline, only users in the whitelist are supported to start charging.",
    detailDepot_10049: "Apply whitelist",
    detailDepot_10050: "Clear whitelist",
    detailDepot_10051: "View whitelist",
    // Tariff setting
    detailDepot_10052: "Public rates",
    detailDepot_10053: "Fleet rates",
    detailDepot_10054: "Please apply the finished set rates to the charger so that the charger can bill the user for the charge at the new rates. Note: Non-Xcharge charger may note support this operation, resulting in apply failure.",
    detailDepot_10055: "Apply rates",
    detailDepot_10056: "Please change the default rates first",
    detailDepot_10057: "No charger under this station, unable to apply",
    detailDepot_10058: "Apply failed",
    detailDepot_10059: "Deleted",
    detailDepot_10060: "This action will remove the fleet from the list, do you want to continue?",
    detailDepot_10061: "Tip",
    detailDepot_10062: "List of fleets",
    detailDepot_10063: "Add fleet",
    detailDepot_10064: "New rates",
    detailDepot_10065: "Select fleet",
    detailDepot_10066: "Please enter the name of the fleet",
    detailDepot_10067: "Number of users",
    detailDepot_10068: "Rates",
    detailDepot_10069: "Please select rates",
    detailDepot_10070: "Discount selection",
    detailDepot_10071: "No discount",
    detailDepot_10072: "Discount service fee(%)",
    detailDepot_10073: "Example: If you set a single charging service fee of 8% off, and the charging cost 100 € (including €70  for electricity and €30 for service), the actual amount you need to pay this time is €95.5 (70+30*85%=95.5)",
    detailDepot_10074: "Enter 85 means discount 15% off and 0 means free",
    detailDepot_10075: "Discount electricity fee and service fee(%)",
    detailDepot_10076: "Example: If you set the total cost of a single charge at 20% off and the total cost of this charge is €100, the actual amount you need to pay this time is €85 (100*85%=85)",
    detailDepot_10077: "Fleet added successfully",
    detailDepot_10078: "Click the [Apply] button to make the rates effective!",
    detailDepot_10079: "The following operations can be performed in [Station management - Station info – RatesSet-Fleet rates]",
    detailDepot_10080: "1. Remove added fleet",
    detailDepot_10081: "2. Add a new fleet",
    detailDepot_10082: "Previous",
    detailDepot_10083: "Next",
    detailDepot_10084: "Please select a fleet",
    detailDepot_10085: "Please select a rates",
    detailDepot_10086: "Fleet cannot be added repeatedly",
    detailDepot_10087: "Please set the discount parameter",
    detailDepot_10088: "Please enter a positive integer less than 100",
    detailDepot_10089: "Select rates and discount",
    detailDepot_10090: "No discount on the total cost of a single successful point (%)",
    detailDepot_10092: "Idle fee free periods",
    detailDepot_10093: "No idle fee free periods",
    detailDepot_10094: "There is an idle fee waiver period",
    detailDepot_10095: "The time slot is the local time slot where the station is located, up to 5 time slots can be added",
    detailDepot_10096: "Idle fee free periods can be set here, depending on the needs of the station operator.",
    detailDepot_10097: "There is an overlap in the time period!",
    detailDepot_10098: "Please fill in the occupation fee period",
    detailDepot_10099: "The end time needs to be greater than the start time",
    detailDepot_10100: "Add time slot",
    detailDepot_10101: "Faulted/Offline",
    detailDepot_10102: "According to the average value of 30 days, the current balance can take {num} days",
    detailDepot_10103: "custom time is the same as the previous option, please select this option directly or fill in the custom time again",
    //储能
    detailDepot_10104: "NZS working mode selection",
    detailDepot_10105: "Auto mode",
    detailDepot_10106: "Eco mode",
    detailDepot_10107: "Max mode",
    detailDepot_10108: "Utilize energy storage devices to fill the charging power gap and quickly fill the vehicle.",
    detailDepot_10109: "Energy storage at low electricity prices and services at high electricity prices reduce electricity costs.",
    detailDepot_10110: "System automatically balances power and cost requirements.",
    detailDepot_10111: "Learn more",
    detailDepot_10112: "NZS air conditioning operation mode",
    detailDepot_10123: "Economic Model",
    detailDepot_10124: "Efficient mode",
    detailDepot_10125: "Grid rates",
    detailDepot_10126: "New grid rates",
    detailDepot_10127: "Charging rates",

    detailDepot_10128: "Grid rates",
    detailDepot_10129: "Period type price",
    detailDepot_10130: 'Grid electricity fee({type}/{unit})',
    detailDepot_10131: 'previous ',
    detailDepot_10132: 'next',
    detailDepot_10133: 'Please select period type',
    detailDepot_10134: 'Super Peak',
    detailDepot_10135: 'Peak',
    detailDepot_10136: 'Off-Peak',
    detailDepot_10137: 'Super Off-Peak',
    detailDepot_10138: 'Period type',
    //配置检查
    detailDepot_10139: '1. Check the status of the device hardware, which takes tens of seconds',
    detailDepot_10140: '2. This check requires that the firmware version number of the Android application for the charger be above 3.0.0.',
    detailDepot_10141: '3. This check is not supported by third-party devices.',
    detailDepot_10142: "Start inspection",
    detailDepot_10143: "Please wait during self-inspection",
    detailDepot_10144: "Re-inspection",
    detailDepot_10145: "Inspect time",
    detailDepot_10146: "Status inspection",
    detailDepot_10147: "Hardware lifetime",
    detailDepot_10148: "Self-inspection items",
    detailDepot_10149: "Confirm and recover from emergency stop",
    detailDepot_10150: "The charger is in use, self-inspection is canceled, please operate after it is idle",
    detailDepot_10151: "The current charger is offline",
    detailDepot_10152: "Charger self-inspection timed out, please try again",
    detailDepot_10153: "Poorer",
    detailDepot_10154: "moderate",
    detailDepot_10155: "better",
    detailDepot_10156: "This inspection requires that the firmware version number of the Android application for the charger be 3.0.0 or higher",
    detailDepot_10157: "Last self-inspection result not found",
    detailDepot_10158: "Recovery immediately",
    detailDepot_10159: "Check the charger first, then consider recovery",
    detailDepot_10160: "Disclaimer",
    detailDepot_10161: "To ensure the safe operation of the charger, we recommend that you perform a hardware check before restoring the emergency stop button. If any hardware failure or safety hazard is found, please stop using the charger and contact our technical support team. We will provide a solution as soon as possible to ensure that your charger can operate safely and stably.",
    detailDepot_10162: "Please note that this disclaimer is intended to remind you that choosing to directly restore the emergency stop button without first determining the status of the charger may involve certain risks, and we will not be held responsible for any resulting consequences.",
    detailDepot_10163: "Confirm direct recovery",
    detailDepot_10164: "The following device configurations are inconsistent",
    detailDepot_10165: "The following device configurations are consistent",
    detailDepot_10166: "No device supporting inspection was found in the site",
    detailDepot_10167: "Response timed out, please try again",
    detailDepot_10168: "Configuration check",
    // add
    accountManage_00002: "Account",
    accountManage_00003: "Owner",
    accountManage_00004: "Mobile",
    accountManage_00005: "Role",
    accountManage_00006: "Add account",
    accountManage_00007: "Operation",
    accountManage_00008: "Scope",
    accountManage_00009: "Add account",
    accountManage_000010: "Account list",
    accountManage_000011: "Edit account",
    accountManage_000012: "Account for logging in to the operation platform",
    accountManage_000013: "Password",
    accountManage_000014: "Password for logging in to the operation platform",
    accountManage_000015: "A maximum of 20 characters can be letters and numbers",
    accountManage_000016: "Owner",
    accountManage_000017: "Use your real name for easy follow-up",
    accountManage_000018: "Mobile",
    accountManage_000019: "For others to contact the administrator",
    accountManage_000020: "Role",
    accountManage_000021: "CPO administrator",
    accountManage_000022: "Select",
    accountManage_000023: "Please select an account role",
    accountManage_000024: "Operation  succeeded",
    accountManage_000025: "Please enter the name of charging station",
    accountManage_000026: "The charging station is added successfully. Procedure",
    accountManage_000027: "The charging station has been selected",
    accountManage_000028: "Delete successfully",
    accountManage_000029: "Super administrator",
    accountManage_000030: "Are you sure you want to delete it?",
    accountManage_000031: "Cancelled delete",
    accountManage_000032: "Add time",
    accountManage_000033: "email",
    detailDepot_11051: 'The maximum number of charging ports supported by the platform has reached the upper limit. Please go to the "Operating platform - Opening Service" page to expand the number of charging ports supported by the platform',
    detailDepot_11052: "Energy station",
    detailDepot_11152: "Add failed! The sum of the maximum offline charging power of all models of charging ports x the number of charging ports in the group must be less than or equal to the minimum value in the setting of the total limited charging power in the group",
    detailDepot_11053: "NZS Battery",
    //百度翻译 小橘
    detailDepot_20001: "Business trends",
    detailDepot_20002: "Revenue analysis",
    detailDepot_20003: "Charging capacity analysis",
    detailDepot_20004: "Efficiency analysis",
    detailDepot_20005: "User analysis",
    detailDepot_20006: "Analysis",
    detailDepot_20007: "Total revenue trends",
    detailDepot_20008: "Service charge (yuan)",
    detailDepot_20009: "Electricity charge (yuan)",
    detailDepot_20010: "Income (yuan)",
    detailDepot_20011: "Proportion of revenue from users",
    detailDepot_20012: "Proportion of third-party traffic",
    detailDepot_20013: "Revenue trends from users",
    detailDepot_20014: "Third party traffic revenue trends",
    detailDepot_20015: "Trend of electricity utilization rate",
    detailDepot_20016: "On time long utilization trend",
    detailDepot_20017: "Utilization rate=daily charge/daily full load charge<br/>daily full load charge=rated power of charging pile * 24 hours",
    detailDepot_20018: "Utilization rate=daily charging time of charging connector/24 hours",
    detailDepot_20019: "AC equipment charging utilization rate (%)",
    detailDepot_20020: "Utilization rate of DC equipment charging (%)",
    detailDepot_20021: "Charging capacity (degree)",
    detailDepot_20022: "Charging capacity trend",
    detailDepot_20023: "Proportion of user source charging",
    detailDepot_20024: "Trend of user source charging capacity",
    detailDepot_20025: "Third party flow charging amount trend",
    detailDepot_20026: "Trend of users",
    detailDepot_20027: "Proportion of charging orders from users",
    detailDepot_20028: "Number of charging users",
    detailDepot_20029: "Number of users charging for the first time",
    detailDepot_20030: "Trend of charging orders from users",
    detailDepot_20031: "Third party flow charging order number trend",
    detailDepot_20032: "Trend of income per connector",
    detailDepot_20033: "Idle fee ({0})",
    detailDepot_20034: "0-24 hour average charging capacity trend",
    detailDepot_20035: "Charging capacity per connector",
    detailDepot_20036: "Degree per connector",
    detailDepot_20037: "Charging capacity per connector",
    detailDepot_20038: "Single quantity per connector",
    detailDepot_20039: "Number of orders per connector",
    detailDepot_20040: "Single",
    detailDepot_20041: "Number of charging orders",
    detailDepot_20042: "Service charge per connector ({0})",
    detailDepot_20043: "Electricity charge per connector ({0})",
    detailDepot_20044: "Idle fee per connector ({0})",
    detailDepot_20045: "Utilization rate=daily charge/daily full load charge<br/>daily full load charge=rated power of charging pile * 24 hours",
    detailDepot_20046: "Utilization rate=daily charging time of charging connector/24 hours",
    detailDepot_20047: "Total income ({0})",
    detailDepot_20048: "Total service charge ({0})",
    detailDepot_20049: "Total electricity charge ({0})",
    detailDepot_20050: "Total idle fee ({0})",
    detailDepot_20051: "Total revenue from third-party diversion sources ({0})",
    detailDepot_20052: "Total charge (kWh)",
    detailDepot_20053: "Total self operated&franchisee source charging capacity (degree)",
    detailDepot_20054: "Total charging capacity of third-party diversion source (degree)",
    detailDepot_20055: "Total number of charging users (person)",
    detailDepot_20056: "Total number of charging orders (single)",
    detailDepot_20057: "Total number of first charging orders (single)",
    detailDepot_20058: "Total number of charging orders (orders) of self operated&franchisees",
    detailDepot_20059: "Total third-party diversion charging orders (single)",
    // 能源微网设置
    detailDepot_30000: "After each modification, the configuration information needs to be pushed to ensure the normal operation of the micro network hardware system. The parameters set here will be pushed in batches to the micro network equipment that can run the parameters in the station.",
    detailDepot_30001: "NZS battery settings",
    detailDepot_30002: "NZS battery minimum stop discharge SOC limit",
    detailDepot_30003: "This is to prevent the power grid outage or the battery over-discharge",
    detailDepot_30004: "NZS battery maximum stop charge SOC limit",
    detailDepot_30005: "This is to prevent the battery from getting overcharged due to battery decay",
    detailDepot_30006: "If there is NZS charger equipment in the station, set this option to 'Yes', you can view the operation and revenue changes brought by NZS to the station in the 'Energy Center'",
    detailDepot_30007: "",
    detailDepot_30010: "Apply settings",
    detailDepot_30011: "Please fill out the form completely",
    detailDepot_30012: "Dual Platform",
    detailDepot_40000: "Does it support VIN startup charging",
    detailDepot_40001: "If the station device supports the VIN as the identity to plug and charge, after this setting, the station can support the VIN as the identity verification when the vehicle starts charging. After the verification, the charging can be started. Application scenario Example: Use the Plug and Charge card mode to support the plug and charge at the station. Set this parameter to Yes. ",
    detailDepot_40002: "Charging configuration of the charging card when the device is offline",
    detailDepot_40003: "Allow all",
    detailDepot_40004: "Whitelist allowed",
    detailDepot_40005: "When offline, the charging card starts charging mode",
    detailDepot_40006: "Batch Add Devices",
    detailDepot_40007: "Please enter the complete device SN. Supports multiple device SN inputs, with a maximum of 50 inputs.",
    detailDepot_40008: "One device SN per line, please do not enter symbols, letters, spaces, etc. outside of SN.",
    detailDepot_40009: "Please enter the device name",
    detailDepot_40010: "Verification failed",
    detailDepot_40011: "Verification successful",
    detailDepot_40012: "Please check if the device SN is correct",
    detailDepot_40013: "A maximum of 50 can be entered",
    detailDepot_40014: "The device SN has been added by another station",
    detailDepot_40015: "Muzzle limit is exceeded",
    detailDepot_40016: "Please enter the device name",
    detailDepot_40017: "{0}",
    detailDepot_40018: "A total of {0} device SNs were input, with {1} successfully verified and {2} failed",
    detailDepot_40019: "Add Devices",
    detailDepot_40020: "Successfully added {0} devices!",
    detailDepot_51000: "Please set  the percentage",
    detailDepot_51001: "The actual payment amount will not exceed the pre-authorized amount set here, including electricity fee, service fee, idle fee, taxes, etc.",

    //设备列表自检
    eqDetails_30003: "Hardware self-inspection",
    eqDetails_30004: "1. Check the status of the device hardware, which takes tens of seconds",
    eqDetails_30005: "2. This check requires that the firmware version number of the Android application for the charger be above 3.0.0.",
    eqDetails_30006: "3. This check is not supported by third-party devices.",
    eqDetails_30007: "Last inspection result",
    eqDetails_30008: "Start inspection",
    eqDetails_30009: "Please wait during self-inspection",
    eqDetails_30010: "Re-inspection",
    eqDetails_30011: "Inspect time",
    eqDetails_30012: "Status inspection",
    eqDetails_30013: "Hardware lifetime",
    eqDetails_30014: "Self-inspection items",
    eqDetails_30015: "Confirm and recover from emergency stop",
    eqDetails_30016: "The charger is in use, self-inspection is canceled, please operate after it is idle",
    eqDetails_30017: "The current charger is offline",
    eqDetails_30018: "Charger self-inspection timed out, please try again",
    eqDetails_30020: "Poorer",
    eqDetails_30021: "moderate",
    eqDetails_30022: "better",
    eqDetails_30023: "This inspection requires that the firmware version number of the Android application for the charger be 3.0.0 or higher",
    eqDetails_30024: "Last self-inspection result not found",
    eqDetails_30025: "Recovery immediately",
    eqDetails_30026: "Check the charger first, then consider recovery",
    eqDetails_30027: "Disclaimer",
    eqDetails_30028: "To ensure the safe operation of the charger, we recommend that you perform a hardware check before restoring the emergency stop button. If any hardware failure or safety hazard is found, please stop using the charger and contact our technical support team. We will provide a solution as soon as possible to ensure that your charger can operate safely and stably.",
    eqDetails_30029: "Please note that this disclaimer is intended to remind you that choosing to directly restore the emergency stop button without first determining the status of the charger may involve certain risks, and we will not be held responsible for any resulting consequences.",
    eqDetails_30030: "Confirm direct recovery",
    eqDetails_30031: "Self-check items",
    eqDetails_30032: "State",
    eqDetails_30033: "{msg},{code},self-inspection is canceled, Please operate after maintenance",
    eqDetails_30034: "This information will be displayed on the pile end screen when the charging pile malfunctions",
    eqDetails_30035: "Station contact phone number",
    eqDetails_30036: "You can modify this information in the 'Station Information' section of the station contact person",
}