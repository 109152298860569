export default {
    gia_00001:'Finance',
    gia_00002:'Common Functions',
    gia_00003:'The types of invoices applied for are all ordinary value-added tax invoices, excluding the cashback amount and discount amount.',
    gia_00004:'application status',
    gia_00005:'All',
    gia_00006:'Pending',
    gia_00007:'Opened',
    gia_00008:'Cancelled',
    gia_00009:'group',
    gia_000010:'Inquire',
    gia_000011:'Reset',
    gia_000012:'export data',
    gia_000013:'Number of pending transactions',
    gia_000014:'Total amount to be processed (yuan)',
    gia_000015:'Number of invoices',
    gia_000016:'Total invoiced amount (yuan)',
    gia_000017:'Username',
    gia_000018:'Application type',
    gia_000019:'Billing method',
    gia_000020:'Application Amount',
    gia_000021:'Check charging order',
    gia_000022:'Check parking space occupancy bill',
    gia_000023:'Reason for rejection',
    gia_000024:'Invoice details',
    gia_000025:'Invoice',
    gia_000026:'Company No.',
    gia_000027:'address',
    gia_000028:'Refuse',
    gia_000029:'information',
    gia_000030:'Tel',
    gia_000031:'Mail',
    gia_000032:'show on every page',
    gia_000033:'Reject Invoicing Button Prompt',
    gia_000034:'Please fill in the reason for rejection',
    gia_000035:'Invoice has been opened button reminder',
    gia_000036:'Paper invoice',
    gia_000037:'E-invoice',
    gia_000038:'hint',
    gia_000039:'Username/Tel',
    gia_000040:'application time:',
    gia_000041:'start date',
    gia_000042:'End date',
    gia_000043:'Enterprise invoice',
    gia_000044:'Personal invoice',
    gia_000045:'Official phone number',
    gia_000046:'Bank account',
    gia_000047:'Account',
    gia_000048:'recipient',
    gia_000049:'Shipping Address',
    gia_000050:'No data',
    gia_000051:'This operation will download the Excel file of the invoice, do you want to continue?',
    gia_000052:'hint',
    gia_000053:'OK',
    gia_000054:'Cancel',
    gia_000055:'start download',
    gia_000056:'Cancel download',
    gia_000057:'Invoice list',
    gia_000058:'Paper invoice and E-invoice'
}