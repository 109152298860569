export default {
    copy: 'Kopieren',
    invite_manage:"Freunde einladen",
    invite_enabled:"Freunde einladen zu aktivieren",
    invite_open_description:"Nach der Aktivierung ist die Funktion (Benutzer, die dem Öffentlichen noch nie gefolgt sind, einladen) verfügbar und erscheint im WeChat Public - Personal Center",
    invite_friend_link:"Einladungslink",
    invite_friend_link_copy:"Einladungslink erfolgreich kopieren",
    invite_friend_link_description:'Sie können das Menü "Freunde einladen" in WeChat Public hinzufügen, fügen Sie das Menü in "WeChat Public" "Benutzerdefiniertes Menü" hinzu, wählen Sie "Zur Seite springen" für den Menüinhalt und kopieren Sie die obige URL-Adresse in "Seitenadresse". ',
    invite_set_rewords:"Geschenkeinstellung",
    invite_stats_invite_count:"Anzahl der Einladung",
    invite_stats_invite_userCount:"Anzahl der Personen, die die Einladung veranlasst haben",
    invite_stats_receive_userCount:"Anzahl der Personen, die Einladung akzeptieren",
    invite_stats_chargeCompleted_userCount:"Anzahl der Freunde, die ihre erste Ladung abgeschlossen haben",
    invite_stats_totalBalance:"Kumulierte Geldbelohnungen für Eingeladene ({unit})",
    invite_stats_totalCoupon:"Kumulative Kartengutscheinprämien für Eingeladene (Stück)",
    invite_stats_receive_totalBalance:"Eingeladene Freunde<br/> akkumulieren Geldprämien ({unit})",
    invite_stats_receive_totalCoupon:"Eingeladene Freunde<br/>Kumulative Kartengutscheinprämien (eine)",
    invite_wechat_nickname:"WeChat-Spitzname des Einladenden",
    invite_receive_usercount_item:"Freunde, die die Einladung angenommen haben (Anzahl)",
    invite_receive_charge_completed:"Freunde, die ihre erste Ladung abgeschlossen haben (Nummer)",
    invite_receive_totalBalance:"Kumulative verdiente Geldbelohnungen ({unit})",
    invite_receive_totalCoupon:"Insgesamt erhaltene Coupons",
    invite_open_remind:"Sie haben noch keine Belohnung eingerichtet, Sie können die Funktion Freunde einladen nicht aktivieren",
    invite_enable_remind:"Ob {option} Freunde einlädt",
    invite_rewords:"Einladungsbelohnungen",
    invite_reword_amount:"Geldbelohnung({unit})",
    invite_reword_coupon:"Kartengutschein-Belohnung",
    invite_reword_coupon_remind:"Es kann nur eine Art von Kartengutschein hinzugefügt werden, es werden bis zu 100 unterstützt",
    invite_code:"Einladungscode",
    invite_object:"Einladungsobjekt",
    invite_object_tip:"Erforderlich, nicht mehr als 20 Zeichen",
    invite_object_input_remind:"Bitte geben Sie das Objekt der Einladung ein",
    invite_object_input_template:"Sie können auch {num} Zeichen eingeben",
    invite_code_or_object:"Einladungscode/Einladungsobjekt",
    benachrichtigen: "benachrichtigen",
    klar: "klar",
    Betrieb: "Betrieb",
    löschen: "Löschen",
    speichern: "Speichern",
    user_detail: "Benutzerdetails",
    bestätigen: 'Bestätigen',
    Abbrechen: "Abbrechen",
    ändern: "Ändern",
    erinnern: "Erinnern",
    generate_invite_code_success_template: "Einladungscode erfolgreich generiert, Einladungscode: {code}",
    export_invited_data: "Exportieren",
    export_invited_data_excel: "Diese Aktion lädt eine Excel-Datei mit den Daten der eingeladenen Freunde herunter, möchten Sie fortfahren?" ,
    head_portrait: "Avatar",
    coupon_title: "Name des Kartengutscheins",
    coupon_amount: "Menge",

    coupon_type: "Coupon type",
    choose_coupon: "Add_coupon",
    voucher_coupon: "voucher_coupon",
    discount_coupon: "discount_coupon",
    Typ: "Typ",
    reword_set_rules_remind01: "Die folgenden Belohnungen können einzeln oder in Kombination gesetzt werden",
    reword_set_rules_remind02: "Eingeladene Freunde (Nutzer, die der Öffentlichkeit noch nie gefolgt sind), die ihre erste Ladung abschließen und bezahlen, geben dem Einladenden eine Belohnung",
    reword_set_rules_remind03: "Setzen Sie mindestens eine Belohnung für den Eingeladenen fest",
    reword_set_rules_remind04: "Legen Sie mindestens eine Belohnung für die Annahme der Einladung eines Freundes fest",
    reword_set_rules_remind05: "Inviter Cash Reward wurde falsch eingegeben",
    reword_set_rules_remind06: "Die Geldprämie des Eingeladenen wurde falsch eingegeben",
    reword_set_rules_remind07: "Falsche Anzahl von Gutscheinen",
    reword_set_rules_remind08: "Die Anzahl der Kartengutscheine darf 100 nicht überschreiten",
    coupon_base_invoice_5: "Maximal 100 Gutscheine pro Karte",
    invitee_rewords: "Belohnungen für Freunde, die Einladungen annehmen",
    invitee_rewords_place_rule_remind: "Belohnung für Freunde, die die Einladung annehmen (Nutzer, die der Öffentlichkeit noch nie gefolgt sind), sobald sie die Einladung annehmen",
    schließen: "schließen",
    offen: "offen",
    begin_date_select_remind: 'Startdatum auswählen',
    end_date_select_remind: "Enddatum auswählen",
    bis: "bis",
    enter_activity_explain: "Bitte geben Sie eine Beschreibung der Aktivität ein",
    no_limit: "Keine Begrenzung",
    Grenze: "Grenze",
    single_user_charge_amount: "Die kumulativen Gesamtkosten für einen einzelnen Nutzer sind erfüllt",
    user_charge_amount: "Der kumulierte Gebührenbetrag für einen einzelnen Nutzer ist erfüllt",
    only_no_charged: "Nur Nutzer, die noch nie auf der Plattform bezahlt haben",
    userLevel: "Benutzerebene",
    userClass: "UserClass",
    promotion_type1: "Aufladebonus (bar)",
    promotion_type2: "Aufstockungsbonus (Bargeld+Kartengutschein)",
    promotion_type3: "Kostenloses Aufladen (Kartengutschein)",
    promotion_type4: "Kostenloser Kartengutschein",
    promotion_type5: "Verkaufsgutscheine",
    cycle_type1: "sub/event full",
    cycle_type2: "Zeiten/Monat",
    cycle_type3: "Zeiten/Woche",
    cycle_type4: "mal/täglich",
    default_userLevel: "Nicht-Benutzer",
    mouth_1: "1 Monat",
    mouth_2: "3 Monate",
    mouth_3: "12 Monate",
    promotion_date: "campaign_period",
    promotion_invoice_1: "Bitte geben Sie einen Kampagnennamen mit weniger als 20 Zeichen ein",
    promotion_invoice_2: "Bitte geben Sie die Startzeit der Kampagne ein",
    promotion_invoice_3: "Bitte geben Sie die Endzeit der Kampagne an",
    promotion_invoice_4: "Bitte geben Sie die korrekte Kampagnenzeit ein",
    promotion_invoice_5: "Bitte wählen Sie das Ziel der Kampagne",
    promotion_invoice_6: "Bitte geben Sie einen Kampagnennamen mit weniger als 150 Zeichen ein",
    promotion_invoice_7: "Geben Sie mehr als 4 Ziffern für den Aufstockungsbetrag/Prämienbetrag ein",
    promotion_invoice_8: "Die Anzahl der erfolgreichen Aufladungen für einen einzelnen Benutzer wurde falsch eingegeben",
    promotion_invoice_9: "Fehler bei der Eingabe der kumulierten Gesamtkosten für einen einzelnen Benutzer",
    promotion_invoice_10: "Fehler bei der Eingabe der Anzahl der erfolgreichen Aufladungen für alle Benutzer",
    promotion_invoice_11: "Fehler bei der Eingabe des Gesamtbetrags des verschenkten Geldes",
    promotion_invoice_14: "Es werden bis zu 6 Stufen unterstützt",
    promotion_invoice_15: "Bestätigen Sie die Löschung dieses aktiven Slots",
    enter_amount: "Bitte geben Sie den Betrag ein",
    Woche: "1 Woche",
    enter_charge_placeholder: "Bitte geben Sie den Ladezustand ein",
    support_refund: "Rückerstattung der Unterstützung",
    no_refund: "Keine Rückerstattung",
    enter_success_times_1: "Bitte geben Sie die Anzahl der erfolgreichen Aufladeversuche an",
    enter_success_times_2: "Bitte geben Sie die Anzahl der erfolgreichen Anträge ein",
    enter_discount_count: "Bitte geben Sie den Gesamtbetrag des verschenkten Geldes ein",
    enter_coupon_name: "Bitte geben Sie den Namen des Kartengutscheins ein",
    Abfrage: "Abfrage",
    common_00241: "Erfolgreich hinzugefügt",
    wechat_user: "WeChat_user",
    confirm_delete_activate: "Bestätigen Sie die Löschung dieser Aktivität",
    delete_success: "Erfolgreich gelöscht",
    Gültigkeit: "Gültigkeit",
    activity_detail: "Einzelheiten",
    Tag: "Tage",
    select_coupon_invoice_1: "Wählen Sie bis zu 6 Gutscheine",
    select_coupon_invoice_2: "Wählen Sie bis zu 1 Gutschein",
    kleiner_als_gleich_bis: "kleiner_als_gleich_bis",
    größer_als_gleich_bis: "größer_als_oder_gleich_bis",
    promotion_invoice_17: "Die gesamten kumulativen Kosten für einen einzelnen Nutzer entsprechen {equal}{feeAmount}$ für den Kampagnenzeitraum",
    promotion_invoice_12: "Die gesamten kumulativen Kosten für einen einzelnen Nutzer entsprechen {equal}{feeAmount}$ für {feeAmountPeriod} Monate.", promotion_invoice_24: "Die gesamten kumulativen Kosten für einen einzelnen Nutzer entsprechen {equal}{feeAmount}$ für {equal} Monate.",
    promotion_invoice_24: "Das kumulative Ladevolumen eines einzelnen Nutzers entspricht {equal}{powerAmount} Grad für den Kampagnenzeitraum",
    promotion_invoice_21: "Es liegt ein Fehler bei der Eingabe des kumulierten Gebührenbetrags für einen einzelnen Benutzer vor",
    promotion_invoice_22: "Der kumulative Ladebetrag eines einzelnen Nutzers entspricht {LeistungsbetragZeitraum} für {gleich}{Leistungsbetrag} Monate",
    promotion_invoice_23: "Die kumulierte Ladekapazität eines einzelnen Nutzers entspricht 7 Tagen {gleich}{powerAmount} Grad",
    modify_success: 'Erfolgreich geändert',
    count_time: "Zählzeit",
    count_coupons: "Gesamtzahl der ausgestellten Gutscheine",
    get_coupon_people: "Gesamtzahl der Empfänger",
    get_coupon_count: "total_coupon_count",
    provide_coupon: "Ausgestellte Gutscheine",
    all_coupons_count: "Kumulative Anzahl der ausgegebenen Coupons",
    fee_total: "Gesamtgebühr",
    use_condition: "Bedingungen für die Nutzung",
    verwendet: "Verwendete Menge",
    unbenutzt: "Verfügbare Menge",
    Abgelaufen: "Abgelaufene Menge",
    user_collection_detail: "Status der Benutzersammlung",
    Spitzname: "Spitzname",
    Geschlecht: "Geschlecht",
    user_mobile: "Benutzername (Handynummer)",
    karte_nummer: "karte_nummer",
    collection_time: "Abholzeit",
    gift_coupon_amount: "gift_coupon_amount",
    gifts_coupon_length:"Gutscheinanzahl",
    u3_card: "Online-Aufweis",
    promotion_use_coupon_condition:"Einmalige Gebühr {msg} entspricht {feeAtLeast}EUR",
    service_fee: "Servicegebühr",
    promotion_amount:"Geschenkbetrag(EUR)",
    amount_balance:"Aufladen-Gesamtbetrag",
    promotion_charge_people:"Aufgeladete Personen Anzahl",
    promotion_charge_count:"Aufladeanzahl",
    user_charge_detail:"Gespeicherter Wert",
    charge_time:"Gespeicherte-Zeit",
    export_data: "Daten exportieren",
    coupon_detail:"Gutschein Details",
    start_download: "Download starten",
    cancel_download: "Download abbrechen",
    promotion_invoice_16:"Bitte geben Sie eine Ereignisbeschreibung mit weniger als 500 Wörtern ein",

    enable: "aktivieren",
    invite_00001: 'Freunde einladen',
    invite_00002: 'Marketing',
    invite_00003: 'Yes',
    invite_00004: 'No',
    invite_00005: 'Freunde zum Verwalten einladen',
    invite_00006: 'Einladungsliste',
    invite_00007: 'Belohnungseinstellung',
    invite_00008: 'Abbrechen',
    invite_00009: 'speichern',
    invite_00010: 'bearbeiten',
    invite_00011: 'Gutscheintyp',
}
