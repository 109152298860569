export default  {
    charging_package45:"用现金抵用电费及服务费",
    charging_package46:"用电量同时抵用电费及服务费",
    charging_package47:"用现金抵用服务费",
    charging_package48:"用电量抵用服务费",
    charging_package52:"用户购买/兑换套餐之日起{day}日",

    cash_coupon0001: '营销中心',
    cash_coupon0002: '优惠工具',
    cash_coupon0003: '名称',
    cash_coupon0004: '请输入',
    cash_coupon0005: '重置',
    cash_coupon0006: '查询',
    cash_coupon0007: '列表',
    cash_coupon0008: '新建',
    cash_coupon0009: '类型',
    cash_coupon0010: '额度',
    cash_coupon0011: '度',
    cash_coupon0012: '元',
    cash_coupon0013: '有效期',
    cash_coupon0014: '操作',
    cash_coupon0015: '详情',
    cash_coupon0016: '电量包',
    cash_coupon0017: '代金券',
}
