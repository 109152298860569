export default {
    teamPlaylistActivity_00001: "活动设置",
    teamPlaylistActivity_00002: "参与队伍",
    teamPlaylistActivity_00003: "活动效果",
    teamPlaylistActivity_00004: "活动规则及设置帮助",
    teamPlaylistActivity_00005: "编辑活动公告",
    teamPlaylistActivity_00006: "移除",
    teamPlaylistActivity_00007: "请输入队伍名称或ID",
    teamPlaylistActivity_00008: "队伍ID",
    teamPlaylistActivity_00009: "队伍名称",
    teamPlaylistActivity_000010: "队伍状态（本周）",
    teamPlaylistActivity_000011: "队伍状态",
    teamPlaylistActivity_000012: "操作",
    teamPlaylistActivity_000013: "队伍详情",
    teamPlaylistActivity_000014: "历史统计",
    teamPlaylistActivity_000015: "组队打榜启用",
    teamPlaylistActivity_000016: "队伍状态",
    teamPlaylistActivity_000017: "全部",
    teamPlaylistActivity_000018: "组队中",
    teamPlaylistActivity_000019: "打榜中",
    teamPlaylistActivity_000020: "打榜成功",
    teamPlaylistActivity_000021: "打榜失败",
    teamPlaylistActivity_000022: "解散",
    teamPlaylistActivity_000023: "最少组队人数",
    teamPlaylistActivity_000024: "打榜等级",
    teamPlaylistActivity_000025: "队伍列表",
    teamPlaylistActivity_000026: "任务电量",
    teamPlaylistActivity_000027: "打榜奖励",
    teamPlaylistActivity_000028: "队长额外奖励",
    teamPlaylistActivity_000029: "添加等级",
    teamPlaylistActivity_000030: "查询",
    teamPlaylistActivity_000031: "场站名称",
    teamPlaylistActivity_000032: "运营商",
    teamPlaylistActivity_000033: "添加场站",
    teamPlaylistActivity_000034: "批量移除",
    teamPlaylistActivity_000035: "全部场站",
    teamPlaylistActivity_000036: "自营场站",
    teamPlaylistActivity_000037: "自定义场站",
    teamPlaylistActivity_000038: "活动信息",
    teamPlaylistActivity_000039: "可用站点",
    teamPlaylistActivity_000040: "最少组队人数为2",
    teamPlaylistActivity_000041: "瓜分红包",
    teamPlaylistActivity_000042: "奖励红包",
    teamPlaylistActivity_000043: "请填写活动公告",
    teamPlaylistActivity_000044: "请先创建组队打榜活动",
    teamPlaylistActivity_000045: "不可删除",
    teamPlaylistActivity_000046: "最多50个等级",
    teamPlaylistActivity_000047: "需设置高于前一级的数值",
    teamPlaylistActivity_000048: "任务电量最多支持一位小数",
    teamPlaylistActivity_000049: "奖励最多支持两位小数",
    teamPlaylistActivity_000050: "开启",



    

    

    

    

    // 站点弹窗
    all_site:"全部站点",
    self_run_site:"自营站点",
    custom_site:"自定义站点",
    coupon_00028: '站点名称',
    coupon_00029: '查询',
    coupon_00030: '批量移除',
    add_site:"添加站点",
    site_name: "站点名称",
    operator: "运营商",
    coupon_00031: '操作',
    coupon_00032: '移除',
    enter_site:"输入站点名、运营商名",
    query: "查询",
    confirm: "确认",
    siteStatus: "站点状态",
    enabled: "已启用",
    disabled: "未启用",
    open: "开放",
    unopen: "不开放",
    isOpen: "对外开放",
    chargeEquipment: "充电桩",
    ac:"交",
    dc:"直",
    member_00072: '取消',
    member_00073: '添加',
    coupon_error_message_10:"请选择站点",


    





    
}