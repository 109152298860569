import view_device_c1_one from '../../img/view_device_c1_one.jpg'
import view_first from '../../img/view_first.png'
import device_c1_three from '../../img/device_c1_three.jpg'
import view_device_c1_two from '../../img/view_device_c1_two.jpg'
import login_online from '../../img/login-online.jpg'
import login_analysis from '../../img/login-analysis.jpg'
import view_device_c4 from '../../img/view_device_c4.jpg'
import device_c4_one from '../../img/device_c4_one.jpg'
import view_third from '../../img/view_third.png'
import view_device_c6 from '../../img/view_device_c6.jpg'
import device_c6s_one from '../../img/device_c6s_one.jpg'
import view_device_c6s from '../../img/view_device_c6s.jpg'
import view_device_c2_one from '../../img/view_device_c2_one.jpg'
import device_c9_one from '../../img/device_c9_one.png'

import view_device_c1_one_en from '../../img/view_device_c1_one_en.png'
import view_first_en from '../../img/view_first_en.jpg'
import device_c1_three_en from '../../img/device_c1_three_en.png'
import view_device_c1_two_en from '../../img/view_device_c1_two_en.png'
import login_online_en from '../../img/login-online_en.png'
import login_analysis_en from '../../img/login-analysis_en.jpg'
import view_device_c4_en from '../../img/view_device_c4_en.png'
import device_c4_one_en from '../../img/device_c4_one_en.png'
import view_third_en from '../../img/view_third_en.png'
import view_device_c6_en from '../../img/view_device_c6_en.png'
import device_c6s_one_en from '../../img/device_c6s_one_en.png'
import view_device_c6s_en from '../../img/view_device_c6s_en.png'
import view_device_c2_one_en from '../../img/view_device_c2_one_en.png'
import device_c9_one_en from '../../img/device_c9_one_en.png'
const resources = {
    cn : {
      //c1开机logo
      view_device_c1_one:view_device_c1_one,
      //c1主界面
      device_c1_three:device_c1_three,
      //c1主页面大图
      view_device_c1_two:view_device_c1_two,
      //c2主页面
      view_first:view_first,
      //登录页在线用户管理图片
      login_online:login_online,
      //登录页营收分析图片
      login_analysis:login_analysis,
      //c4屏幕主页面大图
      view_device_c4:view_device_c4,
      //c4屏幕主页面
      device_c4_one:device_c4_one,
      //c6主页图
      view_third:view_third,
      view_device_c6:view_device_c6,
      device_c6s_one:device_c6s_one,
      view_device_c6s:view_device_c6s,
      view_device_c2_one:view_device_c2_one,
      //c9
      device_c9_one: device_c9_one,
    },
    en :{
      //c1开机logo
      view_device_c1_one:view_device_c1_one_en,
      //c1主界面
      device_c1_three:device_c1_three_en,
      //c1主页面大图
      view_device_c1_two:view_device_c1_two_en,
      //c2主页面
      view_first:view_first_en,
      //登录页在线用户管理图片
      login_online:login_online_en,
      //登录页营收分析图片
      login_analysis:login_analysis_en,
      //c4屏幕主页面大图
      view_device_c4:view_device_c4_en,
      //c4屏幕主页面
      device_c4_one:device_c4_one_en,
      //c6主页图
      view_third:view_third_en,
      view_device_c6:view_device_c6_en,
      device_c6s_one:device_c6s_one_en,
      view_device_c6s:view_device_c6s_en,
      view_device_c2_one:view_device_c2_one_en,
      //c9
      device_c9_one: device_c9_one_en,
    }
}
export default resources;