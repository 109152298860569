export default {
  limitedCharge_00001: "车牌号/VIN",
  limitedCharge_00002: "列表",
  limitedCharge_00003: "车型",
  limitedCharge_00004: "限制原因",
  limitedCharge_00005: "限制时间",
  limitedCharge_00006: "确定要将此车辆在列表中移除？",
  limitedCharge_00007: "禁止原因",
  limitedCharge_00008: "禁止时间",
  limitedCharge_00009: "",
  limitedCharge_00010: "",
  limitedCharge_00011: "",
  limitedCharge_00012: "",
  limitedCharge_00013: "",
}