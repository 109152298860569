export default {
    groupDetail_00001: "Individual payment group details",
    groupDetail_00002: "Fleet payment fleet details",
    groupDetail_00003: "Allocate amount to members",
    groupDetail_00004: "Station settings",
    groupDetail_00005: "Members",
    groupDetail_00006: "Recognize the EV other than user",
    groupDetail_00007: "CPO settings",
    groupDetail_00008: "Group management",
    groupDetail_00009: "Export",
    groupDetail_00010: "Delete group",
    groupDetail_00011: "Unrecoverable after deleting a group",
    groupDetail_00012: "Transactions",
    groupDetail_00013: "This operation will download the Excel file of the group user, do you want to continue?",
    groupDetail_00014: "Are you sure you want to delete the fleet {name}?",
    groupDetail_00015: "Operation cancelled",
    groupDetail_00016: "basic settings",
    groupDetail_00017: "Fleet name",
    groupDetail_00018: "payment method",
    groupDetail_00019: "Privilege setting",
    groupDetail_00020: "Maximum unpaid amount per member({unit})",
    groupDetail_00021: "Maximum number of unpaid transactions per member (transactions)",
    groupDetail_00022: "Whether to accept the charging pile opening time limit",
    groupDetail_00023: "Paid by the individual",
    groupDetail_00024: "Paid by the fleet",
    groupDetail_00025: "{num}",
    groupDetail_00026: "Whether to allow the use of coupons",
    groupDetail_00027: "Whether to support group self-service terminal recharge",
    groupDetail_00028: "Assign account information",
    groupDetail_00029: "Balance",
    groupDetail_00030: "Top-up record",
    groupDetail_00031: "Account top-up",
    groupDetail_00032: "Include cashback{promotionBalance}{unit}",
    groupDetail_00033: "Please save the previous operation!",
    groupDetail_00034: "Charging site name",
    groupDetail_00035: "Discounts for stations",
    groupDetail_00036: "Add discount",
    groupDetail_00037: "Remove discounts",
    groupDetail_00038: "Rates",
    groupDetail_00039: "Discount offer",
    groupDetail_00040: "Operate",
    groupDetail_00041: "DC",
    groupDetail_00042: "AC",
    groupDetail_00043: "The total cost of single charging session is not discounted",
    groupDetail_00044: "Single charge service fee is not discounted",
    groupDetail_00045: "Single charge service fee {des} discount",
    groupDetail_00046: "The total cost of single charging session is {des} off",
    groupDetail_00047: "The total cost of a single charging session is free",
    groupDetail_00048: "Free of charge for a single charging session",
    groupDetail_00049: "Discount setting",
    groupDetail_00050: "Details",
    groupDetail_00051: "remove",
    groupDetail_00052: "Confirm to delete the charging station from the fleet?",
    groupDetail_00053: "Undeleted",
    groupDetail_00054: "Public rates",
    groupDetail_00055: "If the rates is modified, click the 'Apply rates' button to make the changes take effect !",
    groupDetail_00056: "AC rates",
    groupDetail_00057: "DC rates",
    groupDetail_00058: "Select AC rates",
    groupDetail_00059: "Select DC rates",
    groupDetail_00060: "The total cost of a single charge is not discounted (%)",
    groupDetail_00061: "Single charge service fee discount (%)",
    groupDetail_00062: "85 means 15% off, 0 means free",
    groupDetail_00063:"Example: If you set a 15% discount on the service fee for single charging session, the charge for this time is 100{unit} (including 70{unit} for electricity and 30{unit} for service), and the actual amount to be paid this time is 95.5{unit}(70) +30*85%=95.5).",
    groupDetail_00064: "The total cost of single charging session is discounted (%）",
    groupDetail_00065:"For example: If you set a 15% discount on the total cost of single charging session, and the total charge of this charge is 100{unit}, the actual amount to be paid this time is 85{unit} (100*85%=85).",
    groupDetail_00066: "Save Settings",
    groupDetail_00067: "Complete and push immediately",
    groupDetail_00068: "Whether to allocate amount to group members",
    groupDetail_00069: "Fleet information",
    groupDetail_00070: "Fleet name",
    groupDetail_00071: "current balance:{balance}{unit}",
    groupDetail_00072: "Include gift{promotionBalance}{unit}",
    groupDetail_00073: "Fill in the recharge amount",
    groupDetail_00074: "Give away",
    groupDetail_00075: "login password",
    groupDetail_00076: "Please enter the password",
    groupDetail_00077: "Turn on car recognition but not user recognition",
    groupDetail_00078: "Username, mobile number",
    groupDetail_00079: "Add member",
    groupDetail_00080: "User name (mobile number)",
    groupDetail_00081: "Account Settings",
    groupDetail_00082: "Set as administrator",
    groupDetail_00083: "Cancel an administrator",
    groupDetail_00084: "Remove",
    groupDetail_00085: "User details",
    groupDetail_00086: "Are you sure to delete [{nickname}] from the group?",
    groupDetail_00087: "The user also has a group allocation balance of {num} euro, which will be automatically recovered by the system after deletion.",
    groupDetail_00088: "WeChat user member",
    groupDetail_00089: "Search member",
    groupDetail_00090: "card number",
    groupDetail_00091: "Fleet members",
    groupDetail_00092: "Name",
    groupDetail_00093: "Please enter your nickname, mobile phone number, card number, and multiple query conditions support separated by comma (,), line feed, space, and comma (,)",
    groupDetail_00094: "search result",
    groupDetail_00095: "Clear",
    groupDetail_00096: "added",
    groupDetail_00097: "add",
    groupDetail_00098: "Has been set",
    groupDetail_00099: "Set as administrator",
    groupDetail_00100: "user list",
    groupDetail_00101: "Card details",
    groupDetail_00102: "1. If you set 'Whether to allow the use of coupons' to 'Yes', users of the user group can use coupons when paying for orders.",
    groupDetail_00103: "1.1. When paying an order, give priority to the group bound site tariff discount, use the bill amount after the group bound site tariff discount, and then select the coupon to use according to the coupon meeting the conditions.",
    groupDetail_00104: "1.2、 For example: <br/> group users who charge 100 {unit} (including electricity 70 {unit} and service charge 30 {unit}) this time can enjoy the discount of group binding site charges (8.5% off the service charge for a single charge), and there is a coupon (the service charge for a single charge meets 20 {unit} 10% off the service charge), <br/> after using the discount of group binding site charges, the bill amount is 95.5 {unit} (including electricity 70 {unit}) , the service fee is 25.5 {unit} (30 * 85%). The coupon is satisfied (the service fee for a single charge is 20 {unit} 10% off the service fee). <br/> after using the coupon, the bill amount is 92.95 {unit} (including 70 {unit} + 22.95 {unit} (25.5 * 90%). Finally, 92.95 {unit} needs to be actually paid this time.",
    groupDetail_00105: "2. If 'allow coupons' is set to 'no', group users cannot use coupons when paying orders.",
    groupDetail_00106: "After the setting of car recognition without person recognition is enabled, charging at the preferential site bound by the group for the first time will record the VIN corresponding to the vehicle of the group member. A group member can only correspond to one vehicle. For subsequent charging at the group's preferential site, only vehicles with VIN bound by group members can enjoy the site tariff discount set by the group.",
    groupDetail_00107: "• it is recommended that after this setting is enabled, group members need to charge once at the group discount site as soon as possible to bind Vin.",
    groupDetail_00108: "• when this setting is enabled, group members will not be able to charge using the AC pile.",
    groupDetail_00109: "invalid parameter",
    groupDetail_00110: "Please enter the nickname, mobile phone number or card number you want to query",
    groupDetail_00111: "The operation was cancelled",
    groupDetail_00112: "Removal succeeded",
    groupDetail_00113: "Support online invoice application",
    groupDetail_00114: "Fleet account",
    groupDetail_00115: "Fleet account information",
    groupDetail_00116: "Minimum balance limit({unit})",
    groupDetail_00117: "In the fund account payment mode, when the account balance is lower than the minimum balance, users in the fleet cannot start charging (if it is set to 0 {unit}, it means there is no limit)",
    groupDetail_00118: "Reminder balance({unit})",
    groupDetail_00119: "In the fund account payment mode, when the account balance is lower than the reminder balance, the fleet administrator will receive a reminder SMS. (Set to {unit} means no reminder)",
    groupDetail_00120: "You can only enter 0 or a positive integer",
    groupDetail_00121: "Use currency",
    // 20220124--添加优惠站点弹窗
    groupDetail_10000: "Add discount",
    groupDetail_10001: "Enter station name",
    groupDetail_10002: "Batch add",
    groupDetail_10003: "Station",
    groupDetail_10004: "Operator",
    groupDetail_10005: "Chargers",
    groupDetail_10006: "Station status",
    groupDetail_10007: "operation",
    groupDetail_10008: "add",
    groupDetail_10009: "Have been added",
    groupDetail_10010: "Is enabled",
    groupDetail_10011: "Is not enabled",
    groupDetail_10012: "ac",
    groupDetail_10013: "dc",
    groupDetail_10014: "next",
    groupDetail_10015: "Please select tariff",
}