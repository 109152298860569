export default {
    platformLog_00001: "Triggerzeit",
    platformLog_00002: "Startdatum",
    platformLog_00003: "Enddatum",
    platformLog_00004: "Seriennummer des Geräts",
    platformLog_00005: "Bestell-ID",
    platformLog_00006: "Kommunikationsprotokoll",
    platformLog_00007: "Kommunikationsmethode",
    platformLog_00008: "Nachrichtenschlüsselwert",
    platformLog_00009: "Nachrichtendaten",
    platformLog_00010: "Nachrichtenübersetzung",
    platformLog_00011: "Cloud-Bereitstellung",
    platformLog_00012: "Gerätebericht",
    platformLog_00013: "Ansicht",
    platformLog_00014: "Umtauschen mit dem neuen text",
    platformLog_00015: 'Fuzzy Matching, mehrfach durch Kommas getrennt',
    platformLog_00016: "Der Datumsbereich darf 90 Tage nicht überschreiten, bitte erneut auswählen!",
    platformLog_00018: "Bitte wählen Sie ein Datum aus!",
    platformLog_00019: "Bitte Inhalt eingeben",
    platformLog_00020: "Nachrichteninhalt",
    platformLog_00021: "Bitte das Nachrichtendatensegment eingeben",
    platformLog_00022: "Bereits signierte daten",
    platformLog_00023: "Bereits signierte daten",
    platformLog_00024: "Ein öffentlicher schlüssel",
}