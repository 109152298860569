<template lang="html">
    <el-dialog
        :title="$t('eqDetails_30003')"
        :visible.sync="visible"
        :lock-scroll="false"
        :before-close="close"
        width="800px"
    >
        <div class="check" v-loading="loading">
            <!-- 确认自检 -->
            <div class="page" v-if="step == 'a'">
                <img src="../../../assets/img/check.png" />
                <div class="text">
                    <p class="txt">{{ $t("eqDetails_30004") }}</p>
                    <p class="txt">{{ $t("eqDetails_30005") }}</p>
                    <p class="txt">{{ $t("eqDetails_30006") }}</p>
                </div>
                <div class="btn">
                    <el-button @click="close">{{
                        $t("common_00215")
                    }}</el-button>
                    <el-button @click="getCheckResult('prev')">{{
                        $t("eqDetails_30007")
                    }}</el-button>
                    <el-button type="primary" @click="confirmCheck">{{
                        $t("eqDetails_30008")
                    }}</el-button>
                </div>
            </div>
            <!-- loading -->
            <div class="page" v-if="step == 'b'">
                <div class="loader"></div>
                <p class="checkText">{{ $t("eqDetails_30009") }}</p>
            </div>
            <!-- 失败状态 -->
            <div class="page" v-if="step == 'c'">
                <img src="../../../assets/img/checkErr.png" />
                <div class="text">
                    <p class="checkText">{{ formmatDeviceCheckStatus() }}</p>
                </div>
                <div class="btn">
                    <el-button @click="close">{{
                        $t("common_00215")
                    }}</el-button>
                    <el-button type="primary" @click="confirmCheck">{{
                        $t("eqDetails_30010")
                    }}</el-button>
                </div>
            </div>
            <!-- 自检结果 -->
            <div class="table" v-if="step == 'd'">
                <p class="resultTime">
                    {{ $t("eqDetails_30011") }}：{{ resultTime }}
                </p>
                <el-tabs v-model="checkActive" class="checkTab">
                    <el-tab-pane :label="$t('eqDetails_30012')" name="a1">
                        <div class="headText">
                            <p>{{ $t("eqDetails_30031") }}</p>
                            <p class="ml270">{{ $t("eqDetails_30032") }}</p>
                        </div>
                        <el-collapse v-model="activeNames">
                            <el-collapse-item
                                v-for="(i, key) in checkResult"
                                :name="key"
                                :key="key"
                                :disabled="
                                    !i.idMsg &&
                                    Object.keys(i.idMsgExt).length == 0
                                "
                            >
                                <template slot="title">
                                    <i
                                        class="header-icon el-icon-success"
                                        v-if="i.status == 0"
                                    ></i>
                                    <i
                                        class="header-icon el-icon-error"
                                        v-else
                                    ></i>
                                    <span class="item-title">{{ i.msg }}</span>
                                    <span
                                        class="item-val"
                                        v-if="
                                            !i.idMsg &&
                                            Object.keys(i.idMsgExt).length ==
                                                0 &&
                                            i.unit
                                        "
                                    >
                                        {{ i.children[0].value[0] }}{{ i.unit }}
                                    </span>
                                    <span
                                        class="item-val"
                                        v-if="
                                            !i.idMsg &&
                                            Object.keys(i.idMsgExt).length ==
                                                0 &&
                                            Object.keys(i.unitExt).length > 0
                                        "
                                    >
                                        {{ i.unitExt[i.children[0].value[0]] }}
                                    </span>
                                    <span
                                        class="item-val"
                                        v-if="key == 'CH1035'"
                                    >
                                        {{
                                            fommatCH1035(i.children[0].value[0])
                                        }}
                                    </span>
                                    <span
                                        class="item-val"
                                        v-if="key == 'CH1021'"
                                    >
                                        {{
                                            i.status == 0
                                                ? $t("common_00274")
                                                : $t("common_00273")
                                        }}
                                    </span>
                                </template>
                                <div v-if="i.idMsg">
                                    <div v-for="item in i.children">
                                        <div
                                            class="child"
                                            v-if="
                                                (i.status == 1 &&
                                                    (item.status == 1 ||
                                                        item.status == 2)) ||
                                                i.status == 0
                                            "
                                        >
                                            <p v-if="i.idMsg" class="flex">
                                                {{ i.idMsg }}{{ item.id }}
                                            </p>
                                            <div class="flex kv" v-if="i.unit">
                                                <p v-for="it in item.value">
                                                    {{ it }}{{ i.unit }}
                                                </p>
                                            </div>
                                            <div
                                                class="kv flex"
                                                v-if="
                                                    Object.keys(i.unitExt)
                                                        .length > 0
                                                "
                                            >
                                                <p v-for="it in item.value">
                                                    {{ i.unitExt[it] }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="Object.keys(i.idMsgExt).length > 0">
                                    <div v-for="item in i.children">
                                        <div
                                            class="child"
                                            v-if="
                                                (i.status == 1 &&
                                                    (item.status == 1 ||
                                                        item.status == 2)) ||
                                                i.status == 0
                                            "
                                        >
                                            <p class="flex">
                                                {{ i.idMsgExt[item.id] }}
                                            </p>
                                            <div class="flex kv" v-if="i.unit">
                                                <p v-for="it in item.value">
                                                    {{ it }}{{ i.unit }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('eqDetails_30013')" name="a2">
                        <div class="headText">
                            <p>{{ $t("eqDetails_30014") }}</p>
                        </div>
                        <el-collapse v-model="activeNames1">
                            <el-collapse-item
                                v-for="(i, key) in lifeResult"
                                :name="key"
                                :key="key"
                            >
                                <template slot="title">
                                    <i
                                        class="header-icon el-icon-success"
                                        v-if="i[0].status == 0"
                                    ></i>
                                    <i
                                        class="header-icon el-icon-error"
                                        v-else
                                    ></i>
                                    <span class="item-title">{{ key }}</span>
                                </template>
                                <div class="child" v-for="item in i">
                                    <p class="flex">{{ item.num }}</p>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                </el-tabs>
                <div class="btn">
                    <el-button @click="goToDevice">{{
                        $t("common_00307")
                    }}</el-button>
                    <el-button @click="goToTicketPage">{{
                        $t("common_31006")
                    }}</el-button>
                    <el-button type="primary" @click="complete">{{
                        $t("common_00272")
                    }}</el-button>
                    <el-button
                        type="primary"
                        @click="restoreStop"
                        v-if="stopBtnFLAG && !isPrev"
                        >{{ $t("eqDetails_30015") }}</el-button
                    >
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { requestUrl, ajax } from "@/assets/utils/network";
import { mergeObjects, updateStatus } from "@/assets/utils/format";
import { formmatTime } from "@/assets/utils/time";
import checkHealth from "@/assets/js/checkHealth.js";
import checkHealthEu from "@/assets/js/checkHealthEu.js";
export default {
    data() {
        return {
            checkActive: "a1",
            step: "a",
            isCollapseDisabled: false,
            loading: false,
            config: window.product_area != "cn" ? checkHealthEu : checkHealth,
            checkResult: "",
            activeNames: [],
            activeNames1: [],
            timerId: null,
            timerOutId: null,
            deviceCheckStatus: "IN_USE",
            resultTime: "",
            isPrev: false,
            lifeResult: {},
            errorText: '',
        };
    },
    props: [
        "visible",
        "deviceId",
        "stopBtnFLAG",
        "sourceId",
        "siteId",
        "version",
        "checkPrev",
    ],
    methods: {
        // 清楚定时器
        stopTimer() {
            clearTimeout(this.timerId);
            clearTimeout(this.timerOutId);
        },
        // 格式化自检状态
        formmatDeviceCheckStatus() {
            console.log(this.deviceCheckStatus)
            if (this.deviceCheckStatus === "IN_USE") {
                return this.$t("eqDetails_30016");
            } else if (this.deviceCheckStatus === "OFFLINE") {
                return this.$t("eqDetails_30017");
            } else if (this.deviceCheckStatus === "codeError") { 
                return this.errorText;
            } else {
                return this.$t("eqDetails_30016");
            }
        },
        // 格式化时间
        formmatTime(time) {
            return formmatTime(time, true);
        },
        //1035
        fommatCH1035(val) {
            if (val < 10) {
                return this.$t("eqDetails_30020");
            } else if (val >= 10 && val <= 20) {
                return this.$t("eqDetails_30021");
            } else {
                return this.$t("eqDetails_30022");
            }
        },
        //恢复急停
        restoreStop() {
            const obj = { id: this.deviceId };
            ajax({
                type: "POST",
                url: `${requestUrl}/device/emergency/reset`,
                dataType: "json",
                data: JSON.stringify(obj),
                success: (response) => {
                    const info = JSON.parse(response);
                    if (info.error) {
                        this.$message.error(
                            info.error.msg ? info.error.msg : info.error.code
                        );
                    } else {
                        this.loading = true;
                        setTimeout(() => {
                            location.reload();
                        }, 5000);
                    }
                },
            });
        },
        //发起工单
        goToTicketPage() {
            window.open(
                `./workOrder.html?parentRoute=deviceInfo&classifyType=2&sourceId=${this.sourceId}&desc=error&siteId=${this.siteId}`
            );
        },
        //设备保养
        goToDevice() {
            this.$emit("cancel", false);
            const url = window.location.href;
            const filename = url.substring(url.lastIndexOf("/") + 1);
            const pagename = filename.split(".")[0];
            if (pagename != "deviceInfo") {
                window.open(
                    `./deviceInfo.html?parentRoute=deviceMonitor_eq&id=${this.deviceId}&tab=maintenance`
                );
            } else {
                this.$emit("tabActive", "maintenance");
            }
        },
        close() {
            this.$emit("cancel", false);
        },
        complete() {
            this.$emit("cancel", false);
            this.step = "a";
        },
        //开始自检 sid
        confirmCheck() {
            this.checkResult = "";
            this.lifeResult = {};
            if (this.version < (window.product_area != "cn" ? 3 : 4)) {
                this.$message.error(this.$t("eqDetails_30023"));
                return false;
            }
            const obj = {
                id: this.deviceId,
            };
            ajax({
                type: "POST",
                url: `${requestUrl}/device/checkHealth/start`,
                dataType: "json",
                async: false,
                data: JSON.stringify(obj),
                success: (response) => {
                    const info = JSON.parse(response);
                    if (info.error) {
                        this.step = "c";
                        this.deviceCheckStatus = info.error.code;
                        return false;
                    }
                    this.step = "b";
                    this.getCheckResult(info.result.sid);
                    this.timerId = setInterval(() => {
                        this.getCheckResult(info.result.sid);
                    }, 5000);
                    this.timerOutId = setTimeout(() => {
                        if (!this.checkResult) {
                            this.step = "c";
                            this.deviceCheckStatus = "timeOut";
                        }
                        this.stopTimer();
                    }, 300000);
                },
            });
        },
        //通过sid查询当次自检结果
        getCheckResult(sid) {
            console.log(sid)
            console.log(this.deviceId)
            this.activeNames = [];
            let url =
                sid == "prev"
                    ? `${requestUrl}/device/checkHealth/result?deviceId=${this.deviceId}`
                    : `${requestUrl}/device/checkHealth/result?sid=${sid}`;
            this.isPrev = sid == "prev" ? true : false;
            ajax({
                type: "GET",
                url: url,
                success: (response) => {
                    let res = JSON.parse(response).result;
                    if (res.length != 0 && res[0].error) {
                        this.stopTimer();
                        if (res[0].error.data) { 
                            var msg = res[0].error.data[res[0].error.data.length-1].msg;
                            var code = `(${res[0].error.data[res[0].error.data.length-1].code})`;
                        }
                        this.deviceCheckStatus = res[0].error.data ? 'codeError' : res[0].error.code;
                        this.errorText = res[0].error.data
                            ? this.$parent.$t("eqDetails_30033", {msg:msg,code:code})
                            : '';
                        this.step = "c";
                        return false;
                    }
                    if (sid == "prev" && res.length == 0) {
                        this.$message(this.$parent.$t("eqDetails_30024"));
                    }
                    if (res.length != 0) {
                        this.stopTimer();
                        this.resultTime = this.formmatTime(
                            res[0].resultTime,
                            true
                        );
                        this.step = "d";
                        //硬件自检
                        const data = updateStatus(
                            mergeObjects(this.config, res[0].moduleStatus)
                        );
                        let sortedData = Object.entries(data)
                            .sort(
                                ([, obj1], [, obj2]) =>
                                    obj2.status - obj1.status
                            )
                            .reduce((acc, [key, value]) => {
                                acc[key] = value;
                                return acc;
                            }, {});
                        this.checkResult = sortedData;
                        for (let key in this.checkResult) {
                            if (
                                (this.checkResult[key].idMsg ||
                                    Object.keys(this.checkResult[key].idMsgExt)
                                        .length > 0) &&
                                this.checkResult[key].status == 1
                            ) {
                                this.activeNames.push(key);
                            }
                        }
                        //设备寿命
                        const moduleLife = res[0].moduleLife;
                        // 对moduleLife数组进行排序和后缀处理
                        const sortedModuleLife = moduleLife
                            .sort((a, b) => b.status - a.status)
                            .map((item) => {
                                if (item.status === 1) {
                                    item.moduleAlias += "(X)";
                                }
                                return item;
                            });

                        // 根据moduleAlias属性对数据进行分组
                        this.lifeResult = sortedModuleLife.reduce(
                            (result, item) => {
                                const { moduleAlias } = item;
                                if (!result[moduleAlias]) {
                                    result[moduleAlias] = [];
                                }
                                result[moduleAlias].push({
                                    num: item.num,
                                    status: item.status,
                                });
                                return result;
                            },
                            {}
                        );
                        console.log(this.lifeResult);
                    }
                },
            });
        },
    },
    beforeDestroy() {
        if (this.timerOutId) {
            clearTimeout(this.timerOutId);
        }
    },
    created(){
        if(this.checkPrev){
            this.getCheckResult('prev');
        }
    },
};
</script>
<style lang="scss" scoped>
.check {
    .page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .text {
            margin-top: 30px;
        }

        .loader {
            margin: 30px 0;
            font-size: 30px;
            margin: 20% auto;
            width: 30px;
            position: relative;
            text-indent: -9999em;
            animation: load 1.5s infinite linear;
            height: 30px;
            border-radius: 100%;
        }

        .txt {
            font-size: 14px;
            font-weight: 600;
            color: #1b2330;
            margin-bottom: 20px;
        }

        .checkText {
            font-size: 24px;
            font-weight: 600;
            color: #1b2330;
            margin-bottom: 50px;
        }
    }

    .table {
        .resultTime {
            margin-bottom: 20px;
        }

        .headText {
            display: flex;
            width: 760px;
            height: 54px;
            background: #eff2f6;
            border-radius: 4px 4px 0px 0px;
            margin-top: 20px;
            .ml270 {
                padding-left: 270px;
            }
            p {
                flex: 1;
                font-size: 14px;
                font-weight: 600;
                line-height: 54px;
                color: #1b2330;
                padding-left: 40px;
            }
        }

        .el-collapse {
            border: solid 1px #eff2f6;
            border-top: none;
            border-radius: 4px;
            padding: 0 16px;
            height: 500px;
            overflow: hidden;
            overflow-y: scroll;

            .el-collapse-item {
                line-height: 55px;
                border-bottom: 1px dotted #e6e7ec;

                .header-icon {
                    font-size: 16px;
                }

                .el-icon-success {
                    color: #09b59d;
                }

                .el-icon-error {
                    color: #f1330d;
                }

                .item-title {
                    width: 500px;
                    padding-left: 5px;
                    color: #464b5c;
                }
                .child {
                    padding-left: 30px;
                    color: #8087a0;
                    display: flex;

                    .flex {
                        flex: 1;
                        display: flex;

                        p {
                            margin-right: 5px;
                        }
                    }

                    .kv {
                        padding-left: 50px;
                    }
                }
            }
        }

        .btn {
            margin-top: 30px;
            display: flex;
            justify-content: center;
        }
    }
}

@keyframes load {
    0%,
    100% {
        box-shadow: 0em -3em 0em 0.2em #5278e2, 2em -2em 0 0em #5278e2,
            3em 0em 0 -0.5em #5278e2, 2em 2em 0 -0.5em #5278e2,
            0em 3em 0 -0.5em #5278e2, -2em 2em 0 -0.5em #5278e2,
            -3em 0em 0 -0.5em #5278e2, -2em -2em 0 0em #5278e2;
    }

    12.5% {
        box-shadow: 0em -3em 0em 0em #5278e2, 2em -2em 0 0.2em #5278e2,
            3em 0em 0 0em #5278e2, 2em 2em 0 -0.5em #5278e2,
            0em 3em 0 -0.5em #5278e2, -2em 2em 0 -0.5em #5278e2,
            -3em 0em 0 -0.5em #5278e2, -2em -2em 0 -0.5em #5278e2;
    }

    25% {
        box-shadow: 0em -3em 0em -0.5em #5278e2, 2em -2em 0 0em #5278e2,
            3em 0em 0 0.2em #5278e2, 2em 2em 0 0em #5278e2,
            0em 3em 0 -0.5em #5278e2, -2em 2em 0 -0.5em #5278e2,
            -3em 0em 0 -0.5em #5278e2, -2em -2em 0 -0.5em #5278e2;
    }

    37.5% {
        box-shadow: 0em -3em 0em -0.5em #5278e2, 2em -2em 0 -0.5em #5278e2,
            3em 0em 0 0em #5278e2, 2em 2em 0 0.2em #5278e2,
            0em 3em 0 0em #5278e2, -2em 2em 0 -0.5em #5278e2,
            -3em 0em 0 -0.5em #5278e2, -2em -2em 0 -0.5em #5278e2;
    }

    50% {
        box-shadow: 0em -3em 0em -0.5em #5278e2, 2em -2em 0 -0.5em #5278e2,
            3em 0em 0 -0.5em #5278e2, 2em 2em 0 0em #5278e2,
            0em 3em 0 0.2em #5278e2, -2em 2em 0 0em #5278e2,
            -3em 0em 0 -0.5em #5278e2, -2em -2em 0 -0.5em #5278e2;
    }

    62.5% {
        box-shadow: 0em -3em 0em -0.5em #5278e2, 2em -2em 0 -0.5em #5278e2,
            3em 0em 0 -0.5em #5278e2, 2em 2em 0 -0.5em #5278e2,
            0em 3em 0 0em #5278e2, -2em 2em 0 0.2em #5278e2,
            -3em 0em 0 0em #5278e2, -2em -2em 0 -0.5em #5278e2;
    }

    75% {
        box-shadow: 0em -3em 0em -0.5em #5278e2, 2em -2em 0 -0.5em #5278e2,
            3em 0em 0 -0.5em #5278e2, 2em 2em 0 -0.5em #5278e2,
            0em 3em 0 -0.5em #5278e2, -2em 2em 0 0em #5278e2,
            -3em 0em 0 0.2em #5278e2, -2em -2em 0 0em #5278e2;
    }

    87.5% {
        box-shadow: 0em -3em 0em 0em #5278e2, 2em -2em 0 -0.5em #5278e2,
            3em 0em 0 -0.5em #5278e2, 2em 2em 0 -0.5em #5278e2,
            0em 3em 0 -0.5em #5278e2, -2em 2em 0 0em #5278e2,
            -3em 0em 0 0em #5278e2, -2em -2em 0 0.2em #5278e2;
    }
}
</style>
