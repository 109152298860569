export default {
    orderlyCharging_00001: "Tatsächlicher Betrieb",
    orderlyCharging_00002: "Hilfe",
    orderlyCharging_00003: "Aufladen des Fahrzeugs",
    orderlyCharging_00004: "Autofahren",
    orderlyCharging_00005: "Begrenzen Sie die Gesamtladeleistung",
    orderlyCharging_00006: "Tatsächliche Ladeleistung",
    orderlyCharging_00007: "Fahrzeugcode",
    orderlyCharging_00008: "Mindest-SOC für den Abflug erforderlich (%)",
    orderlyCharging_00009: "Abfahrtszeit",
    orderlyCharging_00010: "Zeit zurück drehen",
    orderlyCharging_00011: "Elektrizität",
    orderlyCharging_00012:"1. Starten Sie den Ladevorgang, wenn der Ladestapel offline ist. Die Plattform kann die Gesamtlast nicht kontrollieren, also bitte mit Vorsicht vorgehen! <br/>2. Der Bahnhofstarif ist auf Verspätungsentgelt eingestellt und die geordnete Ladefunktion von Bussen kann nicht genutzt werden! <br/> 3. Beim Aufladen des Fahrzeugs springt die Waffe und der Ladevorgang muss manuell neu gestartet werden!",
}
