export default {
    rates_00001: 'Charges management',
    rates_00002: 'Operations center',
    rates_00003: 'Rates name',
    rates_00004: 'Whether in use',
    rates_00005: 'All',
    rates_00006: 'Yes',
    rates_00007: 'No',
    rates_00008: 'Reset',
    rates_00009: 'Search',
    rates_00010: 'New rates',
    rates_00011: 'Operation ',
    rates_00012: 'Used',
    rates_00013: 'Unused',
    rates_00014: 'Amend',
    rates_00015: 'Copy',
    rates_00016: 'Details',
    rates_00017: 'Delete',
    rates_00018: 'Enforce tariff',
    rates_00019: 'Please enter the rates name',
    rates_00020: 'Please enter the tariff',
    rates_00021: 'Please enter the default tariff for this station',
    rates_00022: 'Please set up the time interval continuously from 0:00 to 24:00',
    rates_00023: 'Please enter the correct tariff',
    rates_00024: 'The tariff name is occupied',
    rates_00025: 'Edit Charges',
    rates_00026: 'Copy Charges',
    rates_00027: 'Add time period',
    rates_00028: 'A maximum of {0} time periods can be added',
    rates_00029: 'Start time',
    rates_00030: 'End time',
    rates_00031: 'Electricity fee({type}/{unit})',
    rates_00032: 'Service fee({type}/{unit})',
    rates_00033: 'Idle fee({type}/{unit})',
    rates_00034: 'Period type',
    rates_00035: 'Undefined',
    rates_00036: 'Cancel',
    rates_00037: 'Confirm',
    rates_00038: 'Push result',
    rates_00039: 'Site name',
    rates_00040: 'Equipment number',
    rates_00041: 'Device push result',
    rates_00042: 'DC',
    rates_00043: 'AC',
    rates_00044: 'The deletion cannot be restored, Do you want to continue?',
    rates_00045: 'Reminder',
    rates_00046: 'Successfully delete',
    rates_00047: 'New success',
    rates_00048: 'Sharp',
    rates_00049: 'Peak',
    rates_00050: 'Flat',
    rates_00051: 'Valley',
    rates_00052: 'Success',
    rates_00053: 'Pushing',
    rates_00054: 'Failed',
    rates_00055: 'Push failed',
    rates_00056: 'Details',
    rates_00057: 'Edit success',
    rates_00058: 'Confirm push',
    rates_00059: 'Close',
    rates_00060: 'Use currency',
    // ****
    rates_00061: 'Apply',
    rates_00062: 'Scope',
    rates_00063: 'Public rates',
    rates_00064: 'Fleet rates',
    rates_00065: 'Third party rates',
    rates_00066: 'Rates',
    rates_00067: 'charges',
    rates_00068: 'Push object',
    rates_00069: 'Please select site',
    rates_00070: 'Please select a group',
    rates_00071: 'Select third-party traffic',
    rates_00072: 'Push to ac pile',
    rates_00073: 'Push to dc pile',
    rates_00074: 'binding',
    rates_00075: 'retry',
    rates_00076: 'Please bind the staked site first',
    // 电网资费
    rates_00077: 'Type',
    rates_00078: 'Grid rates',
    rates_00079: 'Service rates',
    rates_00080: 'Peak pinggu corresponding price',
    rates_00081: 'Peak valley normal section',
    rates_00082: 'Grid electricity({type}/{unit})',
    rates_00083: ' previous',
    rates_00084: 'next',
    rates_00085: 'Please select the peak rate period',
    rates_00086: 'DC',
    rates_00087: 'AC',
    rates_00088: 'Public',
    rates_00089: 'group',
    rates_00090: 'The third party',
    rates_00091: 'Confirm whether to bind the tariff to',
    rates_00092: 'site',
    rates_00093: 'the',
    rates_00094: 'Net Zero',
}