export default {
  resetPassword_00001: "Information Verification ",
  resetPassword_00002: "Password Modification",
  resetPassword_00003: "Modification Completed",
  resetPassword_00004: "Account ",
  resetPassword_00005: "Please enter an account that requires password reset",
  resetPassword_00006: "Mobile Number ",
  resetPassword_00007: "11-digit phone number ",
  resetPassword_00008: "Please enter the mobile number bound to this account ",
  resetPassword_00009: "Send code",
  resetPassword_00010: "Reacquire after {0}s ",
  resetPassword_00011: "SMS Verification code",
  resetPassword_00012: "6 digits ",
  resetPassword_00013: "Please enter SMS verification code ",
  resetPassword_00014: "Next ",
  resetPassword_00015: "New Password ",
  resetPassword_00016: "Contains at least 6 characters, including at least 1 digit, 1 letter, and 1 special character",
  resetPassword_00017: "Please enter a new password ",
  resetPassword_00018: "Confirm Password ",
  resetPassword_00019: "same as the new password ",
  resetPassword_00020: "Please enter the new password again ",
  resetPassword_00021: "Save ",
  resetPassword_00022: "Modified successfully ",
  resetPassword_00023: "Log in to the operating platform ",
  resetPassword_00024: "Please enter the verification code ",
  resetPassword_00025: "Please enter a numeric value ",
  resetPassword_00026: "Please enter 6 digits ",
  resetPassword_00027: "Please enter the new password again",
  resetPassword_00028: "The new passwords entered twice do not match!",
  resetPassword_00029: "Please enter the phone number ",
  resetPassword_00030: "Please enter the correct 11-digit phone number ",
  resetPassword_00031: "Please enter account",
  resetPassword_00032: "Please enter a password ",
  resetPassword_00033: "",
  resetPassword_00034: "The entered phone number is not the phone number bound to the account! Please contact the administrator if necessary!" ,
  resetPassword_00035: "Incorrect verification code ",
  resetPassword_00036: "The verification code is invalid ",
  resetPassword_00037: "The verification code has expired. Please obtain the verification code again ",
  resetPassword_00038: "Reset Password",
  resetPassword_00039: "Please enter a new password according to the rules!",
  
  resetPassword_00040: "Mailbox ",
  resetPassword_00041: "Please enter the email associated with this account",
  resetPassword_00042: "Please enter the correct email！",
  
  resetPassword_00043: "The email you entered is not associated with this account ! Please contact the administrator if necessary!" ,
  resetPassword_00044: "Email verification code",
  resetPassword_00045: "Please enter email verification code",
  
  }