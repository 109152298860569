export default {
    eventRelease_00001: "活动发布",
    eventRelease_00002: "首页Banner启用",
    eventRelease_00003: "预览",
    eventRelease_00004: "活动海报",
    eventRelease_00005: "跳转页面",
    eventRelease_00006: "展示顺序",
    eventRelease_00007: "选择默认图片",
    eventRelease_00008: "放大",
    eventRelease_00009: "修改",
    eventRelease_000010: "点击上传图片",
    eventRelease_000011: "设置帮助",
    eventRelease_000012: "1.上传图片仅支持PNG、JPG格式图片，大小不超过5M。建议尺寸690*260px（8:3），如您上传的图片尺寸或比例不符，则会拉伸展示",
    eventRelease_000013: "2.如活动状态为：未开始、已暂停、已结束，只展示活动海报，点击不可打开页面",
    eventRelease_000014: "",
    eventRelease_000015: "选择活动类型",
    eventRelease_000016: "请粘贴您的公众号文章链接，微信公众平台：https://mp.weixin.qq.com/",
    eventRelease_000017: "最多可设置8个Banner",
    eventRelease_000018: "请设置banner图片",
    eventRelease_000019: "请设置活动ID ",
    eventRelease_000020: "上传",
    eventRelease_000021: "无跳转",
    eventRelease_000022: "活动页面",
    eventRelease_000023: "公众号链接",
    eventRelease_000024: "储值送返",
    eventRelease_000025: "自由领取",
    eventRelease_000026: "邀请好友",
    eventRelease_000027: "购买卡券",
    eventRelease_000028: "请设置跳转页面",
    eventRelease_000029: "活动名称",
    eventRelease_000030: "选择活动",
    eventRelease_000031: "新增活动",




}