export default {
    coupon_charge_condition_1:"单次充电总费用满足{feeAtLeast}元",
    voucher_amount_1:"抵用总费用{discount}元",
    discount_amount_4:"总费用全免",
    coupon_charge_condition_2:"单次充电服务费满足{feeAtLeast}元",
    voucher_amount_2:"抵用服务费{discount}元",
    discount_amount_3:"服务费全免",
    voucher_coupon:"抵用券",
    discount_coupon:"折扣券",
    from_get_coupon_on:"领券之日起{validityDays}日",
    discount_amount_1:"总费用{discount}折",
    discount_amount_2:"服务费{discount}折",

    coupon_00001: '营销中心',
    coupon_00002: '优惠工具',
    coupon_00003: '名称',
    coupon_00004: '请输入',
    coupon_00005: '重置',
    coupon_00006: '查询',
    coupon_00007: '列表',
    coupon_00008: '新建',
    coupon_00009: '使用条件',
    coupon_00010: '有效期',
    coupon_00011: '操作',
    coupon_00012: '详情',
    coupon_00013: '抵用券',
    coupon_00014: '折扣券',


}
