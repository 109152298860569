export default {
    analyzeIncome_00001: "Revenue",
    analyzeIncome_00002: "Revenue By Station",
    analyzeIncome_00003: "Revenue By Fleet",
    analyzeIncome_00004: "User-source income analysis",
    analyzeIncome_00005: "Revenue By Franchisee",
    analyzeIncome_00006: "Revenue trend",
    analyzeIncome_00007: "Analysis",
    analyzeIncome_00008: "Revenue",
    analyzeIncome_00009: "Revenue distribution by charger model",
    analyzeIncome_00010: "Revenue distribution by city",
    analyzeIncome_00011: "Comparison of stations by revenue",
    analyzeIncome_00012: "Revenue trends of selected stations",
    analyzeIncome_00013: "Comparison of fleets by revenue",
    analyzeIncome_00014: "Revenue trends of selected fleets",
    analyzeIncome_00015: "Comparison of Franchisees by revenue",
    analyzeIncome_00016: "Revenue trends of selected Franchisees",
    analyzeIncome_00017: "Select charger model",
    analyzeIncome_00018: "Site Utilization Comparison",
    analyzeIncome_00019: "Site Utilization trend",
    analyzeIncome_00020: "Comparison of charger model utilization",
    analyzeIncome_00021: "Charger model utilization trend",
    analyzeIncome_00022: "Charging utilization",
    analyzeIncome_00023: "Franchisee utilization comparison",
    analyzeIncome_00024: "Franchisee utilization trends",
    analyzeIncome_00025: "City",
    analyzeIncome_00026: "Charger model",
    analyzeIncome_00027: "Service fee",
    analyzeIncome_00028: "Electricity fee",
    analyzeIncome_00029: "Port in charging",
    analyzeIncome_00030: "Money amount analysis of Charge order",
    analyzeIncome_00034: "Share of revenue from user sources",
    analyzeIncome_00035: "Share of each traffic in third-party traffic",
    analyzeIncome_00036: "Trend of user-sourced income",
    analyzeIncome_00037: "Third-party income trend",
    analyzeIncome_00038: "Currency",
    analyzeIncome_00039: 'Revenue per connector trend',
    analyzeIncome_00040: 'Idle fee',
    analyzeIncome_00041: 'Electricity fee per connector',
    analyzeIncome_00042: 'Service fee per connector',
    analyzeIncome_00043: 'Idle fee per connector',
}