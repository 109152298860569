export default {
  work_00001: 'Ticket Details',
  work_00002: 'Diagnostics',
  work_00003: 'Ticket System',
  work_00004: 'Ticket Management',
  work_00005: 'Very Urgen',
  work_00006: 'Send',
  work_00007: 'Ticket num',
  work_00008: 'Issue Site',
  work_00009: 'Issue Site Brand and Model',
  work_00010: 'Issue Source',
  work_00011: 'Initiator',
  work_00012: 'Initiate Time',
  work_00013: 'Update Time',
  work_00014: 'Current Processor',
  work_00015: 'Issue Description',
  work_00016: 'Related Equipment',
  work_00017: 'Related Equipment Abnormal Log',
  work_00018: 'Realted Saftey Evaluation Log',
  work_00019: 'Related User',
  work_00020: 'Solving Solution',
  work_00021: 'Request Process',
  work_00022: 'Completed',
  work_00023: 'Pending',
  work_00024: 'Resolved',
  work_00025: 'Closed',
  work_00026: 'Transfer',
  work_00027: 'Close',
  work_00028: 'Platform communication log query',
  work_00029: 'Fault Exception Code',
  work_00030: 'Fault Exception Description',
  work_00031: 'Urgent',
  work_00032: 'General',
  work_00033: 'Pending',
  work_00034: 'Processing',
  work_00035: 'Resolved',
  work_00036: 'Closed',
  work_00037: 'Equipment Number',
  work_00038: 'Related Orders',
  work_00039: 'Reporting by Staff',
  work_00040: 'Customer Feedback',
  work_00041: 'Equipment Reporting',
  work_00042: 'Automatic trigger',
  work_00043: 'Other',
  work_00044: 'Send',
  work_00045: 'Cannot restore after deleting a Request',
  work_00046: 'Join the knowledge base',
  work_00047: 'Return',
  work_00048: 'Receiving',
  work_00049: 'Initiate Request',
  work_00050: 'Mobile：{mobile}',
  // 工单状态弹窗
  orderMangement_00052: 'Remarks',
  orderMangement_00053: 'Please enter the content',
  orderMangement_00054: 'Solution',
  orderMangement_00055: 'Transfer Processor',
  orderMangement_00056: 'Accept Ticket',
  orderMangement_00057: 'Whether to accept the ticke?',
  orderMangement_00058: 'Confirm Accept',
  orderMangement_00059: 'Transfer Requests',
  orderMangement_00060: 'Confirm transfer',
  orderMangement_00061: 'Reasons for transfer',
  orderMangement_00062: 'Close the Request',
  orderMangement_00063: 'Are you sure to close the Requests',
  orderMangement_00064: 'Confirm to close',
  orderMangement_00065: 'Close reason',
  orderMangement_00066: 'Return to Requests',
  orderMangement_00067: 'Are you sure to return to Requests?',
  orderMangement_00068: 'Confirm Reject',
  orderMangement_00069: 'Return Reason',
  orderMangement_00070: 'The Request has been resolved',
  orderMangement_00071: 'Do you confirm that the Requests has been resolved',
  orderMangement_00072: 'Confirmation Resolved',
  orderMangement_00073: 'Please fill in completely',
  orderMangement_00074: 'Operation succeed',
  orderMangement_00082: 'Become a follower',
  orderMangement_00083: 'Charger Manufacturer',

}
