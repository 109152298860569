export default {
    analyze_BI_00001:"overview",
    analyze_BI_00002:"Total orders (€)",
    analyze_BI_00003:"Total electricity (kWh)",
    analyze_BI_00004:"Total number of users ",
    analyze_BI_00005:"Charging stations",
    analyze_BI_00006:"Order amount (€)",
    analyze_BI_00007:"Charging electricity (kWh)",
    analyze_BI_00008:"Users",
    analyze_BI_00009:"Charging points",
    analyze_BI_00010:"Current users",
    analyze_BI_00011:"Top 5 Vehicle model for charging time",
    analyze_BI_00012:"Top 5 Vehicle model for charging power",
    analyze_BI_00013:"Heat map of 30 days X 24 hour charging electricity",
    analyze_BI_00014:"Top 5 charging station electricity (kWh)",
    analyze_BI_00015:"trend of Usage rate",
    analyze_BI_00016:"Electricity trend",
    analyze_BI_00017:"until",
    analyze_BI_00018:"Charging Amount(Kwh)",
    analyze_BI_00019:"AC equipment charging utilization rate (%)",
    analyze_BI_00020:"DC equipment charging utilization rate (%)",
} 