export default {
  knowledgeDetail_00001: 'Knowledge Base Details',
  knowledgeDetail_00002: 'Knowledge Base',
  knowledgeDetail_00003: 'Update Time',
  knowledgeDetail_00004: 'Delete',
  knowledgeDetail_00005: 'Edit',
  knowledgeDetail_00006: 'Ticket Category',
  knowledgeDetail_00007: "Related Charger's Brand and Model",
  knowledgeDetail_00008: 'Ticket',
  knowledgeDetail_00009: 'Chargers',
  knowledgeDetail_00010: 'Issue Description',
  knowledgeDetail_00011: 'Transactions',
  knowledgeDetail_00012: 'Orders',
  knowledgeDetail_00013: 'Equipment Fault Log',
  knowledgeDetail_00014: 'Equipment Fault Code',
  knowledgeDetail_00015: 'Equipment Fault Description',
  knowledgeDetail_00016: 'Users',
  knowledgeDetail_00017: 'WeChat UserS',
  knowledgeDetail_00018: 'Card UserS',
  knowledgeDetail_00019: 'Solution',
  knowledgeDetail_00020: 'Processing Method',
  knowledgeDetail_00021: 'Shared with relevant CPOs',
}