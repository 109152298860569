export default {
  managementCharger_00001: '运营商充电卡管理',
  managementCharger_00002: '在线身份充电卡管理',
  managementCharger_00003: '即插即充充电卡管理',
  managementCharger_00004: '离线启停卡管理',
  managementCharger_00005: '离线储值卡管理',
  managementCharger_00006: '充电卡号',
  managementCharger_00007: '充电卡列表',
  managementCharger_00008: '新建即插即充充电卡',
  managementCharger_00009: '新建简易在线身份充电卡',
  managementCharger_00010: '下载制卡NFC（APP）',
  managementCharger_00011: '卡号',
  managementCharger_00012: '卡类型',
  managementCharger_00013: '离线启停卡',
  managementCharger_00014: '离线储值卡',
  managementCharger_00015: '即插即充卡',
  managementCharger_00016: '在线身份卡',
  managementCharger_00017: '即插即充',
  managementCharger_00018: '编号',
  managementCharger_00019: '车牌号',
  managementCharger_00020: '姓名(手机号)',
  managementCharger_00021: '所属充电卡组',
  managementCharger_00022: '余额',
  managementCharger_00023: '操作',
  managementCharger_00024: '查询订单',
  managementCharger_00025: "{id}(已挂失)",
  managementCharger_00026: "新建即插即充卡",
  managementCharger_00027: "查询用户匹配的车辆{type}",
  managementCharger_00028: "完善卡片信息",
  managementCharger_00029: "加入卡组",
  managementCharger_00030: "完成新建",
  managementCharger_00031: "请输入用户手机号",
  managementCharger_00032: "此手机号2周内匹配多辆车（{type}），请手动确定车辆（{type}）！",
  managementCharger_00033: "头像",
  managementCharger_00034: "用户昵称|手机号",
  managementCharger_00035: "车辆{type}",
  managementCharger_00036: "最近一次|充电结束时间",
  managementCharger_00037: "最近一次|充电站点",
  managementCharger_00038: "确认",
  managementCharger_00039: "卡号",
  managementCharger_00040: "来源",
  managementCharger_00041: "编号",
  managementCharger_00042: "联系人姓名",
  managementCharger_00043: "联系人手机号",
  managementCharger_00044: "车牌号",
  managementCharger_00045: "密钥",
  managementCharger_00046: "充值金额",
  managementCharger_00047: "登录密码",
  managementCharger_00048: "加入卡组(必填)",
  managementCharger_00049: "请选择卡组",
  managementCharger_00050: "充电卡制作成功",
  managementCharger_00051: "可以在",
  managementCharger_00052: "「充电卡管理」",
  managementCharger_00053: "充电卡群组的",
  managementCharger_00054: "「详情」",
  managementCharger_00055: "中进行如下操作：",
  managementCharger_00056: "1、给存储类型的充电卡充值；",
  managementCharger_00057: "2、查看充值记录；",
  managementCharger_00058: "3、修改充电卡的联系人信息；",
  managementCharger_00059: "上一步",
  managementCharger_00060: "下一步",
  managementCharger_00061: "完 成",
  managementCharger_00062: "请输入身份卡号",
  managementCharger_00063: "请输入正确的卡号",
  managementCharger_00064: "请输入卡片来源",
  managementCharger_00065: "请输入正确的车牌号",
  managementCharger_00066: "充值金额格式不正确",
  managementCharger_00067: "请输入密码",
  managementCharger_00068: "在线身份卡录入成功",
  managementCharger_00069: "新建简易身份卡",
  managementCharger_00070: "卡号不能包含中文",
  managementCharger_00071: "来源不允许填写“即插即充”",
  managementCharger_00072: "请输入正确的手机号",
}