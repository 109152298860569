<template>
  <el-dialog
    :title="$t('common_00265')"
    :visible.sync="visible"
    :lock-scroll="false"
    :close-on-click-modal="false"
    :before-close="close"
    width="500px"
  >
    <div class="tip-wrap">
        <i class="iconfont icon-yichang icon"></i>
        <p>{{$t('common_20029')}}</p>
      </div>
      <el-radio-group v-model="esEcoModeTowardPhase">
        <el-radio :label="4">{{$t('common_20030')}}</el-radio>
        <el-radio :label="2">{{$t('common_20031')}}</el-radio>
      </el-radio-group>
  <div slot="footer" class="dialog-footer">
    <el-button @click="close">{{$t('common_00215')}}</el-button>
    <el-button type="primary" @click="toSubmit">{{$t('common_00234')}}</el-button>
  </div>
  </el-dialog>
</template>

<script>
import {
  requestUrl,
  ajax,
  getQueryString,
  getDateFromLongTime,
} from "@/assets/utils/network";
export default {
  props: {
    modeType:{
      type:Number
    },
  },
  data() {
    return {
      esEcoModeTowardPhase:"",
      visible: true,
    };
  },
  created() {
    this.product = window.product_area;
  },

  methods:{
    check(v){
      console.log('v',v)
    },
    close() {
      this.$emit("close");
    },
    toSubmit() {
      this.$emit("confirm",this.esEcoModeTowardPhase);

    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
}
/deep/.el-input__inner {
  height: 36px;
  line-height: 36px;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.m20 {
  margin: 0 20px;
}
.flex-container-left {
  margin-bottom: 20px;
  .tips {
    i {
      margin-right: 15px;
    }
    margin-bottom: 20px;
  }
}
.dialog-btn{
  display: block;
  width: 100%;
  text-align: right;
}
.mode-detail{
  .mode-info{
    display: flex;
    margin-bottom: 10px;
    p{
      width: 72px;
      flex-shrink: 0;
    }
    .mode-content{
      text-align: justify;
      
    }
  }
}
.tip-wrap {
  display: flex;
  margin-bottom: 30px;
    .icon {
      // font-size: 20px;
      margin-right: 10px;
      margin-top: 2px;
      flex-shrink: 0;
    }
    p{
      flex: 1;
    }
  }
  .dialog-footer{
    
    text-align: center;
    .el-button{
      margin: 0 30px;
    }
  }
</style>
