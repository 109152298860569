export default {
  deviceMessageTemplate_00001: "Custom edit configuration message template, you can add the template below directly in the charging station or edit it again based on the template",
  deviceMessageTemplate_00002: 'C1 (4.3" screen) charging station screen configuration information',
  deviceMessageTemplate_00003: 'C1 (7" screen) charging station screen configuration information',
  deviceMessageTemplate_00004: "Smart charging system",
  deviceMessageTemplate_00005: "View the large image",
  deviceMessageTemplate_00006: "Bootup logo",
  deviceMessageTemplate_00007: "Top logo",
  deviceMessageTemplate_00008: "UI ",
  deviceMessageTemplate_00009: "No more than 16 words",
  deviceMessageTemplate_000010: "C2 charger screen configuration information",
  deviceMessageTemplate_000011: "Scan code interface title",
  deviceMessageTemplate_000012: "Scan code on WeChat to start charging",
  deviceMessageTemplate_000013: "Scan the QR code on the left with WeChat and follow the prompts to proceed to the next step",
  deviceMessageTemplate_000014: "Scan code interface text",
  deviceMessageTemplate_000015: "No more than 10 words",
  deviceMessageTemplate_000016: "No more than 24 words",
  deviceMessageTemplate_000017: "C4 charger screen configuration information",
  deviceMessageTemplate_000018: "C6 charger screen configuration information",
  deviceMessageTemplate_000019: "C6s charger screen configuration information",
  deviceMessageTemplate_000020: "Welcome to use smart charging system",
  deviceMessageTemplate_000021: "Please use WeChat scan code to charge or use NFC card to charge",
  deviceMessageTemplate_000022: "Use the touch button to select the charging point",
  deviceMessageTemplate_000023: "Top wods",
  deviceMessageTemplate_000024: "Welcome title",
  deviceMessageTemplate_000025: "No more than{num}words",
  deviceMessageTemplate_000026: "Welcome text",
  deviceMessageTemplate_000027: "Bottom text",
  deviceMessageTemplate_000028: "Drag the file here, or|click to upload",
  deviceMessageTemplate_000029: "Only PNG format images are supported, the size is {area}px, not more than {size}kb.",
  deviceMessageTemplate_000030: "Modify image",
  deviceMessageTemplate_000031: "Upload image",
  deviceMessageTemplate_000032: "Please upload image",
  deviceMessageTemplate_000033: "The image you selected is too large, please select again",
  deviceMessageTemplate_000034: "Please choose a image in png format to upload",
  deviceMessageTemplate_000035: "Please upload an image that matches {0}px",
  deviceMessageTemplate_000036: "Modify top logo",
  deviceMessageTemplate_000037: "The top logo can use text or image format, choose one of the two.",
  deviceMessageTemplate_000038: "Use text",
  deviceMessageTemplate_000039: "Please enter text",
  deviceMessageTemplate_000040: "Within {num} characters (including punctuation)",
  deviceMessageTemplate_000041: "Use image",
  deviceMessageTemplate_000042: "X-CHARGE",
  deviceMessageTemplate_000043: "The text on the top screen is too long",
  deviceMessageTemplate_000044: "Please select 'Use Image' first",
  deviceMessageTemplate_000045: "The image you selected is too large, please reselect it",
  deviceMessageTemplate_000046: "Please select the image upload in png format",
  deviceMessageTemplate_000047: "Parameter is empty",
  deviceMessageTemplate_000048: "Parameter length is too long",
  deviceMessageTemplate_000049: "Please scan the code to charge or use NFC to swipe the card to charge",
  deviceMessageTemplate_000050: "The title of the scan code interface cannot exceed 10 digits",
  deviceMessageTemplate_000051: "The title of the scan code interface cannot exceed 24 digits",
  deviceMessageTemplate_000052: "Scan to start charging",
  deviceMessageTemplate_000053: "Scan the QR code on the left and follow the instructions to proceed to the next step",
  deviceMessageTemplate_000054: "Please save the previous step",
  deviceMessageTemplate_000055: "No more than 7 digits of text can be entered",
  

  
  
  
  
}