export default {
    begin_date_select_remind: "Start date",
    end_date_select_remind: "Expiry date",
    to: "to",
    use_condition:"Using condition",
    fee_at_least:"Total cost of single recharge to meet the amount",
    yuan:"EUR",
    fee_discount:"Total amount to be offset",
    description_info_1:"Note: If you set the total cost of single recharge to meet 60 euro against the total cost of 10 euro, and the total cost of this recharge is 100 euro, then the actual amount to be paid  is 90 euro (100-10=90)。",
    service_fee_at_least:"Single recharge service fee to meet the amount",
    service_fee_discount:"Amount of service fee",
    description_info_2:"Note: If you set the single charge service fee to meet the 20 euro credit service fee of 10 euro, and the total cost of this charge is 100 euro (including 70 euro for electricity and 30 for service euro), the actual amount to be paid  is 90 euro (70+(30-10)=90)." ,
    description_info_3:"Note: If you set the total cost of single charge to meet the total cost of 60 euro 20% discount, and the total cost of this charge is 100 euro, then the actual amount to be paid is 85 euro (100*85%=85)." ,
    description_info_4:"Note: If you set the single charge service fee to meet the 20% discount of 20 euro service fee, the charge cost 100 euro this time (including 70 euro electricity fee and 30 euro service fee), then the actual amount to be paid this time is 95.5 euro (70+30*85%=95.5)." ,
    percent_discount:"Total cost discounted ",
    percent:"%",
    all_site:"All charging site",
    self_run_site:"Self-operated sites",
    custom_site:"Custom sites",
    add_site:"Add a charging site",
    site_name: "Name",
    operator: "Operator",
    enter_site:"Inpu charging site name、operatore name",
    query: "Search",
    confirm: "Confirm",
    siteStatus: "Charging site status",
    isOpen: "Open to the public",
    enable: "Enable",
    enabled: "Enabled",
    disabled: "Not activated",
    open: "Open",
    unopen: "Unopen",
    device_type_dc: "DC",
    device_type_ac: "AC",
    chargeEquipment: "Charger",
    member_00072: 'Cancel',
    cancel: 'Cancel',
    member_00073: 'Add',
    ac:"AC",
    dc:"DC",
    delete_success: "Delete successfully",
    charging_package44:"Are you sure to delete this message!",
    operate_canceled: "Operation canceled",
    discount_amount_5:"85 means 15% off，0 means free",
    service_percent_discount:"Discounted service fees",
    remind: "Tips",
    coupon_error_message_1:"Please enter a coupon name of less than 16 characters",
    coupon_error_message_2:"Please complete the coupon information",
    coupon_error_message_3:"Please enter the correct expiry date",
    coupon_error_message_4:"The start date should be less than or equal to the expiry date",
    coupon_error_message_5:"The start date should be greater than or equal to the current date",
    coupon_error_message_6:"Please enter the correct credit amount",
    coupon_error_message_7:"Please enter the correct number of discounts",
    coupon_error_message_8:"Please enter the correct amount",
    coupon_error_message_9:"Please complete the coupon information",
    coupon_error_message_10:"Please select the charging sites where the coupon to be added",
    common_00241: "Added successfully",
    text_rule1: "Please enter the number, up to two decimal places",
    coupon_00001: 'Marketing Centre',
    coupon_00002: 'Preferential tools',
    coupon_00003: "Name",
    coupon_00004: 'Please inpu',
    coupon_00005: 'Reset',
    coupon_00006: 'Search',
    coupon_00007: 'List',
    coupon_00008: 'Create',
    coupon_00009: 'Using condition',
    coupon_00010: 'Validity period',
    coupon_00011: 'Operate',
    coupon_00012: 'Detail',
    coupon_00013: 'Credit ',
    coupon_00014: 'Discount',
    coupon_00015: 'Modify',
    coupon_00016: 'Information',
    coupon_00017: 'Cancel',
    coupon_00018: 'Save',
    coupon_00019: 'Modify',
    coupon_00020: 'Delete',
    coupon_00021: 'Relative',
    coupon_00022: 'Definite',
    coupon_00023: 'Start with the date of user acquisition',
    coupon_00024: 'Please enter natural numbers',
    coupon_00025: 'day(s)',
    coupon_00025_1: 'to',
    coupon_00026: 'Using conditions',
    coupon_00027: 'Available sites',
    coupon_00028: 'Charging site name',
    coupon_00029: 'Search',
    coupon_00030: 'Bulk Deletion',
    coupon_00031: 'Operate',
    coupon_00032: 'Delete',
    coupon_00033: 'Non-recoverable after',
}
