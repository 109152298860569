export default {
  userAllocate_00001: "Gruppenname",
  userAllocate_00002: "Guthabenzuweisung",
  userAllocate_00003: "Grundlegende Informationen",
  userAllocate_00004: "Spitzname des Benutzers, Kartennummer, Mobiltelefonnummer",
  userAllocate_00005: "Benutzertyp",
  userAllocate_00006: "Alle",
  userAllocate_00007: "WeChat Benutzer",
  userAllocate_00008: "Online-Ausweis",
  userAllocate_00009: "suchen",
  userAllocate_00010: "zurücksetzen",
  userAllocate_00011: "{1}{3} an {0} Personen zugewiesen, {2}{3} übrig",
  userAllocate_00012: "Zuweisung bestätigen",
  userAllocate_00013: "Avatar",
  userAllocate_00014: "Benutzer (Handynummer)",
  userAllocate_00015: "Kartennummer", 
  userAllocate_00016: "Typen",
  userAllocate_00017: "Kontostand ({0})",
  userAllocate_00018: "Zugewiesener Betrag ({0})",
  userAllocate_00019: "Liste der Benutzer",
  userAllocate_00020: "Bitte geben Sie den Zuweisungsbetrag für die Mitglieder der Benutzergruppe ein!",
  userAllocate_00021: "Unzureichendes Guthaben im Benutzergruppenkonto zum Zuweisen!",
  userAllocate_00022: "Hinweise",
  userAllocate_00023: "Zuweisen fortsetzen",
  userAllocate_00024: "OK",
  userAllocate_00025: "",
}