export default {
  orderAnalysis_00001: 'Tickets stats',
  orderAnalysis_00002: 'Analyze',
  orderAnalysis_00003: "Tickets statistics by station",
  orderAnalysis_00004: "Tickets statistics by roles",
  orderAnalysis_00005: "Tickets statistics by category",
  orderAnalysis_00006: "Tickets statistics by priority",
  orderAnalysis_00007: "Tickets statistics by issue source",
  orderAnalysis_00008: "Tickets statistics by charger's brand & model",
  orderAnalysis_00009: 'Staff report',
  orderAnalysis_00010: 'Customer report',
  orderAnalysis_00011: 'Charger report',
  orderAnalysis_00012: 'Automatic trigger',
  orderAnalysis_00013: 'Other',
  orderAnalysis_00014: 'Normal',
  orderAnalysis_00015: 'Urgen',
  orderAnalysis_00016: 'Very Urgen',
  orderAnalysis_00017: 'Accessory Use statistics',
}