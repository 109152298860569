export default {
    cardSales_00001: 'Discount Einkaufszentrum Bestellungenr',
    cardSales_00002: 'Bestellungsnummer', 
    cardSales_00003: 'Bitte geben Sie Nutzername/Handynummer ein',
    cardSales_00004: 'Startdatum',
    cardSales_00005: 'Endedatum',
    cardSales_00006: 'bis',
    cardSales_00007: 'Umsatz(€)',
    cardSales_00008: 'Anzahl des Verkaufs',
    cardSales_00009: 'Anzahl der Käufe',
    cardSales_00010: 'Bestellungsliste',
    cardSales_00011: 'Bestellung exportieren',
    cardSales_00012: 'alle',
    cardSales_00013: 'Elektrizitätpaket',
    cardSales_00014: 'Gutscheine',
    cardSales_00015: 'Zahlungsbetrag',
    cardSales_00016: 'Bezahlen via Wechat',
    cardSales_00017: '(Wechat Öffentliche Konto)',
    cardSales_00018: 'Die Bestellungsliste als Excel-Datei herunterladen?',
    cardSales_00019: 'Hinweise',
    cardSales_00020: 'Herunterladen starten',
    cardSales_00021: 'Herunterladen abbrechen',
}