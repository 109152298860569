export default {
  createKnowledge_00001: 'Create Knowledege',
  createKnowledge_00002: 'Knowledege Ban',
  createKnowledge_00003: 'Related Tickets Category',
  createKnowledge_00004: 'Related Tickets',
  createKnowledge_00005: 'Ticket Title',
  createKnowledge_00006: '',
  createKnowledge_00007: 'Related Euipement Brand &  Model',
  createKnowledge_00008: '',
  createKnowledge_00009: 'All models',
  createKnowledge_00010: 'Issue Description',
  createKnowledge_00011: 'Solving Solution',
  createKnowledge_00012: 'Please fill in completely',
  createKnowledge_00013: 'Created successfully',
  createKnowledge_00014: 'Share with related CPOs',
  createKnowledge_00015: 'Edit Knowledege',
}