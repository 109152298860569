<template lang="html">
  <el-dialog
    :title="$t('common_00094')"
    :visible.sync="visible"
    :lock-scroll="false"
    :before-close="close"
    width="800px">
      <div class="conten-wrap">
        <div class="wrap">{{dialogData?dialogData.title:''}}</div>
        <el-table
          :data="dialogData?dialogData.data:[]"
          class="table"
          :header-cell-style="{
          height: '54px',
          fontSize: '14px',
          background:'#EFF2F6',
          color:'#1B2330',
          borderRadius: '4px 4px 0px 0px'}">
          <el-table-column
            prop="type"
            :label="$t('common_10000')">
          </el-table-column>
          <el-table-column
            prop="count"
            :label="$t('common_10001')"
            width="180">
          </el-table-column>
          <el-table-column :label="$t('common_00282')">
            <template slot-scope="scope">
              <a class="text"  :href="`storedValueActivity.html?level=${dialogData.level?dialogData.level:''}&levelAlias=${dialogData.title?dialogData.title:''}#${scope.row.tag}`" :target="isOpenInCurrentPage() && '_blank'">
                <el-button type="text">
                  {{$t('common_00094')}}
                </el-button>
              </a>
              <a class="text"  :href="`addStoredValue.html?tag=${scope.row.tag}&fromMember=${dialogData.level?dialogData.level:''}`" :target="isOpenInCurrentPage() && '_blank'">
                <el-button type="text" class="operation">
                  {{$t('common_10002')}}
                </el-button>
              </a>
            </template>
          </el-table-column>
        </el-table>
        </div>
     </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    dialogData: Object,
  },
  data() {
    return {
      tableData: [],
    };
  },

  methods: {
    // 关闭弹窗
    close() {
      this.$emit("cancel", false);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.conten-wrap {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  box-shadow: 0px 2px 12px 0px rgba(30, 30, 30, 0.05);
  .wrap {
    height: 79px;
    line-height: 80px;
    border-bottom: 1px dashed #e6e7ec;
    color: #464b5c;
    padding: 0 16px;
    font-weight: 600;
    color: #41526d;
    font-size: 16px;
  }
  .table {
    padding: 29px 20px 20px 20px;
    background-color: #fff;
    box-sizing: border-box;
    .operation {
      margin-left: 40px;
    }
  }
}
</style>
