<template lang="html">
  <el-dialog :title="$t('diversionDetail_00093')" :visible.sync="visible" :lock-scroll='false' width="580px" :before-close="close">
    <div class="wrap">
      <div class="title">
        <div>
          <span class="name">{{$t('diversionDetail_00060')}}：</span>
          <span>{{thirdplatform}}</span>
        </div>
        <div class="hr">
          <span class="name">{{$t('diversionDetail_00011')}}：</span>
          <span>{{dealer}}</span>
        </div>
      </div>
      <div class="title select-wrap">
        <i class="tips"><span>*</span>{{$t("diversionDetail_00094")}}</i>
        <div>
          <span class="name">{{$t('diversionDetail_00095')}}</span>
          <el-select filterable v-model="slowValue" :placeholder="$t('diversionDetail_00096')">
            <el-option
              v-for="(item,key) in slowOptions"
              :value="item.value"
              :label="item.label"
              :key="key">
            </el-option>
          </el-select>
        </div>
        <div>
          <span class="name">{{$t('diversionDetail_00097')}}</span>
          <el-select filterable v-model="fastValue" :placeholder="$t('diversionDetail_00098')">
            <el-option
              v-for="(item,key) in fastOptions"
              :value="item.value"
              :label="item.label"
              :key="key">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">{{$t('diversionDetail_00084')}}</el-button>
      <el-button type="primary" @click="save">{{$t('common_00217')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { requestUrl, ajax, getQueryString } from "@/assets/utils/network";
export default {
  data() {
    return {
      feePoliyList: [],
      slowOptions: [],
      fastOptions: [],
      slowValue: "",
      fastValue: "",
      billType: 0,
    };
  },
  props: [
    "visible",
    "dealer",
    "thirdplatform",
    "id",
    "site",
    "slowId",
    "fastId",
  ],
  methods: {
    close() {
      this.$emit("close", false);
    },
    save() {
      const data = {
        id: this.id,
        feePolicyIdFast: this.fastValue,
        feePolicyIdSlow: this.slowValue,
      };
      let om = this;
      ajax({
        type: "POST",
        url: requestUrl + "/outsideowner/devicegroup",
        dataType: "json",
        data: JSON.stringify(data),
        success: function (response) {
          var info = JSON.parse(response);
          if (info.error != null) {
            if (info.error.code == "NOT_LOGIN") {
              window.location.href = "./login.html?l=0";
            }
            om.$message.error(
              info.error.msg == null ? info.error.code : info.error.msg
            );
          } else {
            om.$message.success(om.$t("diversionDetail_00073"));
            om.close();
            om.$emit("confirm");
          }
        },
      });
    },
  },
  created() {
    this.fastOptions = [];
    this.slowOptions = [];
    // this.billType = this.site.listedFeePolicies[0].billingByTime;
    var dealerFeePolies = this.site.dealerFeePolies;
    console.log(this.site);
    if (dealerFeePolies) {
      for (var i = 0; i < dealerFeePolies.length; i++) {
        var dealerfee = dealerFeePolies[i];
        var obj = new Object();
        obj.value = dealerfee.id;
        obj.label = dealerfee.title;
        obj.billingByTime = dealerfee.billingByTime;
        this.fastOptions.push(obj);
        this.slowOptions.push(obj);
      }
    }
    for (var i = 0; i < this.site.listedFeePolicies.length; i++) {
      this.fastOptions.push({
        value: this.site.listedFeePolicies[i].id,
        label: this.site.listedFeePolicies[i].title,
        billingByTime: this.site.listedFeePolicies[i].billingByTime,
      });
      this.slowOptions.push({
        value: this.site.listedFeePolicies[i].id,
        label: this.site.listedFeePolicies[i].title,
        billingByTime: this.site.listedFeePolicies[i].billingByTime,
      });
    }
    this.slowValue = this.slowId;
    this.fastValue = this.fastId;
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  .title {
    > div {
      display: flex;
      align-items: center;
      height: 80px;
      border-bottom: 1px dashed #e6e7ec;
      .name {
        max-width: 200px;
        min-width: 150px;
        color: #464b5c;
        font-size: 14px;
        font-weight: 400;
      }
      span {
        color: #464b5c;
        font-size: 14px;
      }
    }
  }
  .select-wrap {
    .tips {
      display: inline-block;
      color: #8087a0;
      font-size: 12px;
      padding: 10px 0;
      font-style: normal;
      > span {
        color: #ff302c;
        padding-right: 5px;
      }
    }
  }
}
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
</style>
