export default {
    work_00001: '工单详情',
    work_00002: '维保中心',
    work_00003: '工单系统',
    work_00004: '工单管理',
    work_00005: '非常紧急',
    work_00006: '发送',
    work_00007: '工单编号',
    work_00008: '问题场站',
    work_00009: '问题设备品牌型号',
    work_00010: '问题来源',
    work_00011: '发起人',
    work_00012: '发起时间',
    work_00013: '更新时间',
    work_00014: '当前处理人',
    work_00015: '问题描述',
    work_00016: '相关设备',
    work_00017: '相关设备异常日志',
    work_00018: '相关安全评估日志',
    work_00019: '相关用户',
    work_00020: '解决方案',
    work_00021: '工单流程',
    work_00022: '已完成',
    work_00023: '待处理',
    work_00024: '已处理',
    work_00025: '已关闭',
    work_00026: '转派工单',
    work_00027: '关闭工单',
    work_00028: '平台通信日志查询',
    work_00029: '故障异常代码',
    work_00030: '故障异常描述',
    work_00031: '紧急',
    work_00032: '一般',
    work_00033: '待处理',
    work_00034: '处理中',
    work_00035: '已处理',
    work_00036: '已关闭',
    work_00037: '设备序列号',
    work_00038: '相关订单',
    work_00039: '工作人员上报',
    work_00040: '客户反馈',
    work_00041: '设备上报',
    work_00042: '自动触发',
    work_00043: '其他',
    work_00044: '发 送',
    work_00045: '删除工单后不可恢复',
    work_00046: '加入知识库',
    work_00047: '退回工单',
    work_00048: '接单',
    work_00049: '发起工单',
    work_00050: '手机：{mobile}',

    work_00059: '解决方案',
    work_00060: '历史方案',
    work_00061: '请输入内容',

    work_10006: '问题描述',
    work_10007: '切换设备',
    work_10008: '添加问题',
    work_10009: '场站位置',
    work_10010: '入场须知',
    work_10011: '相关订单',
    work_10012: '选择订单',
    work_10013: '故障信息',
    work_10014: '故障代码',
    work_10015: '全部场站',
    work_10016: '订单编号',
    work_10018: '确认已处理',
    work_10019: '去处理',
    work_10020: '请处理所有问题',
    work_10021: "暂无消息",
    work_10022: "图片",
    work_10023: "视频",

    

    
    // 工单状态弹窗
    orderMangement_00052: '备注',
    orderMangement_00053: '请输入内容',
    orderMangement_00054: '解决方案',
    orderMangement_00055: '转派处理人',
    orderMangement_00056: '接单',
    orderMangement_00057: '是否确认接单',
    orderMangement_00058: '确认接单',
    orderMangement_00059: '转派工单',
    orderMangement_00060: '确认转派',
    orderMangement_00061: '转派原因',
    orderMangement_00062: '关闭工单',
    orderMangement_00063: '是否确认关闭工单',
    orderMangement_00064: '确认关闭',
    orderMangement_00065: '关闭原因',
    orderMangement_00066: '退回工单',
    orderMangement_00067: '是否确认退回工单',
    orderMangement_00068: '确认退回',
    orderMangement_00069: '退回原因',
    orderMangement_00070: '已处理工单',
    orderMangement_00071: '是否确认已处理工单',
    orderMangement_00072: '确认已处理',
    orderMangement_00073: '请填写完整',
    orderMangement_00074: '操作成功',
    orderMangement_00082: '成为关注人',
    orderMangement_00083: '桩企',

    
    detailDepot_00035: '实景图片',
    detailDepot_00036: '地址、街道、房屋名称',
    detailDepot_00037: '查 找',
    detailDepot_00038: '名 称',
    detailDepot_00039: '详细地址',
    detailDepot_00040: '当前位置',
    detailDepot_00041: '取 消',
    detailDepot_00042: '使用此位置',
    detailDepot_00043: '选择充电站位置坐标',
    detailDepot_00044: '您修改的地图位置与地址不吻合，误差在200米以上！',
    detailDepot_00045: "提 示",
    detailDepot_00046: "我知道了",
    detailDepot_00047: "地址未找到",
    detailDepot_00048: "高德地图发生未知错误",

    
    work_21000: "物料编码",
    work_21001: "物料名称",
    work_21002: "规格型号",
    work_21003: "数量",


}
