export default {
    weChatApp_00001: "微信公众号",
    weChatApp_00002: "微信支付",
    weChatApp_00003: "公众号信息",
    weChatApp_00004: "公众号名称",
    weChatApp_00005: "公众号头像",
    weChatApp_00006: "站点显示规则",
    weChatApp_00007: "公众号中显示第三方站点",
    weChatApp_00008: "共享我的站点至其他平台",
    weChatApp_00009: "授权目录地址 ",
    weChatApp_000010: "进入微信公众号平台，在「自定义菜单」中添加菜单，菜单内容选择「跳转网页」，将下方URL地址复制到「页面地址」中。",
    weChatApp_000011: "查找充电站地址",
    weChatApp_000012: "个人中心地址",
    weChatApp_000013: "前往微信公众平台",
    weChatApp_000014: "接入微信支付指引",
    weChatApp_000015: "按照 接入微信支付指引 内容完成相关操作，即可使用微信支付服务。",
    weChatApp_000016: "微信支付商户号支付授权目录设置",
    weChatApp_000017: "前往微信商户平台",
    weChatApp_000018: "进入微信支付商户平台，在「产品中心」的「开发配置」中，将下方地址复制并添加到「公众号支付」的「支付授权目录」。注意：在下拉列表中选择'https://'。",
    weChatApp_000019: "授权目录地址",
    weChatApp_000020: "复制链接",
    weChatApp_000021: "微信商户平台支付商户号设置",
    weChatApp_000022: "进入微信商户平台，在「账户中心」的「商户信息」中，将「基本账户信息」的「微信支付商户号」添加到此处。",
    weChatApp_000023: "微信支付商户号",
    weChatApp_000024: "微信商户平台API秘钥设置",
    weChatApp_000025: "进入微信商户平台，在「账户中心」的「API安全」中，将下放秘钥复制到「API秘钥」的「秘钥设置」中。",
    weChatApp_000026: "修改密钥",
    weChatApp_000027: "生成新的密钥",
    weChatApp_000028: "生成新的秘钥后，必须进入微信商户平台，在「账户中心」的「API安全」中，将下方生成的秘钥复制到「API秘钥」的「秘钥设置」中，再返回平台保存秘钥，才能保证正常的收款。",
    weChatApp_000029: "上传证书",
    weChatApp_000030: "上传证书后可开启自动退款功能",
    weChatApp_000031: "请登录微信支付商户平台，点击【账户中心】->【账户设置】->【API安全】， 如果看到的是【下载证书】按钮，则直接下载证书文件，并在本页面内上传证书；<br/>如果看到的是【申请证书】按钮，则参考<a href='http://kf.qq.com/faq/161222NneAJf161222U7fARv.html' target=_blank>该微信文档</a>申请证书后，在本页面内上传 .p12 格式的证书文件。",
    weChatApp_000032: "证书已上传",
    weChatApp_000033: "点击上传",
    weChatApp_000034: "请确保证书为最新版(请关注微信商户平台通知)",
    weChatApp_000035: "智充只将该证书用于退款操作。",
    weChatApp_000036: "特别提醒：支付证书属于机密信息，请谨慎使用该功能。",
    weChatApp_000037: "微信商户平台支付商户号设置完成后，您可在此处设置在微信公众号内显示微信支付支付方式为“是”，否则用户在微信公众号内无法使用微信支付。",
    weChatApp_000038: "微信公众号支持微信支付支付方式",
    weChatApp_000039: "请保存上一步操作！！",
    weChatApp_000040: "已复制-'充电站'页面地址",
    weChatApp_000041: "已复制-'历史账单'页面地址",
    weChatApp_000042: "修改成功，请更新微信公众号底部菜单的查找充电站地址",
    weChatApp_000043: "修改成功",
    weChatApp_000044: "已复制-'支付授权目录'页面地址",
    weChatApp_000045: "复制秘钥",
    weChatApp_000046: "已复制-'秘钥'",
    weChatApp_000047: "确认保存密钥",
    weChatApp_000048: "特别提示",
    weChatApp_000049: "如果未在微信商户平台上修改秘钥，就直接保存修改，会造成充电订单无法支付，点击「取消」放弃保存。如确认已修改，30秒倒计时结束后点击「保存」。",
    weChatApp_000050:"点击上传",
    weChatApp_000051:"上传",
    weChatApp_000052:"必须是zip或p12格式文件",
    weChatApp_000053:"上传成功",
    weChatApp_000054:"确定删除证书吗？",
    weChatApp_000055:"提醒",
    weChatApp_000056:"无操作权限",
    weChatApp_000057:"上传失败",
    
}