export default {
  deviceMonitor_00001: 'Belegt',
  deviceMonitor_00002: 'Gerät noch nicht aktiviert',
  deviceMonitor_00003: 'Gerät ist offline',
  deviceMonitor_00005: 'Gehe in den verfügbar',
  deviceMonitor_00006: 'Laden starten',
  deviceMonitor_00008: 'In Reservierung',
  deviceMonitor_00009: 'Zurzeit keine Fehlercodes',
  deviceMonitor_00010: "Gerät erfolgreich neu gestartet",
  deviceMonitor_00011: "Ferngesteuerter Neustart",
  deviceMonitor_00012: "Neustart fehlgeschlagen",
  deviceMonitor_00013: "Geräteverwaltung",
  deviceMonitor_00014: "Aktuelle Störung",
  deviceMonitor_00015: "Fehlersuche",
  deviceMonitor_00016: "Bitte geben Sie den Gerätenamen oder die Seriennummer ein",
  deviceMonitor_00017: "Wählen Sie den Namen der Ladestation oder geben Sie ihn ein",
  deviceMonitor_00018: "Alle",
  deviceMonitor_00019: "Gerätetyp",
  deviceMonitor_00020: "DC",
  deviceMonitor_00021: "AC",
  deviceMonitor_00022: "Gateway",
  deviceMonitor_00023: "Störung",
  deviceMonitor_00024: "Offline",
  deviceMonitor_00025: "verfügbar",
  deviceMonitor_00026: "Nur Eingesteckte anzeigen",
  deviceMonitor_00027: "In Nutzung",
  deviceMonitor_00028: "Nur Zuziehende anzeigen",
  deviceMonitor_00029: "Offline",
  deviceMonitor_00030: "Nicht aktiviert",
  deviceMonitor_00031: "Gerätsseriennummer",
  deviceMonitor_00032: "Hersteller-Modell",
  deviceMonitor_00033: "Ladestation",
  deviceMonitor_00034: "Gerät neu starten",
  deviceMonitor_00035: "Gerätedetails",
  deviceMonitor_00036: "Ladepunkte",
  deviceMonitor_00037: "Offline",
  deviceMonitor_00038: "Nicht aktiviert",
  deviceMonitor_00039: "Störung",
  deviceMonitor_00040: "Belegt",
  deviceMonitor_00041: "Im Laden",
  deviceMonitor_00042: "verfügbar",
  deviceMonitor_00043: "Fahrzeug zu bewegen",
  deviceMonitor_00044: "In Reservierung",
  deviceMonitor_00045: "Eingesteckte",
  deviceMonitor_00046: "zu ziehende",
  deviceMonitor_00047: "Vorgänge anzeigen",
  deviceMonitor_00048: "Ladepunkte neu starten",
  deviceMonitor_00049: "Der Befehl zum Neustart des Geräts wurde gesendet, der Neustartvorgang wird etwa 5 Minuten dauern. Bitte bleiben Sie auf dieser Seite und aktualisieren Sie die Seite nicht. ",
  deviceMonitor_00050: "Hinweise",
  deviceMonitor_00051: "确 Bestätigen",
  deviceMonitor_00052: "Geräteverwaltung",
  deviceMonitor_00053: "Aktuelle Störung",
  deviceMonitor_00054: "Einstecken",
  deviceMonitor_00055: "Ladeende",
  deviceMonitor_00056: "Nicht aktiviert",
  deviceMonitor_00057: "Normal",
  deviceMonitor_00058: "Störung",
  deviceMonitor_00059: "Gesperrt",
  deviceMonitor_00060: "Ruhezustand",
  deviceMonitor_00061: "Unbekannt",
  deviceMonitor_00062: "Gerätestatus",
  deviceMonitor_00063: "Störung",
  deviceMonitor_00064: "nur nicht zurückgegebene anzeigen", 
  deviceMonitor_00065: "zurückgegebene", 
  deviceMonitor_00066: "nicht zurückgegebene", 
  deviceMonitor_00067: "abnormal",
  deviceMonitor_00068: "Energiespeicherschrank",
  deviceMonitor_00086: "Ladegerät hinzufügen",
}