<template>
  <el-dialog :title="diaTitle" width="800px" :lock-scroll="false" custom-class="limited_charge_dialog" :visible="visible" @close="close">
    <div class="step">
      <el-steps :active="active" simple>
        <el-step :status='step[0]' :title="$t('common_61114')"></el-step>
        <el-step :status='step[1]' :title="$t('common_61115')"></el-step>
      </el-steps>
    </div>
    <!-- 查询用户匹配的车辆VIN -->
    <div class="select-car">
      <div class="box" v-show="active==1">
        <div class="search-wrap">
          <el-input size="medium" :placeholder="$t('common_61116')" class="search-inp" v-model="keyword"></el-input>
          <el-button type="primary" size="medium" class="search" icon="el-icon-search" @click='search'>{{$t('common_00255')}}</el-button>
        </div>
        <p class="tip"><i class="el-icon-warning"></i>{{$t('common_61117')}}</p>
      </div>
      <el-table v-loading="loading" :data="active==2?chooseData:tableData" :max-height="320" :header-cell-style="{
        height: '54px',
        fontSize: '14px',
        background:'#EFF2F6',
        color:'#1B2330',
        borderRadius: '4px 4px 0px 0px'}">
        <el-table-column :label="$t('common_00195')" prop="licenseNo" show-overflow-tooltip :formatter="row=>row.licenseNo || '-'"></el-table-column>
        <el-table-column :label="product !='cn'? 'MAC':'VIN'" show-overflow-tooltip prop="vid" :formatter="row=>row.vid || '-'" min-width="120"></el-table-column>
        <el-table-column :label="$t('common_61118')" prop="vmb" show-overflow-tooltip :formatter="row=>row.vmb || '-'"></el-table-column>
        <el-table-column :label="$t('common_00197')" prop="mobile" show-overflow-tooltip :formatter="row=>row.mobile || '-'"></el-table-column>
        <el-table-column :label="$t('common_61119')" prop="nickname" show-overflow-tooltip>
          <template slot-scope="scope">
            <a v-if="scope.row.userType=='wechat'" :href="`./userDetail.html?id=${scope.row.userId}`" :target="isOpenInCurrentPage() && '_blank'">
              <el-button type="text" class="sele" size="medium">{{scope.row.nickname||'-'}}</el-button>
            </a>
            <a v-if="scope.row.userType=='card'" :href="`./outlineCard.html?id=${scope.row.card?.id}&groupId=${scope.row.card?.groupId}`" :target="isOpenInCurrentPage() && '_blank'">
              <el-button type="text" class="sele" size="medium">{{scope.row.nickname||'-'}}</el-button>
            </a>
            <el-button v-if="scope.row.userType=='user'" type="text" class="sele" size="medium">{{scope.row.nickname||'-'}}</el-button>
          </template>
        </el-table-column>
        <el-table-column v-if="active==1" width="60" :label="$t('common_00282')">
          <template slot-scope="scope">
            <el-button type="text" class="sele" size="medium" @click="chooseEvent(scope.row)">{{$t('common_00310')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="page" v-show="active==1">
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" layout="total, prev, pager, next, jumper" :total="dataNum">
        </el-pagination>
      </div>
    </div>
    <!-- 限制内容 -->
    <div class="setting" v-show="active==2">
      <div class="left">
        <el-radio-group v-model="chargeLimitType">
          <el-radio :label="1">{{$t('common_61120')}}</el-radio>
          <el-radio :label="2">{{$t('common_61121')}}</el-radio>
        </el-radio-group>
        <div class="part" v-show="chargeLimitType==1">
          <span class="title">{{$t('common_61122')}}
            <el-tooltip effect="dark" placement="right">
              <div slot="content">{{$t('common_61123')}}<br />{{$t('common_61124')}}</div>
              <i class="icon_tip el-icon-warning"></i>
            </el-tooltip>
          </span>
          <span>
            {{$t('common_61126')}}<el-input class="w220" v-model="SOC" :placeholder="$t('common_61125')" size="small"></el-input>%
          </span>
        </div>
        <div class="part">
          <span class="title">{{$t('common_00313')}}</span>
          <el-radio-group v-model="timeType">
            <el-radio :label="1" style="margin-bottom: 15px;">{{$t('common_61127')}}
              <date-picker style="margin-left: 5px;" pickerType="date" :pickerOptions="pickerOptions" :width="160" valueFormat="yyyyMMdd" @chooseTime="chooseTime" :defaultTime="timeValue" size="small" />
            </el-radio>
            <el-radio :label="2">{{$t('common_61128')}}<span class="tips">{{$t('common_61129')}}</span></el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="right part">
        <span class="title">{{$t('common_61130')}}</span>
        <el-input type="textarea" class="w348" maxlength="50" :rows="4" show-word-limit v-model="chargeLimitExplain" :placeholder="$t('common_61131')"></el-input>
      </div>
    </div>
    <span slot="footer" v-show="active==2" class="dialog-footer">
      <el-button size="medium" type="danger" plain @click="del" v-if="delFlag" style="margin-right:20px;">{{$t('common_00309')}}</el-button>
      <el-button size="medium" @click="active=1" v-show="!limitContent" style="margin-right:20px;">{{$t('common_00311')}}</el-button>
      <el-button type="primary" @click="confirm" size="medium">{{$t('common_00272')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { requestUrl, ajax } from "@/assets/utils/network";
import datePicker from "@/components/datePicker";
export default {
  props: ["visible", "limitContent", "delFlag"],
  components: {
    datePicker,
  },
  data() {
    return {
      diaTitle: "",
      product: "cn",
      keyword: "",
      currentPage: 1,
      tableData: [],
      chooseData: [],
      dataNum: 0,
      loading: false,
      // 步骤器的步数
      active: 1,
      step: ["", "wait", "wait", "wait"],
      // 禁止选择今天以后的日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        },
      },
      // 限制内容
      chargeLimitType: 0,
      timeType: 0,
      timeValue: "", // 固定-结束日期
      SOC: "",
      chargeLimitExplain: "",
    };
  },
  watch: {
    visible(val) {
      if (!val) return;
      this.diaTitle = this.$t("common_61105");
      this.active = this.limitContent ? 2 : 1; // limitContent 有值代表修改；null 代表添加
      if (!this.limitContent) {
        this.diaTitle = this.$t("common_61113");
        // 添加
        this.getList();
      } else if (
        Object.prototype.toString.call(this.limitContent) == "[object String]"
      ) {
        // 通过vid来查询当前选中的车辆信息
        this.keyword = this.limitContent;
        this.getList((res) => {
          this.formmatVal(res.length ? res[0] : "");
        });
      } else if (
        Object.prototype.toString.call(this.limitContent) == "[object Object]"
      ) {
        // 从前一个页面带过来的当前车辆信息
        this.formmatVal(this.limitContent);
      }
    },
    active(val) {
      let temp = this.step.map((item, index) => {
        if (index == val - 1) {
          item = "";
        } else if (index < val - 1) {
          item = "success";
        } else if (index > val - 1) {
          item = "wait";
        }
        return item;
      });
      this.step = temp;
    },
  },
  methods: {
    getList(callback) {
      this.loading = true;
      let url = `${requestUrl}/dealer/vehicle?from=${
        (parseInt(this.currentPage) - 1) * 10
      }&length=10&returnCount=true&keyword=${this.keyword}`;
      !callback && (url += "&chargeLimitType=0");
      ajax({
        type: "GET",
        url: url,
        dataType: "json",
        success: (response) => {
          this.loading = false;
          this.tableData = JSON.parse(response).result || [];
          this.dataNum = JSON.parse(response).totalCount;
          callback && callback(this.tableData);
        },
      });
    },
    chooseEvent(row) {
      this.formmatVal(row);
      this.active = 2;
    },
    chooseTime(val) {
      this.timeValue = val;
    },
    // 完成
    confirm() {
      if (!this.chargeLimitType || (this.chargeLimitType == 1 && !this.SOC)) {
        this.$message.error(this.$t("common_61132"));
        return;
      }
      if (!this.timeType || (this.timeType == 1 && !this.timeValue)) {
        this.$message.error(this.$t("common_61132"));
        return;
      }
      if (!this.chargeLimitExplain) {
        this.$message.error(this.$t("common_61132"));
        return;
      }
      if (this.chargeLimitType == 1 && !/^[1-9]([0-9]?)$/.test(this.SOC)) {
        this.$message.error(this.$t("common_61133"));
        return;
      }
      // 所选日期不能是今天及之前日期
      if (this.timeType == 1) {
        let time = this.formmatTime(this.timeValue).getTime();
        if (!(time > Date.now())) {
          this.$message.error(this.$t("common_61133"));
          return;
        }
      }
      let params = new Object();
      params.vid = this.chooseData[0]?.vid;
      params.chargeLimitType = this.chargeLimitType;
      let chargeLimitRule = {};
      this.chargeLimitType == 1 && (chargeLimitRule.soc = Number(this.SOC));
      this.timeType == 1 &&
        (chargeLimitRule.endTime = this.timeValue + "235959");
      params.chargeLimitRule = chargeLimitRule;
      params.chargeLimitExplain = this.chargeLimitExplain;
      ajax({
        type: "POST",
        url: requestUrl + "/dealer/vehicle",
        dataType: "json",
        data: JSON.stringify(params),
        success: (response) => {
          var info = JSON.parse(response);
          if (info.error != null) {
            if (info.error.code == "NOT_LOGIN") {
              window.location.href = "./login.html?l=0";
            }
            this.$message.error(
              info.error.msg == null ? info.error.code : info.error.msg
            );
          } else {
            this.$message({
              message: this.$t("common_61025"),
              type: "success",
            });
            this.$emit("confirm", false);
          }
        },
      });
    },
    // 移除
    del() {
      let params = new Object();
      params.vid = this.chooseData[0]?.vid;
      params.chargeLimitType = 0;
      ajax({
        type: "POST",
        url: `${requestUrl}/dealer/vehicle`,
        dataType: "json",
        data: JSON.stringify(params),
        success: (response) => {
          var info = JSON.parse(response);
          if (info.error != null) {
            if (info.error.code == "NOT_LOGIN") {
              window.location.href = "./login.html?l=0";
            }
            this.$message.error(
              info.error.msg == null ? info.error.code : info.error.msg
            );
          } else {
            this.$message({
              message: this.$t("common_61025"),
              type: "success",
            });
            this.$emit("confirm", false);
          }
        },
      });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    //分页
    handleCurrentChange(val) {
      var page = `${val}`;
      if (this.currentPage == page) {
        return;
      }
      this.currentPage = Number(page);
      this.getList();
    },
    close() {
      this.$emit("close", false);
    },
    formmatVal(val) {
      this.chooseData = val ? [val] : [];
      this.chargeLimitType = val?.chargeLimitType || 1;
      this.chargeLimitExplain =
        val?.chargeLimitType && val?.chargeLimitExplain
          ? val?.chargeLimitExplain
          : "";
      let chargeLimitRule = JSON.parse(val?.chargeLimitRule || "{}");
      this.SOC = chargeLimitRule?.soc || "";
      this.timeValue = "";
      if (val?.chargeLimitType && !chargeLimitRule?.endTime) {
        this.timeType = 2; // 长期
      } else if (val?.chargeLimitType && chargeLimitRule?.endTime) {
        this.timeType = 1; // 固定时间
        this.timeValue = chargeLimitRule.endTime.substr(0, 8);
      } else {
        this.timeType = 0;
      }
    },
    formmatTime(longTime) {
      longTime = "" + longTime;
      let str =
        longTime.substring(0, 4) +
        "-" +
        longTime.substring(4, 6) +
        "-" +
        longTime.substring(6, 8);
      return new Date(str);
      +" " +
        longTime.substring(8, 10) +
        ":" +
        longTime.substring(10, 12) +
        ":" +
        longTime.substring(12, 14);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog {
  border-radius: 4px;
  .el-dialog__header {
    border-radius: 4px 4px 0 0;
    background-color: #eff2f6;
    .el-dialog__title {
      font-weight: 600;
      color: #8087a0;
      font-size: 16px;
    }
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  font-size: 20px;
  color: #8087a0;
}
.el-button {
  // font-weight: 600;
  /deep/i {
    font-weight: 600;
  }
}
/deep/.el-step.is-simple .el-step__title {
  font-size: 14px;
}
img {
  width: 100%;
}
.step {
  // width: 335px;
  text-align: left;
  margin: 30px auto;
  font-weight: 600;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/.el-steps--simple {
    padding: 10px 120px;
    width: 100%;
    box-sizing: border-box;
  }
}
// 第一页
.select-car {
  .sele {
    font-weight: 600;
    color: #5278e2;
  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .search-wrap {
    .search-inp {
      width: 220px;
      margin-right: 20px;
    }
  }
  .tip {
    font-size: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41526d;
    i {
      margin-right: 10px;
      color: #9097ac;
      font-size: 18px;
      vertical-align: middle;
    }
  }
}
// 第二页
.setting {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  .part {
    display: flex;
    flex-direction: column;
    > span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41526d;
      &.title {
        margin-bottom: 6px;
        &::before {
          content: "*";
          color: #ff453a;
          margin-right: 5px;
        }
        & + span,
        & + div {
          margin-left: 10px;
        }
        .icon_tip {
          color: #9097ac;
          font-size: 18px;
          vertical-align: text-bottom;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }
  .left {
    .part {
      margin-top: 20px;
    }
  }
  .tips {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9399ae;
  }
}
/*
* 分页器
*/
.page {
  width: inherit;
  height: auto;
  margin-top: 30px;
  text-align: center;
}
.w220 {
  width: 220px;
  margin: 0 5px;
}
.w348 {
  width: 348px;
}
</style>