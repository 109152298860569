export default {
    selfHelpPayCost_00001: '收费项目',
    selfHelpPayCost_00002: '充电桩',
    selfHelpPayCost_00003: '充电站',
    selfHelpPayCost_00004: '时长',
    selfHelpPayCost_00005: '收费金额（元）',
    selfHelpPayCost_00006: '元',
    selfHelpPayCost_00007: '年',
    selfHelpPayCost_00008: '月',
    selfHelpPayCost_00009: '账单详情',
    selfHelpPayCost_00010: '待出账单详情',
    selfHelpPayCost_00010: '待出账单详情',
    selfHelpPayCost_00011: '物联卡网费',
    selfHelpPayCost_00012: '账户详情',
    selfHelpPayCost_00013: '余额明细',
    selfHelpPayCost_00014: '充值',
    selfHelpPayCost_00015: '收费账单扣款',
    selfHelpPayCost_00016: '时间',
    selfHelpPayCost_00017: '内容',
    selfHelpPayCost_00018: '金额（元）',
    selfHelpPayCost_00019: '备注',
    selfHelpPayCost_00020: '微信支付（运营商）',
    selfHelpPayCost_00021: '支付宝支付（运营商）',
    selfHelpPayCost_00022: '北京智充电科技有限公司',
    selfHelpPayCost_00023: '账单',
    selfHelpPayCost_00024: '收费账单',
    selfHelpPayCost_00025: '每月1日10:00出上月收费账单，请确保每月10日19:30前账户余额能够足额退款，以免逾期欠费影响相关服务。',
    selfHelpPayCost_00026: '收费金额',
    selfHelpPayCost_00027: '已扣款',
    selfHelpPayCost_00028: '未扣款',
    selfHelpPayCost_00029: '已欠费',
    selfHelpPayCost_00030: '已开发票',
    selfHelpPayCost_00031: '未开发票',
    selfHelpPayCost_00032: '详情',
    selfHelpPayCost_00033: '充电桩分配到运营商#simFreePeriod#天后，#simPrice#/桩/30日。',
    selfHelpPayCost_00034: '账单日',
    selfHelpPayCost_00035: '当前金额',
    selfHelpPayCost_00035: '当前金额',
    selfHelpPayCost_00036: '收费周期',
    selfHelpPayCost_00037: '账户余额',
    selfHelpPayCost_00038: '充值金额',
    selfHelpPayCost_00039: '选择支付方式',
    selfHelpPayCost_00040: '微信支付',
    selfHelpPayCost_00041: '支付宝支付',
    selfHelpPayCost_00042: '其他金额',
    selfHelpPayCost_00043: '支付完成',
    selfHelpPayCost_00044: '上一步',
    selfHelpPayCost_00045: '账户充值',
    selfHelpPayCost_00046: '待出账单金额',
    selfHelpPayCost_00047: '已出账单未扣款金额',
    selfHelpPayCost_00048: '已欠费',
    selfHelpPayCost_00049: '合计金额',
    selfHelpPayCost_00050: '请输入充值金额',
    selfHelpPayCost_00051: '选择支付方式',
    selfHelpPayCost_00052: '不能输入负数',
    selfHelpPayCost_00053: '充值成功',
    selfHelpPayCost_00054: '充值失败',
    selfHelpPayCost_00055: '请输入正确的充值金额',
    selfHelpPayCost_00056: '收费规则',
    selfHelpPayCost_00057: '物联卡网费 - 充电桩分配到运营商365天后，12.5元/桩/30日。',
    selfHelpPayCost_100058: '公对公转账',
    selfHelpPayCost_100059: '1、您可以使用您的企业网银公对公转账方式进行充值，平台会根据您转账交易流水中的付款人名称和转账金额自动匹配为您的平台账户充值。',
    selfHelpPayCost_100060: '2、请注意您的付款人名称需和您申请平台注册码时提供的公司名称（<span style="color:#FA545E">{title}</span>）一致，平台才能自动匹配账户充值成功。如您的公司名称有误，请联系平台修改。',
    selfHelpPayCost_100061: '3、公对公转账金额不会立即充值到您的平台账户中，会有几分钟延迟，最晚一般转账当天24时前充值成功。平台充值成功后会发送通知，也会给您注册手机号发送充值成功短信，请及时查看。可在余额明细中查看相关详情。',
    
    selfHelpPayCost_100062: '公对公转账收款人信息',
    selfHelpPayCost_100063: '收款人账号：110916224910502',
    selfHelpPayCost_100064: '收款人名称：北京智充科技有限公司',
    selfHelpPayCost_100065: '收款人开户行：招商银行股份有限公司北京大运村支行',
    selfHelpPayCost_100066: '收款人开户行联行号：308100005221',
    selfHelpPayCost_100067: '您有任何问题可拨打010-53609116 ，时间：工作日早10：00点至晚18：00点；您也可向您对接的智充业务同事进行咨询。',

    selfHelpPayCost_100068: '公对公转账（运营商）',
    selfHelpPayCost_100069: '流水号（收款行）:',
    selfHelpPayCost_100070: '交易日:',
    selfHelpPayCost_100071: '交易时间:',
    selfHelpPayCost_100072: '交易金额:',
    selfHelpPayCost_100073: '付款人账号:',
    selfHelpPayCost_100074: '付款人名称:',
    selfHelpPayCost_100075: '付款人开户行:',
}