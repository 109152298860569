<template lang="html">
    <!-- 急停恢复 -->
    <el-dialog
        :title="$t('common_13001')"
        :lock-scroll="false"
        width="580px"
        top="25vh"
        :visible.sync="visible"
        :before-close="close"
        v-loading="stopLoading">
        <div class="affirm">
            <p>{{ $t("eqDetails_30028") }}</p>
            <p>{{ $t("eqDetails_30029") }}</p>
        </div>
        <div class="btn">
            <el-button @click="close">{{
                $t("common_00215")
            }}</el-button>
            <el-button type="primary" @click="restoreStop">{{
                $t("eqDetails_30030")
            }}</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { requestUrl, ajax } from "@/assets/utils/network";
export default {
    data() {
        return {
            stopLoading: false,
        };
    },
    props: ["visible", "deviceId"],
    methods: {
        close() {
            this.$emit("cancel", false);
        },
        //恢复急停
        restoreStop() {
            const obj = { id: this.deviceId };
            ajax({
                type: "POST",
                url: `${requestUrl}/device/emergency/reset`,
                dataType: "json",
                data: JSON.stringify(obj),
                success: (response) => {
                    const info = JSON.parse(response);
                    if (info.error) {
                        this.$message.error(
                            info.error.msg ? info.error.msg : info.error.code
                        );
                    } else {
                        this.stopLoading = true;
                        setTimeout(() => {
                            location.reload();
                        }, 5000);
                    }
                },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.dialogWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
        display: flex;
        .el-button {
            margin-left: 20px;
            margin-top: 20px;
        }
    }
}
.affirm {
    p {
        line-height: 25px;
        -ms-overflow-style: 20px;
    }
}
.btn {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
</style>
