export default {
  orderMangement_00001: '自动工单触发器',
  orderMangement_00002: '触发器名称',
  orderMangement_00003: '触发器是否启用',
  orderMangement_00004: '全部',
  orderMangement_00005: '启用',
  orderMangement_00006: '运营类触发器',
  orderMangement_00007: '设备类触发器',
  orderMangement_00008: '工单类别',
  orderMangement_00009: '触发条件',
  orderMangement_00010: '工单处理人',
  orderMangement_00011: '工单关注人',
  orderMangement_00012: '工单通知时间',
  orderMangement_00013: '新建触发器',
  orderMangement_00014: '当设备上报设备故障或者设备异常时，如果触发器设置的时间间隔后仍未恢复正常，自动发起工单排障。（注意：部分设备故障会自动恢复，如果时间间隔设置太短容易产生误报工单）',
  orderMangement_00015: '全部',
  orderMangement_00016: '工单分类',
  orderMangement_00017: '设备序列号',
  orderMangement_00018: '全部厂商设备',
  orderMangement_00019: '全部型号',
  orderMangement_00020: '待处理工单',
  orderMangement_00021: '处理中工单',
  orderMangement_00022: '已处理工单',
  orderMangement_00023: '已关闭工单',
  orderMangement_00024: '工单列表',
  orderMangement_00025: '导出工单',
  orderMangement_00026: '工单编号',
  orderMangement_00027: '工单标题',
  orderMangement_00028: '分类',
  orderMangement_00029: '优先级',
  orderMangement_00030: '状态',
  orderMangement_00031: '问题场站',
  orderMangement_00032: '处理人',
  orderMangement_00033: '发起人',
  orderMangement_00034: '发起时间',
  orderMangement_00035: '更新时间',
  orderMangement_00036: '问题来源',
  orderMangement_00037: '操作',
  orderMangement_00038: '退回工单',
  orderMangement_00039: '已处理',
  orderMangement_00040: '接单',
  orderMangement_00041: '转派工单',
  orderMangement_00042: '关闭工单',
  orderMangement_00043: '加入知识库',
  orderMangement_00044: '详情',
  orderMangement_00045: '非常紧急',
  orderMangement_00046: '紧急',
  orderMangement_00047: '一般',

  orderMangement_00048: '当系统统计发现几天内某充电口异常订单达到一定数量时，自动发起工单进行排查。',
  orderMangement_00049: '当系统统计发现几天内某用户异常订单达到一定数量时，自动发起工单进行排查。',
  orderMangement_00050: '当系统发现在几天内，某个站超过几个桩在没有故障异常的情况下，没有订单，自动发起工单进行排查。',
  orderMangement_00051: '异常订单统计-充电口',
  orderMangement_00052: '异常订单统计-用户',
  orderMangement_00053: '呆滞桩',
  orderMangement_00054: '设备类触发器',
  orderMangement_00055: '',
  orderMangement_00056: '',
  orderMangement_00057: '',
  orderMangement_00058: '',
  orderMangement_00059: '',
  orderMangement_00060: '',
  orderMangement_00061: '',
  orderMangement_00062: '',
  orderMangement_00063: '',
  orderMangement_00064: '',
  orderMangement_00065: '关闭原因',
  orderMangement_00066: '退回工单',
  orderMangement_00067: '是否确认退回工单',
  orderMangement_00068: '确认退回',
  orderMangement_00069: '退回原因',
  orderMangement_00070: '已处理工单',
  orderMangement_00071: '是否确认已处理工单',
  orderMangement_00072: '确认已处理',
  orderMangement_00073: '请填写完整',
  orderMangement_00074: '操作成功',
  orderMangement_00075: '异常',
  orderMangement_00076: '连续',
  orderMangement_00077: '设置',
  orderMangement_00078: '个站点',
  orderMangement_00079: '天达到',
  orderMangement_00080: '单异常',
  orderMangement_00081: '分钟后',
  orderMangement_00082: '说明',
  orderTriggerList_00001:"充电桩离线",
  orderTriggerList_00002:"急停按钮被按下",
  orderTriggerList_00003:"电源模块报警",
  orderTriggerList_00004:"电磁锁上锁/解锁操作失败",


  





}