export default {
    energyStatistics_00001: "储能放电量",
    energyStatistics_00002: "电网使用量",
    energyStatistics_00003: "车充电电量（kWh）",
    energyStatistics_00004: "能源收益趋势({unit})",
    energyStatistics_00005: "车充电能源总收入",
    energyStatistics_00006: "储能补电电量（kWh）",
    energyStatistics_00007: "分析",
    energyStatistics_00008: "电网部分充电成本",
    energyStatistics_00009: "储能部分补电成本",
    energyStatistics_00010: "能源收益",
    energyStatistics_00011: "车充电使用电量",
    energyStatistics_00012: "车充电使用 (kWh)",
    energyStatistics_00013: "能源总收入({unit})",
    energyStatistics_00014: "能源总收益",
    energyStatistics_00015: "",
    energyStatistics_00016: "",
   
} 