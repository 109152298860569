export default {
    analyzeIncome_00001: "整体收入趋势分析",
    analyzeIncome_00002: "站点收入分析",
    analyzeIncome_00003: "团体收入分析",
    analyzeIncome_00004: "用户来源收入分析",
    analyzeIncome_00005: "加盟商收入分析",
    analyzeIncome_00006: "收入趋势",
    analyzeIncome_00007: "分析",
    analyzeIncome_00008: "收入（{0}）",
    analyzeIncome_00009: "收入设备型号分布",
    analyzeIncome_00010: "收入城市分布",
    analyzeIncome_00011: "站点收入对比",
    analyzeIncome_00012: "站点收入趋势",
    analyzeIncome_00013: "团体收入对比",
    analyzeIncome_00014: "团体收入趋势",
    analyzeIncome_00015: "加盟商收入对比",
    analyzeIncome_00016: "加盟商收入对比",
    analyzeIncome_00017: "选择设备型号",
    analyzeIncome_00018: "站点利用率对比",
    analyzeIncome_00019: "站点利用率趋势",
    analyzeIncome_00020: "设备型号利用率对比",
    analyzeIncome_00021: "设备型号利用率趋势",
    analyzeIncome_00022: "充电利用率",
    analyzeIncome_00023: "加盟商利用率对比",
    analyzeIncome_00024: "加盟商利用率趋势",
    analyzeIncome_00025: "城市",
    analyzeIncome_00026: "产品型号",
    analyzeIncome_00027: "服务费（{0}）",
    analyzeIncome_00028: "电费（{0}）",
    analyzeIncome_00029: "充电中枪口",
    analyzeIncome_00030: "充电订单金额分析",
    analyzeIncome_00034: "用户来源收入占比",
    analyzeIncome_00035: "第三方流量中各流量占比",
    analyzeIncome_00036: "用户来源收入趋势",
    analyzeIncome_00037: "第三方流量收入趋势",
    analyzeIncome_00038: "货币单位",
    analyzeIncome_00039: "枪均收入趋势",
    analyzeIncome_00040: "延时费（元）",
    analyzeIncome_00041: "枪均电费（元）",
    analyzeIncome_00042: "枪均服务费（元）",
    analyzeIncome_00043: "枪均延时费（元）",
} 