export default {
  contrastCurve_00001: 'Kurvevergleich',
  contrastCurve_00002: 'Vorgängezentrum',
  contrastCurve_00003: 'alle Vorgänge',
  contrastCurve_00004: 'Vorgänge-Einzelheit',
  contrastCurve_00005: 'Kurvevergleich',
  contrastCurve_00006: 'Vorgängeinfor',
  contrastCurve_00007: 'Ladeinfor',
  contrastCurve_00008: 'aktuelle Vorgang',
  contrastCurve_00009: 'Vorgang in gleicher Zeit von gleiche Ladesäule',
  contrastCurve_00010: 'Elektrizität',
  contrastCurve_00011: 'kWh',
  contrastCurve_00012: 'Einzelheit anzeigen',
  contrastCurve_00013: 'Leistung',
  contrastCurve_00014: 'Spannung',
  contrastCurve_00015: 'angefordete Spannung',
  contrastCurve_00016: 'Strom',
  contrastCurve_00017: 'angefordete Strom',
  contrastCurve_00018: 'Tag',
  contrastCurve_00019: 'Stunde',
  contrastCurve_00020: 'Minuten',
  contrastCurve_00021: 'Gruppe',
  contrastCurve_00022: 'Wechat Benutzer',
  contrastCurve_00023: "Verwalter",

  contrastCurve_00024: 'Startdatum',
  contrastCurve_00025: 'Endedatum',
  contrastCurve_00026: 'Ladedauer',
  contrastCurve_00027: "Vorgangsnummer",
  contrastCurve_00028: "Wechat Benutzer",
  contrastCurve_00029: "Start/Stopp Karte",
  contrastCurve_00030: "Gespeichertewert-Karte",
  contrastCurve_00031: "Ausweiskarte",
  contrastCurve_00032: "Xcharge-Konfigurationskarte",
  contrastCurve_00033: "Gruppe-Konfigurationskarte",
  contrastCurve_00034: "Offline-Datenlesekarte",
  contrastCurve_00035: "Daten-Backup",
  contrastCurve_00036: "Ladekurve",
  contrastCurve_00037: "angeforderte Spannung von EV(gestrichelte Linie)",
  contrastCurve_00038: "angeforderte Strom von EV(gestrichelte Linie)",
  contrastCurve_00039: "Bankkarte",
}