export default {
    analyzeXcharge_00001: "Utilization",
    analyzeXcharge_00002: "Utilization By Station",
    analyzeXcharge_00003: "Charger type utilization analysis",
    analyzeXcharge_00004: "Peak Hours",
    analyzeXcharge_00005: "Average power analysis",
    analyzeXcharge_00006: "Utilization By Franchisee",
    analyzeXcharge_00007: "Analysis",
    analyzeXcharge_00008: "Analysis by electricity",
    analyzeXcharge_00009: "Analysis by duration",
    analyzeXcharge_00010: "Utilization rate = daily charge volume / daily full load charge volume.<br/>Daily full load charge volume = rated power of the charger * 24 hours",
    analyzeXcharge_00011: "Utilization rate = daily charging hours at the charging connector / 24 hours",
    analyzeXcharge_00012: "AC equipment charging utilization (%)",
    analyzeXcharge_00013: "DC equipment charging utlization(%) ",
    analyzeXcharge_00014: "Select Franchisee",
    analyzeXcharge_00015: "Select charging site",
    analyzeXcharge_00016: "Select group",
    analyzeXcharge_00017: "Select charger model",
    analyzeXcharge_00018: "Station utilization comparison",
    analyzeXcharge_00019: "Station utilization trend",
    analyzeXcharge_00020: "Comparison of charger type utilization",
    analyzeXcharge_00021: "Charger type utilization trend",
    analyzeXcharge_00022: "Charging utilization",
    analyzeXcharge_00023: "Franchisee utilization comparison",
    analyzeXcharge_00024: "Franchisee utilization trend",
    analyzeXcharge_00025: "Site average power comparison",
    analyzeXcharge_00026: "Average power analysis of Charger models",
    analyzeXcharge_00027: "Average power",
    analyzeXcharge_00028: "Select or enter a station",
    analyzeXcharge_00029: "Connectors in charging",
    analyzeXcharge_00030: "Charger connector busy rate",
    analyzeXcharge_00031: "Number of connectors in charging",
    analyzeXcharge_00032: "Charger connector busy rate%",
    analyzeXcharge_00033: "Charger connector busy rate = Number of connectors currently charging / Total number of connectors",
}