export default {
  userOrderQuery_00001: "团体名称",
  userOrderQuery_00002: "充电订单查询",
  userOrderQuery_00003: "订单编号",
  userOrderQuery_00004: "重置",
  userOrderQuery_00005: "收入(元)",
  userOrderQuery_00006: "订单金额(元)",
  userOrderQuery_00007: "电费金额(元)",
  userOrderQuery_00008: "服务费金额(元)",
  userOrderQuery_00009: "延时费金额(元)",
  userOrderQuery_00010: "充电电量(度)",
  userOrderQuery_00011: "充电时间(分钟)",
  userOrderQuery_00012: "充电次数(次)",
  userOrderQuery_00013: "至",
  userOrderQuery_00014: "开始日期",
  userOrderQuery_00015: "结束日期",
  userOrderQuery_00016: "订单列表",
  userOrderQuery_00017: "导出订单",
  userOrderQuery_00018: "充电桩-充电口",
  userOrderQuery_00019: "充电时长",
  userOrderQuery_00020: "已删除",
  userOrderQuery_00021: "开始充电",
  userOrderQuery_00022: "结束充电",
  userOrderQuery_00023: "已充电量(度)",
  userOrderQuery_00024: "已创建",
  userOrderQuery_00025: "已取消",
  userOrderQuery_00026: "充电中",
  userOrderQuery_00027: "待支付",
  userOrderQuery_00028: "已支付",
  userOrderQuery_00029: "团体",
  userOrderQuery_00030: "微信用户",
  userOrderQuery_00031: "管理员",
  userOrderQuery_00032: "当前订单金额",
  userOrderQuery_00033: "实际支付金额",
  userOrderQuery_00034: "订单详情",
  userOrderQuery_00035: "此操作将下载账单的Excel文件, 是否继续?",
  userOrderQuery_00036: "提示",
  userOrderQuery_00037: "确定",
  userOrderQuery_00038: "取消",
  userOrderQuery_00039: "数据正在准备中，请耐心等待。当数据准备完后会自动关闭该弹窗并开始下载，在此期间请您不要关闭或刷新页面...",
  userOrderQuery_00040: "取消下载",
  userOrderQuery_00041: "天",
  userOrderQuery_00042: "小时",
  userOrderQuery_00043: "分钟",
  userOrderQuery_00044: "启停卡",
  userOrderQuery_00045: "储值卡",
  userOrderQuery_00046: "备份数据",
  userOrderQuery_00047: "身份卡",
  userOrderQuery_00048: "双枪订单",
  userOrderQuery_00049: "电量小于1度",
  userOrderQuery_00050: "充电时间超长",
  userOrderQuery_00051: "设备异常结束",
  userOrderQuery_00052: "车辆异常结束",
  userOrderQuery_00053: "",
  userOrderQuery_00054: "",
  userOrderQuery_00055: "",
  userOrderQuery_00056: "",
  userOrderQuery_00057: "",
  userOrderQuery_00058: "",
  userOrderQuery_00059: "",
  userOrderQuery_00060: "",
}