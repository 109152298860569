export default {
    pileDetail_00001: '推送配置',
    pileDetail_00002: '储充桩详情',
    pileDetail_00003: '设备信息',
    pileDetail_00004: '设备配置',
    pileDetail_00005: '充电口管理',
    pileDetail_00006: '设备保养',
    pileDetail_00007: '重启设备',
    pileDetail_00008: '设备重启指令已发送，重启过程大概需要5分钟。请停留在此页面，不要刷新页面。',
    pileDetail_00009: '确定',
    pileDetail_00010: '启动充电',
    pileDetail_00011: '设备重启成功',
    pileDetail_00012: '远程重启',
    pileDetail_00013: '设备重启失败',
    pileDetail_00014: '设备名称',
    pileDetail_00015: '所属充电站',
    pileDetail_00016: '查看',
    pileDetail_00017: '设备序列号',
    pileDetail_00018: '设备厂商',
    pileDetail_00019: '设备型号',
    pileDetail_00020: '请输入设备名称',
    pileDetail_00021: '配置修改保存后，需推送配置到设备，等待设配置修改保存后，需推送配置到设备，等待设备响应配置才可生效。',
    pileDetail_00022: '推送配置',
    pileDetail_00023: '推送到设备中',
    pileDetail_00024: '设备启用',
    pileDetail_00025: '电磁锁',
    pileDetail_00026: '电磁锁上锁失败可以充电',
    pileDetail_00027: '车位雷达',
    pileDetail_00028: '设备开放',
    pileDetail_00029: '开始时间',
    pileDetail_00030: '结束时间',
    pileDetail_00031: '最大充电电流(A)',
    pileDetail_00032: '最大充电功率(kW)',
    pileDetail_00033: '推送失败',
    pileDetail_00034: '设备离线',
    pileDetail_00035: '等待设备响应',
    pileDetail_00036: '推送成功',
    pileDetail_00037: '日期设置不正确',
    pileDetail_00038: '电源模块需保养计时提示：重置时间起累计充电时长',
    pileDetail_00039: '{hours}小时',
    pileDetail_00040: '重置时间：',
    pileDetail_00041: '正常',
    pileDetail_00042: '故障',
    pileDetail_00043: '您确定已对此充电桩电源模块完成此次保养？点击确定后，电源模块需要保养提示重新计时。',
    pileDetail_00044: '提示',
    pileDetail_00045: '充电口名称',
    pileDetail_00046: '充电二维码',
    pileDetail_00047: '动态二维码',
    pileDetail_00048: '固定二维码',
    pileDetail_00049: '升级为固定二维码',
    pileDetail_00050: '自定义二维码',
    pileDetail_00051: '远程控制',
    pileDetail_00052: '重启充电口',
    pileDetail_00053: '结束充电',
    pileDetail_00054: '置为空闲',
    pileDetail_00055: '充电口状态',
    pileDetail_00056: '车位管理设备',
    pileDetail_00057: '无',
    pileDetail_00058: '桩控车位锁',
    pileDetail_00059: '云控车位锁',
    pileDetail_00060: '车位管理设备信息',
    pileDetail_00061: '请输入设备编码',
    pileDetail_00062: '启用车位管理设备',
    pileDetail_00063: '远程控制车位管理设备',
    pileDetail_00064: '上升',
    pileDetail_00065: '下降',
    pileDetail_00066: '当前车位占用费计费用户',
    pileDetail_00067: '占用开始时间',
    pileDetail_00068: '用户详情',
    pileDetail_00069: '人工结束此次占用费计费',
    pileDetail_00070: '车位管理设备降下二维码',
    pileDetail_00071: '生成固定二维码',
    pileDetail_00072: '占用中',
    pileDetail_00073: '未启用',
    pileDetail_00074: '离线',
    pileDetail_00075: '故障',
    pileDetail_00076: '预约中',
    pileDetail_00077: '空闲',
    pileDetail_00078: '已插枪',
    pileDetail_00079: '充电中',
    pileDetail_00080: '待挪车',
    pileDetail_00081: '待拔枪',
    pileDetail_00082: '充电口',
    pileDetail_00083: '启动充电',
    pileDetail_00084: '请保存上一步操作！！',
    pileDetail_00085: '当前车位有正在车位占用费计费用户，请稍后修改!',
    pileDetail_00086: '参数为空',
    pileDetail_00087: '最长为20个字符',
    pileDetail_00088: '启动充电指令已发送',
    pileDetail_00089: '结束充电指令已发送',
    pileDetail_00090: '发送成功',
    pileDetail_00091: '升级为固定二维码',
    pileDetail_00092: '生成二维码成功',
    pileDetail_00093: '已启用',
    pileDetail_00094: '未启用',
    pileDetail_00095: '云控车位锁',
    pileDetail_00096: '桩控车位锁',
    pileDetail_00097: '请输入二维码内容，您配置的二维码会在桩上显示',
    pileDetail_00098: '注意: 只能生成固定二维码，且不可恢复成原始状态',
    pileDetail_00099: '自定义二维码不能为空！',
    pileDetail_00100: '自定义二维码成功',
    pileDetail_00101: '设置失败',
    pileDetail_00102: '长度不能超过{0}个字',
    pileDetail_00103: '当前收费时长（分钟）',
    pileDetail_00104: '当前预收占用费（{0}）',
    pileDetail_00105: '修改后占用费（{0}）',
    pileDetail_00106: '请输入金额',
    pileDetail_00107: '请输入修改后占用费',
    pileDetail_00108: '请输入正确的数字',
    pileDetail_00109: '人工结束此次占用费计费成功',
    pileDetail_00110: '固定二维码最多10万个，且不可回收，请谨慎操作，确定升级吗?',
    pileDetail_00111: '升级成功',
    pileDetail_00112: '完成',
    pileDetail_00113: '是否生成车位管理设备降下固定二维码？',
    pileDetail_00114: '暂无二维码',
    pileDetail_00115: '若设备离线，只支持白名单内的用户启动充电。',
    pileDetail_00116: '清除白名单',
    pileDetail_00117: '推送白名单',
    pileDetail_00118: '设备ID',
    pileDetail_00119: '操作系统版本',
    pileDetail_00120: 'Android应用版本',
    pileDetail_00121: 'DCB版本',
    pileDetail_00122: '网络',
    pileDetail_00123: '储充工作模式',
    pileDetail_00124: '储能电池柜名称',
    pileDetail_00125: '储能电池柜容量',
    pileDetail_00126: '储能电池循环次数',
    pileDetail_00127: '储能电池柜SOC',
    pileDetail_00128: '储能电池柜SOH',
    pileDetail_00129: '储能电池柜温度',
    pileDetail_00130: '储能电池柜状态',
    pileDetail_00131: '开启补电',
    pileDetail_00132: '停止补电',
    pileDetail_00133: '开启放电',
    pileDetail_00134: '停止放电',
    pileDetail_00135: '储充空调运行模式',
    pileDetail_00136: '经济模式',
    pileDetail_00137: '高效模式',
    pileDetail_00138: 'Max 功率模式',
    pileDetail_00139: 'Eco 经济模式',
    pileDetail_00140: 'Auto 自动模式',
    pileDetail_00141: '利用储能装置弥补充电功率缺口，快速充满车辆。',
    pileDetail_00142: '低电价时储能、高电价时服务，降低用电成本。',
    pileDetail_00143: '系统自动平衡功率和成本两方面的需求。',
    pileDetail_00144: '了解更多',
    pileDetail_00145:'储能管理',
    pileDetail_00146: '储能电池柜',
    pileDetail_00147: "储能补电中",
    pileDetail_00148: "储能放电中",
    pileDetail_00149: "空闲",
    







}