export default {
    work_00001: 'Initiate a Request',
    work_00002: "Please enter your nickname, mobile phone number, card number and multiple query criteria, which can be separated by comma (,), line feed, space and stop sign (,)",
    work_00003: 'Launch',
    work_00004: 'Issue Site',
    work_00005: 'Site Name',
    work_00006: 'Issue Source',
    work_00007: 'Employee Reported',
    work_00008: 'Customer Reported',
    work_00009: 'Equipment Reported',
    work_00010: 'Other',
    work_00011: 'Request Title',
    work_00012: 'Related Equipment',
    work_00013: 'Charger detail',
    work_00014: 'Charging order detail',
    work_00015: 'Priority',
    work_00016: 'Normal',
    work_00017: 'Urgent',
    work_00018: 'Very Urgent',
    work_00019: 'Issue Description',
    work_00020: 'Processor',
    work_00021: 'Follower',
    work_00022: 'Add User Relate to Request',
    work_00023: 'Clear',
    work_00024: 'Search Result',
    work_00025: 'Added',
    work_00026: 'Add',
    work_00027: 'Please choose the following', 
    work_00028: 'Please select the station that is having issue',
    work_00029: 'Please select a priority',
    work_00030: 'Please select who reported the issue',
    work_00031: 'Please enter the title of the ticket',  
    work_00032: 'Please enter a description of the issue',
    work_00033: 'Please select the respondent of the issue',
    work_00034: 'Charger Manufacturer',
    work_00035: 'Charging order detail',
    work_00036: 'Charger detail',
    work_00037: 'Stationsdetails',
    work_00045: 'Enter the device SN',
    work_00046: 'Enter the order number',
}
