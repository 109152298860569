export default {
  userInvoice_00001: "Gruppename",
  userInvoice_00002: "Abrechnungsdetails",
  userInvoice_00003: "Basisinformation",
  userInvoice_00004: "Antragsstatus",
  userInvoice_00005: "alle",
  userInvoice_00006: "zu behandeln",
  userInvoice_00007: "Abrechnung vorbereitet",
  userInvoice_00008: "abgebrochen",
  userInvoice_00009: "suchen",
  userInvoice_00010: "Papierrechnung",
  userInvoice_00011: "E-rechnung",
  userInvoice_00012: "Papierrechnung und E-rechnung",
  userInvoice_00013: "bis",
  userInvoice_00014: "Startdatum",
  userInvoice_00015: "Endedatum",
  userInvoice_00016: "abbrechen",
  userInvoice_00017: "Spitzname",
  userInvoice_00018: "Antragstypen",
  userInvoice_00019: "Methode der Rechnungsstellung",
  userInvoice_00020: "Antragsbetrag",
  userInvoice_00021: "Rechnungsdetails",
  userInvoice_00022: "Rechnungstitel",
  userInvoice_00023: "Steuernummer des Unternehmens",
  userInvoice_00024: "Firmenadresse",
  userInvoice_00025: "Telefonnummer des Unternehmens",
  userInvoice_00026: "Bank des Kontos",
  userInvoice_00027: "Kontonummer",
  userInvoice_00028: "Kontaktinformationen",
  userInvoice_00029: "Empfänger",
  userInvoice_00030: "Mobiltelefon-Nummer",
  userInvoice_00031: "Empfangsadresse",
  userInvoice_00032: "Persönliche Rechnung",
  userInvoice_00033: "Firmenrechnung",
  userInvoice_00034: "Vorgänge suchen",
  userInvoice_00035: "Email",
  userInvoice_00036: "",
  userInvoice_00037: "",
  userInvoice_00038: "",
  userInvoice_00039: "",
  userInvoice_00040: "",
  userInvoice_00041: "",
  userInvoice_00042: "",
  userInvoice_00043: "",
  userInvoice_00044: "",
  userInvoice_00045: "",
  userInvoice_00046: "",
  userInvoice_00047: "",
  userInvoice_00048: "",
  userInvoice_00049: "",
  userInvoice_00050: "",
}