export default {
    maintenanceHome_00001: "Liste der aktuellen fehler im system",
    maintenanceHome_00002: "Anzahl der offline laufwerke im moment",
    maintenanceHome_00003: "Nicht abgesperrt singular",
    maintenanceHome_00004: "Modul für wartung",
    maintenanceHome_00005: "Modul für wartung",
    maintenanceHome_00006: "Ich kontrolliere sie.",
    maintenanceHome_00007: "Name der party.",
    maintenanceHome_00008: "Geben sie mir die bilder!",
    maintenanceHome_00009: "Und heute ist es eine anomalie",
    maintenanceHome_000010: "Der bekommt heute einen schlechten ruf",
    maintenanceHome_000011: "Das ist eine anomalie im gehirn.",
    maintenanceHome_000012: "Eine online-analyse.",

    
}