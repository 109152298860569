export default {
    depotMap_00001: "快速查看位置",
    depotMap_00002: "当前充电用户",
    depotMap_00003: "总功率",
    depotMap_00004: "费用(€)",
    depotMap_00005: "电量(kWh)",
    depotMap_00006: "Show all",
    depotMap_00007: "总功率(kW)",
    depotMap_00008: "微信用户",
    depotMap_00009: "离线启停卡",
    depotMap_000010: "离线储值卡",
    depotMap_000011: "在线身份卡",
    depotMap_000012: "管理员",
    depotMap_000013: "第三方用户",
    depotMap_000014: "信用卡",
    depotMap_000015: "空闲充电口",
    depotMap_000016: "使用充电口",
    depotMap_000017: "异常充电口",

    
}