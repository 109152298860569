export default {
  add_site: "添加站点",
  site_name: "站点名称",
  operator: "运营商",
  enter_site: "输入站点名、运营商名",
  query: "查询",
  confirm: "确认",
  siteStatus: "站点状态",
  isOpen: "对外开放",
  enable: "启用",
  enabled: "已启用",
  disabled: "未启用",
  open: "开放",
  unopen: "不开放",
  device_type_dc: "直流",
  device_type_ac: "交流",
  chargeEquipment: "充电桩",
  member_00072: '取消',
  cancel: '取消',
  member_00073: '添加',
  ac: "交",
  dc: "直",
  memberDetail_00001: "特权会员信息",
  memberDetail_00002: "特权会员名称",
  memberDetail_00003: "请输入特权会员名称",
  memberDetail_00004: "费用折扣",
  memberDetail_00005: "电费和服务费打折（%）",
  memberDetail_00006: "说明：如设置电费和服务费8.5折，此次充电电费和服务费100元，则此次需实际支付金额85元（100*85%=85）。",
  memberDetail_00007: "服务费打折（%）",
  memberDetail_00008: "说明：如设置服务费8.5折，此次充电费用100元(含电费70元、服务费30元），则此次需实际支付金额95.5元（70+30*85%=95.5）。",
  memberDetail_00009: "85表示8.5折，0表示全免",
  memberDetail_00010: "特权会员权益说明",
  memberDetail_00011: "特权会员时长档位",
  memberDetail_00012: "至少添加1个档位,最多支持6个档位",
  memberDetail_00013: "添加档位",
  memberDetail_00014: "档位",
  memberDetail_00015: "特权会员时长",
  memberDetail_00016: "用户获取之日起",
  memberDetail_00017: "日",
  memberDetail_00018: "请输入自然数",
  memberDetail_00019: "月度",
  memberDetail_00020: "半年",
  memberDetail_00021: "年度",
  memberDetail_00022: "定价({currencyType})",
  memberDetail_00023: "可用场站",
  memberDetail_00024: "全部场站",
  memberDetail_00025: "自营场站",
  memberDetail_00026: "自定义场站",
  memberDetail_00027: "添加站点",
  memberDetail_00028: "批量移除",
  memberDetail_00029: "预览",
  memberDetail_00030: "新建特权会员",
  memberDetail_00031: "特权会员详情",
  memberDetail_00032: "修改特权会员",
  memberDetail_00033: "删除后不可恢复",
  memberDetail_00034: "请填写名称",
  memberDetail_00035: "请填写费用折扣",
  memberDetail_00036: "请正确填写费用折扣",
  memberDetail_00037: "请填写描述说明",
  memberDetail_00038: "至少添加1个档位",
  memberDetail_00039: "请正确设置会员时长",
  memberDetail_00040: "会员时长不能超过365日",
  memberDetail_00041: "请正确设置定价",
  memberDetail_00042: "请设置场站",
  memberDetail_00043: "请选择场站",
  memberDetail_00044: "最多支持6个档位",
  memberDetail_00045: "特权会员名称已存在",
  memberDetail_00046: "特权会员已无效",
  memberDetail_00047: "会员名称不能超过8个字",
  memberDetail_00048: "开启VIN限制设置后，第一次在特权会员可用的场站充电，将会记录特权会员车辆对应的VIN，一个特权会员只能对应一个车辆。",
  memberDetail_00049: "后续在本特权会员可用的场站充电，只有特权会员绑定VIN的车辆才能享受该特权会员优惠",
  memberDetail_00050: "• 建议开启该设置后，特权会员需要尽快在特权会员可用场站充一次电进行VIN的绑定。",
  memberDetail_00051: "• 启用本设置后，特权会员使用交流桩充电将无法获得会员优惠权益。",
  memberDetail_00052: "基本信息",
  memberDetail_00053: "开通统计",
  memberDetail_00054: "开通时间",
  memberDetail_00055: "销售总金额(元)",
  memberDetail_00056: "销售订单总数",
  memberDetail_00057: "购买用户数",
  memberDetail_00058: "兑换总人次",
  memberDetail_00059: "管理员开通数",
  memberDetail_00060: "开通列表",
  memberDetail_00061: "剩余总时长(日)",
  memberDetail_00062: "开通特权会员类型",
  memberDetail_00063: "日",
  memberDetail_00064: "剩余时间",
  memberDetail_00065: "开通来源",
  memberDetail_00066: "开通用户",
  memberDetail_00067: "使用状态",
  memberDetail_00068: "待生效",
  memberDetail_00069: "生效中",
  memberDetail_00070: "已失效",
  memberDetail_00071: "已用完",
  memberDetail_00072: "",
  memberDetail_00073: "",
}