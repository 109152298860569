<template>
  <el-dialog :title="$t('common_80010')" custom-class="TMap_dialog" :visible.sync="mapVisible" width="60%" :close-on-click-modal="false" :lock-scroll="false" :before-close="closeDialog">
    <div class="map_content_wrap">
      <el-input class="search_inp" :placeholder="$t('common_80011')" v-model="keyword" size="medium">
        <el-button type="primary" slot="append" icon="el-icon-search" size="medium" @click="search">{{$t('common_80012')}}</el-button>
      </el-input>
      <el-table class="table_box" tooltip-effect="dark" :data="tableData" max-height="400" highlight-current-row @row-click="get_poi" :header-cell-style="{
        background:'#EFF2F6',
        color:'#000',
        borderRadius: '4px 4px 0px 0px'}">
        <el-table-column width="32"></el-table-column>
        <el-table-column :label="$t('common_80013')">
          <template slot-scope="scope">
            <span>{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('common_80014')">
          <template slot-scope="scope">
            <span>{{scope.row.address}}</span>
          </template>
        </el-table-column>
      </el-table>
      <div id="map-container"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <div class="address_detail">
        <span>{{$t('common_80015')}}：</span>
        <span v-show="map_Adress.province!=map_Adress.city">{{map_Adress.province}}</span>
        <span v-show="map_Adress.city!=map_Adress.district">{{map_Adress.city}}</span>
        <span>{{map_Adress.district}}</span>
        <span>{{map_Adress.address}}</span>
      </div>
      <div class="btns">
        <el-button @click="closeDialog" size="medium">{{isChange?$t('common_00303'):$t('common_80016')}}</el-button>
        <el-button type="primary" v-show="!isChange" @click="pull_address" size="medium">{{$t('common_80017')}}</el-button>
      </div>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: ["mapaddress", "isChange", "mapVisible"],
  data() {
    return {
      mapInstance: null,
      markerInstance: null,
      geocoderInstance: null,
      searchService: null,
      map_Adress: {
        province: "",
        city: "",
        district: "",
        address: "",
        lat: "",
        lng: "",
        adcode: "",
      },
      tableData: [],
      keyword: "",
    };
  },
  watch: {
    mapVisible: {
      handler(val) {
        if (!val) return;
        this.$nextTick(() => {
          // 第一次打开弹窗  加载 初始化map
          !this.mapInstance &&
            this.initMap(
              new TMap.LatLng(this.mapaddress[1], this.mapaddress[0])
            );
        });
      },
      immediate: true,
    },
    mapaddress: {
      handler(val, oldVal) {
        console.log(val, "地图弹窗接收");
        if (!this.mapVisible || !this.mapInstance) return;
        this.updateMap(new TMap.LatLng(val[1], val[0]));
        this.mapInstance.setZoom(17);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    initMap(position) {
      //定义地图中心点坐标
      let center = position || new TMap.LatLng(39.98412, 116.307484);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      this.mapInstance = new TMap.Map("map-container", {
        center: center, //设置地图中心点坐标
        zoom: 17, //设置地图缩放级别
        showControl: false, // 是否显示地图上的控件
      });
      //初始化marker
      this.markerInstance = new TMap.MultiMarker({
        id: "marker-layer",
        map: this.mapInstance,
        styles: {
          marker: new TMap.MarkerStyle({
            width: 20,
            height: 30,
            src: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
          }),
        },
        //点标记数据数组
        geometries: [{ id: "markerId", styleId: "marker", position: center }],
      });
      // 初始化 地址解析 Geocoder
      this.geocoderInstance = new TMap.service.Geocoder();
      // 初始化 Poi检索类
      this.searchService = new TMap.service.Suggestion({ pageSize: 20 });
      //监听地图点击事件 更新 marker 位置
      this.mapInstance.on("click", (evt) => {
        this.updateMarker(evt.latLng);
      });
      // 第一次打开弹窗-解析地址
      this.updateMarker(center);
    },
    // 查看站点位置信息--更新地图中心点位置
    updateMap(position) {
      this.mapInstance.setCenter(position);
      this.updateMarker(position);
    },
    // 更新 marker 位置（新的经纬度坐标）
    updateMarker(position) {
      this.markerInstance.updateGeometries([
        { id: "markerId", styleId: "marker", position },
      ]);
      // 逆地址解析--将经纬度转为地名
      this.geocoderInstance
        .getAddress({ location: position })
        .then((result) => {
          this.formmatAddress(result.result);
        })
        .catch((err) => console.log("逆地址解析err:", err));
    },
    // 地址拼接
    formmatAddress(detail) {
      let temp = detail.address_component || {};
      this.map_Adress.province = temp.province || "";
      this.map_Adress.city = temp.city || temp.district || "";
      this.map_Adress.district = temp.district || temp.city || "";
      this.map_Adress.address = `${temp.street}${temp.street_number}`;
      this.map_Adress.lat = detail.location?.lat || 39.98412;
      this.map_Adress.lng = detail.location?.lng || 116.307484;
      this.map_Adress.adcode = detail.ad_info?.adcode || "";
    },
    // 搜索
    search() {
      // this.searchService.search(this.keyword);
      this.searchService
        .getSuggestions({
          keyword: this.keyword,
          location: this.mapInstance.getCenter(),
        })
        .then((result) => {
          this.tableData = result.data || [];
        })
        .catch((error) => {
          console.log("搜索error:", error);
          this.tableData = [];
        });
    },
    // 查询列表点击项
    get_poi(row) {
      this.updateMap(row.location);
    },
    // 结果的派发
    pull_address() {
      this.$emit("addressDispatch", this.map_Adress);
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("closeMapDialog", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.TMap_dialog {
  .map_content_wrap {
    position: relative;
    .search_inp {
      position: absolute;
      top: 20px;
      left: 10px;
      width: 340px;
      z-index: 2000;
    }
    .table_box {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2000;
      width: 360px;
    }
    #map-container {
      height: 400px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    .address_detail {
      font-size: 14px;
      color: #1c2237;
      text-align: left;
    }
  }
}
</style>
<style lang="scss">
.TMap_dialog {
  min-width: 800px;
  cursor: pointer;
}
</style>