<template lang="html">
  <div v-show="mapflag">
    <el-dialog :title="$t('detailDepot_00043')" width="60%" id="dialog" :before-close="dispatch" :visible.sync="dialog_flag" :lock-scroll="false" size="" :modal="false" :close-on-click-modal="false">
      <!-- <el-row class="search">
        <el-input :placeholder="$t('detailDepot_00036')" v-model="search" size="medium" id="searchInput">
          <el-button type="primary" slot="append" icon="el-icon-search" id="searchBtn" size="medium">{{$t('detailDepot_10023')}}</el-button>
        </el-input>
      </el-row>
      <div id="panel">
        <el-table
                :data="poiData"
                highlight-current-row
                @row-click="get_poi"
                style="width: 100%"
                :header-cell-style="{
                  background:'#EFF2F6',
                  color:'#000',
                  borderRadius: '4px 4px 0px 0px'}">
          <el-table-column width="32"></el-table-column>
          <el-table-column
                  :label="$t('detailDepot_10024')">
              <template slot-scope="scope">
                  <span>{{scope.row.description}}</span>
              </template>
          </el-table-column>
        </el-table>
      </div> -->
      <div id="container">


      </div>
      <div slot="footer" class="dialog-footer">
        <div class="footer-piece-left">
          <div class="layout">{{$t('detailDepot_00040')}}：
            <span>{{map_Adress.address}}</span>
          </div>
        </div>
        <div class="footer-piece-right">
          <el-button @click="dispatch" size="medium">{{isChange?$t('common_00303'):$t('detailDepot_00041')}}</el-button>
          <el-button type="primary" class="info-btn" v-show="!isChange" @click="pull_address" size="medium">{{$t('detailDepot_00042')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    data() {
      return {
        map_Adress: '',
        map_LnglatXY: '',
        poi_lnglat: '',
        dialog_flag: true,
        search: '',
        markersArray:[],
        poiData: []
      }
    },
    props: ['mapflag','isChange','currentPosition'],
    methods: {
      placeMarker(location) {
        this.clearAllMarkers();
        var marker = new google.maps.Marker({
          position: location,
          map: this.map
        });
        var latLngBounds = new google.maps.LatLngBounds();
        latLngBounds.extend(marker.position);
        this.markersArray.push(marker);
        this.map.fitBounds(latLngBounds);
      },
      // 查询列表点击项
      get_poi(row) {
        this.getPositionById(row.place_id);
      },
      getPositionById(placeId) {
        var om = this;
        var request = {
          placeId:placeId,
          fields:['address_components','adr_address','formatted_address','geometry']
        };
        var placesService = new google.maps.places.PlacesService(this.map);
        placesService.getDetails(request, function (result, status) {
          if(status == 'OK'){
            if(result){
              var location = result.geometry.location;


              om.map_LnglatXY = {
                lng: location.lng(),
                lat: location.lat()
              };
              var address_components = result.address_components;
              var country="";
              for(var i = 0; i < address_components.length; i++){
                var types = address_components[i].types;
                if(om.isInArray(types, 'country')){
                  country = address_components[i].long_name;
                  break;
                }

              }
              var city="";
              for(var i = 0; i < address_components.length; i++){
                var types = address_components[i].types;
                if(om.isInArray(types, 'locality')){
                  city = address_components[i].long_name;
                  break;
                }
                if(om.isInArray(types, 'administrative_area_level_1')){
                  city = address_components[i].long_name;
                  break;
                }
              }
              if(!om.map_Adress){
                om.map_Adress = {
                  address:result.formatted_address,
                  country:country,
                  city:city,
                }
              }else{
                om.map_Adress.address = result.formatted_address;
                om.map_Adress.country = country;
                om.map_Adress.city = city;
              }



              var info = om.getTimeZone(location);
              if(info){
                var useDst = false;
                if(info.dstOffset && info.dstOffset != 0){
                  useDst = true;
                }
                om.map_Adress.useDST = useDst;
                var hour = info.rawOffset / 3600;
                var timezoneDisp = "";
                if(hour < 0){
                  hour = Math.abs(hour);
                  timezoneDisp = "-"+ (hour < 10 ? "0"+hour : hour) +":00";
                }else{
                  timezoneDisp = "+"+ (hour < 10 ? "0"+hour : hour) +":00";
                }
                om.map_Adress.timezoneDisp = timezoneDisp;
              } else {
                console.log('No results found');
              }
              om.placeMarker(location);//创建标记

              //
            }
          }

        });
      },
      isInArray(arr,value){
        for(var i = 0; i < arr.length; i++){
          if(value === arr[i]){
            return true;
          }
        }
        return false;
      },
      clearAllMarkers(){
        if(this.markersArray){
          for(var i = 0; i < this.markersArray.length; i++){
            this.markersArray[i].setMap(null);
          }
        }
      },
      getTimeZone(latLng){
        var url = "https://maps.googleapis.com/maps/api/timezone/json?key=AIzaSyAyfVnxQL9gRDT1v-Ii99ANum4J6rr2sXE&language=eu&location="+latLng.lat()+","+latLng.lng();
        var timestemp = new Date().getTime()/1000;
        url = url+"&timestamp="+timestemp;
        var result="";
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function() {//服务器返回值的处理函数，此处使用匿名函数进行实现
          if (xhr.readyState == 4 && xhr.status == 200) {//
            var responseText = xhr.responseText;
            var info = JSON.parse(responseText);
            if(info.status && info.status == 'OK'){
              if(info){
                result = info;
              }
            }
          }
        };
        xhr.open("GET", url, false);//提交get请求到服务器
        xhr.send(null);
        return result;
      },

      dispatch: function() {
        this.dialog_flag = false;
        this.$emit('mapFlagDispatch', false);
        const map_fix_bg = document.getElementById('map_fix_bg');
        document.body.removeChild(map_fix_bg);
      },
      // 结果的派发
      pull_address() {
        console.log(this.map_Adress);
        const get_address = Object.assign(this.map_Adress, this.map_LnglatXY);
        this.$emit('addressDispatch', get_address);
        this.dispatch();
      }
    },
    watch:{
      currentPosition(newVal){
        let val = JSON.parse(JSON.stringify(newVal));
        if(this.map){
          this.map.setCenter(new google.maps.LatLng(val[1],val[0]));
          this.placeMarker(new google.maps.LatLng(val[1],val[0]));
          this.map_Adress= {
            address:val[2]
          };
        }
      },
      mapflag(val){
        if(val){
          this.dialog_flag = true;
        }
      }
    },
    mounted: function() {
      this.$nextTick(function() {

        var that = this;
        var geocoder = new google.maps.Geocoder;
        // 绘制地图函数
        //以客户端所在位置作为google地图的默认中心点
        var center = new google.maps.LatLng(39.9732861,116.487692);//默认北京
        if(navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
                  function (position) {
                    var longitude = position.coords.longitude;
                    var latitude = position.coords.latitude;
                    center = new google.maps.LatLng(latitude,longitude);
                  },
                  function (e) {
                    var msg = e.code;
                    var dd = e.message;
                  }
          )
        }
        var mapProp = {
          center:center,
          zoom:10,
          zoomControl:false,
          mapTypeControl:false,
          fullscreenControl:false,
          disableDoubleClickZoom:false,
          panControl:false,
          rotateControl:false,
          mapTypeId:google.maps.MapTypeId.ROADMAP,
          gestureHandling: 'greedy'
        };
        this.map=new google.maps.Map(document.getElementById("container"), mapProp);
        google.maps.event.addListener(this.map, 'click', function(event) {
          that.placeMarker(event.latLng);//创建标记
          getPositonName(event.latLng);//获取标记名称
          that.map_LnglatXY = {
            lng: event.latLng.lng(),
            lat: event.latLng.lat()
          };

        });

        // 经纬度转具体地址
        function getPositonName(latLng) {
          var request={
            'location': latLng,
          }

          geocoder.geocode(request, function(results, status) {
            if (status === 'OK') {
              if (results[0]) {
                var address_components = results[0].address_components;
                console.log(results[0]);
                var country="";
                for(var i = 0; i < address_components.length; i++){
                  var types = address_components[i].types;
                  if(that.isInArray(types, 'country')){
                    country = address_components[i].long_name;
                    break;
                  }

                }
                var city="";
                for(var i = 0; i < address_components.length; i++){
                  var types = address_components[i].types;
                  if(that.isInArray(types, 'locality')){
                    city = address_components[i].long_name;
                    break;
                  }
                  if(that.isInArray(types, 'administrative_area_level_1')){
                    city = address_components[i].long_name;
                    break;
                  }
                }
                if(!that.map_Adress){
                  that.map_Adress = {
                    address:results[0].formatted_address,
                    country:country,
                    city:city,
                  }
                }else{
                  that.map_Adress.address = results[0].formatted_address;
                  that.map_Adress.country = country;
                  that.map_Adress.city = city;
                }
                var info = that.getTimeZone(latLng);
                if(info){
                  var useDst = false;
                  if(info.dstOffset && info.dstOffset != 0){
                    useDst = true;
                  }
                  that.map_Adress.useDST = useDst;
                  if(info.rawOffset){
                    var offsetHour = parseInt(info.rawOffset / 3600);
                    var offsetMinute = Math.abs(info.rawOffset / 3600 / 600);
                    var timezoneDisp = "";
                    if(offsetHour < 0){
                      offsetHour = Math.abs(offsetHour);
                      timezoneDisp = "-"+ (offsetHour < 10 ? "0"+offsetHour : offsetHour) +":" + (offsetMinute < 10 ? "0" + offsetMinute : offsetMinute);
                    }else{
                      timezoneDisp = "+"+ (offsetHour < 10 ? "0"+offsetHour : offsetHour) +":" + (offsetMinute < 10 ? "0" + offsetMinute : offsetMinute);
                    }
                    that.map_Adress.timezoneDisp = timezoneDisp;
                  }
                } else {
                  console.log('No results found');
                }
              }

            } else {
              that.$message.error(that.$t('detailDepot_10021'));
            }
          });
          console.log(that.map_Adress);
        }
        var autocompleteService = new google.maps.places.AutocompleteService();
        function autoCallback(results,status) {
          if ("OK" == status) {
            that.poiData = results;
          }
        }
        // document.getElementById('searchBtn').onclick = function() {
        //   if(that.search){
        //     var request = {
        //       input:that.search
        //     };
        //     autocompleteService.getQueryPredictions(request,autoCallback);
        //   }
        // }
      })
    }
  }
</script>

<style lang="scss" scoped>
#container {
    height: 390px;
    position: relative;
}
.search {
    width: 300px;
    height: 40px;
    position: absolute;
    left: 30px;
    top: 100px;
    z-index: 2;
}
#panel {
  width: 260px;
  height: inherit;
  overflow: auto;
  position: absolute;
  right: 20px;
  top: 100px;
  z-index: 2;
}
.dialog-footer {
  display: flex;
  .footer-piece-left{
    text-align: left;
    flex: 1;
    .layout {
      margin: 10px 0;
      font-size: 14px;
      color: #1C2237;
    }
  }
  .footer-piece-right {
    width: 300px;
  }
}
/deep/ .el-dialog {
  border-radius: 6px;
  line-height: 16px;
}
/deep/ .el-dialog__header{
  font-size: 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: #EFF2F6;
}
/deep/ .el-dialog__title{
  color: #8087A0;
}
</style>
