export default {
    minProgramBanner_00001: "首页Banner",
    minProgramBanner_00002: "首页Banner启用",
    minProgramBanner_00003: "预览",
    minProgramBanner_00004: "banner图片",
    minProgramBanner_00005: "跳转页面",
    minProgramBanner_00006: "展示顺序",
    minProgramBanner_00007: "选择默认图片",
    minProgramBanner_00008: "放大",
    minProgramBanner_00009: "修改",
    minProgramBanner_000010: "点击上传图片",
    minProgramBanner_000011: "设置帮助",
    minProgramBanner_000012: "1.Banner可最多添加8页，每张Banner图片展示时长为3秒，按序号顺序轮播展示；",
    minProgramBanner_000013: "2.如您设置跳转链接，点击后可跳转您所置的页面; 如您未设置跳转链接，则只展示图片",
    minProgramBanner_000014: "3.上传图片仅支持PNG、JPG格式图片，大小不超过5M。建议尺寸690*260px（8: 3），如您上传的图片尺寸或比例不符，则会拉伸展示",
    minProgramBanner_000015: "选择活动类型",
    minProgramBanner_000016: "请粘贴您的公众号文章链接，微信公众平台：",
    minProgramBanner_000017: "最多可设置8个Banner",
    minProgramBanner_000018: "请设置banner图片",
    minProgramBanner_000019: "请设置活动ID ",
    minProgramBanner_000020: "上传",
    minProgramBanner_000021: "无跳转",
    minProgramBanner_000022: "活动页面",
    minProgramBanner_000023: "公众号链接",
    minProgramBanner_000024: "储值送返",
    minProgramBanner_000025: "自由领取",
    minProgramBanner_000026: "邀请好友",
    minProgramBanner_000027: "购买卡券",
    minProgramBanner_000028: "请设置跳转页面",
    minProgramBanner_000029: "活动名称",
    minProgramBanner_000030: "选择活动",
    minProgramBanner_000031: "新增Banner",
    minProgramBanner_000032: "广告投放",
    minProgramBanner_000033: "请输入备注信息",
    minProgramBanner_000034: "备注信息",
    minProgramBanner_000035: "最多设置一个广告位",
    minProgramBanner_000036: "",






    
}