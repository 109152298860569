export default {
    eur: "EUR",
    eur_name: "EUR",
    gbp: "GBP",
    gbp_name: "British Pound",
    sek: "SEK",
    sek_name: "Swedish Krona",
    hcf: "HCF",
    hcf_name: "Swiss Franc",
    cny: "CNY",
    cny_name: "CNY",
    usd: "USD",
    usd_name: "Dollar",
    rub: "RUB",
    rub_name: "Russian ruble",
    ils: "ILS",
    ils_name: "Israeli shekel",

    common_00001: "Log out",
    common_00002: "YYYY/MM/DD",
    common_00003: "YYYY/MM/DD HH:mm:ss",
    common_00004: "Stations",
    common_00005: "Orders",
    common_00006: "Maintenance",
    common_00007: "Security",
    common_00008: "Users",
    common_00009: "Marketing",
    common_00010: "Data",
    common_00011: "Finance",
    common_00012: "Diversion",
    common_00013: "System",
    common_00014: "Monitoring overview",
    common_00015: "Dashboard",
    common_00016: "overview",
    common_00017: "Real time monitoring",
    common_00018: "Annual statistics",
    common_00019: "Maintenance index",
    common_00020: "Abnormal orders",
    common_00021: "Charging site management",
    common_00022: "Create charging site",
    common_00023: "Tariff management",
    common_00024: "Tariff Template",
    common_00025: "Management of charging payment method",
    common_00026: "Pre-paid",
    common_00027: "Post-paid",
    common_00028: "Chargers management",
    common_00029: "Management of charging payment methods for idle fees",
    common_00030: "Cloud-controlled parking lock",
    common_00031: "Stake-controlled parking  lock",
    common_00032: "Advertising",
    common_00033: "Charger advertising",
    common_00034: "Public advertising",
    common_00035: "Client site display tag",
    common_00036: "System-level marketing tag",
    common_00037: "Site-level operation tag",
    common_00038: "Energy consumption management",
    common_00039: "Energy consumption Statistics",
    common_00040: "Station Energy Management",
    common_00041: "Government platform docking",
    common_00042: "Franchise Management",
    common_00043: "Invite franchise",
    common_00044: "Charging order",
    common_00045: "Individual order",
    common_00046: "Group order",
    common_00047: "Third-party orders",
    common_00048: "Abnormal orders",
    common_00049: "All orders",
    common_00050: "Orders",
    common_00051: "Occupancy invoice",
    common_00052: "Operation monitoring",
    common_00053: "Real time monitoring",
    common_00054: "Abnormal orders",
    common_00055: "Maintenance index",
    common_00056: "Query Log",
    common_00057: "Charger abnormal Log",
    common_00058: "EV Charging Log",
    common_00059: "Platform business Log",
    common_00060: "Troubleshooting",
    common_00061: "Current faults",
    common_00062: "Power module maintenance",
    common_00063: "Charging safety Policy",
    common_00064: "Station EV Charging Restrictions",
    common_00065: "High Risk EV Management",
    common_00066: "Active real-time protection",
    common_00067: "blacklist",
    common_00068: "Users",
    common_00069: "User management",
    common_00070: "User classification",
    common_00071: "Category Management",
    common_00072: "Group",
    common_00073: "Group payment",
    common_00074: "Individual payment",
    common_00075: "All group management",
    common_00076: "Recharge cards",
    common_00077: "Card management",
    common_00078: "PnC card",
    common_00079: "Online indentity card",
    common_00080: "Offline Start-stop card",
    common_00081: "Offline Stored-value card",
    common_00082: "EV car",
    common_00083: "EV car Management",
    common_00084: "Wechat",
    common_00085: "Customer service",
    common_00086: "Member",
    common_00087: "Member Management",
    common_00088: "Deals&Coupons",
    common_00089: "Vouchers",
    common_00090: "Discounts",
    common_00091: "Credits",
    common_00092: "Electricity Tickets",
    common_00093: "Service fee voucher",
    common_00094: "Marketing Campaigns",
    common_00095: "Top-up rebate",
    common_00096: "Tickets For free",
    common_00097: "Sign up & refund activity",
    common_00098: "Charging refund",
    common_00099: "Total fefund Campaign",
    common_00100: "Accumulate charging activity",
    common_00101: "Gaining customers",
    common_00102: "Inviting friends",
    common_00103: "Deals&Coupons sale",
    common_00104: "New Deals&Coupons",
    common_00105: "Orders",
    common_00106: "Sales Consumption Statistics",
    common_00107: "Deals&Coupons distribution",
    common_00108: "Redeem code",
    common_00109: "Records",
    common_00110: "Consumption statistics of redeemed offers",
    common_00111: "Efficiency analysis",
    common_00112: "Overall-level analysis",
    common_00113: "Site-level analysis",
    common_00114: "Charger-level analysis",
    common_00115: "Peak Moment Analysis",
    common_00116: "Average power analysis",
    common_00117: "Franchisee Level Analysis",
    common_00118: "Charge volume analysis",
    common_00119: "Overall-level analysis",
    common_00120: "Site-level analysis",
    common_00121: "Group-level analysis",
    common_00122: "User source level analysis",
    common_00123: "Franchisee Level Analysis",
    common_00124: "Income Analysis",
    common_00125: "Overall-level analysis",
    common_00126: "Site-level analysis",
    common_00127: "Group-level analysis",
    common_00128: "Gross interest analysis",
    common_00129: "User source level analysis",
    common_00130: "Franchisee Level Analysis",
    common_00131: "Charging price amount Analysis",
    common_00132: "User Analysis",
    common_00133: "Overall-level analysis",
    common_00134: "Site-level analysis",
    common_00135: "User source level analysis",
    common_00136: "User contribution analysis",
    common_00137: "User RFM modeling",
    common_00138: "Franchisee Level Analysis",
    common_00139: "Card&coupon Analysis",
    common_00140: "Sales trend Analysis",
    common_00141: "Sales distribution Analysis",
    common_00142: "Top-up analysis",
    common_00143: "Top-up amount analysis",
    common_00144: "Analysis of top-up number",
    common_00145: "Refund analysis",
    common_00146: "Refund amount analysis",
    common_00147: "Refund number analysis",
    common_00148: "Wallet analysis",
    common_00149: "Wallet balance trend analysis",
    common_00150: "Analysis of wallet balance consumption patterns",
    common_00151: "Common Functions",
    common_00152: "Individual Invoice",
    common_00153: "Group Invoice",
    common_00154: "Wallet refund acceptance",
    common_00155: "Order Inquiry",
    common_00156: "Financial Statistics",
    common_00157: "Recharge amount",
    common_00158: "Refund amount",
    common_00159: "User Wallet Balance",
    common_00160: "Amount of card coupon sales",
    common_00161: "Financial Settings",
    common_00162: "User top-up settings",
    common_00163: "User refund settings",
    common_00164: "User invoicing settings",
    common_00165: "Third Party Reserve Management",
    common_00166: "Group self-service end invoicing settings",
    common_00167: "Group self-service Top-Up Settings",
    common_00168: "Self-service payment",
    common_00169: "Platform Billing",
    common_00170: "Account Management",
    common_00171: "Request an invoice",
    common_00172: "Invoice details",
    common_00173: "Platform invoicing",
    common_00174: "Open Leads",
    common_00175: "Map Platform",
    common_00176: "Operating Vehicle Platform",
    common_00177: "Integration Platform",
    common_00178: "Government Platform",
    common_00179: "Data flow analysis",
    common_00180: "Third-party data flow charging",
    common_00181: "Operation Log",
    common_00182: "Account Management",
    common_00183: "Limit of authority Management",
    common_00184: "Other settings",
    common_00185: "API interface view",
    common_00186: "Operator",
    common_00187: "Information Setting",
    common_00188: "Accounts & Permissions",
    common_00189: "Client Setting",
    common_00190: "Wechat",
    common_00191: "Alipay",
    common_00192: "Message template",
    common_00193: "Add vehicle",
    common_00194: "Edit vehicle information",
    common_00195: "Vehicle license no",
    common_00196: "Vehicle VIN (order read)",
    common_00197: "telephone",
    common_00198: "Vehicle model|(optional)",
    common_00199: "Location|(optional)",
    common_00200: "Province/state, city and district",
    common_00201: "Modified successfully",
    common_00202: "Information",
    common_00203: "Charger",
    common_00204: "Start time",
    common_00205: "End time",
    common_00206: "Elec bill",
    common_00207: "Service fee",
    common_00208: "Idle fee",
    common_00209: "KWh",
    common_00210: "Minute",
    common_00211: "App user",
    common_00212: "Add",
    common_00213: "Modify",
    common_00214: "Delete",
    common_00215: "Cancel",
    common_00216: "Deleted successful",
    common_00217: "Save",
    common_00218: "Charging station",
    common_00219: "Charging port",
    common_00220: "Create Order",
    common_00221: "Charging started",
    common_00222: "Charging ended",
    common_00223: "Charge duration",
    common_00224: "Charged amount",
    common_00225: "Promotion",
    common_00226: "Private card",
    common_00227: "Deposit card",
    common_00228: "Online card",
    common_00229: "Details",
    common_00230: "Group User",
    common_00231: "third-party user",
    common_00232: "Coupon sales order",
    common_00233: "Notify",
    common_00234: "Confirm",
    common_00235: "All station",
    common_00236: "Self-owned station",
    common_00237: "Franchisee",
    common_00238: "Please enter site name",
    common_00239: "search",
    common_00240: "Clear",
    common_00241: "Added successfully",
    common_00242: "Hour",
    common_00243: "Administrator",
    common_00244: "No",
    common_00245: "All station",
    common_00246: "Change password",
    common_00247: "Current login password",
    common_00248: "New login password",
    common_00249: "Please use letters and numbers",
    common_00250: "Current account login password",
    common_00251: "Please enter current password",
    common_00252: "Please enter password",
    common_00253: "Please enter password again",
    common_00254: "The confirmation password is not identical",
    common_00255: "Search",
    common_00256: "M",
    common_00257: "F",
    common_00258: "Profile",
    common_00259: "Name",
    common_00260: "Gender",
    common_00261: "Wallet balance",
    common_00262: "User details",
    common_00263: "View charging order",
    common_00264: "WeChat Official Account",
    common_00265: "Reminder",
    common_00266: "Cancel",
    common_00267: "Start to download",
    common_00268: "Enter again",
    common_00269: "User EV certification",
    common_00270: "User blacklist",
    common_00271: "reset",
    common_00272: "Finish",
    common_00273: "Yes",
    common_00274: "No",
    common_00275: "Parameter is empty",
    common_00276: "Please enter a positive integer less than 100.",
    common_00277: "Operator tariff",
    common_00278: "Top-up",
    common_00279: "Unit",
    common_00280: "required",
    common_00281: "Setup succeeded",
    common_00282: "Operate",
    common_00283: "Vehicle make",
    common_00284: "Vehicle model",
    common_00285: "Input manually",
    common_00286: "Vehicle model|make",
    common_00287: "Please save the previous operation first",
    common_00288: "Cancel operation",
    common_00289: "DC",
    common_00290: "AC",
    common_00291: "Gateway",
    common_00292: "Disabled",
    common_00293: "Enabled",
    common_00294: "Setting",
    common_00295: "Push feedback",
    common_00296: "{allDevice} equipments totally, {sucDevice} succeeded, {faiDevice} failed.",
    common_00297: "Equipment number",
    common_00298: "Equipment name",
    common_00299: "Success",
    common_00300: "Fail",
    common_00301: "Waiting for response",
    common_00302: "Offline",
    common_00303: "Close",
    common_00304: "Current charging site",
    common_00305: "Push tariff",
    common_00306: "Ad Management",
    common_00307: "Equipment maintenance",
    common_00308: "Third-party functions",
    common_00309: "Remove",
    common_00310: "Select",
    common_00311: "Back",
    common_00312: "Start date",
    common_00313: "End date",
    common_00314: "Select site",
    common_00315: "Select franchisees",
    common_00316: "Select equipment model",
    common_00317: "Select group",
    common_00318: "OEM platform",
    common_00319: "Start",
    common_00320: "Charging Operation Platform",
    common_00321: "Full Screen",
    common_00322: "Zoom out",
    common_00323: "Export data",
    common_00324: "Map charging site",
    common_00325: "EV MAC",
    common_00326: "Optional",
    common_00327: "Select",
    common_00360: "Basic information",
    common_00361: "Offer site",
    common_00362: "Team member management",
    common_00363: "Order query",
    common_00364: "Charge statistics",
    common_00365: "Edit",
    common_10000: 'The activity type',
    common_10001: 'Number of activities',
    common_10002: 'Create activities',
    common_10003: '维保首页',
    common_10004: '维保中心',
    common_10005: '场站维保分析',
    common_10006: "Maintenance page",
    common_10007: "custom",
    common_10008: "Financial homepage",
    common_10009: "Are you sure to access the old version V2 operating platform?<br/>Since February 28, 2022, the boss V2 operating platform will not be maintained, please be informed!",
    common_20008: '请输入站点名称',
    common_20009: "活动总览",
    //能源中心
    common_20010: 'Operation monitoring',
    common_20011: 'Yield monitoring',
    common_20012: 'Net Zero pile management',
    common_20013: 'Energy statistics',
    common_20014: 'Grid Tariff',
    common_20015: 'statistical',
    common_20016: 'Energy',
    common_20017: 'Max power mode',
    common_20018: 'Eco economic mode',
    common_20019: 'Auto mode',
    common_20020: 'Idle',
    common_20021: 'Battery charging',
    common_20022: 'Battery discharging',
    common_20023: 'minute full',
    common_20024: 'Current working mode',
    common_20025: 'Mode',
    common_20026: 'NZS',
    common_20027: 'Battery idle',
    common_20028: 'Battery failure',
    common_20029: 'Eco mode operates according to your grid tariff during valley hours and peak hours. But you need to choose whether the normal period cost is closer to the valley period cost or the peak period cost. ',
    common_20030: 'closer to the valley period',
    common_20031: 'closer to peak hours',
    common_20032: 'It is found that you have not set the grid tariff for this station',
    common_20033: 'Select grid tariff',
    common_20034: 'New grid tariff',
    common_20035: 'The grid tariff must be set with three periods of peak, flat and valley or four label periods of peak, peak, flat and valley. ',
    common_20036: 'Please select which time period is closer to the normal time cost',
    common_20037: 'The grid tariff must be set to three periods of peak, flat and valley or four label periods of peak, peak, flat and valley',
    common_20038: 'Economic mode',
    common_20039: 'Efficient mode',
    common_20040: 'Storage and charge air conditioner operation mode',
    common_20041: 'Storage and charge working mode',
    common_20042: "The power demand of the vehicle is the first priority, and it is replenished as needed. The DCDC module is used first to charge the vehicle.",
    common_20043: "To make up for the insufficient output power of the station, use energy storage to provide power that is in short supply.",
    common_20044: "The lowest CPO cost can be achieved based on the peak and off-peak status of electricity prices issued by the platform.",
    common_20045: "Peak shaving and valley filling are used to recharge the energy storage when electricity prices are off-peak, and energy storage is used to charge the car during peak electricity prices.",
    common_20046: "Automatically control charging and discharging according to the electricity bill policy issued by the platform.",
    common_20047: "Automatically adjust the power demand and electricity price cost to meet the vehicle charging power while still having enough energy for energy storage and replenishment.",
    common_20048: "Strategy Introduction",
    common_20049: "Application Scenario",
    common_20050: "Please enter the card number",
    common_20051: "Please enter the source",
    common_20057: "Working Mode Introduction",

    common_10010: "v2 entry",
    // 工单
    common_31000: "Work Order System",
    common_31001: "Work Order Management",
    common_31002: "Work order classification",
    common_31003: "Work Order Statistics",
    common_31004: "Knowledge Base",
    common_31005: "Please bind the official account first",
    common_31006: "Initiate work order",
    common_31007: "Automatic notification",
    common_40000: 'Equipment life maintenance',
    common_41000: 'Please fill in correctly',
    common_41001: 'Automatic ticket trigger',
    common_11006: "No Data",
    common_45009: "Open the service",
    common_45010: "Privacy Policy",
    // 卡券
    common_00366: "卡券消耗分析",
    common_45011: "Users of affiliated carriers do not have permission to view the information",
    common_61134: "Immediate push",
    common_61147: "select all",
    common_61148: "Push all AC piles",
    common_61149: "Push all DC piles",
    common_61150: "Confirm whether to bind the charge to all AC posts",
    common_61151: "Whether to bind the change charge to all DC posts",
    common_61152: "All binding successful",
    common_61153: "Some sites failed to bind. Please try again",
    common_61154: "Do not close popover while binding",
    common_61145: "Parameter error",
    common_63013: "Add charger",
}
