export default {
    vehicleChargingLogDetail_00001: "Log details",
    vehicleChargingLogDetail_00002: "Ladekurve",
    vehicleChargingLogDetail_00003: "",
    vehicleChargingLogDetail_00004: "Kennzeichen",
    vehicleChargingLogDetail_00005: "Fahrzeugtyp",
    vehicleChargingLogDetail_00006: "vehicle {tag}",
    vehicleChargingLogDetail_00007: "Eigentümer",
    vehicleChargingLogDetail_00008: "Handynummer",
    vehicleChargingLogDetail_00009: "Startzeit",
    vehicleChargingLogDetail_00010: "Endzeit",
    vehicleChargingLogDetail_00011: "",
    vehicleChargingLogDetail_00012: "",
    vehicleChargingLogDetail_00013: "",
    vehicleChargingLogDetail_00014: "",
    vehicleChargingLogDetail_00015: "",
    billDetail_00025: "Aufladekurven",
    billDetail_00026: "Fz.-Bedarfsspannung",
    billDetail_00027: "Fz.-Bedarfsstrom",
    billDetail_00028: "Fz.-Messspannung",
    billDetail_00029: "Fz.-Messstrom",
    billDetail_00030: "Erste Batterieladung",
    billDetail_00031: "Aktuelle Batterieladung",
    billDetail_00032: "Momentane Leistung",
    billDetail_00033: "Durchschnittliche Leistung",
    billDetail_00034: "Maximale Leistung",
    billDetail_00035: "Modell",
    billDetail_00036: "Leistung",
    billDetail_00037: "Strom",
    billDetail_00038: "Spannung",
    billDetail_00039: "Batteriestand",
    billDetail_00040: "Maximaler Leistungsbereich, Leistungsbereich {powerBegin}-{powerEnd}Kw, insgesamt {minutes} Minuten (von insgesamt {timePercent}), insgesamt {powerCharged} kWh, von insgesamt {chargePercent}",
    billDetail_00041: "Kumulierte Elektrizität",
    billDetail_00042: "kWh",
    billDetail_00043: "Ladevorgang erstellen",
    billDetail_00044: "Ladenstart von Ladesäule",
    billDetail_00045: "Abrechnung beginnen",
    billDetail_00046: "Ladesäule hört auf zu laden",
    billDetail_00047: "Ladesäule beendet den Ladevorgang",
    billDetail_00048: "Beginn der Ladeverzögerungsbetrag",
    billDetail_00049: "Laden abgeschlossen",
    billDetail_00050: "Ende der Abrechnung",
    billDetail_00051: "Zahlung abgeschlossen",
    billDetail_00091: "Maximaler Leistungsbereich, Leistungsbereich {powerBegin}-{powerEnd}Kw, gesamt {minutes} Minuten (insgesamt {timePercent}), gesamt {powerCharged} kWh, von insgesamt Leistung {chargePercent}",
}