export default {
    pia_00001:'Financial Center',
    pia_00002:'Common functions',
    pia_00003:'The requested invoices are ordinary VAT invoices, excluding gift amount, preferential amount and group allocation amount.',
    pia_00004:'Application status',
    pia_00005:'All',
    pia_00006:'Pending',
    pia_00007:'Invoiced',
    pia_00008:'Cancelled',
    pia_00009:'Wechat users:',
    pia_000010:'Search',
    pia_000011:'Reset',
    pia_000012:'Export data',
    pia_000013:'Pending Numbers',
    pia_000014:'Total amount to be processed (€)',
    pia_000015:'Number of invoiced transactions',
    pia_000016:'Total invoiced amount（€）',
    pia_000017:'nickname',
    pia_000018:'Application type',
    pia_000019:'Billing method',
    pia_000020:'Application amount',
    pia_000021:'Query charging order',
    pia_000022:'Query parking space occupation bill',
    pia_000023:'Reasons for refusal',
    pia_000024:'Invoice details',
    pia_000025:'Invoice header',
    pia_000026:'Company tax number',
    pia_000027:'Company address',
    pia_000028:'Refuse',
    pia_000029:'Contact information',
    pia_000030:'Phone',
    pia_000031:'Mailbox',
    pia_000032:'Display per page',
    pia_000033:'Invoice rejection button prompt',
    pia_000034:'Please fill in the reason for rejection',
    pia_000035:'Invoice issued button prompt',
    pia_000036:'Paper invoice',
    pia_000037:'Electronic invoice',
    pia_000038:'Tips',
    pia_000039:'User nickname / mobile number',
    pia_000040:'Application time:',
    pia_000041:'Start date',
    pia_000042:'End date',
    pia_000043:'Enterprise invoice',
    pia_000044:'Personal invoice',
    pia_000045:'Company Office Number',
    pia_000046:'Bank of deposit',
    pia_000047:'Account number',
    pia_000048:'addressee',
    pia_000049:'Receiving address',
    pia_000050:'No data',
    pia_000051:'This operation will download the excel file of the invoice. Do you want to continue?',
    pia_000052:'Tips',
    pia_000053:'confirm',
    pia_000054:'cancel',
    pia_000055:'Start Download',
    pia_000056:'Cancel Download',
    pia_000057:'Invoice list',
    pia_000058:'Paper invoice and electronic invoice'
}