export default {
    weChatRecord_00001: "Top-up record",
    weChatRecord_00002: "Top-up Date",
    weChatRecord_00003: "Top-up amount({0})",
    weChatRecord_00004: "Capital({0})",
    weChatRecord_00005: "Gift({0})",
    weChatRecord_00006: "Top-up method",
    weChatRecord_00007: "Operator",
    weChatRecord_00008: "Remark",
    weChatRecord_00009: "(No Refunds) Top-up activity",
  }