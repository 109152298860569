export default {
  energyHome_00001: "Non NZS charger",
  energyHome_00002: "NZS battery",
  energyHome_00003: "Current NZS battery SOC",
  energyHome_00004: "Self-oowered today",
  energyHome_00005: "Available",
  energyHome_00006: "mode",
  energyHome_00007: "Current working mode",
  energyHome_00008: "Microgrid charger management",
  energyHome_00009: "NZS battery installed capacity",
  energyHome_00010: "Power",
  energyHome_00011: "Voltage",
  energyHome_00012: "Current",
  energyHome_00013: "Work mode time SCH",
  energyHome_00014: "Today's EV Charging Amount",
  energyHome_00015: "Today's EV Charging Revenue",
  energyHome_00016: "Set Grid Use Cost",
  energyHome_00017: "Charging Station Capacity"

}