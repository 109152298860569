export default {
    groupRecord_00001: "Aufladedatum",
    groupRecord_00002: "Gesamtbetrag der Aufladung({unit})",
    groupRecord_00003: "Aufladeprinzip({0})",
    groupRecord_00004: "Guthaben aufladen({unit})",
    groupRecord_00005: "Kontostand nach Aufladung({0})",
    groupRecord_00006: "Auflademethode",
    groupRecord_00007: "Operator",
    groupRecord_00008: "Geschenk einschließen{promotionBalance}{unit}",
    groupRecord_00009: "Aktionsbetrag({unit})",
    groupRecord_00010: "Datensatz zur Aufladung des Gruppenkontos",
}