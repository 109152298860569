export default {
    createDepot_00001: 'Create charging station',
    createDepot_00002: 'Depot Center',
    createDepot_00003: 'Last Step',
    createDepot_00004: 'Support Models',
    createDepot_00005: 'Passenger Car ',
    createDepot_00006: 'Cargo Van',
    createDepot_00007: 'Midibus',
    createDepot_00008: 'Bus',
    createDepot_00009: 'Station name',
    createDepot_00010: 'It is recommended to use a landmark as the name',
    createDepot_00011: 'Station address',
    createDepot_00012: 'Input.GetPosition',
    createDepot_00013: 'Province',
    createDepot_00014: 'City',
    createDepot_00015: 'District',
    createDepot_00016: 'Street',
    createDepot_00017: 'You can modify the description of a specific street',
    createDepot_00018: 'Next step',
    createDepot_00019: 'Basic information',
    createDepot_00020: 'Fees',
    createDepot_00021: 'Finish creating',
    createDepot_00022: 'Scooter',
    createDepot_00023: 'Select rates',
    createDepot_00024: 'New rates',
    createDepot_00025: 'Start time',
    createDepot_00026: 'End time',
    createDepot_00027: 'Electricity fee({type}/{uint})',
    createDepot_00028: 'Service fee({type}/{uint})',
    createDepot_00029: 'Idle fee({type}/{uint})',
    createDepot_00030: 'Period type',
    createDepot_00031: 'Undefined',
    createDepot_00032: 'Cusp',
    createDepot_00033: 'Peak',
    createDepot_00034: 'Flat',
    createDepot_00035: 'Valley',
    createDepot_00036: 'You can',
    createDepot_00037: 'View the details of the station;',
    createDepot_00038: 'Search the charging transaction of the station;',
    createDepot_00039: 'Manage the charger of the station;',
    createDepot_00040: 'Set the rates of the station;',
    createDepot_00041: 'Set the policies of the station, etc.',
    createDepot_00042: 'Access station management',
    createDepot_00043: 'A maximum of {0} time periods can be added',
    createDepot_00044: 'Please fill in the basic information of the station, which is required.',
    createDepot_00045: 'You can create a new rates or select an existing rates from the drop-down box.',
    createDepot_00046: 'Your station has been created, go experience it now!',
    createDepot_00047: 'Station created!',
    createDepot_00048: 'in Station management',
    createDepot_00049: 'perform the following operations:',
    createDepot_00050: 'DC rates',
    createDepot_00051: 'New AC rates',
    createDepot_00052: 'AC rates',
    createDepot_00053: 'New DC rates',
    createDepot_00054: 'Charges name',
    createDepot_00055: 'Select',
    createDepot_00056: 'Add time',
    createDepot_00057: 'Please fill in the rate standard',
    createDepot_00058: 'Delete',
    createDepot_00059: 'Cancel',
    createDepot_00060: 'Confirm ',
    createDepot_00061: 'Please verify the station information',
    createDepot_00062: 'The tariff period starts from 0 to 24',
    createDepot_00063: 'Please fill in the correct rate',
    createDepot_00064: 'New success',
    createDepot_00065: 'Select the location coordinates of the charging station',
    createDepot_00066: 'Name',
    createDepot_00067: 'Detailed address',
    createDepot_00068: 'Using this location',
    createDepot_00069: 'Address, Street, House Name',
    createDepot_00070: 'Search',
    createDepot_00071: 'Current location',
    createDepot_00072: 'Failed to get location!',
    createDepot_00073: 'The tariff name is occupied',
    createDepot_00074: 'Please select or create a new rate standard',
    createDepot_00075: 'Current usage:',
    createDepot_00076: 'Please enter the name of the rates',
    createDepot_00077: 'Use currency',
    createDepot_00078: 'This station needs to select a tariff type of {type}, there is currently no such tariff template, please create a new tariff.',
    createDepot_00079: 'The same currency type must be selected for both DC and AC charger tariffs.',
    createDepot_00080: 'Currency',
    createDepot_00081: 'No currency type required?',
    createDepot_00082: 'Click to add',
    createDepot_00083: 'Add',
    createDepot_00084: 'Cancel',
    createDepot_00085: 'Add failed',
    createDepot_00086: 'Time zone',
    createDepot_00087: 'Add or manage station charger',
    createDepot_00088: 'Station acquisition failed',
}