<template>
  <div class="grid_set_wrap">
    <div class="switch_wrap">
      <el-row type="flex" align="middle">
        <el-col :span="6">
          <span>{{$t('common_61080')}}</span>
        </el-col>
        <el-col :span="18">
          <el-switch v-model="enable" :active-value="true" :inactive-value="false" @change="handlerChange"></el-switch>
        </el-col>
      </el-row>
      <div class="tips"><i class="el-icon-warning"></i>{{$t('common_61081')}}</div>
    </div>
    <el-row type="flex" align="middle" class="row" v-if="enable&&pageType=='detailDepot'">
      <el-col :span="6">{{$t('common_61082')}}(kW)</el-col>
      <el-col :span="10">
        <el-input class="w216" v-model="powerMax" v-if="powerEdit"></el-input>
        <div v-else class="value">{{powerMax}}</div>
      </el-col>
      <el-col :span="8" align="right">
        <el-button size="medium" @click="cancelEdit('power')" v-if="powerEdit">{{$t('common_00215')}}</el-button>
        <el-button type="primary" size="medium" @click="saveEdit('power')" v-if="powerEdit">{{$t('common_00217')}}</el-button>
        <el-button type="text" size="medium" @click="changeEdit('power')" v-if="!powerEdit">{{$t('common_00213')}}</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" class="row" v-if="enable">
      <el-col :span="6">{{$t('common_61083')}}</el-col>
      <el-col :span="10">
        <div class="time" v-if="timeEdit">
          <el-time-select size="small" align="center" class="time-select" :clearable='false' :placeholder="$t('common_00204')" v-model="openTime.from" :picker-options="pickerOptions">
          </el-time-select>
          <el-time-select size="small" align="center" class="time-select" :clearable='false' :placeholder="$t('common_00205')" v-model="openTime.to" :picker-options="pickerOptions">
          </el-time-select>
        </div>
        <div v-else class="value">{{openTime.from}} - {{openTime.to}}</div>
      </el-col>
      <el-col :span="8" align="right">
        <el-button size="medium" @click="cancelEdit('time')" v-if="timeEdit">{{$t('common_00215')}}</el-button>
        <el-button type="primary" size="medium" @click="saveEdit('time')" v-if="timeEdit">{{$t('common_00217')}}</el-button>
        <el-button type="text" size="medium" @click="changeEdit('time')" v-if="!timeEdit">{{$t('common_00213')}}</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: ["pageType", "photovoltaics"],
  data() {
    return {
      enable: true,
      powerEdit: false,
      powerMax: "0.00",
      cachePowerMax: "0.00",
      pickerOptions: {
        start: "00:00",
        step: "00:15",
        end: "24:00",
      },
      timeEdit: false,
      openTime: {
        from: "08:00",
        to: "16:00",
      },
      cacheTime: {
        from: "08:00",
        to: "16:00",
      },
    };
  },
  watch: {
    photovoltaics: {
      handler(val) {
        console.log(val,'子组件');
        if (!val) return;
        this.enable = val.photovoltaicsEnabled;
        this.powerMax = val.sitePhotovoltaicsMaxPower || "0.00";
        this.openTime = val.photovoltaicsWorkingPeriod?.[0] || {
          from: "08:00",
          to: "16:00",
        };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    cancelEdit(type) {
      switch (type) {
        case "power":
          this.powerMax = this.cachePowerMax;
          this.powerEdit = false;
          break;
        case "time":
          this.openTime = {...this.cacheTime};
          this.timeEdit = false;
          break;
      }
    },
    changeEdit(type) {
      const check = (val) => {
        val && this.$message.error(this.$t("common_00287"));
        return val;
      };
      switch (type) {
        case "power":
          if (check(this.timeEdit)) return;
          this.cachePowerMax = this.powerMax;
          this.powerEdit = true;
          break;
        case "time":
          if (check(this.powerEdit)) return;
          this.cacheTime = {...this.openTime};
          this.timeEdit = true;
          break;
      }
    },
    saveEdit(type) {
      switch (type) {
        case "power":
          let regex = /^\d+(\.\d{1,2})?$/;
          if (
            !Boolean(Number(this.powerMax)) ||
            !regex.test(Number(this.powerMax))
          ) {
            this.$message.error(this.$t('common_61160'));
            return;
          }
          this.powerEdit = false;
          break;
        case "time":
          if (
            Number(this.openTime.from.replace(":", ".")) >=
            Number(this.openTime.to.replace(":", "."))
          ) {
            this.$message.error(this.$t('common_61161'));
            return;
          }
          this.timeEdit = false;
          break;
      }
      this.$emit("saveHandler", {
        photovoltaicsEnabled: this.enable,
        sitePhotovoltaicsMaxPower: this.powerMax,
        photovoltaicsWorkingPeriod: [this.openTime],
      });
    },
    handlerChange() {
      this.$emit("saveHandler", {
        photovoltaicsEnabled: this.enable,
        sitePhotovoltaicsMaxPower: this.powerMax,
        photovoltaicsWorkingPeriod: [this.openTime],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.grid_set_wrap {
  line-height: 1;
  .switch_wrap {
    padding: 13px 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #464b5c;
    line-height: 20px;
    .tips {
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 6px 12px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9097ac;
      line-height: 18px;
      background: #f7f7fa;
      border-radius: 20px;
      margin-top: 5px;
      i {
        margin-right: 7px;
        font-size: 18px;
        vertical-align: top;
      }
    }
  }
  .row {
    padding: 13px 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #464b5c;
    line-height: 20px;
    .w216 {
      width: 216px;
    }
    .value {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1b2330;
    }
    .time {
      display: inline-block;
      color: #41526d;
      .time-select {
        width: 96px;
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>