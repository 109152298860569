export default {
  occupancy_00001: "账单列表",
  occupancy_00002: "导出订单",
  occupancy_00003: "账单编号",
  occupancy_00004: "开始日期",
  occupancy_00005: "结束日期",
  occupancy_00006: "重置",
  occupancy_00007: "查询",
  occupancy_00008: "账单状态",
  occupancy_00009: "全部",
  occupancy_00010: "账单类型",
  occupancy_00011: "计费中",
  occupancy_00012: "待支付",
  occupancy_00013: "已支付",
  occupancy_00014: "桩端直连地锁",
  occupancy_00015: "平台直连地锁",
  occupancy_00016: "账单金额(元)",
  occupancy_00017: "占用账单数(个)",
  occupancy_00018: "占用时长(分钟)",
  occupancy_00019: "充电桩-充电口",
  occupancy_00020: "占用开始时间",
  occupancy_00021: "占用结束时间",
  occupancy_00022: "收费时长",
  occupancy_00023: "天",
  occupancy_00024: "小时",
  occupancy_00025: "分钟",
  occupancy_00026: "微信用户",
  occupancy_00027: "团体",
  occupancy_00028: "离线储值卡",
  occupancy_00029: "在线身份卡",
  occupancy_00030: "当前账单金额",
  occupancy_00031: "实际支付金额",
  occupancy_00032: "账单详情",
  occupancy_00033: "提示",
  occupancy_00034: "此操作将下载账单的Excel文件, 是否继续?",
  occupancy_00035: "确定",
  occupancy_00036: "取消",
  occupancy_00037: "开始下载",
  occupancy_00038: "取消下载",
  occupancy_00039: "用户昵称、手机号、车牌号",
  occupancy_00040: "用户昵称",
  occupancy_00041: "手机号",
  occupancy_00042: "车牌号",
  occupancy_00043: "账单总金额",
  bill_00059: "今天",
  bill_00060: "7天前",
  bill_00061: "15天前",
  bill_00077: "昨天",
  bill_00078: "30天前",
}