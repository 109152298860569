<template lang="html">
  <div class="no-login">
    <div class="login-contain">
      <div class="login-logo" v-if="operatorName=='智充'">
      	<a href="./login.html" class="login-a">
          <img v-if="regis" src="../../../assets/img/logo.png" alt="北京智充">
          <img v-else src="../../../assets/img/login_logo.png" alt="北京智充">
          <span>{{$t('login_00006')}}</span>
        </a>
      </div>
      <div class="login-text">
        <div v-if="product == 'eu'" class="select-language">
          <el-select v-model="language" @change="selectLanguage" :placeholder="$t('select_language_tip')">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <span>{{$t('login_00007')}}</span>
        <!-- <div class="login-button userAdmin" @click='login(false)'>{{$t('login_00008')}}</div> -->
        <div :class="['login-button', 'operator',regis?'regis':'']" @click='login(true)'>{{$t('login_00008')}}</div>
        <div class="privacy" v-if="product !='cn'" > <a target="_blank" id="privacy" href="https://www.xcharge.com/statement">{{$t('common_45010')}}</a></div>
      </div>
    </div>
    <!-- 普通的登录 -->
    <normal-login :flag_normal='flag_normal' @normalDispatch='getNormal' :title="login_00001"></normal-login>
  </div>
</template>

<script>
import normalLogin from "./normal_login.vue";
import {operator} from "@/assets/utils/constants";
export default {
  props:["regis"],
  data() {
    return {
      operatorName: "",
      product:"",
      flag_normal: false,
      login_00001:this.$parent.$t("login_00001"),
      language: "",
      options: [
        {
          value: "zh",
          label: "中文"
        },
        {
          value: "de",
          label: "Deutsch"
        },
        // {
        //   value: "es",
        //   label: "Español"
        // }
      ]
    };
  },
  components: {
    normalLogin
  },
  methods: {
    /*
     * 获取普通登录的开关状态
     */
    selectLanguage(value){
      console.log(value)
      this.$i18n.locale = value;
      window.localStorage.lang = value;
      window.location.reload();
    },
    getNormal: function(val) {
      this.flag_normal = val;
    },
    login(flag){
      window.location.href="./login.html";
      return;
      this.flag_normal = true;
      if(flag){
        this.login_00001 = this.$parent.$t("login_00001");
      }else{
        this.login_00001 = this.$parent.$t("user_group_login_00001");
      }
    },
  },
  created:function () {
    var lang = window.localStorage.lang;
    var product = window.product_area;
    this.product = product;
    this.operatorName = operator;
    var en = new Object();
    en.value = "en";
    en.label = "English";
    this.options.push(en);
    if (!lang) {
        var language = navigator.language.slice(0,2);
        if (language === "ch" || language === "en" || language === "de" || language === "es") {
            lang = language;
        } else {
          if(product == 'eu'){
            lang = "en";
          }else{
            lang = "zh";
          }
        }
    }
    this.language = lang;

  }
};
</script>

<style lang="scss">
.no-login {
  $no-login-height: 64px;
  min-width: 1170px;
  width: 100%;
  height: $no-login-height;
  position: relative;
  top: 0;
  left: 0;
  background: #2081d1;
  & .login-contain {
    width: 1170px;
    height: $no-login-height;
    line-height: $no-login-height;
    margin: 0 auto;
    color: #fff;
    & .login-logo {
      float: left;
      // width: 300px;
      height: $no-login-height;
      & a {
        display: block;
        text-decoration: none;
        color: #fff;
      }
      & img {
        float: left;
        display: block;
        height: 24px;
        margin-top: 20px;
      }
      & span {
        float: left;
        display: block;
        height: $no-login-height;
        margin-left: 10px;
        font-size: 20px;
      }
    }
    & .login-text {
      float: right;
      width: 600px;
      height: $no-login-height;
      text-align: right;
      font-size: 14px;
      /* 选择语言 */
      .select-language{
        display: inline-block;
        vertical-align: middle;
        width: 120px;
        margin-right: 30px;
        .el-input__inner{
          background-color: transparent!important;
          color: #fff!important;
        }
      }
      & .login-button {
        // float: right;
        height: 28px;
        margin-top: 17px;
        line-height: 28px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #fff;
        cursor: pointer;
        margin-left: 12px;
        &.regis{
          border: 1px solid #DCDFE6;
        }
      }
      & .userAdmin{
        width: 130px;
      }
      & .operator{
        width: 66px;
        display: inline-block;
      }
      .privacy{
        display: inline-block;
        margin-left: 12px;
        a{
          color: #fff;
        }

      }
    }
  }
}
</style>
