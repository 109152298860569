export default {
  energyStoragePileManagement_00001: 'Occupied',
  energyStoragePileManagement_00002: 'Charger has not been enabled yet',
  energyStoragePileManagement_00003: 'Charger is offline',
  energyStoragePileManagement_00005: 'Chang into Available',
  energyStoragePileManagement_00006: 'Start charging',
  energyStoragePileManagement_00008: 'Reserved',
  energyStoragePileManagement_00009: 'No Error Code so far',
  energyStoragePileManagement_00010: "Charger rebooted successfully   ",
  energyStoragePileManagement_00011: "Remote start",
  energyStoragePileManagement_00012: "Remote start failed",
  energyStoragePileManagement_00013: "charger management",
  energyStoragePileManagement_00014: "Current faults",
  energyStoragePileManagement_00015: "Faults shooting",
  energyStoragePileManagement_00016: "Please enter the name of charger or SN number",
  energyStoragePileManagement_00017: "Select or enter the name of charging site",
  energyStoragePileManagement_00018: "Overall",
  energyStoragePileManagement_00019: "Charger type：",
  energyStoragePileManagement_00020: "DC",
  energyStoragePileManagement_00021: "AC",
  energyStoragePileManagement_00022: "Gateway",
  energyStoragePileManagement_00023: "Faulty",
  energyStoragePileManagement_00024: "Offline",
  energyStoragePileManagement_00025: "Available",
  energyStoragePileManagement_00026: 'Only check already connected',
  energyStoragePileManagement_00027: "In use",
  energyStoragePileManagement_00028: "Only check waiting for plug out",
  energyStoragePileManagement_00029: "Offline",
  energyStoragePileManagement_00030: "Disabled",
  energyStoragePileManagement_00031: "SN：",
  energyStoragePileManagement_00032: "Vendor-Type：",
  energyStoragePileManagement_00033: "EVSE：",
  energyStoragePileManagement_00034: "Reboot charger",
  energyStoragePileManagement_00035: "Charger details",
  energyStoragePileManagement_00036: "Charging point：",
  energyStoragePileManagement_00037: "Offline",
  energyStoragePileManagement_00038: "Disabled",
  energyStoragePileManagement_00039: "Faulty",
  energyStoragePileManagement_00040: "Occupied",
  energyStoragePileManagement_00041: "Charging",
  energyStoragePileManagement_00042: "Available",
  energyStoragePileManagement_00043: "Waiting for remove the EV",
  energyStoragePileManagement_00044: "Reserved",
  energyStoragePileManagement_00045: "Connected",
  energyStoragePileManagement_00046: "Waiting for plug out",
  energyStoragePileManagement_00047: "View orders",
  energyStoragePileManagement_00048: "Restart charging point",
  energyStoragePileManagement_00049: "Reboot command has been sent, and the process would take about 5 minutes. Please stay on this page, do not refresh the page",
  energyStoragePileManagement_00050: "Notice information",
  energyStoragePileManagement_00051: "Confirm",
  energyStoragePileManagement_00052: "Charger management",
  energyStoragePileManagement_00053: "Current Fault",
  energyStoragePileManagement_00054: "Plug in",
  energyStoragePileManagement_00055: "Finish charging",
  energyStoragePileManagement_00056: "Disabled",
  energyStoragePileManagement_00057: "Normal",
  energyStoragePileManagement_00058: "Faulty",
  energyStoragePileManagement_00059: "Locked the EV",
  energyStoragePileManagement_00060: "Standby",
  energyStoragePileManagement_00061: "Unknown",
  energyStoragePileManagement_00062: "Charger status:",
  energyStoragePileManagement_00063: "Faulty",
  energyStoragePileManagement_00064: "Only check cable is not placed back",
  energyStoragePileManagement_00065: "Placed back",
  energyStoragePileManagement_00066: "Not placed back",
  energyStoragePileManagement_00067: "Abnormal",
  energyStoragePileManagement_00068: "Enter charger name or SN number",
  energyStoragePileManagement_00069: "Current mode",
  energyStoragePileManagement_00070: "Available",
  energyStoragePileManagement_00071: "Connected",
  energyStoragePileManagement_00072: "Charging",
  energyStoragePileManagement_00073: "Waiting for plug out",
  energyStoragePileManagement_00074: "Faulty",
  energyStoragePileManagement_00075: "Offline",
  energyStoragePileManagement_00076: "Disabled",
  energyStoragePileManagement_00077: "Occupied",
  energyStoragePileManagement_00078: "Battery charging",
  energyStoragePileManagement_00079: "Battery discharging",
  energyStoragePileManagement_00080: "Battery available",
  energyStoragePileManagement_00081: "Minitue(s) finish charging",
  energyStoragePileManagement_00082: " mode",


}