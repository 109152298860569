export default {
  knowledge_00001: 'Knowledge bank',
  knowledge_00002: 'Title/Issue description/Solution',
  knowledge_00003: 'All manufactories',
  knowledge_00004: 'All models',
  knowledge_00005: 'List',
  knowledge_00006: 'Create knowledge',
  knowledge_00007: 'Knowledge title',
  knowledge_00008: 'Categories',
  knowledge_00009: 'Related chargers',
  knowledge_00010: '',
  knowledge_00011: '',
  knowledge_00012: '',
  knowledge_00013: '',
  knowledge_00014: 'Secondary category',
  knowledge_00015: 'All Categories',
  knowledge_00016: 'Top category',
}