export default {
    analyze_BI_00001:"Gesamtübersicht", 
    analyze_BI_00002:"Gesamtbetrag der Vorgang (EUR)", 
    analyze_BI_00003:"gesamte Ladeeletrizität (kWh)", 
    analyze_BI_00004:"Gesamteanzahl der Ladenutzer", 
    analyze_BI_00005:"Ladesationen",  
    analyze_BI_00006:"Einkommen(EUR)", 
    analyze_BI_00007:"Ladeeletrizität(kWh)", 
    analyze_BI_00008:"Ladenutzer", 
    analyze_BI_00009:"Ladepunkte", 
    analyze_BI_00010:"Derzeitige Gebührennutzer", 
    analyze_BI_00011:"Fahrzeug Modelle mit Top 5 Ladeanzahl", 
    analyze_BI_00012:"Fahrzeug Modelle mit Top 5 Ladeleistung",
    analyze_BI_00013:"Wärmebildkarte von Ladeelektrizität für 30 Tage x 24 Stunden",
    analyze_BI_00014:"Top 5 Ladestationen nach Ladeleistung (kWh)",
    analyze_BI_00015:"Trend der Auslastungsrate",
    analyze_BI_00016:"Trend des Ladevolumens",
    analyze_BI_00017:"bis", 
    analyze_BI_00018:"Charging Amount(Kwh)",
    analyze_BI_00019:"AC equipment charging utilization rate (%)",
    analyze_BI_00020:"DC equipment charging utilization rate (%)",
} 