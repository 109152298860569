export default {
    alipayApp_00001: "Eine App auf der Ant Financial Services Open Platform erstellen",
    alipayApp_00002:"Um in WeChat Öffentliche Konto die Alipay-Zahlungsmethode hinzuzufügen, müssen Sie eine Anwendung in der Ant Financial Services Open Platform vor der Nutzung erstellen. Bitte verwenden Sie Ihr Alipay-Konto, um sich bei der Anthem Open Platform anzumelden (ein mit Echtnamen zertifiziertes Alipay-Unternehmenskonto ist erforderlich). Bei der Erstregistrierung wählen Sie bitte als 'Registrierungsstatus' 'Selbstentwickelter Entwickler' aus.",
    alipayApp_00003: "Klicken Sie hier, um den Prozess der Antragserstellung zu sehen.",
    alipayApp_00004: "Bitte erstellen Sie eine [Payment Access] Anwendung in [Developer Center] - [Web & Mobile Applications]. Wenn Sie bei der Erstellung der App ein Nutzungsszenario ausgewählt haben, wählen Sie bitte das Nutzungsszenario als [App zur Eigennutzung] aus. Fügen Sie die Funktion [Mobile Website-Zahlung] hinzu. Sie können sich für die Funktion [Mobile Site Payment] sofort oder nach dem erfolgreichen Hinzufügen der App anmelden.",
    alipayApp_00005: "Klicken Sie hier, um den Prozess der Antragserstellung zu sehen.",
    alipayApp_00006: "Wenn Sie eine Anwendung erstellen, wählen Sie bitte 'RSA (SHA256) secret key' als Schnittstellen-Signiermethode, um den öffentlichen Schlüssel der Anwendung festzulegen. Bevor Sie den öffentlichen Schlüssel der Anwendung festlegen, erstellen Sie bitte zunächst den geheimen Schlüssel. Wählen Sie beim Erzeugen des geheimen Schlüssels PKCS8 (für JAVA) für das Format des geheimen Schlüssels und 2048 für die Länge des geheimen Schlüssels",
    alipayApp_00007: "Klicken Sie hier, um den Prozess der Generierung eines geheimen Schlüssels anzuzeigen.",
    alipayApp_00008: "Nachdem Sie die Anwendung hinzugefügt haben, vervollständigen Sie bitte die APPID-Einstellung der Anwendung und die Einstellung des geheimen Schlüssels auf der Betriebsplattform und beantragen dann, dass die Anwendung online geht.",
    alipayApp_00009: "Gehen Sie zur Anthem Open Platform",
    alipayApp_000010: "Einstellung der APPID der Anwendung",
    alipayApp_000011: "Nachdem Sie Ihre App erstellt haben, gehen Sie bitte zu Ant Financial Services Open Platform und fügen Sie die erstellte App APPID hier in [Developer Center] - [Web & Mobile App] hinzu.",
    alipayApp_000012: "APPID",
    alipayApp_000013: "Geheimschlüssel-Einstellungen",
    alipayApp_000014: "Nachdem die Erstellung der App abgeschlossen ist, fügen Sie bitte den privaten Schlüssel der App hinzu, der mit der Einstellung [Gegenzeichnungsmethode] [RSA(SHA256) geheimer Schlüssel] erzeugt wurde, wenn Sie die App hier hinzufügen. Überprüfen Sie auch den öffentlichen Alipay-Schlüssel und fügen Sie den öffentlichen Alipay-Schlüssel hier hinzu. Wenn Sie anschließend den geheimen Schlüssel in Anthem Open Platform ändern, fügen Sie ihn bitte hier erneut ein, sonst kann WeChat Public die Alipay-Zahlungsfunktion nicht nutzen.",
    alipayApp_000015: "Privater Schlüssel der Anwendung und öffentlicher Schlüssel von Alipay",
    alipayApp_000016: "eingestellt",
    alipayApp_000017: "Zeit einstellen",
    alipayApp_000018: "Alipay-Zahlungsmethode in WeChat Public anzeigen",
    alipayApp_000019: "Nachdem Sie eine Anwendung in der Ant Financial Services Open Platform erstellt und die APPID-Einstellung der Anwendung sowie die Einstellung des geheimen Schlüssels in der Betriebsplattform abgeschlossen haben, können Sie beantragen, dass die Anwendung in der Ant Financial Services Open Platform aktiviert wird. Sie können hier 'Ja' einstellen, um die Alipay-Zahlungsmethode in der öffentlichen WeChat-Nummer anzuzeigen, nachdem die Anwendung erfolgreich von der Ant Financial Services Open Platform gestartet wurde, andernfalls können Benutzer die Alipay-Zahlung in der öffentlichen WeChat-Nummer nicht verwenden. ",
    alipayApp_000020: "einstellen",
    alipayApp_000021: "Bitte fügen Sie hier den privaten Schlüssel der Anwendung hinzu (erforderlich)",
    alipayApp_000022: "Öffentlicher Alipay-Schlüssel bitte hier hinzufügen (erforderlich)",
    alipayApp_000023: "nicht eingestellt",
    alipayApp_000024: "Bitte stellen Sie zuerst 'Alipay-Zahlungsmethode in der öffentlichen WeChat-Nummer anzeigen' auf 'Nein' ein",
    alipayApp_000025: "",
    alipayApp_000026: "",
    
}