export default {
  userDetail_00001: "Refreshing WeChat messages",
  userDetail_00002: "Male",
  userDetail_00003: "Female",
  userDetail_00004: "Modify",
  userDetail_00005: "Save",
  userDetail_00006: "Cancel",
  userDetail_00007: "User WeChat OpenID",
  userDetail_00008: "Join Time",
  userDetail_00009: "Public number",
  userDetail_000010: "Followed",
  userDetail_000011: "Unfollowed",
  userDetail_000012: "Last charge time",
  userDetail_000013: "WeChat Pay Free Payment",
  userDetail_000014: "Authorized",
  userDetail_000015: "Not authorized",
  userDetail_000016: " link EV VIN",
  userDetail_000017: "Charging",
  userDetail_000018: "Wallet balance",
  userDetail_000019: "€",
  userDetail_000020: "View top-up history",
  userDetail_000021: "View Refund History",
  userDetail_000022: "Accept site charging limit constraints",
  userDetail_000023: "User Vehicles",
  userDetail_000024: "Model",
  userDetail_000025: "License plate number",
  userDetail_000026: "Groups joined",
  userDetail_000027: "Charging data for the last 30 days",
  userDetail_000028: "Please save the previous operation!!!" ,
  userDetail_000029: "The entered mobile phone number cannot be empty",
  userDetail_000030: "The entered license plate number cannot be empty", 
  userDetail_000031: "Please enter the correct format: vehicle make|model",
  userDetail_000032: "Please select the make or model of the vehicle",
  userDetail_000033: "Modified successfully",
  userDetail_000034: "Charging times:", 
  userDetail_000035: "Charging power:",
  userDetail_000036: "Charging cost:",
  userDetail_000037: "Times",
  userDetail_000038: "kwh",
  userDetail_000039: "Select brand",
  userDetail_000040: "Select model",
  userDetail_000041: "Manufacture|Model",
  userDetail_000042: "Enter manually",
  userDetail_000043: "Non-rank user",
  userDetail_000044: "Upgrade to {title} is short of {num}",
  userDetail_000045: "Upgrading to {title} is still {num} short",
  userDetail_000046: "No relegation task required",
  userDetail_000047: "Gift amount",
  userDetail_000048: "Units",
  userDetail_000049: "Login password",
  userDetail_000050: "Required",
  userDetail_000051: "Please enter your password",
  userDetail_000052: "Remark",
  userDetail_000053: "Required, no more than 20 characters",
  userDetail_000054: "Please enter content",
  userDetail_000055: "Confirm",
  userDetail_000056: "Added to user blacklist",
  userDetail_000057: "User Center",
  userDetail_000058: "Basic Information",
  userDetail_000059: "Card Vouchers Owned",
  userDetail_000060: "Enquiry about charging orders",
  userDetail_000061: "Enquiry about occupancy orders",
  userDetail_000062: "Add to user blacklist",
  userDetail_000063: "Operation successful",
  userDetail_000064: "Membership level",
  userDetail_000065: "Adjust level",
  userDetail_000066: "Open",
  userDetail_000067: "Off",
  userDetail_000068: "Top up your account",
  userDetail_000069: "Adjust membership level",
  userDetail_000070: "Enter up to 20 characters for notes",
  userDetail_000071: "Please enter a comment",
  userDetail_000072: "Please enter the amount of recharge or bonus amount",
  userDetail_000073: "Please enter the correct amount for the gift",
  userDetail_000073: "Please enter the correct amount of recharge",
  userDetail_000074: "Add to user blacklist",
  userDetail_000075: "After joining the user blacklist, the user's microsignal and mobile phone number as well as other microsignals corresponding to the same mobile phone number cannot charge the vehicle!" ,
  userDetail_000076: "End date",
  userDetail_000077: "Reason for joining",
  userDetail_000078: "Select date",
  userDetail_000079: "Please enter the reason for joining the blacklist (required)",
  userDetail_000080: "Add to",
  userDetail_000081: "Reason cannot be empty!", 
  userDetail_000082: "End date cannot be empty!", 
  userDetail_000083: "Contains a gift of {num}€",
  userDetail_000084: "Includes group allocation of {num}{unit}€",
  userDetail_000085: "User",
  userDetail_000086: "User Management",
  userDetail_000087: "Administrator",
  userDetail_000088: "Unbind VIN",
  userDetail_000089: "Nickname Comments",
  userDetail_000090: "The entered nickname comment cannot be empty",
  userDetail_000091: "Please bind your mobile phone number", 
  userDetail_000092: "No mobile phone number is bound", 
  userDetail_000093: "No groups joined",
  userDetail_000094: "Please enter your password",
  userDetail_000095: "Please enter a comment name of less than 10 characters",
  userDetail_000096: "Please enter a note of up to 10 characters",
  userDetail_000097: "Source of user",
  userDetail_000098: "Status",
  userDetail_000099: "Name",
  userDetail_000100: "Expiry date",
  userDetail_000101: "Acquisition method",
  userDetail_000102: "Acquisition time",
  userDetail_000103: "Action",
  userDetail_000104: "Coupon details",
  userDetail_000105: "Discount coupon details", 
  userDetail_000106: "Event details", 
  userDetail_000107: "Remaining credit", 
  userDetail_000108: "Power Pack Details", 
  userDetail_000109: "Credits details", 
  userDetail_000110: "Vouchers",
  userDetail_000111: "Discounts", 
  userDetail_000112: "Electricity ticket",
  userDetail_000113: "Credits",
  userDetail_000114: "All",
  userDetail_000115: "To be validated",
  userDetail_000116: "Available",
  userDetail_000117: "Used up",
  userDetail_000118: "Expired",
  userDetail_000119: "Invite a friend: Inviter reward", 
  userDetail_000120: "Invite a friend: reward for accepting an invitation", 
  userDetail_000121: "kwh",
  userDetail_000122: "Purchase",
  userDetail_000123: "Redeem",
  userDetail_000124: "Relegation has been completed",
  userDetail_000135: "Batch voiding of cards and vouchers",
  }