export default {
  bill_00059: "Heute",
  bill_00060: "vor 7 Tagen",
  bill_00061: "vor 15 Tagen",
  bill_00077: "Gestern",
  bill_00078: "vor 30 Tagen", 



  convert_00001: 'Kanaleinlösecode-Rekord',
  convert_00002: 'Marketing-Zentrum',
  convert_00003: 'Gutscheinverteilung',
  convert_00004: 'Kanaleinlösecode-Rekord',
  convert_00005: 'Bitte Einlösecode eingeben',
  convert_00006: 'Bitte Verteilungskanal eingeben',
  convert_00007: 'Bitte Gutscheinname eingeben',
  convert_00008: 'Startdatum',
  convert_00009: 'Endedatum',
  convert_00010: 'Bitte geben Sie Benutzname/Handynummer ein',
  convert_00011: 'Gutscheintyp',
  convert_00012: 'alle',
  convert_00013: 'Gutschein',
  convert_00014: 'Elektrizitätspaket',
  convert_00015: 'zurücksetzen',
  convert_00016: 'suchen',
  convert_00017: 'Einlöseranzahl',
  convert_00018: 'Einlösungsanzahl',
  convert_00019: 'Elektrizitätspaketsanzahl',
  convert_00020: 'Elektrizitätssumme in Paket (kWh)',
  convert_00021: 'Anzahl der Gutscheine',
  convert_00022: 'Gutscheinssumme(EUR)',
  convert_00023: 'Gutscheinliste',
  convert_00024: 'Daten exportieren',
  convert_00025: 'Benutzer-Avatar',
  convert_00026: 'Benutzername(Handynummer)',
  convert_00027: 'Einlösecode',
  convert_00028: 'Verteilungskanal',
  convert_00029: 'Gutscheinstyp',
  convert_00030: 'Gutscheinname',
  convert_00031: 'Einlösungszeit',
  convert_00032: 'aktion',
  convert_00033: 'Benutzereinzelheiten',
  convert_00034: 'Gutscheineinzelheiten',
  convert_00035: 'Bei dieser Aktion wird eine Excel-Datei mit den Einlösecodes heruntergeladen, möchten Sie fortfahren?',
  convert_00036: 'Hinweise',
  convert_00037: 'abbrechen',
  convert_00038: 'Herunterladen starten',
  convert_00039: 'Elektrizitätspaket',
  convert_00040: 'Gutschein',
  convert_00041: 'Kanaleinlösecode-rekord',
}