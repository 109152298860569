export default {
    safetyReportDetails_00001: 'Safety Report Details',
    safetyReportDetails_00002: 'Safety Report',
    safetyReportDetails_00003: 'Reporting Period',
    safetyReportDetails_00004: 'Edit Conclusions&Suggestions',
    safetyReportDetails_00005: 'Export as pdf',
    safetyReportDetails_00006: 'Send to User',
    safetyReportDetails_00007: 'Limit/Forbid Charging',
    safetyReportDetails_00008: 'Plate Number',
    safetyReportDetails_00009: 'VIN',
    safetyReportDetails_00010: 'Brand-Model-Year',
    safetyReportDetails_00011: 'View User',
    safetyReportDetails_00012: 'Charging Behavior Analysis',
    safetyReportDetails_00013: 'Average of SOC During Start-Charging',
    safetyReportDetails_00014: 'Average of SOC During End-Charging',
    safetyReportDetails_00015: 'Fast Charging Times',
    safetyReportDetails_00016: 'Slow Charging Times',
    safetyReportDetails_00017: 'Overdischarge Times ',
    safetyReportDetails_00018: 'Number of Abnormal Transactions ',
    safetyReportDetails_00019: 'View Abnormal Transactions ',
    safetyReportDetails_00020: 'Total Number of Transactions ',
    safetyReportDetails_00021: 'View Security Assessment Log ',
    safetyReportDetails_00022: 'Proportion of Abnormal Transactions',
    safetyReportDetails_00023: 'Abnormal Charging Analysis',
    safetyReportDetails_00024: 'Charge Abnormal Termination Ratio is High ',
    safetyReportDetails_00025: 'Safety Score Analysis',
    safetyReportDetails_00026: 'Event Remarks',
    safetyReportDetails_00027: 'Maximum Temperature Rate Change Trend',
    safetyReportDetails_00028: 'Maximum Temperature Change Trend',
    safetyReportDetails_00029: 'Change Trend of Maximum Temperature Difference',
    safetyReportDetails_00030: 'Change Trend of maximum Differential Pressure',
    safetyReportDetails_00031: 'Disequilibrium',
    safetyReportDetails_00032: 'Score',
    safetyReportDetails_00033: 'Temperature Velocity',
    safetyReportDetails_00034: 'Temperature',
    safetyReportDetails_00035: 'Temperature Difference',
    safetyReportDetails_00036: 'Voltage Difference',
    safetyReportDetails_00037: 'Disequilibrium',
    safetyReportDetails_00038: 'The average value of the battery {name} {time} of the car is {caverage}, with the highest value of {max}, and the average value of the same model year in your city is {oaverage}. It can be seen that the battery {name} index {evaluation} of the vehicle',
    safetyReportDetails_00039: 'No Problem',
    safetyReportDetails_00040: 'Sub-safety, Please pay attention to the maintenance of the vehicle',
    safetyReportDetails_00041: 'There are obvious abnormalities. Please repair the vehicle as soon as possible',
    safetyReportDetails_00042: 'Same Model',
    safetyReportDetails_00043: 'Event Remarks',
    safetyReportDetails_00044: 'Please fill in remarks',
    safetyReportDetails_00045: 'Are you sure to delete this note?',
    safetyReportDetails_00046: 'Start less than 30% soc',
  
      
  }
  