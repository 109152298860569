export default {
    userInvoice_00001: "Group Name",
    userInvoice_00002: "Invoicing details",
    userInvoice_00003: "Basic Information",
    userInvoice_00004: "Application Status",
    userInvoice_00005: "All",
    userInvoice_00006: "Pending",
    userInvoice_00007: "Invoice issued",
    userInvoice_00008: "Cancelled",
    userInvoice_00009: "Enquiry",
    userInvoice_00010: "Paper invoice",
    userInvoice_00011: "e-invoice",
    userInvoice_00012: "Paper invoices and electronic invoices",
    userInvoice_00013: "To",
    userInvoice_00014: "Start date",
    userInvoice_00015: "End date",
    userInvoice_00016: "User Cancellation",
    userInvoice_00017: "Nickname",
    userInvoice_00018: "Application type",
    userInvoice_00019: "Invoicing method",
    userInvoice_00020: "Amount requested",
    userInvoice_00021: "Invoice details",
    userInvoice_00022: "Invoice payable",
    userInvoice_00023: "Company Tax ID",
    userInvoice_00024: "Company address",
    userInvoice_00025: "Company number",
    userInvoice_00026: "Bank of Account",
    userInvoice_00027: "Account number",
    userInvoice_00028: "Contact Information",
    userInvoice_00029: "Recipient",
    userInvoice_00030: "Mobile phone number",
    userInvoice_00031: "Receiving address",
    userInvoice_00032: "Personal invoice",
    userInvoice_00033: "Corporate invoice",
    userInvoice_00034: "Order Enquiry",
    userInvoice_00035: "Email",
    userInvoice_00036: "",
    userInvoice_00037: "",
    userInvoice_00038: "",
    userInvoice_00039: "",
    userInvoice_00040: "",
    userInvoice_00041: "",
    userInvoice_00042: "",
    userInvoice_00043: "",
    userInvoice_00044: "",
    userInvoice_00045: "",
    userInvoice_00046: "",
    userInvoice_00047: "",
    userInvoice_00048: "",
    userInvoice_00049: "",
    userInvoice_00050: "",
  }