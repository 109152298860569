export default {
    rates_00001: 'Tarif management',
    rates_00002: 'Stations',
    rates_00003: 'Tarifname',
    rates_00004: 'Wurde es angewendet',
    rates_00005: 'alle',
    rates_00006: 'Yes',
    rates_00007: 'No',
    rates_00010: 'Neu gebauter Tarif',
    rates_00012: 'Angewandt',
    rates_00013: 'Nicht angewandt',
    rates_00015: 'Kopieren',
    rates_00018: 'Drücken',
    rates_00019: 'Bitte geben Sie den Tarifnamen ein',
    rates_00020: 'Bitte Tarifstandard ausfüllen',
    rates_00021: 'Bitte geben Sie den Standard-Tarifstandard der Site ein',
    rates_00022: 'Tarifperiode muss von 0 bis 24 . beginnen',
    rates_00023: 'Bitte geben Sie den korrekten Tarifstandard ein',
    rates_00024: 'Der Tarifname ist bereits belegt',
    rates_00025: 'Tarif ändern',
    rates_00026: 'Tarif kopieren',
    rates_00027: 'Zeiten zufügen',
    rates_00028: 'Max.{0} Zeiten können hinzugefügt werden',
    rates_00029: 'Startzeit',
    rates_00030: 'Endzeit',
    rates_00031: 'Strompreis({type}/{unit})',
    rates_00032: 'Servicegebühr({type}/{unit})',
    rates_00033: 'Verzugsgebühr({type}/{unit})',
    rates_00034: 'Haupt-/Nebentarif Zeiten',
    rates_00035: 'Undefiniert',
    rates_00036: 'Abbrechen',
    rates_00037: 'Bestätigen',
    rates_00038: 'Ergebnis übertragen',
    rates_00039: 'Standort',
    rates_00040: 'Anzahl der Geräte',
    rates_00041: 'Ergebnis der Geräteübertragung',
    rates_00042: 'DC',
    rates_00043: 'AC',
    rates_00044: 'Nach dem Löschen kann es nicht wiederhergestellt werden. Möchten Sie fortfahren?',
    rates_00047: 'Erfolgreich erstellt',
    rates_00048: 'Tip',
    rates_00049: 'peak',
    rates_00050: 'Flat',
    rates_00051: 'offpeak',
    rates_00053: 'Schieben',
    rates_00055: 'Push fehlgeschlagen',
    rates_00056: 'Tarifdetails',
    rates_00057: 'Erfolgreich bearbeiten',
    rates_00058: 'Bestätigen drücken',
    rates_00059: 'Schließung',
    rates_00060: 'Währung verwenden',
    // ****
    rates_00061: 'bringen',
    rates_00062: 'Öffentliche parkplätze',
    rates_00063: 'Gemeiner vorschuss',
    rates_00064: 'Gruppe',
    rates_00065: 'Paragraph',
    rates_00066: 'Eine liste der forderungen',
    rates_00067: 'finanzierung',
    rates_00068: 'Abschieben',
    rates_00069: 'Seite auswählen',
    rates_00070: 'Wählt die gruppe aus',
    rates_00071: 'Bitte wählen sie eine dritte option',
    rates_00072: 'Kommunizieren',
    rates_00073: 'Bälle in strömen',
    rates_00074: 'Binde',
    rates_00075: 'Jetzt vielleicht',
    rates_00076: 'Suchen sie zuerst ein profil mit einem pfahl heraus',
    // 电网资费
    rates_00077: 'Verschiedene arten der finanzierung',
    rates_00078: 'Speicher',
    rates_00079: 'Ladung',
    rates_00080: 'Der gipfel des tal entspricht dem preis',
    rates_00081: 'Das nennt man gewöhnlich die spitze',
    rates_00082: 'Strom für das stromnetz({type}/{unit})',
    rates_00083: 'Schritt für schritt',
    rates_00084: 'Ich meine als nächstes',
    rates_00085: 'Bitte wählen sie den preis für die veranstaltung des aszendenz aus',
    rates_00086: 'Niedrige gebühren',
    rates_00087: 'Von austauschgebühren',
    rates_00088: 'Für öffentliche gelder',
    rates_00089: 'Gruppe',
    rates_00090: 'Eine dritte partei',
    rates_00091: 'Gilt die bestätigung der teilnahmegebühr',
    rates_00092: 'Seiten',
    rates_00093: '.',
    rates_00094: 'Die lagerung',
}