export default {
    invoice_00001: "发票申请说明：",
    invoice_00002: "1.已支付账单90天内可开发票，跨年账单不可开票",
    invoice_00003: "2.增值税专用发票仅支持纸质发票；增值税普通发票仅支持电子版",
    invoice_00004: "3.账单金额小于2000元，如需开具增值税专业发票，邮费需自理",
    invoice_00005: "4.我们将统一时间进行发票申请处理，申请后请耐心等待，请勿重复申请",
    invoice_00006: "增值税专用发票",
    invoice_00007: "增值税普通发票",
    invoice_00008: "修改金额",
    invoice_00009: "请输入",
    invoice_00010: "用于收取纸质发票的快递",
    invoice_00011: "发票申请信息",
    invoice_00012: "开票金额",
    invoice_00013: "修改金额",
    invoice_00014: "发票类型",
    invoice_00015: "公司名称",
    invoice_00016: "公司地址",
    invoice_00017: "纳税人识别号",
    invoice_00018: "开户行",
    invoice_00019: "开户行账号",
    invoice_00020: "公司电话",
    invoice_00021: "收件人",
    invoice_00022: "手机号",
    invoice_00023: "邮寄地址",
    invoice_00024: "备注",
    invoice_00025: "提交申请",
    invoice_00026: "确定提交发票申请？",
    invoice_00027: "修改金额",
    invoice_00028: "发票申请已提交，请耐心等待。",
    invoice_00029: "请输入",
    invoice_00030: "用于收取纸质发票的快递",
    invoice_00031: "账单金额",
    invoice_00032: "收费款项",
    invoice_00033: "账单编号",
    invoice_00034: "收费项目",
    invoice_00035: "收费时段",
    invoice_00036: "收费版本",
    invoice_00037: "生成日期",
    invoice_00038: "可申请账单列表",
    invoice_00039: "共计{num}笔账单",
    invoice_00040: "用于收取电子发票的邮箱",
    invoice_00041: "用于收取电子发票的手机号",
    invoice_00042: "邮箱",
    invoice_00043: "请输入正确的手机号",
    invoice_00044: '请设置开票金额',
    invoice_00045: '开票内容',
    invoice_00046: '请详细核实开票信息(如公司名称、纳税人识别号等) 无误后再提交发票申请!',
}



