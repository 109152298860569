export default {
  userAllocateDetail_00001: "Gruppename",
  userAllocateDetail_00002: "Zuordnungsdatensatz",
  userAllocateDetail_00003: "Grundinformation",
  userAllocateDetail_00004: "Zuweisungseinzelheit",
  userAllocateDetail_00005: "Zuweisungseinzelheit herunterladen",
  userAllocateDetail_00006: "Avatar",
  userAllocateDetail_00007: "Benutzer/Handynummer/Kartenummer",
  userAllocateDetail_00008: "Typen",
  userAllocateDetail_00009: "Zugewiesener Betrag ({0})",
  userAllocateDetail_00010: "Wechat Nutzer",
  userAllocateDetail_00011: "Online-Ausweis",
  userAllocateDetail_00012: "Nutzerliste",
  userAllocateDetail_00013: "",
  userAllocateDetail_00014: "",
  userAllocateDetail_00015: "",
  userAllocateDetail_00016: "",
  userAllocateDetail_00017: "",
  userAllocateDetail_00018: "",
  userAllocateDetail_00019: "",
  userAllocateDetail_00020: "",
}