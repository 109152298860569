<template>
  <!-- 设置 Dialog -->
  <el-dialog :title="title" :visible.sync="dialogVisible" width="800px" :lock-scroll="false" :before-close="handleClose">
    <h3 class="slot_title">
      <slot name="listTitle"></slot>
    </h3>
    <div class="check_list">
      <el-tag v-for="tag in checkList" :key="tag" closable @close="delCheck(tag)">{{formmatCheck(tag)}}</el-tag>
    </div>
    <h3 class="slot_title s">
      <slot name="listTotal"></slot>
    </h3>
    <div class="check_list">
      <el-button v-for="tag in totalList" :key="tag.id" size="small" @click="addList(tag)">+ {{tag.label}}</el-button>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">{{$t("common_00215")}}</el-button>
      <el-button type="primary" @click="confirm">{{$t("common_00234")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import funMapping from "./funMapping.js";
import { requestUrl, ajax, checkLogin } from "@/assets/utils/network";
import { checkTabs } from "@/assets/utils/permission";
import PubSub from "pubsub-js";
export default {
  props: {
    dialogVisible: {
      require: true,
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "设置",
    },
    type: {
      require: true,
      type: String,
      default: "event",
    },
    checkList: {
      require: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      customConfig: {},
      permissionsList: [],
    };
  },
  computed: {
    totalList() {
      return funMapping.filter(
        (item) =>
          item.type.includes(this.type) && !this.checkList.includes(item.id)
      );
    },
  },
  mounted() {
    PubSub.subscribe("customEdit", (name, content) => {
      this.customConfig = content;
    });
    this.permissionsList = checkLogin("permissionsV2") || {};
  },
  methods: {
    // 已选
    formmatCheck(id) {
      let temp = funMapping.filter((it) => it.id == id);
      return temp.length ? temp[0].label : "-";
    },
    // 添加
    addList(tag) {
      if (this.type == "event" && this.checkList.length >= 4) {
        this.$message.error("最多选择4个");
        return;
      }
      if (this.type == "fun" && this.checkList.length >= 8) {
        this.$message.error("最多选择8个");
        return;
      }
      let { id, permissions } = tag;
      if (!checkTabs(permissions)) {
        this.$message.error("您的账号没有此功能权限，请联系管理员！");
        return;
      }
      this.checkList.push(id);
    },
    // 删除已选
    delCheck(tag) {
      this.checkList.splice(this.checkList.indexOf(tag), 1);
    },
    handleClose() {
      this.$emit("cancle", false);
    },
    confirm() {
      let params = new Object();
      params.id = checkLogin("employeeId");
      params.customConfig = {
        commonItem:
          this.type == "fun"
            ? this.checkList
            : this.customConfig.commonItem || [],
        todoItem:
          this.type == "event"
            ? this.checkList
            : this.customConfig.todoItem || [],
      };
      ajax({
        type: "POST",
        url: `${requestUrl}/employee/config`,
        dataType: "json",
        data: JSON.stringify(params),
        success: (response) => {
          let info = JSON.parse(response);
          if (info.error) {
            this.$message.error(this.$t("common_61026"));
          } else {
            PubSub.publish("customEditSave", true);
            this.handleClose();
          }
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.slot_title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #1b2330;
  line-height: 22px;
  margin-bottom: 16px;
  &.s {
    margin-top: 20px;
  }
}
.check_list {
  display: flex;
  flex-wrap: wrap;
  .el-tag {
    margin-right: 16px;
    margin-bottom: 10px;
    background: #eaefff;
    font-weight: 600;
    color: #186dff;
  }
  .el-button {
    margin-right: 16px;
    margin-bottom: 10px;
    background: #eff2f6;
    color: #1c2237;
    width: 136px;
    & + .el-button {
      margin-left: 0;
    }
  }
}
</style>