export default {
  financingSettings_00001: 'Wenn die Aufladefunktion aktiviert ist, können Nutzer in WeChat Public aufladen, und die Aufladung wird automatisch vom Guthaben abgezogen.',
  financingSettings_00002: "Ob die Aufladung aktiviert werden soll",
  financingSettings_00003: "Einstellungen für die Tastatur des Aufladepanels",
  financingSettings_00004: 'bearbeiten',
  financingSettings_00005: 'Speichern',
  financingSettings_00006: 'Abbrechen',
  financingSettings_00007: 'Löschen',
  financingSettings_00008: 'Hinzufügen',
  financingSettings_00009: 'Bei aktivierter Rückerstattungsanforderung können Nutzer eine Rückerstattung ihres Guthabens in WeChat Public anfordern',
  financingSettings_00010: 'Ob Erstattungen möglich sein sollen',
  financingSettings_00011: 'Erstattungsmethode',
  financingSettings_00012: 'Vollautomatische Erstattung',
  financingSettings_00013: 'Halbautomatische Erstattungen',
  financingSettings_00014: 'Manuelle Erstattung',
  financingSettings_00015: 'Ändern',
  financingSettings_00016: 'Finanzierungsquelle für die Erstattung',
  financingSettings_00017: 'Rückerstattung nicht abgerechneter Mittel',
  financingSettings_00018: 'Rückerstattung des verfügbaren Guthaben',
  financingSettings_00019: 'Erstattungserinnerungsmeldung',
  financingSettings_00020: '(wird auf der Seite für die Rückerstattung des Kontostands auf der WeChat Publik angezeigt)',
  financingSettings_00021: 'Das Portal für die Rechnungsanwendung wird nach dem Öffnen in WeChat Public angezeigt',
  financingSettings_00022: 'Ob das Anwendungsportal für Rechnungen aktiviert werden soll',
  financingSettings_00023: 'Mindestbetrag für die Rechnungsstellung nicht oder falsch eingegeben',
  financingSettings_00024: 'Mindestbetrag für die Rechnungsstellung',
  financingSettings_00025: 'Unterstützte Rechnungsstellungsmethoden',
  financingSettings_00026: 'Papierrechnungen',
  financingSettings_00027: 'Elektronische Rechnungen',
  financingSettings_00028: 'Papierrechnungen und elektronische Rechnungen',
  financingSettings_00029: 'Hinweise für Rechnungen',
  financingSettings_00030: '(wird auf der Seite zur Rechnungsanforderung auf der WeChat-PUBLIK angezeigt)',
  financingSettings_00031: 'Nicht mehr als 4 Zeilen im Aufladefeld!',
  financingSettings_00032: 'Es sind leere Werte in Ihrem Panel, bitte geben Sie den Betrag ein!',
  financingSettings_00033: 'Der Betrag im Feld muss eine positive ganze Zahl ungleich Null sein, bitte geben Sie ihn erneut ein!',
  financingSettings_00034: 'Die maximale Länge des Betrages im Panel ist 5 Ziffern, bitte neu eingeben! ',
  financingSettings_00035: 'Es gibt doppelte Werte in Ihrem Panel, bitte geben Sie sie erneut ein! ',
  financingSettings_00036: 'Erfolgreich geändert',
  reserveManagement_00005: 'Bitte geben Sie den Flussnamen ein',
  reserveManagement_00006: 'Ist die Reserve aktiviert?',
  reserveManagement_00007: 'Alle',
  reserveManagement_00008: 'Aktivieren',
  reserveManagement_00009: 'Nicht aktiviert',
  reserveManagement_00010: 'suchen',
  reserveManagement_00011: 'zurücksetzen',
  reserveManagement_00012: 'Name des zugreifenden Drittenfluss',
  reserveManagement_00013: 'Geöffnete Ladestation',
  reserveManagement_00014: 'Nicht geöffnete Ladestation',
  reserveManagement_00015: 'Zugriffsmethode',
  reserveManagement_00016: 'Ob Reserven aktiviert werden sollen',
  reserveManagement_00017: 'Aktueller Guthaben',
  reserveManagement_00018: 'Reservebetrag',
  reserveManagement_00019: 'Aktion',
  reserveManagement_00020: 'Verwaltung der Reserven',
  reserveManagement_00021: 'Ja',
  reserveManagement_00022: 'Nein',
  reserveManagement_00023: 'Flussplattform eines Dritten',
  reserveManagement_00024: 'CPO',
  reserveManagement_00025: 'Ob Reserve gebraucht?',
  reserveManagement_00026: 'Kann negative Ausbuchungen',
  reserveManagement_00027: 'Guthabenerinnerung',
  reserveManagement_00028: 'Ändern',
  reserveManagement_00029: 'Aufladen des Reservebetrags',
  reserveManagement_00030: 'Speichern',
  reserveManagement_00031: 'Abbrechen',
  reserveManagement_00032: 'Automatischer Stopp des Ladevorgangs, wenn der Saldo kleiner als 0 ist',
  reserveManagement_00033: 'Geschichte der Reserveaufladen',
  reserveManagement_00034: 'Aufladebetrag (EUR)',
  reserveManagement_00035: 'Abzugsbetragszeit',
  reserveManagement_00036: 'zurück',
  reserveManagement_00037: 'Regierung',
  reserveManagement_00038: 'Direktunterzeichnung',
  reserveManagement_00039: 'Eingabefehler',
  selfInvoicingSetting_00004: 'Gruppenname',
  selfInvoicingSetting_00005: 'suchen',
  selfInvoicingSetting_00006: 'zurücksetzen',
  selfInvoicingSetting_00007: 'Zahlungsmethode',
  selfInvoicingSetting_00008: 'Gesamtzahl der Mitglieder',
  selfInvoicingSetting_00009: 'Unterstützt es die Online-Rechnungsanforderung über die Selbstbedienungsverwaltung',
  selfInvoicingSetting_00010: 'Aktion',
  selfInvoicingSetting_00011: 'Einstellungen für die Rechnungsstellung',
  selfInvoicingSetting_00012: 'Mindestbetrag für die Rechnungsstellung',
  selfInvoicingSetting_00013: 'Bitte geben Sie eine Zahl mit bis zu zwei Dezimalstellen ein',
  selfInvoicingSetting_00014: 'Unterstützte Rechnungsstellungsmethoden',
  selfInvoicingSetting_00015: 'Papierrechnung',
  selfInvoicingSetting_00016: 'Elektronische Rechnungen',
  selfInvoicingSetting_00017: 'Papierrechnungen und elektronische Rechnungen',
  selfInvoicingSetting_00018: 'Hinweise für Rechnungen',
  selfInvoicingSetting_00019: 'Rechnungserinnerungsnachrichten werden auf der Self-Service-Rechnungsanforderungsseite angezeigt',
  selfInvoicingSetting_00020: 'Bitte geben Sie Informationen zur Rechnungserinnerung ein',
  selfInvoicingSetting_00021: "Einzelzahlung",
  selfInvoicingSetting_00022: "Gruppezahlung",
  selfInvoicingSetting_00023: "Ja",
  selfInvoicingSetting_00024: "Nein",
  selfInvoicingSetting_00025: "EUR",
  selfInvoicingSetting_00026: "Bestätigen",
  selfInvoicingSetting_00027: "Abbrechen",
  selfRechargeSetting_00004: 'Gruppename',
  selfRechargeSetting_00005: 'suchen',
  selfRechargeSetting_00006: 'zurücksetzen',
  selfRechargeSetting_00007: 'Zahlungsmethode',
  selfRechargeSetting_00008: 'Gesamtzahl der Mitglieder',
  selfRechargeSetting_00009: 'Ob der Betrag den Gruppenmitgliedern zugewiesen werden soll',
  selfRechargeSetting_00010: 'Ob die Aufladung der Selbstverwaltungsgruppe unterstützt werden soll',
  selfRechargeSetting_00011: "Einzelzahlung",
  selfRechargeSetting_00012: "Gruppenzahlung",
  selfRechargeSetting_00013: "Nicht unterstützt",
  selfRechargeSetting_00014: "Bitte geben Sie eine Zahl ein",
  selfAufladeSetting_00015: "Wenn Sie automatische Rückerstattung auswählen, können Sie automatisch ohne manuellen Eingriff zurückerstatten, und die Annahme kann nach Bestätigung nicht storniert werden",
  selfAufladeSetting_00016: "Wenn Sie halbautomatische Rückerstattung auswählen, müssen Sie auf der Rückerstattungsseite auf [Annahme bestätigen] klicken, und die Annahme kann nach Bestätigung nicht storniert werden",
  selfAufladeSetting_00017: "Wenn manuelle Rückerstattung ausgewählt ist, verarbeitet Alipay die Rückerstattung als halbautomatische Rückerstattung (Sie müssen auf der Rückerstattungsseite auf [Annahme bestätigen] klicken, und die Annahme kann nach Bestätigung nicht storniert werden)",
}