export default {
    settingDepot_00001: 'Volume set',
    settingDepot_00002: 'Depot center',
    settingDepot_00003: 'Depot management',
    settingDepot_00004: 'Selected charging depot',
    settingDepot_00005: 'Common resource ',
    settingDepot_00006: 'Group rates',
    settingDepot_00007: 'Basic information',
    settingDepot_00008: 'Run Settings',
    settingDepot_00009: 'Please push the charging standard set for playing to the charging pile, and the charging pile can settle charging charges for users according to the new charging standard  ',
    settingDepot_00010: 'Push tariff',
    settingDepot_00011: 'AC rate standard',
    settingDepot_00012: 'Rates usage:',
    settingDepot_00013: 'Choose Rates',
    settingDepot_00014: 'New DC Rates',
    settingDepot_00015: 'Start time',
    settingDepot_00016: 'End time',
    settingDepot_00017: 'Electricity charge ({type}/{unit})',
    settingDepot_00018: 'Service fee ({type}/{unit})',
    settingDepot_00019: 'Idle fee ({type}/{unit})',
    settingDepot_00020: 'Time frame',
    settingDepot_00021: 'Undefined',
    settingDepot_00022: 'Top',
    settingDepot_00023: 'Peak',
    settingDepot_00024: 'Flat',
    settingDepot_00025: 'Valley',
    settingDepot_00026: 'DC rate standard',
    settingDepot_00027: 'New AC Rates',
    settingDepot_00028: 'New Rates',
    settingDepot_00029: 'Rates name',
    settingDepot_00030: 'Please enter the name of the Rates',
    settingDepot_00031: 'The Rates name is occupied',
    settingDepot_00032: 'Add time',
    settingDepot_00033: 'Operation',
    settingDepot_00034: 'Delete ',
    settingDepot_00035: 'Cancel',
    settingDepot_00036: 'Confirm',
    settingDepot_00037: 'Please select or create a new rate standard',
    settingDepot_00038: 'A maximum of {0} time periods can be added',
    settingDepot_00039: 'Please fill in the rate standard',
    settingDepot_00040: 'Please fill in the correct rate',
    settingDepot_00041: 'Creating success',
    settingDepot_00042: 'Choose',
    settingDepot_00043: 'Please complete the rate information',
    settingDepot_00044: 'The tariff period starts from 0 to 24',
    settingDepot_00045: "The total cost of single charging session is not discounted",
    settingDepot_00046: "The total cost of single charging session is not discounted",
    settingDepot_00047: "Single charging service fee {des} discount",
    settingDepot_00048: "The total cost of single charging session is {des} off",
    settingDepot_00049: "The total cost of single charging session is free",
    settingDepot_00050: "Free of charge for a single charge",
    settingDepot_00051: 'Please enter the correct amount (greater than 0)',
    settingDepot_00052: 'Succeed',
    settingDepot_00053: 'Pushing',
    settingDepot_00054: 'Fail',
    settingDepot_00055: 'Failed to push',
    settingDepot_00056: 'Depot name',
    settingDepot_00057: 'Equipment numbe',
    settingDepot_00058: 'Device push result',
    settingDepot_00059: 'DC',
    settingDepot_00060: 'AC',
    settingDepot_00061: 'Add group',
    settingDepot_00062: 'Group name',
    settingDepot_00063: 'Current rates',
    settingDepot_00064: 'Current Discounts',
    settingDepot_00065: 'Priority (from high to low)',
    settingDepot_00066: 'Shift up ',
    settingDepot_00067: 'Shift down',
    settingDepot_00068: 'Set',
    settingDepot_00069: 'Push',
    settingDepot_00070: 'Delete',
    settingDepot_00071: 'Depot contact',
    settingDepot_00072: 'When the equipment failure alarm, the contact person will be notified by SMS',
    settingDepot_00073: 'Name',
    settingDepot_00074: 'Please enter your nam',
    settingDepot_00075: 'Mobile',
    settingDepot_00076: 'Please enter your mobile number',
    settingDepot_00077: 'E-mail',
    settingDepot_00078: 'Please enter email address',
    settingDepot_00079: 'Edit',
    settingDepot_00080: 'Save',
    settingDepot_00081: 'Depot enabled status',
    settingDepot_00082: 'Control whether the site is displayed in the wechat public account',
    settingDepot_00083: 'Start using',
    settingDepot_00084: 'Not enabled',
    settingDepot_00085: 'opening up',
    settingDepot_00086: 'Open',
    settingDepot_00087: 'Off',
    settingDepot_00088: 'Only support groups are allowed to charge',
    settingDepot_00089: 'After this function is enabled, group users in group rate Settings can be charged',
    settingDepot_00090: 'Yes',
    settingDepot_00091: 'No',
    settingDepot_00092: 'Supported coupons',
    settingDepot_00093: 'Supported queuing',
    settingDepot_00094: 'Supported reservation',
    settingDepot_00095: 'Reservation time',
    settingDepot_00096: '15 minutes',
    settingDepot_00097: '30 minutes',
    settingDepot_00098: 'Wechat user payment mode',
    settingDepot_00099: 'Prepayment',
    settingDepot_00100: 'Postpaid',
    settingDepot_00101: 'The minimum balance limit is',
    settingDepot_00102: 'Yuan',
    settingDepot_00103: 'Online ID card user payment model',
    settingDepot_00104: 'The site information displayed on the client is affected',
    settingDepot_00105: 'Opening hours',
    settingDepot_00106: 'In the prepaid model, only the prepaid user can charge the battery',

    settingDepot_00107: 'Succeed',
    settingDepot_00108: 'Pushing',
    settingDepot_00109: 'Fail',
    settingDepot_00110: 'Failed to push',
    settingDepot_00111: 'Please modify the default tariff or add a group first',
    settingDepot_00112: 'Push failed. There is no device under the site',
    settingDepot_00113: 'This operation will delete the group from the list. Do you want to continue?',
    settingDepot_00114: 'Reminder',
    settingDepot_00115: 'Confirm',
    settingDepot_00116: 'Cancel',
    settingDepot_00117: 'Deletion cancelled',
    settingDepot_00118: 'Please confirm to save other operations first',
    settingDepot_00119: 'Please select a site',
    settingDepot_00120: 'No information modification, operation cancelled',
    settingDepot_00121: 'Please enter the correct contact name',
    settingDepot_00122: 'Please enter the correct phone number',
    settingDepot_00123: 'Please enter the correct email format',
    settingDepot_00124: 'Incorrect date setting',
    settingDepot_00125: 'Select group',
    settingDepot_00126: 'Please enter the community name',
    settingDepot_00127: 'Number of users',
    settingDepot_00128: 'Tariff standard',
    settingDepot_00129: 'If the tariff standard is modified, click the "push tariff" button in the site details - tariff standard to make the tariff effective!',
    settingDepot_00130: 'Exchange tariff',
    settingDepot_00131: 'Please select tariff',
    settingDepot_00132: 'DC tariff',
    settingDepot_00133: 'Discount selection',
    settingDepot_00134: 'The total cost of a single success point is not discounted(%)',
    settingDepot_00135: 'Discount of single charge service fee(%)',
    settingDepot_00136: 'For example: if the service charge for a single charge is set to be 8.5% off, and the charge for this charge is 100 yuan (including 70 yuan for electricity and 30 yuan for service), the actual amount to be paid this time is 95.5 yuan (70 + 30 * 85% = 95.5)',
    settingDepot_00137: '85 means 8.5% off, and 0 means free',
    settingDepot_00138: 'Discount on the total cost of a single charge(%) ',
    settingDepot_00139: 'For example: if the total charge for a single charge is set to be 8.5% off and the total charge for this charge is 100 yuan, the actual payment amount is 85 yuan (100 * 85% = 85)',
    settingDepot_00140: '85 means 8.5% off, and 0 means free',
    settingDepot_00141: 'Group added successfully',
    settingDepot_00142: 'Click the [push charge] button in the tariff standard to make the tariff effective!',
    settingDepot_00143: 'The following operations can be performed in [charging station management - site details - site information]:',
    settingDepot_00144: '1. Remove added community',
    settingDepot_00145: '2. Add a new group',
    settingDepot_00146: 'Previous step',
    settingDepot_00147: 'next step',
    settingDepot_00148: 'confirm',
    settingDepot_00149: 'Please select a group',
    settingDepot_00150: 'Please select the tariff standard',
    settingDepot_00151: 'User groups cannot be added repeatedly',
    settingDepot_00152: 'Please set discount parameters',
    settingDepot_00153: 'Please enter a positive integer less than 100',
    settingDepot_00154: 'Select tariff and discount',
    settingDepot_00155: 'complete',
    settingDepot_00156: 'Batch modification results',
    settingDepot_00157: 'Waiting for response',
    settingDepot_00158: 'reason',
    settingDepot_00159: 'close',
    settingDepot_00160: 'Minimum balance',
    settingDepot_00161: 'Use currency',
    settingDepot_00162: '1. An added group',
    settingDepot_00163: '',
    settingDepot_00164: '',
    settingDepot_00165: '',
    settingDepot_00166: '',
    settingDepot_00167: '',
    settingDepot_00168: '',
    settingDepot_00169: '',
    settingDepot_00170: '',
}